import React from 'react';

const RangeSlider = ({
  min,
  max,
  value,
  onChange,
  showControls,
  step,
  controlled = true,
  buttonStep,
}) => (
  <div className="range-slider">
    {showControls && (
      <button
        className="range-slider__button"
        type="button"
        onClick={() => {
          if (buttonStep) {
            if (value - buttonStep >= min) {
              onChange(value - buttonStep);
            }
          } else {
            if (value - 1 >= min) {
              onChange(value - 1);
            }
          }
        }}
      >
        <span>-</span>
      </button>
    )}
    <input
      type="range"
      min={min}
      max={max}
      value={controlled ? value : undefined}
      step={step}
      onChange={(e) => onChange(e.target.value)}
      className="range-slider__input"
    />
    {showControls && (
      <button
        className="range-slider__button"
        type="button"
        onClick={() => {
          if (buttonStep) {
            if (value + buttonStep <= max) {
              onChange(value + buttonStep);
            }
          } else {
            if (value + 1 <= max) {
              onChange(value + 1);
            }
          }
        }}
      >
        <span>+</span>
      </button>
    )}
  </div>
);

export default RangeSlider;
