import {
  axiosRequestPOST,
  axiosRequestGET,
  axiosRequestDELETE,
  axiosRequestPUT,
} from '../services/serviceConfig';

export const uploadFileToElementService = (host, data, requestOptions) =>
  axiosRequestPOST(`${host}/api/upload`, data, requestOptions);

export const getUploadAccessToken = (
  knowledgeAreaId,
  knowledgeElementId,
  data
) =>
  axiosRequestPOST(
    `/api/editor/knowledge/${knowledgeAreaId}/${knowledgeElementId}/upload/access`,
    data
  );

export const getLinkService = (link) => axiosRequestGET(`/api/link/${link}`);

export const finalizeUpload = (knowledgeAreaId, knowledgeElementId, data) =>
  axiosRequestPUT(
    `/api/editor/knowledge/${knowledgeAreaId}/${knowledgeElementId}/upload/finalize`,
    data
  );

export const deleteFileService = (knowledgeAreaId, knowledgeElementId, data) =>
  axiosRequestDELETE(
    `/api/editor/knowledge/${knowledgeAreaId}/${knowledgeElementId}/file/delete`,
    data
  );

export const getAllowedFileTypes = () =>
  axiosRequestGET('/api/editor/knowledge/upload/mimetypes');

export const getElementFilesProgress = (knowledgeAreaId, knowledgeElementId) =>
  axiosRequestGET(
    `/api/editor/knowledge/${knowledgeAreaId}/${knowledgeElementId}/files/progress`
  );

export const getElementHistoryService = (personId, elementId) =>
  axiosRequestGET(`/api/trainee/${personId}/knowledge/${elementId}/history`);

export const getAllElementHistoryService = (personId, elementId) =>
  axiosRequestGET(
    `/api/trainee/${personId}/knowledge/${elementId}/history/all`
  );

export const getElementRelocationInfoService = (elementId, data) =>
  axiosRequestPUT(
    `/api/editor/knowledge/element/${elementId}/move/relations`,
    data
  );

export const updateElementDescriptionService = (
  areaId,
  elementId,
  updateData
) => {
  const url = `/api/editor/knowledge/${areaId}/${elementId}/update/description`;
  return axiosRequestPUT(url, updateData);
};

export const updateAreaLinkVisiblityService = (areaId, data) =>
  axiosRequestPUT(`/api/editor/knowledge/${areaId}/update/link`, data);

export const updateElementLinkVisiblityService = (areaId, elementId, data) =>
  axiosRequestPUT(
    `/api/editor/knowledge/${areaId}/${elementId}/update/link`,
    data
  );

export const updateElementExpirationService = (elementId, data) =>
  axiosRequestPUT(`/api/editor/knowledge/${elementId}/update/expiration`, data);

export const deleteElementHistoryEventApprovalService = (
  personId,
  elementId,
  eventId,
  approvalId
) =>
  axiosRequestDELETE(
    `/api/trainee/${personId}/knowledge/${elementId}/history/${eventId}/${approvalId}`
  );

export const deleteElementHistoryEventService = (
  personId,
  elementId,
  eventId
) =>
  axiosRequestDELETE(
    `/api/trainee/${personId}/knowledge/${elementId}/history/${eventId}`
  );

export const restoreElementHistoryEventApprovalService = (
  personId,
  elementId,
  eventId,
  approvalId
) =>
  axiosRequestPUT(
    `/api/trainee/${personId}/knowledge/${elementId}/history/${eventId}/${approvalId}`
  );

export const restoreElementHistoryEventService = (
  personId,
  elementId,
  eventId
) =>
  axiosRequestPUT(
    `/api/trainee/${personId}/knowledge/${elementId}/history/${eventId}`
  );

export const moveElementService = (elementId, data) =>
  axiosRequestPUT(`/api/editor/knowledge/${elementId}/move`, data);

export const connectElementsToRoleService = (roleId, areaId, data) =>
  axiosRequestPUT(`/api/editor/roles/${roleId}/${areaId}/connect`, data);

export const disconnectElementsFromRoleService = (roleId, areaId, data) =>
  axiosRequestPUT(`/api/editor/roles/${roleId}/${areaId}/disconnect`, data);
