import React from 'react';
import localeLookup from '../config/locale';

const ProgressDiagram = ({ completionPercentage, diagramValues }) => {
  const sum = () => {
    const sum = diagramValues.reduce((acc, obj) => {
      if (obj.value > 0) {
        return acc + obj.value;
      }
      return acc;
    }, 0);
    return sum;
  };

  const calculateBarPercentage = (value) => {
    const percentageValue = (value / sum()) * 100;
    return `${percentageValue}%`;
  };
  return (
    <div className="progress-diagram">
      <div className="progress-diagram__completion" style={{ left: `${completionPercentage}%` }}>
        <div className="progress-diagram__completion-line" />
        <div className="progress-diagram__completion-triangle" />
        <span className="progress-diagram__completion-text">{localeLookup('translations.GoalShort')}</span>
      </div>
      {sum() === 0 && (
        <div className="progress-diagram__none">
          <span className="progress-diagram__none-text">{localeLookup('translations.No goal')}</span>
        </div>
      )}
      {diagramValues.map((value, i) => {
        if (value.value > 0) {
          return (
            <div className={`progress-diagram__bar progress-diagram__bar--${value.color}`} key={i} style={{ width: calculateBarPercentage(value.value) }}>
              <span className="progress-diagram__bar-value">{value.value}</span>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ProgressDiagram;
