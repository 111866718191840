/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import BoxMessage from '../BoxMessage';
import Textarea from '../Textarea';
import GenericModal from './GenericModal';

class ResetStatusModal extends Component {
  constructor() {
    super();
    this.state = {
      note: '',
    };
  }

  onChangeNote = (e) => {
    this.setState({
      note: e.target.value,
    });
  };

  onClickConfirm = () => {
    const { note } = this.state;
    const { onConfirm } = this.props;
    onConfirm(note);
  };

  render() {
    const { note } = this.state;
    const { cancelButtonText, onCancel, onClose, onCloseClick, title, subtitle, infoText } = this.props;
    return (
      <GenericModal title={title || localeLookup('translations.Add reset')} subtitle={subtitle} confirmButtonText={localeLookup('translations.Add')} onConfirmClick={this.onClickConfirm} cancelButtonText={cancelButtonText} onCancelClick={onCancel || onCloseClick} onClose={onClose || onCloseClick}>
        <BoxMessage spacing="md" icon="info-circle">
          {infoText || localeLookup('translations.A reset will result in the element being shown as not completed')}
        </BoxMessage>
        <Textarea maxLength={255} placeholder={localeLookup('translations.Add a note')} onChange={this.onChangeNote} value={note} />
      </GenericModal>
    );
  }
}

export default ResetStatusModal;
