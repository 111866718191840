import React, { PureComponent } from 'react';
import ModalWrapper from './ModalWrapper';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ButtonIcon from '../ButtonIcon';
import BoxMessage from '../BoxMessage';

class GenericModal extends PureComponent {
  render() {
    const { onCancelClick, message, cancelButtonText, confirmButtonText, confirmButtonKind, onConfirmClick, confirmDisabled, children, title, subtitle, onClose, deep, steps, activeStepIndex } = this.props;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} steps={steps} activeStepIndex={activeStepIndex} onClose={onClose} />
        {message && (
          <BoxMessage type={message.type} icon={message.type === 'warning' ? 'warning' : 'info-circle'}>
            {message.text}
          </BoxMessage>
        )}
        <ModalBody deep={deep}>{children}</ModalBody>
        <ModalFooter onCancelClick={onCancelClick} cancelButtonText={cancelButtonText} confirmButtonText={confirmButtonText} confirmButtonKind={confirmButtonKind} onConfirmClick={onConfirmClick} confirmDisabled={confirmDisabled} />
      </ModalWrapper>
    );
  }
}

export default GenericModal;
