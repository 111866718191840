// Actions
import {
  FETCHING_TRAINING_AND_OWNERSHIPS,
  TRAINING_AND_OWNERSHIPS_RECIEVED,
  SET_SELECTED_SIDEBAR_ELEMENT,
  TRAINING_ROLE_DATA_RECIEVED,
  OWNED_ROLE_DATA_RECIEVED,
  TRAINING_AREA_DATA_RECIEVED,
  OWNED_AREA_DATA_RECIEVED,
  SET_TRAINING_ROLE_LOADER,
  SET_TRAINING_ROLE_ERRORMSG,
  SET_OWNED_ROLE_LOADER,
  SET_OWNED_ROLE_ERRORMSG,
  SET_TRAINING_AREA_LOADER,
  SET_TRAINING_AREA_ERRORMSG,
  SET_OWNED_AREA_LOADER,
  SET_OWNED_AREA_ERRORMSG,
  UPDATED_DASHBOARD_PERSON_DATA_RECEIVED,
  UPDATED_DASHBOARD_PERSONS_DATA_RECEIVED,
  TAO_UPDATED_PERSON_ELEMENT_DATA_RECEIVED,
} from '../actions/trainingAndOwnershipsActions';

const initialState = {
  knowledgeRoles: {},
  knowledgeAreas: {},
  knowledgeElements: {},
  trainingRoles: {},
  trainingKnowledgeAreas: {},
  ownedRoles: {},
  ownedKnowledgeAreas: {},
  persons: {},
  selectedSidebarElement: {
    id: null,
    type: null,
  },
};

const trainingAndOwnership = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_TRAINING_AND_OWNERSHIPS:
      return {
        ...state,
        hasOverviewLoaded: false,
      };
    case TRAINING_AND_OWNERSHIPS_RECIEVED:
      return {
        ...state,
        ...action.data,
        hasOverviewLoaded: true,
      };
    case SET_SELECTED_SIDEBAR_ELEMENT:
      return {
        ...state,
        selectedSidebarElement: {
          id: action.id,
          type: action.elementType,
        },
      };

    case TRAINING_ROLE_DATA_RECIEVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action),
        trainingRoles: {
          ...state.trainingRoles,
          [action.id]: action.data.role,
        },
        knowledgeAreas: {
          ...state.knowledgeAreas,
          ...action.data.knowledgeAreas,
        },
        knowledgeElements: {
          ...state.knowledgeElements,
          ...action.data.knowledgeElements,
        },
      };
    case SET_TRAINING_ROLE_LOADER:
      return {
        ...state,
        trainingRoles: {
          ...state.trainingRoles,
          [action.id]: {
            ...state.trainingRoles[action.id],
            isLoading: action.isLoading,
            hasLoaded: action.hasLoaded,
          },
        },
      };
    case SET_TRAINING_ROLE_ERRORMSG:
      return {
        ...state,
        trainingRoles: {
          ...state.trainingRoles,
          [action.id]: {
            ...state.trainingRoles[action.id],
            errorMsg: action.errorMsg,
          },
        },
      };
    case OWNED_ROLE_DATA_RECIEVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action),
        ownedRoles: {
          ...state.ownedRoles,
          [action.id]: action.data.role,
        },
        knowledgeAreas: {
          ...state.knowledgeAreas,
          ...action.data.knowledgeAreas,
        },
        knowledgeElements: {
          ...state.knowledgeElements,
          ...action.data.knowledgeElements,
        },
      };
    case SET_OWNED_ROLE_LOADER:
      return {
        ...state,
        ownedRoles: {
          ...state.ownedRoles,
          [action.id]: {
            ...state.ownedRoles[action.id],
            isLoading: action.isLoading,
            hasLoaded: action.hasLoaded,
          },
        },
      };
    case SET_OWNED_ROLE_ERRORMSG:
      return {
        ...state,
        ownedRoles: {
          ...state.ownedRoles,
          [action.id]: {
            ...state.ownedRoles[action.id],
            errorMsg: action.errorMsg,
          },
        },
      };
    case TRAINING_AREA_DATA_RECIEVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action),
        trainingKnowledgeAreas: {
          ...state.trainingKnowledgeAreas,
          [action.id]: action.data.area,
        },
        knowledgeRoles: {
          ...state.knowledgeRoles,
          ...action.data.knowledgeRoles,
        },
        knowledgeElements: {
          ...state.knowledgeElements,
          ...action.data.knowledgeElements,
        },
      };
    case SET_TRAINING_AREA_LOADER:
      return {
        ...state,
        trainingKnowledgeAreas: {
          ...state.trainingKnowledgeAreas,
          [action.id]: {
            ...state.trainingKnowledgeAreas[action.id],
            isLoading: action.isLoading,
            hasLoaded: action.hasLoaded,
          },
        },
      };
    case SET_TRAINING_AREA_ERRORMSG:
      return {
        ...state,
        trainingKnowledgeAreas: {
          ...state.trainingKnowledgeAreas,
          [action.id]: {
            ...state.trainingKnowledgeAreas[action.id],
            errorMsg: action.errorMsg,
          },
        },
      };
    case OWNED_AREA_DATA_RECIEVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action),
        ownedKnowledgeAreas: {
          ...state.ownedKnowledgeAreas,
          [action.id]: action.data.area,
        },
        knowledgeRoles: {
          ...state.knowledgeRoles,
          ...action.data.knowledgeRoles,
        },
        knowledgeElements: {
          ...state.knowledgeElements,
          ...action.data.knowledgeElements,
        },
      };
    case SET_OWNED_AREA_LOADER:
      return {
        ...state,
        ownedKnowledgeAreas: {
          ...state.ownedKnowledgeAreas,
          [action.id]: {
            ...state.ownedKnowledgeAreas[action.id],
            isLoading: action.isLoading,
            hasLoaded: action.hasLoaded,
          },
        },
      };
    case SET_OWNED_AREA_ERRORMSG:
      return {
        ...state,
        ownedKnowledgeAreas: {
          ...state.ownedKnowledgeAreas,
          [action.id]: {
            ...state.ownedKnowledgeAreas[action.id],
            errorMsg: action.errorMsg,
          },
        },
      };
    case UPDATED_DASHBOARD_PERSON_DATA_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.personId]: {
            ...state.persons[action.personId],
            knowledgeElements: {
              ...state.persons[action.personId].knowledgeElements,
              ...action.data.persons[action.personId].knowledgeElements,
            },
            knowledgeAreas: {
              ...state.persons[action.personId].knowledgeAreas,
              ...action.data.persons[action.personId].knowledgeAreas,
            },
          },
        },
      };
    case TAO_UPDATED_PERSON_ELEMENT_DATA_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.personId]: {
            ...state.persons[action.personId],
            knowledgeElements: {
              ...state.persons[action.personId].knowledgeElements,
              [action.elementId]: {
                ...state.persons[action.personId].knowledgeElements[action.elementId],
                status: action.status,
                completedAt: action.completedAt,
                hasValidity: action.hasValidity,
                daysUntilExpiration: action.daysUntilExpiration,
              },
            },
          },
        },
      };
    case UPDATED_DASHBOARD_PERSONS_DATA_RECEIVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action),
      };
    default:
      return state;
  }
};

const addKnowledgeAreasToPersonInState = (persons, personId, action) => {
  const personObj = Object.keys(action.data.persons[personId].knowledgeAreas).reduce((obj, key) => {
    if (persons[personId].knowledgeAreas[key]) {
      obj[key] = {
        ...action.data.persons[personId].knowledgeAreas[key],
        roles: {
          ...persons[personId].knowledgeAreas[key].roles,
          ...action.data.persons[personId].knowledgeAreas[key].roles,
        },
      };
    } else {
      obj[key] = { ...action.data.persons[personId].knowledgeAreas[key] };
    }
    return obj;
  }, {});
  return {
    ...persons[personId].knowledgeAreas,
    ...personObj,
  };
};

const addPersonsToState = (persons, action) => {
  const personObj = Object.keys(action.data.persons).reduce((obj, key) => {
    if (persons[key]) {
      obj[key] = {
        ...action.data.persons[key],
        knowledgeElements: {
          ...persons[key].knowledgeElements,
          ...action.data.persons[key].knowledgeElements,
        },
        knowledgeAreas: addKnowledgeAreasToPersonInState(persons, key, action),
      };
    } else {
      obj[key] = { ...action.data.persons[key] };
    }
    return obj;
  }, {});
  return {
    ...persons,
    ...personObj,
  };
};

export default trainingAndOwnership;
