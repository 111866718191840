/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import withPersonLookup from '../HOC/withPersonLookup';
import Icon from '../Icon';
import SimpleTable from '../SimpleTable';
import Text from '../Text';
import Tooltip from '../Tooltip';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

class SignatureDisplayModal extends Component {
  renderSignatureBoxContents = () => {
    const { signature } = this.props;
    if (signature === '') {
      return (
        <div className="signature-box__placeholder">
          <Icon
            color="grey"
            className="signature-box__placeholder-icon"
            kind="eye-crossed"
          />
          <Text bold color="dark-grey">
            {localeLookup(
              'translations.You do not have permission to see this signature'
            )}
          </Text>
        </div>
      );
    }
    if (signature === 'Signature deleted') {
      return (
        <div className="signature-box__placeholder">
          <Text bold color="dark-grey">
            {localeLookup('translations.Signature has been deleted')}
          </Text>
        </div>
      );
    }
    return (
      <img src={signature} alt="signature" className="signature-box__image" />
    );
  };

  render() {
    const {
      title,
      subtitle,
      onClose,
      onCancel,
      signee,
      signatureMetadata,
      signatureGatheredBy,
      signatureGatheredById,
      signedById,
      lookupPerson,
    } = this.props;

    const getSignedBySuffix = () => {
      const person = lookupPerson(signedById);
      if (person) {
        return `(${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }
      return '';
    };
    const getSignatureGatheredBySuffix = () => {
      const person = lookupPerson(signatureGatheredById);
      if (person) {
        return `(${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }
      return '';
    };
    return (
      <ModalWrapper className="signature-modal">
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalBody>
          <div className="signature-box">
            {this.renderSignatureBoxContents()}
            <div className="signature-box__text">
              <Text bold>
                {signee} {getSignedBySuffix()},{' '}
                {signatureMetadata['ShortDateAndTime']}
              </Text>
            </div>
          </div>
          <SimpleTable
            rows={[
              [
                localeLookup('translations.Logged in as'),
                `${signatureGatheredBy} ${getSignatureGatheredBySuffix()}`,
              ],
              ...Object.keys(signatureMetadata).map((key) => {
                if (key === 'ShortDateAndTime') {
                  return [];
                }

                return [key, signatureMetadata[key]];
              }),
            ]}
            headerRow={[localeLookup('translations.Metadata'), '']}
            columnWidths={['50%', '50%']}
          />
        </ModalBody>
        <ModalFooter onCancelClick={onCancel} cancelButtonText="Close" />
      </ModalWrapper>
    );
  }
}

export default withPersonLookup(SignatureDisplayModal);
