import React from 'react';
import cx from 'classnames';
import Icon from './Icon';

const Badge = ({ icon, iconSize, text, color, size, className, iconColor }) => {
  return (
    <div
      className={cx(
        'badge',
        {
          [`badge--${color}`]: color,
          [`badge--${size}`]: size,
          [`badge--icon-size-${iconSize}`]: iconSize,
          [`badge--icon-color-${iconColor}`]: iconColor,
        },
        className
      )}
    >
      {icon ? (
        <Icon className="badge__icon" kind={icon} />
      ) : (
        <span className="badge__text">{text}</span>
      )}
    </div>
  );
};

export default Badge;
