import cx from 'classnames';
import React from 'react';
import Icon from './Icon';

const Select = ({ options, id, className, wrapperClassName, size, onChange, name, value, disabled }) => {
  const modifierClasses = cx({
    [`select--size-${size}`]: size,
  });
  return (
    <div className={cx('select-wrapper', wrapperClassName)}>
      <select disabled={disabled} id={id} name={name} className={cx('select', modifierClasses, className)} onChange={onChange} value={value}>
        {options.map((option) => (
          <option className="select__option" key={option.value} value={option.value} default={option.default}>
            {option.title}
          </option>
        ))}
      </select>
      {!disabled && <Icon kind="chevron-down" size="small" color="grey" className="select__icon"></Icon>}
    </div>
  );
};

export default Select;
