import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import ErrorMessage from './formElements/ErrorMessage';
import Icon from './Icon';
import Loader from './Loader';

class InlineFieldEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      newValue: this.props.defaultValue,
      error: '',
      isValid: true,
      isValidating: false,
      dirty: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) this.textInput.focus();
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ newValue: this.props.defaultValue });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  validate = debounce(async () => {
    const { newValue } = this.state;
    const { validate } = this.props;
    try {
      const { error, isValid } = await validate(newValue);
      this.setState({
        isValidating: false,
        error,
        isValid,
      });
    } catch ({ error, isValid }) {
      this.setState({
        isValidating: false,
        isValid,
        error,
      });
    }
  }, 500);

  onChange = (e) => {
    const { upperCase, validate, defaultValue } = this.props;
    const { value } = e.target;
    const hasChanged = defaultValue !== value;
    this.setState(
      {
        dirty: true,
        isValidating: hasChanged && !!validate,
        newValue: upperCase ? value.toUpperCase() : value,
        error: '',
      },
      () => {
        if (validate && hasChanged) this.validate();
      }
    );
  };

  onClickOutside = (event) => {
    if (
      this.componentRef.current &&
      !this.componentRef.current.contains(event.target)
    ) {
      this.onSubmit();
    }
  };

  onCancelClick = () => {
    this.setState({
      editing: false,
      newValue: this.props.defaultValue,
    });
  };

  onSubmit = (e) => {
    e?.preventDefault();
    const { newValue, isValid, isValidating } = this.state;
    const { defaultValue, onSubmit, allowEmpty, validate } = this.props;
    if (validate && (!isValid || isValidating)) {
      return;
    }
    if (allowEmpty && newValue === '' && defaultValue !== '') {
      onSubmit('');
    } else if (
      newValue !== defaultValue &&
      newValue !== '' &&
      newValue.trim() !== ''
    ) {
      onSubmit(newValue, this.resetField);
    } else {
      this.setState({
        editing: false,
        newValue: defaultValue,
      });
    }

    this.props.onBlur && this.props.onBlur();
  };

  onBlur = () => {
    this.setState({
      editing: false,
    });
  };

  resetField = () => {
    this.setState({
      newValue: this.props.defaultValue,
    });
  };

  render() {
    const {
      className,
      classNameInput,
      placeholder,
      label,
      inputId,
      maxLength,
      disabled,
      validate,
    } = this.props;
    const { isValidating, error, dirty } = this.state;
    return (
      <div
        className={`inline-field-editor ${className}`}
        ref={this.componentRef}
      >
        <form
          className="inline-field-editor__form"
          onBlur={this.onBlur}
          onSubmit={(e) => {
            this.onSubmit(e);
          }}
          autoComplete="off"
        >
          {label && (
            <label
              className="e-label-upper inline-field-editor__form-label"
              htmlFor={inputId}
            >
              {label}
            </label>
          )}
          <div className="inline-field-editor__input-wrapper">
            <input
              disabled={disabled}
              maxLength={maxLength}
              onMouseDown={(e) => e.stopPropagation()}
              className={`ui-input inline-field-editor__form-input ${classNameInput}`}
              type="text"
              value={this.state.newValue}
              id={inputId}
              placeholder={placeholder}
              onChange={this.onChange}
              ref={(input) => {
                this.textInput = input;
              }}
            />
            {isValidating && (
              <Loader className="inline-field-editor__loader" size="small" />
            )}
            {!isValidating && !error && validate && dirty && (
              <motion.div
                className="inline-field-editor__validation-icon"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Icon color="green" kind="checkmark-circle"></Icon>
              </motion.div>
            )}
          </div>
          {error && !isValidating && <ErrorMessage show>{error}</ErrorMessage>}
        </form>
      </div>
    );
  }
}

export default InlineFieldEditor;
