import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAuthUserData } from '../../actions/menuActions';
import { updateUserInfo } from '../../actions/userActions';
import {
  changeLocale,
  changeName,
  changePassword,
  requestUserSettings,
} from '../../actions/userSettingsActions';
import Button from '../../components/Button';
import Input from '../../components/formElements/Input';
import Field from '../../components/formElements/Field';
import WithModals from '../../components/HOC/withModals';
import InlineFieldEditor from '../../components/InlineFieldEditor';
import InlineSelectEditor from '../../components/InlineSelectEditor';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import localeLookup from '../../config/locale';
import { rootmenuSelector } from '../../reducers/rootmenuReducer';
import { updatePreferredTenantService } from '../../services/userProfileService';
import { getWildcardPersons } from '../../slices/wildcardPersonsSlice';
import { trackEvent } from '../../utils/tracking';
import WithPersonActions from '../../components/HOC/withPersonActions';

const mapStateToProps = function (state) {
  const { userSettings, user } = state;
  return {
    userSettings,
    tenants: user.tenants,
    tenantId: user.tenantId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      changeLocale,
      changePassword,
      requestUserSettings,
      changeName,
      updateUserInfo,
      getAuthUserData,
      getWildcardPersons,
    },
    dispatch
  ),
});

class UserProfile extends Component {
  constructor() {
    super();
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
  }

  componentDidMount() {
    trackEvent('$pageview');
    this.props.requestUserSettings();
  }

  onChangeLanguage = (value) => {
    const { changeLocale, getWildcardPersons } = this.props;
    changeLocale(value).then(() => {
      getWildcardPersons();
    });
  };

  onChangePasswordClick() {
    const { showModal, personActions } = this.props;
    personActions.showChangePersonPasswordModal({ isOwnPassword: true });
  }

  onChangePreferredOrganisation = (value) => {
    const { getAuthUserData } = this.props;
    updatePreferredTenantService(value).then(() => {
      getAuthUserData();
    });
  };

  render() {
    const { userSettings, changeLocale, changeName, tenants, tenantId } =
      this.props;
    const languageOptions =
      userSettings.locales &&
      Object.keys(userSettings.locales).reduce(
        (acc, key) => {
          acc.push({
            name: userSettings.locales[key],
            value: key,
          });
          return acc;
        },
        [
          {
            name: localeLookup('translations.Organisation language'),
            value: '',
          },
        ]
      );
    const tenantOptions = Object.values(tenants).map((tenant) => ({
      name: tenant.name,
      value: tenant.id,
    }));
    return (
      <Page>
        <MainArea>
          {userSettings && (
            <div className="user-settings">
              <section className="user-settings__section">
                <div className="user-settings__section-header">
                  <h2 className="user-settings__section-title">
                    {localeLookup('translations.Profile settings')}
                  </h2>
                </div>
                <div className="user-settings__section-body">
                  <InlineFieldEditor
                    disabled={userSettings.userType === 'External'}
                    className="user-settings__section-field"
                    label={localeLookup('translations.Name')}
                    inputId="name"
                    defaultValue={userSettings.name}
                    onSubmit={(name, resetField) => {
                      changeName(name, resetField);
                    }}
                  />
                  <InlineSelectEditor
                    className="user-settings__section-field"
                    label={localeLookup('translations.language')}
                    selectId="language"
                    defaultValue={userSettings.locale}
                    options={languageOptions}
                    onSubmit={this.onChangeLanguage}
                  />
                  <InlineSelectEditor
                    className="user-settings__section-field"
                    label={localeLookup('translations.Preferred organisation')}
                    selectId="organisationPreference"
                    defaultValue={tenantId}
                    options={tenantOptions}
                    onSubmit={this.onChangePreferredOrganisation}
                  />
                  <Field
                    className="user-settings__section-field"
                    label={localeLookup('translations.Initials')}
                    labelSize="sm"
                  >
                    <Input
                      fullWidth
                      disabled
                      value={userSettings.initials}
                    ></Input>
                  </Field>
                  <Field
                    className="user-settings__section-field"
                    label={localeLookup('translations.Employee number')}
                    labelSize="sm"
                  >
                    <Input
                      fullWidth
                      disabled
                      value={userSettings.employeeNumber}
                    ></Input>
                  </Field>
                </div>
              </section>
              <section className="user-settings__section">
                <div className="user-settings__section-header">
                  <h2 className="user-settings__section-title">
                    {localeLookup('translations.Login')}
                  </h2>
                </div>
                <div className="user-settings__section-body">
                  <p className="user-settings__section-email-text">
                    {userSettings.email}
                  </p>
                  {userSettings.userType !== 'External' && (
                    <Button
                      onClick={this.onChangePasswordClick}
                      kind="light-grey"
                      className="user-settings__section-password-button"
                      icon="lock"
                    >
                      {localeLookup('translations.Change password')}
                    </Button>
                  )}
                </div>
              </section>
            </div>
          )}
        </MainArea>
      </Page>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(WithPersonActions(UserProfile)));
