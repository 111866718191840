import { AUTH_USER_INFO_RECEIVED, USER_INFO_RECEIVED } from '../actions/menuActions';
import { USER_SETTINGS_RECIEVED } from '../actions/userSettingsActions';

const initialState = {
  id: '',
  initials: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  locale: '',
  organisation: '',
  champLinkPrefix: '',
  created: 0,
  isAuthenticated: false,
  isManagingCustomer: false,
  tenantId: '',
  tenants: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTH_USER_INFO_RECEIVED:
      return { ...state, id: action.id, tenantId: action.tenantId, tenants: action.tenants };

    case USER_INFO_RECEIVED:
      return {
        ...state,
        initials: action.initials,
        name: action.name,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        locale: action.locale,
        organisation: action.organisation,
        champLinkPrefix: action.champLinkPrefix,
        created: action.created,
        isManagingCustomer: action.isManagingCustomer,
        employeeId: action.employeeId,
      };
    case 'USER_NAME_CHANGED':
      return {
        ...state,
        name: action.name,
      };
    case USER_SETTINGS_RECIEVED:
      return {
        ...state,
        locale: action.data.locale,
      };
    default:
      return state;
  }
};

export default user;

export const userSelector = {
  user(state) {
    return state.user;
  },
};
