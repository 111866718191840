import React, { useEffect, useRef } from 'react';
import Input from '../formElements/Input';
import Icon from '../Icon';

const ModalFilter = ({ placeholder, onChange, value, showButton, onButtonClick, buttonIcon, buttonText }) => {
  const inputRef = useRef(null);
  /* useEffect(() => inputRef.current?.focus?.()); */
  return (
    <div className="modal-content__filter">
      <Input
        innerRef={inputRef}
        autoFocus
        className="modal-content__filter-input"
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {showButton && (
        <button type="button" className="ui-btn ui-btn-darkui modal-content__filter-button" onClick={onButtonClick}>
          {buttonIcon && <Icon kind={buttonIcon} />}
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default ModalFilter;
