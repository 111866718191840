const localeLookup = (key, placeholderValues) => {
  const debugMode = true;
  const translations = window.CHAMP.locale.data.map;
  if (!translations) return key;
  const keyWithoutNameSpace = key.slice(key.indexOf('.') + 1);
  let translation;
  if (!key) return '';
  if (translations[key]) {
    translation = translations[key];
  } else if (translations[key.toLowerCase()]) {
    translation = translations[key.toLowerCase()];
  }
  if (typeof translation === 'undefined') {
    if (debugMode) {
      return key.slice(key.indexOf('.') + 1);
    }
    return key;
  }

  // Temp solution for handling casing
  const isFirstLetterOfKeyUpperCase = isFirstUpperCase(keyWithoutNameSpace);
  const isFirstLetterOfTranslationLowerCase = isFirstLowerCase(translation);
  if (isFirstLetterOfKeyUpperCase && isFirstLetterOfTranslationLowerCase) {
    return upperFirst(interpolateString(translation, placeholderValues));
  }

  const isFirstLetterOfKeyLowerCase = isFirstLowerCase(keyWithoutNameSpace);
  const isFirstLetterOfResultUpperCase = isFirstUpperCase(translation);
  if (isFirstLetterOfKeyLowerCase && isFirstLetterOfResultUpperCase) {
    return lowerFirst(interpolateString(translation, placeholderValues));
  }
  return interpolateString(translation, placeholderValues);
};

const isFirstUpperCase = (str) => str[0] === str[0].toUpperCase();
const isFirstLowerCase = (str) => str[0] === str[0].toLowerCase();
const lowerFirst = (str) => `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
const upperFirst = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
const interpolateString = (str, values = []) => {
  if (!values) return str;
  return str.replace(/{[\d]+}/g, (placeholder) => {
    return values[placeholder.slice(1, -1)];
  });
};

export default localeLookup;
