import React from 'react';
import localeLookup from '../../config/locale';
import CreateRoleForm from '../CreateRoleForm';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';
import TrainingForm from '../TrainingForm';

const TrainingModal = ({
  onClose,
  title,
  initialValues,
  onConfirm,
  subtitle,
  confirmButtonText,
  context
}) => (
  <FormModal onClose={onClose} title={title} subtitle={subtitle}>
    <TrainingForm
      onClose={onClose}
      onConfirm={onConfirm}
      initialValues={initialValues}
      context={context}
      renderFooter={({ handleSubmit, canSubmit }) => (
        <ModalFooter
          confirmButtonText={
            confirmButtonText || localeLookup('translations.Start training')
          }
          confirmDisabled={!canSubmit}
          onConfirmClick={handleSubmit}
          onCancelClick={onClose}
        />
      )}
    />
  </FormModal>
);

export default TrainingModal;
