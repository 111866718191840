/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { EMPTY_ID } from '../../constants';
import {
  createCategoryService,
  deleteCategoryService,
  updateCategoryNameService,
} from '../../services/contentService';
import {
  getAllCategories,
  removeCategory,
  selectCategoriesSortOrder,
} from '../../slices/categoriesSlice';
import Input from '../formElements/Input';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import RadioButton from '../RadioButton';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => ({
  categories: state.categories,
  categoriesSortOrder: selectCategoriesSortOrder(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllCategories, removeCategory }, dispatch);

class AssignCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingCategory: false,
      addCategoryName: '',
      editingNameId: null,
      filterString: '',
      selectedCategory: props.selectedCategory,
    };
  }

  getCategories = () => {
    const { getAllCategories } = this.props;
    getAllCategories();
  };

  showSelf = () => {
    const { showModal, onClose, title, subtitle, selectedCategory, onConfirm } =
      this.props;
    showModal('assignCategory', {
      showModal,
      onClose,
      title,
      subtitle,
      selectedCategory,
      onConfirm,
    });
  };

  onClickDeleteCategory = (id) => {
    const { showModal, categories, removeCategory } = this.props;
    showModal('confirmation', {
      title: `${localeLookup('translations.Are you sure you want to delete')} ${
        categories[id]
      }?`,
      maxWidth: '500px',
      body: [
        <p className="confirmation-modal__body-text">
          {localeLookup('translations.Deletion of this category is permanent')}
        </p>,
        <p className="confirmation-modal__body-text">
          {localeLookup(
            'translations.The underlying knowledge areas will not be deleted, but will no longer have a category'
          )}
        </p>,
      ],
      confirmButtonText: localeLookup('translations.Delete'),
      confirmButtonType: 'alert',
      btnRejectTitle: localeLookup('translations.Cancel'),
      onCancel: this.showSelf,
      onConfirm: () => {
        deleteCategoryService(id).then(() => {
          removeCategory(id);
          this.showSelf();
        });
      },
    });
  };

  onChangeAddCategoryInput = (e) => {
    this.setState({
      addCategoryName: e.target.value,
    });
  };

  onSubmitAddCategory = (e) => {
    const { addCategoryName } = this.state;
    e.preventDefault();
    if (addCategoryName.length > 0) {
      createCategoryService(addCategoryName).then(() => {
        this.onBlurAddCategory();
        this.getCategories();
      });
    }
  };

  onBlurAddCategory = () => {
    this.setState({
      isAddingCategory: false,
      addCategoryName: '',
    });
  };

  onClickAddCategory = () => {
    this.setState({
      isAddingCategory: true,
    });
  };

  onSubmitRenameCategory = (id, newName) => {
    updateCategoryNameService(id, newName).then(() => {
      this.getCategories();
    });
  };

  onBlurRenameCategory = () => {
    this.setState({
      editingNameId: null,
    });
  };

  onClickRenameCategory = (id) => {
    this.setState({
      editingNameId: id,
    });
  };

  onChangeFilter = (e) => {
    this.setState({
      filterString: e.target.value,
      addCategoryName: e.target.value,
    });
  };

  onSelectCategory = (id) => {
    this.setState({
      selectedCategory: id,
    });
  };

  onConfirm = () => {
    const { selectedCategory } = this.state;
    const { onConfirm, onClose } = this.props;
    onConfirm(selectedCategory);
    onClose();
  };

  render() {
    const { categories, categoriesSortOrder, onClose, subtitle, title } =
      this.props;

    const {
      filterString,
      isAddingCategory,
      addCategoryName,
      selectedCategory,
      editingNameId,
    } = this.state;

    const visibleCategoriesKeys = categoriesSortOrder.filter((key) => {
      const category = categories[key];
      return category.toLowerCase().includes(filterString.toLowerCase());
    });

    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalFilter
          placeholder={`${localeLookup('translations.Search for category')}...`}
          onChange={this.onChangeFilter}
          value={filterString}
          onButtonClick={this.onClickAddCategory}
          buttonIcon="plus-circle"
          buttonText={localeLookup('translations.Create category')}
          showButton
        />
        <ModalBody deep>
          <ul className="box-list">
            {isAddingCategory && (
              <li className="box-list__item">
                <div className="organisation-unit-modal__organisation-unit-form">
                  <Input
                    autoFocus
                    kind="invisible"
                    className="organisation-unit-modal__organisation-unit-input"
                    type="text"
                    placeholder={localeLookup('translations.Name of category')}
                    defaultValue={filterString}
                    onChange={this.onChangeAddCategoryInput}
                    onBlur={this.onBlurAddCategory}
                    onEnterPress={this.onSubmitAddCategory}
                  />
                  <button
                    type="button"
                    onMouseDown={this.onSubmitAddCategory}
                    className={`ui-btn ui-btn-${
                      addCategoryName.length ? 'darkui' : 'inactive'
                    } ui-small organisation-unit-modal__organisation-unit-btn`}
                  >
                    {localeLookup('translations.Save')}
                  </button>
                </div>
              </li>
            )}
            <li className="box-list__item">
              <div className="categories-modal__body-categories-item-info">
                <p className="categories-modal__body-categories-item-name">
                  {localeLookup('translations.No category')}
                </p>
              </div>
              <div className="categories-modal__body-categories-item-icon-wrapper">
                <RadioButton
                  className="categories-modal__body-categories-item-radio-button"
                  id={EMPTY_ID}
                  name="category"
                  isChecked={selectedCategory === EMPTY_ID}
                  onChange={() => {
                    this.onSelectCategory(EMPTY_ID);
                  }}
                />
              </div>
            </li>
            {categoriesSortOrder &&
              visibleCategoriesKeys.map((id) => {
                const isSelected = selectedCategory === id;
                return (
                  <li key={id} className="box-list__item">
                    <div className="categories-modal__body-categories-item-info">
                      {editingNameId === id ? (
                        <InlineFieldEditor
                          autoFocus
                          classNameInput="ui-input-invisible categories-modal__body-categories-item-name-input"
                          defaultValue={categories[id]}
                          onBlur={this.onBlurRenameCategory}
                          onSubmit={(newName) => {
                            this.onSubmitRenameCategory(id, newName);
                          }}
                          placeholder={localeLookup(
                            'translations.Name of category'
                          )}
                        />
                      ) : (
                        <p
                          className="categories-modal__body-categories-item-name"
                          onClick={() => {
                            this.onClickRenameCategory(id);
                          }}
                        >
                          {categories[id]}
                          <Icon
                            kind="pencil"
                            className="categories-modal__body-categories-item-name-icon"
                          />
                        </p>
                      )}
                    </div>
                    <div
                      className="categories-modal__body-categories-item-icon-wrapper"
                      onClick={() => this.onClickDeleteCategory(id)}
                    >
                      <Icon
                        kind="trash2"
                        className="categories-modal__body-categories-item-delete-icon"
                      />
                    </div>
                    <div className="categories-modal__body-categories-item-icon-wrapper">
                      <RadioButton
                        className="categories-modal__body-categories-item-radio-button"
                        id={id}
                        name="category"
                        isChecked={isSelected}
                        onChange={() => {
                          this.onSelectCategory(id);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </ModalBody>
        <ModalFooter onConfirmClick={this.onConfirm} onCancelClick={onClose} />
      </ModalWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignCategoryModal);
