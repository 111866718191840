/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { Component, PureComponent } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EditorHeader } from '../../components/editor/EditorHeader';
import DesignboardRole from '../../components/designboard/DesignboardRole';
import DesignboardSidebar from '../../components/designboard/DesignboardSidebar';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithEditorActions from '../../components/HOC/withEditorActions';
import WithModals from '../../components/HOC/withModals';
import Icon from '../../components/Icon';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import { EMPTY_ID } from '../../constants';
import {
  getOpenRolesService,
  hideAllRolesService,
  hideRoleService,
  showRoleService,
} from '../../services/designboardService';
import {
  getAllAreas,
  queryAreas,
  removeArea,
  updateAreaElementsOrder,
} from '../../slices/areasSlice';
import { getAllCategories } from '../../slices/categoriesSlice';
import { getAreasAccess, getRolesAccess } from '../../slices/editorSlice';
import { queryElements, removeElement } from '../../slices/elementsSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { selectActivePersonsSortOrder } from '../../slices/personsSlice';
import {
  getAllRoles,
  queryRoles,
  removeRole,
  selectRolesSortOrder,
  updateRoleAreasOrder,
} from '../../slices/rolesSlice';
import {
  getActiveSpace,
  getAllSpaces,
  getSpaceStatus,
  setActiveSpace,
} from '../../slices/spaceSlice';
import { trackEvent } from '../../utils/tracking';
const mapStateToProps = (state) => {
  const {
    roles,
    areas,
    elements,
    organisationUnits,
    persons,
    categories,
    wildcardPersons,
    editor,
    user,
    spaces,
  } = state;
  return {
    allowChangeOfChampLinkVisibility:
      state.rootmenu.allowChangeOfChampLinkVisibility,
    roles,
    areas,
    elements,
    organisationUnits,
    roleSortOrder: selectRolesSortOrder(state),
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    persons,
    categories,
    wildcardPersons,
    accessibleRoles: editor.accessibleRoles,
    accessibleAreas: editor.accessibleAreas,
    currentUserId: user.employeeId,
    spaces: spaces.spaces,
    activeSpaceId: spaces.activeSpaceId,
    spacesEnabled: spaces.enabled,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getSpaceStatus,
      setActiveSpace,
      getActiveSpace,
      getAllAreas,
      getAllCategories,
      getAllOrganisationUnits,
      getAllRoles,
      getAllSpaces,
      queryRoles,
      queryAreas,
      queryElements,
      removeRole,
      updateRoleAreasOrder,
      removeArea,
      updateAreaElementsOrder,
      removeElement,
      getRolesAccess,
      getAreasAccess,
    },
    dispatch
  ),
});

class Designboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterString: '',
      isFullscreen: false,
      showFullscreenSidebar: false,
      openRoleIds: {},
      isLoading: true,
      isChangingSpace: false,
    };
  }

  async componentDidMount() {
    const {
      location: { state },
      setActiveSpace,
    } = this.props;
    trackEvent('$pageview');

    if (state?.rolesToOpen) {
      const roleId = state.rolesToOpen[0];
      const roleResponse = await this.getRole(roleId);
      const role = roleResponse[roleId];
      const roleSpaceId = role.space;
      await hideRoleService(roleId, roleSpaceId);
      await showRoleService(roleId, roleSpaceId);
      await setActiveSpace(roleSpaceId);
      this.getInitialData();
    } else {
      this.getInitialData();
    }
  }

  getDataForSpace = async (id) => {
    const { accessibleRoles, spaces } = this.props;
    const { openRoleIds } = this.state;
    const openRoleIdsInSpace = openRoleIds[id]?.roles || [];
    const openRoleIdsForCurrentSpaceWithAccess = openRoleIdsInSpace.filter(
      (roleId) => accessibleRoles.includes(roleId)
    );
    const isSpaceAccessible = spaces[id]?.accessible;

    // If the current active space is accessible we fetch the opened roles and underlying modules
    if (isSpaceAccessible) {
      if (openRoleIdsForCurrentSpaceWithAccess.length > 0) {
        await this.getRolesAndConnectedModules(
          openRoleIdsForCurrentSpaceWithAccess
        );
        this.setState({ isLoading: false, activeSpaceId: id });
      }
      this.setState({ isLoading: false, activeSpaceId: id });
    }
    // If the users active space is not accessible, we set the default space as the new room
    else {
      const defaultSpaceId = spaces[Object.keys(spaces)[0]]?.id;
      this.setState({ activeSpaceId: defaultSpaceId });
      await setActiveSpace(defaultSpaceId);
      this.getDataForSpace(id);
    }
  };

  getInitialData = async () => {
    const {
      getActiveSpace,
      setActiveSpace,
      getAllSpaces,
      getAllAreas,
      getAllRoles,
      getAllOrganisationUnits,
      getAllCategories,
      getRolesAccess,
      getAreasAccess,
      getSpaceStatus,
    } = this.props;
    const [activeSpaceId, spaces, openRolesResponse, rolesAccessResponse] =
      await Promise.all([
        getActiveSpace(),
        getAllSpaces(),
        getOpenRolesService(),
        getRolesAccess(),
        getAreasAccess(),
        getAllAreas(),
        getAllRoles(),
        getAllOrganisationUnits(),
        getAllCategories(),
        getSpaceStatus(),
      ]);
    // Object with space ids and open roleIds in the specific space
    const openRoleIds = openRolesResponse.data;
    // Array of roleIds which the current user has access to
    const roleIdsWithAccess = rolesAccessResponse.accessibleRoles;
    // Array of roleIds which the current user has opened in the current space (if any)
    const openRoleIdsForCurrentSpace = openRoleIds[activeSpaceId]?.roles || [];
    // Array of open roleIds removing roles without access
    const openRoleIdsForCurrentSpaceWithAccess =
      openRoleIdsForCurrentSpace.filter((roleId) =>
        roleIdsWithAccess.includes(roleId)
      );
    // Boolean wheather the current space is accessible
    const isSpaceAccessible = spaces[activeSpaceId]?.accessible;
    // If the current active space is accessible we fetch the opened roles and underlying modules
    if (isSpaceAccessible) {
      if (openRoleIdsForCurrentSpaceWithAccess.length > 0) {
        await this.getRolesAndConnectedModules(
          openRoleIdsForCurrentSpaceWithAccess
        );
        this.setState({ isLoading: false, activeSpaceId, openRoleIds });
      }
      this.setState({ isLoading: false, activeSpaceId, openRoleIds });
    }
    // If the users active space is not accessible, we set the default space as the new room
    else {
      const firstAccessibleSpace = Object.values(spaces).find(
        (space) => space.accessible
      );
      const defaultSpaceId = firstAccessibleSpace.id;
      this.setState({ activeSpaceId: defaultSpaceId, openRoleIds });
      await setActiveSpace(defaultSpaceId);
      this.getDataForSpace(defaultSpaceId);
    }
  };

  getOpenRoleIdsWithAccess = () => {
    const { openRoleIds, activeSpaceId } = this.state;
    const { accessibleRoles } = this.props;
    if (openRoleIds[activeSpaceId]?.roles) {
      return openRoleIds[activeSpaceId].roles.filter((id) =>
        accessibleRoles.includes(id)
      );
    }
    return [];
  };

  getRole = (id) => {
    const { queryRoles } = this.props;
    return queryRoles([id]);
  };

  getRolesAndConnectedModules = async (ids) => {
    const { queryRoles, queryAreas } = this.props;

    if (!ids) return;
    return queryRoles(ids).then((roles) => {
      const rolesAreaIds = Object.values(roles).reduce(
        (acc, role) => [...acc, ...role.areas, ...role.additionalAreas],
        []
      );
      const uniqueRoleAreaIds = [...new Set(rolesAreaIds)];
      if (uniqueRoleAreaIds.length > 0) {
        return queryAreas(uniqueRoleAreaIds);
      }
    });
  };

  getAreas = (ids) => {
    const { queryAreas } = this.props;
    return queryAreas(ids);
  };

  filterRoles = () => {
    const { organisationUnits, roles, roleSortOrder, accessibleRoles } =
      this.props;
    const { filterString, activeSpaceId } = this.state;
    return roleSortOrder.filter((id) => {
      const role = roles[id];
      if (!accessibleRoles.includes(id) || role.space !== activeSpaceId)
        return false;
      const organisationUnitNames = role.organisationUnits.map(
        (id) => organisationUnits[id].name
      );
      return `${role.name}${organisationUnitNames}`
        .toLowerCase()
        .includes(filterString.toLowerCase());
    });
  };

  onChangeAreaOrder = ({ roleId, isAdditional, index, moveToIndex }) => {
    const { editorActions } = this.props;
    editorActions.changeRoleAreasOrder({
      roleId,
      isAdditional,
      index,
      moveToIndex,
    });
  };

  onChangeElementOrder = ({ areaId, index, moveToIndex }) => {
    const { editorActions } = this.props;
    editorActions.changeAreaElementsOrder({ areaId, index, moveToIndex });
  };

  onChangeSpace = async (space) => {
    const { setActiveSpace } = this.props;
    this.setState({ isChangingSpace: true, activeSpaceId: space.id });
    await this.getDataForSpace(space.id);
    await setActiveSpace(space.id);
    this.setState({ isChangingSpace: false });
  };

  onClickHideAllRoles = () => {
    const { activeSpaceId } = this.state;
    hideAllRolesService(activeSpaceId).then(() => {
      this.setState({ openRoleIds: { [activeSpaceId]: { roles: [] } } });
    });
  };

  onClickToggleRoleVisiblity = async (roleId) => {
    const { openRoleIds, activeSpaceId } = this.state;
    const openRoleIdsForSpace = openRoleIds[activeSpaceId]?.roles || [];
    if (!openRoleIdsForSpace.includes(roleId)) {
      await showRoleService(roleId, activeSpaceId);
      await this.getRolesAndConnectedModules([roleId]);
      const openRolesResponse = await getOpenRolesService();
      this.setState({ openRoleIds: openRolesResponse.data });
    } else {
      await hideRoleService(roleId, activeSpaceId);
      const openRolesResponse = await getOpenRolesService();
      this.setState({ openRoleIds: openRolesResponse.data });
    }
  };

  onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) return;
    const [draggableType, roleId, areaId] = draggableId.split('_');
    const isDroppedAtSamePlace =
      destination.droppableId === source.droppableId &&
      destination.index === source.index;
    if (isDroppedAtSamePlace) return;
    if (draggableType === 'AREA') {
      this.onChangeAreaOrder({
        roleId,
        isAdditional: false,
        index: source.index,
        moveToIndex: destination.index,
      });
    }
    if (draggableType === 'ADDITIONALAREA') {
      this.onChangeAreaOrder({
        roleId,
        isAdditional: true,
        index: source.index,
        moveToIndex: destination.index,
      });
    }
    if (draggableType === 'ELEMENT') {
      this.onChangeElementOrder({
        areaId,
        index: source.index,
        moveToIndex: destination.index,
      });
    }
  };

  setFilterString = (e) => {
    this.setState({ filterString: e.target.value });
  };

  showConfirmRoleDeleteModal = (roleId) => {
    const { openRoleIds, activeSpaceId } = this.state;
    const { editorActions } = this.props;
    editorActions.showConfirmRoleDeleteModal(roleId, () => {
      this.setState({
        openRoleIds: {
          ...openRoleIds,
          [activeSpaceId]: {
            ...openRoleIds[activeSpaceId],
            roles: openRoleIds[activeSpaceId].roles.filter(
              (id) => id !== roleId
            ),
          },
        },
      });
    });
  };

  showCreateRoleModal = () => {
    const { persons, organisationUnits, showModal, hideModal, getRolesAccess } =
      this.props;
    showModal('createRole', {
      maxWidth: '500px',
      fullWidth: true,
      organisationUnits,
      onCreated: (id) => {
        hideModal();
        getRolesAccess();
        this.onClickToggleRoleVisiblity(id);
      },
    });
  };

  toggleFullscreen = () => {
    const { isFullscreen } = this.state;
    this.setState({
      isFullscreen: !isFullscreen,
      showFullscreenSidebar: false,
    });
  };

  toggleFullscreenSidebar = (e) => {
    const { showFullscreenSidebar } = this.state;
    e.stopPropagation();
    this.setState({
      showFullscreenSidebar: !showFullscreenSidebar,
    });
  };

  render() {
    const {
      spacesEnabled,
      allowChangeOfChampLinkVisibility,
      categories,
      organisationUnits,
      persons,
      roles,
      wildcardPersons,
      areas,
      spaces,
      elements,
      editorActions,
    } = this.props;
    const {
      isFullscreen,
      showFullscreenSidebar,
      isLoading,
      openRoleIds,
      activeSpaceId,
      isChangingSpace,
    } = this.state;
    if (isLoading) return <LoadScreen />;
    return (
      <DragDropContext nonce={cspNonce} onDragEnd={this.onDragEnd}>
        <EditorHeader
          spacesEnabled={spacesEnabled}
          fixed={isFullscreen}
          activeSpaceId={activeSpaceId}
          onChangeSpace={this.onChangeSpace}
          spaces={spaces}
          isFullscreen={isFullscreen}
          onToggleFullscreen={this.toggleFullscreen}
        />

        <Page fullScreen={isFullscreen}>
          <DesignboardSidebar
            activeSpaceId={activeSpaceId}
            hidden={isFullscreen && !showFullscreenSidebar}
            filteredRoles={this.filterRoles()}
            onChangeFilterString={this.setFilterString}
            onCreateRoleClick={this.showCreateRoleModal}
            onClickToggleRoleVisiblity={this.onClickToggleRoleVisiblity}
            onClickHideAllRoles={this.onClickHideAllRoles}
            organisationUnits={organisationUnits}
            roles={roles}
            selectedRoles={openRoleIds[activeSpaceId]?.roles || []}
          />

          {isFullscreen && (
            <div
              className={`main-aside__toggle-show ${cx({
                'main-aside__toggle-show--collapsed': !showFullscreenSidebar,
              })}`}
              onClick={this.toggleFullscreenSidebar}
            >
              <Icon className="main-aside__toggle-show-icon" kind="menu" />
            </div>
          )}
          <MainArea horizontalScroll backgroundColor="grey">
            {!isChangingSpace && (
              <div
                className={cx('designboard', {
                  'designboard--fullscreen': isFullscreen,
                })}
              >
                <div className="designboard__roles">
                  {roles &&
                    this.getOpenRoleIdsWithAccess()
                      .slice()
                      .reverse()
                      .map((key, i) => {
                        if (roles[key]) {
                          return (
                            <DesignboardRole
                              allowChangeOfChampLinkVisibility={
                                allowChangeOfChampLinkVisibility
                              }
                              categories={categories}
                              key={key}
                              knowledgeAreas={areas}
                              knowledgeElements={elements}
                              onClickHideRole={this.onClickToggleRoleVisiblity}
                              organisationUnits={organisationUnits}
                              persons={persons}
                              role={roles[key]}
                              wildcardPersons={wildcardPersons}
                              zIndex={
                                this.getOpenRoleIdsWithAccess().length - i
                              }
                              onClickDeleteRole={
                                this.showConfirmRoleDeleteModal
                              }
                              onClickChangeRoleEditors={
                                editorActions.showChangeRoleEditorsModal
                              }
                              onClickChangeAreaEditors={
                                editorActions.showChangeAreaEditorsModal
                              }
                              onClickAddExpert={
                                editorActions.showChangeAreaExpertsModal
                              }
                              onClickAddArea={
                                editorActions.showChangeRoleAreasModal
                              }
                              onClickChangeCategory={
                                editorActions.showChangeAreaCategoryModal
                              }
                              onClickDeleteArea={
                                editorActions.showConfirmAreaDeleteModal
                              }
                              onClickDeleteElement={
                                editorActions.showConfirmElementDeleteModal
                              }
                              onClickEditElementDescription={
                                editorActions.showChangeElementDescriptionModal
                              }
                              onClickEditElementResponsible={
                                editorActions.showChangeElementResponsibleModal
                              }
                              onClickMoveElement={
                                editorActions.showChangeElementLocationModal
                              }
                              onClickToggleAreaLinkVisiblity={
                                editorActions.showToggleAreaLinkVisiblityModal
                              }
                              onClickToggleElementLinkVisiblity={
                                editorActions.showToggleElementLinkVisiblityModal
                              }
                              onClickEditRoleDescription={
                                editorActions.showChangeRoleDescriptionModal
                              }
                              onClickEditRoleOrganisationUnits={
                                editorActions.showChangeOrganisationUnitModal
                              }
                              onClickEditAreaOwner={
                                editorActions.showChangeAreaOwnerModal
                              }
                              onClickEditRoleOwner={
                                editorActions.showChangeRoleOwnerModal
                              }
                              spaces={spaces}
                            />
                          );
                        }
                        return null;
                      })}
                </div>
              </div>
            )}
          </MainArea>
        </Page>
      </DragDropContext>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(withAccessControl(WithEditorActions(Designboard))));
