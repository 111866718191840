import {
  fetchTraineeRoleAreaData,
  getKnowledgeElementService,
  getTraineeAreaService,
  updateAreaLinkState,
  updateTraineeAreaStatus,
  updateTraineeAreaViewState,
} from '../services/traineeService';

export const TRAINEE_UPDATED_AREA_DATA_RECEIVED = 'TRAINEE_UPDATED_AREA_DATA_RECEIVED';
export const traineeUpdatedAreaDataReceived = (data) => ({
  type: TRAINEE_UPDATED_AREA_DATA_RECEIVED,
  data,
});

export const TRAINEE_AREA_RECIEVED = 'TRAINEE_AREA_RECIEVED';
export const traineeAreaReceived = ({ area, areaId, roleId, completionPercentage }) => ({
  type: TRAINEE_AREA_RECIEVED,
  area,
  areaId,
  roleId,
  completionPercentage,
});

export const UPDATED_AREA_STATUS_DATA_RECEIVED = 'UPDATED_AREA_STATUS_DATA_RECEIVED';
export const updatedAreaStatusDataReceived = (roleId, areaId, data) => ({
  type: UPDATED_AREA_STATUS_DATA_RECEIVED,
  data,
  areaId,
  roleId,
});

export const updateKnowledgeAreaStatus = (roleId, areaId, status) => (dispatch) => {
  const put = updateTraineeAreaStatus(roleId, areaId, {
    Status: status,
  });
  put
    .then((data) => {
      dispatch(updatedAreaStatusDataReceived(roleId, areaId, data.data));
    })
    .catch(() => {
      console.log('failed updating status');
    });
};

export const TRAINEE_ELEMENT_RECIEVED = 'TRAINEE_ELEMENT_RECIEVED';
export const traineeElementRecieved = (data, elementId) => ({
  type: TRAINEE_ELEMENT_RECIEVED,
  data,
  elementId,
});

export const UPDATE_AREA_NOTIFICATION_COUNT = 'UPDATE_AREA_NOTIFICATION_COUNT';
export const updateAreaNotificationCount = (areaId, count) => ({
  type: UPDATE_AREA_NOTIFICATION_COUNT,
  count,
  areaId,
});

export const updateArea =
  ({ roleId, areaId }) =>
  (dispatch) =>
    getTraineeAreaService(roleId, areaId)
      .then(({ data }) => {
        dispatch(
          traineeAreaReceived({ areaId: data.knowledgeArea.id, area: data.knowledgeArea, completionPercentage: data.completionPercentage, roleId })
        );
      })
      .catch((ex) => {
        console.log(ex);
      });

export const updateKnowledgeElement = (personId, elementId) => (dispatch) =>
  getKnowledgeElementService(personId, elementId)
    .then((response) => dispatch(traineeElementRecieved({ updatedKnowledgeElement: response.data.knowledgeElements }, elementId)))
    .catch((ex) => {
      console.log(ex);
    });

export const updateTraineeRoleArea = (roleId, areaId) => (dispatch) => {
  fetchTraineeRoleAreaData(roleId, areaId)
    .then((data) => {
      dispatch(traineeUpdatedAreaDataReceived(data.data));
    })
    .catch(() => {
      console.log('failed fetching trainee area data');
    });
};

export const setTraineeAreaViewState = (roleId, areaId) => () => {
  updateTraineeAreaViewState(roleId, areaId).catch(() => {
    console.log('failed updating trainee area view state');
  });
};

export const changeAreaLinkState = (roleId, areaId, linkToEmployee) => (dispatch) =>
  updateAreaLinkState(roleId, areaId, {
    linkToEmployee,
  })
    .then(({ data }) => {
      dispatch(traineeAreaReceived({ area: data.updatedKnowledgeArea[areaId], areaId, roleId, completionPercentage: data.completionPercentage }));
    })
    .catch(() => {
      console.log('failed updating status');
    });
