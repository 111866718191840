import React, { forwardRef } from 'react';
import { Formik } from 'formik';

const FormWrapper = React.forwardRef(function FormWrapper(
  {
    children,
    onSubmit,
    initialValues,
    validationSchema,
    className,
    initialTouched,
    validateOnMount,
    validateOnChange
  },
  ref
) {
  return (
    <Formik
      innerRef={ref}
      className={className}
      validateOnMount={validateOnMount}
      initialTouched={initialTouched}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {(props) => children(props)}
    </Formik>
  );
});

export default FormWrapper;
