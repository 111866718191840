import React from 'react';
import cx from 'classnames';

export const Header = ({
  leftComponent,
  rightComponent,
  leftComponentContainerClass,
  rightComponentContainerClass,
  className
}) => {
  return (
    <div className={cx('header', className)}>
      <div className={cx('header__left', leftComponentContainerClass)}>
        {leftComponent}
      </div>
      <div className={cx('header__right', rightComponentContainerClass)}>
        {rightComponent}
      </div>
    </div>
  );
};
