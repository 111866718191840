// Constants
import {
  SYSTEM_SETTINGS_DATA_PENDING,
  SYSTEM_SETTINGS_RECIEVED,
  PERMISSIONS_RECEIVED,
} from '../actions/systemSettingsActions';

const systemSettingsInitialState = {};

const systemSettings = (state = systemSettingsInitialState, action) => {
  switch (action.type) {
    case SYSTEM_SETTINGS_DATA_PENDING:
      return {
        ...state,
        isFetchingSettings: true,
      };
    case SYSTEM_SETTINGS_RECIEVED:
      return {
        ...state,
        organisationSettings: {
          ...state.organisationSettings,
          ...action.data,
        },
        isFetchingSettings: false,
      };
    case PERMISSIONS_RECEIVED:
      return {
        ...state,
        permissions: action.data.permissions,
        permissionGroups: action.data.permissionGroups
      };
    default:
      return state;
  }
};

export default systemSettings;
