import React from 'react';
import FormModal from './FormModal';
import UserTypeForm from '../UserTypeForm';
import ModalFooter from './ModalFooter';

const UserTypeModal = ({
  activeStepIndex,
  confirmButtonText,
  initialValues,
  onClose,
  onSubmit,
  steps,
  title,
  onCancelClick,
  isEditingLogin,
}) => {
  return (
    <FormModal onClose={onClose} title={title} steps={steps} activeStepIndex={activeStepIndex}>
      <UserTypeForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        isEditingLogin={isEditingLogin}
        confirmButtonText={confirmButtonText}
        renderFooter={({ handleSubmit, canSubmit, submitButtonText }) => (
          <ModalFooter
            confirmButtonText={submitButtonText}
            onConfirmClick={handleSubmit}
            onCancelClick={onCancelClick}
            confirmDisabled={!canSubmit}
          />
        )}
      />
    </FormModal>
  );
};

export default UserTypeModal;
