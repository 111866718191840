import { TRAINING_REGISTRATION_TYPES } from '../constants';
import {
  updateMenteesElementMediatorsService,
  updateMenteesElementStatusesService,
} from '../services/roleService';
import {
  createUserFilterService,
  deleteUserFilterService,
  getAllPersonsElementStatusService,
  getFiltersService,
  getOrganisationUnitsElementStatusService,
  getPersonsElementStatusService,
  getUserFiltersService,
  updateUserFilterService,
} from '../services/statusTableService';
import { registerBulkTrainingService } from '../services/trainingService';

export const STATUS_TABLE_CATEGORIES_RECEIVED =
  'STATUS_TABLE_CATEGORIES_RECEIVED';
export const statusTableCategoriesReceived = (categories) => ({
  type: STATUS_TABLE_CATEGORIES_RECEIVED,
  categories,
});
export const STATUS_TABLE_KNOWLEDGE_AREAS_RECEIVED =
  'STATUS_TABLE_KNOWLEDGE_AREAS_RECEIVED';
export const statusTableknowledgeAreasReceived = (knowledgeAreas) => ({
  type: STATUS_TABLE_KNOWLEDGE_AREAS_RECEIVED,
  knowledgeAreas,
});
export const STATUS_TABLE_KNOWLEDGE_ELEMENTS_RECEIVED =
  'STATUS_TABLE_KNOWLEDGE_ELEMENTS_RECEIVED';
export const statusTableKnowledgeElementsReceived = (knowledgeElements) => ({
  type: STATUS_TABLE_KNOWLEDGE_ELEMENTS_RECEIVED,
  knowledgeElements,
});
export const STATUS_TABLE_ORGANISATION_UNITS_RECEIVED =
  'STATUS_TABLE_ORGANISATION_UNITS_RECEIVED';
export const statusTableOrganisationUnitsReceived = (organisationUnits) => ({
  type: STATUS_TABLE_ORGANISATION_UNITS_RECEIVED,
  organisationUnits,
});
export const STATUS_TABLE_ORGANISATION_UNIT_ROOT_NOTES_RECEIVED =
  'STATUS_TABLE_ORGANISATION_UNIT_ROOT_NOTES_RECEIVED';
export const statusTableOrganisationUnitRootNotesReceived = (
  organisationUnitRootNodes
) => ({
  type: STATUS_TABLE_ORGANISATION_UNIT_ROOT_NOTES_RECEIVED,
  organisationUnitRootNodes,
});
export const STATUS_TABLE_PERSONS_RECEIVED = 'STATUS_TABLE_PERSONS_RECEIVED';
export const statusTablePersonsReceived = (persons) => ({
  type: STATUS_TABLE_PERSONS_RECEIVED,
  persons,
});
export const STATUS_TABLE_PERSONS_ELEMENT_STATUS_RECEIVED =
  'STATUS_TABLE_PERSONS_ELEMENT_STATUS_RECEIVED';
export const statusTablePersonsElementStatusReceived = (persons) => ({
  type: STATUS_TABLE_PERSONS_ELEMENT_STATUS_RECEIVED,
  persons,
});
export const STATUS_TABLE_PRESET_FILTERS_RECEIVED =
  'STATUS_TABLE_PRESET_FILTERS_RECEIVED';
export const statusTablePresetFiltersReceived = (presetFilters) => ({
  type: STATUS_TABLE_PRESET_FILTERS_RECEIVED,
  presetFilters,
});
export const STATUS_TABLE_ROLES_RECEIVED = 'STATUS_TABLE_ROLES_RECEIVED';
export const statusTableRolesReceived = (roles) => ({
  type: STATUS_TABLE_ROLES_RECEIVED,
  roles,
});
export const STATUS_TABLE_WILDCARD_PERSONS_RECEIVED =
  'STATUS_TABLE_WILDCARD_PERSONS_RECEIVED';
export const statusTableWildcardPersonsReceived = (wildcardPersons) => ({
  type: STATUS_TABLE_WILDCARD_PERSONS_RECEIVED,
  wildcardPersons,
});

export const getUserFilters = () => (dispatch) =>
  getUserFiltersService()
    .then(({ data }) => {
      dispatch(statusTablePresetFiltersReceived(data.presetFilters));
    })
    .catch((err) => {
      console.log('catch', err);
    });

export const createUserFilter = (filter) => (dispatch) =>
  createUserFilterService(filter)
    .then(() => {
      dispatch(getUserFilters());
    })
    .catch((err) => {
      console.log('catch', err);
    });

export const deleteUserFilter = (id) => (dispatch) =>
  deleteUserFilterService(id)
    .then(() => {
      dispatch(getUserFilters());
    })
    .catch((err) => {
      console.log('catch', err);
    });

export const getFilters = () => (dispatch) =>
  getFiltersService()
    .then(({ data }) => {
      dispatch(statusTablePersonsReceived(data.employees));
      dispatch(statusTableKnowledgeElementsReceived(data.knowledgeElements));
      dispatch(statusTableknowledgeAreasReceived(data.knowledgeAreas));
      dispatch(statusTableRolesReceived(data.roles));
      dispatch(statusTableOrganisationUnitsReceived(data.organisationUnits));
      dispatch(
        statusTableOrganisationUnitRootNotesReceived(
          data.organisationUnitRootNodes
        )
      );
      dispatch(statusTableCategoriesReceived(data.categories));
      dispatch(statusTableWildcardPersonsReceived(data.wildcardPersons));
      dispatch(statusTablePresetFiltersReceived(data.presetFilters));
      return data;
    })
    .catch((ex) => {
      console.error(ex);
    });

// Single person
export const getLimitedPersonsElementStatus = (persons) => (dispatch) =>
  getPersonsElementStatusService({ persons })
    .then(({ data }) => {
      dispatch(statusTablePersonsElementStatusReceived(data.persons));
      return data;
    })
    .catch((ex) => {
      console.error(ex);
    });

// Organisation unit
export const getOrganisationUnitsElementStatus = (unitIds) => (dispatch) => {
  return getOrganisationUnitsElementStatusService(unitIds)
    .then(({ data }) => {
      return dispatch(statusTablePersonsElementStatusReceived(data.persons));
    })
    .catch((err) => {
      const error = new Error(err);
      error.response = err.response;
      throw error;
    });
};
// All
export const getPersonsElementStatus = () => (dispatch) =>
  getAllPersonsElementStatusService()
    .then(({ data }) => {
      return dispatch(statusTablePersonsElementStatusReceived(data.persons));
    })
    .catch((ex) => {
      console.error(ex);
    });

export const updateElementsStatus =
  (personElements, allKnowledgeElements) => (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      const elements = personElements[personId].map((elementId) => ({
        menteeId: personId,
        knowledgeElementId: elementId,
        knowledgeAreaId:
          allKnowledgeElements[elementId].connections.knowledgeArea,
        isCompleted: true,
      }));

      return [...acc, ...elements];
    }, []);

    return updateMenteesElementStatusesService({ elements: data }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };

export const addElementsAdminApproval =
  ({ personElements, note, validFromDate }) =>
  (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      return [
        ...acc,
        { menteeId: personId, elements: personElements[personId] },
      ];
    }, []);

    return registerBulkTrainingService({
      type: TRAINING_REGISTRATION_TYPES.ADMIN_APPROVAL,
      note,
      validFromDate,
      operations: data,
    }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };
export const completeElements =
  ({ personElements }) =>
  (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      return [
        ...acc,
        { menteeId: personId, elements: personElements[personId] },
      ];
    }, []);
    return registerBulkTrainingService({
      type: TRAINING_REGISTRATION_TYPES.STANDARD,
      operations: data,
    }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };

export const addElementsStatusReset =
  ({ personElements, note }) =>
  (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      return [
        ...acc,
        { menteeId: personId, elements: personElements[personId] },
      ];
    }, []);

    return registerBulkTrainingService({
      type: TRAINING_REGISTRATION_TYPES.RESET_STATUS,
      note,
      operations: data,
    }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };
export const addElementsAdminStatusReset =
  ({ personElements, note, validFromDate }) =>
  (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      return [
        ...acc,
        { menteeId: personId, elements: personElements[personId] },
      ];
    }, []);

    return registerBulkTrainingService({
      type: TRAINING_REGISTRATION_TYPES.ADMIN_RESET_STATUS,
      note,
      validFromDate,
      operations: data,
    }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };

export const updateElementsMediator =
  ({ personElements, allKnowledgeElements, mediator }) =>
  (dispatch) => {
    const personIds = Object.keys(personElements);
    const data = personIds.reduce((acc, personId) => {
      const elements = personElements[personId].map((elementId) => ({
        menteeId: personId,
        knowledgeElementId: elementId,
        knowledgeAreaId:
          allKnowledgeElements[elementId].connections.knowledgeArea,
        mediatorId: mediator,
      }));

      return [...acc, ...elements];
    }, []);

    return updateMenteesElementMediatorsService({ elements: data }).then(() => {
      dispatch(getLimitedPersonsElementStatus(personIds));
    });
  };

export const updateUserFilter = (id, filter) => (dispatch) =>
  updateUserFilterService(id, filter)
    .then(() => {
      dispatch(getUserFilters());
    })
    .catch((err) => {
      console.log('catch', err);
    });
