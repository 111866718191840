import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const fetchSystemSettings = () => {
  const url = `/api/systemsettings`;
  return axiosRequestGET(url);
};

export const fetchPermissions = () => {
  return axiosRequestGET(`/api/organisation/permissions`);
};

export const updateOrganisationName = (updateData) => {
  return axiosRequestPUT(`/api/systemsettings/organisationname`, updateData);
};

export const updateOrganisationLanguage = (updateData) => {
  const url = `/api/systemsettings/language`;
  return axiosRequestPUT(url, updateData);
};

export const updatePermission = (updateData) => {
  const url = `/api/organisation/permissions/update`;
  return axiosRequestPUT(url, updateData);
};

export const updateAzureADAdminConsentService = (data) => {
  return axiosRequestPUT('/api/systemsettings/azuread/adminconsent', data);
};

export const updateSecret = () => {
  const url = `/api/organisation/permissions/rotate-api-secret`;
  return axiosRequestPUT(url, {});
};

export const updateOrganisationTimeZoneService = (timeZone) =>
  axiosRequestPUT('/api/systemsettings/time-zone', { timeZone });

export const updateSupportAccessLevelsService = (accessLevels = []) =>
  axiosRequestPUT('/api/systemsettings/support-chat-accesslevels', {
    accessLevels,
  });
