import React from 'react';
import Icon from './Icon';

const DrawerOption = ({ text, icon = 'book', onClick }) => {
  return (
    <button className="drawer-option" onClick={onClick}>
      <Icon kind={icon} className="drawer-option__icon" />
      <p className="drawer-option__text">{text}</p>
    </button>
  );
};

export default DrawerOption;
