import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { createSpaceService } from '../services/contentService';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import withAccessControl from './HOC/withAccessControl';
import ModalBody from './modal/ModalBody';
import { createGroupService } from '../services/groupService';
import { ACCESS_LEVELS, PERSON_STATES } from '../constants';
import BoxMessage from './BoxMessage';

import MultiSelect from './MultiSelect';
import { compareLocal, sortBy } from '../utils/helpers';
import Field from './formElements/Field';

const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
  persons: state.persons,
  groups: state.groups,
});

const mapDispatchToProps = {};

class CreateGroupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  canEditAfterCreation = (values) => {
    const { currentUserId, hasAccess } = this.props;
    const hasAccessRoles = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.groupAdministrator,
    ]);

    if (hasAccessRoles) return true;
    if (values.administrators.includes(currentUserId)) return true;
    return false;
  };

  onSubmit = (values) => {
    const { onCreated } = this.props;
    this.setState({ isSubmitting: true });
    const { name, shortName, administrators } = values;
    createGroupService({
      name,
      shortName,
      assignableAs: ['Mentor'],
      administrators,
    })
      .then(({ data: id }) => {
        onCreated(id);
      })
      .catch(() => {});
  };

  render() {
    const { isSubmitting } = this.state;
    const { persons, renderFooter, initialName, hasAccess, currentUserId } =
      this.props;

    const administratorOptions = [
      ...sortBy(
        Object.keys(persons)
          .filter((id) => persons[id].state === PERSON_STATES.ACTIVE)
          .map((id) => {
            const person = persons[id];
            return {
              label: persons[id].name,
              value: id,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            };
          }),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
    ];

    const isAdmin = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.groupAdministrator,
    ]);

    const currentUserOption = isAdmin
      ? null
      : administratorOptions.find((person) => person.value === currentUserId);

    const defaultAdministratorsValue = isAdmin ? [] : [currentUserId];

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
      shortName: Yup.string().required(
        localeLookup('translations.Short name is required')
      ),
    });

    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }
      return true;
    };

    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          name: initialName,
          shortName: '',
          administrators: defaultAdministratorsValue,
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <>
            {!this.canEditAfterCreation(values) && (
              <BoxMessage type="warning" spacing="md" icon="warning">
                {localeLookup(
                  'translations.You have to be group administrator to access this group after creation'
                )}
              </BoxMessage>
            )}
            <ModalBody>
              <form
                className="create-group-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  required
                  autoFocus
                  placeholder={localeLookup('translations.Name')}
                  name="name"
                  id="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.name && errors.name}
                  value={values.name}
                  label={localeLookup('translations.Name')}
                />
                <TextField
                  autoComplete="off"
                  name="shortName"
                  id="shortName"
                  error={touched.shortName && errors.shortName}
                  onBlur={handleBlur}
                  value={values.shortName}
                  maxLength={5}
                  onChange={(e) => {
                    let value = e.target.value || '';
                    value = value.toUpperCase().trim();
                    setFieldValue('shortName', value);
                  }}
                  required
                  placeholder={localeLookup('translations.Short name')}
                  label={localeLookup('translations.Short name')}
                />
                <Field
                  label={localeLookup('translations.Group administrators')}
                >
                  <MultiSelect
                    onChange={(values) => {
                      setFieldValue(
                        'administrators',
                        values.map((value) => value.value)
                      );
                    }}
                    defaultValue={[currentUserOption]}
                    name="editors"
                    filterOption={filterOption}
                    options={administratorOptions}
                    placeholder={localeLookup(
                      'translations.Select administrators'
                    )}
                  />
                </Field>
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(withAccessControl(CreateGroupForm));
