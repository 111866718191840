import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import icons from '../../assets/icons/symbol-defs.svg';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';

class Icon extends PureComponent {
  render() {
    const { kind, size, className, onClick, isButton, color, style, tooltip } = this.props;
    const uiSizeClass = size ? `ui-${size}` : 'ui-medium';
    const colorClass = color ? `icon--color-${color}` : '';

    return (
      <ConditionalWrap
        condition={tooltip}
        wrap={(children) => (
          <Tooltip tooltip={tooltip}>
            <span>{children}</span>
          </Tooltip>
        )}
      >
        <ConditionalWrap
          condition={isButton}
          wrap={(children) => (
            <button className="icon-button-wrapper" onClick={onClick}>
              {children}
            </button>
          )}
        >
          <svg className={cx('icon', `icon-${kind}`, uiSizeClass, colorClass, className)}>
            <use xlinkHref={`${icons}#icon-${kind}`} />
          </svg>
        </ConditionalWrap>
      </ConditionalWrap>
    );
  }
}

Icon.propTypes = {
  kind: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Icon;
