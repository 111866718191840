import React from 'react';
import cx from 'classnames';
import localeLookup from '../config/locale';
import Text from './Text';
import { ORGANISATION_UNIT_STATES } from '../constants';

const EditorOrganisationTree = ({
  allOrganisationUnits,
  organisationUnit,
  onUnitSelect,
  selectedUnitId,
  isChild = false,
  getOrganisationUnitCount,
}) => {
  if (!organisationUnit) return null;
  const { name, descendants, id, state } = organisationUnit;
  const isVisibilityLimited =
    organisationUnit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
    organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;
  return (
    <li
      className={`organisation-tree__item ${
        isChild ? 'organisation-tree__item--child' : ''
      }`}
    >
      <div
        className={
          'organisation-tree__item-wrapper ' +
          cx({ selected: id === selectedUnitId })
        }
        onClick={() => {
          onUnitSelect(id, name);
        }}
      >
        <p className="organisation-tree__item-text">
          <p>
            <span className="organisation-tree__item-text-unitname">
              {name}
            </span>
            <span className="organisation-tree__item-text-count">
              ({getOrganisationUnitCount(id)})
            </span>
          </p>
          {isVisibilityLimited && (
            <Text color="dark-grey" size="sm">
              {localeLookup('translations.Limited visibility')}
            </Text>
          )}
        </p>
      </div>
      {descendants &&
        descendants.map((key) => (
          <ul className="organisation-tree__list" key={key}>
            <EditorOrganisationTree
              allOrganisationUnits={allOrganisationUnits}
              organisationUnit={allOrganisationUnits[key]}
              onUnitSelect={onUnitSelect}
              selectedUnitId={selectedUnitId}
              isChild={true}
              getOrganisationUnitCount={getOrganisationUnitCount}
            />
          </ul>
        ))}
    </li>
  );
};

export default EditorOrganisationTree;
