import {
  SYSTEM_ALLOWED_MIME_TYPES_RECEIVED,
  SYSTEM_BLOCK_UI_OFF,
  SYSTEM_BLOCK_UI_ON,
  SYSTEM_HIDE_CONTEXT_MENU,
  SYSTEM_HIDE_LOAD_OVERLAY,
  SYSTEM_SHOW_CONTEXT_MENU,
  SYSTEM_SHOW_LOAD_OVERLAY,
  SYSTEM_TOGGLE_MENU,
} from '../actions/systemActions';

const initialState = {
  menuOpen: false,
  blockUI: false,
  modal: {
    modalType: null,
    modalProps: {},
    show: false,
  },
  allowedFileMimeTypes: {},
  showLoadOverlay: false,
  contextMenuOpen: false,
};

const system = function (state = initialState, action) {
  switch (action.type) {
    case SYSTEM_ALLOWED_MIME_TYPES_RECEIVED:
      return {
        ...state,
        allowedFileMimeTypes: {
          ...state.allowedFileMimeTypes,
          ...action.mimeTypes,
        },
      };
    case SYSTEM_HIDE_LOAD_OVERLAY:
      return {
        ...state,
        showLoadOverlay: false,
      };
    case SYSTEM_SHOW_LOAD_OVERLAY:
      return {
        ...state,
        showLoadOverlay: true,
      };
    case SYSTEM_SHOW_CONTEXT_MENU:
      return {
        ...state,
        contextMenuOpen: true,
      };
    case SYSTEM_HIDE_CONTEXT_MENU:
      return {
        ...state,
        contextMenuOpen: false,
      };
    case SYSTEM_TOGGLE_MENU:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case SYSTEM_BLOCK_UI_ON:
      return {
        ...state,
        blockUI: true,
      };
    case SYSTEM_BLOCK_UI_OFF:
      return {
        ...state,
        blockUI: false,
      };

    default:
      return state;
  }
};

export const systemSelector = {
  get(state) {
    return state.system;
  },
  menuOpen(state) {
    return state.system.menuOpen;
  },
  blockUI(state) {
    return state.system.blockUI;
  },
};

export default system;
