import {
  axiosRequestPOST,
  axiosRequestGET,
  axiosRequestPUT,
  axiosRequestDELETE,
} from '../services/serviceConfig';

export const createUserFilterService = (filter) =>
  axiosRequestPOST('/api/organisation/elements/presetFilter/create', filter);

export const getFiltersService = () =>
  axiosRequestGET('/api/organisation/elements');

export const deleteUserFilterService = (id) =>
  axiosRequestDELETE(`/api/organisation/elements/presetFilter/${id}/delete`);

export const getAllPersonsElementStatusService = () =>
  axiosRequestGET('/api/organisation/elements/all');

export const getOrganisationUnitsElementStatusService = (unitIds) =>
  axiosRequestPOST('/api/organisation/elements/all', {
    organisationUnits: unitIds,
  });

export const getPersonsElementStatusService = (persons) =>
  axiosRequestPOST('/api/organisation/elements', persons);

export const getUserFiltersService = () =>
  axiosRequestGET('/api/organisation/elements/presetFilter');

export const updateUserFilterService = (id, filter) =>
  axiosRequestPUT(
    `/api/organisation/elements/presetFilter/${id}/update`,
    filter
  );

export const getTaskPersonsElementStatusService = () =>
  axiosRequestGET('/api/organisation/elements/personally');

export const checkForLargeDataSetService = (unitIds) => {
  return axiosRequestPOST('/api/organisation/elements/warning', {
    organisationUnits: unitIds,
  });
};
