import PropTypes from 'prop-types';
import React from 'react';
import DashboardKnowledgeArea from './DashboardKnowledgeAreaV2';

const RoleDashboardPerson = ({
  additionalKnowledgeAreasSortOrder,
  allPersons,
  canSelectElements,
  expandedAreaId,
  id,
  knowledgeAreas,
  knowledgeAreasSortOrder,
  knowledgeElements,
  mentorId,
  onChangeElementStatus,
  onClickAdditionalArea,
  onClickSelectElement,
  personKnowledgeAreas,
  personKnowledgeElements,
  roleId,
  selectedElements,
  showResponsibles,
  wildcardPersons,
  onClickSubmitChangeSingleMediator
}) => {
  const renderKnowledgeArea = ({ areaId, isAdditionalArea }) => {
    const area = knowledgeAreas[areaId];
    const personArea = personKnowledgeAreas[areaId];
    return (
      <DashboardKnowledgeArea
        allPersons={allPersons}
        area={area}
        expandedAreaId={expandedAreaId}
        id={areaId}
        isAdditionalArea={isAdditionalArea}
        key={areaId}
        knowledgeElements={knowledgeElements}
        mentorId={mentorId}
        personArea={personArea}
        personId={id}
        personKnowledgeElements={personKnowledgeElements}
        roleId={roleId}
        wildcardPersons={wildcardPersons}
        onChangeElementStatus={onChangeElementStatus}
        onClickAdditionalArea={onClickAdditionalArea}
        canSelectElements={canSelectElements}
        onClickSelectElement={onClickSelectElement}
        selectedElements={selectedElements}
        showResponsibles={showResponsibles}
        onClickSubmitChangeSingleMediator={onClickSubmitChangeSingleMediator}
      />
    );
  };
  return (
    <div className="dashboard-person">
      {knowledgeAreasSortOrder.map((id) => renderKnowledgeArea({ areaId: id, isAdditionalArea: false }))}
      {additionalKnowledgeAreasSortOrder.length > 0 && (
        <div className="dashboard-person__additional-areas">
          {additionalKnowledgeAreasSortOrder.map((id) => renderKnowledgeArea({ areaId: id, isAdditionalArea: true }))}
        </div>
      )}
    </div>
  );
};

RoleDashboardPerson.propTypes = {
  additionalKnowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedAreaId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  knowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  knowledgeAreas: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  knowledgeElements: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  mentorId: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  wildcardPersons: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeElementStatus: PropTypes.func.isRequired,
  onClickSelectElement: PropTypes.func.isRequired,
  canSelectElements: PropTypes.bool.isRequired,
};

export default RoleDashboardPerson;
