/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import BoxMessage from './BoxMessage';
import Column from './Column';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import Row from './Row';
import {
  createUserWithoutLoginService,
  createUserWithLoginService,
  createExternalUserService,
  validateEmployeeNumberService,
  validatePasswordService,
} from '../services/personsService';
import { EMAIL_REGEX } from '../constants';
import { bindActionCreators } from 'redux';
import { getPersons } from '../slices/personsSlice';
import { connect } from 'react-redux';
import ModalBody from './modal/ModalBody';
import { PasswordScoreBar } from './PasswordScoreBar';
import { PasswordRequirementsChecker } from './PasswordRequirementsChecker';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getPersons }, dispatch);

class CreatePersonForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      passwordScore: 0,
      passwordErrors: [],
    };
  }
  getValidationSchema = () => {
    const that = this;
    const { isExternalUser, isUserWithoutLogin } = this.props;
    if (isUserWithoutLogin) {
      return Yup.object().shape({
        name: Yup.string().required(
          localeLookup('translations.Name is required')
        ),
        employeeNumber: Yup.string()
          .notRequired()
          .test(
            'is-valid',
            localeLookup('translations.Employee number is not valid'),
            function (value) {
              return validateEmployeeNumberService(value)
                .then(() => true)
                .catch((err) => {
                  return this.createError({
                    message: err.response.data.message,
                  });
                });
            }
          ),
      });
    }
    if (isExternalUser) {
      return Yup.object().shape({
        email: Yup.string()
          .matches(EMAIL_REGEX, localeLookup('translations.Email is not valid'))
          .required(localeLookup('translations.Email is required')),
        name: Yup.string().required(
          localeLookup('translations.Name is required')
        ),
        employeeNumber: Yup.string()
          .notRequired()
          .test(
            'is-valid',
            localeLookup('translations.Employee number is not valid'),
            function (value) {
              return validateEmployeeNumberService(value)
                .then(() => true)
                .catch((err) => {
                  return this.createError({
                    message: err.response.data.message,
                  });
                });
            }
          ),
      });
    }
    return Yup.object().shape({
      email: Yup.string()
        .matches(EMAIL_REGEX, localeLookup('translations.Email is not valid'))
        .required(localeLookup('translations.Email is required')),
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
      employeeNumber: Yup.string()
        .notRequired()
        .test(
          'is-valid',
          localeLookup('translations.The employee number is already in use'),
          function (value) {
            return validateEmployeeNumberService(value)
              .then(() => true)
              .catch((err) => {
                return this.createError({ message: err.response.data.message });
              });
          }
        ),
      password: Yup.string()
        .required(localeLookup('translations.Password is required'))
        .test('is-valid', '', function (value) {
          return validatePasswordService(value)
            .then((response) => {
              that.setState(() => ({
                passwordScore: response.data.score,
                passwordErrors: response.data.errors,
              }));
              if (response.data.errors.length > 0) {
                return this.createError({
                  message: localeLookup('translations.Password does not meet requirements'),
                });
              }
              return true;
            })
            .catch((err) => {
              return this.createError({ message: err.response.data.message });
            });
        }),
      password2: Yup.string()
        .required(localeLookup('translations.Repeated password is required'))
        .oneOf(
          [Yup.ref('password'), null],
          localeLookup('translations.Passwords do not match')
        ),
    });
  };

  createExternalUser({ email, initials, name, employeeNumber }) {
    const { onCreated, getPersons } = this.props;
    createExternalUserService({ email, initials, name, employeeNumber }).then(
      ({ data }) => {
        const { id, name, initials, autoGeneratedInitials, employeeNumber } =
          data;
        getPersons().then(() => {
          onCreated({
            id,
            name,
            initials,
            autoGeneratedInitials,
            employeeNumber,
          });
        });
      }
    );
  }

  createUserWithLogin({
    name,
    initials,
    email,
    password,
    repeatedPassword,
    employeeNumber,
  }) {
    const { onCreated, getPersons } = this.props;
    createUserWithLoginService({
      name,
      initials,
      email,
      password,
      repeatedPassword,
      employeeNumber,
    }).then(({ data }) => {
      const { id, name, initials, autoGeneratedInitials, employeeNumber } =
        data;
      getPersons().then(() => {
        onCreated({
          id,
          name,
          initials,
          autoGeneratedInitials,
          employeeNumber,
        });
      });
    });
  }

  createUserWithoutLogin({ name, initials, employeeNumber }) {
    const { onCreated, getPersons } = this.props;
    createUserWithoutLoginService({ name, initials, employeeNumber }).then(
      ({ data }) => {
        const { id, name, initials, autoGeneratedInitials, employeeNumber } =
          data;
        getPersons().then(() => {
          onCreated({
            id,
            name,
            initials,
            autoGeneratedInitials,
            employeeNumber,
          });
        });
      }
    );
  }

  onSubmit = (values) => {
    const { isExternalUser, isUserWithoutLogin } = this.props;
    if (isUserWithoutLogin) {
      this.createUserWithoutLogin(values);
    } else if (!isExternalUser) {
      this.createUserWithLogin(values);
    } else if (isExternalUser) {
      this.createExternalUser(values);
    }
  };

  render() {
    const { passwordErrors, passwordScore } = this.state;
    const { initialValues, renderFooter, isExternalUser, isUserWithoutLogin } =
      this.props;
    const defaultInitialValues = {
      email: '',
      name: '',
      initials: '',
      employeeNumber: '',
      password: '',
      password2: '',
    };
    return (
      <ModalBody noSpacing>
        <FormWrapper
          className="create-person-form"
          onSubmit={this.onSubmit}
          initialValues={{ ...defaultInitialValues, ...initialValues }}
          validationSchema={this.getValidationSchema()}
          validateOnMount
        >
          {({
            handleSubmit,
            values,
            handleChange,
            touched,
            errors,
            handleBlur,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <>
                <form
                  className="create-person-form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  {isExternalUser && (
                    <BoxMessage>
                      {localeLookup('translations.AdUserLoginMessage')}
                    </BoxMessage>
                  )}
                  <div className="create-person-form__inner">
                    {!isUserWithoutLogin && (
                      <TextField
                        autoComplete="off"
                        disabled
                        name="email"
                        id="email"
                        required
                        error={touched.email && errors.email}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={localeLookup('translations.Email')}
                      />
                    )}
                    <TextField
                      autoComplete="off"
                      autoFocus
                      name="name"
                      id="name"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.name && errors.name}
                      value={values.name}
                      label={localeLookup('translations.Name')}
                    />
                    <Row>
                      <Column size="6">
                        <TextField
                          autoComplete="off"
                          name="initials"
                          id="initials"
                          error={touched.initials && errors.initials}
                          onBlur={handleBlur}
                          value={values.initials}
                          maxLength={5}
                          /* placeholder={localeLookup(
                            'translations.If left blank initials will be auto generated'
                          )} */
                          onChange={(e) => {
                            let value = e.target.value || '';
                            value = value.toUpperCase().trim();
                            setFieldValue('initials', value);
                          }}
                          label={localeLookup('translations.Initials')}
                        />
                      </Column>
                      <Column size="6">
                        <TextField
                          autoComplete="off"
                          maxLength={10}
                          name="employeeNumber"
                          id="employeeNumber"
                          error={errors.employeeNumber}
                          onBlur={handleBlur}
                          value={values.employeeNumber}
                          onChange={handleChange}
                          label={localeLookup('translations.Employee number')}
                        />
                      </Column>
                    </Row>
                    {!isExternalUser && !isUserWithoutLogin && (
                      <>
                        <Row align="start">
                          <Column size="6">
                            <TextField
                              autoComplete="nope"
                              name="password"
                              id="password"
                              required
                              onBlur={handleBlur}
                              error={touched.password && errors.password}
                              value={values.password}
                              onChange={handleChange}
                              label={localeLookup('translations.Password')}
                              type="password"
                            />
                          </Column>
                          <Column size="6">
                            <TextField
                              autoComplete="new-password"
                              name="password2"
                              id="password2"
                              required
                              onBlur={handleBlur}
                              error={
                                values.password2.length > 1 && errors.password2
                              }
                              value={values.password2}
                              onChange={handleChange}
                              label={localeLookup(
                                'translations.Repeat password'
                              )}
                              type="password"
                            />
                          </Column>
                        </Row>
                        <PasswordScoreBar
                          score={passwordScore}
                          passwordFilled={values.password.length > 0}
                        />
                        <PasswordRequirementsChecker errors={passwordErrors} />
                      </>
                    )}
                  </div>
                  <input type="submit" style={{ visibility: 'hidden' }} />
                </form>
                {renderFooter &&
                  renderFooter({
                    handleSubmit,
                    disableSubmit: !isValid || isSubmitting,
                  })}
              </>
            );
          }}
        </FormWrapper>
      </ModalBody>
    );
  }
}

export default connect(null, mapDispatchToProps)(CreatePersonForm);
