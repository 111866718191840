import React, { Component } from 'react';
import cx from 'classnames';
import Icon from './Icon';

class ConfirmButton extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onReject = this.onReject.bind(this);
  }

  onClick() {
    const { showButtons } = this.state;
    this.setState({
      showButtons: !showButtons,
    });
  }

  onConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
  }

  onReject() {
    this.setState({
      showButtons: false,
    });
  }

  render() {
    const { className, render } = this.props;
    const { showButtons } = this.state;
    return (
      <div
        className={`confirm-button ${className} ${cx({
          'confirm-button--active': showButtons,
        })}`}
      >
        <div className="confirm-button__child">{render(this.onClick, showButtons)}</div>
        <div className="confirm-button__buttons">
          <button type="button" className="confirm-button__button confirm-button__button--confirm" onClick={this.onConfirm}>
            <Icon kind="check" />
          </button>
          <button type="button" className="confirm-button__button confirm-button__button--reject" onClick={this.onReject}>
            <Icon kind="cross" />
          </button>
        </div>
      </div>
    );
  }
}

ConfirmButton.defaultProps = {
  className: '',
};

export default ConfirmButton;
