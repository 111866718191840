import React from 'react';
import cx from 'classnames';
import SideNavTitle from './SideNavTitle';

const SideNavListSection = ({
  title,
  children,
  virtual,
  listHeader,
  titleIcon,
  titleIconColor,
  onTitleIconClick,
}) => {
  const modifierClasses = {
    'side-nav-list-section--virtual': virtual,
  };
  return (
    <div className={cx('side-nav-list-section', modifierClasses)}>
      {title && (
        <SideNavTitle
          icon={titleIcon}
          iconColor={titleIconColor}
          onIconClick={onTitleIconClick}
          title={title}
        />
      )}
      {listHeader && listHeader}
      <ul className="side-nav-list-section__list">{children}</ul>
    </div>
  );
};

export default SideNavListSection;
