import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const DashboardSidebarList = ({ children, nested, dashedBorders }) => {
  const modifierClasses = {
    'dashboard-sidebar-list--nested': nested,
    'dashboard-sidebar-list--dashed-borders': dashedBorders,
  };
  return <ul className={cx('dashboard-sidebar-list', modifierClasses)}>{children}</ul>;
};

DashboardSidebarList.defaultProps = {
  nested: false,
  dashedBorders: false,
};

DashboardSidebarList.propTypes = {
  children: PropTypes.node.isRequired,
  nested: PropTypes.bool,
  dashedBorders: PropTypes.bool,
};

export default DashboardSidebarList;
