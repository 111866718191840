import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeAreaElementSignees,
  changeAreaElementStatus,
  changeRoleAreaElementSignees,
  changeRoleAreaElementStatus,
  changeRoleAreaLinkState,
  changeRoleAreaStatus,
  requestTrainingAndOwnerships,
  updatedPersonElementDataReceived,
} from '../../actions/trainingAndOwnershipsActions';
import KnowledgeDashboard from '../../components/dashboard/KnowledgeDashboard';
import RoleDashboard from '../../components/dashboard/RoleDashboard';
import EmptyState from '../../components/EmptyState';
import LoadScreen from '../../components/LoadScreen';
import Page from '../../components/Page';
import localeLookup from '../../config/locale';
import { rootmenuSelector } from '../../reducers/rootmenuReducer';
import { fetchTrainingAndOwnershipsArea, fetchTrainingAndOwnershipsRole } from '../../services/trainingAndOwnershipsService';
import { trackEvent } from '../../utils/tracking';
import TrainingAndOwnershipsSidebar from './TrainingAndOwnershipsSidebar';

const mapStateToProps = (state) => {
  const { trainingAndOwnerships } = state;
  return {
    allPersons: trainingAndOwnerships.allPersons,
    wildcardPersons: trainingAndOwnerships.wildcardPersons,
    ownedKnowledgeAreas: trainingAndOwnerships.ownedKnowledgeAreas,
    ownedRoles: trainingAndOwnerships.ownedRoles,
    trainingKnowledgeAreas: trainingAndOwnerships.trainingKnowledgeAreas,
    trainingRoles: trainingAndOwnerships.trainingRoles,
    knowledgeAreas: trainingAndOwnerships.knowledgeAreas,
    knowledgeElements: trainingAndOwnerships.knowledgeElements,
    persons: trainingAndOwnerships.persons,
    knowledgeRoles: trainingAndOwnerships.knowledgeRoles,
    selectedSidebarElement: trainingAndOwnerships.selectedSidebarElement,
    hasOverviewLoaded: trainingAndOwnerships.hasOverviewLoaded,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAreaElementSignees,
      changeAreaElementStatus,
      changeRoleAreaElementSignees,
      changeRoleAreaElementStatus,
      changeRoleAreaLinkState,
      changeRoleAreaStatus,
      requestTrainingAndOwnerships,
      updatedPersonElementDataReceived,
    },
    dispatch
  );

class TrainingAndOwnerships extends Component {
  constructor() {
    super();
    this.state = {
      selectedSidebarElementType: '',
      selectedSidebarElementId: '',
    };
  }
  componentDidMount() {
    trackEvent('$pageview');
    this.props.requestTrainingAndOwnerships();
  }

  onClickSidebarElement = ({ type, id }) => {
    this.setState({ selectedSidebarElementType: type, selectedSidebarElementId: id });
  };

  renderRoleDashboard = () => {
    const { selectedSidebarElementType, selectedSidebarElementId } = this.state;
    const getData = () =>
      fetchTrainingAndOwnershipsRole(selectedSidebarElementId, { isOwnershipRequest: selectedSidebarElementType === 'ownedRole' });
    return (
      <RoleDashboard
        key={`${selectedSidebarElementType}${selectedSidebarElementId}`}
        defaultFilterOption={selectedSidebarElementType === 'trainingRole' ? 'yourMentees' : null}
        hideAllPersonsFilterOption={selectedSidebarElementType === 'trainingRole'}
        dashboardType={selectedSidebarElementType}
        getData={getData}
        visibleOrganisationUnitIds={[]}
      />
    );
  };

  renderKnowledgeDashboard = () => {
    const { selectedSidebarElementType, selectedSidebarElementId } = this.state;
    const getData = () =>
      fetchTrainingAndOwnershipsArea(selectedSidebarElementId, { isOwnershipRequest: selectedSidebarElementType === 'ownedKnowledgeArea' });
    return (
      <KnowledgeDashboard
        key={`${selectedSidebarElementType}${selectedSidebarElementId}`}
        dashboardType={selectedSidebarElementType}
        defaultFilterOption={selectedSidebarElementType === 'trainingKnowledgeArea' ? 'yourMentees' : null}
        hideAllPersonsFilterOption={selectedSidebarElementType === 'trainingKnowledgeArea'}
        getData={getData}
      />
    );
  };

  render() {
    const {
      allPersons,
      changeAreaElementSignees,
      changeAreaElementStatus,
      changeRoleAreaElementSignees,
      changeRoleAreaElementStatus,
      changeRoleAreaLinkState,
      changeRoleAreaStatus,
      hasOverviewLoaded,
      knowledgeAreas,
      knowledgeElements,
      ownedKnowledgeAreas,
      ownedRoles,
      persons,
      selectedSidebarElement,
      trainingKnowledgeAreas,
      trainingRoles,
      wildcardPersons,
      updatedPersonElementDataReceived,
    } = this.props;

    const { selectedSidebarElementType, selectedSidebarElementId } = this.state;

    let role;
    let area;
    if (selectedSidebarElementType === 'trainingRole') {
      role = trainingRoles[selectedSidebarElement.id];
    } else if (selectedSidebarElementType === 'ownedRole') {
      role = ownedRoles[selectedSidebarElement.id];
    } else if (selectedSidebarElementType === 'trainingKnowledgeArea') {
      area = trainingKnowledgeAreas[selectedSidebarElement.id];
    } else if (selectedSidebarElementType === 'ownedKnowledgeArea') {
      area = ownedKnowledgeAreas[selectedSidebarElement.id];
    }

    const showEmptyState = isEmpty(trainingRoles) && isEmpty(ownedRoles) && isEmpty(trainingKnowledgeAreas) && isEmpty(ownedKnowledgeAreas);

    const selectedElementIsRole = selectedSidebarElementType === 'trainingRole' || selectedSidebarElementType === 'ownedRole';
    const selectedElementIsKnowledgeArea =
      selectedSidebarElementType === 'trainingKnowledgeArea' || selectedSidebarElementType === 'ownedKnowledgeArea';

    const defaultPersonFilterOption = () => {
      if (selectedSidebarElementType === 'ownedRole' || selectedSidebarElementType === 'ownedKnowledgeArea') {
        return 'persons';
      }
      if (selectedSidebarElementType === 'trainingRole' || selectedSidebarElementType === 'trainingKnowledgeArea') {
        return 'yourMentees';
      }
      return null;
    };
    const showAllPersonsFilterOption = selectedSidebarElementType === 'ownedRole' || selectedSidebarElementType === 'ownedKnowledgeArea';

    if (!hasOverviewLoaded) return <LoadScreen />;
    return (
      <Page>
        {!showEmptyState && (
          <TrainingAndOwnershipsSidebar
            selectedSidebarElementType={selectedSidebarElementType}
            selectedSidebarElementId={selectedSidebarElementId}
            onClickSelectElement={this.onClickSidebarElement}
          />
        )}
        {showEmptyState && <EmptyState fullHeight title={localeLookup('translations.No tasks here!')} />}
        {selectedElementIsRole && this.renderRoleDashboard()}
        {selectedElementIsKnowledgeArea && this.renderKnowledgeDashboard()}
      </Page>
    );
  }
}

TrainingAndOwnerships = connect(mapStateToProps, mapDispatchToProps)(TrainingAndOwnerships);

export default TrainingAndOwnerships;
