import {
  axiosRequestDELETE,
  axiosRequestGET,
  axiosRequestPOST,
  axiosRequestPUT,
} from './serviceConfig';

export const createTrainingSessionService = ({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  organisers,
  location,
  completionType,
}) =>
  axiosRequestPOST('/api/training/sessions', {
    name,
    startDate,
    endDate,
    startTime,
    endTime,
    organisers,
    location,
    completionType,
  });

export const getTrainingSessionsService = () =>
  axiosRequestGET('/api/training/sessions');

export const getTrainingSessionsSettingsService = () =>
  axiosRequestGET('/api/training/sessions/settings');

export const getTrainingSessionService = (id) =>
  axiosRequestGET(`/api/training/sessions/${id}`);

export const getTrainingSessionStatusService = (id) =>
  axiosRequestGET(`/api/training/sessions/${id}/status`);

export const updateTrainingSessionsViewSettingService = (view) =>
  axiosRequestPUT('/api/training/sessions/settings/view', { view });

export const updateTrainingSessionsStatusSettingService = (showCompleted) =>
  axiosRequestPUT('/api/training/sessions/settings/status', { showCompleted });

export const updateTrainingSessionElementsService = (id, elementIds = []) =>
  axiosRequestPUT(`/api/training/sessions/${id}/elements`, {
    elements: elementIds,
  });

export const updateTrainingSessionParticipantsService = (id, personIds = []) =>
  axiosRequestPUT(`/api/training/sessions/${id}/participants`, {
    participants: personIds,
  });

export const updateTrainingSessionTrainersService = (id, personIds = []) =>
  axiosRequestPUT(`/api/training/sessions/${id}/trainers`, {
    trainers: personIds,
  });

export const updateTrainingSessionName = (id, name) =>
  axiosRequestPUT(`/api/training/sessions/${id}/name`, { name });

export const updateTrainingSessionLocation = (id, location) =>
  axiosRequestPUT(`/api/training/sessions/${id}/location`, { location });

export const updateTrainingSessionOrganisers = (id, organisers) => {
  return axiosRequestPUT(`/api/training/sessions/${id}/organisers`, {
    organisers,
  });
};

export const updateTrainingSessionCompletionType = (id, completionType) => {
  return axiosRequestPUT(`/api/training/sessions/${id}/completion-type`, {
    completionType,
  });
};

export const updateTrainingSessionDateAndTimeService = (
  id,
  { startDate, startTime, endDate, endTime }
) => {
  return axiosRequestPUT(`/api/training/sessions/${id}/date-time`, {
    startDate,
    startTime,
    endDate,
    endTime,
  });
};

export const invalidateTrainingSessionCompletionsService = (id, eventIds) =>
  axiosRequestDELETE(`/api/training/sessions/${id}/status`, { eventIds });

export const updateTrainingSessionStateService = (id, state) =>
  axiosRequestPUT(`/api/training/sessions/${id}/state`, { state });

export const duplicateTrainingSessionService = (id, name, include) =>
  axiosRequestPOST(`/api/training/sessions/${id}/duplicate`, {
    include,
    name,
  });
