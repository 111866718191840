import React, { PureComponent } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import Input from '../formElements/Input';
import OrganisationTree from './OrganisationTree';
import localeLookup from '../../config/locale';
import SideNavTitle from '../sideNav/SideNavTitle';
import SideNavItem from '../sideNav/SideNavItem';
import Loader from '../Loader';
import SideNavFilter from '../sideNav/SideNavFilter';
import { ACCESS_LEVELS, ORGANISATION_UNIT_STATES } from '../../constants';
import withAccessControl from '../HOC/withAccessControl';
import {
  collapseOrganisationUnitService,
  expandOrganisationUnitService,
} from '../../services/organisationService';

class OrganisationSidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedUnitIds: props.organisationUnitsExpanded || [],
    };
  }

  onToggleExpandUnit = (id) => {
    const { expandedUnitIds } = this.state;
    if (expandedUnitIds.includes(id)) {
      collapseOrganisationUnitService(id);
      this.setState({
        expandedUnitIds: expandedUnitIds.filter(
          (expandedId) => expandedId !== id
        ),
      });
    } else {
      expandOrganisationUnitService(id);
      this.setState({
        expandedUnitIds: [...expandedUnitIds, id],
      });
    }
  };

  render() {
    const { expandedUnitIds } = this.state;
    const {
      filteredOrganisationUnits,
      isReadOnly,
      loadingOrganisationUnits,
      organisationUnitFilterString,
      onChangeOrganisationUnitFilterString,
      onEditOrganisationUnitsClick,
      onSelectOrganisationUnit,
      organisationUnits,
      selectedOrganisationUnits,
      organisationUnitRootNodes,
      hasAccess,
    } = this.props;
    const showTreeStructure = !organisationUnitFilterString;
    if (!organisationUnits) return null;
    const showEditUnits = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.teamadministrator,
    ]);
    return (
      <div className="organisation-sidebar">
        <SideNavFilter
          autoFocus
          onChange={onChangeOrganisationUnitFilterString}
          placeholder={`${localeLookup(
            'translations.Search for organisation units'
          )}...`}
        />
        <SideNavTitle
          title={localeLookup('translations.Organisation units')}
          icon="pencil"
          iconColor="green"
          onIconClick={
            !isReadOnly && showEditUnits ? onEditOrganisationUnitsClick : null
          }
        />
        <ul className="organisation-sidebar__tree">
          {showTreeStructure &&
            organisationUnitRootNodes.map((id) => {
              return (
                <OrganisationTree
                  onToggleExpandUnit={this.onToggleExpandUnit}
                  expandedUnitIds={expandedUnitIds}
                  key={id}
                  loadingOrganisationUnits={loadingOrganisationUnits}
                  onSelectOrganisationUnit={onSelectOrganisationUnit}
                  organisationUnit={organisationUnits[id]}
                  organisationUnits={organisationUnits}
                  renderSubtitle={(unit) => {
                    const isVisibilityLimited =
                      unit?.state ===
                        ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
                      unit?.state === ORGANISATION_UNIT_STATES.PASSIVE;
                    if (isVisibilityLimited)
                      return localeLookup('translations.Limited visibility');
                    return null;
                  }}
                  selectedOrganisationUnits={selectedOrganisationUnits}
                />
              );
            })}
          {!showTreeStructure &&
            filteredOrganisationUnits.map((organisationUnit) => {
              const isLoading =
                loadingOrganisationUnits.includes(organisationUnit);
              const isSelected =
                selectedOrganisationUnits.includes(organisationUnit);
              return (
                <SideNavItem
                  key={organisationUnit}
                  onClick={() => {
                    if (isLoading) return;
                    onSelectOrganisationUnit(organisationUnit);
                  }}
                  rightComponent={
                    isLoading ? (
                      <Loader numberOfDots={1} />
                    ) : (
                      <Icon
                        kind={isSelected ? 'eye' : 'eye-crossed'}
                        color={isSelected ? 'green' : 'light-grey'}
                      />
                    )
                  }
                  title={organisationUnits[organisationUnit].organisationName}
                />
              );
            })}
        </ul>
      </div>
    );
  }
}

export default withAccessControl(OrganisationSidebar);
