import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { moveArrayItemToIndex } from '../../utils/helpers';

import DragAndDropHandle from '../DragAndDropHandle';
import EmptyState from '../EmptyState';
import ListCard from '../ListCard';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

export default class SortableListModal extends PureComponent {
  constructor(props) {
    super(props);
    const { items } = props;
    this.state = {
      items,
    };
  }

  onConfirm = () => {
    const { items } = this.state;
    const { onConfirm } = this.props;
    onConfirm(items);
  };

  onDragEnd = (result) => {
    const { items } = this.state;
    const { destination, source, draggableId } = result;
    const isDroppedAtSamePlace =
      destination.droppableId === source.droppableId && destination.index === source.index;
    if (!destination || isDroppedAtSamePlace) return;

    const newOrder = moveArrayItemToIndex({
      arr: items,
      currentIndex: source.index,
      newIndex: destination.index,
    });
    this.setState({ items: newOrder });
  };

  render() {
    const { items } = this.state;
    const { title, onClose, confirmBtnText, emptyStateText,subtitle } = this.props;
    const showEmptyState = items.length === 0;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : (
            <DragDropContext nonce={cspNonce} onDragEnd={this.onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => {
                            return (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <ListCard leftComponent={<DragAndDropHandle />}>
                                  <>
                                    <Text>{item.title}</Text>
                                    {item.subtitle && (
                                      <Text size="sm" color="dark-grey">
                                        {item.subtitle}
                                      </Text>
                                    )}
                                  </>
                                </ListCard>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                      <div style={{ marginBottom: '1rem' }}>{provided.placeholder}</div>
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}
