/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React from 'react';
import ButtonIcon from '../ButtonIcon';

const OrganisationTree = ({
  isChild = false,
  loadingOrganisationUnits,
  onSelectOrganisationUnit,
  organisationUnit,
  organisationUnits,
  selectedOrganisationUnits,
  expandedUnitIds = [],
  onToggleExpandUnit,
  renderSubtitle,
  unitId,
}) => {
  if (!organisationUnit) {
    return null;
  }
  const {
    additionalKnowledgeHasNotCompletedGoals,
    organisationName,
    descendants,
    id,
    rolesHasNotCompletedGoals,
  } = organisationUnit;
  const isLoading = loadingOrganisationUnits.includes(id);
  const isSelected = selectedOrganisationUnits.includes(id);
  const notificationCount =
    additionalKnowledgeHasNotCompletedGoals + rolesHasNotCompletedGoals;
  const isExpanded = expandedUnitIds.includes(id);
  return (
    <li
      className={cx('organisation-sidebar-tree__item', {
        'organisation-sidebar-tree__item--child': isChild,
      })}
    >
      <div
        className={cx('organisation-sidebar-tree__item-wrapper', {
          'organisation-sidebar-tree__item-wrapper--loading': isLoading,
          'organisation-sidebar-tree__item-wrapper--state-expanded': isExpanded,
          'organisation-sidebar-tree__item-wrapper--has-subtitle':
            renderSubtitle && renderSubtitle(organisationUnit) !== null,
          'organisation-sidebar-tree__item-wrapper--has-children':
            descendants?.length > 0,
        })}
        onClick={() => {
          if (isLoading) return;
          onSelectOrganisationUnit(id);
        }}
      >
        {descendants?.length > 0 && (
          <ButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpandUnit(id);
            }}
            className="organisation-sidebar-tree__item-expand-icon"
            size="small"
            icon="chevron-down"
          />
        )}
        <div
          className="organisation-sidebar-tree__item-text"
          title={organisationName}
        >
          <p className="organisation-sidebar-tree__item-title">
            {organisationName}
          </p>
          {renderSubtitle && renderSubtitle(organisationUnit) !== null ? (
            <p className="organisation-sidebar-tree__item-subtitle">
              {renderSubtitle(organisationUnit)}
            </p>
          ) : null}
        </div>
        <div className="organisation-sidebar-tree__item-left">
          {notificationCount > 0 && (
            <div className="notification-counter notification-counter--small organisation-sidebar-tree__item-notifications">
              {notificationCount}
            </div>
          )}
          <ButtonIcon
            isLoading={isLoading}
            numberOfLoaderDots={1}
            className="organisation-sidebar-tree__item-select-button"
            icon={isSelected ? 'eye' : 'eye-crossed'}
            iconColor={isSelected ? 'green' : 'light-grey'}
          />
        </div>
      </div>
      {isExpanded &&
        descendants &&
        descendants.map((key) => (
          <ul className="organisation-sidebar-tree__list" key={key}>
            <OrganisationTree
              isChild
              loadingOrganisationUnits={loadingOrganisationUnits}
              onSelectOrganisationUnit={onSelectOrganisationUnit}
              organisationUnits={organisationUnits}
              unitId={key}
              organisationUnit={organisationUnits[key]}
              selectedOrganisationUnits={selectedOrganisationUnits}
              expandedUnitIds={expandedUnitIds}
              renderSubtitle={renderSubtitle}
              onToggleExpandUnit={onToggleExpandUnit}
            />
          </ul>
        ))}
    </li>
  );
};

export default OrganisationTree;
