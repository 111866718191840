import { getMentorRolesService } from '../services/roleService';

export const MENTOR_ROLES_RECEIVED = 'MENTOR_ROLES_RECEIVED';
export const mentorRolesReceived = (data) => ({
  type: MENTOR_ROLES_RECEIVED,
  data,
});

export const getMentorRoles = () => (dispatch) =>
  getMentorRolesService()
    .then(({ data }) => dispatch(mentorRolesReceived(data)))
    .catch(console.error);
