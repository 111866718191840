import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, PERSON_STATES } from '../constants';
import { createTrainingSessionService } from '../services/trainingSessionService';
import { compareLocal, sortBy } from '../utils/helpers';
import DateFieldV2 from './DateFieldV2';
import { Grid } from './Grid';
import MultiSelect from './MultiSelect';
import { RadioButtonGroup } from './RadioButtonGroup';
import TimeField from './TimeField';
import Field from './formElements/Field';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import ModalBody from './modal/ModalBody';
import { set } from 'date-fns';
import withAccessControl from './HOC/withAccessControl';
import BoxMessage from './BoxMessage';

const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
  persons: state.persons,
});

const mapDispatchToProps = {};

class CreateTrainingSessionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  canEditAfterCreation = (values) => {
    const { currentUserId, hasAccess } = this.props;
    const hasAccessRoles = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.trainingSessionAdministrator,
    ]);

    if (hasAccessRoles) return true;
    if (values.organisers.includes(currentUserId)) return true;
    return false;
  };

  onSubmit = (values) => {
    const { onCreated } = this.props;
    const {
      name,
      startDate,
      endDate,
      startTime,
      endTime,
      location,
      organisers,
      completionType,
    } = values;
    this.setState({ isSubmitting: true });
    const startTimePart = startTime ? format(startTime, 'HH:mm') : null;
    const endTimePart = endTime ? format(endTime, 'HH:mm') : null;
    createTrainingSessionService({
      name,
      startDate: startDate ? format(startDate, 'yyyy/MM/dd') : null,
      endDate: endDate ? format(endDate, 'yyyy/MM/dd') : null,
      startTime: startTimePart,
      endTime: endTimePart,
      organisers,
      location,
      completionType,
    }).then(({ data }) => {
      onCreated?.({ data });
    });
  };

  render() {
    const { isSubmitting } = this.state;
    const { persons, renderFooter, currentUserId, hasAccess } = this.props;

    const organiserOptions = [
      ...sortBy(
        Object.keys(persons)
          .filter((id) => persons[id].state === PERSON_STATES.ACTIVE)
          .map((id) => {
            const person = persons[id];
            return {
              label: persons[id].name,
              value: id,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            };
          }),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
    ];

    const isAdmin = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.trainingSessionAdministrator,
    ]);

    const currentUserOption = isAdmin
      ? null
      : organiserOptions.find((person) => person.value === currentUserId);

    const defaultOrganisersValue = isAdmin ? [] : [currentUserId];

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
      startDate: Yup.date()
        /* .required(localeLookup('translations.Start date is required')) */
        .test(
          'is-before-end-date',
          localeLookup('translations.Start date must be before end date'),
          function (value) {
            if (value && value !== '' && this?.parent?.endDate) {
              const valueObj = new Date(value);
              const endDateObj = new Date(this.parent.endDate);
              return (
                isBefore(valueObj, endDateObj) ||
                isSameDay(valueObj, endDateObj)
              );
            } else {
              return true;
            }
          }
        ),
      startTime: Yup.date()
        .nullable()
        .test(
          'is-filled-if-required',
          localeLookup(
            'translations.Start time is required when end time and start date is set'
          ),
          function (value) {
            if (this?.parent?.endTime && this?.parent?.startDate) {
              return value && value !== '';
            }
            return true;
          }
        ),
      endDate: Yup.date()
        .nullable()
        .when('endTime', {
          is: (value) => value && value !== '',
          then: () =>
            Yup.date().required(
              'translations.End date is required when end time is set'
            ),
        })
        .test(
          'is-after-start-date',
          localeLookup('translations.End date must be after start date'),
          function (value) {
            if (this?.parent?.startDate && value && value !== '') {
              const valueObj = new Date(value);
              const startDateObj = new Date(this.parent.startDate);
              return (
                isAfter(valueObj, startDateObj) ||
                isSameDay(valueObj, startDateObj)
              );
            } else {
              return true;
            }
          }
        ),
      endTime: Yup.date()
        .nullable()
        .test(
          'is-filled-if-required',
          localeLookup(
            'translations.End time is required when start time and end date is set'
          ),
          function (value) {
            if (this?.parent?.startTime && this?.parent?.endDate) {
              return value && value !== '';
            }
            return true;
          }
        )
        .test(
          'is-after-start-date',
          localeLookup(
            'translations.End time must be after start date and time'
          ),
          function (value) {
            if (
              this?.parent?.startDate &&
              this?.parent?.startTime &&
              this?.parent?.endDate &&
              value &&
              value !== ''
            ) {
              const startDateObj = new Date(this.parent.startDate);
              const startTimeObj = new Date(this.parent.startTime);
              const startDateWithStartTime = set(startDateObj, {
                hours: startTimeObj.getHours(),
                minutes: startTimeObj.getMinutes(),
              });
              const endDateObj = new Date(this.parent.endDate);
              const endTimeObj = new Date(this.parent.endTime);
              const endDateWithEndTime = set(endDateObj, {
                hours: endTimeObj.getHours(),
                minutes: endTimeObj.getMinutes(),
              });
              return isAfter(endDateWithEndTime, startDateWithStartTime);
            } else {
              return true;
            }
          }
        ),
    });

    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }
      return true;
    };

    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          completionType: 'SessionEmployeeSignature',
          organisers: defaultOrganisersValue,
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          setFieldValue,
          validateForm,
        }) => (
          <>
            <ModalBody>
              {!this.canEditAfterCreation(values) && (
                <BoxMessage type="warning" spacing="md" icon="warning">
                  {localeLookup(
                    'translations.You have to be organiser to access this training session after creation'
                  )}
                </BoxMessage>
              )}
              <form
                className="create-training-session-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  required
                  autoFocus
                  placeholder={localeLookup('translations.Name')}
                  name="name"
                  id="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.name && errors.name}
                  value={values.name}
                  label={localeLookup('translations.Name')}
                />
                <Grid gridTemplateColumns="1fr 1fr" columnGap="1rem">
                  <DateFieldV2
                    label={localeLookup('translations.Start date')}
                    error={touched.startDate && errors.startDate}
                    onBlur={handleBlur}
                    name="startDate"
                    id="startDate"
                    minDate={new Date()}
                    maxDate={values.endDate}
                    value={values.startDate}
                    showFormatAsPlaceholder
                    clearable
                    onClear={async () => {
                      await setFieldValue('startDate', '');
                      setFieldValue('startTime', '');
                    }}
                    onChange={(value) => {
                      setFieldValue('startDate', value);
                    }}
                  />
                  <Field
                    label={localeLookup('translations.Start time')}
                    margin="none"
                    required={
                      values.startDate && values.endDate && values.endTime
                    }
                  >
                    <TimeField
                      error={touched.startTime && errors.startTime}
                      onBlur={handleBlur}
                      name="startTime"
                      id="startTime"
                      disabled={!values.startDate}
                      value={values.startTime}
                      clearable
                      onClear={() => {
                        setFieldValue('startTime', '');
                      }}
                      onChange={(value) => {
                        setFieldValue('startTime', value);
                      }}
                    />
                  </Field>
                  <DateFieldV2
                    label={localeLookup('translations.End date')}
                    error={touched.endDate && errors.endDate}
                    onBlur={handleBlur}
                    name="endDate"
                    id="endDate"
                    minDate={values.startDate || new Date()}
                    value={values.endDate}
                    showFormatAsPlaceholder
                    clearable
                    onClear={async () => {
                      await setFieldValue('endDate', '');
                      setFieldValue('endTime', '');
                    }}
                    onChange={(value) => {
                      setFieldValue('endDate', value);
                    }}
                  />

                  <Field
                    label={localeLookup('translations.End time')}
                    margin="none"
                    required={
                      values.startDate && values.startTime && values.endDate
                    }
                  >
                    <TimeField
                      disabled={!values.endDate}
                      error={touched.endTime && errors.endTime}
                      onBlur={handleBlur}
                      name="endTime"
                      id="endTime"
                      minDate={new Date()}
                      value={values.endTime}
                      clearable
                      onClear={() => {
                        setFieldValue('endTime', '');
                      }}
                      onChange={(value) => {
                        setFieldValue('endTime', value);
                      }}
                    />
                  </Field>
                </Grid>
                <TextField
                  placeholder={localeLookup('translations.Location')}
                  name="location"
                  id="location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.location && errors.location}
                  value={values.location}
                  label={localeLookup('translations.Location')}
                />
                <Field label={localeLookup('translations.Organisers')}>
                  <MultiSelect
                    onChange={(values) => {
                      setFieldValue(
                        'organisers',
                        values.map((value) => value.value)
                      );
                    }}
                    defaultValue={[currentUserOption]}
                    name="organisers"
                    filterOption={filterOption}
                    options={organiserOptions}
                    placeholder={localeLookup('translations.Select organisers')}
                  />
                </Field>

                <RadioButtonGroup
                  groupName="completionType"
                  label={`${localeLookup('translations.Documentation type')}:`}
                  onChange={(e) => {
                    setFieldValue('completionType', e.target.id);
                  }}
                  options={[
                    {
                      value: 'SessionEmployeeSignature',
                      label: localeLookup('translations.Participant signature'),
                      isChecked:
                        values.completionType === 'SessionEmployeeSignature',
                    },
                    {
                      value: 'SessionTrainerSignature',
                      label: localeLookup('translations.Trainer signature'),
                      isChecked:
                        values.completionType === 'SessionTrainerSignature',
                    },
                    {
                      value: 'SessionMultipartSignature',
                      label: localeLookup('translations.Multipart signature'),
                      isChecked:
                        values.completionType === 'SessionMultipartSignature',
                    },
                  ]}
                ></RadioButtonGroup>
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(
  withAccessControl(CreateTrainingSessionForm)
);
