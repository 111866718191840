/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react';
import ListCard from './ListCard';
import Text from './Text';
import SwitchCheckbox from './SwitchCheckbox';
import cx from 'classnames';
import ButtonIcon from './ButtonIcon';
import Accordion from './Accordion';

export default class CheckList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedOptionIds: [],
    };
  }
  onClickToggleExpandOption = (value) => {
    const { expandedOptionIds } = this.state;
    this.setState({
      expandedOptionIds: expandedOptionIds.includes(value)
        ? expandedOptionIds.filter((expandedId) => expandedId !== value)
        : [...expandedOptionIds, value],
    });
  };

  renderOptions = () => {
    const { options, onChange, selectedOptionIds, disableAll, nestedOptions } =
      this.props;
    const { expandedOptionIds } = this.state;
    if (nestedOptions) {
      return options.map((option) => {
        const isAllNestedOptionsDisabled = option.options.every(
          (nestedOption) => nestedOption.disabled
        );
        return (
          <div
            key={option.id}
            className={cx('check-list__option-nest', {
              'check-list__option-nest--state-expanded':
                expandedOptionIds.includes(option.value),
            })}
          >
            <ListCard
              disabled={disableAll || option.disabled}
              tooltip={option.tooltip}
              clickable
              noCardStyling
              key={option.id}
              onClick={() => this.onClickToggleExpandOption(option.value)}
              rightComponent={
                <div className="check-list__option-right">
                  <ButtonIcon
                    className="check-list__option-expand-icon"
                    inline
                    icon="chevron-down"
                  ></ButtonIcon>
                  <Text>
                    {
                      option.options.filter((nestedOption) =>
                        selectedOptionIds[option.value]?.includes(
                          nestedOption.value
                        )
                      ).length
                    }
                    /
                    {option.options.filter((option) => !option.disabled).length}
                  </Text>
                  <SwitchCheckbox
                    disabled={
                      disableAll ||
                      option.disabled ||
                      isAllNestedOptionsDisabled
                    }
                    hitslop="md"
                    stopPropagation
                    size="small"
                    onChange={() => onChange(option.id)}
                    id={option.id}
                    isChecked={
                      !isAllNestedOptionsDisabled &&
                      option.options
                        .filter((option) => !option.disabled)
                        .every((nestedOption) =>
                          selectedOptionIds[option.value]?.includes(
                            nestedOption.value
                          )
                        )
                    }
                  />
                </div>
              }
            >
              <>
                <Text color={option.titleColor ? option.titleColor : undefined}>
                  {option.title}
                </Text>
                {option.subtitle && (
                  <Text size="sm" color="dark-grey">
                    {option.subtitle}
                  </Text>
                )}
              </>
            </ListCard>
            <Accordion isOpen={expandedOptionIds.includes(option.value)}>
              {option.options && expandedOptionIds.includes(option.value)
                ? option.options.map((nestedOption) => (
                    <ListCard
                      tooltip={nestedOption.tooltip}
                      clickable
                      noCardStyling
                      el="label"
                      htmlFor={`${option.id}${nestedOption.id}`}
                      key={nestedOption.id}
                      rightComponent={
                        <>
                          <SwitchCheckbox
                            size="small"
                            disabled={disableAll || nestedOption.disabled}
                            onChange={() =>
                              onChange(option.id, nestedOption.id)
                            }
                            id={`${option.id}${nestedOption.id}`}
                            isChecked={selectedOptionIds[option.id]?.includes(
                              nestedOption.id
                            )}
                          />
                        </>
                      }
                    >
                      <>
                        <Text>{nestedOption.title}</Text>
                        {nestedOption.subtitle && (
                          <Text size="sm" color="dark-grey">
                            {nestedOption.subtitle}
                          </Text>
                        )}
                      </>
                    </ListCard>
                  ))
                : null}
            </Accordion>
          </div>
        );
      });
    }

    return options.map((option) => (
      <ListCard
        disabled={disableAll || option.disabled}
        tooltip={option.tooltip}
        clickable
        el="label"
        htmlFor={option.id}
        key={option.id}
        rightComponent={
          <SwitchCheckbox
            disabled={disableAll || option.disabled}
            onChange={() => onChange(option.id)}
            id={option.id}
            isChecked={selectedOptionIds.includes(option.id)}
          />
        }
      >
        <>
          <Text color={option.titleColor ? option.titleColor : undefined}>
            {option.title}
          </Text>
          {option.subtitle && (
            <Text size="sm" color="dark-grey">
              {option.subtitle}
            </Text>
          )}
        </>
      </ListCard>
    ));
  };
  render() {
    const {
      options,
      onChange,
      selectedOptionIds,
      disableAll,
      backgroundColor,
      negativeHorizontalMargin,
      nestedOptions,
    } = this.props;
    const modifierClasses = {
      [`check-list--background-${backgroundColor}`]: backgroundColor,
      'check-list--negative-horizontal-margin': negativeHorizontalMargin,
    };
    return (
      <div className={cx('check-list', modifierClasses)}>
        {this.renderOptions()}
      </div>
    );
  }
}
