import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { EMAIL_REGEX } from '../constants';
import BoxMessage from './BoxMessage';
import Column from './Column';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import Row from './Row';
import { validatePasswordService } from '../services/personsService';
import { PasswordScoreBar } from './PasswordScoreBar';
import { PasswordRequirementsChecker } from './PasswordRequirementsChecker';

export default class LoginInformationForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      passwordScore: 0,
      passwordErrors: [],
    };
  }

  getValidationSchema = () => {
    const { newUserType, currentUserType } = this.props;
    const that = this;
    // TODO: Remove external logic since external is no longer shown this modal
    if (newUserType === 'External') {
      return Yup.object().shape({
        email: Yup.string()
          .matches(EMAIL_REGEX, localeLookup('translations.Email is not valid'))
          .required(localeLookup('translations.Email is required')),
        name: Yup.string().required(
          localeLookup('translations.Name is required')
        ),
      });
    }
    if (newUserType === 'Champ' && currentUserType === 'Champ') {
      return Yup.object().shape({
        email: Yup.string()
          .matches(EMAIL_REGEX, localeLookup('translations.Email is not valid'))
          .required(localeLookup('translations.Email is required')),
      });
    }
    return Yup.object().shape({
      email: Yup.string()
        .matches(EMAIL_REGEX, localeLookup('translations.Email is not valid'))
        .required(localeLookup('translations.Email is required')),
      password: Yup.string()
        .required(localeLookup('translations.Password is required'))
        .test('is-valid', '', function (value) {
          return validatePasswordService(value)
            .then((response) => {
              that.setState(() => ({
                passwordScore: response.data.score,
                passwordErrors: response.data.errors,
              }));
              if (response.data.errors.length > 0) {
                return false;
              }
              return true;
            })
            .catch((err) => {
              return this.createError({ message: err.response.data.message });
            });
        }),
      password2: Yup.string()
        .required(localeLookup('translations.Repeated password is required'))
        .oneOf(
          [Yup.ref('password'), null],
          localeLookup('translations.Passwords do not match')
        ),
    });
  };

  getVisibleFields = () => {
    const { currentUserType, newUserType } = this.props;
    if (newUserType === 'External') {
      return ['email', 'name'];
    }
    if (newUserType === 'Champ') {
      if (currentUserType === 'External' || currentUserType === 'NoAccess') {
        return ['email', 'password'];
      }
      if (currentUserType === 'Champ') {
        return ['email'];
      }
    }
    if (newUserType === 'NoAccess') {
      return ['email', 'password'];
    }
    return [];
  };

  onSubmit = ({ email, password, password2 }) => {
    const { onSubmit, newUserType } = this.props;
    onSubmit({ email, password, password2, newUserType });
  };

  render() {
    const { passwordErrors, passwordScore } = this.state;
    const { initialValues, renderFooter, newUserType } = this.props;
    const defaultInitialValues = {
      email: '',
      name: '',
      initials: '',
      employeeNo: '',
      password: '',
      password2: '',
    };
    const visibleFields = this.getVisibleFields();
    return (
      <>
        <FormWrapper
          className="create-person-form"
          onSubmit={this.onSubmit}
          initialValues={{ ...defaultInitialValues, ...initialValues }}
          validationSchema={this.getValidationSchema()}
          validateOnMount
        >
          {({
            handleSubmit,
            values,
            handleChange,
            touched,
            errors,
            handleBlur,
            isValid,
          }) => {
            return (
              <>
                <form className="create-person-form" onSubmit={handleSubmit}>
                  <div className="create-person-form__inner">
                    {visibleFields.includes('email') && (
                      <TextField
                        disabled
                        name="email"
                        id="email"
                        error={touched.email && errors.email}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={localeLookup('translations.Email')}
                      />
                    )}
                    {visibleFields.includes('password') && (
                      <Row align="start">
                        <Column size="6">
                          <TextField
                            autoFocus
                            name="password"
                            id="password"
                            required
                            onBlur={handleBlur}
                            error={touched.password && errors.password}
                            value={values.password}
                            onChange={handleChange}
                            label={localeLookup('translations.Password')}
                            type="password"
                          />
                        </Column>
                        <Column size="6">
                          <TextField
                            name="password2"
                            id="password2"
                            required
                            onBlur={handleBlur}
                            error={touched.password2 && errors.password2}
                            value={values.password2}
                            onChange={handleChange}
                            label={localeLookup('translations.Repeat password')}
                            type="password"
                          />
                        </Column>
                      </Row>
                    )}
                    {visibleFields.includes('password') && (
                      <>
                        <PasswordScoreBar
                          score={passwordScore}
                          passwordFilled={values.password.length > 0}
                        />
                        <PasswordRequirementsChecker errors={passwordErrors} />
                      </>
                    )}
                  </div>
                  <input type="submit" style={{ visibility: 'hidden' }} />
                </form>
                {renderFooter &&
                  renderFooter({ handleSubmit, canSubmit: isValid })}
              </>
            );
          }}
        </FormWrapper>
      </>
    );
  }
}
