import React from 'react';
import cx from 'classnames';

const RadioButton = ({ className, id, isChecked, name, onChange, size, value, disabled }) => (
  <div className={`radio-button ${cx(className, { ['radio-button--' + size]: size })}`}>
    <input
      disabled={disabled}
      id={id}
      name={name}
      checked={isChecked}
      onChange={onChange}
      className="radio-button__input"
      type="radio"
      value={value}
    />
    <div className="radio-button__check" />
    <label className="radio-button__label" htmlFor={id} />
  </div>
);

export default RadioButton;
