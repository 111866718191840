import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import cx from 'classnames';
const DashboardSidebarItem = ({
  title,
  icon,
  children,
  rightComponent,
  onClick,
  highlight,
  sticky,
}) => {
  const modifierClasses = {
    'dashboard-sidebar-item--highlight': highlight,
    'dashboard-sidebar-item--sticky': sticky,
  };
  return (
    <li title={title} className={cx('dashboard-sidebar-item', modifierClasses)}>
      {onClick ? (
        <button
          type="button"
          onClick={onClick}
          className="dashboard-sidebar-item__box dashboard-sidebar-item__box--clickable"
        >
          {icon && (
            <Icon className="dashboard-sidebar-item__icon" kind={icon} />
          )}
          <p className="dashboard-sidebar-item__text">{title}</p>
          {rightComponent}
        </button>
      ) : (
        <div className="dashboard-sidebar-item__box">
          {icon && (
            <Icon className="dashboard-sidebar-item__icon" kind={icon} />
          )}
          <p className="dashboard-sidebar-item__text">{title}</p>
          {rightComponent}
        </div>
      )}
      {children}
    </li>
  );
};

DashboardSidebarItem.defaultProps = {
  icon: null,
  rightComponent: null,
};

DashboardSidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  rightComponent: PropTypes.node,
};

export default DashboardSidebarItem;
