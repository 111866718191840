import React from 'react';
import FormModal from './FormModal';
import CreatePersonForm from '../CreatePersonForm';
import ModalFooter from './ModalFooter';

const CreateUserInformationModal = ({
  onClose,
  title,
  steps,
  activeStepIndex,
  isExternalUser,
  isUserWithoutLogin,
  onCreated,
  initialValues,
  confirmButtonText,
  cancelButtonText,
  onCancelClick,
}) => {
  return (
    <FormModal onClose={onClose} title={title} steps={steps} activeStepIndex={activeStepIndex}>
      <CreatePersonForm
        isExternalUser={isExternalUser}
        isUserWithoutLogin={isUserWithoutLogin}
        onCreated={onCreated}
        initialValues={initialValues}
        renderFooter={({ handleSubmit, disableSubmit }) => (
          <ModalFooter
            confirmButtonText={confirmButtonText}
            confirmDisabled={disableSubmit}
            onConfirmClick={handleSubmit}
            cancelButtonText={cancelButtonText}
            onCancelClick={onCancelClick}
          />
        )}
      />
    </FormModal>
  );
};

export default CreateUserInformationModal;
