import React from 'react';
import cx from 'classnames';

const Column = ({ size, children }) => {
  const modifierClasses = {
    [`column--size-${size}`]: size,
  };
  return <div className={cx('column', modifierClasses)}>{children}</div>;
};

export default Column;
