import cx from 'classnames';
import React, { Component } from 'react';
import Portal from '../Portal';
import ContextMenuTrigger from './ContextMenuTrigger';
import {
  systemHideContextMenu,
  systemShowContextMenu,
} from '../../actions/systemActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const closeEvent = new Event('close');

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { systemHideContextMenu, systemShowContextMenu },
    dispatch
  );

class ContextMenu extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      position: 'bottom-right',
      fixedPosition: { x: 0, y: 0 },
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);

    window.addEventListener(
      'close',
      () => {
        this.hide();
      },
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  getContainerRect = () => {
    const { containerElementClass } = this.props;
    const containerElement = document.querySelector(
      `.${containerElementClass}`
    );
    const containerRect = containerElement?.getBoundingClientRect();
    return containerRect;
  };

  getPosition = () => {
    const { containerElementClass } = this.props;
    const menuRect = this.menuElement.getBoundingClientRect();
    const triggerRect = this.componentRef.getBoundingClientRect();
    const elementRightPosition = menuRect.right;
    const elementBottomPosition = menuRect.bottom;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (containerElementClass) {
      const containerRect = this.getContainerRect();
      console.log(containerElementClass);
      const containerTop = containerRect.top;
      const triggerTop = triggerRect.top;
      const triggerRight = triggerRect.right;
      const menuHeight = menuRect.height;
      const menuWidth = menuRect.width;

      const isMenuAboveContainer = triggerTop - menuHeight < containerTop;

      // If the top position of the trigger minus the height of the menu is less than the top position of the container
      if (isMenuAboveContainer) {
        // If the right position of the trigger plus the width of the menu is greater than the right position of the container
        if (triggerRight + menuWidth > containerRect.right) {
          return 'bottom-left';
        }
        return 'bottom-right';
      } else {
        if (triggerRight + menuWidth > containerRect.right) {
          return 'top-left';
        }
        return 'top-right';
      }
    }

    if (elementRightPosition > windowWidth) {
      if (elementBottomPosition > windowHeight) {
        return 'top-left';
      }
      return 'bottom-left';
    }
    return 'bottom-right';
  };

  hide = () => {
    const { isOpen } = this.state;
    if (!isOpen) return;
    const { systemHideContextMenu } = this.props;
    this.setState(
      {
        isOpen: false,
        position: 'bottom-right',
      },
      systemHideContextMenu
    );
  };

  onTriggerClick = (e) => {
    const { fixed, systemShowContextMenu } = this.props;
    window.dispatchEvent(closeEvent);

    e.stopPropagation();
    if (this.state.isOpen) {
      this.hide();
    } else {
      if (fixed) {
        const triggerRect = e.target.getBoundingClientRect();
        const elementRect = this.menuElement.getBoundingClientRect();
        const containerRect = this.getContainerRect();
        const getYPosition = () => {
          const containerBottom = containerRect
            ? containerRect.bottom
            : window.innerHeight;
          if (triggerRect.bottom + elementRect.bottom > containerBottom) {
            return triggerRect.top - elementRect.height;
          }
          return triggerRect.bottom;
        };
        this.setState(
          {
            isOpen: true,
            fixedPosition: {
              x: triggerRect.x + triggerRect.width,
              y: getYPosition(),
            },
          },
          systemShowContextMenu
        );
      } else {
        this.setState(
          {
            isOpen: true,
            position: this.getPosition(),
          },
          systemShowContextMenu
        );
      }
    }
  };

  onWindowClick = (event) => {
    if (!this.state.isOpen) return;
    const component = this.componentRef;
    const targetIsComponent = event.target === component;
    const componentContainsTarget = component.contains(event.target);
    const targetIsModalButton =
      event.target.classList &&
      event.target.classList.contains('modal-content__footer-btn');
    if (
      !targetIsComponent &&
      !componentContainsTarget &&
      !targetIsModalButton
    ) {
      this.hide();
    }
  };

  renderMenu = () => {
    const { position, fixedPosition, isOpen } = this.state;
    const { children, fixed, closeOnSelectItem } = this.props;
    const menuModifierClass = cx({
      'context-menu__menu--bottom-left': !fixed && position === 'bottom-left',
      'context-menu__menu--bottom-right':
        (!fixed && position === 'bottom-right') || position === undefined,
      'context-menu__menu--top-left': !fixed && position === 'top-left',
      'context-menu__menu--top-right': !fixed && position === 'top-right',
      'context-menu__menu--fixed': fixed,
      'context-menu__menu--visible': isOpen,
    });
    return (
      <ul
        ref={(element) => {
          this.menuElement = element;
        }}
        onClick={closeOnSelectItem && this.hide}
        style={fixed ? { left: fixedPosition.x, top: fixedPosition.y } : {}}
        className={`context-menu__menu ${menuModifierClass}`}
      >
        {children}
      </ul>
    );
  };

  render() {
    const { isOpen } = this.state;
    const {
      triggerClass,
      triggerSize,
      triggerPadding,
      fixed,
      preventDefaultOnClick,
    } = this.props;
    const containerClasses = cx({
      'context-menu--open': isOpen,
    });
    return (
      <div
        onClick={(e) => {
          if (preventDefaultOnClick) e.preventDefault();
          e.stopPropagation();
        }}
        className={`context-menu ${containerClasses}`}
        ref={(c) => (this.componentRef = c)}
      >
        <ContextMenuTrigger
          onClick={this.onTriggerClick}
          className={triggerClass}
          size={triggerSize}
          padding={triggerPadding}
        />
        {fixed ? (
          <Portal id="overlay-root">{this.renderMenu()}</Portal>
        ) : (
          this.renderMenu()
        )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ContextMenu);
export const contextMenuCloseEvent = closeEvent;
