import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import cx from 'classnames';
import Icon from './Icon';
import { Chart as ChartJS, ArcElement } from 'chart.js';
ChartJS.register(ArcElement);

const themes = {
  green: {
    background: '#009d8c',
    value: '#a7efe3',
  },
  grey: {
    background: '#b4b4b4',
    value: '#e6e6e6',
  },
  'light-grey': {
    value: '#f7f7f7',
    background: '#e6e6e6',
  },
};

class Donut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    const { value = 0, size = 54, showLabel, className, cutout, color, icon, text } = this.props;
    const data = {
      datasets: [
        {
          data: [value, 100 - value],
          backgroundColor: [themes[color].background, 'transparent'],
          borderWidth: 0,
        },
      ],
    };
    const dataBackground = {
      datasets: [
        {
          data: [100],
          backgroundColor: [themes[color].value],
          borderWidth: 0,
        },
      ],
    };
    const options = {
      aspectRatio: 1,
      layout: {
        padding: 2,
      },
      tooltips: { enabled: false },
      cutout,
      hover: { mode: null },
      legend: {
        display: false,
      },
    };
    return (
      <div className={cx('donut', { ['donut--' + color]: color })}>
        <div className="donut__inner" style={{ width: `${size}px`, height: `${size}px` }}>
          {icon && <Icon kind={icon} className="donut__icon" />}
          <div className="donut__background">
            <Doughnut data={dataBackground} height={size} width={size} options={{ ...options, animation: false }} />
          </div>
          <div className="donut__value">
            <Doughnut data={data} height={size} width={size} options={options} />
          </div>
          {showLabel && <span className="donut__label">{value}%</span>}
        </div>
        {text && <span className="donut__text">{text}</span>}
      </div>
    );
  }
}

Donut.defaultProps = {
  cutout: '70%',
  color: 'green',
};

export default Donut;
