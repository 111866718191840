import React, { Component } from 'react';
import DragAndDropHandle from './DragAndDropHandle';

// Cómponents
import Input from './formElements/Input';

class ElementAddBox extends Component {
  constructor() {
    super();
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      elementName: '',
    };
  }
  componentDidMount() {
    this.nameInput.focus();
  }

  onInputChange(e) {
    this.setState({
      elementName: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.elementName.trim().length > 0) {
      this.props.onSubmit(this.state.elementName);
      this.setState({
        elementName: '',
      });
    }
  }

  render() {
    const { placeholder, onBlur } = this.props;

    return (
      <div className="editor-element editor-element--add-element">
        <div className="editor-element-wrapper">
          <DragAndDropHandle />
          <div className="editor-element__info">
            <Input
              ref={(input) => {
                this.nameInput = input;
              }}
              kind="invisible"
              className="editor-element__info-input"
              onChange={this.onInputChange}
              onBlur={onBlur}
              type="text"
              value={this.state.elementName}
              placeholder={placeholder}
              onEnterPress={this.onSubmit}
            />
          </div>
          <div className="editor-element__options">
            <button className={`ui-btn ui-btn-${this.state.elementName.trim().length > 0 ? 'darkui' : 'inactive'} editor-element__options-add-btn`} onMouseDown={this.onSubmit}>
              Tilføj
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ElementAddBox;
