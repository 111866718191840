/* eslint-disable no-case-declarations */
import {
  getActiveSpaceService,
  getAllSpacesService,
  getSpaceStatusService,
  setActiveSpaceService,
  setSpaceStatusService,
} from '../services/contentService';

// Actions
const LOAD_ALL = 'spaces/LOAD_ALL';
const LOAD_SPACES = 'spaces/LOAD_SPACES';
const REMOVE_SPACE = 'spaces/REMOVE_SPACE';
const LOAD_ACTIVE_SPACE = 'spaces/LOAD_ACTIVE_SPACE';
const SET_ACTIVE_SPACE = 'spaces/SET_ACTIVE_SPACE';
const LOAD_SPACE_STATUS = 'spaces/LOAD_SPACE_STATUS';
const SET_SPACE_STATUS = 'spaces/SET_SPACE_STATUS';

const defaultState = {
  spaces: {},
  activeSpaceId: '',
  enabled: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_ACTIVE_SPACE:
      return { ...state, activeSpaceId: action.activeSpaceId };
    case LOAD_ALL:
      return {
        ...state,
        spaces: { ...state.spaces, ...action.spaces },
      };
    case LOAD_SPACES:
      return { ...state, spaces: [...state.spaces, ...action.spaces] };
    case REMOVE_SPACE:
      const { [action.id]: value, ...withoutItem } = state.spaces;
      return { ...state, spaces: withoutItem };
    case SET_ACTIVE_SPACE:
      return { ...state, activeSpaceId: action.activeSpaceId };
    case SET_SPACE_STATUS:
    case LOAD_SPACE_STATUS:
      return { ...state, enabled: action.enabled };
    default:
      return state;
  }
};

export const getAllSpaces = () => (dispatch) =>
  getAllSpacesService([]).then(({ data }) => {
    dispatch({ type: LOAD_ALL, spaces: data });
    return data;
  });

export const getActiveSpace = () => async (dispatch) => {
  const { data: spaces } = await getAllSpacesService([]);
  return getActiveSpaceService().then(async ({ data }) => {
    const isSpaceAccessible = spaces[data]?.accessible;
    if (!isSpaceAccessible) {
      const firstAcessibleSpace = Object.values(spaces).find(
        (space) => space.accessible
      );
      await dispatch(setActiveSpace(firstAcessibleSpace.id));
      dispatch({
        type: LOAD_ACTIVE_SPACE,
        activeSpaceId: firstAcessibleSpace.id,
      });
      return firstAcessibleSpace.id;
    } else {
      dispatch({ type: LOAD_ACTIVE_SPACE, activeSpaceId: data });
      return data;
    }
  });
};

export const setActiveSpace = (id) => (dispatch) =>
  setActiveSpaceService(id).then(() => {
    dispatch({ type: SET_ACTIVE_SPACE, activeSpaceId: id });
  });

export const removeSpace = (id) => (dispatch) => {
  dispatch({ type: REMOVE_SPACE, id });
};

export const getSpaceStatus = () => (dispatch) =>
  getSpaceStatusService().then(({ data }) => {
    dispatch({
      type: LOAD_SPACE_STATUS,
      enabled: data === 'Activated' ? true : false,
    });
    return data;
  });

export const setSpaceStatus = (enable) => (dispatch) =>
  setSpaceStatusService(enable).then(() => {
    return getSpaceStatusService().then(({ data }) => {
      return dispatch({
        type: SET_SPACE_STATUS,
        enabled: data === 'Activated' ? true : false,
      });
    });
  });
