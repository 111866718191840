import React, { Component } from 'react';
import { compareLocal, sortBy } from '../../utils/helpers';
import SwitchCheckbox from '../SwitchCheckbox';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import { ORGANISATION_UNIT_STATES } from '../../constants';
import Text from '../Text';
import localeLookup from '../../config/locale';

class OrganisationUnitModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      selectedUnits: props.selectedOrganisationUnitIds || [],
    };
  }

  onClickToggleUnit = (id) => {
    const { selectedUnits } = this.state;
    if (selectedUnits.includes(id)) {
      this.setState({
        selectedUnits: selectedUnits.filter(
          (addedUnitId) => addedUnitId !== id
        ),
      });
    } else {
      this.setState({
        selectedUnits: [...selectedUnits, id],
      });
    }
  };

  onConfirm = () => {
    const { onConfirm, onClose } = this.props;
    const { selectedUnits } = this.state;
    onConfirm(selectedUnits);
    onClose();
  };

  renderOrganisationTree = (
    organisationUnit,
    organisationUnits,
    isChild = false
  ) => {
    const { selectedUnits } = this.state;
    const { name, descendants, id } = organisationUnit;
    const isChecked = selectedUnits.includes(id);
    const isVisibilityLimited =
      organisationUnit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
      organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;

    return (
      <li style={{ marginLeft: isChild ? '1rem' : '0' }}>
        <label
          className="organisation-unit-modal__organisation-unit-wrapper"
          htmlFor={id}
        >
          <div className="organisation-unit-modal__organisation-unit-text">
            <p className="organisation-unit-modal__organisation-unit-title">
              {name}
            </p>
            {isVisibilityLimited && (
              <Text size="sm" color="dark-grey">{localeLookup('translations.Limited visibility')}</Text>
            )}
          </div>
          <SwitchCheckbox
            isChecked={isChecked}
            id={id}
            onChange={() => this.onClickToggleUnit(id)}
          />
        </label>
        {descendants &&
          sortBy(descendants, [
            (a, b) =>
              compareLocal(
                organisationUnits[a]?.name,
                organisationUnits[b]?.name
              ),
          ]).map((key) => (
            <ul
              className="organisation-unit-modal__organisation-unit-list"
              key={key}
            >
              {this.renderOrganisationTree(
                organisationUnits[key],
                organisationUnits,
                true
              )}
            </ul>
          ))}
      </li>
    );
  };

  render() {
    const { isSubmitting } = this.state;
    const { onClose, organisationUnits, title, subtitle } = this.props;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalBody deep>
          <ul className="organisation-unit-modal__organisation-unit-list">
            {this.renderOrganisationTree(
              organisationUnits[Object.keys(organisationUnits)[0]],
              organisationUnits
            )}
          </ul>
        </ModalBody>
        <ModalFooter
          confirmDisabled={isSubmitting}
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
        />
      </ModalWrapper>
    );
  }
}

export default OrganisationUnitModal;
