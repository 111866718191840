import React from 'react';
import InputLabel from './InputLabel';

export const FakeFieldButton = ({
  label,
  value,
  onClick,
  disabled,
  placeholder,
}) => {
  return (
    <div className="fake-field-button">
      <InputLabel>{label}</InputLabel>
      <button
        onClick={onClick}
        className="fake-field-button__button"
        type="button"
        disabled={disabled}
      >
        {value && value !== '' ? (
          value
        ) : (
          <span className="fake-field-button__placeholder">{placeholder}</span>
        )}
      </button>
    </div>
  );
};
