import { reducer as notifications } from 'react-notification-system-redux';
import { combineReducers } from 'redux';
import areasSlice from '../slices/areasSlice';
import categoriesSlice from '../slices/categoriesSlice';
import editorSlice from '../slices/editorSlice';
import elementsSlice from '../slices/elementsSlice';
import organisationUnitsSlice from '../slices/organisationUnitsSlice';
import personsReducer from '../slices/personsSlice';
import rolesSlice from '../slices/rolesSlice';
import spaceSlice from '../slices/spaceSlice';
import wildcardPersonsSlice from '../slices/wildcardPersonsSlice';
import header from './headerReducer';
import organisation from './organisationReducer';
import permissions from './permissionsReducer';
import personEditor from './personEditorReducer';
import roleReducer from './roleReducer';
import rootmenu from './rootmenuReducer';
import statusTable from './statusTableReducer';
import system from './systemReducer';
import systemSettings from './systemSettingsReducer';
import taskList from './taskListReducer';
import trainee from './traineeReducer';
import trainingAndOwnerships from './trainingAndOwnershipsReducer';
import trainingReducer from './trainingReducer';
import user from './userReducer';
import userSettings from './userSettingsReducer';
import groupsSlice from '../slices/groupsSlice';
import trainingSessionsSlice from '../slices/trainingSessionsSlice';

const rootReducer = combineReducers({
  areas: areasSlice,
  categories: categoriesSlice,
  elements: elementsSlice,
  editor: editorSlice,
  header,
  groups: groupsSlice,
  notifications,
  organisation,
  organisationUnits: organisationUnitsSlice,
  permissions,
  personEditor,
  persons: personsReducer,
  role: roleReducer,
  roles: rolesSlice,
  rootmenu,
  statusTable,
  system,
  systemSettings,
  taskList,
  trainee,
  training: trainingReducer,
  trainingSessions: trainingSessionsSlice,
  trainingAndOwnerships,
  spaces: spaceSlice,
  user,
  userSettings,
  wildcardPersons: wildcardPersonsSlice,
});

export default rootReducer;
