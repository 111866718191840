import cx from 'classnames';
import React, { PureComponent } from 'react';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, EMPTY_ID } from '../constants';
import { compareLocal, getCurrentDate, sortBy } from '../utils/helpers';
import AccessLevelWrapper from './AccessLevelWrapper';
import Button from './Button';
import WithModals from './HOC/withModals';

class StatusTableHeader extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }

  getMediatorOptions = () => {
    const { persons } = this.props;
    const sortedPersonIds = sortBy(Object.keys(persons), [
      (a, b) => compareLocal(persons[a]?.name, persons[b]?.name),
    ]);
    return sortedPersonIds.reduce(
      (acc, id) => {
        const person = persons[id];
        if (person.isActive) {
          acc.push({
            value: id,
            label: person.name,
            tooltip: `${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          });
        }
        return acc;
      },
      [
        {
          label: localeLookup('translations.Standard setting'),
          value: EMPTY_ID,
        },
      ]
    );
  };

  onClickAdminApproval = () => {
    const { showModal, hideModal, onSubmit, numSelected } = this.props;
    showModal('adminApproval', {
      maxWidth: '500px',
      infoText: localeLookup(
        "translations.This action overrides the elements' completion requirements and adds an admin completion to the selected elements"
      ),
      subtitle: `${
        numSelected === 1
          ? localeLookup('translations.{0} selected element', [numSelected])
          : localeLookup('translations.{0} selected elements', [numSelected])
      }`,
      onConfirm: (note, date) => {
        hideModal();
        onSubmit({ action: 'adminApproval', note, validFromDate: date });
      },
    });
  };

  onClickChangeMediator = () => {
    const { showModal, onSubmit, numSelected } = this.props;
    const options = this.getMediatorOptions();
    showModal('radioButton', {
      title: localeLookup('translations.Change responsible'),
      subtitle: `${
        numSelected === 1
          ? localeLookup('translations.{0} selected element', [numSelected])
          : localeLookup('translations.{0} selected elements', [numSelected])
      }`,
      infoText: localeLookup(
        'translations.This action will change the responsible for the selected elements'
      ),
      fullWidth: true,
      options,
      selectedValue: null,
      disableConfirmIfValueIsNull: true,
      confirmBtnText: localeLookup('translations.Change'),
      onConfirm: (mediatorId) => {
        onSubmit({ action: 'mediator', mediator: mediatorId });
      },
    });
  };

  onClickCompleteElements = () => {
    const { onSubmit } = this.props;
    onSubmit({ action: 'complete' });
  };

  onClickResetStatus = (isAdminReset) => {
    const { showModal, onSubmit, hideModal, numSelected } = this.props;
    showModal('resetStatus', {
      title: isAdminReset
        ? localeLookup('translations.Add reset as admin')
        : localeLookup('translations.Add reset'),
      subtitle: `${
        numSelected === 1
          ? localeLookup('translations.{0} selected element', [numSelected])
          : localeLookup('translations.{0} selected elements', [numSelected])
      }`,
      maxWidth: '500px',
      infoText: isAdminReset
        ? localeLookup(
            "translations.This action ignores if the elements are locked and adds an admin reset to the selected elements. The elements' history will be preserved"
          )
        : localeLookup(
            "translations.This action adds a reset to the selected elements which you have permission to reset. The elements' history will be preserved"
          ),
      onConfirm: (note) => {
        hideModal();
        onSubmit({
          action: isAdminReset ? 'adminResetStatus' : 'resetStatus',
          note,
          validFromDate: getCurrentDate().format('YYYY-MM-DD'),
        });
      },
    });
  };

  render() {
    const { isLoading } = this.state;
    const { numSelected } = this.props;
    const modifierClasses = {
      'status-table-header--state-visible': numSelected > 0,
    };
    return (
      <div className={cx('status-table-header', modifierClasses)}>
        <p className="status-table-header__text">
          {numSelected === 1
            ? localeLookup('translations.{0} selected element', [numSelected])
            : localeLookup('translations.{0} selected elements', [numSelected])}
        </p>
        <div className="status-table-header__action">
          <Button
            onClick={this.onClickCompleteElements}
            className="status-table-header__button"
            disabled={isLoading}
            size="small"
            kind="darkui"
          >
            {localeLookup('translations.Complete')}
          </Button>
          <Button
            onClick={this.onClickChangeMediator}
            className="status-table-header__button"
            disabled={isLoading}
            size="small"
            kind="darkui"
          >
            {localeLookup('translations.Change responsible')}
          </Button>
          <Button
            onClick={() => this.onClickResetStatus(false)}
            className="status-table-header__button"
            disabled={isLoading}
            size="small"
            kind="darkui"
          >
            {localeLookup('translations.Add reset')}
          </Button>
          <AccessLevelWrapper
            acceptedLevels={[
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.teamadministrator,
            ]}
          >
            <Button
              onClick={this.onClickAdminApproval}
              className="status-table-header__button status-table-header__button--extra-spacing"
              disabled={isLoading}
              size="small"
              kind="darkui"
            >
              {localeLookup('translations.Complete as admin')}
            </Button>
            <Button
              onClick={() => this.onClickResetStatus(true)}
              className="status-table-header__button"
              disabled={isLoading}
              size="small"
              kind="darkui"
            >
              {localeLookup('translations.Add reset as admin')}
            </Button>
          </AccessLevelWrapper>
        </div>
      </div>
    );
  }
}

StatusTableHeader.propTypes = {};

export default WithModals(StatusTableHeader);
