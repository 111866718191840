import React, { Component } from 'react';
import ErrorMessage from '../formElements/ErrorMessage';
import FormWrapper from '../formElements/FormWrapper';
import Input from '../formElements/Input';
import GenericModal from './GenericModal';
import * as Yup from 'yup';
import localeLookup from '../../config/locale';
import { validateEmployeeNumberService } from '../../services/personsService';
import { debounce } from 'lodash';

export default class ChangeEmployeeNumberModal extends Component {
  render() {
    const {
      onConfirm,
      confirmButtonText,
      inputPlaceholder,
      onCloseClick,
      title,
      defaultValue,
      subtitle,
    } = this.props;

    const validateEmployeeNumber = async (value, values) => {
      if (value === defaultValue) return true;
      try {
        await validateEmployeeNumberService(value);
        return true;
      } catch (error) {
        return false;
      }
    };


    return (
      <FormWrapper
        onSubmit={onConfirm}
        initialValues={{ employeeNumber: defaultValue }}
        validationSchema={Yup.object().shape({
          employeeNumber: Yup.string()
            .notRequired()
            .test(
              'is-valid',
              localeLookup('translations.The employee number is already in use'),
              async (value, values) => {
                const isValid = await validateEmployeeNumber(value, values);
                return isValid === true;
              }
            ),
        })}
      >
        {(props) => {
          const { errors, handleSubmit, values, dirty, handleChange, isValid } =
            props;
          return (
            <GenericModal
              confirmButtonText={confirmButtonText}
              onCancelClick={onCloseClick}
              onClose={onCloseClick}
              onConfirmClick={handleSubmit}
              title={title}
              subtitle={subtitle}
              confirmDisabled={!isValid || !dirty}
            >
              <form onSubmit={handleSubmit}>
                <Input
                  maxLength={10}
                  autoFocus
                  fullWidth
                  placeholder={inputPlaceholder}
                  id="employeeNumber"
                  value={values.employeeNumber}
                  onChange={handleChange}
                />
                <ErrorMessage show={errors.employeeNumber}>
                  {errors.employeeNumber}
                </ErrorMessage>
              </form>
            </GenericModal>
          );
        }}
      </FormWrapper>
    );
  }
}
