import React, { Component } from 'react';
import { debounce } from 'lodash';

class Counter extends Component {
  constructor(props) {
    super(props);
    const value =
      this.props.zeroAsDash && this.props.value === 0 ? '-' : this.props.value.toString();
    this.state = {
      localValue: value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.localValue) {
      const value =
        this.props.zeroAsDash && nextProps.value === 0 ? '-' : nextProps.value.toString();
      this.setState({ localValue: value });
    }
  }

  onBlur = () => {
    const { localValue } = this.state;
    const { value, zeroAsDash } = this.props;
    if (localValue === '0' || localValue === '') {
      this.setState(
        {
          localValue: zeroAsDash ? '-' : '0',
        },
        this.onChange
      );
    } else if (localValue !== value) {
      this.onChange();
    }
  };

  onChange = debounce(() => {
    const { handleChange } = this.props;
    const { localValue } = this.state;
    const valueAsInt = parseInt(localValue);
    if (localValue === '-') {
      handleChange(0);
    } else {
      handleChange(valueAsInt);
    }
  }, 1000);

  onKeyPress = (e) => {
    const { localValue } = this.state;
    const { value, zeroAsDash } = this.props;
    if (e.key === 'Enter') {
      if (localValue === '0' || localValue === '') {
        this.setState(
          {
            localValue: zeroAsDash ? '-' : '0',
          },
          this.onChange
        );
      } else if (localValue !== value) {
        this.onChange();
      }
    }
  };

  onInputChange = (e) => {
    const { value } = e.target;
    const valueAsInt = parseInt(value);
    if (value === '') {
      this.setState({
        localValue: value,
      });
    } else if (!isNaN(valueAsInt) && valueAsInt >= 0) {
      this.setState(
        {
          localValue: valueAsInt.toString(),
        },
        this.onChange
      );
    }
  };

  onDecrement = () => {
    const { localValue } = this.state;
    const { zeroAsDash } = this.props;
    const localValueAsInt = parseInt(localValue);
    if (localValue === '' || localValue === '-' || localValueAsInt === 1) {
      this.setState(
        {
          localValue: zeroAsDash ? '-' : '0',
        },
        this.onChange
      );
    } else if (localValueAsInt > 0) {
      this.setState(
        {
          localValue: (localValueAsInt - 1).toString(),
        },
        this.onChange
      );
    }
  };

  onIncrement = () => {
    const { localValue } = this.state;
    const localValueAsInt = parseInt(localValue);
    if (localValue === '' || localValue === '-' || isNaN(localValueAsInt)) {
      this.setState(
        {
          localValue: '1',
        },
        this.onChange
      );
    } else {
      this.setState(
        {
          localValue: (localValueAsInt + 1).toString(),
        },
        this.onChange
      );
    }
  };

  render() {
    const { count, disabled } = this.props;
    const { localValue } = this.state;
    return (
      <div className="counter">
        <button
          type="button"
          className="counter__button"
          onClick={this.onDecrement}
          disabled={disabled}
        >
          -
        </button>
        <input
          autoComplete="off"
          className="counter__input"
          disabled={disabled}
          name="counter"
          value={localValue}
          onBlur={this.onBlur}
          onChange={this.onInputChange}
          onKeyPress={this.onKeyPress}
          placeholder={count}
        />
        <button
          type="button"
          className="counter__button"
          onClick={this.onIncrement}
          disabled={disabled}
        >
          +
        </button>
      </div>
    );
  }
}

export default Counter;
