import {
  ROLE_RECEIVED,
  ELEMENT_RECIEVED,
  ELEMENTS_RECIEVED,
  AREA_RECIEVED,
  COMPLETION_PERCENTAGE_RECEIVED,
  SET_LOADING_STATE,
} from '../actions/roleActions';
import { MENTOR_ID } from '../constants';

const roleInitialState = {
  additionalKnowledgeAreasSortOrder: [],
  categories: {},
  completionPercentage: 0,
  description: '',
  id: null,
  isLoading: false,
  knowledgeAreas: {},
  knowledgeAreasSortOrder: [],
  knowledgeElements: {},
  menteeId: '',
  mentor: '',
  name: '',
  persons: {},
  wildcardPersons: {
    '00000000-0000-0000-0000-000000000001': {
      id: '00000000-0000-0000-0000-000000000001',
      name: 'Person themself',
      initials: 'S',
      email: '',
    },
    '00000000-0000-0000-0000-000000000002': {
      id: '00000000-0000-0000-0000-000000000002',
      name: 'Knowledge owner',
      initials: 'K',
      email: '',
    },
    '00000000-0000-0000-0000-000000000003': {
      id: '00000000-0000-0000-0000-000000000003',
      name: 'Expert',
      initials: 'E',
      email: '',
    },
    [MENTOR_ID]: {
      id: MENTOR_ID,
      name: 'Mentor',
      initials: 'M',
      email: '',
    },
    '00000000-0000-0000-0000-000000000004': {
      id: '00000000-0000-0000-0000-000000000004',
      name: 'Deleted person',
      initials: 'N/A',
      email: '',
    },
  },
};

const role = (state = roleInitialState, action) => {
  switch (action.type) {
    case AREA_RECIEVED: {
      return {
        ...state,
        knowledgeAreas: {
          ...state.knowledgeAreas,
          [action.id]: { ...action.area },
        },
      };
    }
    case COMPLETION_PERCENTAGE_RECEIVED: {
      return {
        ...state,
        completionPercentage: action.completionPercentage,
      };
    }
    case ELEMENT_RECIEVED: {
      return {
        ...state,
        knowledgeElements: {
          ...state.knowledgeElements,
          [action.id]: { ...action.element },
        },
      };
    }
    case ELEMENTS_RECIEVED: {
      return {
        ...state,
        knowledgeElements: {
          ...state.knowledgeElements,
          ...action.elements,
        },
      };
    }
    case ROLE_RECEIVED:
      return {
        ...state,
        ...action.data,
      };
    case SET_LOADING_STATE:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default role;
