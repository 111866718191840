import cx from 'classnames';
import React, { Component } from 'react';
import { linkifyString } from '../utils/helpers';

class ElementDescription extends Component {
  componentDidMount() {
    if (!this.richTextBody) return;
    const links = this.richTextBody.querySelectorAll('a');
    if (links) {
      links.forEach((link) => {
        link.target = '_blank';
      });
    }
  }

  render() {
    const { description, title, fullWidth } = this.props;
    const descriptionWithoutMarkupLength = description.replace(/<[^>]*>?/gm, '').trim().length;
    const bodyWithClickableLinks = descriptionWithoutMarkupLength !== 0 ? linkifyString(description) : null;
    const modifierClasses = {
      'element-description--full-width': fullWidth,
    };
    if (bodyWithClickableLinks && bodyWithClickableLinks.length > 0) {
      return (
        <div className={cx('element-description', modifierClasses)}>
          {title && (
            <div className="element-description__header">
              <div className="element-description__header-left">
                <h1>{title}</h1>
              </div>
            </div>
          )}
          <div ref={(el) => (this.richTextBody = el)} className="element-description__body" dangerouslySetInnerHTML={{ __html: bodyWithClickableLinks }} />
        </div>
      );
    }
    return null;
  }
}

export default ElementDescription;
