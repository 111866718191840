import React, { PureComponent } from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import ConditionalWrap from '../ConditionalWrapper';

class ContextMenuItem extends PureComponent {
  render() {
    const {
      className,
      disabled,
      leftIconClassName, // 'red' or 'green'
      leftIconColor,
      leftIconKind,
      onClick,
      rightComponent,
      rightIconClassName,
      rightIconKind,
      subtitleText,
      subtitleTextClassName,
      titleText,
      titleTextClassName,
      children,
      noClick,
      tooltip,
    } = this.props;
    if (children) {
      return (
        <li
          className={`context-menu-item context-menu-item--no-click context-menu-item--content ${cx(
            className
          )}`}
        >
          <div className="context-menu-item__header">
            {leftIconKind && (
              <Icon
                kind={leftIconKind}
                className={`context-menu-item__icon-left ${cx(
                  {
                    [`context-menu-item__icon-left--${leftIconColor}`]:
                      leftIconColor,
                  },
                  leftIconClassName
                )}`}
              />
            )}
            {titleText && (
              <div className="context-menu-item__text">
                <p
                  className={`context-menu-item__text-title ${cx(
                    titleTextClassName
                  )}`}
                >
                  {titleText}
                </p>
                {subtitleText && (
                  <p
                    className={`context-menu-item__text-subtitle ${cx(
                      subtitleTextClassName
                    )}`}
                  >
                    {subtitleText}
                  </p>
                )}
              </div>
            )}
            {rightIconKind && (
              <Icon
                kind={rightIconKind}
                className={`context-menu-item__icon-right ${cx(
                  rightIconClassName
                )}`}
              />
            )}
            {rightComponent && (
              <div className="context-menu-item__right">{rightComponent}</div>
            )}
          </div>
          <div className="context-menu-item__content">{children}</div>
        </li>
      );
    }
    return (
      <ConditionalWrap
        condition={!!tooltip}
        wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
      >
        <li
          className={`context-menu-item ${cx(className, {
            'context-menu-item--disabled': disabled,
            'context-menu-item--no-click': noClick,
          })}`}
          onTouchStart={
            !disabled
              ? (e) => {
                  e.stopPropagation();
                  onClick(e);
                }
              : null
          }
          onClick={!disabled ? onClick : null}
        >
          {leftIconKind && (
            <Icon
              kind={leftIconKind}
              className={`context-menu-item__icon-left ${cx(
                {
                  [`context-menu-item__icon-left--${leftIconColor}`]:
                    leftIconColor,
                },
                leftIconClassName
              )}`}
            />
          )}
          {titleText && (
            <div className="context-menu-item__text">
              <p
                className={`context-menu-item__text-title ${cx(
                  titleTextClassName
                )}`}
              >
                {titleText}
              </p>
              {subtitleText && (
                <p
                  className={`context-menu-item__text-subtitle ${cx(
                    subtitleTextClassName
                  )}`}
                >
                  {subtitleText}
                </p>
              )}
            </div>
          )}
          {rightIconKind && (
            <Icon
              kind={rightIconKind}
              className={`context-menu-item__icon-right ${cx(
                rightIconClassName
              )}`}
            />
          )}
          {rightComponent && (
            <div className="context-menu-item__right">{rightComponent}</div>
          )}
        </li>
      </ConditionalWrap>
    );
  }
}

export default ContextMenuItem;
