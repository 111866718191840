import React from 'react';
import localeLookup from '../../config/locale';
import CreateSpaceForm from '../CreateSpaceForm';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';

const CreateSpaceModal = ({ onClose, onCreated, initialName }) => (
  <FormModal onClose={onClose} title={localeLookup('translations.Create content space')}>
    <CreateSpaceForm onCreated={onCreated} initialName={initialName} renderFooter={({ handleSubmit, canSubmit }) => <ModalFooter confirmButtonText={localeLookup('translations.Create')} confirmDisabled={!canSubmit} onConfirmClick={handleSubmit} onCancelClick={onClose} />} />
  </FormModal>
);

export default CreateSpaceModal;
