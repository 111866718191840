import localeLookup from '../config/locale';

// Wildcard persons
export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
export const SELF_STUDY_ID = '00000000-0000-0000-0000-000000000001';
export const KNOWLEDGE_OWNER_ID = '00000000-0000-0000-0000-000000000002';
export const EXPERT_ID = '00000000-0000-0000-0000-000000000003';
export const DELETED_PERSON_ID = '00000000-0000-0000-0000-000000000004';
export const CHAMP_ID = '00000000-0000-0000-0000-000000000005';
export const MENTOR_ID = '00000000-0000-0000-0000-000000000006';
export const MENTEE_ID = '00000000-0000-0000-0000-000000000008';
export const NONE_ID = '00000000-0000-0000-0000-000000000011';
export const DELETED_GROUP_ID = '00000000-0000-0000-0000-000000000012';
export const ROLE_OWNER_ID = '00000000-0000-0000-0000-000000000020';
export const ROLE_EDITORS_ID = '00000000-0000-0000-0000-000000000021';

export const AUTH_ENDPOINT = () => {
  if (authServer !== undefined) {
    return authServer;
  }
  if (window.location.origin === 'https://localhost:3000') {
    return process.env.REACT_APP_AUTH_ENDPOINT;
  }

  return 'https://auth.champ.dk';
};

export const WILDCARDS_SORTING_RANK = {
  [EMPTY_ID]: 1,
  [SELF_STUDY_ID]: 2,
  [MENTOR_ID]: 3,
  [EXPERT_ID]: 4,
  [KNOWLEDGE_OWNER_ID]: 5,
};

// Misc
export const DEFAULT_EXPIRATION_WARNING_VALUE = 30;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Access levels
export const ACCESS_LEVELS = {
  champadministrator: 'champadministrator', // 'champadministrator'
  administrator: 'administrator', // 'administrator'
  contentAdministrator: 'contentadministrator',
  createTrainingSessions: 'createtrainingsessions',
  createGroups: 'creategroups',
  createRoles: 'createroles',
  createModules: 'createmodules',
  systemAdministrator: 'systemadministrator',
  teamadministrator: 'teamadministrator', // 'teamadministrator'
  trainingSessionAdministrator: 'trainingsessionadministrator',
  roleOwner: 'roleowner',
  roleEditor: 'roleeditor',
  moduleEditor: 'moduleeditor',
  moduleOwner: 'moduleowner',
  employee: 'employee',
  groupManager: 'groupmanager',
  createPersons: 'createpersons',
  deletePersons: 'deletepersons',
  groupAdministrator: 'groupadministrator',
  personAdministrator: 'personadministrator',
  userAdministrator: 'useradministrator',
  assignAccessLevels: 'assignaccesslevels',
};

export const ROLE_OPERATIONS = {
  ASSIGN_ROLE: 'AssignRole',
  CHANGE_LEVEL: 'ChangeLevel',
  REMOVE_ROLE: 'RemoveRole',
};

export const USER_TYPES = {
  userWithLogin: 'Champ',
  userWithExternalLogin: 'External',
  userWithoutLogin: 'NoAccess',
};

export const ROLE_LEVELS = {
  NONE: 'doesNotHaveRole',
  TRAINING: 'training',
  QUALIFIED: 'qualified',
  EXPERIENCED: 'experienced',
};

export const MAIN_STATUS_CATEGORIES = {
  NO_COMPLETION: 'NoCompletion',
  VALID_COMPLETION: 'ValidCompletion',
  INVALID_COMPLETION: 'InvalidCompletion',
};
export const STATUS_CATEGORIES = {
  NO_COMPLETION: 'NoCompletion',
  NO_COMPLETION_WITH_EXPIRATION: 'NoCompletionWithExpiration',
  VALID_COMPLETION: 'ValidCompletion',
  VALID_COMPLETION_WITH_EXPIRATION: 'ValidCompletionWithExpiration',
  VALID_COMPLETION_EXPIRING: 'ValidCompletionExpiring',
  INVALID_COMPLETION_EXPIRED: 'InvalidCompletionExpired',
  INVALID_COMPLETION_ONGOING: 'InvalidCompletionOngoing',
};

export const PENDING_STATES = {
  NONE: 'None',
  ALL: 'All',
  YOU: 'You',
  OTHER_TRAINER: 'OtherTrainer',
  OTHER_TRAINEE: 'OtherTrainee',
  COUNTER_PART_TRAINER: 'CounterPartTrainer',
  COUNTER_PART_TRAINEE: 'CounterPartTrainee',
};

export const PERSON_STATES = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  HIDDEN: 'Hidden',
};

export const VALID_PERSON_STATES = [
  PERSON_STATES.ACTIVE,
  PERSON_STATES.ARCHIVED,
];

export const ACTION_STATES = {
  COMPLETE: 'Complete',
  COMPLETE_WITH_SIGNATURE: 'CompleteWithSignature',
  LOCKED: 'Locked',
  REQUEST_SIGNATURE: 'CollectSignature',
  PENDING_COUNTERPART: 'PendingCounterpart',
};

export const LOCK_STATES = {
  LOCKED: 'Locked',
  UNLOCKED: 'Unlocked',
};

export const EVENT_TYPES = {
  STANDARD: 'Standard',
  MULTIPART: 'Multipart',
  ADMIN_APPROVAL: 'AdminApproval',
  ADMIN_RESET_STATUS: 'AdminResetStatus',
  RESET_STATUS: 'ResetStatus',
};

export const EVENT_NAMES = {
  [EVENT_TYPES.ADMIN_RESET_STATUS]: localeLookup('translations.Admin reset'),
  [EVENT_TYPES.STANDARD]: localeLookup('translations.Completion'),
  [EVENT_TYPES.ADMIN_APPROVAL]: localeLookup('translations.Admin completion'),
  [EVENT_TYPES.RESET_STATUS]: localeLookup('translations.ResetEvent'),
};

export const TRAINING_REGISTRATION_TYPES = {
  STANDARD: 'Standard',
  MULTIPART: 'Multipart',
  ADMIN_APPROVAL: 'AdminApproval',
  ADMIN_RESET_STATUS: 'AdminResetStatus',
  RESET_STATUS: 'ResetStatus',
  SESSION_EMPLOYEE_SIGNATURE: 'SessionEmployeeSignature',
  SESSION_TRAINER_SIGNATURE: 'SessionTrainerSignature',
};

export const RELEVANCE_STATES = {
  RELEVANT: 'Relevant',
  NOT_RELEVANT: 'NotRelevant',
};

export const ROLE_STATES = {
  TRAINING: localeLookup('translations.Training'),
  QUALIFIED: localeLookup('translations.Qualified'),
  EXPERIENCED: localeLookup('translations.Experienced'),
};

export const ELEMENT_TYPES = {
  CRITICAL: 'Critical',
  STANDARD: 'Standard',
};

export const VERSION_TYPES = {
  NAME: 'Name',
  CREATED: 'Created',
  MIGRATED: 'Migrated',
  DESCRIPTION_EDITED: 'DescriptionEdited',
  DESCRIPTION_ADDED: 'DescriptionAdded',
  DESCRIPTION_REMOVED: 'DescriptionRemoved',
  FILE_ADDED: 'FileAdded',
  FILE_REMOVED: 'FileRemoved',
};

export const ROLE_MATRIX_COMPLETION_STATES = {
  NONE: 'None',
  ALL: 'All',
  TRAINING: 'Training',
};

export const ROLE_MATRIX_PROGRESS_VIEWS = {
  NONE: { value: 'none', amount: '', title: localeLookup('translations.None') },
  DAY: {
    value: 'day',
    amount: '1',
    title: 'translations.day',
  },
  THREE_DAYS: {
    value: 'threeDays',
    amount: '3',
    title: 'translations.days',
  },
  WEEK: {
    value: 'week',
    amount: '1',
    title: 'translations.week',
  },
  TWO_WEEKS: {
    value: 'twoWeeks',
    amount: '2',
    title: 'translations.weeks',
  },
  MONTH: {
    value: 'month',
    amount: '1',
    title: 'translations.month',
  },
};

export const TIME_FORMAT_STRINGS = {
  TwentyFourHour: 'HH:mm',
  TwelveHour: 'h:mm A',
};

export const DATE_FORMAT_STRINGS = {
  DayMonthYear: 'DD.MM.YYYY',
  MonthDayYear: 'MM.DD.YYYY',
  YearMonthDay: 'YYYY.MM.DD',
};

export const ORGANISATION_UNIT_STATES = {
  ACTIVE: 'Active',
  PASSIVE: 'Passive',
  INHERITED_PASSIVE: 'InheritedPassive',
};
