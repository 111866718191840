import { isAfter, isBefore, isSameDay } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { EMPTY_ID, NONE_ID, PERSON_STATES, ROLE_LEVELS } from '../constants';
import { getGroups } from '../slices/groupsSlice';
import { getAllOrganisationUnits } from '../slices/organisationUnitsSlice';
import { getPersons } from '../slices/personsSlice';
import { getAllRoles } from '../slices/rolesSlice';
import { compareLocal, sortBy } from '../utils/helpers';
import Accordion from './Accordion';
import BoxMessage from './BoxMessage';
import Column from './Column';
import DateFieldV2 from './DateFieldV2';
import withAccessControl from './HOC/withAccessControl';
import Icon from './Icon';
import MultiSelect from './MultiSelect';
import { RadioButtonGroup } from './RadioButtonGroup';
import Row from './Row';
import SwitchCheckbox from './SwitchCheckbox';
import Tooltip from './Tooltip';
import AutocompleteSelect from './formElements/AutocompleteSelect';
import ErrorMessage from './formElements/ErrorMessage';
import Field from './formElements/Field';
import FormWrapper from './formElements/FormWrapper';
import ModalBody from './modal/ModalBody';

const mapStateToProps = (state) => {
  return {
    persons: state.persons,
    roles: state.roles,
    groups: state.groups,
    organisationUnits: state.organisationUnits,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getPersons, getAllRoles, getAllOrganisationUnits, getGroups },
    dispatch
  );

class AssignRolesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isLoading: true,
      overwriteRoleLevels: false,
    };
    this.formRef = React.createRef(null);
  }

  componentDidMount() {
    const { getPersons, getAllOrganisationUnits, getAllRoles, getGroups } =
      this.props;
    Promise.all([
      getPersons(),
      getGroups(),
      getAllOrganisationUnits(),
      getAllRoles(),
    ]).then(() => {
      this.setState({ isLoading: false });
    });
  }

  getGroupedMentorOptions = () => {
    const { groups } = this.props;
    const options = this.getMentorOptions();
    const groupedOptions = options.reduce(
      (acc, option) => {
        const level = option.level;
        return {
          ...acc,
          [level]: {
            ...acc[level],
            options: [...acc[level].options, option],
          },
        };
      },
      {
        NoGroup: {
          options: [],
        },
        Groups: {
          label: localeLookup('translations.Groups'),
          options: [],
        },
        doesNotHaveRole: {
          label: localeLookup('translations.Other persons'),
          options: [],
        },
      }
    );
    return Object.values(groupedOptions);
  };

  getMentorOptions = () => {
    const { persons, groups } = this.props;
    const selectedPersonIds = this.formRef?.current?.values?.personIds;
    const options = sortBy(
      Object.keys(persons)
        .filter((id) => {
          const isActive = persons[id].state === PERSON_STATES.ACTIVE;
          const isNotSelectedAsPerson = !selectedPersonIds?.includes(id);
          return isActive && isNotSelectedAsPerson;
        })
        .map((id) => {
          const person = persons[id];
          return {
            level: ROLE_LEVELS.NONE,
            label: `${person.name} (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber})` : ')'
            }`,
            value: id,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
    return [
      ...options,
      ...sortBy(
        Object.values(groups)
          .filter((group) => group.assignableAs.includes('Mentor'))
          .map((group) => ({
            label: group.name,
            value: group.id,
            searchString: group.name,
            level: 'Groups',
          })),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
      {
        label: localeLookup('translations.No mentor'),
        value: NONE_ID,
        level: 'NoGroup',
        searchString: localeLookup('translations.No mentor'),
      },
    ];
  };

  getMentorValue = () => {
    const selectedMentorId = this.formRef?.current?.values?.mentorId;
    const options = this.getMentorOptions();
    const selectedOption = options.find(
      (option) => option.value === selectedMentorId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  getRoleLevelOptions = () => {
    return [
      {
        label: localeLookup('translations.Training'),
        value: ROLE_LEVELS.TRAINING,
      },
      {
        label: localeLookup('translations.Qualified'),
        value: ROLE_LEVELS.QUALIFIED,
      },
      {
        label: localeLookup('translations.Experienced'),
        value: ROLE_LEVELS.EXPERIENCED,
      },
    ];
  };

  getPersonOptions = () => {
    const { persons, organisationUnits, organisationUnitId } = this.props;
    const options = sortBy(
      Object.keys(persons)
        .filter((id) => {
          const organisationUnit = organisationUnits[organisationUnitId];
          return (
            organisationUnit?.employees?.includes(id) &&
            persons[id].state === PERSON_STATES.ACTIVE
          );
        })
        .map((id) => {
          const person = persons[id];
          return {
            label: `${person.name} (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber})` : ')'
            }`,
            value: id,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
    return options;
  };

  getPersonValue = () => {
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    const selectedPersonId = this.formRef?.current?.values?.personId;
    const options = this.getPersonOptions(selectedOrganisationUnitId);
    const selectedOption = options.find(
      (option) => option.value === selectedPersonId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  getRoleOptions = () => {
    const { roles, organisationUnits, organisationUnitId } = this.props;
    return sortBy(
      Object.keys(roles)
        .filter((id) => {
          const organisationUnit = organisationUnits[organisationUnitId];
          return organisationUnit?.roles?.includes(id);
        })
        .map((id) => {
          const role = roles[id];
          return {
            label: role.name,
            value: id,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
  };

  getRoleValue = () => {
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    const selectedRoleId = this.formRef?.current?.values?.roleId;
    const options = this.getRoleOptions(selectedOrganisationUnitId);
    const selectedOption = options.find(
      (option) => option.value === selectedRoleId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      personIds: Yup.array()
        .min(1, localeLookup('translations.Persons is required'))
        .required(localeLookup('translations.Persons is required')),
      roleIds: Yup.array()
        .min(1, localeLookup('translations.Roles is required'))
        .required(localeLookup('translations.Roles is required')),
      roleLevel: Yup.string().required(
        localeLookup('translations.Role level is required')
      ),
      mentorId: Yup.string().when('roleLevel', {
        is: ROLE_LEVELS.TRAINING,
        then: () =>
          Yup.string()
            .test(
              'is-not-empty-id',
              localeLookup('translations.Mentor is required'),
              function (value) {
                if (value === EMPTY_ID) {
                  return false;
                }
                return true;
              }
            )
            .required(localeLookup('translations.Mentor is required')),
      }),
      startDate: Yup.string()
        .test(
          'is-before-end-date',
          localeLookup('translations.Start date must be before end date'),
          function (value) {
            if (this?.parent?.endDate) {
              const valueObj = new Date(value);
              const endDateObj = new Date(this.parent.endDate);
              return (
                isBefore(valueObj, endDateObj) ||
                isSameDay(valueObj, endDateObj)
              );
            } else {
              return true;
            }
          }
        )
        .required(localeLookup('translations.Start date is required')),
      endDate: Yup.string()
        .nullable()
        .test(
          'is-after-start-date',
          localeLookup('translations.End date must be after start date'),
          function (value) {
            if (this?.parent?.startDate && value && value !== '') {
              const valueObj = new Date(value);
              const startDateObj = new Date(this.parent.startDate);
              return (
                isAfter(valueObj, startDateObj) ||
                isSameDay(valueObj, startDateObj)
              );
            } else {
              return true;
            }
          }
        ),
    });
  };

  onChangeCreateOnly = () => {
    const { createOnly, onChangeCreateOnly } = this.props;
    onChangeCreateOnly(!createOnly);
  };

  onSubmit = (values) => {
    const { onConfirm } = this.props;
    onConfirm(values);
  };

  render() {
    const { isSubmitting, isLoading } = this.state;
    const { initialValues, renderFooter, createOnly } = this.props;

    const validationSchema = this.getValidationSchema();

    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return candidate.label.toLowerCase().includes(input.toLowerCase());
        }
      }
      return true;
    };

    const personOptions = this.getPersonOptions();
    const roleOptions = this.getRoleOptions();
    if (isLoading) return null;
    return (
      <FormWrapper
        ref={this.formRef}
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          endDate: '',
          mentorId: '',
          organisationUnitId: '',
          personId: '',
          roleId: '',
          startDate: initialValues.startDate
            ? new Date(initialValues.startDate)
            : new Date(),
          state: 'Active',
          ...initialValues,
        }}
      >
        {({
          handleSubmit,
          values,
          isValid,
          setFieldValue,
          touched,
          handleBlur,
          errors,
          setFieldTouched,
        }) => (
          <>
            {!createOnly && (
              <BoxMessage type="warning" spacing="md" icon="warning">
                {localeLookup(
                  'translations.Role levels and mentor, start- and end date for persons who already have the selected roles will be overwritten'
                )}
              </BoxMessage>
            )}
            <ModalBody>
              <form
                className="assign-roles-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Field required label={localeLookup('translations.Persons')}>
                  <MultiSelect
                    defaultValue={personOptions.filter((option) =>
                      values.personIds.includes(option.value)
                    )}
                    closeMenuOnSelect={false}
                    onChange={(selectedPersonValues) => {
                      if (
                        selectedPersonValues.includes(
                          (value) => value.value === values.mentorId
                        )
                      ) {
                        setFieldValue('mentorId', '');
                      }
                      setFieldValue(
                        'personIds',
                        selectedPersonValues.map((value) => value.value)
                      );
                    }}
                    filterOption={filterOption}
                    onBlur={() => setFieldTouched('personIds')}
                    name="personIds"
                    options={personOptions}
                    placeholder={localeLookup('translations.Select persons')}
                  />
                  <ErrorMessage show={touched.personIds && errors.personIds}>
                    {errors.personIds}
                  </ErrorMessage>
                </Field>
                <Field required label={localeLookup('translations.Roles')}>
                  <MultiSelect
                    defaultValue={roleOptions.filter((option) =>
                      values.roleIds.includes(option.value)
                    )}
                    closeMenuOnSelect={false}
                    onChange={(values) => {
                      setFieldValue(
                        'roleIds',
                        values.map((value) => value.value)
                      );
                    }}
                    onBlur={() => setFieldTouched('roleIds')}
                    filterOption={filterOption}
                    name="roleIds"
                    options={roleOptions}
                    placeholder={localeLookup('translations.Select roles')}
                  />
                  <ErrorMessage show={touched.roleIds && errors.roleIds}>
                    {errors.roleIds}
                  </ErrorMessage>
                </Field>
                <Field required label={localeLookup('translations.Role level')}>
                  <AutocompleteSelect
                    defaultValue={values.roleLevel}
                    name="roleLevel"
                    onBlur={() => setFieldTouched('roleLevel')}
                    onChange={(selectedOption) => {
                      setFieldValue('roleLevel', selectedOption.value);
                    }}
                    filterOption={filterOption}
                    options={this.getRoleLevelOptions()}
                    placeholder={localeLookup('translations.Select role level')}
                  ></AutocompleteSelect>
                  <ErrorMessage show={touched.roleLevel && errors.roleLevel}>
                    {errors.roleLevel}
                  </ErrorMessage>
                </Field>

                <SwitchCheckbox
                  wrapperClassName="assign-roles-form__switch-checkbox-wrapper"
                  labelSize="md"
                  onChange={this.onChangeCreateOnly}
                  isChecked={!createOnly}
                  name="createOnly"
                  labelText={localeLookup(
                    'translations.Overwrite role levels and mentor, start- and end date for persons who already have the selected roles'
                  )}
                />

                <Accordion isOpen={values.roleLevel === ROLE_LEVELS.TRAINING}>
                  <Field required label={localeLookup('translations.Mentor')}>
                    <AutocompleteSelect
                      value={this.getMentorValue()}
                      name="mentorId"
                      placeholder={localeLookup('translations.Select mentor')}
                      onBlur={() => setFieldTouched('mentorId')}
                      onChange={(selectedOption) => {
                        setFieldValue('mentorId', selectedOption.value);
                      }}
                      filterOption={filterOption}
                      options={this.getGroupedMentorOptions()}
                    />
                    <ErrorMessage show={touched.mentorId && errors.mentorId}>
                      {errors.mentorId}
                    </ErrorMessage>
                  </Field>

                  <Row align="start">
                    <Column size="6">
                      <Field
                        margin="sm"
                        required
                        label={localeLookup('translations.Start date')}
                      >
                        <DateFieldV2
                          error={touched.startDate && errors.startDate}
                          maxDate={values.endDate}
                          value={new Date(values.startDate)}
                          onBlur={handleBlur}
                          name="startDate"
                          id="startDate"
                          required
                          onChange={(value) => {
                            setFieldValue('startDate', value);
                          }}
                        />
                      </Field>
                    </Column>
                    <Column size="6">
                      <Field
                        margin="sm"
                        label={localeLookup('translations.End date')}
                      >
                        <DateFieldV2
                          error={touched.endDate && errors.endDate}
                          onBlur={handleBlur}
                          name="endDate"
                          id="endDate"
                          minDate={values.startDate}
                          value={values.endDate}
                          clearable
                          onClear={() => setFieldValue('endDate', '')}
                          onChange={(value) => {
                            setFieldValue('endDate', value);
                          }}
                        />
                      </Field>
                    </Column>
                  </Row>
                  <div>
                    <RadioButtonGroup
                      groupName="state"
                      options={[
                        {
                          value: 'Active',
                          label: localeLookup('translations.Visible'),
                          isChecked: values.state === 'Active',
                        },
                        {
                          value: 'Passive',
                          label: (
                            <Tooltip
                              overlayStyle={{
                                maxWidth: '500px',
                                whiteSpace: 'normal',
                              }}
                              tooltip={localeLookup(
                                'translations.Limited visibility means this training program will only be visible to administrators and organisation administrators. The person themself, mentors and responsibles will not be able to see the training program and tasks'
                              )}
                            >
                              <span>
                                {localeLookup(
                                  'translations.Limited visibility'
                                )}
                                <Icon
                                  className="checkbox-button__tooltip-icon"
                                  kind="info-circle"
                                ></Icon>
                              </span>
                            </Tooltip>
                          ),
                          isChecked: values.state === 'Passive',
                        },
                      ]}
                      label={`${localeLookup('translations.Visibility')}:`}
                      onChange={(e) => {
                        setFieldValue('state', e.target.id);
                      }}
                    ></RadioButtonGroup>
                  </div>
                </Accordion>
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAccessControl(AssignRolesForm));
