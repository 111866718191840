import { find, forOwn, pickBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  AutoSizer,
  defaultCellRangeRenderer,
  MultiGrid,
} from 'react-virtualized';
import { bindActionCreators } from 'redux';
import Button from '../../components/Button';
import ConditionalWrap from '../../components/ConditionalWrapper';
import ContextMenu from '../../components/contextMenu/ContextMenu';
import ContextMenuItem from '../../components/contextMenu/ContextMenuItem';
import Input from '../../components/formElements/Input';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithEditorActions from '../../components/HOC/withEditorActions';
import WithModals from '../../components/HOC/withModals';
import WithPersonActions from '../../components/HOC/withPersonActions';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import Icon from '../../components/Icon';
import { Legend } from '../../components/Legend';
import MultiSelect from '../../components/MultiSelect';
import MultiStateButton from '../../components/MultiStateButton';
import OrganisationUnitPathTooltip from '../../components/OrganisationUnitPathTooltip';
import RadioButton from '../../components/RadioButton';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import TableCell from '../../components/TableCell';
import TableHeaderCell from '../../components/TableHeaderCell';
import Tabs from '../../components/Tabs';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import TruncatedArrayText from '../../components/TruncatedArrayText';
import localeLookup from '../../config/locale';
import {
  ACCESS_LEVELS,
  EMPTY_ID,
  ORGANISATION_UNIT_STATES,
  PERSON_STATES,
} from '../../constants';
import {
  getContentAdministrationColumnSettingsService,
  updateContentAdministrationColumnSettingsService,
} from '../../services/contentAdministrationService';
import {
  connectRolesToOrganisationUnitsService,
  disconnectRolesFromOrganisationUnitsService,
  updateSpaceAdministratorsService,
  updateSpaceCreateModulePermissionsService,
  updateSpaceCreateRolePermissionsService,
} from '../../services/contentService';
import { getPersonsWithAccessLevelsService } from '../../services/personsService';
import { getAllAreas, queryAreas } from '../../slices/areasSlice';
import { getAllCategories } from '../../slices/categoriesSlice';
import { getAreasAccess, getRolesAccess } from '../../slices/editorSlice';
import {
  getAllOrganisationUnits,
  selectParentMap,
} from '../../slices/organisationUnitsSlice';
import { selectActivePersons } from '../../slices/personsSlice';
import { getAllRoles, queryRoles } from '../../slices/rolesSlice';
import {
  getActiveSpace,
  getAllSpaces,
  getSpaceStatus,
  setSpaceStatus,
} from '../../slices/spaceSlice';
import {
  compareEmpty,
  compareFalsy,
  compareLocal,
  insertStringInString,
  sortBy,
} from '../../utils/helpers';

export class ContentAdministration extends Component {
  constructor(props) {
    super(props);
    const { editorActions, allowChangeOfChampLinkVisibility } = props;
    this.state = {
      isLoading: true,
      isDynamicDataLoading: false,
      rowFilterString: '',
      columnFilterString: '',
      personsWithAccessLevels: {},
      rows: [],
      dynamicDataColumns: [],
      columns: {
        roles: {
          entity: 'roles',
          hiddenColumns: [],
          dynamicData: { id: '', name: '', entity: '' },
          sorting: { columnId: 'name', direction: 'asc' },
          showEmptyColumns: true,
          columns: {
            name: {
              id: 'name',
              name: localeLookup('translations.Name'),
              width: 300,
              focusable: false,
              hideable: false,
            },
            organisationUnits: {
              id: 'organisationUnits',
              entity: 'organisationUnits',
              name: localeLookup('translations.Organisation units'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeOrganisationUnitModal(id),
            },
            owner: {
              id: 'owner',
              entity: 'persons',
              additionalEntities: {
                [EMPTY_ID]: {
                  id: EMPTY_ID,
                  name: localeLookup('translations.No owner'),
                },
              },
              name: localeLookup('translations.Owner'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeRoleOwnerModal({ roleId: id }),
            },
            editors: {
              id: 'editors',
              entity: 'persons',
              name: localeLookup('translations.Editors'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeRoleEditorsModal({ roleId: id }),
            },
            space: {
              id: 'space',
              entity: 'spaces',
              name: localeLookup('translations.Content space'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeRoleSpaceModal({ roleId: id }),
            },
          },
        },
        modules: {
          entity: 'areas',
          hiddenColumns: [],
          dynamicData: { id: '', name: '', entity: '' },
          sorting: { columnId: 'name', direction: 'asc' },
          showEmptyColumns: true,
          columns: {
            name: {
              id: 'name',
              name: localeLookup('translations.Name'),
              width: 300,
              focusable: false,
              hideable: false,
            },
            champLink: {
              id: 'champLink',
              name: 'Champ link',
              width: 200,
              focusable: false,
              hideable: true,
              hoverToShowIcon: false,
              getIcon: (id) => {
                const { areas, allowChangeOfChampLinkVisibility } = this.props;
                const area = areas[id];
                if (!area || !allowChangeOfChampLinkVisibility) return '';
                const isPublic = area.champLink?.isPublic;
                return isPublic ? 'unlock' : 'lock';
              },
              onIconClick: allowChangeOfChampLinkVisibility
                ? (id) => {
                    const { areas, allowChangeOfChampLinkVisibility } =
                      this.props;
                    if (!allowChangeOfChampLinkVisibility) return;
                    const area = areas[id];
                    editorActions.showToggleAreaLinkVisiblityModal(area, () =>
                      this.gridRef.forceUpdate()
                    );
                  }
                : null,
            },
            owner: {
              id: 'owner',
              entity: 'persons',
              additionalEntities: {
                [EMPTY_ID]: {
                  id: EMPTY_ID,
                  name: localeLookup('translations.No owner'),
                },
              },
              name: localeLookup('translations.Owner'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeAreaOwnerModal({ areaId: id }),
            },
            editors: {
              id: 'editors',
              entity: 'persons',
              name: localeLookup('translations.Editors'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeAreaEditorsModal({ areaId: id }),
            },
            spaces: {
              id: 'spaces',
              entity: 'spaces',
              name: localeLookup('translations.Content spaces'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeAreaSpaceModal({ areaId: id }),
            },
            category: {
              id: 'category',
              entity: 'categoriesObject',
              additionalEntities: {
                [EMPTY_ID]: {
                  id: EMPTY_ID,
                  name: localeLookup('translations.No category'),
                },
              },
              name: localeLookup('translations.Category'),
              width: 200,
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeAreaCategoryModal(id),
            },
          },
        },
        persons: {
          entity: 'persons',
          hiddenColumns: [],
          dynamicData: { id: '', name: '', entity: '' },
          sorting: { columnId: 'name', direction: 'asc' },
          showEmptyColumns: true,
          columns: {
            name: {
              id: 'name',
              name: localeLookup('translations.Name'),
              width: 300,
              focusable: false,
              hideable: false,
            },
            ownedRoles: {
              id: 'ownedRoles',
              name: localeLookup('translations.Owner of roles'),
              width: 200,
              entity: 'roles',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonRoleOwnershipsModal({
                  personId: id,
                }),
            },
            editorRoles: {
              id: 'editorRoles',
              name: localeLookup('translations.Editor of roles'),
              width: 200,
              entity: 'roles',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonRoleEditorshipsModal({
                  personId: id,
                }),
            },
            ownedAreas: {
              id: 'ownedAreas',
              name: localeLookup('translations.Owner of modules'),
              width: 200,
              entity: 'areas',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonAreaOwnershipsModal({
                  personId: id,
                }),
            },
            editorAreas: {
              id: 'editorAreas',
              name: localeLookup('translations.Editor of modules'),
              width: 200,
              entity: 'areas',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonAreaEditorshipsModal({
                  personId: id,
                }),
            },
            contentAdministrator: {
              id: 'contentAdministrator',
              name: localeLookup(
                'translations.Administrator of content spaces'
              ),
              width: 250,
              entity: 'spaces',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonSpaceAdministratorModal({
                  personId: id,
                  onChanged: this.getPersonsAccessLevels,
                }),
            },
            createRoles: {
              id: 'createRoles',
              name: localeLookup('translations.Create roles in content spaces'),
              width: 275,
              entity: 'spaces',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonSpaceCreateRoleModal({
                  personId: id,
                }),
            },
            createAreas: {
              id: 'createAreas',
              name: localeLookup(
                'translations.Create modules in content spaces'
              ),
              width: 275,
              entity: 'spaces',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangePersonSpaceCreateAreaModal({
                  personId: id,
                }),
            },
          },
        },
        spaces: {
          entity: 'spaces',
          hiddenColumns: [],
          dynamicData: { id: '', name: '', entity: '' },
          sorting: { columnId: 'name', direction: 'asc' },
          showEmptyColumns: true,
          columns: {
            name: {
              id: 'name',
              name: localeLookup('translations.Name'),
              width: 300,
              focusable: false,
              hideable: false,
            },
            roles: {
              id: 'roles',
              name: localeLookup('translations.Roles'),
              width: 200,
              entity: 'roles',
              focusable: true,
              hideable: true,
            },
            areas: {
              id: 'areas',
              name: localeLookup('translations.Knowledge areas'),
              width: 200,
              entity: 'areas',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeSpaceAreasModal({
                  spaceId: id,
                }),
            },
            contentAdministrators: {
              id: 'contentAdministrators',
              name: localeLookup('translations.Content administrators'),
              width: 250,
              entity: 'persons',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeSpaceAdministratorsModal({
                  spaceId: id,
                }),
            },
            createRoles: {
              id: 'createRoles',
              name: localeLookup('translations.Create roles'),
              width: 200,
              entity: 'persons',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeSpaceCreateRolePermissionModal({
                  spaceId: id,
                }),
            },
            createAreas: {
              id: 'createAreas',
              name: localeLookup('translations.Create modules'),
              width: 200,
              entity: 'persons',
              focusable: true,
              hideable: true,
              icon: 'pencil',
              onIconClick: (id) =>
                editorActions.showChangeSpaceCreateAreaPermissionModal({
                  spaceId: id,
                }),
            },
          },
        },
      },
      dynamicData: {
        id: '',
        name: '',
        entity: '',
      },
      staticData: {
        name: 'roles',
        entity: 'roles',
      },
    };
  }

  componentDidMount = async () => {
    const {
      getAllAreas,
      getAllCategories,
      getAllOrganisationUnits,
      getAllRoles,
      getAllSpaces,
      getSpaceStatus,
      getRolesAccess,
      getAreasAccess,
      getActiveSpace,
    } = this.props;
    const [personsWithAccessLevelsResponse] = await Promise.all([
      getPersonsWithAccessLevelsService(),
      getRolesAccess(),
      getAreasAccess(),
      getSpaceStatus(),
      getAllSpaces(),
      getActiveSpace(),
      getAllAreas(),
      getAllRoles(),
      getAllOrganisationUnits(),
      getAllCategories(),
    ]);
    await this.setColumnSettings();
    await this.buildRows();
    await this.buildDynamicDataColumns();
    this.setState({
      isLoading: false,
      personsWithAccessLevels: personsWithAccessLevelsResponse.data,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, columns, staticData } = this.state;
    const { spaces, persons, roles, areas } = this.props;
    if (!isLoading) {
      if (
        (prevProps.spaces && prevProps.spaces !== spaces) ||
        (prevProps.areas && prevProps.areas !== areas) ||
        (prevProps.persons && prevProps.persons !== persons) ||
        (prevProps.roles && prevProps.roles !== roles)
      ) {
        const dynamicData = columns[staticData.name].dynamicData;
        const hasColumnFocused = !!dynamicData.id;
        this.buildRows();
        if (hasColumnFocused) {
          this.buildDynamicDataColumns();
        }
      }
    }
  }

  buildRows = () => {
    this.setState({ rows: this.getSortedRows() }, () => {
      this?.gridRef?.recomputeGridSize?.();
    });
  };

  buildDynamicDataColumns = () => {
    this.setState(
      {
        dynamicDataColumns: this.getSortedDynamicDataColumnArray(),
      },
      () => this.gridRef?.recomputeGridSize()
    );
  };

  getSortedRows = (entity) => {
    const { staticData } = this.state;
    const staticDataEntityName = staticData.entity;
    switch (entity || staticDataEntityName) {
      case 'roles': {
        return this.getSortedRoles();
      }
      case 'areas': {
        return this.getSortedAreas();
      }
      case 'persons': {
        return this.getSortedPersons();
      }
      case 'spaces': {
        return this.getSortedSpaces();
      }
      default:
        return [];
    }
  };

  getPersonsAccessLevels = async () => {
    const personsWithAccessLevelsResponse =
      await getPersonsWithAccessLevelsService();
    await this.setState({
      personsWithAccessLevels: personsWithAccessLevelsResponse.data,
    });
    return personsWithAccessLevelsResponse.data;
  };

  setColumnSettings = async () => {
    const { columns } = this.state;
    const {
      data: { settings },
    } = await getContentAdministrationColumnSettingsService();
    this.setState({
      columns: {
        ...columns,
        roles: {
          ...columns.roles,
          hiddenColumns: settings.Roles
            ? settings.Roles.hiddenColumns.reduce((acc, columnId) => {
                if (columns.roles.columns[columnId]) {
                  return [
                    ...acc,
                    {
                      id: columnId,
                      name: columns.roles.columns[columnId].name,
                    },
                  ];
                }
                return acc;
              }, [])
            : [],
          dynamicData:
            settings.Roles && settings.Roles.focusedColumn !== ''
              ? {
                  id: settings.Roles.focusedColumn,
                  name: columns.roles.columns[settings.Roles.focusedColumn]
                    .name,
                  entity:
                    columns.roles.columns[settings.Roles.focusedColumn].entity,
                }
              : {},
          showEmptyColumns: settings.Roles
            ? settings.Roles.showEmptyColumns
            : true,
        },
        modules: {
          ...columns.modules,
          hiddenColumns: settings.Modules
            ? settings.Modules.hiddenColumns.map((columnId) => {
                return {
                  id: columnId,
                  name: columns.modules.columns[columnId].name,
                };
              })
            : [],
          dynamicData:
            settings.Modules && settings.Modules.focusedColumn !== ''
              ? {
                  id: settings.Modules.focusedColumn,
                  name: columns.modules.columns[settings.Modules.focusedColumn]
                    .name,
                  entity:
                    columns.modules.columns[settings.Modules.focusedColumn]
                      .entity,
                }
              : {},
          showEmptyColumns: settings.Modules
            ? settings.Modules.showEmptyColumns
            : true,
        },
        persons: {
          ...columns.persons,
          hiddenColumns: settings.Persons
            ? settings.Persons.hiddenColumns.map((columnId) => {
                return {
                  id: columnId,
                  name: columns.persons.columns[columnId].name,
                };
              })
            : [],
          dynamicData:
            settings.Persons && settings.Persons.focusedColumn !== ''
              ? {
                  id: settings.Persons.focusedColumn,
                  name: columns.persons.columns[settings.Persons.focusedColumn]
                    .name,
                  entity:
                    columns.persons.columns[settings.Persons.focusedColumn]
                      .entity,
                }
              : {},
          showEmptyColumns: settings.Persons
            ? settings.Persons.showEmptyColumns
            : true,
        },
        spaces: {
          ...columns.spaces,
          hiddenColumns: settings.Spaces
            ? settings.Spaces.hiddenColumns.map((columnId) => {
                return {
                  id: columnId,
                  name: columns.spaces.columns[columnId].name,
                };
              })
            : [],
          dynamicData:
            settings.Spaces && settings.Spaces.focusedColumn !== ''
              ? {
                  id: settings.Spaces.focusedColumn,
                  name: columns.spaces.columns[settings.Spaces.focusedColumn]
                    .name,
                  entity:
                    columns.spaces.columns[settings.Spaces.focusedColumn]
                      .entity,
                }
              : {},
          showEmptyColumns: settings.Spaces
            ? settings.Spaces.showEmptyColumns
            : true,
        },
      },
    });
  };

  getColumnCount = () => {
    const staticDataColumns = this.getStaticDataColumns();
    const dynamicDataColumns = this.getDynamicDataColumnArray();
    return (
      Object.keys(staticDataColumns).length +
      Object.keys(dynamicDataColumns).length +
      1
    ); // Add 1 to show hidden column at end
  };

  getColumnWidth = ({ index }) => {
    const columnCount = this.getColumnCount();
    const isLastColumn = index === columnCount - 1;
    const staticDataColumns = this.getStaticDataColumns();
    const staticColumn = Object.values(staticDataColumns)[index];
    if (isLastColumn) return 1000;
    if (staticColumn) return staticColumn.width;
    return 40;
  };

  getDynamicColumnTooltip = (entity, id) => {
    const {
      areas,
      categories,
      persons,
      roles,
      organisationUnits,
      organisationUnitParents,
    } = this.props;
    switch (entity) {
      case 'organisationUnits': {
        if (Object.keys(organisationUnitParents[id]).length === 0) return null;
        return <OrganisationUnitPathTooltip id={id} />;
      }
      case 'areas': {
        const area = areas[id];
        return categories[area.category];
      }
      case 'persons': {
        const person = persons[id];
        if (!person) return null;
        return `${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        }`;
      }
      case 'roles': {
        const role = roles[id];
        if (role?.organisationUnits?.length === 0) return null;
        return sortBy(role.organisationUnits, [
          (a, b) =>
            compareLocal(organisationUnits[a].name, organisationUnits[b].name),
        ]).map((id) => (
          <span key={id}>
            {organisationUnits[id].name} <br />
          </span>
        ));
      }

      default: {
        return '';
      }
    }
  };

  getOwnerAndEditorIdsForPerson = (personId) => {
    const { roles, areas } = this.props;
    const ownedRoleIds = [];
    const editorRoleIds = [];
    const ownedAreaIds = [];
    const editorAreaIds = [];
    forOwn(roles, (role) => {
      if (role.owner === personId) ownedRoleIds.push(role.id);
      if (role.editors?.includes(personId)) editorRoleIds.push(role.id);
    });
    forOwn(areas, (area) => {
      if (area.owner === personId) ownedAreaIds.push(area.id);
      if (area.editors.includes(personId)) editorAreaIds.push(area.id);
    });
    return {
      ownedRoleIds,
      editorRoleIds,
      ownedAreaIds,
      editorAreaIds,
    };
  };

  getOwnerAndEditorIdsForPersons = () => {
    const { roles, areas, persons } = this.props;
    return Object.keys(persons).reduce((acc, personId) => {
      const ownedRoleIds = [];
      const editorRoleIds = [];
      const ownedAreaIds = [];
      const editorAreaIds = [];
      forOwn(roles, (role) => {
        if (role.owner === personId) ownedRoleIds.push(role.id);
        if (role.editors?.includes(personId)) editorRoleIds.push(role.id);
      });
      forOwn(areas, (area) => {
        if (area.owner === personId) ownedRoleIds.push(area.id);
        if (area.editors.includes(personId)) editorRoleIds.push(area.id);
      });
      return {
        ...acc,
        [personId]: {
          ownedRoleIds,
          editorRoleIds,
          ownedAreaIds,
          editorAreaIds,
        },
      };
    }, {});
  };

  getPersonsIdsThatHasAdministatorPermissionInSpace = ({ spaceId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces, persons } = this.props;
    const space = spaces[spaceId];
    if (!space) return [];
    const activePersons = pickBy(
      persons,
      (person) => person.state === PERSON_STATES.ACTIVE
    );
    return Object.keys(activePersons).filter((personId) => {
      const person = activePersons[personId];
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      if (isPersonAdmin) return true;
      return space.administrators.includes(person.id);
    });
  };

  getFilteredDynamicDataColumns = () => {
    const {
      roles,
      areas,
      spaces,
      categoriesObject,
      organisationUnits,
      accessibleRoles,
      accessibleAreas,
      persons,
    } = this.props;
    const { staticData, columns, columnFilterString } = this.state;
    let dynamicDataColumns = {};
    const showEmptyColumns = columns[staticData.name].showEmptyColumns;
    const dynamicData = columns[staticData.name].dynamicData;
    const activePersons = pickBy(
      persons,
      (person) => person.state === PERSON_STATES.ACTIVE
    );

    if (showEmptyColumns) {
      if (dynamicData.entity === 'persons') {
        dynamicDataColumns = activePersons;
      } else {
        dynamicDataColumns = this.props[dynamicData.entity];
      }
    } else {
      switch (staticData.entity) {
        case 'areas': {
          switch (dynamicData.id) {
            case 'editors': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const firstEditorArea = find(
                  areas,
                  (area) =>
                    area.editors.includes(person.id) &&
                    accessibleAreas.includes(area.id)
                );
                return firstEditorArea !== undefined;
              });
              break;
            }
            case 'category': {
              dynamicDataColumns = pickBy(categoriesObject, (category) => {
                const firstAreaWithCategory = find(
                  areas,
                  (area) =>
                    area.category === category.id &&
                    accessibleAreas.includes(area.id)
                );
                return firstAreaWithCategory !== undefined;
              });
              break;
            }
            case 'owner': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const firstOwnerArea = find(
                  areas,
                  (area) =>
                    area.owner === person.id &&
                    accessibleAreas.includes(area.id)
                );
                return firstOwnerArea !== undefined;
              });
              break;
            }
            case 'spaces': {
              dynamicDataColumns = pickBy(spaces, (space) => {
                return (
                  space.modulesEditable?.filter((id) =>
                    accessibleAreas.includes(id)
                  )?.length > 0 ||
                  space.modulesReadOnly?.filter((id) =>
                    accessibleAreas.includes(id)
                  )?.length > 0
                );
              });
              break;
            }
          }
          break;
        }
        case 'spaces': {
          switch (dynamicData.id) {
            case 'roles': {
              // Roles can't exist without space so we just return roles
              dynamicDataColumns = roles;
              break;
            }
            case 'areas': {
              // Areas can't exist without space so we just return areas
              dynamicDataColumns = areas;
              break;
            }
            case 'contentAdministrators': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const getSpaceIdsWherePersonIsAdministrator =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: person.id,
                  });
                return getSpaceIdsWherePersonIsAdministrator.length > 0;
              });
              break;
            }
            case 'createRoles': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const spaceIdsWherePersonCanCreateRoles =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: person.id,
                  });
                return spaceIdsWherePersonCanCreateRoles.length > 0;
              });
              break;
            }
            case 'createAreas': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const spaceIdsWherePersonCanCreateAreas =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: person.id,
                  });
                return spaceIdsWherePersonCanCreateAreas.length > 0;
              });
              break;
            }
          }
          break;
        }
        case 'persons': {
          switch (dynamicData.id) {
            case 'ownedRoles': {
              dynamicDataColumns = pickBy(
                roles,
                (role) => role.owner !== EMPTY_ID
              );
              break;
            }
            case 'editorRoles': {
              dynamicDataColumns = pickBy(
                roles,
                (role) => role.editors?.length > 0
              );
              break;
            }
            case 'ownedAreas': {
              dynamicDataColumns = pickBy(
                areas,
                (area) => area.owner !== EMPTY_ID
              );
              break;
            }
            case 'editorAreas': {
              dynamicDataColumns = pickBy(
                areas,
                (area) => area.editors.length > 0
              );
              break;
            }
            case 'contentAdministrator': {
              dynamicDataColumns = pickBy(spaces, (space) => {
                const personsIdsThatHasAdministratorPermissionInSpace =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: space.id,
                  });
                return (
                  personsIdsThatHasAdministratorPermissionInSpace.length > 0
                );
              });
              break;
            }
            case 'createRoles': {
              dynamicDataColumns = pickBy(spaces, (space) => {
                const personsIdsThatHasCreateRolePermissionInSpace =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: space.id,
                  });
                return personsIdsThatHasCreateRolePermissionInSpace.length > 0;
              });
              break;
            }
            case 'createAreas': {
              dynamicDataColumns = pickBy(spaces, (space) => {
                const personsIdsThatHasCreateAreaPermissionInSpace =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: space.id,
                  });
                return personsIdsThatHasCreateAreaPermissionInSpace.length > 0;
              });
              break;
            }
          }
          break;
        }
        case 'roles': {
          switch (dynamicData.id) {
            case 'organisationUnits': {
              dynamicDataColumns = pickBy(organisationUnits, (unit) => {
                const firstRoleWithUnit = find(
                  roles,
                  (role) =>
                    role.organisationUnits.includes(unit.id) &&
                    accessibleRoles.includes(role.id)
                );
                return firstRoleWithUnit !== undefined;
              });
              break;
            }
            case 'editors': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const firstEditorRole = find(
                  roles,
                  (role) =>
                    role.editors?.includes(person.id) &&
                    accessibleRoles.includes(role.id)
                );
                return firstEditorRole !== undefined;
              });
              break;
            }
            case 'owner': {
              dynamicDataColumns = pickBy(activePersons, (person) => {
                const firstOwnerRole = find(
                  roles,
                  (role) =>
                    role.owner === person.id &&
                    accessibleRoles.includes(role.id)
                );
                return firstOwnerRole !== undefined;
              });
              break;
            }
            case 'space': {
              dynamicDataColumns = pickBy(spaces, (space) => {
                return (
                  space.roles?.length > 0 &&
                  space.roles?.some((spaceRoleId) =>
                    accessibleRoles.includes(spaceRoleId)
                  )
                );
              });
              break;
            }
          }
        }
      }
    }
    if (columnFilterString.length > 0) {
      if (dynamicData.entity === 'persons') {
        return pickBy(dynamicDataColumns, (column) =>
          `${column.name}${column.initials}${column.employeeNumber}`
            ?.toLowerCase()
            .includes(columnFilterString.toLowerCase())
        );
      }
      return pickBy(dynamicDataColumns, (column) =>
        column.name?.toLowerCase().includes(columnFilterString.toLowerCase())
      );
    }
    return dynamicDataColumns;
  };

  getDynamicDataColumnArray = () => {
    const { dynamicDataColumns } = this.state;
    return dynamicDataColumns;
  };

  getSortedDynamicDataColumnArray = () => {
    const { staticData, columns } = this.state;
    const staticDataName = staticData.name;
    const dynamicData = columns[staticDataName].dynamicData;
    if (!dynamicData.entity) return [];
    const additionalEntities =
      columns[staticDataName]?.columns?.[dynamicData.id].additionalEntities ||
      {};
    const filteredColumns = this.getFilteredDynamicDataColumns();
    if (dynamicData.entity === 'organisationUnits') {
      return Object.values(filteredColumns).map((unit) => {
        const isVisibilityLimited =
          unit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
          unit.state === ORGANISATION_UNIT_STATES.PASSIVE;
        return {
          ...unit,
          name: isVisibilityLimited
            ? `${unit.name} (${localeLookup(
                'translations.Limited visibility'
              )})`
            : unit.name,
        };
      });
    }
    const sortedDynamicDataColumnObjects = sortBy(
      Object.values(filteredColumns),
      [(a, b) => compareLocal(a.name, b.name)]
    );
    return [
      ...Object.values(additionalEntities),
      ...sortedDynamicDataColumnObjects,
    ];
  };

  getDynamicDataEntityByIndex = (index) => {
    const entities = this.getDynamicDataColumnArray();
    const entity = entities[index];
    if (!entity) return null;
    return entity;
  };

  getFocusableColumnOptions = () => {
    const { spacesEnabled } = this.props;
    const { staticData, columns } = this.state;
    const staticColumn = columns[staticData.name];
    const staticColumns = staticColumn.columns;
    const columnsThatRequiresSpacesEnabled = [
      'space',
      'spaces',
      'contentAdministrator',
      'createRoles',
      'createAreas',
    ];
    const focusableColumns = Object.values(staticColumns).reduce(
      (acc, column) => {
        if (
          !spacesEnabled &&
          columnsThatRequiresSpacesEnabled.includes(column.id)
        ) {
          return acc;
        }
        if (column.focusable) {
          return [...acc, { value: column.id, label: column.name }];
        }
        return acc;
      },
      []
    );
    return focusableColumns;
  };

  getHideableColumnOptions = () => {
    const { spacesEnabled } = this.props;
    const { staticData, columns } = this.state;
    const columnsThatRequiresSpacesEnabled = [
      'space',
      'spaces',
      'contentAdministrator',
      'createRoles',
      'createAreas',
    ];
    const staticColumns = columns[staticData.name].columns;
    const hideableColumns = Object.values(staticColumns).reduce(
      (acc, column) => {
        if (
          !spacesEnabled &&
          columnsThatRequiresSpacesEnabled.includes(column.id)
        )
          return acc;
        if (column.hideable) {
          return [...acc, { value: column.id, label: column.name }];
        }
        return acc;
      },
      []
    );
    return hideableColumns;
  };

  getPersonsIdsThatHasCreateAreaPermissionInSpace = ({ spaceId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces, persons } = this.props;
    const space = spaces[spaceId];
    if (!space) return [];
    const activePersons = pickBy(
      persons,
      (person) => person.state === PERSON_STATES.ACTIVE
    );
    return Object.keys(activePersons).filter((personId) => {
      const person = activePersons[personId];
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      if (isPersonAdmin) return true;
      return (
        space.canCreateModules.includes(person.id) ||
        space.administrators.includes(person.id)
      );
    });
  };

  getPersonsIdsThatHasCreateRolePermissionInSpace = ({ spaceId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces, persons } = this.props;
    const space = spaces[spaceId];
    if (!space) return [];
    const activePersons = pickBy(
      persons,
      (person) => person.state === PERSON_STATES.ACTIVE
    );
    return Object.keys(activePersons).filter((personId) => {
      const person = activePersons[personId];
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      if (isPersonAdmin) return true;
      return (
        space.canCreateRoles.includes(person.id) ||
        space.administrators.includes(person.id)
      );
    });
  };

  getRowCount = () => {
    const { columns, staticData } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const hasColumnFocused = !!dynamicData.id;
    const offset = hasColumnFocused ? 2 : 1;
    return this.getRowArray().length + offset; // 1+ Because of Header row;
  };

  getRowArray = () => {
    const { rows } = this.state;
    return rows;
  };

  getRowHeight = ({ index }) => {
    const { columns, staticData } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const hasColumnFocused = !!dynamicData.id;
    if ((hasColumnFocused && index === 1) || (!hasColumnFocused && index === 0))
      return 140;
    return 40;
  };

  getSortedAreas = () => {
    const { lookupPerson, spaces, categories, areas, accessibleAreas } =
      this.props;
    const { staticData, columns, rowFilterString } = this.state;
    const sorting = columns[staticData.name].sorting;
    const entityArray = Object.values(areas).filter((area) => {
      const isAccessible = accessibleAreas.includes(area.id);
      const matchesFilterString =
        rowFilterString.length > 0
          ? area.name.toLowerCase().includes(rowFilterString.toLowerCase())
          : true;
      return isAccessible && matchesFilterString;
    });
    const dynamicDataId = columns[staticData.name].dynamicData.id;

    if (sorting.isDynamicColumn) {
      switch (dynamicDataId) {
        case 'owner': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.owner === sorting.columnId,
                  b.owner === sorting.columnId
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'editors': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.editors.includes(sorting.columnId),
                  b.editors.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'spaces': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const areaASpaceIds = [
                  ...Object.keys(a.editableInSpaces),
                  ...Object.keys(a.readOnlyInSpaces),
                ];
                const areaBSpaceIds = [
                  ...Object.keys(b.editableInSpaces),
                  ...Object.keys(b.readOnlyInSpaces),
                ];
                return compareFalsy(
                  areaASpaceIds.includes(sorting.columnId),
                  areaBSpaceIds.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'category': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.category === sorting.columnId,
                  b.category === sorting.columnId
                );
              },
            ],
            [sorting.direction]
          );
        }
        default:
          return entityArray;
      }
    } else {
      switch (sorting.columnId) {
        case 'name': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareLocal(a.name, b.name);
              },
            ],
            [sorting.direction]
          );
        }
        case 'champLink': {
          return sortBy(
            entityArray,
            [(a, b) => compareLocal(a.champLink?.link, b.champLink?.link)],
            [sorting.direction]
          );
        }
        case 'owner': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const ownerA = lookupPerson(a.owner)?.name || '';
                const ownerB = lookupPerson(b.owner)?.name || '';
                return compareLocal(ownerA, ownerB);
              },
              (a, b) => {
                const ownerA = lookupPerson(a.owner)?.name || '';
                const ownerB = lookupPerson(b.owner)?.name || '';
                return compareEmpty(ownerA, ownerB);
              },
            ],
            [sorting.direction, 'asc']
          );
        }
        case 'editors': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const editorA = lookupPerson(a.editors[0])?.name || '';
                const editorB = lookupPerson(b.editors[0])?.name || '';
                return compareLocal(editorA, editorB);
              },
              (a, b) => {
                return a.editors.length - b.editors.length;
              },
              (a, b) => {
                return compareEmpty(a.editors.length, b.editors.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'spaces': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const areaASpaceIds = [
                  ...Object.keys(a.editableInSpaces),
                  ...Object.keys(a.readOnlyInSpaces),
                ];
                const areaBSpaceIds = [
                  ...Object.keys(b.editableInSpaces),
                  ...Object.keys(b.readOnlyInSpaces),
                ];
                const spaceA = spaces[areaASpaceIds[0]].name || '';
                const spaceB = spaces[areaBSpaceIds[0]].name || '';
                return compareLocal(spaceA, spaceB);
              },
              (a, b) => {
                const areaASpaceIds = [
                  ...Object.keys(a.editableInSpaces),
                  ...Object.keys(a.readOnlyInSpaces),
                ];
                const areaBSpaceIds = [
                  ...Object.keys(b.editableInSpaces),
                  ...Object.keys(b.readOnlyInSpaces),
                ];
                return areaASpaceIds.length - areaBSpaceIds.length;
              },
              (a, b) => {
                const areaASpaceIds = [
                  ...Object.keys(a.editableInSpaces),
                  ...Object.keys(a.readOnlyInSpaces),
                ];
                const areaBSpaceIds = [
                  ...Object.keys(b.editableInSpaces),
                  ...Object.keys(b.readOnlyInSpaces),
                ];
                return compareEmpty(areaASpaceIds.length, areaBSpaceIds.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }

        case 'category': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const categoryA = categories[a.category] || '';
                const categoryB = categories[b.category] || '';
                return compareLocal(categoryA, categoryB);
              },
              (a, b) => {
                const categoryA = categories[a.category] || '';
                const categoryB = categories[b.category] || '';
                return compareEmpty(categoryA, categoryB);
              },
            ],
            [sorting.direction, 'asc']
          );
        }
        default: {
          return entityArray;
        }
      }
    }
  };

  getSortedRoles = () => {
    const { lookupPerson, spaces, roles, organisationUnits, accessibleRoles } =
      this.props;
    const { staticData, columns, rowFilterString } = this.state;
    const sorting = columns[staticData.name].sorting;
    const entityArray = Object.values(roles).filter((role) => {
      const isAccessible = accessibleRoles.includes(role.id);
      const matchesFilterString =
        rowFilterString.length > 0
          ? role.name.toLowerCase().includes(rowFilterString.toLowerCase())
          : true;
      return isAccessible && matchesFilterString;
    });
    const dynamicDataId = columns[staticData.name].dynamicData.id;

    if (sorting.isDynamicColumn) {
      switch (dynamicDataId) {
        case 'organisationUnits': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.organisationUnits.includes(sorting.columnId),
                  b.organisationUnits.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'owner': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.owner === sorting.columnId,
                  b.owner === sorting.columnId
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'editors': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.editors.includes(sorting.columnId),
                  b.editors.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'space': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                return compareFalsy(
                  a.space === sorting.columnId,
                  b.space === sorting.columnId
                );
              },
            ],
            [sorting.direction]
          );
        }
        default:
          return entityArray;
      }
    } else {
      switch (sorting.columnId) {
        case 'name': {
          return sortBy(
            entityArray,
            [(a, b) => compareLocal(a.name, b.name)],
            [sorting.direction]
          );
        }
        case 'organisationUnits': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const unitA =
                  organisationUnits[a.organisationUnits[0]]?.name || '';
                const unitB =
                  organisationUnits[b.organisationUnits[0]]?.name || '';
                return compareLocal(unitA, unitB);
              },
              (a, b) => {
                return a.organisationUnits.length - b.organisationUnits.length;
              },
              (a, b) => {
                return compareEmpty(
                  a.organisationUnits.length,
                  b.organisationUnits.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'owner': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const ownerA = lookupPerson(a.owner)?.name || '';
                const ownerB = lookupPerson(b.owner)?.name || '';
                return compareLocal(ownerA, ownerB);
              },
              (a, b) => {
                const ownerA = lookupPerson(a.owner)?.name || '';
                const ownerB = lookupPerson(b.owner)?.name || '';
                return compareEmpty(ownerA, ownerB);
              },
            ],
            [sorting.direction, 'asc']
          );
        }
        case 'editors': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const editorA = lookupPerson(a.editors[0])?.name || '';
                const editorB = lookupPerson(b.editors[0])?.name || '';
                return compareLocal(editorA, editorB);
              },
              (a, b) => {
                return a.editors.length - b.editors.length;
              },
              (a, b) => {
                return compareEmpty(a.editors.length, b.editors.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'space': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const spaceA = spaces[a.space]?.name || '';
                const spaceB = spaces[b.space]?.name || '';
                return compareLocal(spaceA, spaceB);
              },
            ],
            [sorting.direction]
          );
        }
        default: {
          return entityArray;
        }
      }
    }
  };

  getSortedPersons = () => {
    const { spaces, persons, roles, areas } = this.props;
    const { staticData, columns, rowFilterString } = this.state;
    const sorting = columns[staticData.name].sorting;
    const entityArray = Object.values(persons).filter((person) => {
      const isActive = person.state === PERSON_STATES.ACTIVE;
      const matchesFilterString =
        rowFilterString.length > 0
          ? person.name.toLowerCase().includes(rowFilterString.toLowerCase()) ||
            person.initials
              .toLowerCase()
              .startsWith(rowFilterString.toLowerCase()) ||
            person.employeeNumber
              .toLowerCase()
              .startsWith(rowFilterString.toLowerCase())
          : true;
      return isActive && matchesFilterString;
    });
    const dynamicDataId = columns[staticData.name].dynamicData.id;

    if (sorting.isDynamicColumn) {
      switch (dynamicDataId) {
        case 'ownedRoles': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const { ownedRoleIds: ownedRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedRoleIds: ownedRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);

                return compareFalsy(
                  ownedRoleIdsA.includes(sorting.columnId),
                  ownedRoleIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'editorRoles': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const { editorRoleIds: editorRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorRoleIds: editorRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);

                return compareFalsy(
                  editorRoleIdsA.includes(sorting.columnId),
                  editorRoleIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'ownedAreas': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const { ownedAreaIds: ownedAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedAreaIds: ownedAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);

                return compareFalsy(
                  ownedAreaIdsA.includes(sorting.columnId),
                  ownedAreaIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'editorAreas': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const { editorAreaIds: editorAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorAreaIds: editorAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);

                return compareFalsy(
                  editorAreaIdsA.includes(sorting.columnId),
                  editorAreaIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'contentAdministrator': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const adminSpaceIdsA =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: a.id,
                  });
                const adminSpaceIdsB =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: b.id,
                  });

                return compareFalsy(
                  adminSpaceIdsA.includes(sorting.columnId),
                  adminSpaceIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'createAreas': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const createAreasSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: a.id,
                  });
                const createAreasSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: b.id,
                  });

                return compareFalsy(
                  createAreasSpaceIdsA.includes(sorting.columnId),
                  createAreasSpaceIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'createRoles': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const createRolesSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: a.id,
                  });
                const createRolesSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: b.id,
                  });

                return compareFalsy(
                  createRolesSpaceIdsA.includes(sorting.columnId),
                  createRolesSpaceIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }

        default:
          return entityArray;
      }
    } else {
      switch (sorting.columnId) {
        case 'name': {
          return sortBy(
            entityArray,
            [(a, b) => compareLocal(a.name, b.name)],
            [sorting.direction]
          );
        }
        case 'ownedRoles': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const { ownedRoleIds: ownedRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedRoleIds: ownedRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                const ownedRoleA = roles[ownedRoleIdsA[0]]?.name || '';
                const ownedRoleB = roles[ownedRoleIdsB[0]]?.name || '';
                return compareLocal(ownedRoleA, ownedRoleB);
              },
              (a, b) => {
                const { ownedRoleIds: ownedRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedRoleIds: ownedRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return ownedRoleIdsA.length - ownedRoleIdsB.length;
              },
              (a, b) => {
                const { ownedRoleIds: ownedRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedRoleIds: ownedRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return compareEmpty(ownedRoleIdsA.length, ownedRoleIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'editorRoles': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const { editorRoleIds: editorRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorRoleIds: editorRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                const editorRoleA = roles[editorRoleIdsA[0]]?.name || '';
                const editorRoleB = roles[editorRoleIdsB[0]]?.name || '';
                return compareLocal(editorRoleA, editorRoleB);
              },
              (a, b) => {
                const { editorRoleIds: editorRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorRoleIds: editorRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return editorRoleIdsA.length - editorRoleIdsB.length;
              },
              (a, b) => {
                const { editorRoleIds: editorRoleIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorRoleIds: editorRoleIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return compareEmpty(
                  editorRoleIdsA.length,
                  editorRoleIdsB.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'ownedAreas': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const { ownedAreaIds: ownedAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedAreaIds: ownedAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                const ownedAreaA = areas[ownedAreaIdsA[0]]?.name || '';
                const ownedAreaB = areas[ownedAreaIdsB[0]]?.name || '';
                return compareLocal(ownedAreaA, ownedAreaB);
              },
              (a, b) => {
                const { ownedAreaIds: ownedAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedAreaIds: ownedAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return ownedAreaIdsA.length - ownedAreaIdsB.length;
              },
              (a, b) => {
                const { ownedAreaIds: ownedAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { ownedAreaIds: ownedAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return compareEmpty(ownedAreaIdsA.length, ownedAreaIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'editorAreas': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const { editorAreaIds: editorAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorAreaIds: editorAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                const editorAreaA = areas[editorAreaIdsA[0]]?.name || '';
                const editorAreaB = areas[editorAreaIdsB[0]]?.name || '';
                return compareLocal(editorAreaA, editorAreaB);
              },
              (a, b) => {
                const { editorAreaIds: editorAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorAreaIds: editorAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return editorAreaIdsA.length - editorAreaIdsB.length;
              },
              (a, b) => {
                const { editorAreaIds: editorAreaIdsA } =
                  this.getOwnerAndEditorIdsForPerson(a.id);
                const { editorAreaIds: editorAreaIdsB } =
                  this.getOwnerAndEditorIdsForPerson(b.id);
                return compareEmpty(
                  editorAreaIdsA.length,
                  editorAreaIdsB.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'contentAdministrator': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const adminSpaceIdsA =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: a.id,
                  });
                const adminSpaceIdsB =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: b.id,
                  });
                const adminSpaceA = spaces[adminSpaceIdsA[0]]?.name || '';
                const adminSpaceB = spaces[adminSpaceIdsB[0]]?.name || '';
                return compareLocal(adminSpaceA, adminSpaceB);
              },
              (a, b) => {
                const adminSpaceIdsA =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: a.id,
                  });
                const adminSpaceIdsB =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: b.id,
                  });
                return adminSpaceIdsA.length - adminSpaceIdsB.length;
              },
              (a, b) => {
                const adminSpaceIdsA =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: a.id,
                  });
                const adminSpaceIdsB =
                  this.getSpaceIdsWherePersonIsAdministrator({
                    personId: b.id,
                  });
                return compareEmpty(
                  adminSpaceIdsA.length,
                  adminSpaceIdsB.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'createAreas': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const createAreasSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: a.id,
                  });
                const createAreasSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: b.id,
                  });

                const adminSpaceA = spaces[createAreasSpaceIdsA[0]]?.name || '';
                const adminSpaceB = spaces[createAreasSpaceIdsB[0]]?.name || '';
                return compareLocal(adminSpaceA, adminSpaceB);
              },
              (a, b) => {
                const createAreasSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: a.id,
                  });
                const createAreasSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: b.id,
                  });
                return (
                  createAreasSpaceIdsA.length - createAreasSpaceIdsB.length
                );
              },
              (a, b) => {
                const createAreasSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: a.id,
                  });
                const createAreasSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateAreas({
                    personId: b.id,
                  });
                return compareEmpty(
                  createAreasSpaceIdsA.length,
                  createAreasSpaceIdsB.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'createRoles': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const createRolesSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: a.id,
                  });
                const createRolesSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: b.id,
                  });

                const adminSpaceA = spaces[createRolesSpaceIdsA[0]]?.name || '';
                const adminSpaceB = spaces[createRolesSpaceIdsB[0]]?.name || '';
                return compareLocal(adminSpaceA, adminSpaceB);
              },
              (a, b) => {
                const createRolesSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: a.id,
                  });
                const createRolesSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: b.id,
                  });
                return (
                  createRolesSpaceIdsA.length - createRolesSpaceIdsB.length
                );
              },
              (a, b) => {
                const createRolesSpaceIdsA =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: a.id,
                  });
                const createRolesSpaceIdsB =
                  this.getSpaceIdsWherePersonCanCreateRoles({
                    personId: b.id,
                  });
                return compareEmpty(
                  createRolesSpaceIdsA.length,
                  createRolesSpaceIdsB.length
                );
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
        }
        default: {
          return null;
        }
      }
    }
  };

  getSortedSpaces = () => {
    const { lookupPerson, spaces, roles, areas } = this.props;
    const { staticData, columns, rowFilterString } = this.state;
    const sorting = columns[staticData.name].sorting;
    const entityArray = Object.values(spaces).filter((space) => {
      const matchesFilterString =
        rowFilterString.length > 0
          ? space.name.toLowerCase().includes(rowFilterString.toLowerCase())
          : true;
      return space.accessible && matchesFilterString;
    });
    const dynamicDataId = columns[staticData.name].dynamicData.id;

    if (sorting.isDynamicColumn) {
      switch (dynamicDataId) {
        case 'areas': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const areaIdsA = [...a.modulesEditable, ...a.modulesReadOnly];
                const areaIdsB = [...b.modulesEditable, ...b.modulesReadOnly];

                return compareFalsy(
                  areaIdsA.includes(sorting.columnId),
                  areaIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'contentAdministrators': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: b.id,
                  });

                return compareFalsy(
                  personIdsA.includes(sorting.columnId),
                  personIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'createRoles': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: b.id,
                  });

                return compareFalsy(
                  personIdsA.includes(sorting.columnId),
                  personIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }
        case 'createAreas': {
          return sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: b.id,
                  });

                return compareFalsy(
                  personIdsA.includes(sorting.columnId),
                  personIdsB.includes(sorting.columnId)
                );
              },
            ],
            [sorting.direction]
          );
        }

        default:
          return entityArray;
      }
    } else {
      switch (sorting.columnId) {
        case 'name': {
          return sortBy(
            entityArray,
            [(a, b) => compareLocal(a.name, b.name)],
            [sorting.direction]
          );
        }
        case 'roles': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const roleA = roles[a.roles[0]]?.name || '';
                const roleB = roles[b.roles[0]]?.name || '';
                return compareLocal(roleA, roleB);
              },
              (a, b) => {
                return a.roles.length - b.roles.length;
              },
              (a, b) => {
                return compareEmpty(a.roles.length, b.roles.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'areas': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const areaIdsA = [...a.modulesEditable, ...a.modulesReadOnly];
                const areaIdsB = [...b.modulesEditable, ...b.modulesReadOnly];
                const areaA = areas[areaIdsA[0]]?.name || '';
                const areaB = areas[areaIdsB[0]]?.name || '';
                return compareLocal(areaA, areaB);
              },
              (a, b) => {
                const areaIdsA = [...a.modulesEditable, ...a.modulesReadOnly];
                const areaIdsB = [...b.modulesEditable, ...b.modulesReadOnly];
                return areaIdsA.length - areaIdsB.length;
              },
              (a, b) => {
                const areaIdsA = [...a.modulesEditable, ...a.modulesReadOnly];
                const areaIdsB = [...b.modulesEditable, ...b.modulesReadOnly];
                return compareEmpty(areaIdsA.length, areaIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'contentAdministrators': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: b.id,
                  });
                const personA = lookupPerson(personIdsA[0])?.name || '';
                const personB = lookupPerson(personIdsB[0])?.name || '';
                return compareLocal(personA, personB);
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: b.id,
                  });
                return personIdsA.length - personIdsB.length;
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                    spaceId: b.id,
                  });
                return compareEmpty(personIdsA.length, personIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'createRoles': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: b.id,
                  });
                const personA = lookupPerson(personIdsA[0])?.name || '';
                const personB = lookupPerson(personIdsB[0])?.name || '';
                return compareLocal(personA, personB);
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: b.id,
                  });
                return personIdsA.length - personIdsB.length;
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                    spaceId: b.id,
                  });
                return compareEmpty(personIdsA.length, personIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        case 'createAreas': {
          const sortedItems = sortBy(
            entityArray,
            [
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: b.id,
                  });
                const personA = lookupPerson(personIdsA[0])?.name || '';
                const personB = lookupPerson(personIdsB[0])?.name || '';
                return compareLocal(personA, personB);
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: b.id,
                  });
                return personIdsA.length - personIdsB.length;
              },
              (a, b) => {
                const personIdsA =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: a.id,
                  });
                const personIdsB =
                  this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                    spaceId: b.id,
                  });
                return compareEmpty(personIdsA.length, personIdsB.length);
              },
            ],
            [sorting.direction, sorting.direction, 'asc']
          );
          return sortedItems;
        }
        default: {
          return entityArray;
        }
      }
    }
  };

  getSpaceIdsWherePersonCanCreateAreas = ({ personId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isPersonAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) =>
      personWithAccessLevels?.accessLevels.includes(accessLevel)
    );
    if (isPersonAdmin) return Object.keys(spaces);
    return Object.keys(spaces).filter((spaceId) => {
      const space = spaces[spaceId];
      return (
        space.canCreateModules.includes(personId) ||
        space.administrators.includes(personId)
      );
    });
  };

  getSpaceIdsWherePersonCanCreateRoles = ({ personId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isPersonAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) =>
      personWithAccessLevels?.accessLevels.includes(accessLevel)
    );
    if (isPersonAdmin) return Object.keys(spaces);
    return Object.keys(spaces).filter((spaceId) => {
      const space = spaces[spaceId];
      return (
        space.canCreateRoles.includes(personId) ||
        space.administrators.includes(personId)
      );
    });
  };
  getSpaceIdsWherePersonIsAdministrator = ({ personId }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isPersonAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) =>
      personWithAccessLevels?.accessLevels.includes(accessLevel)
    );
    if (isPersonAdmin) return Object.keys(spaces);
    return Object.keys(spaces).filter((spaceId) => {
      const space = spaces[spaceId];
      return space.administrators.includes(personId);
    });
  };

  getStaticColumnHeaderCellContextMenuItems = ({
    id,
    name,
    entity,
    focusable,
    hideable,
  }) => {
    const { columns, staticData } = this.state;
    const staticDataColumn = columns[staticData.name];
    const dynamicData = staticDataColumn.dynamicData;
    const isFocused = dynamicData.id === id;
    const onClickHideColumn = () => this.onClickHideColumn({ name, id });
    const onClickFocusColumn = () =>
      this.onClickFocusColumn({ name, id, entity });
    const onClickUnFocusColumn = () => this.onClickUnfocusColumn();
    const items = [];
    if (isFocused && focusable) {
      items.push({
        title: localeLookup('translations.Deactivate focus mode'),
        icon: 'focus',
        onClick: onClickUnFocusColumn,
      });
    }
    if (focusable && !isFocused) {
      items.push({
        title: localeLookup('translations.Activate focus mode'),
        icon: 'focus',
        onClick: onClickFocusColumn,
      });
    }
    if (hideable) {
      items.push({
        title: localeLookup('translations.Hide'),
        icon: 'eye-crossed',
        onClick: onClickHideColumn,
      });
    }
    return items;
  };

  getStaticColumnCellContextMenuItems = ({ rowIndex, columnName }) => {
    const { staticData } = this.state;
    const { editorActions, getAllSpaces } = this.props;
    const staticDataEntityName = staticData.entity;
    switch (staticDataEntityName) {
      case 'roles': {
        const role = this.getStaticDataEntityByIndex(rowIndex);
        switch (columnName) {
          case 'name':
            return [
              {
                onClick: () =>
                  editorActions.showChangeRoleNameModal({ roleId: role.id }),
                leftIconKind: 'pencil',
                titleText: localeLookup('translations.Name'),
              },
              {
                onClick: () =>
                  editorActions.showConfirmRoleDeleteModal(role.id, () => {
                    getAllSpaces([]);
                    this.buildRows();
                  }),
                leftIconKind: 'trash2',
                leftIconColor: 'red',
                titleText: localeLookup('translations.Delete'),
              },
            ];
          default:
            return null;
        }
      }
      case 'areas': {
        const area = this.getStaticDataEntityByIndex(rowIndex);
        switch (columnName) {
          case 'name':
            return [
              {
                onClick: () =>
                  editorActions.showChangeAreaNameModal({ areaId: area.id }),
                leftIconKind: 'pencil',
                titleText: localeLookup('translations.Name'),
              },
              {
                onClick: () =>
                  editorActions.showConfirmAreaDeleteModal(area.id, () => {
                    getAllSpaces([]);
                    this.buildRows();
                  }),
                leftIconKind: 'trash2',
                leftIconColor: 'red',
                titleText: localeLookup('translations.Delete'),
              },
            ];
          default:
            return null;
        }
      }
      case 'spaces': {
        const space = this.getStaticDataEntityByIndex(rowIndex);
        switch (columnName) {
          case 'name':
            return [
              {
                onClick: () =>
                  editorActions.showChangeSpaceNameModal({ spaceId: space.id }),
                leftIconKind: 'pencil',
                titleText: localeLookup('translations.Name'),
              },
              {
                onClick: () => {
                  editorActions.showConfirmSpaceDeleteModal({
                    spaceId: space.id,
                    onDeleted: () => {
                      this.buildRows();
                    },
                  });
                },
                leftIconKind: 'trash2',
                leftIconColor: 'red',
                titleText: localeLookup('translations.Delete'),
              },
            ];
          default:
            return null;
        }
      }
      default: {
        return null;
      }
    }
  };

  getStaticDataEntityByIndex = (index) => {
    const { columns, staticData } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const hasColumnFocused = !!dynamicData.id;
    const offset = hasColumnFocused ? 2 : 1;
    const rows = this.getRowArray();
    return rows[index - offset];
  };

  getStaticDataEntityById = (id) => {
    const rows = this.getRowArray();
    return rows.find((row) => row.id === id);
  };

  getStaticDataColumns = () => {
    const { spacesEnabled } = this.props;
    const { staticData, columns } = this.state;
    const staticColumnData = columns[staticData.name];
    const staticColumns = staticColumnData?.columns;
    const dynamicData = staticColumnData.dynamicData;
    const columnsThatRequiresSpacesEnabled = [
      'space',
      'spaces',
      'contentAdministrator',
      'createRoles',
      'createAreas',
    ];
    if (!staticColumns) return {};
    const columnsWithoutHidden = Object.keys(staticColumns).reduce(
      (acc, columnId) => {
        const staticColumn = staticColumns[columnId];
        const isFocused = dynamicData.id === columnId;
        const isVisible = !staticColumnData.hiddenColumns.find(
          (column) => column.id === columnId
        );
        if (
          !spacesEnabled &&
          columnsThatRequiresSpacesEnabled.includes(columnId)
        ) {
          return acc;
        }
        if (isVisible && !isFocused) {
          return {
            ...acc,
            [columnId]: staticColumn,
          };
        }
        return acc;
      },
      {}
    );
    return columnsWithoutHidden;
  };

  isStaticCellDisabled = ({ id, columnName, entity }) => {
    const {
      roles,
      hasAccess,
      areas,
      currentUserId,
      readOnlyAreas,
      hasChangeAreaOwnerPermission,
      hasChangeRoleOwnerPermission,
      spaces,
    } = this.props;
    switch (entity) {
      case 'areas': {
        const area = areas[id];
        if (!area) return true;
        const isReadOnly = readOnlyAreas.includes(id);
        if (isReadOnly) return true;
        switch (columnName) {
          case 'owner': {
            const canEditOwner = hasChangeAreaOwnerPermission({
              currentOwnerId: area.owner,
              editableInSpaceIds: Object.keys(area.editableInSpaces),
            });

            return !canEditOwner;
          }
          case 'spaces': {
            const isAdmin = hasAccess([
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ]);
            const isAdminInAnySpacesWhereAreaIsEditable = Object.keys(
              area.editableInSpaces
            ).some((spaceId) =>
              spaces[spaceId].administrators.includes(currentUserId)
            );
            if (!isAdmin && !isAdminInAnySpacesWhereAreaIsEditable) return true;
            return false;
          }
          default: {
            return false;
          }
        }
      }
      case 'roles': {
        const role = roles[id];
        if (!role) return true;
        switch (columnName) {
          case 'owner': {
            const canEditOwner = hasChangeRoleOwnerPermission({
              currentOwnerId: role.owner,
              roleSpaceId: role.space,
            });

            return !canEditOwner;
          }
          case 'space': {
            const isAdmin = hasAccess([
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ]);
            if (isAdmin) return false;
            const roleSpace = spaces[role.space];
            const isAdminInRoleSpace =
              roleSpace.administrators.includes(currentUserId);
            return !isAdminInRoleSpace;
          }
          default: {
            return false;
          }
        }
      }
      default: {
        return false;
      }
    }
  };

  onChangeAreaCategory = ({ areaId, categoryId }) => {
    const { editorActions } = this.props;
    editorActions.updateAreaCategory({ areaId, categoryId });
  };

  onChangeAreaOwner = ({
    areaId,
    personId,
    showWarning,
    removeOwnerIfSameId,
  }) => {
    const { editorActions, areas, lookupPerson, showModal } = this.props;
    const area = areas[areaId];
    const currentOwner = lookupPerson(area.owner);
    const selectedOwner = lookupPerson(personId);
    if (showWarning && area.owner !== EMPTY_ID) {
      if (removeOwnerIfSameId && area.owner === personId) {
        showModal('confirmation', {
          title: localeLookup('translations.Owner'),
          subtitle: area.name,
          maxWidth: '500px',
          fullWidth: true,
          body: localeLookup(
            'translations.Do you want to remove {0} as the owner of the module {1}?',
            [currentOwner.name, area.name]
          ),
          confirmButtonText: localeLookup('translations.Change'),
          onConfirm: () => {
            editorActions.updateAreaOwner({
              areaId,
              ownerId: EMPTY_ID,
              showModalOnScopeCancel: false,
            });
          },
        });
      } else {
        showModal('confirmation', {
          title: localeLookup('translations.Owner'),
          subtitle: area.name,
          maxWidth: '500px',
          fullWidth: true,
          infoText: localeLookup(
            'translations.Modules can only have one owner'
          ),
          body: localeLookup(
            'translations.Do you want to change the owner of the module {0} from {1} to {2}?',
            [area.name, currentOwner.name, selectedOwner.name]
          ),
          confirmButtonText: localeLookup('translations.Change'),
          onConfirm: () => {
            editorActions.updateAreaOwner({
              areaId,
              ownerId: personId,
              showModalOnScopeCancel: false,
            });
          },
        });
      }
    } else {
      editorActions.updateAreaOwner({
        areaId,
        ownerId: personId,
        showModalOnScopeCancel: false,
      });
    }
  };

  onChangeColumnFilterInput = (e) => {
    this.setState(
      { columnFilterString: e.target.value },
      this.buildDynamicDataColumns
    );
  };

  onChangeAreaSpace = ({ areaId, spaceId, newStateId }) => {
    const { editorActions } = this.props;
    editorActions.updateAreaSpaceConnection({ areaId, spaceId, newStateId });
  };

  onChangeColumnSettings = async () => {
    const { columns } = this.state;
    this.gridRef.recomputeGridSize();
    const settings = Object.keys(columns).reduce((acc, columnId) => {
      const column = columns[columnId];
      return {
        ...acc,
        [columnId]: {
          hiddenColumns: column.hiddenColumns.map((column) => column.id),
          focusedColumn: column.dynamicData.id || '',
          showEmptyColumns: column.showEmptyColumns,
        },
      };
    }, {});
    await updateContentAdministrationColumnSettingsService(settings);
  };

  onChangeFocusedColumn = (focusedColumn) => {
    const { columns, staticData } = this.state;
    if (focusedColumn === null) {
      this.setState(
        {
          columnFilterString: '',
          columns: {
            ...columns,
            [staticData.name]: {
              ...columns[staticData.name],
              dynamicData: {
                name: '',
                entity: '',
                id: '',
              },
            },
          },
        },
        this.onChangeColumnSettings
      );
    } else {
      const columnId = focusedColumn.value;
      const column = columns[staticData.name].columns[columnId];
      this.setState(
        {
          columnFilterString: '',
          columns: {
            ...columns,
            [staticData.name]: {
              ...columns[staticData.name],
              dynamicData: {
                name: column.name,
                entity: column.entity,
                id: column.id,
              },
              hiddenColumns: columns[staticData.name].hiddenColumns.filter(
                (o) => o.id !== columnId
              ),
            },
          },
        },
        this.onChangeColumnSettings
      );
    }
  };

  onChangeRoleOwner = ({
    roleId,
    personId,
    showWarning,
    removeOwnerIfSameId,
  }) => {
    const { editorActions, roles, lookupPerson, showModal } = this.props;
    const role = roles[roleId];
    const currentOwner = lookupPerson(role.owner);
    const selectedOwner = lookupPerson(personId);

    if (showWarning && role.owner !== EMPTY_ID) {
      if (removeOwnerIfSameId && role.owner === personId) {
        showModal('confirmation', {
          title: localeLookup('translations.Role owner'),
          subtitle: role.name,
          maxWidth: '500px',
          fullWidth: true,
          body: localeLookup(
            'translations.Do you want to remove {0} as the owner of the role {1}?',
            [currentOwner.name, role.name]
          ),
          confirmButtonText: localeLookup('translations.Change'),
          onConfirm: () => {
            editorActions.updateRoleOwner({
              roleId,
              ownerId: EMPTY_ID,
              showModalOnScopeCancel: false,
            });
          },
        });
      } else {
        showModal('confirmation', {
          title: localeLookup('translations.Role owner'),
          subtitle: role.name,
          maxWidth: '500px',
          fullWidth: true,
          infoText: localeLookup('translations.Roles can only have one owner'),
          body: localeLookup(
            'translations.Do you want to change the owner of the role {0} from {1} to {2}?',
            [role.name, currentOwner.name, selectedOwner.name]
          ),
          confirmButtonText: localeLookup('translations.Change'),
          onConfirm: () => {
            editorActions.updateRoleOwner({
              roleId,
              ownerId: personId,
              showModalOnScopeCancel: false,
            });
          },
        });
      }
    } else {
      editorActions.updateRoleOwner({
        roleId,
        ownerId: personId,
        showModalOnScopeCancel: false,
      });
    }
  };

  onChangeRoleSpace = ({ roleId, spaceId }) => {
    const { editorActions } = this.props;
    editorActions.changeRoleSpace({ roleId, spaceId });
  };

  onChangeRowFilterInput = (e) => {
    this.setState({ rowFilterString: e.target.value }, this.buildRows);
  };

  onChangeTab = (tab) => {
    this.setState({ isLoading: true }, () => {
      this.setState(
        {
          isLoading: true,
          rowFilterString: '',
          staticData: {
            entity: tab.id,
            name: tab.dataName,
          },
        },
        () => {
          this.setState(
            {
              rows: this.getSortedRows(),
              dynamicDataColumns: this.getSortedDynamicDataColumnArray(),
              isLoading: false,
            },
            () => {
              this.gridRef.recomputeGridSize();
            }
          );
        }
      );
    });
  };

  onChangeVisibleColumns = (shownColumns) => {
    const { staticData, columns } = this.state;
    const columnOptions = this.getHideableColumnOptions();
    const selectedValues = shownColumns.map((column) => column.value);

    const newHiddenColumns = columnOptions.filter(
      (columnOption) => !selectedValues.includes(columnOption.value)
    );

    const dynamicDataId = columns[staticData.name].dynamicData.id;

    const isHidingFocusedColumn = newHiddenColumns.some(
      (column) => column.value === dynamicDataId
    );

    this.setState(
      {
        isDynamicDataLoading: isHidingFocusedColumn,
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            hiddenColumns: newHiddenColumns.map(({ label, value }) => ({
              id: value,
              name: label,
            })),
            dynamicData: isHidingFocusedColumn
              ? { id: '', name: '', entity: '' }
              : columns[staticData.name].dynamicData,
          },
        },
      },
      () => {
        if (isHidingFocusedColumn) {
          this.setState({
            dynamicDataColumns: this.getSortedDynamicDataColumnArray(),
            isDynamicDataLoading: false,
          });
        }
        this.onChangeColumnSettings();
      }
    );
  };

  onClickChangeSorting = ({ columnId, isDynamicColumn = false }) => {
    const { staticData, columns } = this.state;
    const sorting = columns[staticData.name].sorting;
    const direction =
      sorting.columnId === columnId && sorting.direction === 'asc'
        ? 'desc'
        : 'asc';
    this.setState(
      {
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            sorting: {
              columnId,
              direction,
              isDynamicColumn,
            },
          },
        },
      },
      () => {
        this.buildRows();
        this.gridRef.recomputeGridSize();
      }
    );
  };

  onClickDeactivateContentSpaces = async () => {
    const { columns } = this.state;
    const { setSpaceStatus, showModal } = this.props;
    const columnsThatRequiresSpacesEnabled = [
      'space',
      'spaces',
      'contentAdministrator',
      'createRoles',
      'createAreas',
    ];
    showModal('confirmation', {
      title: localeLookup('translations.Deactivate content spaces'),
      maxWidth: '500px',
      fullWidth: true,
      confirmButtonType: 'alert',
      body: localeLookup(
        'translations.Do you want to deactivate content spaces?'
      ),
      confirmButtonText: localeLookup('translations.Deactivate'),
      onConfirm: () => {
        // If any space related columns is focused, remove these
        this.setState(
          {
            columns: Object.keys(columns).reduce((acc, columnId) => {
              const column = columns[columnId];
              return {
                ...acc,
                [columnId]: {
                  ...columns[columnId],
                  dynamicData: columnsThatRequiresSpacesEnabled.includes(
                    column.dynamicData?.id
                  )
                    ? {
                        id: '',
                        name: '',
                        entity: '',
                      }
                    : column.dynamicData,
                },
              };
            }, {}),
          },
          this.onChangeColumnSettings
        );
        setSpaceStatus(false);
      },
    });
  };

  onClickEnableContentSpaces = () => {
    const { setSpaceStatus } = this.props;
    setSpaceStatus(true).then(() => {
      this.gridRef?.recomputeGridSize();
    });
  };

  onClickFocusColumn = ({ id, entity, name }) => {
    const { columns, staticData } = this.state;
    this.setState(
      {
        columnFilterString: '',
        dynamicDataColumns: [],
        isDynamicDataLoading: true,
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            dynamicData: {
              name: name,
              entity: entity,
              id: id,
            },
            hiddenColumns: columns[staticData.name].hiddenColumns.filter(
              (column) => column.id !== id
            ),
          },
        },
      },
      () => {
        this.setState({
          dynamicDataColumns: this.getSortedDynamicDataColumnArray(),
          isDynamicDataLoading: false,
        });
        this.onChangeColumnSettings();
      }
    );
  };

  onClickHideColumn = ({ id, name }) => {
    const { staticData, columns } = this.state;
    const isHidingFocusedColumn =
      columns[staticData.name].dynamicData.id === id;
    this.setState(
      {
        isDynamicDataLoading: isHidingFocusedColumn,
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            hiddenColumns: [
              ...columns[staticData.name].hiddenColumns,
              { id, name },
            ],
            dynamicData: isHidingFocusedColumn
              ? { id: '', name: '', entity: '' }
              : columns[staticData.name].dynamicData,
          },
        },
      },
      () => {
        if (isHidingFocusedColumn) {
          this.setState({
            dynamicDataColumns: this.getSortedDynamicDataColumnArray(),
            isDynamicDataLoading: false,
          });
        }
        this.onChangeColumnSettings();
      }
    );
  };

  onClickUnfocusColumn = () => {
    const { columns, staticData } = this.state;
    this.setState(
      {
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            dynamicData: {
              id: '',
              name: '',
              entity: '',
            },
          },
        },
      },
      () => {
        this.buildDynamicDataColumns();
        this.onChangeColumnSettings();
      }
    );
  };

  onClickUnhideColumn = ({ id: unHideId }) => {
    const { columns, staticData } = this.state;
    this.setState(
      {
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            hiddenColumns: columns[staticData.name].hiddenColumns.filter(
              ({ id }) => id !== unHideId
            ),
          },
        },
      },
      this.onChangeColumnSettings
    );
  };

  onClickToggleAdmin = async ({ spaceId, personId, isAdmin }) => {
    const { spaces, getAllSpaces } = this.props;
    const currentAdminIds = spaces[spaceId].administrators;
    if (isAdmin) {
      await updateSpaceAdministratorsService(
        [spaceId],
        [...currentAdminIds, personId],
        'space'
      );
    } else {
      await updateSpaceAdministratorsService(
        [spaceId],
        currentAdminIds.filter((id) => id !== personId),
        'space'
      );
    }
    getAllSpaces();
  };

  onClickToggleAreaEditor = ({ personId, areaId, isEditor }) => {
    const { areas, editorActions } = this.props;
    const area = areas[areaId];
    editorActions.updateAreaEditors({
      areaId,
      ids: isEditor
        ? [...area.editors, personId]
        : area.editors.filter((editorId) => editorId !== personId),
      showModalOnScopeCancel: false,
    });
  };

  onClickToggleCreateModulesInSpace = async ({
    personId,
    spaceId,
    canCreateModules,
  }) => {
    const { spaces, getAllSpaces } = this.props;
    const currentPersonIdsAllowedToCreateModules =
      spaces[spaceId].canCreateModules;
    if (canCreateModules) {
      await updateSpaceCreateModulePermissionsService(
        [spaceId],
        [...currentPersonIdsAllowedToCreateModules, personId],
        'space'
      );
    } else {
      await updateSpaceCreateModulePermissionsService(
        [spaceId],
        currentPersonIdsAllowedToCreateModules.filter((id) => id !== personId),
        'space'
      );
    }
    getAllSpaces();
  };

  onClickToggleCreateRolesInSpace = async ({
    personId,
    spaceId,
    canCreateRoles,
  }) => {
    const { spaces, getAllSpaces } = this.props;
    const currentPersonIdsAllowedToCreateRoles = spaces[spaceId].canCreateRoles;
    if (canCreateRoles) {
      await updateSpaceCreateRolePermissionsService(
        [spaceId],
        [...currentPersonIdsAllowedToCreateRoles, personId],
        'space'
      );
    } else {
      await updateSpaceCreateRolePermissionsService(
        [spaceId],
        currentPersonIdsAllowedToCreateRoles.filter((id) => id !== personId),
        'space'
      );
    }
    getAllSpaces();
  };

  onClickToggleRoleEditor = ({ roleId, personId, isEditor }) => {
    const { roles, editorActions } = this.props;
    const role = roles[roleId];
    editorActions.updateRoleEditors({
      roleId,
      ids: isEditor
        ? [...role.editors, personId]
        : role.editors.filter((editorId) => editorId !== personId),
      showModalOnScopeCancel: false,
    });
  };

  onClickToggleRoleOrganisationUnit = async ({ roleId, unitId, connect }) => {
    const { editorActions } = this.props;
    if (connect) {
      await connectRolesToOrganisationUnitsService([roleId], [unitId]);
      await editorActions.getRole(roleId);
    } else {
      await disconnectRolesFromOrganisationUnitsService([roleId], [unitId]);
      await editorActions.getRole(roleId);
    }
  };

  onClickToggleShowEmptyColumns = () => {
    const { staticData, columns } = this.state;
    const showEmptyColumns = columns[staticData.name].showEmptyColumns;
    this.setState(
      {
        columns: {
          ...columns,
          [staticData.name]: {
            ...columns[staticData.name],
            showEmptyColumns: !showEmptyColumns,
          },
        },
      },
      () => {
        this.buildDynamicDataColumns();
        this.onChangeColumnSettings();
      }
    );
  };

  renderCell = ({ columnIndex, key, rowIndex, style, parent }) => {
    const { columns, staticData } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const columnCount = this.getColumnCount();
    const isLastColumn = columnIndex === columnCount - 1;
    const hasColumnFocused = !!dynamicData.id;

    if (isLastColumn) return null;
    if (hasColumnFocused && rowIndex === 0) {
      return null;
    }
    if (
      (hasColumnFocused && rowIndex === 1) ||
      (!hasColumnFocused && rowIndex === 0)
    ) {
      return this.renderHeaderCell({
        columnIndex,
        key,
        rowIndex,
        style,
        parent,
        isLastColumn,
      });
    }
    return this.renderTableCell({
      columnIndex,
      key,
      rowIndex,
      style,
      parent,
      isLastColumn,
    });
  };

  renderHeaderCell = ({ columnIndex, key, style, isLastColumn }) => {
    const { organisationUnitParents } = this.props;
    const { staticData, columns } = this.state;
    const staticDataColumns = this.getStaticDataColumns();
    const staticDataColumn = Object.values(staticDataColumns)[columnIndex];
    const sorting = columns[staticData.name].sorting;
    // We add a hidden column last to support rotated headers
    if (isLastColumn) {
      return <TableHeaderCell hidden alignEnd key={key} style={style} />;
    } else if (staticDataColumn) {
      return (
        <TableHeaderCell
          sortable
          sortDirection={sorting.direction}
          sortActive={sorting.columnId === staticDataColumn.id}
          contextMenuItems={this.getStaticColumnHeaderCellContextMenuItems({
            name: staticDataColumn.name,
            entity: staticDataColumn.entity,
            id: staticDataColumn.id,
            focusable: staticDataColumn.focusable,
            hideable: staticDataColumn.hideable,
          })}
          onClick={() =>
            this.onClickChangeSorting({ columnId: staticDataColumn.id })
          }
          alignEnd
          key={key}
          title={staticDataColumn.name}
          style={style}
        />
      );
    } else {
      const dynamicData = columns[staticData.name].dynamicData;
      const sortedDynamicColumnArray = this.getDynamicDataColumnArray();
      const column =
        sortedDynamicColumnArray[
          columnIndex - Object.keys(staticDataColumns).length
        ];
      const unitParents =
        dynamicData.entity === 'organisationUnits'
          ? organisationUnitParents[column.id]
          : [];

      const tooltip = this.getDynamicColumnTooltip(
        dynamicData.entity,
        column.id
      );
      return (
        <TableHeaderCell
          alignEnd
          sortable
          sortActive={sorting.columnId === column.id}
          rotated
          onClick={() =>
            this.onClickChangeSorting({
              columnId: column.id,
              isDynamicColumn: true,
            })
          }
          key={key}
          title={column.name}
          style={style}
        >
          <div onClick={() => {}}>
            <p className="permission-matrix__header-cell-title">
              {unitParents.length > 0 && (
                <Text as="span">
                  {Array(unitParents.length).fill('·')} {''}
                </Text>
              )}
              <span
                className="permission-matrix__header-cell-title-text"
                title={column.name}
              >
                {column.name}
              </span>
            </p>
            {tooltip && (
              <Tooltip tooltip={tooltip}>
                <span>
                  <Icon
                    className="permission-matrix__header-cell-tooltip-icon"
                    kind="info-circle"
                  ></Icon>
                </span>
              </Tooltip>
            )}
          </div>
        </TableHeaderCell>
      );
    }
  };

  renderDynamicDataCellContents = ({ rowIndex, columnIndex }) => {
    const {
      hasAccess,
      currentUserId,
      readOnlyAreas,
      spaces,
      hasChangeAreaOwnerPermission,
      hasChangeRoleOwnerPermission,
    } = this.props;
    const { staticData, columns, personsWithAccessLevels } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    switch (staticData.entity) {
      case 'areas': {
        const area = this.getStaticDataEntityByIndex(rowIndex);
        const isAreaReadOnly = readOnlyAreas.includes(area.id);
        switch (dynamicData.id) {
          case 'editors': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const isEditor = area.editors.includes(person.id);
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleAreaEditor({
                    areaId: area.id,
                    personId: person.id,
                    isEditor: newState,
                  })
                }
                disabled={isAreaReadOnly}
                rounded
                activeStateId={isEditor}
                states={[
                  { icon: '', stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'category': {
            const category = this.getDynamicDataEntityByIndex(columnIndex);
            if (!category) {
              return null;
            }
            const hasCategory = area.category === category.id;
            const onChange = () =>
              this.onChangeAreaCategory({
                areaId: area.id,
                categoryId: category.id,
              });
            return (
              <RadioButton
                size="medium"
                id={`${area.id}${category.id}`}
                onChange={onChange}
                isChecked={hasCategory}
                name={area.id}
                disabled={isAreaReadOnly}
              />
            );
          }
          case 'owner': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const canEditOwner = hasChangeAreaOwnerPermission({
              currentOwnerId: area.owner,
              editableInSpaceIds: Object.keys(area.editableInSpaces),
            });
            const isOwner = area.owner === person.id;
            if (!person) {
              return null;
            }
            const onChange = () =>
              this.onChangeAreaOwner({ areaId: area.id, personId: person.id });
            return (
              <RadioButton
                size="medium"
                id={`${area.id}${person.id}`}
                onChange={onChange}
                isChecked={isOwner}
                disabled={!canEditOwner}
                name={area.id}
              />
            );
          }
          case 'spaces': {
            const space = this.getDynamicDataEntityByIndex(columnIndex);
            if (!area) return null;
            const isEditableInSpace = Object.keys(
              area.editableInSpaces
            ).includes(space.id);
            const isReadOnlyInSpace = Object.keys(
              area.readOnlyInSpaces
            ).includes(space.id);
            const activeStateId = isEditableInSpace
              ? 'editable'
              : isReadOnlyInSpace
              ? 'readonly'
              : 'notConnected';
            const areaSpaceIds = [
              ...Object.keys(area.editableInSpaces),
              ...Object.keys(area.readOnlyInSpaces),
            ];
            const isRequiredInSpace =
              area.editableInSpaces[space.id]?.connectedToRoles.length > 0 ||
              area.readOnlyInSpaces[space.id]?.connectedToRoles.length > 0;
            const canAreaBeRemovedFromSpace =
              (areaSpaceIds.length === 1 && areaSpaceIds.includes(space.id)) ||
              isRequiredInSpace;
            const isAdministratorInSpace =
              space.administrators.includes(currentUserId);
            const isAdminInAnySpacesWhereAreaIsEditable = Object.keys(
              area.editableInSpaces
            ).some((spaceId) =>
              spaces[spaceId].administrators.includes(currentUserId)
            );

            const isAdmin = hasAccess([
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ]);
            const disabled = isAdmin
              ? false
              : isAreaReadOnly ||
                !isAdministratorInSpace ||
                !isAdminInAnySpacesWhereAreaIsEditable;
            return (
              <MultiStateButton
                isRequired={isRequiredInSpace}
                disabled={disabled}
                onClick={(newStateId) =>
                  this.onChangeAreaSpace({
                    areaId: area.id,
                    spaceId: space.id,
                    newStateId,
                  })
                }
                rounded
                activeStateId={activeStateId}
                states={
                  canAreaBeRemovedFromSpace
                    ? [
                        {
                          icon: 'eye',
                          stateId: 'readonly',
                          color: 'light-green',
                        },
                        { icon: 'check', stateId: 'editable', color: 'green' },
                      ]
                    : [
                        { stateId: 'notConnected', color: 'stroke' },
                        {
                          icon: 'eye',
                          stateId: 'readonly',
                          color: 'light-green',
                        },
                        { icon: 'check', stateId: 'editable', color: 'green' },
                      ]
                }
              />
            );
          }
          default: {
            return '';
          }
        }
      }
      case 'spaces': {
        const space = this.getStaticDataEntityByIndex(rowIndex);
        if (!space) return null;
        switch (dynamicData.id) {
          case 'roles': {
            const role = this.getDynamicDataEntityByIndex(columnIndex);
            if (!role) {
              return null;
            }
            const onChange = () =>
              this.onChangeRoleSpace({ roleId: role.id, spaceId: space.id });
            return (
              <RadioButton
                size="medium"
                id={`${role.id}${space.id}`}
                onChange={onChange}
                isChecked={space.roles.includes(role.id)}
                name={role.id}
              />
            );
          }
          case 'areas': {
            const area = this.getDynamicDataEntityByIndex(columnIndex);
            if (!area) return null;
            const isEditableInSpace = Object.keys(
              area.editableInSpaces
            ).includes(space.id);
            const isReadOnlyInSpace = Object.keys(
              area.readOnlyInSpaces
            ).includes(space.id);
            const activeStateId = isEditableInSpace
              ? 'editable'
              : isReadOnlyInSpace
              ? 'readonly'
              : 'notConnected';
            const areaSpaceIds = [
              ...Object.keys(area.editableInSpaces),
              ...Object.keys(area.readOnlyInSpaces),
            ];
            const isRequiredInSpace =
              area.editableInSpaces[space.id]?.connectedToRoles.length > 0 ||
              area.readOnlyInSpaces[space.id]?.connectedToRoles.length > 0;
            const disabled =
              (areaSpaceIds.length === 1 && areaSpaceIds.includes(space.id)) ||
              isRequiredInSpace;
            return (
              <MultiStateButton
                isRequired={isRequiredInSpace}
                //disabled={disabled}
                onClick={(newStateId) =>
                  this.onChangeAreaSpace({
                    areaId: area.id,
                    spaceId: space.id,
                    newStateId,
                  })
                }
                rounded
                activeStateId={activeStateId}
                states={
                  disabled
                    ? [
                        {
                          icon: 'eye',
                          stateId: 'readonly',
                          color: 'light-green',
                        },
                        { icon: 'check', stateId: 'editable', color: 'green' },
                      ]
                    : [
                        { stateId: 'notConnected', color: 'stroke' },
                        {
                          icon: 'eye',
                          stateId: 'readonly',
                          color: 'light-green',
                        },
                        { icon: 'check', stateId: 'editable', color: 'green' },
                      ]
                }
              />
            );
          }
          case 'contentAdministrators': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const personsIdsThatHasAdministratorPermissionInSpace =
              this.getPersonsIdsThatHasAdministatorPermissionInSpace({
                spaceId: space.id,
              });
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleAdmin({
                    spaceId: space.id,
                    personId: person.id,
                    isAdmin: newState,
                  })
                }
                rounded
                disabled={isPersonAdmin}
                activeStateId={personsIdsThatHasAdministratorPermissionInSpace.includes(
                  person.id
                )}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'createRoles': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            const isSpaceAdmin = space.administrators.includes(person.id);
            const personsIdsThatHasCreateRolePermissionInSpace =
              this.getPersonsIdsThatHasCreateRolePermissionInSpace({
                spaceId: space.id,
              });
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleCreateRolesInSpace({
                    spaceId: space.id,
                    personId: person.id,
                    canCreateRoles: newState,
                  })
                }
                disabled={isPersonAdmin || isSpaceAdmin}
                rounded
                activeStateId={personsIdsThatHasCreateRolePermissionInSpace.includes(
                  person.id
                )}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'createAreas': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            const isSpaceAdmin = space.administrators.includes(person.id);
            const personsIdsThatHasCreateAreaPermissionInSpace =
              this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
                spaceId: space.id,
              });
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleCreateModulesInSpace({
                    spaceId: space.id,
                    personId: person.id,
                    canCreateModules: newState,
                  })
                }
                disabled={isPersonAdmin || isSpaceAdmin}
                rounded
                activeStateId={personsIdsThatHasCreateAreaPermissionInSpace.includes(
                  person.id
                )}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          default: {
            return '';
          }
        }
      }
      case 'persons': {
        const person = this.getStaticDataEntityByIndex(rowIndex);
        const personWithAccessLevels = personsWithAccessLevels[person.id];
        const isPersonAdmin = [
          ACCESS_LEVELS.champadministrator,
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.contentAdministrator,
        ].some((accessLevel) =>
          personWithAccessLevels?.accessLevels.includes(accessLevel)
        );
        const { editorAreaIds, editorRoleIds, ownedAreaIds, ownedRoleIds } =
          this.getOwnerAndEditorIdsForPerson(person.id);
        switch (dynamicData.id) {
          case 'ownedRoles': {
            const role = this.getDynamicDataEntityByIndex(columnIndex);
            return (
              <MultiStateButton
                onClick={() => {
                  this.onChangeRoleOwner({
                    roleId: role.id,
                    personId: person.id,
                    showWarning: true,
                    removeOwnerIfSameId: true,
                  });
                }}
                rounded
                activeStateId={ownedRoleIds.includes(role.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'editorRoles': {
            const role = this.getDynamicDataEntityByIndex(columnIndex);
            return (
              <MultiStateButton
                onClick={(newState) => {
                  this.onClickToggleRoleEditor({
                    roleId: role.id,
                    personId: person.id,
                    isEditor: newState,
                  });
                }}
                rounded
                activeStateId={editorRoleIds.includes(role.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'ownedAreas': {
            const area = this.getDynamicDataEntityByIndex(columnIndex);
            return (
              <MultiStateButton
                onClick={() => {
                  this.onChangeAreaOwner({
                    areaId: area.id,
                    personId: person.id,
                    showWarning: true,
                    removeOwnerIfSameId: true,
                  });
                }}
                rounded
                activeStateId={ownedAreaIds.includes(area.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'editorAreas': {
            const area = this.getDynamicDataEntityByIndex(columnIndex);
            return (
              <MultiStateButton
                onClick={(newState) => {
                  this.onClickToggleAreaEditor({
                    areaId: area.id,
                    personId: person.id,
                    isEditor: newState,
                  });
                }}
                rounded
                activeStateId={editorAreaIds.includes(area.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'contentAdministrator': {
            const space = this.getDynamicDataEntityByIndex(columnIndex);
            const administratorSpaceIds =
              this.getSpaceIdsWherePersonIsAdministrator({
                personId: person.id,
              });
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleAdmin({
                    spaceId: space.id,
                    personId: person.id,
                    isAdmin: newState,
                  })
                }
                disabled={isPersonAdmin}
                rounded
                activeStateId={administratorSpaceIds.includes(space.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'createRoles': {
            const space = this.getDynamicDataEntityByIndex(columnIndex);
            const isSpaceAdmin = space.administrators.includes(person.id);
            const createRolesSpaceIds =
              this.getSpaceIdsWherePersonCanCreateRoles({
                personId: person.id,
              });
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleCreateRolesInSpace({
                    spaceId: space.id,
                    personId: person.id,
                    canCreateRoles: newState,
                  })
                }
                rounded
                disabled={isPersonAdmin || isSpaceAdmin}
                activeStateId={createRolesSpaceIds.includes(space.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'createAreas': {
            const space = this.getDynamicDataEntityByIndex(columnIndex);
            const isSpaceAdmin = space.administrators.includes(person.id);
            const createAreasSpaceIds =
              this.getSpaceIdsWherePersonCanCreateAreas({
                personId: person.id,
              });
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleCreateModulesInSpace({
                    spaceId: space.id,
                    personId: person.id,
                    canCreateModules: newState,
                  })
                }
                disabled={isPersonAdmin || isSpaceAdmin}
                rounded
                activeStateId={createAreasSpaceIds.includes(space.id)}
                states={[
                  { stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          default: {
            return '';
          }
        }
      }
      case 'roles': {
        const role = this.getStaticDataEntityByIndex(rowIndex);
        switch (dynamicData.id) {
          case 'organisationUnits': {
            const organisationUnit =
              this.getDynamicDataEntityByIndex(columnIndex);
            const isConnectedToUnit = role.organisationUnits.includes(
              organisationUnit.id
            );
            return (
              <MultiStateButton
                onClick={(newState) => {
                  this.onClickToggleRoleOrganisationUnit({
                    roleId: role.id,
                    unitId: organisationUnit.id,
                    connect: newState,
                  });
                }}
                rounded
                activeStateId={isConnectedToUnit}
                states={[
                  { icon: '', stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'editors': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const isEditor = role.editors?.includes(person.id);
            return (
              <MultiStateButton
                onClick={(newState) =>
                  this.onClickToggleRoleEditor({
                    roleId: role.id,
                    personId: person.id,
                    isEditor: newState,
                  })
                }
                rounded
                activeStateId={isEditor}
                states={[
                  { icon: '', stateId: false, color: 'stroke' },
                  { icon: 'check', stateId: true, color: 'green' },
                ]}
              />
            );
          }
          case 'owner': {
            const person = this.getDynamicDataEntityByIndex(columnIndex);
            const isOwner = role.owner === person.id;
            const canEditOwner = hasChangeRoleOwnerPermission({
              currentOwnerId: role.owner,
              roleSpaceId: role.space,
            });
            if (!person) {
              return null;
            }
            const onChange = () =>
              this.onChangeRoleOwner({ roleId: role.id, personId: person.id });
            return (
              <RadioButton
                disabled={!canEditOwner}
                size="medium"
                id={`${role.id}${person.id}`}
                onChange={onChange}
                isChecked={isOwner}
                name={role.id}
              />
            );
          }
          case 'space': {
            const space = this.getDynamicDataEntityByIndex(columnIndex);
            const isCurrentUserSpaceAdmin =
              space.administrators.includes(currentUserId);
            const isCurrentUserAdminOnRoleSpace =
              spaces[role.space].administrators.includes(currentUserId);
            const isAdmin = hasAccess([
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ]);
            const disabled = isAdmin
              ? false
              : !isCurrentUserSpaceAdmin || !isCurrentUserAdminOnRoleSpace;
            if (!role) {
              return null;
            }
            const onChange = () =>
              this.onChangeRoleSpace({ roleId: role.id, spaceId: space.id });
            return (
              <RadioButton
                disabled={disabled}
                size="medium"
                id={`${role.id}${space.id}`}
                onChange={onChange}
                isChecked={space.roles.includes(role.id)}
                name={role.id}
              />
            );
          }
          default: {
            return '';
          }
        }
      }
      default: {
        return '';
      }
    }
  };

  renderTableCell = (props) => {
    const { columns, staticData, isDynamicDataLoading } = this.state;
    const { columnIndex, key, rowIndex, style, isLastColumn } = props;

    const dynamicData = columns[staticData.name].dynamicData;
    const staticDataColumns = this.getStaticDataColumns();
    const staticDataColumnName = Object.keys(staticDataColumns)[columnIndex];
    if (staticDataColumnName) {
      const column = staticDataColumns[staticDataColumnName];
      const item = this.getStaticDataEntityByIndex(rowIndex);
      if (!item) return null;
      return (
        <TableCell
          contextMenuItems={this.getStaticColumnCellContextMenuItems({
            rowIndex,
            columnName: staticDataColumnName,
          })}
          disabled={this.isStaticCellDisabled({
            id: item.id,
            columnName: staticDataColumnName,
            entity: staticData.entity,
          })}
          icon={column.icon || column.getIcon?.(item.id)}
          hoverToShowIcon={column.hoverToShowIcon !== false}
          onIconClick={() => column.onIconClick(item.id)}
          hidden={isLastColumn}
          style={style}
          key={key}
          subtitle={this.renderStaticDataCellSubtitle({
            rowIndex,
            columnName: staticDataColumnName,
          })}
          title={this.renderStaticDataCellContents({
            rowIndex,
            columnName: staticDataColumnName,
          })}
        />
      );
    }
    if (!isDynamicDataLoading && dynamicData.entity) {
      const showStripesOnOddRows = false;
      const showStripesOnOddColumns =
        staticData.name === 'spaces' && dynamicData.entity === 'roles';
      return (
        <TableCell
          backgroundColor={
            ((showStripesOnOddColumns && columnIndex % 2 === 0) ||
              (showStripesOnOddRows && rowIndex % 2 === 0)) &&
            'grey'
          }
          centerContent
          hidden={isLastColumn}
          style={style}
          key={key}
        >
          {this.renderDynamicDataCellContents({
            rowIndex,
            columnIndex: columnIndex - Object.keys(staticDataColumns).length,
          })}
        </TableCell>
      );
    }
    return null;
  };

  renderTable = () => {
    const { columns, staticData } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const hasColumnFocused = !!dynamicData.id;
    return (
      <AutoSizer nonce={cspNonce}>
        {({ height, width }) => (
          <MultiGrid
            ref={(el) => (this.gridRef = el)}
            getScrollbarSize={() => 12}
            cellRenderer={this.renderCell}
            columnCount={this.getColumnCount()}
            columnWidth={this.getColumnWidth}
            cellRangeRenderer={this.renderCellRange}
            fixedColumnCount={1}
            fixedRowCount={hasColumnFocused ? 2 : 1}
            rowCount={this.getRowCount()}
            rowHeight={this.getRowHeight}
            width={width}
            height={height}
            classNameTopRightGrid="grid__top-right"
            classNameTopLeftGrid="grid__top-left"
          />
        )}
      </AutoSizer>
    );
  };

  renderStaticDataCellContents = ({ rowIndex, columnName }) => {
    const { staticData } = this.state;
    const staticDataEntityName = staticData.entity;
    switch (staticDataEntityName) {
      case 'roles':
        return this.renderRoleCellContents({ rowIndex, columnName });
      case 'areas':
        return this.renderAreaCellContents({ rowIndex, columnName });
      case 'persons':
        return this.renderPersonCellContents({ rowIndex, columnName });
      case 'spaces':
        return this.renderSpaceCellContents({ rowIndex, columnName });
      default:
        return null;
    }
  };
  renderStaticDataCellSubtitle = ({ rowIndex, columnName }) => {
    const { spaces } = this.props;
    const { staticData } = this.state;
    const staticDataEntityName = staticData.entity;
    switch (staticDataEntityName) {
      case 'areas':
        var area = this.getStaticDataEntityByIndex(rowIndex);
        if (!area) return null;
        switch (columnName) {
          case 'spaces': {
            const editableInSpaceNames = Object.keys(area.editableInSpaces).map(
              (id) => spaces[id]?.name
            );
            const readOnlyInSpaceNames = Object.keys(area.readOnlyInSpaces).map(
              (id) => spaces[id]?.name
            );
            const numberOfSpacesEditable = editableInSpaceNames?.length || 0;
            const numberOfSpacesReadOnly = readOnlyInSpaceNames?.length || 0;
            const totalNumberOfSpaces =
              numberOfSpacesEditable + numberOfSpacesReadOnly;
            if (totalNumberOfSpaces !== 1) return null;
            return numberOfSpacesReadOnly === 0
              ? localeLookup('translations.Editable')
              : localeLookup('translations.Readonly');
          }
          default: {
            return null;
          }
        }
      default:
        return null;
    }
  };

  renderAreaCellContents = ({ rowIndex, columnName }) => {
    const { lookupPerson, spaces, categories } = this.props;
    var area = this.getStaticDataEntityByIndex(rowIndex);
    if (!area) return null;
    switch (columnName) {
      case 'name': {
        return area.name;
      }
      case 'champLink': {
        return insertStringInString(area.champLink.link, '-', 3);
      }
      case 'owner': {
        const owner = lookupPerson(area.owner);
        if (owner)
          return (
            <Tooltip
              tooltip={`${owner.initials} ${
                owner.employeeNumber ? `· ${owner.employeeNumber}` : ''
              }`}
            >
              <span>{owner.name}</span>
            </Tooltip>
          );
        return '';
      }
      case 'editors': {
        const editors = area.editors.map((id) => lookupPerson(id));
        const editorNames = editors.map((editor) => {
          return `${editor.name} (${editor.initials}${
            editor.employeeNumber ? ` · ${editor.employeeNumber}` : ''
          })`;
        });
        if (editors.length === 0) return '';
        if (editorNames.length > 1) {
          return (
            <Tooltip tooltip={editorNames.join('\n')}>
              <span>{`${editorNames.length} ${localeLookup(
                'translations.editors'
              )}`}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            tooltip={`${editors[0].initials} ${
              editors[0].employeeNumber ? `· ${editors[0].employeeNumber}` : ''
            }`}
          >
            <span>{editors[0].name}</span>
          </Tooltip>
        );
      }
      case 'spaces': {
        const editableInSpaceNames = Object.keys(area.editableInSpaces).map(
          (id) => spaces[id]?.name
        );
        const readOnlyInSpaceNames = Object.keys(area.readOnlyInSpaces).map(
          (id) => spaces[id]?.name
        );
        const numberOfSpacesEditable = editableInSpaceNames?.length || 0;
        const numberOfSpacesReadOnly = readOnlyInSpaceNames?.length || 0;
        const totalNumberOfSpaces =
          numberOfSpacesEditable + numberOfSpacesReadOnly;

        if (totalNumberOfSpaces > 1) {
          return (
            <Tooltip
              tooltip={
                <>
                  {editableInSpaceNames?.length > 0 && (
                    <>
                      <p>{localeLookup('translations.Editable')}:</p>
                      <ul>
                        {editableInSpaceNames.map((name) => (
                          <li key={name}>{name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {readOnlyInSpaceNames?.length > 0 && (
                    <>
                      <p>{localeLookup('translations.Read only')}:</p>
                      <ul>
                        {readOnlyInSpaceNames.map((name) => (
                          <li key={name}>{name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              }
            >
              <div>{`${totalNumberOfSpaces} ${localeLookup(
                'translations.content spaces'
              )}`}</div>
            </Tooltip>
          );
        }
        return <>{[...editableInSpaceNames, ...readOnlyInSpaceNames].join()}</>;
      }
      case 'category': {
        const category = categories[area.category];
        return category;
      }
      default: {
        return '';
      }
    }
  };

  renderCellRange = (props) => {
    const { staticData, columns, columnFilterString } = this.state;
    const children = defaultCellRangeRenderer(props);
    const dynamicData = columns[staticData.name].dynamicData;
    const showEmptyColumns = columns[staticData.name].showEmptyColumns;

    if (!dynamicData.id) return children;

    if (props.parent.props?.className === 'grid__top-left') {
      children.push(
        <div className="content-administration__focus-header-left"></div>
      );
    }
    if (props.parent.props?.className === 'grid__top-right') {
      const dynamicDataColumn =
        columns[staticData.name].columns[dynamicData.id];
      const staticDataColumns = this.getStaticDataColumns();
      const contextMenuItems = this.getStaticColumnHeaderCellContextMenuItems({
        id: dynamicDataColumn.id,
        entity: dynamicDataColumn.entity,
        focusable: dynamicDataColumn.focusable,
        hideable: dynamicDataColumn.hideable,
        name: dynamicDataColumn.name,
        isFocused: true,
      });
      children.push(
        <div
          key="focus-header"
          className="content-administration__focus-header-right"
        >
          {Object.values(staticDataColumns).map((column) => {
            // Rendering dummy cells for all columns but name, since it is fixed
            if (column.id !== 'name')
              return (
                <div
                  className="content-administration__focus-header-placeholder-cell"
                  style={{ width: column.width }}
                  key={column.id}
                ></div>
              );
          })}
          <div className="content-administration__focus-header-focused-cell">
            {localeLookup(dynamicData.name)}
            <ContextMenu
              closeOnSelectItem
              fixed
              className="table-header-cell__context-menu"
              triggerClass="table-header-cell__context-menu-trigger"
            >
              {contextMenuItems.map((item, i) => {
                return (
                  <ContextMenuItem
                    key={i}
                    leftIconKind={item.icon}
                    titleText={item.title}
                    onClick={item.onClick}
                  ></ContextMenuItem>
                );
              })}
            </ContextMenu>

            <SwitchCheckbox
              wrapperClassName="content-administration__focus-header-checkbox"
              onChange={this.onClickToggleShowEmptyColumns}
              isChecked={showEmptyColumns}
              labelText={localeLookup('translations.Show empty columns')}
              labelSize="sm"
              size="small"
            />
            <Input
              className="content-administration__focus-header-search-input"
              onChange={this.onChangeColumnFilterInput}
              placeholder={`${localeLookup('translations.Search')}...`}
              value={columnFilterString}
              size="small"
              fullWidth
            />
          </div>
        </div>
      );
    }
    return children;
  };

  renderCreateButton = () => {
    const { staticData } = this.state;
    const {
      editorActions,
      personActions,
      hasAccess,
      hasCreateAreaPermission,
      hasCreateRolePermission,
      getAllSpaces,
    } = this.props;
    switch (staticData.entity) {
      case 'roles': {
        const canCreate = hasCreateRolePermission({});
        if (!canCreate) return null;
        return (
          <Button
            kind="darkui"
            className="content-administration__header-button"
            onClick={() =>
              editorActions.showCreateRoleModal({
                allowSpaceSelection: true,
                onCreated: () => {
                  this.buildRows();
                  getAllSpaces([]);
                },
              })
            }
          >
            {localeLookup('translations.Create role')}
          </Button>
        );
      }
      case 'areas': {
        const canCreate = hasCreateAreaPermission({});
        if (!canCreate) return null;
        return (
          <Button
            kind="darkui"
            className="content-administration__header-button"
            onClick={() =>
              editorActions.showCreateAreaModal({
                allowSpaceSelection: true,
                onCreated: () => {
                  this.buildRows();
                  getAllSpaces([]);
                },
              })
            }
          >
            {localeLookup('translations.Create knowledge area')}
          </Button>
        );
      }
      case 'persons': {
        const canCreate = hasAccess([
          ACCESS_LEVELS.champadministrator,
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.createPersons,
          ACCESS_LEVELS.userAdministrator,
          ACCESS_LEVELS.personAdministrator,
        ]);
        if (!canCreate) {
          return null;
        }
        return (
          <Button
            kind="darkui"
            className="content-administration__header-button"
            onClick={() =>
              personActions.showCreatePersonModal({
                onCreated: () => {
                  this.getPersonsAccessLevels();
                  this.buildRows();
                },
              })
            }
          >
            {localeLookup('translations.Create person')}
          </Button>
        );
      }
      case 'spaces': {
        return (
          <Button
            kind="darkui"
            className="content-administration__header-button"
            onClick={() =>
              editorActions.showCreateSpaceModal({
                onCreated: this.buildRows,
              })
            }
          >
            {localeLookup('translations.Create content space')}
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  };

  renderHeader = () => {
    return (
      <header className="content-administration__header">
        {this.renderHeaderTop()}
        {this.renderHeaderBottom()}
      </header>
    );
  };

  renderHeaderBottom = () => {
    const { spacesEnabled, spaces } = this.props;
    const { staticData, columns, rowFilterString } = this.state;
    const hideableColumnOptions = this.getHideableColumnOptions();

    const hiddenColumnIds = columns[staticData.name].hiddenColumns.map(
      (column) => column.id
    );
    const shownColumns = hideableColumnOptions.filter(
      (column) => !hiddenColumnIds.includes(column.value)
    );
    return (
      <div className="content-administration__header-bottom">
        <div className="content-administration__header-bottom-left">
          <MultiSelect
            controlStyles={{ width: 300 }}
            overrideComponents={{
              MultiValue: ({ index }) =>
                index === 0 ? (
                  <>
                    {localeLookup(
                      'translations.Displayed columns ({0} of {1})',
                      [shownColumns.length, hideableColumnOptions.length]
                    )}{' '}
                  </>
                ) : null,
            }}
            closeMenuOnSelect={false}
            onChange={this.onChangeVisibleColumns}
            showCheckboxes
            value={shownColumns}
            placeholder={localeLookup('translations.No displayed columns')}
            isSearchable={false}
            hideSelectedOptions={false}
            isClearable={false}
            options={this.getHideableColumnOptions()}
            className="content-administration__header-column-select"
          />
          <Input
            className="content-administration__header-search-input"
            onChange={this.onChangeRowFilterInput}
            placeholder={`${localeLookup(
              'translations.Search for'
            )} ${localeLookup(`translations.${staticData.name}`)}`}
            value={rowFilterString}
            size="small"
            fullWidth
          />
        </div>
        <div className="content-administration__header-bottom-right">
          {spacesEnabled && staticData.name === 'spaces' && (
            <ConditionalWrap
              condition={Object.keys(spaces).length > 1}
              wrap={(children) => (
                <Tooltip
                  tooltip={localeLookup(
                    'translations.Can only deactivate if only 1 content space present'
                  )}
                >
                  <div>{children}</div>
                </Tooltip>
              )}
            >
              <Button
                kind="alert"
                disabled={Object.keys(spaces).length > 1}
                className="content-administration__header-button"
                onClick={this.onClickDeactivateContentSpaces}
              >
                {localeLookup('translations.Deactivate content spaces')}
              </Button>
            </ConditionalWrap>
          )}
          {this.renderCreateButton()}
        </div>
      </div>
    );
  };

  renderHeaderTop = () => {
    const { hasAccess } = this.props;
    const { staticData } = this.state;
    const tabs = [
      {
        name: localeLookup('translations.Roles'),
        id: 'roles',
        entity: 'roles',
        dataName: 'roles',
      },
      {
        name: localeLookup('translations.Knowledge areas'),
        id: 'areas',
        dataName: 'modules',
      },
      {
        name: localeLookup('translations.Persons'),
        id: 'persons',
        dataName: 'persons',
      },
      {
        name: localeLookup('translations.Content spaces'),
        id: 'spaces',
        dataName: 'spaces',
      },
    ];
    const showAdminTabs = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ]);
    return (
      <div className="content-administration__header-top">
        <Tabs
          onChangeTab={this.onChangeTab}
          activeTab={staticData.entity}
          tabWidth={'10rem'}
          tabs={
            showAdminTabs
              ? tabs
              : tabs.filter(
                  (tab) => tab.id !== 'spaces' && tab.id !== 'persons'
                )
          }
        ></Tabs>
      </div>
    );
  };

  renderLegend = () => {
    const { staticData, columns } = this.state;
    const dynamicData = columns[staticData.name].dynamicData;
    const legendItemsMap = {
      areas: {
        spaces: [
          {
            icon: 'check',
            text: localeLookup('translations.Editable'),
          },
          {
            icon: 'eye',
            text: localeLookup('translations.Read only'),
          },
          {
            text: (
              <Text as="span">
                <Text as="span" color="red">
                  *
                </Text>{' '}
                {localeLookup(
                  'translations.The module is used in a role in the content space and can not be removed'
                )}
              </Text>
            ),
          },
        ],
      },
      spaces: {
        modules: [
          {
            icon: 'check',
            text: localeLookup('translations.Editable'),
          },
          {
            icon: 'eye',
            text: localeLookup('translations.Read only'),
          },
          {
            text: (
              <Text as="span">
                <Text as="span" color="red">
                  *
                </Text>{' '}
                {localeLookup(
                  'translations.The module is used in a role in the content space and can not be removed'
                )}
              </Text>
            ),
          },
        ],
      },
    };
    const currentLegendItems =
      legendItemsMap[dynamicData.id]?.[staticData.name] || [];
    if (!dynamicData?.id || currentLegendItems.length === 0) return null;
    return (
      <div className="content-administration__legend">
        <Legend items={currentLegendItems}></Legend>
      </div>
    );
  };

  renderPersonCellContents = ({ rowIndex, columnName }) => {
    const { personsWithAccessLevels } = this.state;
    const { spaces, roles, areas } = this.props;
    var person = this.getStaticDataEntityByIndex(rowIndex);
    if (!person) return null;
    const { editorAreaIds, editorRoleIds, ownedAreaIds, ownedRoleIds } =
      this.getOwnerAndEditorIdsForPerson(person.id);
    switch (columnName) {
      case 'name': {
        return (
          <Tooltip
            tooltip={`${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber}` : ''
            }`}
          >
            <span>{person.name}</span>
          </Tooltip>
        );
      }
      case 'ownedRoles': {
        const roleNames = ownedRoleIds.reduce((acc, id) => {
          if (!roles[id]) return acc;
          return [...acc, roles[id]?.name];
        }, []);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Roles')}
            items={roleNames}
          />
        );
      }
      case 'editorRoles': {
        const roleNames = editorRoleIds.reduce((acc, id) => {
          if (!roles[id]) return acc;
          return [...acc, roles[id]?.name];
        }, []);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Roles')}
            items={roleNames}
          />
        );
      }
      case 'ownedAreas': {
        const areasNames = ownedAreaIds.reduce((acc, id) => {
          if (!areas[id]) return acc;
          return [...acc, areas[id]?.name];
        }, []);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Knowledge areas')}
            items={areasNames}
          />
        );
      }
      case 'editorAreas': {
        const areasNames = editorAreaIds.reduce((acc, id) => {
          if (!areas[id]) return acc;
          return [...acc, areas[id]?.name];
        }, []);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Knowledge areas')}
            items={areasNames}
          />
        );
      }
      case 'contentAdministrator': {
        const adminSpaceIds = this.getSpaceIdsWherePersonIsAdministrator({
          personId: person.id,
        });
        const spaceNames = adminSpaceIds.map((id) => spaces[id]?.name);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Content spaces')}
            items={spaceNames}
          />
        );
      }
      case 'createRoles': {
        const createRolesSpaceIds = this.getSpaceIdsWherePersonCanCreateRoles({
          personId: person.id,
        });
        const spaceNames = createRolesSpaceIds.map((id) => spaces[id]?.name);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Content spaces')}
            items={spaceNames}
          />
        );
      }
      case 'createAreas': {
        const createAreasSpaceIds = this.getSpaceIdsWherePersonCanCreateAreas({
          personId: person.id,
        });
        const spaceNames = createAreasSpaceIds.map((id) => spaces[id]?.name);
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Content spaces')}
            items={spaceNames}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  renderRoleCellContents = ({ rowIndex, columnName }) => {
    const { lookupPerson, spaces, organisationUnits } = this.props;
    var role = this.getStaticDataEntityByIndex(rowIndex);
    if (!role) return null;
    switch (columnName) {
      case 'name': {
        return role.name;
      }
      case 'organisationUnits': {
        const unitIds = role.organisationUnits;
        const unitNames = unitIds.map((id) => {
          const organisationUnit = organisationUnits[id];
          if (!organisationUnit) return null;
          const isVisibilityLimited =
            organisationUnit.state ===
              ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
            organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;
          return `${organisationUnits[id]?.name} ${
            isVisibilityLimited
              ? `(${localeLookup('translations.Limited visibility')})`
              : ''
          }`;
        });
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Organisation units')}
            items={unitNames}
          />
        );
      }
      case 'owner': {
        const owner = lookupPerson(role.owner);
        if (owner)
          return (
            <Tooltip
              tooltip={`${owner.initials} ${
                owner.employeeNumber ? `· ${owner.employeeNumber}` : ''
              }`}
            >
              <span>{owner.name}</span>
            </Tooltip>
          );
        return '';
      }
      case 'editors': {
        if (!role.editors) return '';
        const editors = role.editors?.map((id) => lookupPerson(id));
        const editorNames = editors?.map((editor) => {
          return `${editor.name} (${editor.initials}${
            editor.employeeNumber ? ` · ${editor.employeeNumber}` : ''
          })`;
        });
        if (editors.length === 0) return '';
        if (editorNames.length > 1) {
          return (
            <Tooltip tooltip={editorNames.join('\n')}>
              <span>{`${editorNames.length} ${localeLookup(
                'translations.editors'
              )}`}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            tooltip={`${editors[0].initials} ${
              editors[0].employeeNumber ? `· ${editors[0].employeeNumber}` : ''
            }`}
          >
            <span>{editors[0].name}</span>
          </Tooltip>
        );
      }
      case 'space': {
        const spaceName = spaces[role.space]?.name;
        return spaceName;
      }
      default: {
        return '';
      }
    }
  };

  renderSpaceCellContents = ({ rowIndex, columnName }) => {
    const { roles, areas, persons } = this.props;
    var space = this.getStaticDataEntityByIndex(rowIndex);
    if (!space) return null;
    switch (columnName) {
      case 'areas': {
        const editableAreaNames = sortBy(
          space.modulesEditable.map((id) => areas[id]?.name),
          [(a, b) => compareLocal(a, b)]
        );
        const readOnlyAreaNames = sortBy(
          space.modulesReadOnly.map((id) => areas[id]?.name),
          [(a, b) => compareLocal(a, b)]
        );
        return (
          <TruncatedArrayText
            tooltip={
              <>
                {editableAreaNames?.length > 0 && (
                  <>
                    <p>{localeLookup('translations.Editable')}:</p>
                    <ul>
                      {editableAreaNames.map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  </>
                )}
                {readOnlyAreaNames?.length > 0 && (
                  <>
                    <p>{localeLookup('translations.Read only')}:</p>
                    <ul>
                      {readOnlyAreaNames.map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            }
            countSuffixText={localeLookup('translations.Knowledge areas')}
            items={[...editableAreaNames, ...readOnlyAreaNames]}
          />
        );
      }
      case 'contentAdministrators': {
        const personsIdsThatHasAdministratorPermissionInSpace =
          this.getPersonsIdsThatHasAdministatorPermissionInSpace({
            spaceId: space.id,
          });
        const contentAdministrators =
          personsIdsThatHasAdministratorPermissionInSpace.map(
            (id) => persons[id]
          );

        const contentAdministratorNames = sortBy(
          contentAdministrators.map((person) => {
            if (person) {
              return `${person.name} (${person.initials}${
                person.employeeNumber ? ` · ${person.employeeNumber}` : ''
              })`;
            }
          }),
          [(a, b) => compareLocal(a, b)]
        );
        if (contentAdministrators.length === 0) return '';
        if (contentAdministrators.length > 1) {
          return (
            <TruncatedArrayText
              countSuffixText={localeLookup(
                'translations.Content administrators'
              )}
              items={contentAdministratorNames}
            />
          );
        }
        return (
          <Tooltip
            tooltip={`${contentAdministrators[0].initials} ${
              contentAdministrators[0].employeeNumber
                ? `· ${contentAdministrators[0].employeeNumber}`
                : ''
            }`}
          >
            <span>{contentAdministrators[0].name}</span>
          </Tooltip>
        );
      }
      case 'createRoles': {
        const personsIdsThatHasCreateRolePermissionInSpace =
          this.getPersonsIdsThatHasCreateRolePermissionInSpace({
            spaceId: space.id,
          });
        const personsThatHasCreateRolePermissionInSpace =
          personsIdsThatHasCreateRolePermissionInSpace.map((id) => persons[id]);

        const personNamesThatHasCreateRolePermissionInSpace = sortBy(
          personsThatHasCreateRolePermissionInSpace.map((person) => {
            if (person) {
              return `${person.name} (${person.initials}${
                person.employeeNumber ? ` · ${person.employeeNumber}` : ''
              })`;
            }
          }),
          [(a, b) => compareLocal(a, b)]
        );
        if (personsThatHasCreateRolePermissionInSpace.length === 0) return '';
        if (personsThatHasCreateRolePermissionInSpace.length > 1) {
          return (
            <TruncatedArrayText
              countSuffixText={localeLookup('translations.Persons')}
              items={personNamesThatHasCreateRolePermissionInSpace}
            />
          );
        }
        return (
          <Tooltip
            tooltip={`${
              personsThatHasCreateRolePermissionInSpace[0].initials
            } ${
              personsThatHasCreateRolePermissionInSpace[0].employeeNumber
                ? `· ${personsThatHasCreateRolePermissionInSpace[0].employeeNumber}`
                : ''
            }`}
          >
            <span>{personsThatHasCreateRolePermissionInSpace[0].name}</span>
          </Tooltip>
        );
      }
      case 'createAreas': {
        const personsIdsThatHasCreateAreaPermissionInSpace =
          this.getPersonsIdsThatHasCreateAreaPermissionInSpace({
            spaceId: space.id,
          });
        const personsThatHasCreateAreaPermissionInSpace =
          personsIdsThatHasCreateAreaPermissionInSpace.map((id) => persons[id]);

        const personNamesThatHasCreateAreaPermissionInSpace = sortBy(
          personsThatHasCreateAreaPermissionInSpace.map((person) => {
            if (person) {
              return `${person.name} (${person.initials}${
                person.employeeNumber ? ` · ${person.employeeNumber}` : ''
              })`;
            }
          }),
          [(a, b) => compareLocal(a, b)]
        );
        if (personsThatHasCreateAreaPermissionInSpace.length === 0)
          return personsThatHasCreateAreaPermissionInSpace;
        if (personsThatHasCreateAreaPermissionInSpace.length > 1) {
          return (
            <TruncatedArrayText
              countSuffixText={localeLookup('translations.Persons')}
              items={personNamesThatHasCreateAreaPermissionInSpace}
            />
          );
        }
        return (
          <Tooltip
            tooltip={`${
              personsThatHasCreateAreaPermissionInSpace[0].initials
            } ${
              personsThatHasCreateAreaPermissionInSpace[0].employeeNumber
                ? `· ${personsThatHasCreateAreaPermissionInSpace[0].employeeNumber}`
                : ''
            }`}
          >
            <span>{personsThatHasCreateAreaPermissionInSpace[0].name}</span>
          </Tooltip>
        );
      }
      case 'name': {
        return space.name;
      }
      case 'roles': {
        const roleIds = space.roles;
        const roleNames = sortBy(
          roleIds.map((id) => roles[id]?.name),
          [(a, b) => compareLocal(a, b)]
        );
        return (
          <TruncatedArrayText
            countSuffixText={localeLookup('translations.Roles')}
            items={roleNames}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  renderSpaceInitializationOverlay = () => {
    return (
      <div className="content-administration__space-overlay">
        <div className="content-administration__space-overlay-inner">
          <h1>{localeLookup('translations.Content spaces')}</h1>
          <Text>
            {localeLookup(
              'translations.Content spaces is a function to separate roles and modules. Each content space can be assigned content administrators who have the right to edit roles and modules in the content space'
            )}
          </Text>
          <Button
            onClick={this.onClickEnableContentSpaces}
            className="content-administration__space-overlay-button"
            kind="darkui"
          >
            {localeLookup('translations.Activate content spaces')}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { spacesEnabled } = this.props;
    const { isLoading, staticData } = this.state;
    if (isLoading) return null;
    return (
      <div className="content-administration">
        {!spacesEnabled &&
          staticData.name === 'spaces' &&
          this.renderSpaceInitializationOverlay()}
        {this.renderHeader()}
        <div className="content-administration__table">
          {this.renderTable()}
        </div>
        {this.renderLegend()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    roles,
    areas,
    spaces,
    categories,
    rootmenu,
    organisationUnits,
    editor,
    user,
  } = state;
  return {
    accessibleRoles: editor.accessibleRoles,
    accessibleAreas: editor.accessibleAreas,
    allowChangeOfChampLinkVisibility: rootmenu.allowChangeOfChampLinkVisibility,
    currentUserId: user.employeeId,
    roles,
    areas,
    persons: selectActivePersons(state),
    organisationUnits,
    organisationUnitParents: selectParentMap(state),
    spaces: spaces.spaces,
    spacesEnabled: spaces.enabled,
    categoriesObject: Object.keys(categories).reduce((acc, categoryId) => {
      return {
        ...acc,
        [categoryId]: { id: categoryId, name: categories[categoryId] },
      };
    }, {}),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllAreas,
      getAllCategories,
      getAllOrganisationUnits,
      getAllRoles,
      getAllSpaces,
      queryRoles,
      queryAreas,
      getSpaceStatus,
      setSpaceStatus,
      getRolesAccess,
      getAreasAccess,
      getActiveSpace,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPersonLookup(
    WithEditorActions(
      WithModals(withAccessControl(WithPersonActions(ContentAdministration)))
    )
  )
);
