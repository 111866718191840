import { TRAINEEROLES_RECIEVED, TRAINEE_ROLE_DATA_RECIEVED, TRAINEE_ROLE_DATA_PENDING } from '../actions/traineeRolesActions';

import { TRAINEE_AREA_RECIEVED, TRAINEE_ELEMENT_RECIEVED, TRAINEE_UPDATED_AREA_DATA_RECEIVED, UPDATE_AREA_NOTIFICATION_COUNT, UPDATED_AREA_STATUS_DATA_RECEIVED } from '../actions/traineeRoleActions';

const traineeInitialState = {
  roles: {},
};

const trainee = (state = traineeInitialState, action) => {
  switch (action.type) {
    case TRAINEEROLES_RECIEVED:
      return {
        ...state,
        ...action.data,
      };
    case TRAINEE_ROLE_DATA_RECIEVED:
      return {
        ...state,
        selectedRole: { ...action.data },
        fetchingRoleData: false,
      };
    case TRAINEE_ROLE_DATA_PENDING:
      return {
        ...state,
        fetchingRoleData: true,
      };
    case TRAINEE_AREA_RECIEVED:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          knowledgeAreas: {
            ...state.selectedRole.knowledgeAreas,
            [action.areaId]: action.area,
          },
          completionPercentage: action.completionPercentage,
        },
        roles: {
          ...state.roles,
          [action.roleId]: {
            ...state.roles[action.roleId],
            completionPercentage: action.completionPercentage,
          },
        },
      };
    case TRAINEE_ELEMENT_RECIEVED:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          knowledgeElements: {
            ...state.selectedRole.knowledgeElements,
            [action.elementId]: action.data.updatedKnowledgeElement[action.elementId],
          },
        },
      };
    case TRAINEE_UPDATED_AREA_DATA_RECEIVED:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          knowledgeAreas: {
            ...state.selectedRole.knowledgeAreas,
            [action.data.knowledgeArea.id]: action.data.knowledgeArea,
          },
          knowledgeElements: {
            ...state.selectedRole.knowledgeElements,
            ...action.data.knowledgeElements,
          },
        },
      };
    case UPDATE_AREA_NOTIFICATION_COUNT:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          knowledgeAreas: {
            ...state.selectedRole.knowledgeAreas,
            [action.areaId]: {
              ...state.selectedRole.knowledgeAreas[action.areaId],
              notificationCount: action.count,
            },
          },
        },
      };
    case UPDATED_AREA_STATUS_DATA_RECEIVED:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          knowledgeAreas: {
            ...state.selectedRole.knowledgeAreas,
            [action.areaId]: action.data.updatedKnowledgeArea[action.areaId],
          },
          completionPercentage: action.data.completionPercentage,
          knowledgeElements: {
            ...state.selectedRole.knowledgeElements,
            ...action.data.updatedKnowledgeElements,
          },
        },
        roles: {
          ...state.roles,
          [action.roleId]: {
            ...state.roles[action.roleId],
            completionPercentage: action.data.completionPercentage,
          },
        },
      };
    default:
      return state;
  }
};

export default trainee;
