import React, { PureComponent } from 'react';
import cx from 'classnames';
import ButtonIcon from '../ButtonIcon';

class Input extends PureComponent {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
    this.state = {
      showClearText: false,
    };
  }
  onKeyDown(e) {
    const { onEnterPress } = this.props;
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress(e);
    }
  }

  onClickToggleClearText = () => {
    this.setState((prevState) => ({
      showClearText: !prevState.showClearText,
    }));
  };

  render() {
    const {
      autoComplete,
      accept,
      autoFocus,
      className,
      fullWidth,
      id,
      innerRef,
      kind,
      name,
      onBlur,
      onChange,
      placeholder,
      size,
      type,
      value,
      disabled,
      maxLength,
      wrapperClassName,
    } = this.props;
    const { showClearText } = this.state;
    const modifierClasses = {
      [`input--color-${kind}`]: kind,
      [`input--size-${size}`]: size,
      'input--full-width': fullWidth,
    };
    return (
      <div className={cx('input-wrapper', wrapperClassName)}>
        <input
          data-lpignore="true"
          data-1p-ignore="true"
          autoComplete={autoComplete}
          accept={accept}
          disabled={disabled}
          autoFocus={autoFocus}
          className={cx('input', modifierClasses, className)}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          ref={innerRef}
          type={showClearText && type === 'password' ? 'text' : type}
          value={value}
        />
        {type === 'password' && (
          <ButtonIcon
            tabIndex="-1"
            onClick={this.onClickToggleClearText}
            className="input__icon-button"
            icon={showClearText ? 'eye-crossed' : 'eye'}
          />
        )}
      </div>
    );
  }
}

Input.defaultProps = {
  type: 'text',
  className: '',
};

export default React.forwardRef((props, ref) => <Input innerRef={ref} {...props} />);
