import React from 'react';
import localeLookup from '../config/locale';
import Tooltip from './Tooltip';
import { connect } from 'react-redux';
import { ORGANISATION_UNIT_STATES } from '../constants';

const mapStateToProps = (state) => ({
  stateOrganisationUnits: state.organisationUnits,
});

const OrganisationUnitsText = ({
  organisationUnits,
  truncateAfter = 2,
  organisationUnitIds,
  stateOrganisationUnits,
}) => {
  if (organisationUnitIds) {
    if (organisationUnitIds.length >= truncateAfter) {
      return (
        <Tooltip
          tooltip={organisationUnitIds.map((id, i) => {
            const organisationUnit = stateOrganisationUnits[id];
            if (!organisationUnit) return null;
            const isVisibilityLimited =
              organisationUnit.state ===
                ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
              organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;
            return (
              <span key={i}>
                {organisationUnit.name}
                {isVisibilityLimited ? (
                  <span>
                    {' '}
                    ({localeLookup('translations.Limited visibility')})
                  </span>
                ) : null}
                <br />
              </span>
            );
          })}
        >
          <span>
            {organisationUnitIds.length}{' '}
            {localeLookup('translations.organisation units')}
          </span>
        </Tooltip>
      );
    }
    const organisationUnitNames = organisationUnitIds.map((id) => {
      const organisationUnit = stateOrganisationUnits[id];
      if (!organisationUnit) return;
      const isVisibilityLimited =
        organisationUnit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
        organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;

      return `${organisationUnit.name} ${
        isVisibilityLimited
          ? `(${localeLookup('translations.Limited visibility')})`
          : ''
      }`;
    });
    return (
      <span title={organisationUnitNames.join(', ')}>
        {organisationUnitNames.join(', ')}
      </span>
    );
  } else {
    if (organisationUnits.length >= truncateAfter) {
      return (
        <Tooltip
          tooltip={organisationUnits.map((unitName, i) => (
            <span key={i}>
              {unitName} <br></br>
            </span>
          ))}
        >
          <span>
            {organisationUnits.length}{' '}
            {localeLookup('translations.organisation units')}
          </span>
        </Tooltip>
      );
    }
    return (
      <span title={organisationUnits.join(', ')}>
        {organisationUnits.join(', ')}
      </span>
    );
  }
};

export default connect(mapStateToProps)(OrganisationUnitsText);
