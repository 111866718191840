import React from 'react';

const LoadScreen = () => {
  return (
    <div className="load-screen">
      <div className="load-screen__spinner">
        <svg
          className="load-screen__spinner-logo"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 37.11 37.07"
        >
          <path
            className="load-screen__spinner-body"
            d="M30.15,33.72c-2.5,1-4.93,2.33-7.52,2.94A18.41,18.41,0,0,1,.08,17.93,18.41,18.41,0,0,1,19.4.13,18.4,18.4,0,0,1,37.17,18.75q0,7.24,0,14.48c0,2.28-1.16,3.7-3.2,3.93-1.88.21-3.35-1.05-3.78-3.25C30.16,33.75,30.12,33.59,30.15,33.72ZM18.57,31.85A13.14,13.14,0,0,0,31.85,18.69,13.29,13.29,0,0,0,18.58,5.33,13.43,13.43,0,0,0,5.31,18.62,13.3,13.3,0,0,0,18.57,31.85Z"
            transform="translate(-0.06 -0.11)"
          />
          <path
            className="load-screen__spinner-eye"
            d="M27.26,13.53c.66,2.05,0,4.1-1.48,4.57s-3.22-.82-3.88-2.87,0-4.1,1.48-4.57S26.6,11.47,27.26,13.53Z"
            transform="translate(-0.06 -0.11)"
          />
          <path
            className="load-screen__spinner-eye"
            d="M18.83,14.74c.73,2,.15,4.1-1.31,4.62s-3.24-.7-4-2.73-.15-4.1,1.32-4.62S18.1,12.71,18.83,14.74Z"
            transform="translate(-0.06 -0.11)"
          />
        </svg>
      </div>
    </div>
  );
};

export default LoadScreen;
