import React from 'react';
import cx from 'classnames';

const Label = ({ children, htmlFor, className, style = 'upper' }) => {
  const modifierClasses = {
    [`label--style-${style}`]: style,
  };
  return (
    <label
      htmlFor={htmlFor}
      className={cx('label', modifierClasses, className)}
    >
      {children}
    </label>
  );
};

export default Label;
