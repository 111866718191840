import React from 'react';
import RCTooltip from 'rc-tooltip';

export default function Tooltip({
  tooltip,
  children,
  placement,
  showArrow,
  align,
  overlayStyle,
  onVisibleChange,
}) {
  if (!tooltip) return children;
  return (
    <RCTooltip
      onVisibleChange={onVisibleChange}
      overlayStyle={overlayStyle}
      align={align}
      showArrow={showArrow}
      placement={placement}
      overlay={<span>{tooltip}</span>}
    >
      {children}
    </RCTooltip>
  );
}
