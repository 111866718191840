import { getUserInfo } from '../services/authService';
import { fetchMenuItems } from '../services/menuService';
import { setDefaultDateFnsOptions } from '../utils/helpers';

export const MENU_DATA_RECEIVED = 'MENU_DATA_RECEIVED';
export const menuDataReceived = (data) => ({
  type: MENU_DATA_RECEIVED,
  data,
});
export const SYSTEM_PERMISSIONS_RECEIVED = 'SYSTEM_PERMISSIONS_RECEIVED';
export const systemPermissionsReceived = (data) => ({
  type: SYSTEM_PERMISSIONS_RECEIVED,
  data,
});

export const AUTH_USER_INFO_RECEIVED = 'AUTH_USER_INFO_RECEIVED';
export const authUserInfoReceived = ({ id, tenantId, tenants }) => ({
  type: AUTH_USER_INFO_RECEIVED,
  id,
  tenantId,
  tenants,
});

export const USER_INFO_RECEIVED = 'USER_INFO_RECEIVED';
export const userInfoReceived = ({
  initials,
  name,
  email,
  locale,
  organisation,
  champLinkPrefix,
  created,
  isManagingCustomer,
  employeeId,
}) => ({
  type: USER_INFO_RECEIVED,
  initials,
  name,
  email,
  locale,
  organisation,
  champLinkPrefix,
  created,
  isManagingCustomer,
  employeeId,
});

export const getAuthUserData = () => (dispatch) =>
  getUserInfo()
    .then((response) => {
      const { tenants, primary, userId } = response.data;
      dispatch(
        authUserInfoReceived({ id: userId, tenantId: primary, tenants })
      );
      return { tenantId: primary, tenants, userId };
    })
    .catch((err) => {
      throw new Error(err);
    });

export const requestMenuData = () => (dispatch) =>
  fetchMenuItems()
    .then((response) => {
      const { menuName, menuGuide } = response.data;
      const { id, name, email, locale } = menuName;
      setDefaultDateFnsOptions({ locale });
      dispatch(systemPermissionsReceived(menuGuide));
      dispatch(menuDataReceived(response.data));
      dispatch(
        userInfoReceived({
          initials: menuName.initials,
          name: menuName.name,
          email: menuName.email,
          locale: menuName.locale,
          organisation: menuName.organisation,
          champLinkPrefix: menuName.champLinkPrefix,
          created: menuName.created,
          isManagingCustomer: menuName.isManagingCustomer,
          employeeId: menuName.id,
        })
      );
      return {
        name,
        email,
        telemetryApiKey: menuGuide.telemetryApiKey,
        telemetryUrl: menuGuide.telemetryUrl,
        telemetryEnabled: menuGuide.telemetryEnabled,
        aiConnectionString: menuGuide.aiConnectionString,
        showSupport: menuGuide.showIntercom,
      };
    })
    .catch((ex) => {
      throw new Error(ex);
    });
