/* eslint-disable nonblock-statement-body-position */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import {
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  EXPERT_ID,
  KNOWLEDGE_OWNER_ID,
  MENTOR_ID,
  NONE_ID,
  PERSON_STATES,
  ROLE_EDITORS_ID,
  ROLE_OWNER_ID,
  SELF_STUDY_ID,
  VALID_PERSON_STATES,
  WILDCARDS_SORTING_RANK,
} from '../../constants';
import { updateElementResponsibilityService } from '../../services/contentService';
import { compareLocal, sortBy } from '../../utils/helpers';
import withPersonLookup from '../HOC/withPersonLookup';
import ListCard from '../ListCard';
import RadioButton from '../RadioButton';
import SwitchCheckbox from '../SwitchCheckbox';
import Tabs from '../Tabs';
import Text from '../Text';
import CreateUserModal from './CreateUserModal';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => ({
  persons: state.persons,
  wildcardPersons: state.wildcardPersons,
});

class MediatorModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterString: '',
      selectedMediatorId: props.selectedMediator,
      selectedAlternativeMediatorId:
        props.selectedAlternativeMediatorId || EMPTY_ID,
      locked: props.locked,
      requireSignature: props.requireSignature,
      multiApproval: props.multiApproval,
      alternativeMediator: !!props.selectedAlternativeMediatorId,
      isCreatingUser: false,
      newlyCreatedUsers: [],
      activeTab: 'trainingApprover',
    };
  }

  getCurrentSelectedMediatorId = () => {
    const { activeTab } = this.state;
    const { selectedMediator, selectedAlternativeMediatorId } = this.props;
    if (activeTab === 'trainingApprover') return selectedMediator;
    if (selectedAlternativeMediatorId === null) return EMPTY_ID;
    return selectedAlternativeMediatorId;
  };

  getOptionSubtitle = (id) => {
    const { locked, multiApproval, requireSignature } = this.state;
    switch (id) {
      case EMPTY_ID:
        if (!locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.Everybody can complete the element'
          );
        }
        if (locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.Another person than the person themself must complete the element'
          );
        }
        if (locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.Another person than the person themself must complete the element with a signature'
          );
        }
        if (!locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.Everybody can complete the element with a signature'
          );
        }
        if (locked && !requireSignature && multiApproval) {
          return localeLookup(
            'translations.The person themself and another person must complete the element'
          );
        }
        return localeLookup(
          'translations.The person themself and another person must complete the element with a signature'
        );

      case SELF_STUDY_ID:
        if (!locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the person themself. Everybody can complete the element'
          );
        }
        if (locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The person themself must complete the element'
          );
        }
        if (locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The person themself must complete the element with a signature'
          );
        }
        if (!locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the person themself. Everybody can complete the element with a signature'
          );
        }
        return '';

      case MENTOR_ID:
        if (!locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the persons mentor. Everybody can complete the element'
          );
        }
        if (locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The persons mentor must complete the element'
          );
        }
        if (locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The persons mentor must complete the element with a signature'
          );
        }
        if (!locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the persons mentor. Everybody can complete the element with a signature'
          );
        }
        if (locked && !requireSignature && multiApproval) {
          return localeLookup(
            'translations.The person themself and the persons mentor must complete the element'
          );
        }
        return localeLookup(
          'translations.The person themself and the persons mentor must complete the element with a signature'
        );

      case EXPERT_ID:
        if (!locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the knowledge areas experts. Everybody can complete the element'
          );
        }
        if (locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.One of the knowledge areas experts must complete the element'
          );
        }
        if (locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.One of the knowledge areas experts must complete the element with a signature'
          );
        }
        if (!locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the knowledge areas experts. Everybody can complete the element with a signature'
          );
        }
        if (locked && !requireSignature && multiApproval) {
          return localeLookup(
            'translations.The person themself and one of the knowledge areas experts must complete the element'
          );
        }
        return localeLookup(
          'translations.The person themself and one of the knowledge areas experts must complete the element with a signature'
        );

      case KNOWLEDGE_OWNER_ID:
        if (!locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the knowledge areas owner. Everybody can complete the element'
          );
        }
        if (locked && !requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The knowledge areas owner must complete the element'
          );
        }
        if (locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The knowledge areas owner must complete the element with a signature'
          );
        }
        if (!locked && requireSignature && !multiApproval) {
          return localeLookup(
            'translations.The task is assigned the knowledge areas owner. Everybody can complete the element with a signature'
          );
        }
        if (locked && !requireSignature && multiApproval) {
          return localeLookup(
            'translations.The person themself and the knowledge areas owner must complete the element'
          );
        }
        return localeLookup(
          'translations.The person themself and the knowledge areas owner must complete the element with a signature'
        );

      default:
        return '';
    }
  };

  getWildcardOptions = () => {
    const { locked } = this.state;
    const { wildcardPersons } = this.props;
    const currentSelectedMediatorId = this.getCurrentSelectedMediatorId();
    const options = Object.keys(wildcardPersons).reduce(
      (acc, id) => {
        if (
          id !== DELETED_GROUP_ID &&
          id !== DELETED_PERSON_ID &&
          id !== ROLE_EDITORS_ID &&
          id !== ROLE_OWNER_ID &&
          id !== currentSelectedMediatorId
        ) {
          return [
            ...acc,
            {
              label: wildcardPersons[id].name,
              value: id,
              subtitle: this.getOptionSubtitle(id),
            },
          ];
        }
        return acc;
      },
      currentSelectedMediatorId === EMPTY_ID
        ? []
        : [
            {
              label: locked
                ? localeLookup('translations.Relevant person')
                : localeLookup('translations.Any person'),
              value: EMPTY_ID,
              subtitle: this.getOptionSubtitle(EMPTY_ID),
            },
          ]
    );
    return this.getFilteredOptions(
      sortBy(options, [
        (a, b) =>
          WILDCARDS_SORTING_RANK[a.value] - WILDCARDS_SORTING_RANK[b.value],
      ])
    );
  };

  getExpertOptions = () => {
    const { expertIds, persons } = this.props;
    const currentSelectedMediatorId = this.getCurrentSelectedMediatorId();
    const options = expertIds.reduce((acc, id) => {
      const person = persons[id];
      if (id !== currentSelectedMediatorId) {
        return [
          ...acc,
          {
            label: persons[id].name,
            value: id,
            tooltip: `${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          },
        ];
      }
      return acc;
    }, []);
    return this.getFilteredOptions(
      sortBy(options, [(a, b) => compareLocal(a.label, b.label)])
    );
  };

  getFilteredOptions = (options) => {
    const { filterString } = this.state;
    const optionFilterFunction = (option) => {
      if (option.searchString) {
        return option.searchString
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return option.label.toLowerCase().includes(filterString.toLowerCase());
    };
    if (filterString.length === 0) return options;
    return options.filter(optionFilterFunction);
  };

  getPersonOptions = () => {
    const { persons, expertIds } = this.props;
    const currentSelectedMediatorId = this.getCurrentSelectedMediatorId();
    const options = Object.keys(persons).reduce((acc, id) => {
      const person = persons[id];
      if (
        expertIds.includes(id) ||
        person.state !== PERSON_STATES.ACTIVE ||
        id === currentSelectedMediatorId
      )
        return acc;
      return [
        ...acc,
        {
          label: person.name,
          value: id,
          tooltip: `${person.initials} ${
            person.employeeNumber ? `· ${person.employeeNumber}` : ''
          }`,
          searchString: `${person.name}${person.employeeNumber}${person.initials}`,
        },
      ];
    }, []);
    return this.getFilteredOptions(
      sortBy(options, [(a, b) => compareLocal(a.label, b.label)])
    );
  };

  getCurrentSelectionOptions = () => {
    const { locked } = this.state;
    const { lookupPerson } = this.props;
    const currentSelectedMediatorId = this.getCurrentSelectedMediatorId();
    const currentSelectedPerson = lookupPerson(currentSelectedMediatorId);

    if (currentSelectedMediatorId === EMPTY_ID) {
      return this.getFilteredOptions([
        {
          label: locked
            ? localeLookup('translations.Relevant person')
            : localeLookup('translations.Any person'),
          subtitle: this.getOptionSubtitle(currentSelectedMediatorId),
          value: currentSelectedMediatorId,
        },
      ]);
    }
    if (currentSelectedPerson === null) {
      return this.getFilteredOptions([
        {
          label: localeLookup('translations.Not available'),
          value: currentSelectedMediatorId,
          assignable: false,
        },
      ]);
    }
    return this.getFilteredOptions([
      {
        label: `${currentSelectedPerson.name} ${
          currentSelectedPerson.state === PERSON_STATES.ARCHIVED
            ? `(${localeLookup('translations.Archived')})`
            : ''
        }`,
        labelColor:
          currentSelectedPerson.state === PERSON_STATES.ARCHIVED ||
          currentSelectedMediatorId === DELETED_GROUP_ID ||
          currentSelectedMediatorId === DELETED_PERSON_ID
            ? 'red'
            : 'black',
        subtitle: this.getOptionSubtitle(currentSelectedMediatorId),
        value: currentSelectedMediatorId,
        tooltip: currentSelectedPerson.isWildcardPerson
          ? null
          : `${currentSelectedPerson.initials} ${
              currentSelectedPerson.employeeNumber
                ? `· ${currentSelectedPerson.employeeNumber}`
                : ''
            }`,
        assignable:
          currentSelectedPerson.state !== PERSON_STATES.ARCHIVED &&
          currentSelectedMediatorId !== DELETED_PERSON_ID &&
          currentSelectedMediatorId !== DELETED_GROUP_ID,
      },
    ]);
  };

  getGroups = () => {
    const { newlyCreatedUsers } = this.state;
    const groups = [
      {
        title: localeLookup('translations.Current selection'),
        options: this.getCurrentSelectionOptions(),
      },
      {
        title: localeLookup('translations.Just created'),
        options: newlyCreatedUsers,
      },
      {
        title: localeLookup('translations.Mediator type'),
        options: this.getWildcardOptions(),
      },
      {
        title: localeLookup('translations.Experts'),
        options: this.getExpertOptions(),
      },
      {
        title: localeLookup('translations.Other persons'),
        options: this.getPersonOptions(),
      },
    ];
    return groups;
  };

  onChangeApproverTab = (tab) => {
    this.setState({
      activeTab: tab.id,
    });
  };

  onChangeFilter = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onChangeMediator = (value) => {
    const { activeTab } = this.state;
    if (activeTab === 'trainingApprover') {
      this.setState({
        selectedMediatorId: value,
      });
    } else {
      this.setState({
        selectedAlternativeMediatorId: value,
      });
    }
  };

  onChangeSetting = (e) => {
    if (e.target.name === 'multiApproval') {
      this.setState({ [e.target.name]: e.target.checked, locked: true });
    } else if (e.target.name === 'alternativeMediator') {
      this.setState({
        [e.target.name]: e.target.checked,
        activeTab: 'trainingApprover',
      });
    } else {
      this.setState({ [e.target.name]: e.target.checked });
    }
  };

  onClickCancelCreateUser = () => {
    const { updateModalProps } = this.props;
    updateModalProps({ fullWidth: true });
    this.setState({ isCreatingUser: false });
  };

  onClickCreateUser = () => {
    const { updateModalProps } = this.props;
    updateModalProps({ fullWidth: false });
    this.setState({ isCreatingUser: true });
  };

  onClickSubmitMediator = () => {
    const { elementId, onUpdated, onClose } = this.props;
    const {
      locked,
      selectedMediatorId,
      multiApproval,
      requireSignature,
      selectedAlternativeMediatorId,
      alternativeMediator,
    } = this.state;
    updateElementResponsibilityService(elementId, {
      lockedApprover: locked,
      responsible: selectedMediatorId,
      alternativeResponsible: alternativeMediator
        ? selectedAlternativeMediatorId
        : null,
      completionType: multiApproval ? 'Multipart' : 'Standard',
      completionRequirement: requireSignature ? 'Signature' : 'None',
    }).then(() => {
      onClose();
      onUpdated();
    });
  };

  onCreatedUser = ({ id, name }) => {
    const { newlyCreatedUsers } = this.state;
    const { onCreatedUser, updateModalProps } = this.props;
    updateModalProps({ fullWidth: true });
    this.setState({
      selectedMediatorId: id,
      isCreatingUser: false,
      newlyCreatedUsers: [{ label: name, value: id }, ...newlyCreatedUsers],
    });
    onCreatedUser();
  };

  renderGroup = (group, i) => {
    const { activeTab } = this.state;
    const isOnlyOptionMentorAndShouldBeHidden =
      group.options.length === 1 &&
      group.options[0].value === MENTOR_ID &&
      activeTab === 'qualifiedApprover';
    if (group.options.length > 0 && !isOnlyOptionMentorAndShouldBeHidden) {
      return (
        <div key={i}>
          <h2 className="e-label-upper modal-content__body-title">
            {group.title}
          </h2>
          <ul className="box-list">{group.options.map(this.renderOption)}</ul>
        </div>
      );
    }
    return null;
  };

  renderOption = (option) => {
    const {
      multiApproval,
      activeTab,
      selectedMediatorId,
      selectedAlternativeMediatorId,
    } = this.state;
    const isSelected =
      activeTab === 'trainingApprover'
        ? selectedMediatorId === option.value
        : selectedAlternativeMediatorId === option.value;
    const disabled = option.value === SELF_STUDY_ID && multiApproval;
    const disableAfterSelection = option.assignable === false && !isSelected;

    // Mentor option not available when selecting alternative mediator
    if (
      (activeTab !== 'trainingApprover' && option.value === MENTOR_ID) ||
      option.value === NONE_ID
    ) {
      return null;
    }
    return (
      <ListCard
        clickable
        el="label"
        tooltip={option.tooltip}
        htmlFor={option.value}
        disabled={disabled || disableAfterSelection}
        key={option.value}
        rightComponent={
          <RadioButton
            id={option.value}
            disabled={disabled || disableAfterSelection}
            isChecked={isSelected}
            onChange={() => {
              this.onChangeMediator(option.value);
            }}
          />
        }
      >
        <>
          <Text color={option.labelColor}>{option.label}</Text>
          {option.subtitle && (
            <Text size="sm" color="dark-grey">
              {option.subtitle}
            </Text>
          )}
        </>
      </ListCard>
    );
  };

  renderSettingModal = () => {
    const {
      filterString,
      locked,
      requireSignature,
      multiApproval,
      selectedMediatorId,
      activeTab,
      alternativeMediator,
      selectedAlternativeMediatorId,
    } = this.state;
    const { showFilterButton, onClose, subtitle } = this.props;
    const multiApprovalDisabled =
      selectedMediatorId === SELF_STUDY_ID ||
      selectedAlternativeMediatorId === SELF_STUDY_ID;
    return (
      <ModalWrapper>
        <ModalHeader
          title={localeLookup('translations.Responsibility & completion')}
          subtitle={subtitle}
          onClose={onClose}
        />
        <div className="mediator-approval-options">
          <Text
            className="mediator-approval-options__title"
            color="dark-grey"
            size="sm"
          >
            {localeLookup('translations.Completion requirements')}
          </Text>
          <SwitchCheckbox
            onChange={this.onChangeSetting}
            isChecked={locked}
            disabled={multiApproval}
            name="locked"
            icon="lock"
            wrapperClassName="mediator-approval-options__option"
            labelText={localeLookup(
              'translations.Locked: Only responsible can complete the element'
            )}
          />
          <SwitchCheckbox
            onChange={this.onChangeSetting}
            isChecked={multiApproval}
            disabled={multiApprovalDisabled}
            tooltip={
              multiApprovalDisabled
                ? localeLookup(
                    'translations.Can not be used with "person themself" selected'
                  )
                : ''
            }
            name="multiApproval"
            icon="users2"
            wrapperClassName="mediator-approval-options__option"
            labelText={localeLookup(
              'translations.Multipart completion: Both the person themself and the responsible must complete the element'
            )}
          />
          <SwitchCheckbox
            onChange={this.onChangeSetting}
            isChecked={requireSignature}
            name="requireSignature"
            icon="sign"
            wrapperClassName="mediator-approval-options__option"
            labelText={localeLookup(
              'translations.Requires signature: Completion of the element requires signature'
            )}
          />
          <Text
            className="mediator-approval-options__title"
            color="dark-grey"
            size="sm"
          >
            {localeLookup('translations.Responsible')}
          </Text>
          <SwitchCheckbox
            onChange={this.onChangeSetting}
            isChecked={alternativeMediator}
            name="alternativeMediator"
            icon="tab"
            wrapperClassName="mediator-approval-options__option"
            labelText={localeLookup(
              'translations.Responsible depends on role level'
            )}
          />
        </div>
        <ModalFilter
          buttonIcon="plus-circle"
          buttonText={localeLookup('translations.Create person')}
          onButtonClick={this.onClickCreateUser}
          onChange={this.onChangeFilter}
          placeholder={`${localeLookup('translations.Search')}...`}
          showButton={showFilterButton}
          value={filterString}
        />

        {alternativeMediator && (
          <Tabs
            activeTab={activeTab}
            tabWidth="50%"
            activeTabColor="grey"
            rounded={false}
            onChangeTab={this.onChangeApproverTab}
            tabs={[
              {
                name: localeLookup('translations.In training'),
                id: 'trainingApprover',
              },
              {
                name: localeLookup('translations.Qualified/Experienced'),
                id: 'qualifiedApprover',
              },
            ]}
          />
        )}
        <ModalBody deep>{this.getGroups().map(this.renderGroup)}</ModalBody>
        <ModalFooter
          confirmDisabled={!selectedMediatorId || selectedMediatorId === ''}
          onCancelClick={onClose}
          onConfirmClick={this.onClickSubmitMediator}
          confirmButtonText={localeLookup('translations.Save')}
        />
      </ModalWrapper>
    );
  };

  render() {
    const { onClose } = this.props;
    const { isCreatingUser, filterString } = this.state;
    if (isCreatingUser) {
      return (
        <CreateUserModal
          initialName={filterString}
          onCancel={this.onClickCancelCreateUser}
          checkForInformationConclicts
          onClose={onClose}
          onCreated={this.onCreatedUser}
          title={localeLookup('translations.Create person')}
        />
      );
    }
    return this.renderSettingModal();
  }
}

MediatorModal.propTypes = {
  options: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  showFilterButton: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withPersonLookup(MediatorModal));
