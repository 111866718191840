import React from 'react';

// Components
import Icon from './Icon';

const Card = ({ icon, linkPath, title, subtitle, rightComponent = null, subtitle2 }) => (
  <div className="card" to={linkPath}>
    <div className="card__wrapper">
      <Icon className="card__icon-left" kind={icon} />
      <div className="card__text">
        <p className="card__text-title">{title}</p>
        <p className="card__text-subtitle">{subtitle}</p>
        {!!subtitle2 && <p className="card__text-subtitle">{subtitle2}</p>}
      </div>
      {rightComponent && <div className="card__right">{rightComponent}</div>}
    </div>
  </div>
);

export default Card;
