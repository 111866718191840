import React, { PureComponent } from 'react';
import cx from 'classnames';
import localeLookup from '../config/locale';

export default class GanttChartTaskIndicator extends PureComponent {
  onClick = () => {
    const { task, onClick } = this.props;
    onClick(task);
  };
  render() {
    const { isVisible, direction, task } = this.props;
    return (
      <div
        className={cx('gantt-chart__task-indicator', {
          'gantt-chart__task-indicator--visible': isVisible === false,
          [`gantt-chart__task-indicator--direction-${direction}`]: direction,
        })}
      >
        <button
          onClick={this.onClick}
          className="gantt-chart__task-indicator-button"
          type="button"
        >
          <svg
            className="gantt-chart__task-indicator-arrow"
            width="23"
            height="38"
            viewBox="0 0 23 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22.083 34.3848C22.083 37.0575 18.8516 38.396 16.9617 36.5061L1.57694 21.1213C0.405364 19.9497 0.405364 18.0503 1.57694 16.8787L16.9617 1.4939C18.8516 -0.39599 22.083 0.94251 22.083 3.61522V34.3848Z" />
          </svg>

          <div className="gantt-chart__task-indicator-text">
            <div className="gantt-chart__task-indicator-title">{task.role}</div>
            <div className="gantt-chart__task-indicator-subtitle">
              {task.personInitials} · {localeLookup('translations.Mentor')}:{' '}
              {task.mentorInitials}
            </div>
          </div>
        </button>
      </div>
    );
  }
}
