import { getAllOrganisationUnitsService } from '../services/organisationService';

// Actions
const LOAD_ALL = 'organisationUnits/LOAD_ALL';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_ALL:
      return { ...state, ...action.organisationUnits };
    default:
      return state;
  }
};

export const getAllOrganisationUnits = () => (dispatch) =>
  getAllOrganisationUnitsService().then(({ data }) => {
    dispatch({ type: LOAD_ALL, organisationUnits: data });
    return data;
  });

// Selectors
export const selectParentMap = (state) => {
  const { organisationUnits } = state;
  return Object.keys(organisationUnits).reduce((acc, id) => {
    return {
      ...acc,
      [id]: getOrganisationUnitParents(id, organisationUnits),
    };
  }, {});
};

const getOrganisationUnitParents = (unitId, organisationUnits) => {
  let allParents = [];
  const directParent = Object.values(organisationUnits).find((unit) =>
    unit.descendants.includes(unitId)
  );
  if (directParent) {
    allParents = [...allParents, directParent];
    const parentDirectParent = Object.values(organisationUnits).find((unit) =>
      unit.descendants.includes(directParent.id)
    );
    if (parentDirectParent) {
      allParents = [
        ...getOrganisationUnitParents(directParent.id, organisationUnits),
        ...allParents,
      ];
    }
  }
  return allParents;
};
