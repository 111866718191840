import React from 'react';
import Button from '../../components/Button';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS } from '../../constants';

const UserAdministrationPermissionFilter = ({
  filterState,
  isDefault,
  onClickReset,
  onToggleFilter,
}) => {
  const sections = [
    {
      title: localeLookup('translations.Administration roles'),
      items: [
        {
          name: localeLookup('translations.Administrator'),
          selected: filterState[ACCESS_LEVELS.administrator],
          stateKey: ACCESS_LEVELS.administrator,
          tooltip: localeLookup('translations.AdministratorTooltip'),
        },
        {
          name: localeLookup('translations.Content administrator'),
          selected: filterState[ACCESS_LEVELS.contentAdministrator],
          stateKey: ACCESS_LEVELS.contentAdministrator,
          tooltip: localeLookup('translations.ContentAdministratorTooltip'),
        },
        {
          name: localeLookup('translations.Group administrator'),
          selected: filterState[ACCESS_LEVELS.groupAdministrator],
          stateKey: ACCESS_LEVELS.groupAdministrator,
          tooltip: localeLookup('translations.GroupAdministratorTooltip'),
        },
        {
          name: localeLookup('translations.Person administrator'),
          selected: filterState[ACCESS_LEVELS.personAdministrator],
          stateKey: ACCESS_LEVELS.personAdministrator,
          tooltip: localeLookup('translations.PersonAdministratorTooltip'),
        },
        {
          name: localeLookup('translations.User administrator'),
          selected: filterState[ACCESS_LEVELS.userAdministrator],
          stateKey: ACCESS_LEVELS.userAdministrator,
          tooltip: localeLookup('translations.UserAdministratorTooltip'),
        },
        {
          name: localeLookup('translations.Session administrator'),
          selected: filterState[ACCESS_LEVELS.trainingSessionAdministrator],
          stateKey: ACCESS_LEVELS.trainingSessionAdministrator,
          tooltip: localeLookup('translations.SessionAdministratorTooltip'),
        },
      ],
    },
    {
      title: localeLookup('translations.Special permissions'),
      items: [
        {
          name: localeLookup('translations.Create groups'),
          selected: filterState[ACCESS_LEVELS.createGroups],
          stateKey: ACCESS_LEVELS.createGroups,
          tooltip: localeLookup('translations.CreateGroupsPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Create sessions'),
          selected: filterState[ACCESS_LEVELS.createTrainingSessions],
          stateKey: ACCESS_LEVELS.createTrainingSessions,
          tooltip: localeLookup('translations.CreateSessionsPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Create roles'),
          selected: filterState[ACCESS_LEVELS.createRoles],
          stateKey: ACCESS_LEVELS.createRoles,
          tooltip: localeLookup('translations.CreateRolesPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Create modules'),
          selected: filterState[ACCESS_LEVELS.createModules],
          stateKey: ACCESS_LEVELS.createModules,
          tooltip: localeLookup('translations.CreateModulesPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Create persons'),
          selected: filterState[ACCESS_LEVELS.createPersons],
          stateKey: ACCESS_LEVELS.createPersons,
          tooltip: localeLookup('translations.CreatePersonsPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Delete persons'),
          selected: filterState[ACCESS_LEVELS.deletePersons],
          stateKey: ACCESS_LEVELS.deletePersons,
          tooltip: localeLookup('translations.DeletePersonsPermissionTooltip'),
        },
        {
          name: localeLookup('translations.Change permissions'),
          selected: filterState[ACCESS_LEVELS.assignAccessLevels],
          stateKey: ACCESS_LEVELS.assignAccessLevels,
          tooltip: localeLookup(
            'translations.AssignAccessLevelsPermissionTooltip'
          ),
        },
      ],
    },
    {
      title: localeLookup('translations.System'),
      items: [
        {
          name: localeLookup('translations.System administrator'),
          selected: filterState[ACCESS_LEVELS.systemAdministrator],
          stateKey: ACCESS_LEVELS.systemAdministrator,
          tooltip: localeLookup('translations.SystemAdministratorTooltip'),
        },
      ],
    },
  ];
  const renderItem = (item) => (
    <SideNavItem
      small
      element="div"
      key={`setting-${item.name}`}
      disabled={item.disabled}
      showHoverTitle
      title={item.name}
      tooltip={item.tooltip}
      onClick={() => onToggleFilter(item.stateKey)}
      stopRightComponentClickPropagation
      rightComponent={
        <SwitchCheckbox
          disabled={item.disabled}
          onChange={() => onToggleFilter(item.stateKey)}
          isChecked={item.selected}
          id={`setting-${item.stateKey}`}
        />
      }
    />
  );
  const renderSection = (section, index) => {
    if (section.groups) {
      return (
        <React.Fragment key={index}>
          <SideNavTitle size="small" title={section.title} />
          {section.groups.map((group, index) => (
            <React.Fragment key={index}>
              <SideNavTitle subtitle title={group.groupTitle} />
              {group.items.map(renderItem)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <SideNavTitle size="small" title={section.title} />
        {section.items.map(renderItem)}
      </React.Fragment>
    );
  };
  return (
    <>
      <SideNavItem
        small
        element="div"
        key={`setting-no-permissions`}
        showHoverTitle
        title={localeLookup('translations.Persons without permissions')}
        onClick={() => onToggleFilter('NoPermissions')}
        stopRightComponentClickPropagation
        rightComponent={
          <SwitchCheckbox
            onChange={() => onToggleFilter('NoPermissions')}
            isChecked={filterState['NoPermissions']}
            id={`setting-no-permissions`}
          />
        }
      />
      {sections.map(renderSection)}
      <Button
        kind="alert"
        disabled={isDefault}
        onClick={onClickReset}
        className="status-table__sidebar-reset-button"
      >
        {localeLookup('translations.Reset')}
      </Button>
    </>
  );
};

export default UserAdministrationPermissionFilter;
