import { getWildcardPersonsService } from '../services/personsService';

// Actions
const LOAD = 'wildcardPersons/LOAD';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        ...action.wildcardPersons,
      };

    default:
      return state;
  }
};

export const getWildcardPersons = () => (dispatch) =>
  getWildcardPersonsService().then(({ data }) => {
    dispatch({ type: LOAD, wildcardPersons: data });
  });
