import React from 'react';
import localeLookup from '../../config/locale';

const OrganisationBoardLegend = () => {
  return (
    <div className="organisation-board-legend">
      <ul className="organisation-board-legend__list">
        <li className="organisation-board-legend__item">
          <p className="organisation-board-legend__mentorship">MEN</p>
          <span className="organisation-board-legend__text">
            {localeLookup('translations.Training')}
          </span>
        </li>
        <li className="organisation-board-legend__item">
          <div className="organisation-board-legend__point" />
          <span className="organisation-board-legend__text">
            {localeLookup('translations.Qualified')}
          </span>
        </li>
        <li className="organisation-board-legend__item">
          <div className="organisation-board-legend__icon-wrapper">
            <svg
              className="organisation-board-legend__icon"
              height="20"
              width="20"
              viewBox="0 0 1792 1792"
            >
              <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" />
            </svg>
          </div>
          <span className="organisation-board-legend__text">
            {localeLookup('translations.Experienced')}
          </span>
        </li>
        <div className="organisation-board-legend__divider"></div>
        <li className="organisation-board-legend__item">
          <div className="organisation-board-legend__color organisation-board-legend__color--red" />
          <span className="organisation-board-legend__text">
            {localeLookup('translations.Contains open critical elements')}
          </span>
        </li>
        <li className="organisation-board-legend__item">
          <div className="organisation-board-legend__color organisation-board-legend__color--grey" />
          <span className="organisation-board-legend__text">
            {localeLookup('translations.Not relevant in organisation unit')}
            {' / '}
            {localeLookup('translations.Limited visibility')}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default OrganisationBoardLegend;
