import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import { RadioButtonGroup } from '../RadioButtonGroup';
import FormWrapper from '../formElements/FormWrapper';
import GenericModal from './GenericModal';
import { ORGANISATION_UNIT_STATES } from '../../constants';
import Text from '../Text';

export default class ChangeOrganisationUnitStateModal extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
    };
  }
  onSubmit = (values) => {
    const { onConfirm } = this.props;
    this.setState({ isSubmitting: true });
    onConfirm(values);
  };
  render() {
    const { isSubmitting } = this.state;
    const {
      confirmButtonText,
      onCloseClick,
      title,
      subtitle,
      infoText,
      currentState,
      disabled,
      body
    } = this.props;
    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        initialValues={{
          state: currentState,
        }}
      >
        {(props) => {
          const {
            errors,
            handleSubmit,
            values,
            dirty,
            handleChange,
            isValid,
            setFieldValue,
          } = props;
          return (
            <GenericModal
              confirmButtonText={confirmButtonText}
              onCancelClick={onCloseClick}
              onClose={onCloseClick}
              onConfirmClick={handleSubmit}
              title={title}
              message={{ text: infoText }}
              subtitle={subtitle}
              confirmDisabled={!isValid || isSubmitting}
            >
              <form onSubmit={handleSubmit}>
                <RadioButtonGroup
                  groupName="state"
                  disabled={disabled}
                  options={[
                    {
                      value: ORGANISATION_UNIT_STATES.ACTIVE,
                      label: localeLookup('translations.Visible'),
                      isChecked:
                        values.state === ORGANISATION_UNIT_STATES.ACTIVE,
                    },
                    {
                      value: ORGANISATION_UNIT_STATES.PASSIVE,
                      label: localeLookup('translations.Limited visibility'),
                      isChecked:
                        values.state === ORGANISATION_UNIT_STATES.PASSIVE ||
                        values.state ===
                          ORGANISATION_UNIT_STATES.INHERITED_PASSIVE,
                    },
                  ]}
                  label={`${localeLookup('translations.Visibility')}:`}
                  onChange={(e) => {
                    setFieldValue('state', e.target.id);
                  }}
                ></RadioButtonGroup>
              </form>
              {body && <Text size="sm" color="dark-grey" italic>{body}</Text>}
            </GenericModal>
          );
        }}
      </FormWrapper>
    );
  }
}
