import React from 'react';
import Badge from './Badge';
import Text from './Text';
import localeLookup from '../config/locale';
import cx from 'classnames';

const ErrorState = ({
  title = localeLookup('translations.An error occurred'),
  body = localeLookup('translations.Please try again or contact Champ if the issue persists'),
  fullHeight,
}) => {
  const modifierClasses = {
    'error-state--full-height': fullHeight,
  };
  return (
    <div className={cx('error-state', modifierClasses)}>
      <Badge color="red" size="large" icon="cross" />
      <Text size="lg" className="error-state__title" size="lg" color="dark-grey">
        {title}
      </Text>
      {body && (
        <Text className="error-state__body" color="dark-grey">
          {body}
        </Text>
      )}
    </div>
  );
};

export default ErrorState;
