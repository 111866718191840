
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import FileHandler from '../FileHandler';
import { linkifyString } from '../../utils/helpers';

// Components
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalWrapper from './ModalWrapper';
import ModalFooter from './ModalFooter';

import ButtonIcon from '../ButtonIcon';
import Icon from '../Icon';
import localeLookup from '../../config/locale';
import EmptyState from '../EmptyState';

class RichTextModal extends Component {
  componentDidMount() {
    const links = this.richTextBody?.querySelectorAll('a');
    if (links) {
      links.forEach((link) => {
        link.target = '_blank';
      });
    }
  }

  render() {
    const { onClose, title, body, files, subtitle } = this.props;
    const descriptionWithoutMarkupLength = body.replace(/<[^>]*>?/gm, '').trim().length;
    const bodyWithClickableLinks =
      descriptionWithoutMarkupLength !== 0 ? linkifyString(body) : null;
    const file = files && files.length > 0 && files[0];
    const isProcessingFile = file && file.state === 'Preparing';
    const showEmptyState = (!files || files.length === 0) && descriptionWithoutMarkupLength === 0;
    return (
      <ModalWrapper className="rich-text-modal">
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        {showEmptyState && <EmptyState title={localeLookup('translations.No content')} />}
        <ModalBody>
          {!isProcessingFile && !!file && <FileHandler file={file} canDelete={false} />}
          {isProcessingFile && (
            <div className="file-upload-box file-upload-box--has-file">
              <div className="file-upload-box__file">
                <div className="file-upload-box__file-icon-wrapper">
                  <Icon className="file-upload-box__file-icon" kind="camera" />
                </div>
                <div className="file-upload-box__file-info">
                  <p className="file-upload-box__file-name">
                    <span>{localeLookup('translations.Video is being prepared')}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {bodyWithClickableLinks && bodyWithClickableLinks.length > 0 && (
            <div
              ref={(el) => (this.richTextBody = el)}
              className="rich-text-modal__body"
              dangerouslySetInnerHTML={{ __html: bodyWithClickableLinks }}
            />
          )}
        </ModalBody>
        <ModalFooter onCancelClick={onClose} cancelButtonText={localeLookup("translations.Close")} cancelButtonKind="darkui" />
      </ModalWrapper>
    );
  }
}

export default RichTextModal;
