import { getUserInfo } from "../services/authService";
import { fetchMenuItems } from "../services/menuService";
import { userInfoReceived } from "./menuActions";

export const updateUserInfo = () => (dispatch) =>
  Promise.all([fetchMenuItems(), getUserInfo()])
    .then(([response, userInfoResponse]) => {
      const { menuName } = response.data;
      const { tenants, primary } = userInfoResponse.data;
      dispatch(
        userInfoReceived({
          id: menuName.id,
          initials: menuName.initials,
          name: menuName.name,
          firstName: menuName.firstName,
          lastName: menuName.lastName,
          email: menuName.email,
          locale: menuName.locale,
          organisation: menuName.organisation,
          champLinkPrefix: menuName.champLinkPrefix,
          created: menuName.created,
          isManagingCustomer: menuName.isManagingCustomer,
          tenantId: primary,
          tenants,
        })
      );
    })
    .catch((ex) => {
      throw new Error(ex);
    });
