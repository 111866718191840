import React from 'react';
import Tooltip from './Tooltip';
import cx from 'classnames';
const HorizontalSection = ({
  title,
  items,
  renderItem,
  FooterComponent,
  EmptyComponent,
  titleTooltip,
  renderLeft,
  renderRight,
  leftWidth = 'md',
  subtitle,
  subtitleTooltip,
  showSeparator,
}) => {
  const modifierClasses = {
    [`horizontal-section--left-width-${leftWidth}`]: leftWidth,
    [`horizontal-section--show-separator`]: showSeparator,
  };
  return (
    <section className={cx('horizontal-section', modifierClasses)}>
      <div className="horizontal-section__left">
        {renderLeft !== undefined ? (
          renderLeft()
        ) : (
          <>
            <p className="horizontal-section__title">
              <Tooltip tooltip={titleTooltip}>
                <span>{title}</span>
              </Tooltip>
            </p>
            <p className="horizontal-section__subtitle">
              <Tooltip tooltip={subtitleTooltip}>
                <span>{subtitle}</span>
              </Tooltip>
            </p>
          </>
        )}
      </div>
      <div className="horizontal-section__right">
        {renderRight ? (
          renderRight()
        ) : items?.length > 0 ? (
          <>
            {items.map((item) => renderItem(item))}
            {FooterComponent && FooterComponent}
          </>
        ) : (
          EmptyComponent
        )}
      </div>
    </section>
  );
};

export default HorizontalSection;
