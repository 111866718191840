import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class SplitInput extends PureComponent {
  constructor(props) {
    super(props);
    this.initialValues = props.defaultValue
      ? [
          ...props.defaultValue.split(''),
          ...Array(props.numberOfCharacters - props.defaultValue.length).fill(''),
        ]
      : [...Array(props.numberOfCharacters)].fill('');

    this.state = {
      values: this.initialValues,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    const { onChange } = this.props;
    onChange(values.join(''));
  }

  clearValues = () => {
    const { defaultValue } = this.props;
    this.setState(
      {
        values: this.initialValues,
      },
      () => {
        this[`input${defaultValue.length}`].focus();
      }
    );
  };

  onChangeValue = (value, index) => {
    if (!/^[a-zA-Z0-9]*$/.test(value)) return;
    this.setState(
      (prevState) => {
        return {
          values: prevState.values.map((oldValue, i) => {
            if (index === i) {
              return value.toUpperCase();
            }
            return oldValue;
          }),
        };
      },
      () => {
        this.handleFocus(value, index);
      }
    );
  };

  onKeyDown = (e, index) => {
    const { onEnterPress } = this.props;
    if (e.target.value === '' && e.key === 'Backspace' && this[`input${index - 1}`]) {
      this[`input${index - 1}`].focus();
    }
    if (e.key === 'Enter') onEnterPress();
  };

  handleFocus = (value, index) => {
    if (value !== '' && this[`input${index + 1}`]) {
      this[`input${index + 1}`].focus();
    }
  };

  render() {
    const { defaultValue, disablePrefilled, className } = this.props;
    const { values } = this.state;
    return (
      <div className={cx('split-input', className)}>
        {values.map((value, index) => (
          <>
            <input
              autoFocus={index === defaultValue.length}
              className="split-input__input"
              value={value}
              key={index}
              type="text"
              disabled={disablePrefilled && index < defaultValue.length}
              ref={(el) => (this[`input${index}`] = el)}
              maxLength={1}
              onKeyDown={(e) => this.onKeyDown(e, index)}
              onChange={(e) => this.onChangeValue(e.target.value, index)}
            />
            {/* TODO: Find way to define divider via props */}
            {index === 2 && <span className="split-input__divider">-</span>}
          </>
        ))}
      </div>
    );
  }
}

SplitInput.propTypes = {};

export default SplitInput;
