import React, { PureComponent } from 'react';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

class FormModal extends PureComponent {
  render() {
    const { children, title, subtitle, onClose, steps, activeStepIndex } = this.props;
    return (
      <ModalWrapper>
        <ModalHeader
          title={title}
          subtitle={subtitle}
          steps={steps}
          activeStepIndex={activeStepIndex}
          onClose={onClose}
        />
        {children}
      </ModalWrapper>
    );
  }
}

export default FormModal;
