import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import { EMPTY_ID } from '../../constants';
import {
  updateRoleAreaDashboardLinkState,
  updateRoleAreaElementDashboardSignees,
} from '../../services/dashboardService';
import { getWildcardPersonsService } from '../../services/personsService';
import { getKnowledgeElementService } from '../../services/traineeService';
import { updateUserSettingService } from '../../services/userProfileService';
import { compareLocal, sortBy } from '../../utils/helpers';
import WithModals from '../HOC/withModals';
import withPersonLookup from '../HOC/withPersonLookup';
import Loader from '../Loader';
import MainArea from '../MainArea';
import DashboardFilter from './DashboardFilter';
import RoleDashboardContent from './RoleDashboardContent';
import RoleDashboardSidebar from './RoleDashboardSidebar';

const mapStateToProps = (state) => ({
  allPersons: state.persons,
});

class RoleDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      filterString: '',
      knowledgeAreas: {},
      role: {},
      persons: {},
      knowledgeElements: {},
      expandedAreaId: '',
      mediatorOption: '',
      wildcardPersons: {},
      showResponsibles: false,
      fullScreen: false,
      personFilterOption: props.defaultFilterOption || 'persons',
      visibleOrganisationUnitIds: props.visibleOrganisationUnitIds,
      personView: 'initials',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { getData } = this.props;
    Promise.all([getData(), getWildcardPersonsService()]).then(
      ([{ data }, { data: wildcardPersons }]) => {
        this.setState({
          knowledgeAreas: data.knowledgeAreas,
          role: data.role,
          persons: data.persons,
          knowledgeElements: data.knowledgeElements,
          isLoading: false,
          showResponsibles: data.showResponsible,
          personView: data.showEmployeeNumber ? 'employeeNumber' : 'initials',
          canSelectElements: false,
          selectedElements: {},
          wildcardPersons,
        });
      }
    );
  };

  getFilterOptions = () => {
    const { hideAllPersonsFilterOption } = this.props;
    const { role } = this.state;
    let options = [];
    if (role.yourMentees) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.Your mentorships')} (${
            role.yourMentees.length
          })`,
          value: 'yourMentees',
        },
      ];
    }

    if (role.mentees) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.All mentorships')} (${
            role.mentees.length
          })`,
          value: 'mentees',
        },
      ];
    }

    if (role.persons && !hideAllPersonsFilterOption) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.All with this role')} (${
            role.persons.length
          })`,
          value: 'persons',
        },
      ];
    }
    return options;
  };

  getVisiblePersonIds = () => {
    const { lookupPerson } = this.props;
    const {
      filterString,
      personFilterOption,
      persons,
      role,
      visibleOrganisationUnitIds,
      personView,
      showResponsibles,
    } = this.state;
    const checkPersonMatch = (id) => {
      const person = persons[id];
      const mentor = lookupPerson(person.mentor);
      const searchString = mentor
        ? `${person?.name}${person?.initials}${person?.employeeNumber}${mentor?.name}${mentor?.initials}${mentor?.employeeNumber}`
        : `${person?.name}${person?.initials}${person?.employeeNumber}`;
      if (visibleOrganisationUnitIds.length > 0) {
        return (
          visibleOrganisationUnitIds.some((unitId) =>
            person.organisationUnits.includes(unitId)
          ) && searchString.toLowerCase().includes(filterString.toLowerCase())
        );
      }
      return searchString.toLowerCase().includes(filterString.toLowerCase());
    };
    const getIdentifier = (person) => {
      if (showResponsibles) return person.name;
      const identifier =
        personView === 'initials'
          ? person.initials
          : person.employeeNumber
          ? person.employeeNumber
          : person.initials;
      return identifier;
    };
    const sortFunction = (a, b) => {
      const personA = persons[a];
      const personB = persons[b];
      const personAIdentifier = getIdentifier(personA);
      const personBIdentifier = getIdentifier(personB);
      return compareLocal(personAIdentifier, personBIdentifier);
    };

    if (personFilterOption === 'yourMentees') {
      return sortBy(role.yourMentees.filter(checkPersonMatch), [sortFunction]);
    }
    if (personFilterOption === 'mentees') {
      return sortBy(role.mentees.filter(checkPersonMatch), [sortFunction]);
    }
    if (personFilterOption === 'persons') {
      return sortBy(role.persons.filter(checkPersonMatch), [sortFunction]);
    }
  };

  onChangeElementStatus = ({ elementId, personId }) => {
    const { persons } = this.state;
    return getKnowledgeElementService(personId, elementId).then(({ data }) => {
      this.setState({
        persons: {
          ...persons,
          [personId]: {
            ...persons[personId],
            knowledgeElements: {
              ...persons[personId].knowledgeElements,
              [elementId]: data.knowledgeElements[elementId],
            },
          },
        },
      });
    });
  };

  onChangeFilterOption = (e) => {
    this.setState({ personFilterOption: e.target.value });
  };

  onChangeFilterString = (e) => {
    this.setState({ filterString: e.target.value });
  };

  onChangeMediatorOption = (e) => {
    this.setState({ mediatorOption: e.target.value });
  };

  onChangePersonViewTab = (tab) => {
    updateUserSettingService({
      name: 'DashboardEmployeeNumberView',
      enabled: tab.id === 'employeeNumber',
    });
    this.setState({ personView: tab.id });
  };

  onClickAdditionalArea = ({ roleId, isLinked, personId, areaId }) => {
    const { persons } = this.state;
    updateRoleAreaDashboardLinkState(roleId, {
      linkToEmployee: !isLinked,
      knowledgeAreaId: areaId,
      employeeId: personId,
    }).then(({ data }) => {
      this.setState({
        persons: {
          ...persons,
          [personId]: {
            ...persons[personId],
            knowledgeAreas: {
              ...persons[personId].knowledgeAreas,
              [areaId]: data.persons[personId].knowledgeAreas[areaId],
            },
            knowledgeElements: {
              ...persons[personId].knowledgeElements,
              ...data.persons[personId].knowledgeElements,
            },
          },
        },
      });
    });
  };

  onClickExpandArea = (id) => {
    const { expandedAreaId } = this.state;
    if (expandedAreaId === id) {
      this.setState({
        expandedAreaId: '',
        canSelectElements: false,
        selectedElements: {},
      });
    } else {
      this.setState({ expandedAreaId: id });
    }
  };

  onClickSelectElement = ({ elementId, personId }) => {
    const { selectedElements } = this.state;
    const selectedElementObject = selectedElements[elementId];
    // If element is not selected, add the element with the person
    if (!selectedElementObject) {
      this.setState({
        selectedElements: {
          ...selectedElements,
          [elementId]: { persons: [personId] },
        },
      });
    } else {
      const isPersonSelected =
        selectedElementObject.persons?.includes(personId);
      // If person is not selected, but element is, add the person
      if (!isPersonSelected) {
        this.setState({
          selectedElements: {
            ...selectedElements,
            [elementId]: {
              persons: [...selectedElementObject.persons, personId],
            },
          },
        });
      }
      // If person and element and the element is already selected, remove the person
      else {
        const newElementPersonSelections = selectedElementObject.persons.filter(
          (id) => id !== personId
        );
        // If element selections is empty after removal, remove element selection
        if (newElementPersonSelections.length === 0) {
          const { [elementId]: value, ...withoutElementId } = selectedElements;
          this.setState({
            selectedElements: withoutElementId,
          });
        }
        // If element selections is not empty after removal, remove person selection
        else {
          this.setState({
            selectedElements: {
              ...selectedElements,
              [elementId]: {
                ...selectedElementObject,
                persons: newElementPersonSelections,
              },
            },
          });
        }
      }
    }
  };

  onClickShowElementDescription = (element) => {
    const { showModal } = this.props;
    showModal('richText', {
      title: element.name,
      description: element.description,
      files: element.files,
      maxWidth: '700px',
      fullWidth: true,
    });
  };

  onClickSubmitChangeMediators = () => {
    const { role, selectedElements, expandedAreaId, mediatorOption, persons } =
      this.state;
    updateRoleAreaElementDashboardSignees(role.id, expandedAreaId, {
      signeeId: mediatorOption === 'none' ? EMPTY_ID : mediatorOption,
      knowledgeElements: selectedElements,
    }).then(({ data }) => {
      this.setState({
        selectedElements: {},
        mediatorOption: '',
        persons: Object.keys(persons).reduce((acc, id) => {
          if (data.persons[id]) {
            return { ...acc, [id]: { ...persons[id], ...data.persons[id] } };
          }
          return {
            ...acc,
            [id]: persons[id],
          };
        }, {}),
      });
    });
  };

  onClickSubmitChangeSingleMediator = ({ elementId, personId, mediatorId }) => {
    const { role, expandedAreaId, persons } = this.state;
    updateRoleAreaElementDashboardSignees(role.id, expandedAreaId, {
      signeeId: mediatorId === 0 ? EMPTY_ID : mediatorId,
      knowledgeElements: { [elementId]: { persons: [personId] } },
    }).then(({ data }) => {
      this.setState({
        persons: Object.keys(persons).reduce((acc, id) => {
          if (data.persons[id]) {
            return { ...acc, [id]: { ...persons[id], ...data.persons[id] } };
          }
          return {
            ...acc,
            [id]: persons[id],
          };
        }, {}),
      });
    });
  };

  onClickToggleChangeMediators = () => {
    const { canSelectElements, selectedElements } = this.state;
    this.setState({
      canSelectElements: !canSelectElements,
      selectedElements: canSelectElements ? {} : selectedElements,
    });
  };

  onClickToggleFullScreen = () => {
    const { fullScreen } = this.state;
    this.setState({
      fullScreen: !fullScreen,
    });
  };

  onClickToggleShowResponsibles = () => {
    const { showResponsibles } = this.state;
    updateUserSettingService({
      name: 'DashboardShowResponsible',
      enabled: !showResponsibles,
    });
    this.setState({
      showResponsibles: !showResponsibles,
      canSelectElements: false,
      selectedElements: {},
    });
  };

  render() {
    const { hideFilterSelect, allPersons, dashboardType } = this.props;
    const {
      isLoading,
      role,
      knowledgeAreas,
      knowledgeElements,
      expandedAreaId,
      persons,
      filterString,
      personFilterOption,
      canSelectElements,
      mediatorOption,
      selectedElements,
      wildcardPersons,
      showResponsibles,
      fullScreen,
      personView,
    } = this.state;
    const visiblePersonIds = !isLoading && this.getVisiblePersonIds();
    const canSubmitChangeMediator =
      mediatorOption !== '' && Object.keys(selectedElements).length !== 0;
    const modifierClasses = {
      'dashboard--state-compact': !showResponsibles,
    };
    return (
      <MainArea backgroundColor="grey" fullScreen={fullScreen}>
        <div className={cx('dashboard', modifierClasses)}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DashboardFilter
                allPersons={allPersons}
                filterOptions={this.getFilterOptions()}
                filterString={filterString}
                mediatorOption={mediatorOption}
                onChangeFilterOption={this.onChangeFilterOption}
                onChangeFilterString={this.onChangeFilterString}
                onChangeMediatorOption={this.onChangeMediatorOption}
                onClickSubmitChangeMediators={this.onClickSubmitChangeMediators}
                onClickToggleChangeMediators={this.onClickToggleChangeMediators}
                personFilterOption={personFilterOption}
                showChangeMediatorButton={expandedAreaId !== ''}
                showMediatorOptions={canSelectElements}
                canSubmitChangeMediator={canSubmitChangeMediator}
                onClickToggleShowResponsibles={
                  this.onClickToggleShowResponsibles
                }
                showResponsibles={showResponsibles}
                onClickToggleFullScreen={this.onClickToggleFullScreen}
                fullScreen={fullScreen}
                hideFilterSelect={dashboardType === 'trainingRole'}
                personView={personView}
                onChangePersonViewTab={this.onChangePersonViewTab}
              />
              <div className="dashboard__main">
                <RoleDashboardSidebar
                  additionalKnowledgeAreasSortOrder={
                    role.relatedKnowledgeAreasSortOrder
                  }
                  expandedAreaId={expandedAreaId}
                  knowledgeAreas={knowledgeAreas}
                  knowledgeAreasSortOrder={role.knowledgeAreasSortOrder}
                  knowledgeElements={knowledgeElements}
                  onClickExpandArea={this.onClickExpandArea}
                  roleKnowledgeAreas={role.knowledgeAreas}
                  roleName={role.name}
                />
                <RoleDashboardContent
                  additionalKnowledgeAreasSortOrder={
                    role.relatedKnowledgeAreasSortOrder
                  }
                  allPersons={allPersons}
                  expandedAreaId={expandedAreaId}
                  knowledgeAreas={role.knowledgeAreas}
                  knowledgeAreasSortOrder={role.knowledgeAreasSortOrder}
                  knowledgeElements={knowledgeElements}
                  onClickAdditionalArea={this.onClickAdditionalArea}
                  persons={persons}
                  roleId={role.id}
                  visiblePersonIds={visiblePersonIds}
                  wildcardPersons={wildcardPersons}
                  onChangeElementStatus={this.onChangeElementStatus}
                  canSelectElements={canSelectElements}
                  onClickSelectElement={this.onClickSelectElement}
                  selectedElements={selectedElements}
                  showResponsibles={showResponsibles}
                  onClickSubmitChangeSingleMediator={
                    this.onClickSubmitChangeSingleMediator
                  }
                  personView={personView}
                />
              </div>
              {/* <DashboardLegend dashboardType={expandedAreaId !== '' ? 'knowledgeArea' : 'role'} /> */}
            </>
          )}
        </div>
      </MainArea>
    );
  }
}

RoleDashboard.defaultProps = {
  hideFilterSelect: false,
};

RoleDashboard.propTypes = {
  showModal: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  hideFilterSelect: PropTypes.bool,
};

export default connect(mapStateToProps)(
  WithModals(withPersonLookup(RoleDashboard))
);
