import React from 'react';
import localeLookup from '../../config/locale';
import ButtonIcon from '../ButtonIcon';
import Input from '../formElements/Input';
import Select from '../Select';
import SwitchCheckbox from '../SwitchCheckbox';
import Tabs from '../Tabs';

const DashboardFilter = ({
  filterOptions,
  filterString,
  fullScreen,
  hideFilterSelect,
  onChangeFilterOption,
  onChangeFilterString,
  onChangePersonViewTab,
  onClickToggleFullScreen,
  onClickToggleShowResponsibles,
  personFilterOption,
  personView,
  showResponsibles,
}) => (
  <div className="dashboard-filter">
    <div className="dashboard-filter__inner">
      <div className="dashboard-filter__left">
        {!hideFilterSelect && (
          <Select
            value={personFilterOption}
            size="small"
            wrapperClassName="dashboard-filter__left-select"
            onChange={onChangeFilterOption}
            options={filterOptions}
          />
        )}
        <Input
          placeholder={localeLookup('translations.Search for person')}
          size="small"
          className="dashboard-filter__left-input"
          onChange={onChangeFilterString}
          value={filterString}
        />
        <SwitchCheckbox
          onChange={onClickToggleShowResponsibles}
          isChecked={showResponsibles}
          wrapperClassName="dashboard-filter__left-switch"
          labelText={localeLookup('translations.Detailed view')}
        />
      </div>
      <div className="dashboard-filter__right">
        {!showResponsibles && (
          <Tabs
            tabWidth="200px"
            onChangeTab={onChangePersonViewTab}
            activeTab={personView}
            round
            showBackground
            showBorder
            tabs={[
              { name: localeLookup('translations.Initials'), id: 'initials' },
              {
                name: localeLookup('translations.Employee number'),
                id: 'employeeNumber',
              },
            ]}
          />
        )}
        <ButtonIcon
          onClick={onClickToggleFullScreen}
          tooltip={localeLookup('translations.Show full screen')}
          className="dashboard-filter__right-full-screen-button"
          rounded
          color="green"
          size="md"
          icon={fullScreen ? 'contract' : 'expand'}
        />
      </div>
    </div>
  </div>
);

DashboardFilter.propTypes = {};

export default DashboardFilter;
