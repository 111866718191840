// Constants
import { CREATED_PERSON_DATA_RECEIVED, PERSONS_LISTS_RECEIVED, PERSONS_OVERVIEW_DATA_PENDING, PERSONS_OVERVIEW_RECIEVED, PERSONS_SORT_ORDERS_RECEIVED, PERSON_DETAILS_RECEIVED, PERSON_INFO_RECEIVED, SET_CHANGE_PASSWORD_ERROR, UPDATED_PERSON_DATA_RECEIVED } from '../actions/personEditorActions';

const personsInitialState = {};

const personEditor = (state = personsInitialState, action) => {
  switch (action.type) {
    case CREATED_PERSON_DATA_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.data.updatedPerson.id]: {
            ...action.data.updatedPerson,
          },
        },
        personsSortOrder: action.data.personsSortOrder,
        deactivatedPersonsSortOrder: action.data.deactivatedPersonsSortOrder,
      };
    case PERSON_DETAILS_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.data.id]: {
            ...state.persons[action.data.id],
            ...action.data,
          },
        },
      };
    case PERSON_INFO_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.data.id]: {
            ...state.persons[action.data.id],
            ...action.data,
          },
        },
      };
    case PERSONS_SORT_ORDERS_RECEIVED:
      return {
        ...state,
        personsSortOrder: action.data.personsSortOrder,
        deactivatedPersonsSortOrder: action.data.deactivatedPersonsSortOrder,
      };
    case PERSONS_LISTS_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.data.updatedPerson.id]: {
            ...action.data.updatedPerson,
          },
        },
        personsSortOrder: action.data.personsSortOrder,
        deactivatedPersonsSortOrder: action.data.deactivatedPersonsSortOrder,
      };
    case PERSONS_OVERVIEW_DATA_PENDING:
      return {
        ...state,
        isFetchingOverview: true,
      };
    case PERSONS_OVERVIEW_RECIEVED:
      return {
        ...state,
        ...action.data,
        isFetchingOverview: false,
      };
    case SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordErrorMessage: action.errMessage,
      };
    case UPDATED_PERSON_DATA_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.data.updatedPerson.id]: {
            ...state.persons[action.data.updatedPerson.id],
            ...action.data.updatedPerson,
          },
        },
      };
    default:
      return state;
  }
};

export default personEditor;
