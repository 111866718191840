/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { PureComponent } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS, ORGANISATION_UNIT_STATES } from '../../constants';
import { getRoleDashbordService } from '../../services/dashboardService';
import { queryElements } from '../../slices/elementsSlice';
import { queryRoles } from '../../slices/rolesSlice';
import { compareLocal, sortBy } from '../../utils/helpers';
import ContextMenu from '../contextMenu/ContextMenu';
import ContextMenuItem from '../contextMenu/ContextMenuItem';
import RoleDashboard from '../dashboard/RoleDashboard';
import withAccessControl from '../HOC/withAccessControl';
import WithEditorActions from '../HOC/withEditorActions';
import Icon from '../Icon';
import IconWithCount from '../IconWithCount';
import InlineFieldEditor from '../InlineFieldEditor';
import OrganisationUnitsText from '../OrganisationUnitsText';
import Overlay from '../Overlay';
import Portal from '../Portal';
import DesignboardKnowledgeArea from './DesignboardKnowledgeArea';

const mapStateToProps = (state) => {
  const { user, spaces } = state;
  return {
    currentUserId: user.employeeId,
    spacesEnabled: spaces.enabled,
    activeSpaceId: spaces.activeSpaceId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      queryRoles,
      queryElements,
    },
    dispatch
  ),
});

class DesignboardRole extends PureComponent {
  constructor() {
    super();
    this.state = {
      isEditingName: false,
      showDashboardOverlay: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { role, onClickHideRole, activeSpaceId } = this.props;

    // Role hides itself if moved out of space
    if (prevProps.role.space !== role.space && role.space !== activeSpaceId) {
      onClickHideRole(role.id);
    }
  }

  updateRole = () => {
    const { role, queryRoles } = this.props;
    queryRoles([role.id]);
  };

  onChangeElementConnections = ({ elementIds = [], connect = true }) => {
    const { role, editorActions } = this.props;
    if (connect) {
      return editorActions.connectElementsToRoles({
        roleIds: [role.id],
        elementIds,
      });
    }
    return editorActions.disconnectElementsFromRoles({
      roleIds: [role.id],
      elementIds,
    });
  };

  onBlurEditName = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onClickAddArea = (additionalArea) => {
    const { role, onClickAddArea } = this.props;
    onClickAddArea(role.id, additionalArea);
  };

  onClickEditName = () => {
    this.setState({
      isEditingName: true,
    });
  };

  onClickDelete = () => {
    const { role, onClickDeleteRole } = this.props;
    onClickDeleteRole(role.id);
  };

  onClickEditDescription = () => {
    const { role, onClickEditRoleDescription } = this.props;
    onClickEditRoleDescription(role.id);
  };

  onClickEditOrganisationUnits = () => {
    const { role, onClickEditRoleOrganisationUnits } = this.props;
    onClickEditRoleOrganisationUnits(role.id);
  };

  onClickEditOwner = () => {
    const { role, onClickEditRoleOwner } = this.props;
    onClickEditRoleOwner({ roleId: role.id });
  };

  onClickEditSpace = () => {
    const { role, editorActions, onClickHideRole } = this.props;
    editorActions.showChangeRoleSpaceModal({
      roleId: role.id,
      onChanged: () => onClickHideRole(role.id),
    });
  };

  onClickChangeRoleEditors = () => {
    const { role, onClickChangeRoleEditors } = this.props;
    onClickChangeRoleEditors({ roleId: role.id });
  };

  onClickHide = () => {
    const { role, onClickHideRole } = this.props;
    onClickHideRole(role.id);
  };

  onClickToggleShowDashboardOverlay = () => {
    const { showDashboardOverlay } = this.state;
    this.setState({ showDashboardOverlay: !showDashboardOverlay });
  };

  onSubmitRename = (value) => {
    const { role, editorActions } = this.props;
    editorActions.changeRoleName(role.id, value);
  };

  renderHeader = () => {
    const {
      organisationUnits,
      persons,
      role,
      currentUserId,
      hasAccess,
      spaces,
      spacesEnabled,
      hasChangeRoleOwnerPermission,
    } = this.props;
    const { isEditingName } = this.state;

    const getEditorsSubtitle = () => {
      if (!role.editors || role.editors?.length === 0)
        return localeLookup('translations.No editors');
      if (role.editors?.length === 1) return persons[role.editors[0]]?.name;
      return localeLookup('translations.{0} editors', [role.editors?.length]);
    };

    const connectedPersonsTooltipText = () => {
      const numEmployeesConnected = role.employeesConnected?.length;
      if (!role.employeesConnected || numEmployeesConnected === 0) return '';
      if (numEmployeesConnected > 10) {
        return sortBy(role.employeesConnected, [
          (a, b) => compareLocal(persons[a]?.name, persons[b]?.name),
        ])
          .slice(0, 10)
          .reduceRight(
            (acc, id) => [persons[id].name, ...acc],
            [
              `${localeLookup('translations.and')} ${
                numEmployeesConnected - 10
              } ${localeLookup('translations.more')}...`,
            ]
          )
          .map((name) => (
            <>
              {name}
              <br />
            </>
          ));
      }
      return role.employeesConnected.map((id) => (
        <>
          {persons[id]?.name}
          <br />
        </>
      ));
    };

    const descriptionLength =
      role.description &&
      role.description.replace(/<(?:.|\n)*?>/gm, '').trim().length;

    const canEditOwner = hasChangeRoleOwnerPermission({
      currentOwnerId: role.owner,
      roleSpaceId: role.space,
    });

    const isAdminInSpace =
      spaces[role.space].administrators.includes(currentUserId);
    const showContentSpaces = spacesEnabled
      ? hasAccess([
          ACCESS_LEVELS.champadministrator,
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.contentAdministrator,
        ]) || isAdminInSpace
      : false;

    return (
      <div className="designboard-role__header">
        <Icon
          kind="profile"
          className="designboard-role__header-icon"
          size="xlarge"
        />
        <div className="designboard-role__header-text">
          {isEditingName ? (
            <InlineFieldEditor
              autoFocus
              classNameInput="designboard-role__header-title-input"
              defaultValue={role.name}
              onBlur={this.onBlurEditName}
              onSubmit={this.onSubmitRename}
              placeholder={localeLookup('translations.Name of role')}
            />
          ) : (
            <div
              className="designboard-role__header-title"
              onClick={this.onClickEditName}
            >
              <p
                title={role.name}
                className="designboard-role__header-title-text"
              >
                {role.name}
              </p>
              <Icon
                kind="pencil"
                className="designboard-role__header-title-icon"
                size="small"
              />
            </div>
          )}
          <p className="designboard-role__header-unit">
            <OrganisationUnitsText
              organisationUnitIds={role.organisationUnits}
              /* organisationUnits={role.organisationUnits.map(
                (id) => organisationUnits[id].name
              )} */
            />
          </p>
        </div>
        <IconWithCount
          color="grey"
          count={role.organisationUnits.length}
          kind="site-map"
          onClick={this.onClickEditOrganisationUnits}
          className="designboard-role__header-icon-button"
          tooltip={
            role.organisationUnits.length > 0
              ? role.organisationUnits.map((id) => {
                  const organisationUnit = organisationUnits[id];
                  if (!organisationUnit) return null;
                  const isVisibilityLimited =
                    organisationUnit.state ===
                      ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
                    organisationUnit.state === ORGANISATION_UNIT_STATES.PASSIVE;
                  return (
                    <>
                      {organisationUnit.name}
                      {isVisibilityLimited ? (
                        <>
                          <span>
                            {' '}
                            ({localeLookup('translations.Limited visibility')})
                          </span>
                        </>
                      ) : null}
                      <br />
                    </>
                  );
                })
              : ''
          }
        />
        <IconWithCount
          color="grey"
          count={role.employeesConnected?.length}
          kind="users"
          onClick={this.onClickToggleShowDashboardOverlay}
          className="designboard-role__header-icon-button"
          tooltip={connectedPersonsTooltipText()}
        />

        <ContextMenu triggerClass="designboard-role__header-context-menu-icon">
          <ContextMenuItem
            disabled={!canEditOwner}
            onClick={this.onClickEditOwner}
            leftIconKind="key"
            subtitleText={
              persons[role.owner]
                ? persons[role.owner].name
                : localeLookup('translations.No owner')
            }
            titleText={localeLookup('translations.Owner')}
          />
          <ContextMenuItem
            onClick={this.onClickChangeRoleEditors}
            leftIconKind="clipboard-pencil"
            subtitleText={getEditorsSubtitle()}
            tooltip={
              role.editors?.length > 1 &&
              role.editors.map((id) => <p key={id}>{persons[id]?.name}</p>)
            }
            titleText={localeLookup('translations.Editors')}
          />
          <ContextMenuItem
            onClick={this.onClickEditOrganisationUnits}
            leftIconKind="site-map"
            titleText={localeLookup('translations.Organisation units')}
            subtitleText={
              <OrganisationUnitsText
                organisationUnitIds={role.organisationUnits}
              />
            }
          />
          {showContentSpaces && (
            <ContextMenuItem
              onClick={this.onClickEditSpace}
              leftIconKind="folder"
              titleText={localeLookup('translations.Content space')}
              subtitleText={spaces[role.space]?.name}
            />
          )}
          <ContextMenuItem
            onClick={this.onClickEditDescription}
            leftIconKind="document"
            titleText={localeLookup('translations.Role description')}
            subtitleText={
              descriptionLength > 0 && role.description
                ? role.description.replace(/(<([^>]+)>)/gi, '')
                : localeLookup('translations.No role description')
            }
          />
          <ContextMenuItem
            onClick={this.onClickToggleShowDashboardOverlay}
            leftIconKind="users"
            titleText={localeLookup('translations.Show status')}
          />
          <ContextMenuItem
            onClick={this.onClickHide}
            leftIconKind="eye-crossed"
            titleText={localeLookup('translations.Hide')}
          />
          <ContextMenuItem
            onClick={this.onClickDelete}
            leftIconClassName="designboard-role__header-delete-icon"
            leftIconKind="trash2"
            titleText={localeLookup('translations.Delete')}
          />
        </ContextMenu>
      </div>
    );
  };

  renderArea = ({ areaId, isAdditional, index }) => {
    const {
      allowChangeOfChampLinkVisibility,
      knowledgeAreas,
      knowledgeElements,
      onClickAddExpert,
      onClickChangeCategory,
      onClickDeleteArea,
      onClickDeleteElement,
      onClickEditAreaOwner,
      onClickEditElementDescription,
      onClickEditElementResponsible,
      onClickMoveElement,
      onClickToggleAreaLinkVisiblity,
      onClickToggleElementLinkVisiblity,
      persons,
      role,
      wildcardPersons,
      onClickChangeAreaEditors,
      hasAccessToArea,
      hasReadOnlyAccessToArea,
    } = this.props;
    const hasAccess = hasAccessToArea(areaId);
    const hasReadOnlyAccess = hasReadOnlyAccessToArea(areaId);
    return (
      <Draggable
        draggableId={`${isAdditional ? 'ADDITIONALAREA' : 'AREA'}_${
          role.id
        }_${areaId}`}
        index={index}
        key={`${role.id}${areaId}`}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <DesignboardKnowledgeArea
              allowChangeOfChampLinkVisibility={
                allowChangeOfChampLinkVisibility
              }
              area={knowledgeAreas[areaId]}
              onClickDeleteElement={onClickDeleteElement}
              onClickDeleteArea={onClickDeleteArea}
              id={areaId}
              isAreaReadOnly={hasAccess ? hasReadOnlyAccess : true}
              index={index}
              key={areaId}
              knowledgeElements={knowledgeElements}
              onClickAddExpert={onClickAddExpert}
              onClickChangeCategory={onClickChangeCategory}
              onClickChangeEditors={onClickChangeAreaEditors}
              onChangeElementConnections={this.onChangeElementConnections}
              onClickEditAreaOwner={onClickEditAreaOwner}
              onClickEditElementResponsible={onClickEditElementResponsible}
              onClickEditElementDescription={onClickEditElementDescription}
              onClickMoveElement={onClickMoveElement}
              onClickToggleAreaLinkVisiblity={onClickToggleAreaLinkVisiblity}
              onClickToggleElementLinkVisiblity={
                onClickToggleElementLinkVisiblity
              }
              persons={persons}
              roleElementIds={role.elements}
              roleId={role.id}
              wildcardPersons={wildcardPersons}
            />
          </div>
        )}
      </Draggable>
    );
  };

  renderAdditionalKnowledgeAreas = () => {
    const { knowledgeAreas, role } = this.props;
    if (role.additionalAreas && role.additionalAreas.length > 0) {
      return (
        <div className="designboard-role__knowledge-areas designboard-role__knowledge-areas--additional">
          <h2 className="designboard-role__knowledge-areas-title">
            {localeLookup('translations.Additional knowledge')}
          </h2>
          <Droppable
            droppableId={`ADDITIONALAREA_${role.id}`}
            type={`ADDITIONALAREA_${role.id}`}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {role.additionalAreas.map((areaId, i) => {
                  if (knowledgeAreas[areaId]) {
                    return this.renderArea({
                      areaId,
                      isAdditional: true,
                      index: i,
                    });
                  }
                  return null;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      );
    }
    return null;
  };

  renderKnowledgeAreas = () => {
    const { knowledgeAreas, role } = this.props;
    if (role.areas && role.areas.length > 0) {
      return (
        <Droppable droppableId={role.id} type={role.id}>
          {(provided) => (
            <div
              className="designboard-role__knowledge-areas"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {role.areas.map((areaId, i) => {
                if (knowledgeAreas[areaId]) {
                  return this.renderArea({
                    areaId,
                    isAdditional: false,
                    index: i,
                  });
                }
                return null;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }
    return null;
  };

  renderRoleAddBox = (additionalArea = false) => {
    const onClick = () => this.onClickAddArea(additionalArea);
    return (
      <div
        className={cx('designboard-role__add-box', {
          'designboard-role__add-box--additional': additionalArea,
        })}
        onClick={onClick}
      >
        <div className="designboard-role__add-box-inner">
          <Icon kind="plus-circle" className="designboard-role__add-box-icon" />
          <p className="designboard-role__add-box-text">
            {additionalArea
              ? `${localeLookup('translations.Add additional knowledge')}...`
              : `${localeLookup('translations.Add mandatory knowledge')}...`}
          </p>
        </div>
      </div>
    );
  };

  renderDashboardOverlay = () => {
    const { role } = this.props;
    const { showDashboardOverlay } = this.state;
    if (!showDashboardOverlay) return null;
    return (
      <Overlay
        isOpen={showDashboardOverlay}
        onClose={this.onClickToggleShowDashboardOverlay}
        title={<p>{role.name}</p>}
      >
        <RoleDashboard
          hideFilterSelect
          getData={() => getRoleDashbordService(role.id)}
          visibleOrganisationUnitIds={[]}
        />
      </Overlay>
    );
  };

  render() {
    const { zIndex } = this.props;
    return (
      <div className="designboard-role" style={{ zIndex }}>
        {this.renderHeader()}
        {this.renderKnowledgeAreas()}
        {this.renderRoleAddBox()}
        {this.renderAdditionalKnowledgeAreas()}
        {this.renderRoleAddBox(true)}
        <Portal id="overlay-root">{this.renderDashboardOverlay()}</Portal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAccessControl(WithEditorActions(DesignboardRole)));
