import Notifications from 'react-notification-system-redux';

export const errorNotification = (notificationOpts) => (dispatch) => {
  const notificationsOptsTranslated = {
    ...notificationOpts,
    title: notificationOpts.title,
    message: notificationOpts.message,
  };
  dispatch(Notifications.error(notificationsOptsTranslated));
};

export const successNotification = (notificationOpts) => (dispatch) => {
  const notificationsOptsTranslated = {
    ...notificationOpts,
    title: notificationOpts.title,
    message: notificationOpts.message,
  };
  dispatch(Notifications.success(notificationsOptsTranslated));
};
