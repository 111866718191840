import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EditorHeader } from '../../components/editor/EditorHeader';
import EditorAreas from '../../components/EditorAreas';
import EditorAreasNav from '../../components/EditorAreasNav';
import WithEditorActions from '../../components/HOC/withEditorActions';
import WithModals from '../../components/HOC/withModals';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import localeLookup from '../../config/locale';
import { EMPTY_ID } from '../../constants';
import {
  getAllAreas,
  queryAreas,
  selectAreasSortOrder,
} from '../../slices/areasSlice';
import {
  getAllCategories,
  selectCategoriesSortOrder,
} from '../../slices/categoriesSlice';
import { getAreasAccess } from '../../slices/editorSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import {
  getActiveSpace,
  getAllSpaces,
  getSpaceStatus,
  setActiveSpace,
} from '../../slices/spaceSlice';
import { getQueryStringParams } from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';

const mapStateToProps = (state) => {
  const { categories, areas, persons, organisationUnits, editor, spaces } =
    state;
  return {
    categories,
    areas,
    persons,
    organisationUnits,
    categoriesSortOrder: selectCategoriesSortOrder(state),
    areasSortOrder: selectAreasSortOrder(state),
    accessibleAreas: editor.accessibleAreas,
    spaces: spaces.spaces,
    activeSpaceId: spaces.activeSpaceId,
    spacesEnabled: spaces.enabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getSpaceStatus,
      getActiveSpace,
      getAllAreas,
      getAllCategories,
      getAllOrganisationUnits,
      queryAreas,
      getAreasAccess,
      setActiveSpace,
      getAllSpaces,
    },
    dispatch
  ),
});

class PageEditorKnowledge extends Component {
  constructor() {
    super();
    this.state = {
      activeSpaceId: '',
      isLoading: true,
      filterString: '',
      filter: {
        type: null,
        id: null,
        name: null,
        searchString: '',
      },
    };
  }

  componentDidMount() {
    const {
      location,
      getAllAreas,
      getAllOrganisationUnits,
      getAllCategories,
      getAreasAccess,
      getAllSpaces,
      getActiveSpace,
      getSpaceStatus,
    } = this.props;
    trackEvent('$pageview');
    Promise.all([
      getAllAreas(),
      getAllOrganisationUnits(),
      getAllCategories(),
      getAreasAccess(),
      getActiveSpace(),
      getAllSpaces(),
      getSpaceStatus(),
    ])
      .then(
        ([
          ,
          organisationUnitsResponse,
          categoriesResponse,
          ,
          activeSpaceId,
        ]) => {
          const queryParams = getQueryStringParams(location.search);
          this.setState({ activeSpaceId });
          if (queryParams.category) {
            this.setState({
              filter: {
                type: 'CATEGORY',
                id: queryParams.category,
                name:
                  queryParams.category === EMPTY_ID
                    ? localeLookup('translations.No category')
                    : categoriesResponse[queryParams.category],
              },
              filterString: queryParams.term || '',
            });
          } else if (queryParams.unit) {
            this.setState({
              filter: {
                type: 'UNIT',
                id: queryParams.unit,
                name:
                  queryParams.unit === EMPTY_ID
                    ? localeLookup('translations.Not connected')
                    : organisationUnitsResponse[queryParams.unit].name,
              },
              filterString: queryParams.term || '',
            });
          } else if (queryParams.term) {
            this.setState({
              filterString: queryParams.term || '',
            });
          }
          this.setState({ isLoading: false });
        }
      )
      .catch(() => this.setState({ isLoading: false }));
  }

  getVisibleAreaIds = () => {
    const { areas, areasSortOrder, persons, accessibleAreas, activeSpaceId } =
      this.props;
    const { filter, filterString } = this.state;

    return areasSortOrder.filter((key) => {
      if (!accessibleAreas.includes(key)) return false;
      const area = areas[key];
      const areaSpaceIds = [
        ...Object.keys(area.readOnlyInSpaces),
        ...Object.keys(area.editableInSpaces),
      ];
      if (!areaSpaceIds.includes(activeSpaceId)) return false;
      const ownerName = persons[area.knowledgeOwner]?.name || '';
      const doesAreaNameMatchFilterString = area.name
        .toLowerCase()
        .includes(filterString.toLowerCase());
      const doesOwnerNameMatchFilterString = ownerName
        .toLowerCase()
        .includes(filterString.toLowerCase());
      const doesAnyAreaUnitsMatchSelectedUnit =
        area.organisationUnits?.indexOf(filter.id) !== -1 ||
        (filter.id === EMPTY_ID && area.organisationUnits?.length === 0);

      const doesAreaCategoryMatchSelectedCategory =
        area.category === filter.id ||
        (filter.id === null && area.category === EMPTY_ID);

      if (filter.type === 'UNIT') {
        return (
          doesAnyAreaUnitsMatchSelectedUnit &&
          (doesAreaNameMatchFilterString || doesOwnerNameMatchFilterString)
        );
      }
      if (filter.type === 'CATEGORY' && area.category === filter.id) {
        return (
          doesAreaCategoryMatchSelectedCategory &&
          (doesAreaNameMatchFilterString || doesOwnerNameMatchFilterString)
        );
      }
      if (filter.type === null) {
        return doesAreaNameMatchFilterString || doesOwnerNameMatchFilterString;
      }
    });
  };

  onChangeSpace = async (space) => {
    const { setActiveSpace } = this.props;
    this.setState({ isChangingSpace: true, activeSpaceId: space.id });
    //await this.getDataForSpace(space.id);
    await setActiveSpace(space.id);
    this.setState({ isChangingSpace: false });
  };

  onFilterClick = (type, id, name) => {
    this.setState(
      {
        filter: {
          type,
          id,
          name,
        },
      },
      this.setSearchParams
    );
  };

  onFilterReset = () => {
    this.setState(
      {
        filter: {
          type: null,
          id: null,
          name: null,
        },
        filterString: '',
      },
      this.setSearchParams
    );
  };

  onFilterStringChange = (e) => {
    this.setState(
      {
        filterString: e.target.value,
      },
      this.setSearchParams
    );
  };

  setSearchParams = () => {
    const { filterString, filter } = this.state;
    const { history } = this.props;
    const serialize = (obj) =>
      Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    history.push({
      search: serialize({
        term: filterString,
        [filter.type === 'UNIT' ? 'unit' : 'category']: filter.id,
      }),
    });
  };

  render() {
    const {
      categories,
      categoriesSortOrder,
      areas,
      organisationUnits,
      persons,
      editorActions,
      accessibleAreas,
      activeSpaceId,
      spaces,
      spacesEnabled,
    } = this.props;

    const { filter, isLoading, filterString } = this.state;
    const areaCount = accessibleAreas.filter((id) => {
      const area = areas[id];
      if (!area) return false;
      const areaSpaceIds = [
        ...Object.keys(area.editableInSpaces),
        ...Object.keys(area.readOnlyInSpaces),
      ];
      return areaSpaceIds.includes(activeSpaceId);
    }).length;
    if (isLoading) return <LoadScreen />;

    return (
      <>
        {spacesEnabled && (
          <EditorHeader
            spacesEnabled={spacesEnabled}
            activeSpaceId={activeSpaceId}
            onChangeSpace={this.onChangeSpace}
            spaces={spaces}
          ></EditorHeader>
        )}
        <Page>
          <EditorAreasNav
            activeSpaceId={activeSpaceId}
            areas={areas}
            categories={categories}
            categoriesSortOrder={categoriesSortOrder}
            filter={filter}
            onFilterClick={this.onFilterClick}
            onFilterReset={this.onFilterReset}
            organisationUnits={organisationUnits}
            areaCount={areaCount}
            areaIdsWithAccess={accessibleAreas}
          />
          <MainArea forceVerticalScrollbar backgroundColor="grey">
            <EditorAreas
              activeSpaceId={activeSpaceId}
              filterString={filterString}
              onFilterStringChange={this.onFilterStringChange}
              areas={areas}
              persons={persons}
              filter={filter}
              onCreateAreaClick={editorActions.showCreateAreaModal}
              visibleAreas={this.getVisibleAreaIds()}
            />
          </MainArea>
        </Page>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(WithEditorActions(PageEditorKnowledge)));
