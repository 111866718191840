import React, { PureComponent } from 'react';
import Tooltip from '../Tooltip';
export default class OrganisationBoardPerson extends PureComponent {
  onClick = (e) => {
    const { toggleContextMenu, personId } = this.props;
    toggleContextMenu({ e, personId });
  };

  onMouseEnter = () => {
    const { onMouseEnter, personId } = this.props;
    onMouseEnter({ personId, type: 'person' });
  };
  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    onMouseLeave();
  };

  render() {
    const { name, initials, employeeNumber, view, width } = this.props;
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        className="organisation-board-person"
        style={{ width: width }}
      >
        <div className="organisation-board-person__name">
          <div>
            <abbr className="organisation-board-person__name-initials">
              {view === 'initials' ? initials : employeeNumber || initials}
            </abbr>
          </div>
        </div>
      </div>
    );
  }
}
