import {
  getAllRolesService,
  queryRolesService,
} from '../services/contentService';
import { compareLocal, sortBy } from '../utils/helpers';

// Actions
const LOAD_ALL = 'roles/LOAD_ALL';
const LOAD_ROLES = 'roles/LOAD_ROLES';
const UPDATE_ROLE_AREAS_ORDER = 'roles/UPDATE_ROLE_AREAS_ORDER';
export const REMOVE_ROLE = 'roles/REMOVE_ROLE';

export default (state = {}, action) => {
  switch (action.type) {
    case REMOVE_ROLE:
      const { [action.id]: value, ...withoutRole } = state;
      return withoutRole;
    case LOAD_ALL:
      return {
        ...state,
        ...action.roles,
      };
    case LOAD_ROLES:
      return { ...state, ...action.roles };
    case UPDATE_ROLE_AREAS_ORDER:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          areas: action.areasOrder,
          additionalAreas: action.additionalAreasOrder,
        },
      };
    default:
      return state;
  }
};

// Action creators

export const getAllRoles = () => (dispatch) =>
  getAllRolesService().then(({ data }) => {
    dispatch({ type: LOAD_ALL, roles: data });
  });

export const queryRoles = (roleIds) => (dispatch) =>
  queryRolesService(roleIds).then(({ data }) => {
    dispatch({ type: LOAD_ROLES, roles: data });
    return data;
  });

export const removeRole = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ROLE, id });
};

export const updateRoleAreasOrder =
  ({ id, areasOrder, additionalAreasOrder }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_ROLE_AREAS_ORDER,
      id,
      areasOrder,
      additionalAreasOrder,
    });
  };

// Selectors
export const selectRolesSortOrder = (state) =>
  sortBy(Object.keys(state.roles), [
    (a, b) => compareLocal(state.roles[a].name, state.roles[b].name),
  ]);
