/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ACCESS_LEVELS } from '../../constants';

const mapStateToProps = (state) => ({
  accessLevels: state.permissions.accessLevels,
  accessibleRoles: state.editor.accessibleRoles,
  accessibleAreas: state.editor.accessibleAreas,
  readOnlyAreas: state.editor.readOnlyAreas,
  spaces: state.spaces.spaces,
  currentUserId: state.user.employeeId,
});

const WithAccessControl = (WrappedComponent) => {
  function WithAccessControlComponent(props) {
    const hasAccessToRole = (id) => {
      const { accessibleRoles } = props;
      return accessibleRoles.includes(id);
    };

    const hasAccessToArea = (id) => {
      const { accessibleAreas } = props;
      return accessibleAreas.includes(id);
    };

    const hasChangeAreaOwnerPermission = ({
      currentOwnerId,
      editableInSpaceIds,
    }) => {
      const { currentUserId, spaces } = props;
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const isContentAdminInAnySpacesWhereAreaIsEditable =
        editableInSpaceIds.some((id) =>
          spaces[id].administrators.includes(currentUserId)
        );
      // If the person is owner of the area, then the person can change owner
      if (currentUserId === currentOwnerId) return true;
      // Or if the person is admin
      if (isAdmin) return true;
      // Or if the person is contentadministrator on any of the content spaces, where the area is editable
      if (isContentAdminInAnySpacesWhereAreaIsEditable) return true;

      return false;
    };

    const hasChangeRoleOwnerPermission = ({ currentOwnerId, roleSpaceId }) => {
      const { currentUserId, spaces } = props;
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const isAdminInRoleSpace =
        spaces[roleSpaceId].administrators.includes(currentUserId);
      // If the person is owner of the area, then the person can change owner
      if (currentUserId === currentOwnerId) return true;
      // Or if the person is admin
      if (isAdmin) return true;
      // Or if the person is contentadministrator on any of the content spaces, where the area is editable
      if (isAdminInRoleSpace) return true;

      return false;
    };

    const hasCreateAreaPermission = ({ spaceId }) => {
      const { spaces, currentUserId } = props;
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      if (isAdmin) return true;
      if (spaceId) {
        return (
          spaces?.[spaceId]?.canCreateModules?.includes(currentUserId) ||
          spaces?.[spaceId]?.administrators?.includes(currentUserId)
        );
      }
      return Object.values(spaces).some(
        (space) =>
          space.canCreateModules.includes(currentUserId) ||
          space.administrators.includes(currentUserId)
      );
    };

    const hasCreateRolePermission = ({ spaceId }) => {
      const { spaces, currentUserId } = props;
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      if (isAdmin) return true;
      if (spaceId) {
        return (
          spaces[spaceId].canCreateRoles.includes(currentUserId) ||
          spaces[spaceId].administrators.includes(currentUserId)
        );
      }
      return Object.values(spaces).some(
        (space) =>
          space.canCreateRoles.includes(currentUserId) ||
          space.administrators.includes(currentUserId)
      );
    };

    const hasReadOnlyAccessToArea = (id) => {
      const { readOnlyAreas } = props;
      return readOnlyAreas.includes(id);
    };

    const hasAccess = (acceptedLevels) => {
      const { accessLevels } = props;
      return acceptedLevels.some((level) => accessLevels.includes(level));
    };
    return (
      <WrappedComponent
        hasAccess={hasAccess}
        hasAccessToRole={hasAccessToRole}
        hasAccessToArea={hasAccessToArea}
        hasReadOnlyAccessToArea={hasReadOnlyAccessToArea}
        hasCreateAreaPermission={hasCreateAreaPermission}
        hasCreateRolePermission={hasCreateRolePermission}
        hasChangeAreaOwnerPermission={hasChangeAreaOwnerPermission}
        hasChangeRoleOwnerPermission={hasChangeRoleOwnerPermission}
        {...props}
      />
    );
  }
  return WithAccessControlComponent;
};

const withAccessControl = compose(
  connect(mapStateToProps, null),
  WithAccessControl
);

export default withAccessControl;
