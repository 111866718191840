import React, { Component } from 'react';
import Measure from 'react-measure';
import Pad from 'signature_pad';
import localeLookup from '../config/locale';
import Button from './Button';
import DateTime from './DateTime';
import Text from './Text';
import cx from 'classnames';

export default class SignaturePad extends Component {
  constructor() {
    super();
    this.state = {
      dimensions: {
        width: -1,
        height: -1,
      },
    };
    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.signaturePad = new Pad(this.canvas.current, {
      onEnd: this.onEnd,
    });
  }

  onClickResetCanvas = () => {
    const { onEndSignature } = this.props;
    onEndSignature('');
    this.signaturePad.clear();
  };

  onEnd = () => {
    const { onEndSignature } = this.props;
    const data = this.signaturePad.toDataURL();
    onEndSignature(data);
  };

  render() {
    const { signee, padding, margin } = this.props;
    const { dimensions } = this.state;
    const modifierClasses = {
      [`signature-pad--padding-${padding}`]: padding,
      [`signature-pad--margin-${margin}`]: margin,
    };
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className={cx('signature-pad', modifierClasses)}
          >
            <canvas
              ref={this.canvas}
              height="250px"
              width={dimensions.width}
              className="signature-pad__canvas"
            ></canvas>
            <div className="signature-pad__text">
              <Text bold>
                {signee}, {<DateTime date={Date.now()}></DateTime>}
              </Text>
            </div>
            <Button
              onClick={this.onClickResetCanvas}
              className="signature-pad__reset-button"
              icon="refresh"
              kind="invisible"
            >
              {localeLookup('translations.Reset')}
            </Button>
          </div>
        )}
      </Measure>
    );
  }
}
