/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import localeLookup from '../config/locale';
import Button from './Button';
import CardLink from './CardLink';
import Input from './formElements/Input';
import withAccessControl from './HOC/withAccessControl';

class EditorAreas extends Component {
  renderAreaCard = (id) => {
    const { areas, persons, hasAccessToArea, hasReadOnlyAccessToArea } =
      this.props;
    const area = areas[id];
    const hasAreaAccess = hasAccessToArea(area.id);
    const hasReadOnlyAccess = hasReadOnlyAccessToArea(area.id);
    const isAreaReadOnly = hasAreaAccess ? hasReadOnlyAccess : true;
    return (
      <CardLink
        leftIconColor={isAreaReadOnly ? 'light-grey' : 'green'}
        titleColor={isAreaReadOnly ? 'dark-grey' : 'blue'}
        icon="book2"
        key={id}
        linkPath={`/editor/knowledge/${id}`}
        subtitle={persons[areas[id].owner] && persons[areas[id].owner].name}
        title={areas[id].name}
        stateProps={{
          backTitle: localeLookup('translations.Knowledge areas'),
        }}
      />
    );
  };
  render() {
    const {
      areas,
      filter,
      onCreateAreaClick,
      visibleAreas,
      onFilterStringChange,
      filterString,
      hasCreateAreaPermission,
      activeSpaceId,
    } = this.props;

    const showCreateButton = hasCreateAreaPermission({
      spaceId: activeSpaceId,
    });

    return (
      <div className="editor-areas">
        <div className="editor-areas__header">
          <h1 className="editor-areas__header-title">
            {filter.name === null
              ? localeLookup('translations.All knowledge areas')
              : filter.name}
            {filterString.length > 0 && (
              <span>
                {' - '}
                {localeLookup('translations.Search')}: {filterString}
              </span>
            )}
          </h1>
          <Input
            onChange={onFilterStringChange}
            autoFocus
            value={filterString}
            placeholder={`${localeLookup('translations.Search for area')}...`}
          />
          {showCreateButton && (
            <Button
              className="editor-areas__header-btn"
              onClick={onCreateAreaClick}
              icon="plus-circle"
              kind="darkui"
            >
              {localeLookup('translations.Create knowledge area')}
            </Button>
          )}
        </div>
        <div className="editor-areas__grid">
          {areas && visibleAreas.map(this.renderAreaCard)}
        </div>
      </div>
    );
  }
}

export default withAccessControl(EditorAreas);
