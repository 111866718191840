import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS, ORGANISATION_UNIT_STATES } from '../../constants';
import {
  deleteGroupService,
  updateGroupAdministratorsService,
  updateGroupMembersService,
  updateGroupNameService,
} from '../../services/groupService';
import { deleteOrganisationUnitService } from '../../services/organisationService';
import withAccessControl from './withAccessControl';
import WithModals from './withModals';
import { getGroups, removeGroup } from '../../slices/groupsSlice';
import {
  addPersonPermissionsService,
  getPersonsWithAccessLevelsService,
  removePersonPermissionsService,
} from '../../services/personsService';

const mapStateToProps = (state, ownProps) => {
  const { groups, persons } = state;
  return { groups, persons, wrappedComponentProps: ownProps };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ getGroups, removeGroup }, dispatch),
});

const WithGroupActions = (WrappedComponent) => {
  class WithGroupActionsComponent extends React.Component {
    getGroups = () => {
      const { getGroups } = this.props;
      return getGroups();
    };
    showChangeGroupAdministratorsModal = async ({
      selectedValues,
      newlyCreatedUserIds = [],
      groupId,
      onChanged,
    }) => {
      const {
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        groups,
      } = this.props;
      const group = groups[groupId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const selectedOptionIds =
        selectedValues ||
        activePersonsSortOrder.filter((id) => {
          const person = persons[id];
          const personWithAccessLevels = personsWithAccessLevels[person.id];
          const isPersonAdmin = [
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.groupAdministrator,
          ].some((accessLevel) =>
            personWithAccessLevels?.accessLevels.includes(accessLevel)
          );
          return group.administrators?.includes(id) || isPersonAdmin;
        });
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.groupAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            } else if (group.administrators?.includes(id) || isPersonAdmin) {
              acc[1].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
                disabled: isPersonAdmin,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Group administrators'),
        subtitle: group.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: async (ids) => {
          hideModal();
          await updateGroupAdministratorsService([groupId], ids, 'group');
          this.getGroups().then(() => {
            onChanged?.();
          });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeGroupAdministratorsModal({
                groupId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
                onChanged,
              });
            },
          });
        },
      });
    };

    showChangeGroupNameModal = ({ groupId, name, onChanged }) => {
      const { showModal, hideModal, getGroups } = this.props;
      showModal('nameModal', {
        title: localeLookup('translations.Rename'),
        defaultValue: name,
        confirmButtonText: localeLookup('translations.Rename'),
        subtitle: name,
        onConfirm: ({ name }) => {
          hideModal();
          updateGroupNameService({ id: groupId, name }).then(() => {
            onChanged ? onChanged() : getGroups();
          });
        },
      });
    };

    showChangeGroupMembersModal = ({
      groupId,
      selectedValues,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        groups,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
      } = this.props;
      const group = groups[groupId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const selectedOptionIds = selectedValues || group.members;

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else if (group.members?.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Persons'),
        subtitle: group.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: (ids) => {
          hideModal();
          updateGroupMembersService({ id: groupId, members: ids }).then(() => {
            onChanged?.(ids);
          });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeGroupMembersModal({
                groupId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
                onChanged,
              });
            },
          });
        },
      });
    };

    showChangePersonGroupAdministratorModal = async ({
      personId,
      onChanged,
    }) => {
      const { groups, hideModal, showModal, persons } = this.props;
      const person = persons[personId];
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const selectedOptionIds = Object.values(groups).reduce((acc, group) => {
        if (group.administrators.includes(personId)) {
          return [...acc, group.id];
        }
        return acc;
      }, []);
      const options = Object.values(groups).reduce((acc, group) => {
        return [
          ...acc,
          {
            title: group.name,
            id: group.id,
          },
        ];
      }, []);

      showModal('groupAdministrator', {
        title: localeLookup('translations.Group administrator'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        personAccessLevels: personWithAccessLevels?.accessLevels,
        options,
        selectedOptionIds,
        onConfirm: async ({
          selectedOptionIds: groupIds,
          overAllGroupAdministrator,
        }) => {
          hideModal();
          const isPersonGroupAdministrator =
            personWithAccessLevels?.accessLevels.includes(
              ACCESS_LEVELS.groupAdministrator
            );
          if (isPersonGroupAdministrator !== overAllGroupAdministrator) {
            if (overAllGroupAdministrator) {
              addPersonPermissionsService(personId, [
                ACCESS_LEVELS.groupAdministrator,
              ]);
            } else {
              removePersonPermissionsService(personId, [
                ACCESS_LEVELS.groupAdministrator,
              ]);
            }
          }
          await updateGroupAdministratorsService(
            groupIds,
            [personId],
            'person'
          );
          this.getGroups();
          onChanged?.();
        },
      });
    };

    showCreateGroupModal = ({ onCreated }) => {
      const { showModal, hideModal, getGroups } = this.props;
      showModal('createGroup', {
        fullWidth: true,
        maxWidth: '500px',
        onCreated: ({ id }) => {
          getGroups().then(() => {
            onCreated?.(id);
            hideModal();
          });
        },
      });
    };

    showConfirmGroupDeleteModal = ({ id, onDeleted }) => {
      const { groups, showModal, getGroups, removeGroup } = this.props;
      const group = groups[id];
      showModal('confirmation', {
        title: localeLookup('translations.Delete group'),
        subtitle: group.name,
        maxWidth: '500px',
        safeWord: localeLookup('translations.Delete'),
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
        body: (
          <>
            <p>
              {localeLookup('translations.Deletion of this group is permanent')}
            </p>
            <br />
            <p>
              {localeLookup(
                'translations.In the following contexts, the group is changed to "Deleted group"'
              )}
              :
            </p>
            <ul>
              <li>
                {localeLookup(
                  "translations.Mentoring responsibility for people's training programs"
                )}
              </li>
            </ul>
          </>
        ),
        onConfirm: () => {
          deleteGroupService(id).then(() => {
            removeGroup(id);
            onDeleted?.();
          });
        },
      });
    };

    render() {
      return (
        <WrappedComponent
          groupActions={{
            showChangeGroupNameModal: this.showChangeGroupNameModal,
            showChangeGroupMembersModal: this.showChangeGroupMembersModal,
            showCreateGroupModal: this.showCreateGroupModal,
            showConfirmGroupDeleteModal: this.showConfirmGroupDeleteModal,
            showChangeGroupAdministratorsModal:
              this.showChangeGroupAdministratorsModal,
            showChangePersonGroupAdministratorModal:
              this.showChangePersonGroupAdministratorModal,
          }}
          {...this.props.wrappedComponentProps}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModals(withAccessControl(WithGroupActionsComponent)));
};

export default WithGroupActions;
