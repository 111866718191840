import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SplitInput from './SplitInput';
import Icon from './Icon';
import Button from './Button';
import localeLookup from '../config/locale';
import { insertStringInString } from '../utils/helpers';

class ChampLinkForm extends PureComponent {
  constructor() {
    super();
    this.inputRef = React.createRef();
    this.state = {
      value: '',
      isLoading: false,
    };
  }

  canSubmit = () => {
    const { value } = this.state;
    return value.length === 6;
  };

  onChange = (value) => {
    this.setState({
      value,
    });
  };

  onPreviousLinkPress = (link) => {
    this.setState(
      {
        value: link,
      },
      () => {
        this.onSubmit();
      }
    );
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { value } = this.state;
    if (!this.canSubmit()) return;
    this.inputRef.current.clearValues();
    this.setState({ isLoading: true });
    return onSubmit(value).then(() => this.setState({ isLoading: false }));
  };

  render() {
    const { defaultValue, previousLinks } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="champ-link-form">
        <Icon kind="champ-link" className="champ-link-form__icon" />
        <SplitInput
          ref={this.inputRef}
          className="champ-link-form__input"
          numberOfCharacters={6}
          defaultValue={defaultValue}
          disablePrefilled
          onChange={this.onChange}
          onEnterPress={this.onSubmit}
        />
        <Button
          onClick={this.onSubmit}
          disabled={!this.canSubmit()}
          className="champ-link-form__button"
          kind="darkui"
          fullWidth
          isLoading={isLoading}
        >
          {localeLookup('translations.Open')}
        </Button>
        {/* previousLinks.map(({ link, title }) => (
          <Button
            onClick={() => this.onPreviousLinkPress(link)}
            className="champ-link-form__link"
            kind="link-style"
          >
            {title} ({insertStringInString(link, '-', 3)})
          </Button>
        )) */}
      </div>
    );
  }
}

ChampLinkForm.propTypes = {};

export default ChampLinkForm;
