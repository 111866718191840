/* eslint-disable no-case-declarations */
import { getAllCategoriesService } from '../services/contentService';
import { compareLocal, sortBy } from '../utils/helpers';

// Actions
const LOAD_ALL = 'categories/LOAD_ALL';
const REMOVE_CATEGORY = 'categories/REMOVE_CATEGORY';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_ALL:
      return { ...state, ...action.categories };
    case REMOVE_CATEGORY:
      const { [action.id]: value, ...withoutCategory } = state;
      return withoutCategory;
    default:
      return state;
  }
};

export const getAllCategories = () => (dispatch) =>
  getAllCategoriesService().then(({ data }) => {
    dispatch({ type: LOAD_ALL, categories: data });
    return data;
  });

export const removeCategory = (id) => (dispatch) => {
  dispatch({ type: REMOVE_CATEGORY, id });
};

// Selectors
export const selectCategoriesSortOrder = (state) =>
  sortBy(Object.keys(state.categories), [
    (a, b) => compareLocal(state.categories[a], state.categories[b]),
  ]);
