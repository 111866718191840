import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  accessLevels: state.permissions.accessLevels,
});

class AccessLevelWrapper extends PureComponent {
  hasAccess = () => {
    const { acceptedLevels, accessLevels } = this.props;
    if (!acceptedLevels) return true;
    return acceptedLevels.some((level) => accessLevels.includes(level));
  };

  render() {
    const { children } = this.props;
    const hasAccess = this.hasAccess();
    if (hasAccess) {
      return children;
    }
    return null;
  }
}

export default connect(mapStateToProps)(AccessLevelWrapper);
