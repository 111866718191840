import React from 'react';
import Icon from './Icon';
import Text from './Text';
import cx from 'classnames';

export const Legend = ({
  items,
  children,
  horizontalPadding,
  noPadding = false,
}) => {
  const className = cx('legend', {
    'legend--horizontal-padding': horizontalPadding,
    'legend--no-padding': noPadding,
  });
  return (
    <div className={className}>
      {items.map((item, i) => {
        return (
          <div key={i} className="legend__item">
            {item.icon && (
              <Icon kind={item.icon} size="small" color={item.iconColor}></Icon>
            )}
            <Text
              as="span"
              size="sm"
              className="legend__item-text"
              color={item.textColor}
            >
              {item.text}
            </Text>
          </div>
        );
      })}
      {children}
    </div>
  );
};
