import { isNothing } from '../utils/fn';

// Services
import {
  fetchTrainingAndOwnerships,
  fetchTrainingAndOwnershipsArea,
  fetchTrainingAndOwnershipsRole,
  updateRoleAreaStatus,
  updateRoleAreaElementStatus,
  updateRoleAreaElementSignees,
  updateAreaElementStatus,
  updateAreaElementSignees,
  updateRoleAreaLinkState,
} from '../services/trainingAndOwnershipsService';

export const SET_SELECTED_SIDEBAR_ELEMENT = 'SET_SELECTED_SIDEBAR_ELEMENT';
export const setSelectedSidebarElement = (id, elementType) => ({
  type: SET_SELECTED_SIDEBAR_ELEMENT,
  id,
  elementType,
});

export const TRAINING_AND_OWNERSHIPS_RECIEVED = 'TRAINING_AND_OWNERSHIPS_RECIEVED';
export const trainingAndOwnershipsRecieved = (data) => ({
  type: TRAINING_AND_OWNERSHIPS_RECIEVED,
  data,
});

export const FETCHING_TRAINING_AND_OWNERSHIPS = 'FETCHING_TRAINING_AND_OWNERSHIPS';
export const fetchingTrainingAndOwnerships = () => ({
  type: FETCHING_TRAINING_AND_OWNERSHIPS,
});

export const requestTrainingAndOwnerships = () => (dispatch) => {
  dispatch(fetchingTrainingAndOwnerships());
  dispatch(setSelectedSidebarElement(null, null));
  const fetch = fetchTrainingAndOwnerships();
  fetch
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(trainingAndOwnershipsRecieved(data.data));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });
};

export const TRAINING_ROLE_DATA_RECIEVED = 'TRAINING_ROLE_DATA_RECIEVED';
export const trainingRoleDataRecieved = (id, data) => ({
  type: TRAINING_ROLE_DATA_RECIEVED,
  id,
  data,
});

export const SET_TRAINING_ROLE_LOADER = 'SET_TRAINING_ROLE_LOADER';
export const setTrainingRoleLoader = (id, isLoading, hasLoaded) => ({
  type: SET_TRAINING_ROLE_LOADER,
  id,
  isLoading,
  hasLoaded,
});

export const SET_TRAINING_ROLE_ERRORMSG = 'SET_TRAINING_ROLE_ERRORMSG';
export const setTrainingRoleErrorMsg = (id, errorMsg) => ({
  type: SET_TRAINING_ROLE_ERRORMSG,
  id,
  errorMsg,
});

export const requestTrainingRoleData = (id) => (dispatch) => {
  dispatch(setTrainingRoleLoader(id, true, false));
  dispatch(setTrainingRoleErrorMsg(id, null));

  const fetch = fetchTrainingAndOwnershipsRole(id);
  fetch
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(trainingRoleDataRecieved(id, data.data));
      dispatch(setTrainingRoleLoader(id, false, true));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
      setTimeout(() => {
        dispatch(setTrainingRoleLoader(id, false, false));
        dispatch(setTrainingRoleErrorMsg(id, 'Failed getting data for role'));
      }, 2000);
    });
};

export const OWNED_ROLE_DATA_RECIEVED = 'OWNED_ROLE_DATA_RECIEVED';
export const ownedRoleDataRecieved = (id, data) => ({
  type: OWNED_ROLE_DATA_RECIEVED,
  id,
  data,
});

export const SET_OWNED_ROLE_LOADER = 'SET_OWNED_ROLE_LOADER';
export const setOwnedRoleLoader = (id, isLoading, hasLoaded) => ({
  type: SET_OWNED_ROLE_LOADER,
  id,
  isLoading,
  hasLoaded,
});

export const SET_OWNED_ROLE_ERRORMSG = 'SET_OWNED_ROLE_ERRORMSG';
export const setOwnedRoleErrorMsg = (id, errorMsg) => ({
  type: SET_OWNED_ROLE_ERRORMSG,
  id,
  errorMsg,
});

export const requestOwnedRoleData = (id) => (dispatch) => {
  dispatch(setOwnedRoleLoader(id, true, false));
  dispatch(setOwnedRoleErrorMsg(id, null));

  const fetch = fetchTrainingAndOwnershipsRole(id, {
    isOwnershipRequest: true,
  });
  fetch
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(ownedRoleDataRecieved(id, data.data));
      dispatch(setOwnedRoleLoader(id, false, true));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
      setTimeout(() => {
        dispatch(setOwnedRoleLoader(id, false, false));
        dispatch(setOwnedRoleErrorMsg(id, 'Failed getting data for role'));
      }, 2000);
    });
};

export const TRAINING_AREA_DATA_RECIEVED = 'TRAINING_AREA_DATA_RECIEVED';
export const trainingAreaDataRecieved = (id, data) => ({
  type: TRAINING_AREA_DATA_RECIEVED,
  id,
  data,
});

export const SET_TRAINING_AREA_LOADER = 'SET_TRAINING_AREA_LOADER';
export const setTrainingAreaLoader = (id, isLoading, hasLoaded) => ({
  type: SET_TRAINING_AREA_LOADER,
  id,
  isLoading,
  hasLoaded,
});

export const SET_TRAINING_AREA_ERRORMSG = 'SET_TRAINING_AREA_ERRORMSG';
export const setTrainingAreaErrorMsg = (id, errorMsg) => ({
  type: SET_TRAINING_AREA_ERRORMSG,
  id,
  errorMsg,
});

export const requestTrainingAreaData = (id) => (dispatch) => {
  dispatch(setTrainingAreaLoader(id, true, false));
  dispatch(setTrainingAreaErrorMsg(id, null));

  const fetch = fetchTrainingAndOwnershipsArea(id);
  fetch
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(trainingAreaDataRecieved(id, data.data));
      dispatch(setTrainingAreaLoader(id, false, true));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
      setTimeout(() => {
        dispatch(setTrainingAreaLoader(id, false, false));
        dispatch(setTrainingAreaErrorMsg(id, 'Failed getting data for role'));
      }, 2000);
    });
};

export const OWNED_AREA_DATA_RECIEVED = 'OWNED_AREA_DATA_RECIEVED';
export const ownedAreaDataRecieved = (id, data) => ({
  type: OWNED_AREA_DATA_RECIEVED,
  id,
  data,
});

export const SET_OWNED_AREA_LOADER = 'SET_OWNED_AREA_LOADER';
export const setOwnedAreaLoader = (id, isLoading, hasLoaded) => ({
  type: SET_OWNED_AREA_LOADER,
  id,
  isLoading,
  hasLoaded,
});

export const SET_OWNED_AREA_ERRORMSG = 'SET_OWNED_AREA_ERRORMSG';
export const setOwnedAreaErrorMsg = (id, errorMsg) => ({
  type: SET_OWNED_AREA_ERRORMSG,
  id,
  errorMsg,
});

export const requestOwnedAreaData = (id) => (dispatch) => {
  dispatch(setOwnedAreaLoader(id, true, false));
  dispatch(setOwnedAreaErrorMsg(id, null));

  const fetch = fetchTrainingAndOwnershipsArea(id, {
    isOwnershipRequest: true,
  });
  fetch
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(ownedAreaDataRecieved(id, data.data));
      dispatch(setOwnedAreaLoader(id, false, true));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
      setTimeout(() => {
        dispatch(setOwnedAreaLoader(id, false, false));
        dispatch(setOwnedAreaErrorMsg(id, 'Failed getting data for role'));
      }, 2000);
    });
};

export const UPDATED_DASHBOARD_PERSON_DATA_RECEIVED = 'UPDATED_DASHBOARD_PERSON_DATA_RECEIVED';
export const updatedPersonDataReceived = (personId, data) => ({
  type: UPDATED_DASHBOARD_PERSON_DATA_RECEIVED,
  personId,
  data,
});

export const UPDATED_DASHBOARD_PERSONS_DATA_RECEIVED = 'UPDATED_DASHBOARD_PERSONS_DATA_RECEIVED';
export const updatedPersonsDataReceived = (data) => ({
  type: UPDATED_DASHBOARD_PERSONS_DATA_RECEIVED,
  data,
});

export const UPDATED_ROLE_COMPLETION_PERCENTAGE_RECEIVED = 'UPDATED_ROLE_COMPLETION_PERCENTAGE_RECEIVED';
export const updatedRoleCompletionPercentageReceived = (data) => ({
  type: UPDATED_ROLE_COMPLETION_PERCENTAGE_RECEIVED,
  data,
});

export const TAO_UPDATED_PERSON_ELEMENT_DATA_RECEIVED = 'TAO_UPDATED_PERSON_ELEMENT_DATA_RECEIVED';
export const updatedPersonElementDataReceived = ({ personId, elementId, status, completedAt, hasValidity, daysUntilExpiration }) => ({
  type: TAO_UPDATED_PERSON_ELEMENT_DATA_RECEIVED,
  personId,
  elementId,
  status,
  completedAt,
  hasValidity,
  daysUntilExpiration,
});

export const changeRoleAreaStatus = (roleId, dataObj) => (dispatch) => {
  const put = updateRoleAreaStatus(roleId, dataObj);
  put
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(updatedPersonDataReceived(dataObj.employeeId, data.data));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });
};

export const changeRoleAreaLinkState = (roleId, dataObj) => (dispatch) => {
  const put = updateRoleAreaLinkState(roleId, dataObj);
  put
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(updatedPersonDataReceived(dataObj.employeeId, data.data));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });
};

// For role dashboard
export const changeRoleAreaElementStatus = (roleId, areaId, dataObj) => (dispatch) =>
  updateRoleAreaElementStatus(roleId, areaId, dataObj)
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(updatedPersonDataReceived(dataObj.employeeId, data.data));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });

export const changeRoleAreaElementSignees = (roleId, areaId, dataObj, successCallback) => (dispatch) => {
  const put = updateRoleAreaElementSignees(roleId, areaId, dataObj);
  put
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(updatedPersonsDataReceived(data.data));
      successCallback();
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });
};

// For knowledge dashboard
export const changeAreaElementStatus = (areaId, dataObj) => (dispatch) =>
  updateAreaElementStatus(areaId, dataObj)
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }
      dispatch(updatedPersonDataReceived(dataObj.employeeId, data.data));
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });

// For knowledge dashboard
export const changeAreaElementSignees = (areaId, dataObj, successCallback) => (dispatch) => {
  const put = updateAreaElementSignees(areaId, dataObj);
  put
    .then((data) => {
      if (isNothing(data.data)) {
        console.log('Kontakt til server fejlede');
      }

      dispatch(updatedPersonsDataReceived(data.data));
      successCallback();
    })
    .catch(() => {
      console.log('Failed getting data for Training and Ownerships');
    });
};
