import React from 'react';
import cx from 'classnames';
import ConditionalWrap from '../ConditionalWrapper';
import Tooltip from '../Tooltip';

const SideNavItem = ({
  active,
  element: Element = 'li',
  innerElement: InnerElement = 'button',
  leftComponent,
  noTruncate,
  onClick,
  rightComponent,
  showHoverTitle,
  small,
  stopRightComponentClickPropagation,
  style,
  subtitle,
  title,
  tooltip,
  disabled,
  hidden,
  innerRef,
  subtitle2,
  to,
  opaque,
}) => {
  const modifierClasses = {
    'side-nav-item--state-active': active,
    'side-nav-item--small': small,
    'side-nav-item--no-truncate': noTruncate,
    'side-nav-item--disabled': disabled,
    'side-nav-item--hidden': hidden,
    'side-nav-item--opaque': opaque,
  };
  return (
    <Element
      className={cx('side-nav-item', modifierClasses)}
      style={style}
      ref={innerRef}
      data-place="right"
      data-class="info-tooltip__text"
    >
      <ConditionalWrap
        condition={tooltip}
        wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
      >
        <InnerElement
          className="side-nav-item__button"
          to={to}
          onClick={onClick}
          activeClassName="side-nav-item__button--state-active"
        >
          {leftComponent !== undefined && (
            <div className="side-nav-item__left">{leftComponent}</div>
          )}
          <div className="side-nav-item__text">
            <p className="side-nav-item__title" title={showHoverTitle && title}>
              {title}
            </p>
            <p className="side-nav-item__subtitle">{subtitle}</p>
            {subtitle2 && (
              <p className="side-nav-item__subtitle">{subtitle2}</p>
            )}
          </div>
          {rightComponent !== undefined && (
            <div
              className="side-nav-item__right"
              onClick={(e) => {
                if (stopRightComponentClickPropagation) e.stopPropagation();
              }}
            >
              {rightComponent}
            </div>
          )}
        </InnerElement>
      </ConditionalWrap>
    </Element>
  );
};

export default React.forwardRef((props, ref) => (
  <SideNavItem innerRef={ref} {...props} />
));
