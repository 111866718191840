import React from 'react';

export const Grid = ({
  inline = false,
  columnGap,
  gridTemplateColumns,
  children,
}) => {
  return (
    <div
      className="grid"
      style={{
        display: inline ? 'inline-grid' : 'grid',
        gridTemplateColumns,
        columnGap,
      }}
    >
      {children}
    </div>
  );
};
