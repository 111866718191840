import {
  getMenteeRoleService,
  updateMenteeAreaStatusService,
  updateMenteeAreaLinkStateService,
  updateMenteeElementMediatorService,
  getAreaService,
} from '../services/roleService';
import { getKnowledgeElementService } from '../services/traineeService';

export const AREA_RECIEVED = 'AREA_RECIEVED';
export const areaRecieved = (id, area) => ({
  type: AREA_RECIEVED,
  id,
  area,
});

export const COMPLETION_PERCENTAGE_RECEIVED = 'COMPLETION_PERCENTAGE_RECEIVED';
export const completionPercentageReceived = (completionPercentage) => ({
  type: COMPLETION_PERCENTAGE_RECEIVED,
  completionPercentage,
});

export const ELEMENT_RECIEVED = 'ELEMENT_RECIEVED';
export const elementRecieved = (id, element) => ({
  type: ELEMENT_RECIEVED,
  id,
  element,
});
export const ELEMENTS_RECIEVED = 'ELEMENTS_RECIEVED';
export const elementsRecieved = (elements) => ({
  type: ELEMENTS_RECIEVED,
  elements,
});

export const ROLE_RECEIVED = 'ROLE_RECEIVED';
export const roleReceived = (data) => ({
  type: ROLE_RECEIVED,
  data,
});

export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const setLoadingState = (isLoading) => ({
  type: SET_LOADING_STATE,
  isLoading,
});

export const getMenteeRole =
  (menteeId, roleId, successCallback, setLoadState = true) =>
  (dispatch) => {
    setLoadState && dispatch(setLoadingState(true));
    return getMenteeRoleService(menteeId, roleId)
      .then((response) => {
        dispatch(
          roleReceived({
            ...response.data,
            mentor: response.data.mentor || '',
          })
        );
        successCallback && successCallback();
        dispatch(setLoadingState(false));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

export const updateKnowledgeElement = (personId, elementId) => (dispatch) =>
  getKnowledgeElementService(personId, elementId)
    .then((response) => dispatch(elementRecieved(elementId, response.data.knowledgeElements[elementId])))
    .catch((ex) => {
      console.log(ex);
    });

export const updateArea =
  ({ personId, roleId, areaId }) =>
  (dispatch) =>
    getAreaService(personId, roleId, areaId)
      .then(({ data }) => {
        dispatch(areaRecieved(data.knowledgeArea.id, data.knowledgeArea));
        dispatch(completionPercentageReceived(data.completionPercentage));
      })
      .catch((ex) => {
        console.log(ex);
      });

export const updateMenteeElementMediator =
  ({ menteeId, roleId, areaId, elementId, newMediatorId }) =>
  (dispatch) => {
    updateMenteeElementMediatorService(menteeId, roleId, areaId, elementId, {
      mediatorId: newMediatorId,
    })
      .then((response) => {
        dispatch(elementRecieved(elementId, response.data.updatedKnowledgeElement[elementId]));
        dispatch(areaRecieved(areaId, response.data.updatedKnowledgeArea[areaId]));
      })
      .catch((err) => {
        console.log('catch', err);
      });
  };

export const updateMenteeAreaStatus =
  ({ menteeId, roleId, areaId, status }) =>
  (dispatch) => {
    updateMenteeAreaStatusService(menteeId, roleId, areaId, {
      status,
    })
      .then((response) => {
        dispatch(completionPercentageReceived(response.data.completionPercentage));
        dispatch(areaRecieved(areaId, response.data.updatedKnowledgeArea[areaId]));
        dispatch(elementsRecieved(response.data.updatedKnowledgeElements));
      })
      .catch((err) => {
        console.log('catch', err);
      });
  };

export const updateMenteeAreaLinkState =
  ({ menteeId, roleId, areaId, link }) =>
  (dispatch) => {
    updateMenteeAreaLinkStateService(menteeId, roleId, areaId, {
      linkToEmployee: link,
    })
      .then((response) => {
        dispatch(completionPercentageReceived(response.data.completionPercentage));
        dispatch(areaRecieved(areaId, response.data.updatedKnowledgeArea[areaId]));
      })
      .catch((err) => {
        console.log('catch', err);
      });
  };
