import React, { PureComponent } from 'react';
import Portal from './Portal';
import { systemSelector } from '../reducers/systemReducer';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  menuOpen: systemSelector.menuOpen(state),
});

const mapDispatchToProps = {};

class CursorContentWrapper extends PureComponent {
  constructor() {
    super();
    this.state = {
      positionSet: false,
    };
  }
  componentDidMount() {
    const { enabled } = this.props;
    if (enabled) {
      window.addEventListener('mousemove', this.onMoveMouse);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { enabled } = this.props;
    if (prevProps.enabled !== enabled) {
      if (enabled) {
        window.addEventListener('mousemove', this.onMoveMouse);
      } else {
        window.removeEventListener('mousemove', this.onMoveMouse);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMoveMouse);
  }
  onMoveMouse = (e) => {
    const { offsetX, offsetY, cursorContent, menuOpen } = this.props;
    const mouseY = e.clientY;
    const mouseX = e.clientX;
    if (this.cursorContentRef && cursorContent) {
      const boxClientRect = this.cursorContentRef.getBoundingClientRect();
      const boxLeftPosition = boxClientRect.width + mouseX;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const isOffScreenRight = boxLeftPosition > windowWidth;
      const isOffScreenBottom = boxClientRect.height + mouseY > windowHeight;
      const translateX = isOffScreenRight
        ? mouseX - boxClientRect.width - offsetX
        : mouseX + offsetX;
      const translateY = isOffScreenBottom
        ? mouseY - boxClientRect.height - offsetY
        : mouseY + offsetY;
      this.cursorContentRef.style.transform = `translate3d(${
        menuOpen ? translateX - 270 : translateX // 270 is the width of the menu
      }px, ${translateY}px, 0)`;
    }
    this.setState({ positionSet: true });
  };
  render() {
    const { positionSet } = this.state;
    const { children, cursorContent, enabled } = this.props;
    return (
      <>
        {children}
        <Portal id="cursor-content-root">
          <div
            className="cursor-content-wrapper"
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              display: enabled && positionSet ? 'block' : 'none',
              pointerEvents: 'none',
              //visibility: 'hidden',
            }}
            ref={(el) => (this.cursorContentRef = el)}
          >
            {cursorContent}
          </div>
        </Portal>
      </>
    );
  }
}

CursorContentWrapper.defaultProps = {
  offsetX: 0,
  offsetY: 0,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CursorContentWrapper);
