import React, { Component } from 'react';

import DrawerOption from './DrawerOption';
import cx from 'classnames';
import { motion } from 'framer-motion';
import Accordion from './Accordion';

const variants = {
  open: { height: 'auto' },
  closed: { height: 0 },
};

class DrawerBox extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { children, options, disabled } = this.props;
    const { isOpen } = this.state;
    const modifierClasses = cx({ 'drawer-box--disabled': disabled });
    return (
      <div className={`drawer-box ${modifierClasses}`}>
        <div className="drawer-box__trigger" onClick={this.toggleOpen}>
          {children}
        </div>
        <Accordion isOpen={isOpen} className="drawer-box__drawer">
          <div className="drawer-box__drawer-inner">
            {options.map((option, index) => (
              <DrawerOption onClick={option.onClick} text={option.text} icon={option.icon} key={index} />
            ))}
          </div>
        </Accordion>
      </div>
    );
  }
}

export default DrawerBox;
