import PropTypes from 'prop-types';
import React from 'react';
import { getElementContentIcon } from '../utils/elementHelpers';
import ButtonIcon from './ButtonIcon';

const KnowledgeElementContentButton = ({ files, description, onClick, size }) => {
  const icon = getElementContentIcon({ description, files });
  if (icon) {
    return (
      <div className="knowledge-element-content-button">
        <ButtonIcon size={size} icon={icon} onClick={onClick} />
      </div>
    );
  }
  return null;
};

KnowledgeElementContentButton.defaultProps = {
  size: 'large',
};

KnowledgeElementContentButton.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      type: PropTypes.string,
      state: PropTypes.string,
      url: PropTypes.string,
      progress: PropTypes.number,
      minutesRemaining: PropTypes.number,
    })
  ).isRequired,
};

export default KnowledgeElementContentButton;
