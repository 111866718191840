import cx from 'classnames';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import InputError from './formElements/InputError';
import InputLabel from './formElements/InputLabel';
import Icon from './Icon';
import localeLookup from '../config/locale';

const mapStateToProps = (state) => {
  const { rootmenu } = state;
  return {
    dateFormat: rootmenu?.dateFormat,
    timeFormat: rootmenu?.timeFormat,
    userLocale: state.user.locale,
  };
};

const TimeField = ({
  onChange,
  value,
  label,
  clearable,
  onClear,
  required,
  disabled,
  error,
  errors,
  onBlur,
  id,
  name,
  userLocale,
  timeFormat,
  durationSelect = false,
  placeholderText = localeLookup('translations.HH:MM'),
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const datepickerRef = React.useRef(null);

  const setLocale = async () => {
    if (userLocale === 'en') {
      setIsLoading(false);
      return;
    }

    if (userLocale === 'zh') {
      const module = await import(`date-fns/locale/${userLocale}-CN`);
      registerLocale(userLocale, module.default);
      setIsLoading(false);
      return;
    }

    try {
      const module = await import(`date-fns/locale/${userLocale}`);
      registerLocale(userLocale, module.default);
    } catch {}

    setIsLoading(false);
  };

  useEffect(() => {
    setLocale();
  }, []);

  const modifierClasses = {
    'date-field--no-value': !value,
  };

  const getTimeFormat = () => {
    const TIME_FORMAT_STRINGS = {
      TwentyFourHour: 'HH:mm',
      TwelveHour: 'hh:mm aa',
    };
    if (durationSelect) return 'HH:mm';
    const timeFormatString = TIME_FORMAT_STRINGS[timeFormat] || 'HH:mm';
    return timeFormatString;
  };

  const getError = () => {
    if (error) return error;
    if (errors)
      return errors.map((error, i) => (
        <React.Fragment key={i}>
          <span>- {error}</span>
          <br />
        </React.Fragment>
      ));
  };

  if (isLoading) return null;
  return (
    <div className={cx('date-field', modifierClasses)}>
      {label && (
        <InputLabel className="date-field__label" htmlFor="date">
          {label}
        </InputLabel>
      )}
      <div className="date-field__input-wrapper">
        <DatePicker
          disabled={disabled}
          ref={datepickerRef}
          className="date-field__input"
          dateFormat={getTimeFormat()}
          timeFormat={getTimeFormat()}
          showTimeSelect
          showTimeSelectOnly
          onBlur={onBlur}
          placeholderText={placeholderText}
          onChange={(date) => {
            onChange(date);
          }}
          id={id || 'time'}
          name={name}
          timeCaption={
            durationSelect
              ? localeLookup('translations.Duration')
              : localeLookup('translations.Time')
          }
          selected={value}
          portalId="portal-root"
          locale={userLocale}
          handleCalendarClickOutside={() => {
            console.log('click outside');
          }}
        />
        {clearable && !!value && (
          <button onClick={onClear} className="date-field__clear-button">
            <Icon size="small" color="grey" kind="cross"></Icon>
          </button>
        )}
      </div>
      <InputError>{getError()}</InputError>
    </div>
  );
};

export default connect(mapStateToProps)(TimeField);
