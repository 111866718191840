import React from 'react';
import cx from 'classnames';
import localeLookup from '../config/locale';
import Tooltip from './Tooltip';

const AreaElementConnectionCount = ({ connected, total }) => {
  const modifierClasses = {
    'area-element-connection-count--color-red': connected === 0,
  };
  return (
    <Tooltip tooltip={`${connected} ${localeLookup('translations.elements connected')}`}>
      <p className={cx('area-element-connection-count', modifierClasses)}>
        {connected}/{total}
      </p>
    </Tooltip>
  );
};

export default AreaElementConnectionCount;
