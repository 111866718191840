import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../config/locale';
import { queryAreas } from '../slices/areasSlice';
import { queryElements } from '../slices/elementsSlice';
import { queryRoles } from '../slices/rolesSlice';
import { insertStringInString } from '../utils/helpers';
import Button from './Button';
import Elements from './editor/Elements';
import withAccessControl from './HOC/withAccessControl';
import WithEditorActions from './HOC/withEditorActions';
import WithModals from './HOC/withModals';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryAreas, queryRoles, queryElements }, dispatch);

class KnowledgeEditorRole extends Component {
  getArea = () => {
    const { area, queryAreas } = this.props;
    return queryAreas([area.id]);
  };

  onClickRemoveAreaConnection = () => {
    const { area, role, onClickRemoveAreaConnection, editorActions } =
      this.props;
    editorActions
      .disconnectAreasFromRoles({ roleIds: [role.id], areaIds: [area.id] })
      .then(() => {
        onClickRemoveAreaConnection();
      });
  };

  onClickToggleAreaLinkVisiblity = () => {
    const { area, editorActions } = this.props;
    editorActions.showToggleAreaLinkVisiblityModal(area);
  };

  renderHeader = () => {
    const {
      allowChangeOfChampLinkVisibility,
      area,
      role,
      hasAccessToRole,
      isAreaReadOnly,
    } = this.props;
    const hasRoleAccess = hasAccessToRole(role.id);
    return (
      <div className="editor__header">
        <h1 className="editor__header-title">{role.name}</h1>
        {allowChangeOfChampLinkVisibility && !isAreaReadOnly ? (
          <Button
            onClick={this.onClickToggleAreaLinkVisiblity}
            kind="shy"
            className="editor__header-button"
            icon={area.champLink.isPublic ? 'unlock' : 'lock'}
          >
            {insertStringInString(area.champLink.link, '-', 3)}
          </Button>
        ) : (
          <p className="editor__header-text">
            {insertStringInString(area.champLink.link, '-', 3)}
          </p>
        )}
        {hasRoleAccess && (
          <Button
            kind="light-grey"
            className="editor__header-button"
            onClick={this.onClickRemoveAreaConnection}
            icon="circle-minus"
          >
            {localeLookup('translations.Remove connection')}
          </Button>
        )}
      </div>
    );
  };

  renderElementsSection = () => {
    const { allowChangeOfChampLinkVisibility, area, role, isAreaReadOnly } =
      this.props;
    const { knowledgeElements } = area;
    return (
      <Elements
        isInRoleContext
        allowChangeOfChampLinkVisibility={allowChangeOfChampLinkVisibility}
        areaId={area.id}
        canDrag={!isAreaReadOnly}
        canCreate={!isAreaReadOnly}
        readOnly={isAreaReadOnly}
        roleId={role.id}
        sortOrder={knowledgeElements}
      />
    );
  };

  render() {
    return (
      <div className="editor">
        {this.renderHeader()}
        {this.renderElementsSection()}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(WithModals(WithEditorActions(withAccessControl(KnowledgeEditorRole))));
