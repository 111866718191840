/* eslint-disable no-case-declarations */
import {
  getAllAreasService,
  queryAreasService,
} from '../services/contentService';
import { compareLocal, sortBy } from '../utils/helpers';

// Actions
const LOAD_ALL = 'areas/LOAD_ALL';
const LOAD_AREAS = 'areas/LOAD_AREAS';
const REMOVE_AREA = 'areas/REMOVE_AREA';
const UPDATE_AREA_ELEMENTS_ORDER = 'areas/UPDATE_AREA_ELEMENTS_ORDER';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        ...action.areas,
      };
    case LOAD_AREAS:
      return { ...state, ...action.areas };
    case REMOVE_AREA:
      const { [action.id]: value, ...withoutArea } = state;
      return withoutArea;
    case UPDATE_AREA_ELEMENTS_ORDER:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          knowledgeElements: action.elementsOrder,
        },
      };
    default:
      return state;
  }
};

export const getAllAreas = () => (dispatch) =>
  getAllAreasService().then(({ data }) => {
    dispatch({ type: LOAD_ALL, areas: data });
  });

export const removeArea = (id) => (dispatch) => {
  dispatch({ type: REMOVE_AREA, id });
};

export const updateAreaElementsOrder = (id, elementsOrder) => (dispatch) => {
  dispatch({ type: UPDATE_AREA_ELEMENTS_ORDER, id, elementsOrder });
};

export const queryAreas = (areaIds) => (dispatch) =>
  queryAreasService(areaIds).then(({ data }) => {
    dispatch({ type: LOAD_AREAS, areas: data });
    return data;
  });

// Selectors
export const selectAreasSortOrder = (state) =>
  sortBy(Object.keys(state.areas), [
    (a, b) => compareLocal(state.areas[a].name, state.areas[b].name),
  ]);
