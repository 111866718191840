import React from 'react';
import cx from 'classnames';
import Tooltip from './Tooltip';
import ConditionalWrap from './ConditionalWrapper';

const ListCard = ({
  el: Element = 'div',
  children,
  leftComponent,
  rightComponent,
  noRightPadding,
  clickable,
  disabled,
  tooltip,
  onClick,
  noCardStyling,
}) => {
  const modifierClasses = cx({
    'list-card--no-right-padding': noRightPadding,
    'list-card--clickable': clickable,
    'list-card--disabled': disabled,
    'list-card--no-card-styling': noCardStyling,
  });
  return (
    <ConditionalWrap
      condition={tooltip}
      wrap={(children) => (
        <Tooltip placement="left" tooltip={tooltip}>
          {children}
        </Tooltip>
      )}
    >
      <Element onClick={onClick} className={cx('list-card', modifierClasses)}>
        {!!leftComponent && (
          <div className="list-card__left">{leftComponent}</div>
        )}
        {!!children && <div className="list-card__center">{children}</div>}
        <div className="list-card__right">{rightComponent}</div>
      </Element>
    </ConditionalWrap>
  );
};
export default ListCard;
