import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getQueryStringParams } from '../utils/helpers';
import Button from '../components/Button';
import { withAuth } from 'react-oidc-context';
import { withRouter } from 'react-router';
import { getTranslationsService } from '../services/menuService';
import localeLookup from '../config/locale';

class ErrorPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      hasTranslations: false,
    };
  }

  componentDidMount() {
    this.getTranslations();
  }
  getTranslations = () => {
    return getTranslationsService().then(({ data }) => {
      const globalTranslationObject = window.CHAMP.locale.data;
      const browserLanguage = window.navigator.language.split('-')[0];
      const locale = globalTranslationObject.locale
        ? globalTranslationObject.locale
        : data[browserLanguage]
        ? browserLanguage
        : 'en';
      globalTranslationObject.locale = locale;
      globalTranslationObject.map = Object.keys(
        data[locale.toLowerCase()].translations
      ).reduce(
        (acc, key) => ({
          ...acc,
          [key.toLowerCase()]: data[locale.toLowerCase()].translations[key],
        }),
        {}
      );
      this.setState({
        hasTranslations: true,
      });
    });
  };
  onClickLogout = () => {
    const { auth } = this.props;
    const { signoutRedirect } = auth;
    signoutRedirect({ post_logout_redirect_uri: window.location.origin });
  };
  onClickTryAgain = () => {
    const { history } = this.props;
    window.location.href = '/roles';
  };
  render() {
    const { hasTranslations } = this.state;
    const queryParams = getQueryStringParams(window.location.search);
    return (
      <div className="error-page">
        <div className="error-page__overlay" />
        {hasTranslations && (
          <div className="error-page__content">
            {queryParams.type === 'NoAccess' ? (
              <h1 className="error-page__title">
                {localeLookup('translations.Access denied')}
              </h1>
            ) : (
              <h1 className="error-page__title">An error occurred</h1>
            )}
            <p>{queryParams.message}</p>
            {queryParams.type === 'NoAccess' && (
              <div className="error-page__buttons">
                <Button
                  className="error-page__button"
                  kind="darkui"
                  onClick={this.onClickLogout}
                >
                  {localeLookup('translations.Log out')}
                </Button>
                <Button
                  className="error-page__button"
                  kind="darkui"
                  onClick={this.onClickTryAgain}
                >
                  {localeLookup('translations.Retry')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ErrorPage.propTypes = {};

export default withAuth(withRouter(ErrorPage));
