import axios from 'axios';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../config/locale';
import { truncateFilename } from '../utils/helpers';
import Input from './formElements/Input';
import Icon from './Icon';
import ProgressBar from './ProgressBar';

const fileBoxVariants = {
  enter: { opacity: 1, delay: 100, beforeChildren: 100 },
  exit: { opacity: 0 },
};

const mapStateToProps = (state, ownProps) => ({
  allowedMimeTypes: state.system.allowedFileMimeTypes,
});

class FileUploadBox extends Component {
  constructor() {
    super();
    this.state = {
      file: null,
      illegalFileType: false,
      isUploading: false,
      uploadFailed: false,
      uploadPercentage: 0,
      cancelTokenSource: null,
      dragAndDropSupported: false,
      isDraggingOver: false,
    };
  }

  componentDidMount() {
    this.setState({
      dragAndDropSupported: this.dragAndDropSupported(),
    });
  }

  cancelUpload = () => {
    if (this.state.cancelTokenSource) {
      this.state.cancelTokenSource.cancel('Upload cancelled');
    }
  };

  dragAndDropSupported = () => {
    const div = document.createElement('div');
    return (
      ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
      'FormData' in window &&
      'FileReader' in window
    );
  };

  getStatusMessage = () => {
    const { uploadPercentage } = this.state;
    if (uploadPercentage === 0) {
      return localeLookup('translations.Starting upload');
    }
    if (uploadPercentage === 100) {
      return localeLookup('translations.Finishing upload');
    }
    return localeLookup('translations.Uploading');
  };

  handleFile = (file) => {
    const { onFileChange } = this.props;

    // Custom handling for DocuNote files
    if (file.name.endsWith('.dno')) {
      file = new File([file.slice(0, file.size, 'text/xml')], file.name, {
        type: 'text/xml',
      });
    }
    // Custom handling for Publisher files
    else if (file.name.endsWith('.pub')) {
      file = new File(
        [file.slice(0, file.size, 'application/x-mspublisher')],
        file.name,
        { type: 'application/x-mspublisher' }
      );
    }

    const illegalFileType =
      file.type === '' ||
      !this.getAllAllowedFileTypesString().includes(file.type);
    const error = illegalFileType
      ? localeLookup('translations.Filetype not allowed')
      : null;

    onFileChange(file);
    this.setState(
      {
        error,
        file,
        illegalFileType,
      },
      () => {
        if (!error) {
          this.onSubmit();
        }
      }
    );
  };

  onDragDrop = (e) => {
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      this.handleFile(file);
    }
  };

  onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isDraggingOver: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      isDraggingOver: false,
    });
  };

  onFileChange = (e) => {
    const file = e.target.files[0];
    this.handleFile(file);
  };

  onRemoveFile = () => {
    const { onFileRemove } = this.props;
    this.fileInput.value = '';
    onFileRemove();
    this.setState({ file: null });
  };

  onSubmit = () => {
    const { file } = this.state;
    const { onSubmit } = this.props;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({
      isUploading: true,
      error: null,
      cancelTokenSource: source,
    });
    onSubmit({
      file,
      onProgressChange: this.setUploadPercentage,
      errorCallback: this.onUploadError,
      cancelToken: source.token,
    });
  };

  onUploadError = (error) => {
    if (error.message === 'Upload cancelled') {
      this.onRemoveFile();
      this.setState({
        isUploading: false,
        uploadPercentage: 0,
      });
    } else {
      this.setState({
        isUploading: false,
        error: localeLookup('translations.Upload error'),
        uploadPercentage: 0,
      });
    }
  };

  getAllAllowedFileTypesString = () => {
    const { allowedMimeTypes } = this.props;
    if (!allowedMimeTypes.allowedFileTypes) return '';
    return [
      ...allowedMimeTypes.allowedFileTypes,
      ...allowedMimeTypes.allowedImageTypes,
      ...(allowedMimeTypes.allowedVideoTypes || []),
      '.dno',
      '.pub',
    ].toString();
  };

  getIconBasedOnFileType = () => {
    const { error, file } = this.state;
    const { allowedMimeTypes } = this.props;
    if (error) {
      return 'cross';
    }
    if (allowedMimeTypes.allowedImageTypes.includes(file.type)) {
      return 'picture';
    }
    if (allowedMimeTypes?.allowedVideoTypes?.includes(file.type)) {
      return 'camera';
    }
    return 'paperclip';
  };

  setUploadPercentage = (percentage) => {
    this.setState({
      uploadPercentage: percentage,
    });
  };

  render() {
    const {
      error,
      file,
      illegalFileType,
      isUploading,
      uploadPercentage,
      isDraggingOver,
    } = this.state;
    const { allowedMimeTypes } = this.props;

    if (!allowedMimeTypes) return null;

    return (
      <div
        className={`file-upload-box ${cx({
          'file-upload-box--has-file': file != null,
          'file-upload-box--error': error,
          'file-upload-box--drag-over': isDraggingOver,
        })}`}
      >
        <AnimatePresence exitBeforeEnter>
          {!file && (
            <motion.div
              key="label"
              draggable
              onDragEnter={this.onDragOver}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDragEnd={this.onDragLeave}
              onDrop={this.onDragDrop}
              initial={fileBoxVariants.exit}
              animate={fileBoxVariants.enter}
              exit={fileBoxVariants.exit}
            >
              <label className="file-upload-box__label" htmlFor="file">
                <Icon
                  kind="cloud-upload"
                  className="file-upload-box__label-icon"
                />
                <p className="file-upload-box__label-text">
                  {localeLookup('translations.Upload files')}
                </p>
              </label>
            </motion.div>
          )}
          {file && (
            <motion.div
              initial={fileBoxVariants.exit}
              animate={fileBoxVariants.enter}
              exit={fileBoxVariants.exit}
              key="file"
            >
              <div className="file-upload-box__file">
                <div className="file-upload-box__file-icon-wrapper">
                  <Icon
                    className="file-upload-box__file-icon"
                    kind={this.getIconBasedOnFileType()}
                  />
                </div>
                <div className="file-upload-box__file-info">
                  <p className="file-upload-box__file-name">
                    <span>{truncateFilename(file.name)}</span>
                    {isUploading && (
                      <span className="file-upload-box__file-status">
                        {this.getStatusMessage()}...
                      </span>
                    )}
                    {!isUploading && (
                      <button
                        type="button"
                        onClick={this.onRemoveFile}
                        className="file-upload-box__file-remove"
                      >
                        <Icon
                          className="file-upload-box__file-remove-icon"
                          kind="cross-circle"
                        />
                      </button>
                    )}
                  </p>
                  {isUploading && <ProgressBar percentage={uploadPercentage} />}
                  {error && (
                    <p className="file-upload-box__file-error">{error}</p>
                  )}
                </div>
                {!isUploading && !illegalFileType && (
                  <button
                    type="button"
                    onClick={this.onSubmit}
                    className="ui-btn ui-btn-darkui ui-small"
                  >
                    {error
                      ? localeLookup('translations.Retry')
                      : localeLookup('translations.Save')}
                  </button>
                )}
                {isUploading && (
                  <button
                    type="button"
                    onClick={this.cancelUpload}
                    className="ui-btn ui-btn-alert ui-small"
                  >
                    {localeLookup('translations.Cancel')}
                  </button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <Input
          accept={this.getAllAllowedFileTypesString()}
          onChange={this.onFileChange}
          className="file-upload-box__input"
          type="file"
          id="file"
          ref={(input) => {
            this.fileInput = input;
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(FileUploadBox);
