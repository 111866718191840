import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import FormWrapper from './formElements/FormWrapper';
import RadioButtonField from './formElements/RadioButtonField';
import TextField from './formElements/TextField';
import { validateEmailService } from '../services/personsService';
import { EMAIL_REGEX } from '../constants';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  hasExternalUserDirectoryIntegration:
    state.rootmenu.hasExternalUserDirectoryIntegration,
  organisationAllowsChampLogin: state.rootmenu.organisationAllowsChampLogin,
});

class UserTypeForm extends PureComponent {
  onSubmit = ({ email, userType }, { setErrors }) => {
    const { onSubmit, initialValues } = this.props;
    if (initialValues?.email === email) {
      onSubmit({ email, userType });
      return;
    }
    if (userType === 'userWithoutLogin') {
      onSubmit({ userType });
      return;
    }
    validateEmailService({ email })
      .then(() => {
        onSubmit({ email, userType });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setErrors({ email: localeLookup(err.response.data.message) });
        }
      });
  };

  render() {
    const {
      renderFooter,
      initialValues,
      isEditingLogin,
      confirmButtonText,
      organisationAllowsChampLogin,
      hasExternalUserDirectoryIntegration,
    } = this.props;
    const defaultInitialValues = {
      email: '',
      userType:
        !organisationAllowsChampLogin && hasExternalUserDirectoryIntegration
          ? 'userWithExternalLogin'
          : 'userWithLogin',
    };
    return (
      <FormWrapper
        className="create-person-form"
        onSubmit={this.onSubmit}
        initialValues={{ ...defaultInitialValues, ...initialValues }}
        validateOnMount
        validationSchema={Yup.object().shape({
          userType: Yup.string().required(),
          email: Yup.string().when('userType', {
            is: (userType) =>
              userType === 'userWithLogin' ||
              userType === 'userWithExternalLogin',
            then: () =>
              Yup.string()
                .matches(
                  EMAIL_REGEX,
                  localeLookup('translations.Email is not valid')
                )
                .test(
                  'contains illegal chars',
                  localeLookup('translations.Email is not valid'),
                  (value, context) => !value?.toLowerCase().match(/ø|æ|å/gi)
                )
                .required(localeLookup('translations.Email is required')),
          }),
        })}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          const submitButtonText =
            (isEditingLogin && values.userType === 'userWithoutLogin') ||
            (isEditingLogin && values.userType === 'userWithExternalLogin')
              ? localeLookup('translations.Save')
              : confirmButtonText;
          return (
            <>
              <form className="user-type-form" onSubmit={handleSubmit}>
                {organisationAllowsChampLogin && (
                  <RadioButtonField
                    name="userType"
                    onChange={handleChange}
                    id="userWithLogin"
                    value="userWithLogin"
                    isChecked={values.userType === 'userWithLogin'}
                    label={localeLookup('translations.User with login')}
                  />
                )}
                {hasExternalUserDirectoryIntegration && (
                  <RadioButtonField
                    name="userType"
                    onChange={handleChange}
                    id="userWithExternalLogin"
                    value="userWithExternalLogin"
                    isChecked={values.userType === 'userWithExternalLogin'}
                    label={localeLookup(
                      'translations.User with Active Directory login'
                    )}
                  />
                )}
                <RadioButtonField
                  name="userType"
                  onChange={handleChange}
                  id="userWithoutLogin"
                  value="userWithoutLogin"
                  isChecked={values.userType === 'userWithoutLogin'}
                  label={localeLookup('translations.User without login')}
                />
                {(values.userType === 'userWithLogin' ||
                  values.userType === 'userWithExternalLogin') && (
                  <TextField
                    name="email"
                    id="email"
                    required
                    error={dirty && touched.email && errors.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      let value = e.target.value || '';
                      value = value.toLowerCase().trim();
                      setFieldValue('email', value);
                    }}
                    label={localeLookup('translations.Email')}
                  />
                )}
              </form>
              {renderFooter &&
                renderFooter({
                  handleSubmit,
                  canSubmit: isValid,
                  submitButtonText,
                })}
            </>
          );
        }}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(UserTypeForm);
