import React from 'react';
import localeLookup from '../config/locale';
import Icon from './Icon';
import Tooltip from './Tooltip';

export const PasswordRequirementsChecker = ({ errors }) => {
  const tooltip = localeLookup('translations.passwordSequenceTooltip');
  return (
    <div className="password-requirements-checker">
      <p>{localeLookup('translations.Password requirements')}</p>
      <ul className="password-requirements-checker__requirements-list">
        <li className="password-requirements-checker__requirement">
          <Icon
            color={errors.includes('length') ? 'red' : 'green'}
            kind={errors.includes('length') ? 'cross' : 'check'}
          />
          {localeLookup('translations.At least 12 characters')}
        </li>
        <li className="password-requirements-checker__requirement">
          <Icon
            color={errors.includes('strength') ? 'red' : 'green'}
            kind={errors.includes('strength') ? 'cross' : 'check'}
          />
          {localeLookup('translations.At least "Medium" in strength')}
        </li>
        <li className="password-requirements-checker__requirement">
          <Icon
            color={errors.includes('sequence') ? 'red' : 'green'}
            kind={errors.includes('sequence') ? 'cross' : 'check'}
          />
          {localeLookup(
            'translations.Can not contain sequences longer than 4 characters'
          )}{' '}
          <Tooltip tooltip={tooltip}>
            <span>
              <Icon
                className="password-requirements-checker__requirement-tooltip-icon"
                kind="info-circle"
                size="small"
              ></Icon>
            </span>
          </Tooltip>
        </li>
        <li className="password-requirements-checker__requirement">
          <Icon
            color={errors.includes('breach') ? 'red' : 'green'}
            kind={errors.includes('breach') ? 'cross' : 'check'}
          />
          {localeLookup(
            'translations.Can not be found in online breaches of passwords'
          )}
        </li>
      </ul>
    </div>
  );
};
