import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getMenteeRole } from '../../actions/roleActions';
import { getMentorRoles } from '../../actions/trainingActions';
import EmptyState from '../../components/EmptyState';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import HorizontalSection from '../../components/HorizontalSection';
import Icon from '../../components/Icon';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import RoleCard from '../../components/RoleCard';
import Text from '../../components/Text';
import localeLookup from '../../config/locale';
import {
  compareLocal,
  formatDate,
  getQueryStringParams,
  sortBy,
} from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';
import { EMPTY_ID } from '../../constants';
import { Header } from '../../components/Header';
import dayjs from 'dayjs';
import GanttChart from '../../components/GanttChart';
import Tabs from '../../components/Tabs';
import Label from '../../components/Label';
import Select from '../../components/Select';
import Field from '../../components/formElements/Field';
import { Grid } from '../../components/Grid';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
const mapStateToProps = (state) => {
  const { training, organisationUnits } = state;
  return {
    mentees: training.mentees,
    roles: training.roles,
    organisationUnits,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMentorRoles,
      getAllOrganisationUnits,
    },
    dispatch
  );

class Programs extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      activeView: 'list',
      timelineOptions: {
        sorting: 'endDate',
        grouping: 'none',
      },
    };
  }

  componentDidMount() {
    const { getMentorRoles, getAllOrganisationUnits, location } = this.props;
    trackEvent('$pageview');
    this.getQueryParams();
    Promise.all([getAllOrganisationUnits(), getMentorRoles()]).then(() =>
      this.setState({
        isLoading: false,
      })
    );
  }

  getDateSubtitle = (menteeId, roleId) => {
    const { mentees } = this.props;
    const mentee = mentees[menteeId];
    const menteeRole = mentee.roles[roleId];
    const { mentorId, endDate, startDate } = menteeRole;
    if (mentorId === EMPTY_ID || !startDate) return null;
    return `${formatDate(startDate)} - ${
      endDate ? `${formatDate(endDate)}` : ''
    }`;
  };

  getGanttTasks = () => {
    const { mentees, lookupPerson, organisationUnits } = this.props;

    return Object.values(mentees).reduce((acc, mentee) => {
      const menteeTasks = Object.values(mentee.roles).map((menteeRole) => {
        const mentor = lookupPerson(menteeRole.mentorId);
        return {
          person: mentee.name,
          personInitials: mentee.initials,
          personEmployeeNumber: mentee.employeeNumber,
          organisationUnits: menteeRole.organisationUnits.map((id) => ({
            id,
            name: organisationUnits[id].name,
          })),
          canEdit: false,
          canClick: true,
          personId: mentee.id,
          mentor: mentor.name,
          mentorSuffix: mentor.suffix,
          showMentorError: mentor.showError,
          mentorId: menteeRole.mentor,
          mentorInitials: mentor.initials,
          mentorEmployeeNumber: mentor.employeeNumber,
          mentorIsWildcardPerson: mentor.isWildcardPerson,
          role: menteeRole.name,
          roleId: menteeRole.id,
          startDate: menteeRole.startDate
            ? dayjs(menteeRole.startDate)
            : dayjs().subtract(2, 'days'),
          endDate: menteeRole.endDate ? dayjs(menteeRole.endDate) : '',
          progress: menteeRole.completionPercentage,
        };
      });
      return [...acc, ...menteeTasks];
    }, []);
  };

  getSubtitle = ({ menteeId, roleId }) => {
    const { mentees } = this.props;

    const mentee = mentees[menteeId];
    if (mentee.roles[roleId].trainingTasks > 0) {
      return (
        <Text color="blue">
          {localeLookup('translations.You have')}{' '}
          {mentee.roles[roleId].trainingTasks}{' '}
          {mentee.roles[roleId].trainingTasks === 1
            ? localeLookup('translations.training task')
            : localeLookup('translations.training tasks')}
        </Text>
      );
    }
    return null;
  };

  getQueryParams = () => {
    const { location } = this.props;
    const queryParams = getQueryStringParams(location.search);
    this.setState({
      activeView: queryParams.view || 'list',
      timelineOptions: {
        sorting: queryParams.sorting || 'endDate',
        grouping: queryParams.grouping || 'none',
      },
    });
  };

  setQueryParams = () => {
    const { activeView, timelineOptions } = this.state;
    const { history } = this.props;
    const serialize = (obj) =>
      Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    history.push({
      search: serialize({ view: activeView, ...timelineOptions }),
    });
  };

  onChangeTimelineGrouping = (e) => {
    const { timelineOptions } = this.state;
    this.setState(
      {
        timelineOptions: {
          ...timelineOptions,
          grouping: e.target.value,
        },
      },
      this.setQueryParams
    );
  };

  onChangeTimelineSorting = (e) => {
    const { history } = this.props;
    const { timelineOptions } = this.state;
    this.setState(
      {
        timelineOptions: {
          ...timelineOptions,
          sorting: e.target.value,
        },
      },
      this.setQueryParams
    );
  };

  onChangeView = (view) => {
    const { history } = this.props;
    this.setState(
      {
        activeView: view.id,
      },
      this.setQueryParams
    );
  };

  onClickTimelineTask = ({ e, personId, roleId }) => {
    const { history, mentees } = this.props;
    const mentee = mentees[personId];
    const menteeRole = mentee.roles[roleId];
    const route = `/training/your programs/${roleId}?menteeId=${personId}`;
    history.push({
      pathname: route,
      state: {
        title: `${mentee.name} - ${menteeRole.name}`,
        backTitle: localeLookup('translations.Your programs'),
      },
    });
  };

  renderHeader = () => {
    const { isLoading, activeView, timelineOptions } = this.state;
    return (
      <Header
        leftComponent={
          activeView === 'timeline' ? (
            <Grid gridTemplateColumns="auto auto" columnGap="1rem">
              <Field
                margin="none"
                label={localeLookup('translations.Grouping')}
              >
                <Select
                  value={timelineOptions.grouping}
                  onChange={this.onChangeTimelineGrouping}
                  size="small"
                  options={[
                    {
                      title: localeLookup('translations.None'),
                      value: 'none',
                    },
                    {
                      title: localeLookup('translations.Organisation unit'),
                      value: 'organisationUnit',
                    },
                    {
                      title: localeLookup('translations.Person'),
                      value: 'person',
                    },
                    /* {
                      title: localeLookup('translations.Mentor'),
                      value: 'mentor',
                    }, */
                    {
                      title: localeLookup('translations.Role'),
                      value: 'role',
                    },
                  ]}
                />
              </Field>
              <Field margin="none" label={localeLookup('translations.Sorting')}>
                <Select
                  value={timelineOptions.sorting}
                  onChange={this.onChangeTimelineSorting}
                  size="small"
                  options={[
                    {
                      value: 'startDate',
                      title: localeLookup('translations.Start date'),
                    },
                    {
                      value: 'endDate',
                      title: localeLookup('translations.End date'),
                    },
                  ]}
                />
              </Field>
            </Grid>
          ) : null
        }
        rightComponent={
          <Tabs
            activeTab={activeView}
            tabWidth="100px"
            activeTabColor="green"
            tabColor="grey"
            rounded
            onChangeTab={this.onChangeView}
            tabs={[
              { name: localeLookup('translations.List'), id: 'list' },
              {
                name: localeLookup('translations.Timeline'),
                id: 'timeline',
              },
            ]}
          />
        }
      />
    );
  };

  renderRole = ({ role, mentee }) => {
    const hasMentor = role.mentor !== '';
    const route = `/training/your programs/${role.id}?menteeId=${mentee.id}`;
    return (
      <Link
        to={{
          pathname: route,
          state: {
            title: `${mentee.name} - ${role.name}`,
            backTitle: localeLookup('translations.Your programs'),
          },
        }}
        key={role.id}
      >
        <RoleCard
          competenceLevel={role.competenceLevel}
          title={role.name}
          clickable
          notifications={role.notifications}
          completionPercentage={role.completionPercentage}
          donutColor={hasMentor ? 'green' : 'grey'}
          subtitle={this.getDateSubtitle(mentee.id, role.id)}
          renderRight={
            <Icon
              className="role-card__arrow"
              color="grey"
              kind="chevron-right"
            />
          }
        />
      </Link>
    );
  };

  renderMenteeSection = (menteeId) => {
    const { mentees, lookupPerson } = this.props;
    const mentee = mentees[menteeId];
    const person = lookupPerson(menteeId);
    const titleTooltip = `${person.initials} ${
      person.employeeNumber ? `· ${person.employeeNumber}` : ''
    }`;
    return (
      <HorizontalSection
        key={menteeId}
        title={mentee.name}
        titleTooltip={titleTooltip}
        items={Object.values(mentee.roles)}
        renderItem={(role) => this.renderRole({ role, mentee })}
      />
    );
  };

  render() {
    const { mentees } = this.props;
    const { isLoading, activeView, timelineOptions } = this.state;
    const sortedMenteeIds = sortBy(Object.keys(mentees), [
      (a, b) => compareLocal(mentees[a]?.name, mentees[b]?.name),
    ]);

    if (isLoading && Object.keys(mentees).length === 0) return <LoadScreen />;
    return (
      <Page>
        <div className="programs">
          {this.renderHeader()}
          {activeView === 'list' ? (
            <MainArea defaultPadding backgroundColor="grey">
              {sortedMenteeIds.map(this.renderMenteeSection)}
            </MainArea>
          ) : (
            <GanttChart
              progressView="none"
              sorting={timelineOptions.sorting}
              grouping={timelineOptions.grouping}
              tasks={this.getGanttTasks()}
              onChangeTrainingProgram={this.getPersonData}
              onClickTask={this.onClickTimelineTask}
            />
          )}
          {Object.keys(mentees).length === 0 && (
            <EmptyState
              fullHeight
              title={localeLookup('translations.No tasks here!')}
              body={localeLookup('translations.You have no active programs')}
            />
          )}
        </div>
      </Page>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonLookup(Programs));
