/* eslint-disable no-use-before-define */
import {
  getFiltersService,
  getPersonsElementStatusService,
  getTaskPersonsElementStatusService,
} from '../services/statusTableService';
import { getKnowledgeElementService } from '../services/traineeService';

export const TASK_LIST_CATEGORIES_RECEIVED = 'TASK_LIST_CATEGORIES_RECEIVED';
export const taskListCategoriesReceived = (categories) => ({
  type: TASK_LIST_CATEGORIES_RECEIVED,
  categories,
});

export const TASK_LIST_KNOWLEDGE_AREAS_RECEIVED = 'TASK_LIST_KNOWLEDGE_AREAS_RECEIVED';
export const taskListknowledgeAreasReceived = (knowledgeAreas) => ({
  type: TASK_LIST_KNOWLEDGE_AREAS_RECEIVED,
  knowledgeAreas,
});
export const TASK_LIST_KNOWLEDGE_ELEMENTS_RECEIVED = 'TASK_LIST_KNOWLEDGE_ELEMENTS_RECEIVED';
export const taskListKnowledgeElementsReceived = (knowledgeElements) => ({
  type: TASK_LIST_KNOWLEDGE_ELEMENTS_RECEIVED,
  knowledgeElements,
});
export const TASK_LIST_ORGANISATION_UNITS_RECEIVED = 'TASK_LIST_ORGANISATION_UNITS_RECEIVED';
export const taskListOrganisationUnitsReceived = (organisationUnits) => ({
  type: TASK_LIST_ORGANISATION_UNITS_RECEIVED,
  organisationUnits,
});
export const TASK_LIST_PERSONS_RECEIVED = 'TASK_LIST_PERSONS_RECEIVED';
export const taskListPersonsReceived = (persons) => ({
  type: TASK_LIST_PERSONS_RECEIVED,
  persons,
});
export const TASK_LIST_PERSON_ELEMENT_STATUS_RECEIVED = 'TASK_LIST_PERSON_ELEMENT_STATUS_RECEIVED';
export const taskListPersonElementStatusReceived = ({
  menteeId,
  elementId,
  isCompleted,
  mediator,
  hasExpiration,
  daysUntilExpiration,
  completedAt,
  showNotification,
}) => ({
  type: TASK_LIST_PERSON_ELEMENT_STATUS_RECEIVED,
  menteeId,
  elementId,
  isCompleted,
  mediator,
  hasExpiration,
  daysUntilExpiration,
  completedAt,
  showNotification,
});
export const TASK_LIST_PERSONS_ELEMENT_STATUS_RECEIVED =
  'TASK_LIST_PERSONS_ELEMENT_STATUS_RECEIVED';
export const taskListPersonsElementStatusReceived = (persons) => ({
  type: TASK_LIST_PERSONS_ELEMENT_STATUS_RECEIVED,
  persons,
});
export const TASK_LIST_ROLES_RECEIVED = 'TASK_LIST_ROLES_RECEIVED';
export const taskListRolesReceived = (roles) => ({
  type: TASK_LIST_ROLES_RECEIVED,
  roles,
});
export const TASK_LIST_WILDCARD_PERSONS_RECEIVED = 'TASK_LIST_WILDCARD_PERSONS_RECEIVED';
export const taskListWildcardPersonsReceived = (wildcardPersons) => ({
  type: TASK_LIST_WILDCARD_PERSONS_RECEIVED,
  wildcardPersons,
});

export const getTaskListInitialData = () => (dispatch) =>
  getFiltersService()
    .then(({ data }) => {
      dispatch(taskListPersonsReceived(data.employees));
      dispatch(taskListKnowledgeElementsReceived(data.knowledgeElements));
      dispatch(taskListknowledgeAreasReceived(data.knowledgeAreas));
      dispatch(taskListRolesReceived(data.roles));
      dispatch(taskListOrganisationUnitsReceived(data.organisationUnits));
      dispatch(taskListCategoriesReceived(data.categories));
      dispatch(taskListWildcardPersonsReceived(data.wildcardPersons));
    })
    .then(() => dispatch(getPersonsElementStatus()))
    .catch((ex) => {
      console.error(ex);
    });

// Single person
export const getLimitedPersonsElementStatus = (persons) => (dispatch) =>
  getPersonsElementStatusService({ persons, personal: true })
    .then(({ data }) => {
      dispatch(taskListPersonsElementStatusReceived(data.persons));
    })
    .catch((ex) => {
      console.error(ex);
    });

// All
export const getPersonsElementStatus = () => (dispatch) =>
  getTaskPersonsElementStatusService()
    .then(({ data }) => {
      dispatch(taskListPersonsElementStatusReceived(data.persons));
    })
    .catch((ex) => {
      console.error(ex);
    });

export const updatePersonElement = ({ menteeId, elementId }) => (dispatch) =>
  getKnowledgeElementService(menteeId, elementId)
    .then(({ data }) => {
      const updatedElement = data.knowledgeElements[elementId];
      dispatch(
        taskListPersonElementStatusReceived({
          menteeId,
          elementId,
          isCompleted: updatedElement.isCompleted,
          completedAt: updatedElement.completedAt,
          mediator: updatedElement.mediator,
          hasExpiration: updatedElement.hasValidity,
          daysUntilExpiration: updatedElement.daysUntilExpiration,
          showNotification: updatedElement.showNotification,
        })
      );
    })
    .catch((ex) => {
      console.error(ex);
    });
