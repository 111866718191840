import React from 'react';
import ButtonIcon from './ButtonIcon';
import cx from 'classnames';

const ListHeader = ({
  title,
  icon,
  onIconClick,
  iconColor = 'green',
  className,
  showTopBorder,
}) => (
  <div
    className={cx(
      'list-header',
      { 'list-header--show-top-border': showTopBorder },
      className
    )}
  >
    <div className="list-header__left">
      <h2 className="list-header__title">{title}</h2>
    </div>
    {icon && (
      <ButtonIcon
        className="list-header__icon"
        iconColor={iconColor}
        size="large"
        icon={icon}
        onClick={onIconClick}
      />
    )}
  </div>
);

export default ListHeader;
