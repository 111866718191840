import { React, PureComponent } from 'react';
import localeLookup from '../config/locale';
import { EMPTY_ID } from '../constants';
import Aside from './Aside';
import EditorOrganisationTree from './EditorOrganisationTree';
import WithModals from './HOC/withModals';
import SideNavItem from './sideNav/SideNavItem';
import SideNavList from './sideNav/SideNavList';
import SideNavTitle from './sideNav/SideNavTitle';

class EditorAreasNav extends PureComponent {
  getOrganisationUnitCount = (unitId) => {
    const { areas, areaIdsWithAccess, activeSpaceId } = this.props;
    return areaIdsWithAccess.reduce((acc, id) => {
      const area = areas[id];
      const areaSpaceIds = [
        ...Object.keys(area.readOnlyInSpaces),
        ...Object.keys(area.editableInSpaces),
      ];
      if (!areaSpaceIds.includes(activeSpaceId)) return acc;
      if (area.organisationUnits.includes(unitId)) return acc + 1;
      return acc;
    }, 0);
  };

  getCategoryCount = (categoryId) => {
    const { areas, areaIdsWithAccess, activeSpaceId } = this.props;
    return areaIdsWithAccess.reduce((acc, id) => {
      const area = areas[id];
      const areaSpaceIds = [
        ...Object.keys(area.readOnlyInSpaces),
        ...Object.keys(area.editableInSpaces),
      ];
      if (!areaSpaceIds.includes(activeSpaceId)) return acc;
      if (area.category === categoryId) return acc + 1;
      return acc;
    }, 0);
  };

  onClickEditCategories = () => {
    const { showModal } = this.props;
    showModal('editCategories');
  };

  render() {
    const {
      areaCount,
      areas,
      categories,
      categoriesSortOrder,
      filter,
      onFilterClick,
      onFilterReset,
      organisationUnits,
      areaIdsWithAccess,
      activeSpaceId,
    } = this.props;
    const numAreasWithNoCategory = areaIdsWithAccess.reduce((acc, areaId) => {
      const area = areas[areaId];
      const areaSpaceIds = [
        ...Object.keys(area.readOnlyInSpaces),
        ...Object.keys(area.editableInSpaces),
      ];
      if (!areaSpaceIds.includes(activeSpaceId)) return acc;
      if (area.category === EMPTY_ID) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const numAreasWithNoConnections = areaIdsWithAccess.reduce(
      (acc, areaId) => {
        const area = areas[areaId];
        const areaSpaceIds = [
          ...Object.keys(area.readOnlyInSpaces),
          ...Object.keys(area.editableInSpaces),
        ];
        if (!areaSpaceIds.includes(activeSpaceId)) return acc;
        area.organisationUnits.length === 0 && acc++;
        return acc;
      },
      0
    );

    return (
      <Aside>
        <SideNavList>
          <SideNavItem
            onClick={onFilterReset}
            title={`${localeLookup(
              'translations.All knowledge areas'
            )} (${areaCount})`}
            active={filter.id === null && filter.type === null}
          />
        </SideNavList>
        <SideNavTitle
          title={localeLookup('translations.Categories')}
          icon="pencil"
          iconColor="green"
          onIconClick={this.onClickEditCategories}
        />

        <SideNavList>
          <SideNavItem
            title={`${localeLookup(
              'translations.No category'
            )} (${numAreasWithNoCategory})`}
            active={EMPTY_ID === filter.id && filter.type === 'CATEGORY'}
            onClick={() => {
              onFilterClick(
                'CATEGORY',
                EMPTY_ID,
                localeLookup('translations.No category')
              );
            }}
          />
          {categoriesSortOrder &&
            categoriesSortOrder.map((id) => {
              const category = categories[id];
              return (
                <SideNavItem
                  key={id}
                  title={`${category} (${this.getCategoryCount(id)})`}
                  active={id === filter.id}
                  onClick={() => {
                    onFilterClick('CATEGORY', id, category);
                  }}
                />
              );
            })}
        </SideNavList>
        <SideNavTitle title={localeLookup('translations.Organisation units')} />

        <SideNavList>
          <SideNavItem
            title={`${localeLookup(
              'translations.Not connected'
            )} (${numAreasWithNoConnections})`}
            active={filter.id === EMPTY_ID && filter.type === 'UNIT'}
            onClick={() => {
              onFilterClick(
                'UNIT',
                EMPTY_ID,
                localeLookup('translations.Not connected')
              );
            }}
          />
          {organisationUnits && (
            <EditorOrganisationTree
              organisationUnit={
                organisationUnits[Object.keys(organisationUnits)[0]]
              }
              allOrganisationUnits={organisationUnits}
              onUnitSelect={(id, organisationName) => {
                onFilterClick('UNIT', id, organisationName);
              }}
              getOrganisationUnitCount={this.getOrganisationUnitCount}
              selectedUnitId={filter.id}
            />
          )}
        </SideNavList>
      </Aside>
    );
  }
}

EditorAreasNav.propTypes = {};

export default WithModals(EditorAreasNav);
