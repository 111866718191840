import {
  axiosRequestDELETE,
  axiosRequestGET,
  axiosRequestPOST,
  axiosRequestPUT,
} from './serviceConfig';

export const getAllPersonsService = () => axiosRequestGET('/api/persons/all');

export const fetchPersonsOverview = () => {
  const url = `/api/persons`;
  return axiosRequestGET(url);
};

export const fetchPersonsDetails = (personId) => {
  const url = `/api/persons/${personId}`;
  return axiosRequestGET(url);
};

export const deleteDemoUsersService = () => {
  const url = '/api/persons/demousers/delete';
  return axiosRequestDELETE(url);
};

export const deletePersonService = (personId) => {
  const url = `/api/persons/${personId}/delete`;
  return axiosRequestDELETE(url);
};

export const createPersonService = (updateData) => {
  const url = `/api/persons/create`;
  return axiosRequestPOST(url, updateData);
};

export const activatePersonService = (personId) => {
  const url = `/api/persons/${personId}/activate`;
  return axiosRequestPUT(url);
};

export const deactivatePersonService = (personId) => {
  const url = `/api/persons/${personId}/deactivate`;
  return axiosRequestPUT(url);
};

export const getPersonsWithRoleService = (roleId) => {
  const url = `/api/persons/with-role/${roleId}`;
  return axiosRequestGET(url);
};

export const updatePersonAdminStatus = (personId, updateData) => {
  const url = `/api/persons/${personId}/update/admin`;
  return axiosRequestPUT(url, updateData);
};

export const updatePersonOrganisationUnits = (personId, data) => {
  const url = `/api/persons/update/${personId}/organisation-units`;
  return axiosRequestPUT(url, data);
};

export const updatePersonPassword = (personId, updateData) => {
  const url = `/api/persons/update/${personId}/password`;
  return axiosRequestPUT(url, updateData);
};

export const validateEmailService = (data) => {
  return axiosRequestPUT('/api/persons/create/with-login/validate-email', data);
};

export const createUserWithoutLoginService = (data) => {
  return axiosRequestPOST('/api/persons/create/without-login', data);
};

export const createUserWithLoginService = (data) => {
  return axiosRequestPOST('/api/persons/create/with-login', data);
};

export const createExternalUserService = (data) => {
  return axiosRequestPOST('/api/persons/create/with-external-login', data);
};

export const changeLoginToNoLoginService = (personId, data) => {
  return axiosRequestPUT(`/api/persons/update/${personId}/to-no-login`, data);
};
export const changeLoginToInternalLoginService = (personId, data) => {
  return axiosRequestPUT(
    `/api/persons/update/${personId}/to-champ-login`,
    data
  );
};
export const changeLoginToExternalLoginService = (personId, data) => {
  return axiosRequestPUT(
    `/api/persons/update/${personId}/to-external-login`,
    data
  );
};

export const changeNameService = (personId, data) => {
  return axiosRequestPUT(`/api/persons/update/${personId}/name`, data);
};

export const changeInitialsService = (personId, data) => {
  return axiosRequestPUT(`/api/persons/update/${personId}/initials`, data);
};

export const changeEmployeeNumberService = (
  personId,
  { employeeNumber = '' }
) => {
  return axiosRequestPUT(`/api/persons/update/${personId}/employee-number`, {
    employeeNumber,
  });
};
export const validateEmployeeNumberService = (employeeNumber = '') => {
  return axiosRequestPUT(`/api/persons/validate/employee-number`, {
    employeeNumber,
  });
};

export const changeLanguageService = (personId, data) => {
  return axiosRequestPUT(`/api/persons/update/${personId}/language`, data);
};

export const getRoleLevelChangeConflictsService = (personId, data) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/assign/conflicts`, data);

export const setPersonRoleAsTrainingService = ({ personId, roleId, data }) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/${roleId}/training`, data);

export const editPersonRoleTrainingService = (personId, roleId, data) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/${roleId}/training`, data);

export const setPersonRoleAsExperiencedService = ({ personId, roleId }) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/${roleId}/experienced`);

export const setPersonRoleAsQualifiedService = ({ personId, roleId }) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/${roleId}/qualified`);

export const removeRoleFromPersonService = ({ personId, roleId }) =>
  axiosRequestDELETE(`/api/persons/${personId}/roles/${roleId}/`);

export const addRolesToPersonService = (personId, unitId, data) =>
  axiosRequestPUT(`/api/persons/${personId}/unit/${unitId}/roles/`, data);

export const changePersonRoleRelevanceService = ({
  personId = '',
  roleId = '',
  notRelevantInOrganisationUnitIds = [],
}) =>
  axiosRequestPUT(`/api/persons/${personId}/roles/${roleId}/relevance`, {
    units: notRelevantInOrganisationUnitIds,
  });

export const togglePersonRoleAreaExperiencedService = (
  personId,
  roleId,
  areaId
) =>
  axiosRequestPUT(
    `/api/persons/${personId}/roles/${roleId}/knowledge/${areaId}/experienced`
  );

export const togglePersonRoleAreaLinkService = (personId, roleId, areaId) =>
  axiosRequestPUT(
    `/api/persons/${personId}/roles/${roleId}/knowledge/${areaId}/link`
  );

export const getWildcardPersonsService = () =>
  axiosRequestGET('/api/persons/wildcards');

export const addPersonPermissionsService = (personId, permissions = []) =>
  axiosRequestPUT(`/api/persons/${personId}/permissions/add`, { permissions });

export const removePersonPermissionsService = (personId, permissions = []) =>
  axiosRequestPUT(`/api/persons/${personId}/permissions/remove`, {
    permissions,
  });

export const getAccessLevels = () =>
  axiosRequestGET('/api/persons/access-levels');

export const getPersonsWithAccessLevelsService = (personIds = []) =>
  axiosRequestPOST('/api/persons/access-levels', { persons: personIds });

export const validatePasswordService = (password = '') =>
  axiosRequestPUT(`/api/persons/validate/password`, {
    password,
  });

export const assignRolesService = ({
  roleLevel,
  personIds,
  roleIds,
  createOnly,
  data,
}) =>
  axiosRequestPUT(`/api/persons/roles`, {
    roleLevel,
    persons: personIds,
    roles: roleIds,
    createOnly,
    data,
  });

export const getBulkRoleAssignConflictsService = ({
  roleLevel,
  personIds,
  roleIds,
  createOnly,
  data,
}) =>
  axiosRequestPOST(`/api/persons/roles/conflict`, {
    roleLevel,
    persons: personIds,
    roles: roleIds,
    createOnly,
    data,
  });
