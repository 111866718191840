import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withAccessControl from './withAccessControl';
import WithModals from './withModals';
import localeLookup from '../../config/locale';
import {
  duplicateOrganisationUnit,
  requestOrganisationOverview,
  updateOrgnisationUnitState,
} from '../../actions/organisationActions';
import {
  deleteOrganisationUnitService,
  updateOrganisationUnitNameService,
} from '../../services/organisationService';
import { ORGANISATION_UNIT_STATES } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  const { persons } = state;
  return { persons, wrappedComponentProps: ownProps };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      duplicateOrganisationUnit,
      updateOrgnisationUnitState,
      requestOrganisationOverview,
    },
    dispatch
  ),
});

const WithOrganisationUnitActions = (WrappedComponent) => {
  class WithOrganisationUnitActionsComponent extends React.Component {
    showChangeOrganisationUnitNameModal = ({ unitId, name, onChanged }) => {
      const { showModal, hideModal, requestOrganisationOverview } = this.props;
      showModal('nameModal', {
        title: localeLookup('translations.Rename'),
        defaultValue: name,
        confirmButtonText: localeLookup('translations.Rename'),
        subtitle: name,
        onConfirm: ({ name }) => {
          hideModal();
          updateOrganisationUnitNameService(unitId, { name }).then(() => {
            onChanged ? onChanged() : requestOrganisationOverview();
          });
        },
      });
    };

    showAssignRolesModal = ({ unitId, unitName, onConfirm }) => {
      const { showModal } = this.props;
      showModal('assignRoles', {
        title: localeLookup('translations.Assign roles'),
        subtitle: `${unitName}`,
        confirmButtonText: localeLookup('translations.Assign'),
        fullWidth: true,
        maxWidth: '700px',
        stopPropagation: false,
        organisationUnitId: unitId,
        initialValues: {
          mentorId: '',
          personId: '',
          roleId: '',
          endDate: null,
          startDate: new Date(),
          state: 'Active',
        },
        onConfirm: () => {
          onConfirm?.();
        },
      });
    };

    showChangeOrganisationUnitStateModal = ({
      unitId,
      unitName,
      currentState,
    }) => {
      const { showModal, updateOrgnisationUnitState, hideModal } = this.props;
      showModal('changeOrganisationUnitState', {
        fullWidth: true,
        maxWidth: '500px',
        title: localeLookup('translations.Visibility'),
        subtitle: unitName,
        infoText: localeLookup(
          "translations.Limited visibility means that persons' roles will only be visible to administrators and organisation administrators on that and underlying organisation units. The person himself, mentors and responsibles will not be able to see roles, levels and training tasks"
        ),
        disabled: currentState === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE,
        body:
          currentState === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE
            ? localeLookup(
                'translations.Changing visibility for this organisation unit is disabled because a parent organisation unit has limited visibility'
              )
            : null,
        currentState,
        onConfirm: (values) => {
          hideModal();
          updateOrgnisationUnitState({ unitId, state: values.state });
        },
      });
    };

    showDeleteOrganisationUnitModal = ({
      unit,
      organisationUnits,
      roles,
      onDeleted,
    }) => {
      const { showModal, hideModal, requestOrganisationOverview } = this.props;

      const getAffectedRoleAndUnitIds = (orgUnit, object) => {
        let unitAndRolesAffected = {
          roleIds: [...object.roleIds, ...orgUnit.roles],
          unitIds: [...object.unitIds, ...orgUnit.descendants],
        };
        if (orgUnit.descendants.length) {
          orgUnit.descendants.map((unitId) => {
            unitAndRolesAffected = getAffectedRoleAndUnitIds(
              organisationUnits[unitId],
              unitAndRolesAffected
            );
          });
        }
        return unitAndRolesAffected;
      };

      const unitObject = getAffectedRoleAndUnitIds(unit, {
        roleIds: [],
        unitIds: [],
      });

      const rolesString = unitObject.roleIds
        .map((roleId) => roles[roleId].name)
        .join(', ');

      const unitsString = unitObject.unitIds
        .map((unitId) => organisationUnits[unitId].organisationName)
        .join(', ');

      if (unitsString.length === 0 && rolesString.length === 0) {
        showModal('confirmation', {
          title: `${localeLookup(
            'translations.Are you sure you want to delete'
          )} ${unit.organisationName}?`,
          fullWidth: true,
          maxWidth: '500px',
          body: localeLookup(
            'translations.Deletion of this organisation unit is permanent'
          ),
          confirmButtonText: localeLookup('translations.Delete'),
          confirmButtonType: 'alert',
          onCancel: this.showEditOrganisationUnitsModal,
          onConfirm: () => {
            deleteOrganisationUnitService(unit.id).then(() => {
              requestOrganisationOverview().then(() => {
                onDeleted?.();
              });
            });
          },
        });
      } else {
        showModal('information', {
          title: `${localeLookup('translations.Can not delete')} ${
            unit.organisationName
          }`,
          fullWidth: true,
          maxWidth: '500px',
          onClose: this.showEditOrganisationUnitsModal,
          body: (
            <>
              {(unitsString.length > 0 || rolesString.length > 0) && (
                <p className="confirmation-modal__body-text">
                  {localeLookup(
                    'translations.To delete this organisation unit the following underlying content must first be deleted or moved'
                  )}
                </p>
              )}
              {unitsString.length > 0 && (
                <div>
                  <br />
                  <p className="confirmation-modal__body-text">
                    {localeLookup('translations.Organisation units')}
                  </p>
                  <p className="confirmation-modal__body-text-small">
                    {unitsString}
                  </p>
                </div>
              )}
              {rolesString.length > 0 && (
                <div>
                  <br />
                  <p className="confirmation-modal__body-text">
                    {localeLookup('translations.Roles')}
                  </p>
                  <p className="confirmation-modal__body-text-small">
                    {rolesString}
                  </p>
                </div>
              )}
            </>
          ),
        });
      }
    };

    showDuplicateOrganisationUnitModal = ({ unitId, unitName }) => {
      const { showModal, duplicateOrganisationUnit, hideModal } = this.props;
      showModal('duplicateOrganisationUnitModal', {
        fullWidth: true,
        maxWidth: '400px',
        title: localeLookup('translations.Duplicate'),
        subtitle: unitName,
        unitId: unitId,
        unitName,
        onConfirm: ({ name, include }) => {
          duplicateOrganisationUnit({ unitId, name, include }).then(() => {
            hideModal();
          });
        },
      });
    };

    render() {
      return (
        <WrappedComponent
          organisationUnitActions={{
            showChangeOrganisationUnitNameModal:
              this.showChangeOrganisationUnitNameModal,
            showDeleteOrganisationUnitModal:
              this.showDeleteOrganisationUnitModal,
            showDuplicateOrganisationUnitModal:
              this.showDuplicateOrganisationUnitModal,
            showChangeOrganisationUnitStateModal:
              this.showChangeOrganisationUnitStateModal,
            showAssignRolesModal: this.showAssignRolesModal,
          }}
          {...this.props.wrappedComponentProps}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModals(withAccessControl(WithOrganisationUnitActionsComponent)));
};

export default WithOrganisationUnitActions;
