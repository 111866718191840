import React from 'react';
import localeLookup from '../config/locale';

export const TrainingElementLegend = () => {
  return (
    <div className="training-element-legend">
      <div className="training-element-legend__item training-element-legend__item--required">
        <div className="training-element-legend__item-figure" />
        <p className="training-element-legend__item-text">
          {localeLookup('translations.Critical')}
        </p>
      </div>
      <div className="training-element-legend__item training-element-legend__item--required-completed">
        <div className="training-element-legend__item-figure" />
        <p className="training-element-legend__item-text">
          {localeLookup('translations.Critical and completed')}
        </p>
      </div>
    </div>
  );
};
