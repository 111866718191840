/* eslint-disable import/prefer-default-export */
export const getElementContentIcon = ({ description, files }) => {
  const descriptionLength = description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
  if (files && files.length > 0) {
    const file = files[0];
    if (file.type === 'Image') {
      return 'picture';
    } else if (file.type === 'Video') {
      return 'camera';
    }
    return 'paperclip';
  } else if (descriptionLength > 0) {
    return 'document';
  }
  return null;
};
