import React from 'react';
import { compareLocal, sortBy } from '../utils/helpers';

const RenderRecursive = ({
  render,
  rootId,
  childrenProp = 'descendants',
  isChild = false,
  nodes,
  childLeftMargin = '1rem',
}) => {
  return (
    <div style={{ marginLeft: isChild ? childLeftMargin : 0 }}>
      {render({ isChild, node: nodes[rootId] })}
      {nodes[rootId][childrenProp] &&
        nodes[rootId][childrenProp].length > 0 &&
        sortBy(nodes[rootId][childrenProp], [
          (a, b) => {
            return compareLocal(nodes[a].name, nodes[b].name);
          },
        ]).map((id) => {
          return (
            <RenderRecursive
              key={id}
              render={render}
              rootId={id}
              nodes={nodes}
              childLeftMargin={childLeftMargin}
              isChild
              childrenProp={childrenProp}
            />
          );
        })}
    </div>
  );
};

export default RenderRecursive;
