import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const registerTrainingService = ({
  personId,
  elementId,
  type,
  signature = null,
  note,
  validFromDate,
}) =>
  axiosRequestPUT('/api/training/register', {
    menteeId: personId,
    knowledgeElementId: elementId,
    registrationType: type,
    signature,
    note,
    validFromDate,
  });

export const registerTrainingOnBehalfService = ({
  menteeId,
  signeeId,
  elementId,
  signature = {},
}) =>
  axiosRequestPUT('/api/training/register/on-behalf', {
    menteeId,
    signeeId,
    knowledgeElementId: elementId,
    signature,
  });

export const getApproversService = ({ menteeId, elementId }) =>
  axiosRequestGET(`/api/training/${menteeId}/knowledge/${elementId}/approvers`);

/**
"Operations": [
    { 
        "MenteeId": "Guid",
        "Elements": ["Guid", ...]
    }
]
*/
export const registerBulkTrainingService = ({
  type,
  validFromDate,
  note,
  operations,
  signature,
  sessionId,
  onBehalfOfOther,
  signeeId,
}) =>
  axiosRequestPUT('/api/training/register/bulk', {
    registrationType: type,
    validFromDate,
    note,
    operations,
    signature,
    sessionId,
    onBehalfOfOther,
    signeeId,
  });
