import React from 'react';
import ButtonIcon from '../ButtonIcon';
import ModalStepIndicator from './ModalStepIndicator';

const ModalHeader = ({ title, subtitle, onClose, steps, activeStepIndex, dismissable = true }) => (
  <div className="modal-content__header">
    <div className="modal-content__header-text">
      <h1 className="modal-content__header-title">{title}</h1>
      {subtitle && <h2 className="modal-content__header-subtitle">{subtitle}</h2>}
      <ModalStepIndicator steps={steps} activeStepIndex={activeStepIndex}></ModalStepIndicator>
    </div>
    {onClose && dismissable && <ButtonIcon className="modal-content__header-closer" icon="cross" onClick={onClose} />}
  </div>
);

export default ModalHeader;
