import React from 'react';
import Button from '../../components/Button';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import localeLookup from '../../config/locale';
import cx from 'classnames';
const ElementStatusStatusFilter = ({ filterState, isDefault, onClickReset, onToggleFilter }) => {
  const { noCompletion, validCompletion, validCompletionExpiring, invalidCompletionExpired, invalidCompletionOngoing } = filterState;
  const sections = [
    {
      title: localeLookup('translations.Completion status'),
      items: [
        {
          name: localeLookup('translations.No completion'),
          selected: noCompletion,
          stateKey: 'noCompletion',
          color: 'white',
        },
        {
          name: localeLookup('translations.Unfinished completion'),
          selected: invalidCompletionOngoing,
          stateKey: 'invalidCompletionOngoing',
          color: 'light-green',
        },
        {
          name: localeLookup('translations.Valid completion'),
          selected: validCompletion,
          stateKey: 'validCompletion',
          color: 'green',
        },
        {
          name: localeLookup('translations.Valid & expires soon'),
          selected: validCompletionExpiring,
          stateKey: 'validCompletionExpiring',
          color: 'yellow',
        },
        {
          name: localeLookup('translations.Invalid completion'),
          selected: invalidCompletionExpired,
          stateKey: 'invalidCompletionExpired',
          color: 'red',
        },
      ],
    },
  ];
  const renderItem = (item) => (
    <SideNavItem
      small
      element="div"
      leftComponent={item.color && <div className={cx('status-table__sidebar-bullet', `status-table__sidebar-bullet--color-${item.color}`)} />}
      key={`setting-${item.name}`}
      disabled={item.disabled}
      showHoverTitle
      title={item.name}
      tooltip={item.tooltip}
      onClick={() => onToggleFilter(item.stateKey)}
      stopRightComponentClickPropagation
      rightComponent={<SwitchCheckbox disabled={item.disabled} onChange={() => onToggleFilter(item.stateKey)} isChecked={item.selected} id={`setting-${item.stateKey}`} />}
    />
  );
  const renderSection = (section, index) => {
    if (section.groups) {
      return (
        <React.Fragment key={index}>
          <SideNavTitle size="small" title={section.title} />
          {section.groups.map((group, index) => (
            <React.Fragment key={index}>
              <SideNavTitle subtitle title={group.groupTitle} />
              {group.items.map(renderItem)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <SideNavTitle size="small" title={section.title} />
        {section.items.map(renderItem)}
      </React.Fragment>
    );
  };
  return (
    <>
      {sections.map(renderSection)}
      <Button kind="alert" disabled={isDefault} onClick={onClickReset} className="status-table__sidebar-reset-button">
        {localeLookup('translations.Reset')}
      </Button>
    </>
  );
};

export default ElementStatusStatusFilter;
