import {
  axiosRequestDELETE,
  axiosRequestGET,
  axiosRequestPOST,
  axiosRequestPUT,
} from './serviceConfig';

export const getAllGroupsService = () => axiosRequestGET('/api/groups');

export const getGroupService = (id) => axiosRequestGET(`/api/groups/${id}`);

export const deleteGroupService = (id) =>
  axiosRequestDELETE(`/api/groups/${id}`);

export const createGroupService = ({
  name = '',
  shortName = '',
  assignableAs = [],
  members = [],
  administrators = [],
}) =>
  axiosRequestPOST('/api/groups', {
    name,
    shortName,
    assignableAs,
    members,
    administrators,
  });

export const updateGroupNameService = ({ id = '', name = '' }) =>
  axiosRequestPUT(`/api/groups/${id}/name`, {
    name,
  });

export const updateGroupShortNameService = ({ id = '', shortName = '' }) =>
  axiosRequestPUT(`/api/groups/${id}/short-name`, {
    shortName,
  });

export const updateGroupAssignableAsService = ({
  id = '',
  assignableAs = [],
}) =>
  axiosRequestPUT(`/api/groups/${id}/assignable-as`, {
    assignableAs,
  });

export const updateGroupMembersService = ({ id = '', members = '' }) =>
  axiosRequestPUT(`/api/groups/${id}/members`, {
    members,
  });

export const updateGroupAdministratorsService = (
  groupIds = [],
  administratorIds = [],
  context = 'group' /* or 'person' */
) =>
  axiosRequestPUT(`/api/groups/administrators`, {
    administrators: administratorIds,
    groups: groupIds,
    context,
  });
