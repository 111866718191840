import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { createSpaceService } from '../services/contentService';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import withAccessControl from './HOC/withAccessControl';
import ModalBody from './modal/ModalBody';

const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
  persons: state.persons,
  spaces: state.spaces.spaces,
  activeSpaceId: state.spaces.activeSpaceId,
});

const mapDispatchToProps = {};

class CreateSpaceForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  onSubmit = (values) => {
    const { onCreated } = this.props;
    this.setState({ isSubmitting: true });
    createSpaceService(values.name)
      .then(({ data: id }) => {
        onCreated(id);
      })
      .catch(() => {});
  };

  render() {
    const { isSubmitting } = this.state;
    const { persons, renderFooter, initialName } = this.props;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
    });
    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{ name: initialName, contentAdministrators: [] }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <>
            <ModalBody>
              <form
                className="create-space-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  required
                  autoFocus
                  placeholder={localeLookup('translations.Name')}
                  name="name"
                  id="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.name && errors.name}
                  value={values.name}
                  label={localeLookup('translations.Name')}
                />
                {/* <Field label={localeLookup('translations.Content administrators')}>
                  <MultiSelect
                    onChange={(values) => {
                      setFieldValue(
                        'contentAdministrators',
                        values.map((value) => value.value)
                      );
                    }}
                    name="contentAdministrators"
                    options={adminOptions}
                    placeholder={localeLookup('translations.Select content administrators')}
                  />
                </Field> */}
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(withAccessControl(CreateSpaceForm));
