import React, { Component } from 'react';
import ProgressBar from '../ProgressBar';
import Icon from '../Icon';

import { truncateFilename } from '../../utils/helpers';
import localeLookup from '../../config/locale';
class Video extends Component {
  constructor() {
    super();
    this.getStatusMessage = this.getStatusMessage.bind(this);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.isProcessing) {
      this.props.onGetProgress();
      this.intervalId = setInterval(this.props.onGetProgress, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isProcessing) {
      clearInterval(this.intervalId);
    }
  }

  getStatusMessage() {
    const { processPercentage } = this.props;
    if (!processPercentage || processPercentage === 0) {
      return localeLookup('translations.Starting conversion');
    } else if (processPercentage === 100) {
      return localeLookup('translations.Finishing conversion');
    }
    return localeLookup('translations.Converting video');
  }

  render() {
    const { url, isProcessing, fileName, processPercentage, processMinutesRemaining, onCancelProcess } = this.props;
    if (isProcessing) {
      return (
        <div className="file-upload-box file-upload-box--has-file">
          <div className="file-upload-box__file">
            <div className="file-upload-box__file-icon-wrapper">
              <Icon className="file-upload-box__file-icon" kind="camera" />
            </div>
            <div className="file-upload-box__file-info">
              <p className="file-upload-box__file-name">
                <span>{truncateFilename(fileName)}</span>
                <span className="file-upload-box__file-status">{this.getStatusMessage()}...</span>
              </p>
              <ProgressBar percentage={processPercentage || 0} />
            </div>
            <button onClick={onCancelProcess} className="ui-btn ui-btn-alert ui-small">
              {localeLookup('translations.Cancel')}
            </button>
          </div>
          <div className="file-upload-box__info">
            <p className="file-upload-box__info-message">{localeLookup('translations.You can now close this window - conversion will continue in background')}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="video">
        <video className="video__video" controls controlsList="nodownload" disablepictureinpicture="true">
          <source src={url} type="video/mp4; codecs=avc1.42E01E,mp4a.40.2" />
          Your browser does not support HTML5 video.
        </video>
        {isProcessing && (
          <div className="video__message">
            <p className="video__message-text">Processing video...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Video;
