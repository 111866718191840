import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import React, { Component } from 'react';
import ButtonIcon from './ButtonIcon';

const motion = {
  div: createDomMotionComponent('section'),
};


class Overlay extends Component {
  constructor() {
    super();
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen) {
      //document.addEventListener('click', this.onDocumentClick);
      document.addEventListener('keyup', this.onKeyPress);
    } else if (!this.props.isOpen) {
      //document.removeEventListener('click', this.onDocumentClick);
      document.removeEventListener('keyup', this.onKeyPress);
    }
  }

  onKeyPress(e) {
    const { onClose, isOpen } = this.props;
    if (!isOpen) return;
    if (e.key === 'Escape') onClose();
  }

  //TODO: Find a solution that works with modals
  onDocumentClick(event) {
    const { isOpen, onClose } = this.props;
    if (!isOpen) return;
    const component = this.componentRef;
    const targetIsComponent = event.target === component;
    const componentContainsTarget = component.contains(event.target);
    if (!targetIsComponent && !componentContainsTarget) {
      onClose();
    }
  }

  render() {
    const { children, isOpen, title, onClose, subtitle } = this.props;

    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="overlay"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: { y: 0 },
              hidden: { y: 1000 },
            }}
            transition={{ duration: 0.15 }}
            ref={(c) => (this.componentRef = c)}
          >
            <div className="overlay__header">
              <div className="overlay__header-left">
                <div className="overlay__header-title">{title}</div>
                {subtitle && <div className="overlay__header-subtitle">{subtitle}</div>}
              </div>
              <ButtonIcon color="green" round className="overlay__close" icon="cross" onClick={onClose} />
            </div>
            <div className="overlay__body">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

export default Overlay;
