export const initiateSupport = () => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = '0b592184-338f-45b3-90cf-edf3a2097f61';
  (function () {
    const d = document;
    const s = d.createElement('script');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
};

export const setSupportUserProperties = ({ email, nickname, company }) => {
  window.$crisp.push(['set', 'user:email', [email]]);
  window.$crisp.push(['set', 'user:nickname', [nickname]]);
  window.$crisp.push(['set', 'user:company', [company]]);
};

export const resetSupportSession = () => {
  window.$crisp.push(['do', 'session:reset']);
};
