import React from 'react';
import cx from 'classnames';

const Page = ({ children, fullScreen, className }) => {
  const modifierClasses = cx({
    'page--fullscreen': fullScreen,
  });
  return <div className={cx('page', modifierClasses, className)}>{children}</div>;
};

export default Page;
