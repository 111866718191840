import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS } from '../constants';
import { compareLocal, sortBy } from '../utils/helpers';
import AreaElementConnectionCount from './AreaElementConnectionCount';
import Aside from './Aside';
import withAccessControl from './HOC/withAccessControl';
import Icon from './Icon';
import OrganisationUnitsText from './OrganisationUnitsText';
import SideNavItem from './sideNav/SideNavItem';
import SideNavList from './sideNav/SideNavList';
import SideNavText from './sideNav/SideNavText';
import SideNavTitle from './sideNav/SideNavTitle';

const mapStateToProps = (state) => {
  return {
    accessibleRoles: state.editor.accessibleRoles,
  };
};

class KnowledgeEditorNav extends PureComponent {
  getRoleElementConnections = (id) => {
    const { knowledgeElements, allRoles } = this.props;
    const role = allRoles[id];
    return knowledgeElements.reduce(
      (acc, id) => {
        if (role.elements?.includes(id)) {
          return {
            ...acc,
            connected: acc.connected + 1,
          };
        }
        return acc;
      },
      {
        total: Object.keys(knowledgeElements).length,
        connected: 0,
      }
    );
  };

  renderRole = (role) => {
    const { organisationUnits, selectedId, onSelect, hasAccessToRole } =
      this.props;
    if (!role) return null;
    const { id } = role;
    const hasAccess = hasAccessToRole(id);
    const connectionCount = this.getRoleElementConnections(id);
    return (
      <SideNavItem
        title={role.name}
        subtitle={
          <OrganisationUnitsText organisationUnitIds={role.organisationUnits} />
        }
        active={selectedId === id}
        opaque={!hasAccess}
        onClick={() => {
          onSelect('role', id);
        }}
        rightComponent={
          <AreaElementConnectionCount
            total={connectionCount.total}
            connected={connectionCount.connected}
          />
        }
      />
    );
  };

  render() {
    const {
      activeView,
      allRoles,
      hasAccess,
      attachedAsAdditionalToRoles,
      onAddRoleClick,
      onAddAsAddtionalToRoleClick,
      onSelect,
      roles,
      showDashboard,
      accessibleRoles,
    } = this.props;
    const canConnectRoles =
      hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]) || accessibleRoles.length > 0;
    return (
      <Aside wide>
        <SideNavList>
          <SideNavItem
            title={localeLookup('translations.Content')}
            active={activeView === 'content'}
            onClick={() => {
              onSelect('content');
            }}
            leftComponent={<Icon color="green" kind="list3" />}
          />
          <SideNavItem
            title={localeLookup('translations.Area settings')}
            active={activeView === 'settings'}
            onClick={() => {
              onSelect('settings');
            }}
            leftComponent={<Icon color="green" kind="cog" />}
          />
          {showDashboard && (
            <SideNavItem
              title={localeLookup('translations.Status')}
              active={activeView === 'status'}
              onClick={() => {
                onSelect('status');
              }}
              leftComponent={<Icon color="green" kind="users" />}
            />
          )}
        </SideNavList>
        {roles && (
          <div>
            <SideNavTitle
              title={localeLookup(
                'translations.Appears as mandatory knowledge'
              )}
              icon="plus-circle"
              iconColor="green"
              onIconClick={canConnectRoles ? onAddRoleClick : undefined}
            />
            <SideNavList>
              {sortBy(roles, [
                (a, b) => {
                  const aName = allRoles[a]?.name;
                  const bName = allRoles[b]?.name;
                  return compareLocal(aName, bName);
                },
              ]).map((id) => {
                const role = allRoles[id];
                return this.renderRole(role);
              })}
            </SideNavList>
            {roles.length === 0 && (
              <SideNavText>
                {localeLookup(
                  'translations.Knowledge area does not appear in any roles'
                )}
              </SideNavText>
            )}
          </div>
        )}
        {attachedAsAdditionalToRoles && (
          <div>
            <SideNavTitle
              title={localeLookup(
                'translations.Appears as additional knowledge'
              )}
              icon="plus-circle"
              iconColor="green"
              onIconClick={
                canConnectRoles ? onAddAsAddtionalToRoleClick : undefined
              }
            />
            <SideNavList>
              {sortBy(attachedAsAdditionalToRoles, [
                (a, b) => {
                  const aName = allRoles[a]?.name;
                  const bName = allRoles[b]?.name;
                  return compareLocal(aName, bName);
                },
              ]).map((id) => {
                const role = allRoles[id];
                return this.renderRole(role);
              })}
            </SideNavList>
            {attachedAsAdditionalToRoles.length === 0 && (
              <SideNavText>
                {localeLookup(
                  'translations.Knowledge area does not appear as additional knowledge in any roles'
                )}
              </SideNavText>
            )}
          </div>
        )}
      </Aside>
    );
  }
}

KnowledgeEditorNav.propTypes = {};

export default connect(mapStateToProps)(withAccessControl(KnowledgeEditorNav));
