/* eslint-disable no-case-declarations */
import {
  getAllElementsService,
  queryElementsService,
} from '../services/contentService';

// Actions
const LOAD_ALL = 'elements/LOAD_ALL';
const LOAD_ELEMENTS = 'elements/LOAD_ELEMENTS';
const REMOVE_ELEMENT = 'elements/REMOVE_ELEMENT';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        ...action.elements,
      };
    case LOAD_ELEMENTS:
      return { ...state, ...action.elements };
    case REMOVE_ELEMENT:
      const { [action.id]: value, ...withoutElement } = state;
      return withoutElement;
    default:
      return state;
  }
};

export const getAllElements = () => (dispatch) =>
  getAllElementsService().then(({ data }) => {
    dispatch({ type: LOAD_ALL, elements: data });
  });

export const queryElements = (elementIds) => (dispatch) =>
  queryElementsService(elementIds).then(({ data }) => {
    dispatch({ type: LOAD_ELEMENTS, elements: data });
    return data;
  });

export const removeElement = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ELEMENT, id });
};

// Selectors
export const selectElement = (state, id) => state.elements[id];
