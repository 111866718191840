import React from 'react';
import cx from 'classnames';

const StatusLabel = ({ children, color, className, round, size, as: Element = 'span', noMargin }) => {
  const modifierClass = cx(className, {
    [`status-label--${color}`]: color,
    [`status-label--size-${size}`]: size,
    'status-label--round': round,
    'status-label--no-margin': noMargin,
  });
  return <Element className={`status-label ${modifierClass}`}>{children}</Element>;
};

export default StatusLabel;
