import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  activatePerson,
  changeEmployeeNumber,
  changeInitials,
  changeLanguage,
  changeName,
  changePersonAdminState,
  changePersonPassword,
  deactivatePerson,
  deletePerson,
  requestPersonsDetails,
  requestPersonsOverview,
} from '../../actions/personEditorActions';
import { getMenteeRole } from '../../actions/roleActions';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithModals from '../../components/HOC/withModals';
import WithPersonActions from '../../components/HOC/withPersonActions';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Modal from '../../components/Modal';
import OrganisationUnitsText from '../../components/OrganisationUnitsText';
import Overlay from '../../components/Overlay';
import Page from '../../components/Page';
import PersonEditor from '../../components/PersonEditor';
import PersonsNav from '../../components/PersonsNav';
import Portal from '../../components/Portal';
import StatusLabel from '../../components/StatusLabel';
import CheckListModal from '../../components/modal/CheckListModal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import CreateUserModal from '../../components/modal/CreateUserModal';
import ManageLoginModal from '../../components/modal/ManageLoginModal';
import MultiActionModal from '../../components/modal/MultiActionModal';
import OrganisationUnitCheckListModal from '../../components/modal/OrganisationUnitCheckListModal';
import RadioButtonModal from '../../components/modal/RadioButtonModal';
import localeLookup from '../../config/locale';
import { ROLE_STATES } from '../../constants';
import { getLanguagesService } from '../../services/localizationService';
import {
  addRolesToPersonService,
  changeLoginToExternalLoginService,
  changeLoginToInternalLoginService,
  changeLoginToNoLoginService,
  changePersonRoleRelevanceService,
  updatePersonOrganisationUnits,
} from '../../services/personsService';
import { getAllAreas } from '../../slices/areasSlice';
import { getAllCategories } from '../../slices/categoriesSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import {
  getPersons,
  removePerson,
  selectActivePersonsSortOrder,
} from '../../slices/personsSlice';
import { getAllRoles } from '../../slices/rolesSlice';
import { getAllSpaces, getSpaceStatus } from '../../slices/spaceSlice';
import { trackEvent } from '../../utils/tracking';
import Role from '../Role';

const mapStateToProps = (state) => {
  const { personEditor, persons } = state;
  return {
    allPersons: persons,
    locations: personEditor.locations,
    loggedInUserId: state.user.employeeId,
    persons: personEditor.persons,
    personsSortOrder: personEditor.personsSortOrder,
    deactivatedPersonsSortOrder: personEditor.deactivatedPersonsSortOrder,
    roles: personEditor.roles,
    locales: personEditor.locales,
    organisationUnitRootNodes: personEditor.rootNodes,
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getSpaceStatus,
      getAllSpaces,
      getAllOrganisationUnits,
      getAllCategories,
      getAllAreas,
      getAllRoles,
      activatePerson,
      changeInitials,
      changeLanguage,
      changeName,
      changePersonAdminState,
      changePersonPassword,
      deactivatePerson,
      deletePerson,
      getMenteeRole,
      getPersons,
      requestPersonsDetails,
      requestPersonsOverview,
      removePerson,
      changeEmployeeNumber,
    },
    dispatch
  ),
});

class PersonsPage extends Component {
  constructor() {
    super();
    this.state = {
      filterString: '',
      selectedPersonId: null,
      showRoleOverlay: false,
      roleOverlayProps: {
        personId: null,
        roleId: null,
      },
      hasError: false,
      isFetchingPerson: true,
      activeModal: '',
      modalProps: {},
      isLoading: true,
      languages: {},
    };
  }

  componentDidMount() {
    const {
      requestPersonsOverview,
      getAllAreas,
      getAllRoles,
      getAllCategories,
      getPersons,
      getAllOrganisationUnits,
      getAllSpaces,
      getSpaceStatus,
    } = this.props;
    trackEvent('$pageview');
    Promise.all([
      getLanguagesService(),
      getAllCategories(),
      getAllRoles(),
      getAllOrganisationUnits(),
      getAllAreas(),
      requestPersonsOverview(),
      getPersons(),
      getAllSpaces({}),
      getSpaceStatus(),
    ]).then((responses) => {
      const [languagesResponse] = responses;
      this.setState({ isLoading: false, languages: languagesResponse.data });
    });
  }

  onActivatePersonClick = (person) => {
    const { activatePerson, getPersons } = this.props;
    activatePerson(person.id).then(() => {
      this.onSelectPerson(person.id);
      getPersons();
    });
  };

  onChangePersonOrganisationUnitsClick = (person) => {
    const { locations } = this.props;

    this.showModal('organisationUnitsCheckList', {
      fullWidth: true,
      title: localeLookup('translations.Organisation units'),
      selectedUnits: person.memberOfOrganisationUnits,
      onConfirm: (selectedUnits) => {
        const unitObject = Object.keys(locations).reduce((acc, unitId) => {
          if (selectedUnits.includes(unitId)) {
            return {
              ...acc,
              [unitId]: true,
            };
          }
          return { ...acc, [unitId]: false };
        }, {});
        updatePersonOrganisationUnits(person.id, {
          organisationUnits: unitObject,
        }).then(() => {
          this.hideModal();
          this.onSelectPerson(person.id);
        });
      },
      filterPlaceholder: `${localeLookup(
        'translations.Search for organisation units'
      )}...`,
    });
  };

  onChangePersonPasswordClick = (person) => {
    const { showModal } = this.props;
    showModal('changePassword', {
      personId: person.id,
      confirmButtonText: localeLookup('translations.Change'),
      title: localeLookup('translations.Change password'),
      subtitle: person?.name,
      maxWidth: '500px',
      fullWidth: true,
    });
  };

  onClickPersonRoleExperienced = ({ personId, roleId }) => {
    const { requestPersonsDetails, persons, roles, personActions } = this.props;
    const { selectedPersonId } = this.state;
    const person = persons[personId];
    const role = roles[roleId];
    personActions.onChangePersonRoleToExperienced({
      person,
      role,
      onChanged: () => requestPersonsDetails(selectedPersonId),
    });
  };

  onClickPersonRoleRelevance = ({ roleId, personId, personRole }) => {
    const { selectedPersonId } = this.state;

    const { persons, roles, requestPersonsDetails, personActions } = this.props;
    const person = persons[personId];
    const role = roles[roleId];

    const sharedRoleAndPersonOrganisationUnitIds =
      role.organisationUnits?.filter((id) =>
        person.memberOfOrganisationUnits.includes(id)
      );
    
    personActions.onChangePersonRoleRelevance({
      person,
      role,
      onChanged: () => requestPersonsDetails(selectedPersonId),
      organisationUnitsWherePersonHasRole: sharedRoleAndPersonOrganisationUnitIds,
      personRoleNotRelevantInOrganisationUnitIds:
        personRole.notRelevantInOrganisationUnits,
    });
  };

  onClickPersonRoleQualified = ({ roleId, personId }) => {
    const { requestPersonsDetails, personActions, roles, persons } = this.props;
    const { selectedPersonId } = this.state;
    const person = persons[personId];
    const role = roles[roleId];
    personActions.onChangePersonRoleToQualified({
      person,
      role,
      onChanged: () => requestPersonsDetails(selectedPersonId),
    });
  };

  onClickPersonRoleRelevant = ({ personId, unitId, roleId, personRole }) => {
    const { requestPersonsDetails, persons } = this.props;
    const { selectedPersonId } = this.state;
    const notRelevantInOrganisationUnitIds =
      personRole?.notRelevantInOrganisationUnits?.filter(
        (id) => id !== unitId
      ) || [];
    changePersonRoleRelevanceService({
      personId: selectedPersonId,
      notRelevantInOrganisationUnitIds: notRelevantInOrganisationUnitIds,
      roleId: roleId,
    }).then(() => requestPersonsDetails(selectedPersonId));
  };

  onClickPersonRoleRemove = ({ personId, roleId }) => {
    const { requestPersonsDetails, persons, roles, personActions } = this.props;
    const { selectedPersonId } = this.state;
    const person = persons[personId];
    const role = roles[roleId];
    personActions.onRemovePersonRole({
      person,
      role,
      onChanged: () => requestPersonsDetails(selectedPersonId),
    });
  };

  onClickPersonRoleTraining = ({ personId, unitId, roleId, personRole }) => {
    const { requestPersonsDetails, persons, roles, personActions } = this.props;
    const { selectedPersonId } = this.state;
    const role = roles[roleId];
    const person = persons[personId];
    personActions.onChangePersonRoleTraining({
      person,
      role,
      // I know this is stupid
      personRole: { ...personRole, mentor: personRole.mentorId },
      unitId,
      onChanged: () => requestPersonsDetails(selectedPersonId),
    });
  };

  onDeactivatePersonClick = (person) => {
    const { deactivatePerson, getPersons } = this.props;
    this.showModal('confirmation', {
      title: localeLookup('translations.Archive'),
      subtitle: person.name,
      maxWidth: '500px',
      fullWidth: true,
      body: (
        <>
          <p>{localeLookup('translations.Archiving maintains')}:</p>
          <ul>
            <li>
              {localeLookup(
                'translations.Own roles (Shown under "Former roles")'
              )}
            </li>
            <li>
              {localeLookup(
                "translations.Mentoring responsibility for other people's training programs"
              )}
            </li>
            <li>{localeLookup('translations.Status of own elements')}</li>
            <li>
              {localeLookup(
                "translations.Responsibility for completion of other persons' elements"
              )}
            </li>
            <li>
              {localeLookup(
                "translations.Completions on other people's elements"
              )}
            </li>
            <li>
              {localeLookup(
                "translations.Signatures on other people's elements"
              )}
            </li>
          </ul>
          <br />
          <p>{localeLookup('translations.Archiving removes')}: </p>
          <ul>
            <li>{localeLookup('translations.Ability to log in')}</li>
            <li>{localeLookup('translations.Permissions')}</li>
            <li>
              {localeLookup('translations.Connection to organisation units')}
            </li>
            <li>
              {localeLookup('translations.Organisation administrator status')}
            </li>
            <li>{localeLookup('translations.Role owner- and editorships')}</li>
            <li>
              {localeLookup('translations.Module owner- and editorships')}
            </li>
            <li>{localeLookup('translations.Expert status')}</li>
          </ul>
        </>
      ),
      onConfirm: () => {
        this.setState({ isDeactivatingOrDeletingPerson: true });
        deactivatePerson(person.id).then(() => {
          this.setState({ isDeactivatingOrDeletingPerson: false });
          getPersons();
        });
      },
      confirmButtonText: localeLookup('translations.Archive'),
      confirmButtonType: 'darkui',
    });
  };

  onDeletePersonClick = (person) => {
    const { deletePerson, history, removePerson } = this.props;
    this.showModal('confirmation', {
      title: localeLookup('translations.Delete'),
      subtitle: person.name,
      safeWord: localeLookup('translations.Delete'),
      maxWidth: '500px',
      fullWidth: true,
      skipCloseOnConfirm: true,
      body: (
        <>
          <p>
            {localeLookup(
              'translations.All data about the person will be permanently deleted and can not be recovered'
            )}
          </p>
          <br />
          <p>
            {localeLookup(
              'translations.In the following contexts, the person is changed to "Deleted person"'
            )}
            :
          </p>
          <ul>
            <li>
              {localeLookup(
                "translations.Mentoring responsibility for other people's training programs"
              )}
            </li>
            <li>
              {localeLookup(
                "translations.Responsibility for completion of other persons' elements"
              )}
            </li>
            <li>
              {localeLookup(
                "translations.Completions on other people's elements"
              )}
            </li>
          </ul>
          <br />
          <p>
            {localeLookup(
              "translations.Signatures on other people's elements are deleted, but other information about the completion is maintained"
            )}
          </p>
        </>
      ),
      onConfirm: () => {
        this.setState({ isDeactivatingOrDeletingPerson: true });
        deletePerson(person.id).then(() => {
          this.hideModal();
          history.replace('/persons');
          removePerson(person.id);
          this.setState({
            selectedPersonId: null,
            isDeactivatingOrDeletingPerson: false,
          });
        });
      },
      confirmButtonText: localeLookup('translations.Delete'),
      confirmButtonType: 'alert',
    });
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onPersonEditRolesClick = ({ person, organisationUnitId }) => {
    const { roles, locations, requestPersonsDetails } = this.props;
    const { selectedPersonId } = this.state;
    const options = Object.keys(roles).reduce((acc, id) => {
      const role = roles[id];
      const organisationUnitNames = role.organisationUnits.map(
        (id) => locations[id].name
      );
      if (
        !role.hasAccess ||
        !role.organisationUnits.includes(organisationUnitId) ||
        person.roles[id]
      ) {
        return acc;
      }
      return [
        ...acc,
        {
          id: role.id,
          title: role.name,
          searchString: `${role.name}${organisationUnitNames}`,
          subtitle: (
            <OrganisationUnitsText
              organisationUnitIds={role.organisationUnits}
            />
          ),
        },
      ];
    }, []);

    this.showModal('checkList', {
      fullWidth: true,
      selectedOptionIds: [],
      title: localeLookup('translations.Roles'),
      emptyStateText: localeLookup(
        'translations.Organisation unit contains no roles'
      ),
      onConfirm: (selectedRoleIds) => {
        addRolesToPersonService(person.id, organisationUnitId, {
          roles: selectedRoleIds,
        }).then(() => {
          requestPersonsDetails(selectedPersonId);
        });
        this.hideModal();
      },
      options,
      filterPlaceholder: `${localeLookup('translations.Search for role')}...`,
    });
  };

  onSelectPerson = (personId) => {
    const { requestPersonsDetails } = this.props;
    this.setState({ isFetchingPerson: true, hasError: false });
    requestPersonsDetails(personId)
      .then(() => {
        this.setState({
          isFetchingPerson: false,
          hasError: false,
          selectedPersonId: personId,
        });
      })
      .catch(() => {
        this.setState({
          isFetchingPerson: false,
          hasError: true,
          selectedPersonId: personId,
        });
      });
  };

  onUserCreated = ({ id, name }) => {
    const { requestPersonsOverview, history } = this.props;
    this.setState({ selectedPersonId: null }, () => {
      requestPersonsOverview().then(() => {
        this.hideModal();
        this.onSelectPerson(id);
        history.push({
          pathname: `/persons/all/${id}`,
          state: { title: name },
        });
      });
    });
  };

  onSubmitEditLogin = ({ email, password, password2, newUserType, userId }) => {
    if (newUserType === 'NoAccess') {
      changeLoginToNoLoginService(userId).then(() => {
        this.hideModal();
        this.onSelectPerson(userId);
      });
    } else if (newUserType === 'Champ') {
      changeLoginToInternalLoginService(userId, {
        email,
        password,
        repeatedPassword: password2,
      }).then(() => {
        this.hideModal();
        this.onSelectPerson(userId);
      });
    } else if (newUserType === 'External') {
      changeLoginToExternalLoginService(userId, { email }).then(() => {
        this.hideModal();
        this.onSelectPerson(userId);
      });
    }
  };

  getVisiblePersons = () => {
    const { allPersons } = this.props;
    const { filterString } = this.state;

    if (!filterString || filterString.length === 0)
      return Object.keys(allPersons);

    const checkPersonMatch = (obj, keys) => {
      const matchString = keys.reduce((acc, key) => {
        if (obj[key]) {
          acc += `${obj[key]} `;
        }
        return acc;
      }, '');

      return matchString.toLowerCase().includes(filterString.toLowerCase());
    };

    return Object.keys(allPersons).filter((id) =>
      checkPersonMatch(allPersons[id], [
        'name',
        'initials',
        'email',
        'autoGeneratedInitials',
        'employeeNumber',
      ])
    );
  };

  renderRoleOverlay = () => {
    const { allPersons } = this.props;
    const { showRoleOverlay, roleOverlayProps } = this.state;
    const { personId, roleId, personName, roleName, roleStatus } =
      roleOverlayProps;
    const person = allPersons[personId];
    const getNameSuffix = () => {
      if (person) {
        return `(${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }
      return '';
    };
    return (
      <Overlay
        isOpen={showRoleOverlay}
        onClose={() => {
          this.toggleRoleOverlay({ personId });
        }}
        title={
          <p>
            {personName} {getNameSuffix()}
          </p>
        }
        subtitle={
          <p>
            <span className="e-label-upper">{roleName}</span>
            {roleStatus && (
              <StatusLabel
                size="small"
                color={
                  roleStatus === ROLE_STATES.TRAINING ? 'light-green' : 'grey'
                }
              >
                {localeLookup(roleStatus)}
              </StatusLabel>
            )}
          </p>
        }
      >
        <Role
          overlayMenteeId={personId}
          overlayRoleId={roleId}
          isMenteeContext
        />
      </Overlay>
    );
  };

  toggleRoleOverlay = ({
    personId,
    roleId,
    personName,
    roleName,
    roleStatus,
  }) => {
    const { requestPersonsDetails } = this.props;
    const { showRoleOverlay, selectedPersonId } = this.state;
    if (!showRoleOverlay) {
      this.setState({
        roleOverlayProps: {
          personId,
          roleId,
          personName,
          roleName,
          roleStatus,
        },
        showRoleOverlay: true,
      });
    } else {
      requestPersonsDetails(selectedPersonId);
      this.setState({
        showRoleOverlay: false,
      });
    }
  };

  hideModal = () => {
    this.setState({
      activeModal: '',
      modalProps: {},
    });
  };

  showModal = (type, props = {}) => {
    const { modalProps } = this.state;
    this.setState({
      activeModal: type,
      modalProps: { ...modalProps, ...props },
    });
  };

  renderModals = () => {
    const { activeModal, modalProps } = this.state;
    const { locations, organisationUnitRootNodes } = this.props;
    return (
      <Modal
        maxWidth={modalProps.maxWidth || '700px'}
        isOpen={activeModal}
        fullWidth={modalProps.fullWidth}
        onCloseClick={this.hideModal}
        render={({ onCloseClick }) => {
          if (activeModal === 'checkList') {
            return (
              <CheckListModal
                title={modalProps.title}
                selectedOptionIds={modalProps.selectedOptionIds}
                emptyStateText={modalProps.emptyStateText}
                onClose={onCloseClick}
                options={modalProps.options}
                onConfirm={modalProps.onConfirm}
                filterPlaceholder={modalProps.filterPlaceholder}
                filterButtonText={modalProps.filterButtonText}
                onFilterButtonClick={modalProps.onFilterButtonClick}
              />
            );
          }
          if (activeModal === 'confirmation') {
            return (
              <ConfirmationModal
                title={modalProps.title}
                subtitle={modalProps.subtitle}
                body={modalProps.body}
                btnConfirmTitle={modalProps.confirmButtonText}
                onCancel={modalProps.onCancel || onCloseClick}
                onClose={onCloseClick}
                btnConfirmKind={modalProps.confirmButtonType}
                onConfirm={modalProps.onConfirm}
                safeWord={modalProps.safeWord}
                skipCloseOnConfirm={modalProps.skipCloseOnConfirm}
              />
            );
          }
          if (activeModal === 'createUser') {
            return (
              <CreateUserModal
                checkForInformationConclicts
                onClose={onCloseClick}
                onCreated={this.onUserCreated}
                title={localeLookup('translations.Create person')}
              />
            );
          }
          if (activeModal === 'editLogin') {
            return (
              <ManageLoginModal
                currentUserType={modalProps.userType}
                onClose={onCloseClick}
                onSubmit={(values) =>
                  this.onSubmitEditLogin({
                    ...values,
                    userId: modalProps.userId,
                  })
                }
                email={modalProps.email}
                title={localeLookup('translations.Edit login')}
              />
            );
          }
          if (activeModal === 'multiAction') {
            return (
              <MultiActionModal
                title={modalProps.title}
                body={modalProps.body}
                actions={modalProps.actions}
                onClose={onCloseClick}
              />
            );
          }
          if (activeModal === 'organisationUnitsCheckList') {
            return (
              <OrganisationUnitCheckListModal
                title={modalProps.title}
                selectedUnits={modalProps.selectedUnits}
                emptyStateText={modalProps.emptyStateText}
                onClose={onCloseClick}
                organisationUnits={locations}
                rootNodes={organisationUnitRootNodes}
                onConfirm={modalProps.onConfirm}
                filterPlaceholder={modalProps.filterPlaceholder}
              />
            );
          }
          if (activeModal === 'radioButton') {
            return (
              <RadioButtonModal
                onClose={onCloseClick}
                grouped={modalProps.grouped}
                onCreated={modalProps.onCreated}
                titleText={modalProps.title}
                options={modalProps.options}
                confirmBtnText={modalProps.confirmBtnText}
                filterPlaceholder={modalProps.filterPlaceholder}
                showFilterButton={modalProps.showFilterButton}
                selectedValue={modalProps.selectedValue}
                onConfirm={modalProps.onConfirm}
                buttonText={modalProps.filterButtonText}
                onFilterButtonClick={modalProps.onFilterButtonClick}
              />
            );
          }
        }}
      />
    );
  };

  renderEditor = () => {
    const {
      changePersonAdminState,
      locations,
      loggedInUserId,
      persons,
      roles,
      changeName,
      changeInitials,
      changeLanguage,
      changeEmployeeNumber,
    } = this.props;
    const {
      selectedPersonId,
      isDeactivatingOrDeletingPerson,
      isLoading,
      languages,
    } = this.state;
    if (isLoading) return null;
    return (
      <MainArea forceVerticalScrollbar>
        <PersonEditor
          getPerson={this.onSelectPerson}
          isDeactivatingOrDeletingPerson={isDeactivatingOrDeletingPerson}
          locales={languages}
          locations={locations}
          loggedInUserId={loggedInUserId}
          onActivatePersonClick={this.onActivatePersonClick}
          onChangeInitials={changeInitials}
          onChangeEmployeeNumber={changeEmployeeNumber}
          onChangeLanguage={changeLanguage}
          onChangeName={changeName}
          onChangePersonAdminState={changePersonAdminState}
          onChangePersonOrganisationUnitsClick={
            this.onChangePersonOrganisationUnitsClick
          }
          onChangePersonPasswordClick={this.onChangePersonPasswordClick}
          onDeactivatePersonClick={this.onDeactivatePersonClick}
          onDeletePersonClick={this.onDeletePersonClick}
          onEditLoginClick={({ userType, userId, email }) =>
            this.showModal('editLogin', { userType, userId, email })
          }
          onPersonEditRolesClick={this.onPersonEditRolesClick}
          onShowRoleClick={this.toggleRoleOverlay}
          person={persons[selectedPersonId]}
          roles={roles}
          onClickRoleRemove={this.onClickPersonRoleRemove}
          onClickRoleExperienced={this.onClickPersonRoleExperienced}
          onClickRoleQualified={this.onClickPersonRoleQualified}
          onClickPersonRoleRelevance={this.onClickPersonRoleRelevance}
          onClickRoleRelevant={this.onClickPersonRoleRelevant}
          onClickRoleTraining={this.onClickPersonRoleTraining}
        />
      </MainArea>
    );
    /* } */
  };

  render() {
    const { persons, match } = this.props;
    const { selectedPersonId, filterString, isLoading } = this.state;
    if (isLoading || !persons) return <LoadScreen />;
    return (
      <Page>
        <PersonsNav
          filterString={filterString}
          onCreatePersonClick={() => this.showModal('createUser')}
          onFilterChange={this.onFilterChange}
          onSelectPerson={this.onSelectPerson}
          selectedPersonId={selectedPersonId}
          visiblePersons={this.getVisiblePersons()}
        />
        <Switch>
          <Route path={`${match.path}/:personId`}>{this.renderEditor()}</Route>
        </Switch>
        <Portal>{this.renderModals()}</Portal>
        <Portal id="overlay-root">{this.renderRoleOverlay()}</Portal>
      </Page>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPersonLookup(
    withRouter(WithModals(withAccessControl(WithPersonActions(PersonsPage))))
  )
);
