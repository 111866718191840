import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Icon from './Icon';
import Text from './Text';

const CardLink = ({
  icon,
  linkPath,
  onClick,
  title,
  subtitle,
  stateProps = {},
  titleColor,
  leftIconColor,
}) => (
  <Link
    className="card-link"
    to={{ pathname: linkPath, state: { title, ...stateProps } }}
    onClick={onClick}
  >
    <div className="card-link__wrapper">
      <Icon
        color={leftIconColor}
        className="card-link__icon-left"
        kind={icon}
      />
      <div className="card-link__text">
        <Text color={titleColor} className="card-link__text-title">
          {title}
        </Text>
        <p className="card-link__text-subtitle">{subtitle}</p>
      </div>
      <Icon className="card-link__icon-right" kind="chevron-right" />
    </div>
  </Link>
);

export default CardLink;
