import React, { Component } from 'react';
import ErrorMessage from '../formElements/ErrorMessage';
import FormWrapper from '../formElements/FormWrapper';
import Input from '../formElements/Input';
import GenericModal from './GenericModal';
import * as Yup from 'yup';
import localeLookup from '../../config/locale';
import CheckboxGroup from '../CheckboxGroup';
import Field from '../formElements/Field';

export default class DuplicateOrganisationUnitModal extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
    };
  }
  onSubmit = (values) => {
    const { onConfirm } = this.props;
    this.setState({ isSubmitting: true });
    onConfirm(values);
  };
  render() {
    const { isSubmitting } = this.state;
    const {
      onConfirm,
      confirmButtonText,
      inputPlaceholder,
      onCloseClick,
      title,
      defaultValue,
      subtitle,
      unitName,
    } = this.props;
    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        initialValues={{
          name: `${unitName} (2)`,
          include: [
            'employees',
            'roles',
            'goals',
            'organisationAdministrators',
          ],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            localeLookup('translations.Name is required')
          ),
        })}
      >
        {(props) => {
          const {
            errors,
            handleSubmit,
            values,
            dirty,
            handleChange,
            isValid,
            setFieldValue,
          } = props;
          return (
            <GenericModal
              confirmButtonText={confirmButtonText}
              onCancelClick={onCloseClick}
              onClose={onCloseClick}
              onConfirmClick={handleSubmit}
              title={title}
              subtitle={subtitle}
              confirmDisabled={!isValid || isSubmitting}
            >
              <form onSubmit={handleSubmit}>
                <Field margin="lg" label={localeLookup('translations.Name')}>
                  <Input
                    autoFocus
                    fullWidth
                    placeholder={inputPlaceholder}
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <ErrorMessage show={errors.name}>{errors.name}</ErrorMessage>
                </Field>
                <CheckboxGroup
                  title={localeLookup('translations.Include')}
                  groupName="include"
                  initialCheckedValues={values.include}
                  onChange={(options) => setFieldValue('include', options)}
                  options={[
                    {
                      label: localeLookup('translations.Persons'),
                      id: 'employees',
                      value: 'employees',
                    },
                    {
                      label: localeLookup('translations.Roles'),
                      id: 'roles',
                      value: 'roles',
                    },
                    {
                      label: localeLookup('translations.Goals'),
                      id: 'goals',
                      value: 'goals',
                      dependsOn: 'roles',
                    },
                    {
                      label: localeLookup(
                        'translations.Organisation administrators'
                      ),
                      id: 'organisationAdministrators',
                      value: 'organisationAdministrators',
                    },
                  ]}
                />
              </form>
            </GenericModal>
          );
        }}
      </FormWrapper>
    );
  }
}
