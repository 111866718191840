/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import Tooltip from '../Tooltip';
import OrganisationStatusboxProgressBar from './OrganisationStatusboxProgressBar';

class StatusBoxKnowledgeOrganisation extends PureComponent {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const {
      toggleContextMenu,
      personId,
      roleId,
      knowledgeAreaId,
      isReadOnly,
      isIrrelevant,
    } = this.props;
    if (isReadOnly || isIrrelevant) return;
    toggleContextMenu({ e, personId, roleId, areaId: knowledgeAreaId });
  }

  onMouseEnter = () => {
    const { onMouseEnter, roleId, personId, areaId } = this.props;
    onMouseEnter({ roleId, personId, areaId, type: 'area' });
  };
  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    onMouseLeave();
  };

  render() {
    const {
      isExperienced,
      completionPercentage,
      hasIncompleteCriticalKnowledge,
      isConnected,
      showProgress,
      isIrrelevant,
      width,
    } = this.props;
    const style = { width: `${width}px` };

    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={style}
        type="button"
        className={cx('organisation-statusbox-knowledge', {
          'organisation-statusbox-knowledge--theme-red':
            hasIncompleteCriticalKnowledge && !isIrrelevant,
          'organisation-statusbox-knowledge--theme-grey': isIrrelevant,
        })}
        onClick={this.onClick}
      >
        <div className="organisation-statusbox-knowledge__content">
          {isExperienced && (
            <div className="organisation-statusbox-knowledge__star">
              <svg
                className="organisation-statusbox-knowledge__star-icon"
                height="20"
                width="20"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" />
              </svg>
            </div>
          )}
          {!isExperienced && isConnected && (
            <div className="organisation-statusbox-knowledge__point" />
          )}
          {!isExperienced && !isConnected && (
            <span className="organisation-statusbox-knowledge__dash">–</span>
          )}
        </div>
        {isConnected && showProgress && completionPercentage !== 100 && (
          <OrganisationStatusboxProgressBar
            color={hasIncompleteCriticalKnowledge ? 'red' : 'green'}
            completionPercentage={completionPercentage}
          />
        )}
      </div>
    );
  }
}

export default StatusBoxKnowledgeOrganisation;
