import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import localeLookup from '../config/locale';
import DateTimeRange from './DateTimeRange';
import Icon from './Icon';
import ProgressBar from './ProgressBar';
import StatusLabel from './StatusLabel';
import Text from './Text';
import Tooltip from './Tooltip';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';
import WithTrainingActions from './HOC/withTrainingActions';
import { ACCESS_LEVELS } from '../constants';
import withAccessControl from './HOC/withAccessControl';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
});

const TrainingSessionCard = ({
  id,
  name,
  location,
  startTime,
  endTime,
  startDate,
  endDate,
  numberOfParticipants,
  numberOfTrainers,
  totalNumberOfRegistrations,
  numberOfCompletedRegistrations,
  onClickDuplicate,
  participantsTooltip,
  trainersTooltip,
  originalName,
  state,
  onClickArchive,
  onClickCancel,
  onClickResume,
  onClickRestore,
  hasAccess,
  currentUserId,
  organisers,
}) => {
  let { url } = useRouteMatch();
  const isAdmin = hasAccess([
    ACCESS_LEVELS.champadministrator,
    ACCESS_LEVELS.administrator,
    ACCESS_LEVELS.trainingSessionAdministrator,
  ]);

  const isOrganiser = organisers.includes(currentUserId);

  return (
    <li className="training-session-card">
      <div className="training-session-card__context-menu">
        {isAdmin || isOrganiser ? (
          <ContextMenu>
            {state !== 'Archived' ? (
              <>
                {state === 'Cancelled' ? (
                  <ContextMenuItem
                    onClick={() => onClickResume(id)}
                    leftIconKind="calendar-check"
                    titleText={localeLookup('translations.Resume')}
                  />
                ) : (
                  <ContextMenuItem
                    onClick={() => onClickCancel(id)}
                    leftIconKind="calendar-cross"
                    titleText={localeLookup('translations.Cancel session')}
                  />
                )}
              </>
            ) : null}
            {state === 'Archived' ? (
              <ContextMenuItem
                onClick={() => onClickRestore(id)}
                leftIconKind="undelete"
                leftIconColor="green"
                titleText={localeLookup('translations.Restore')}
              />
            ) : (
              <ContextMenuItem
                onClick={() => onClickArchive(id)}
                leftIconKind="trash2"
                leftIconColor="red"
                titleText={localeLookup('translations.Archive')}
              />
            )}
            <ContextMenuItem
              onClick={() => onClickDuplicate(id)}
              leftIconKind="copy"
              titleText={localeLookup('translations.Duplicate')}
            />
          </ContextMenu>
        ) : null}
      </div>
      <Link
        to={{
          pathname: `${url}/${id}`,
          state: {
            title: originalName || name,
            backTitle: localeLookup('translations.Training sessions'),
          },
        }}
        className="training-session-card__inner"
      >
        <div className="training-session-card__header">
          <Text color="blue" size="lg">
            {name}
          </Text>
          {state === 'Cancelled' && (
            <StatusLabel noMargin round size="small" color="red">
              {localeLookup('translations.Cancelled')}
            </StatusLabel>
          )}
          {state === 'Archived' && (
            <StatusLabel noMargin round size="small" color="grey">
              {localeLookup('translations.Archived')}
            </StatusLabel>
          )}
        </div>
        <div className="training-session-card__body">
          {startDate || endDate ? (
            <div className="training-session-card__detail">
              <Icon
                className="training-session-card__detail-icon"
                color="grey"
                kind={startDate ? 'calendar-full' : 'flag'}
              />
              <Text className="training-session-card__detail-text">
                <DateTimeRange
                  startDate={startDate}
                  endDate={endDate}
                  startTime={startTime}
                  endTime={endTime}
                />
              </Text>
            </div>
          ) : null}
          {location && (
            <div className="training-session-card__detail">
              <Icon
                className="training-session-card__detail-icon"
                color="grey"
                kind="map-marker"
              ></Icon>
              <Text className="training-session-card__detail-text">
                {location}
              </Text>
            </div>
          )}
        </div>
        <div className="training-session-card__divider"></div>
        <div className="training-session-card__footer">
          <Text className="training-session-card__footer-progress-text">
            {numberOfCompletedRegistrations}/{totalNumberOfRegistrations}
          </Text>
          <ProgressBar
            rounded
            size="lg"
            className="training-session-card__progress-bar"
            percentage={Math.ceil(
              (numberOfCompletedRegistrations / totalNumberOfRegistrations) *
                100
            )}
          />
          <Tooltip tooltip={trainersTooltip}>
            <div className="training-session-card__person-counter">
              <Text className="training-session-card__person-counter-text">
                {numberOfTrainers}
              </Text>
              <Icon
                className="training-session-card__person-counter-icon"
                color="grey"
                size="x-small"
                kind="school-teacher"
              ></Icon>
            </div>
          </Tooltip>
          <Tooltip tooltip={participantsTooltip}>
            <div className="training-session-card__person-counter">
              <Text className="training-session-card__person-counter-text">
                {numberOfParticipants}
              </Text>
              <Icon
                className="training-session-card__person-counter-icon"
                color="grey"
                size="x-small"
                kind="user"
              ></Icon>
            </div>
          </Tooltip>
        </div>
      </Link>
    </li>
  );
};

export default connect(mapStateToProps)(
  WithTrainingActions(withAccessControl(TrainingSessionCard))
);
