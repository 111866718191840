import {
  axiosRequestPOST,
  axiosRequestGET,
  axiosRequestDELETE,
  axiosRequestPUT,
} from './serviceConfig';

export const fetchOrganisationOverviewService = () => {
  const url = '/api/organisation';
  return axiosRequestGET(url);
};

export const getAllOrganisationUnitsService = () =>
  axiosRequestGET('/api/organisation/units');

export const getOrganisationUnitService = (id) => {
  const url = `/api/organisation/${id}`;
  return axiosRequestGET(url);
};
export const getOrganisationUnitsService = (data) => {
  const url = '/api/organisation/units';
  return axiosRequestPOST(url, data);
};

export const getOrganisationUnitPersonService = (personId) => {
  const url = `/api/organisation/persons/${personId}`;
  return axiosRequestGET(url);
};

export const getOrganisationUnitPersonsService = (persons = []) => {
  const url = `/api/organisation/persons`;
  return axiosRequestPOST(url, { persons });
};

export const toggleOrganisationUnitSelectionService = (updateData) => {
  const url = '/api/organisation/toggle';
  return axiosRequestPOST(url, updateData);
};

export const createOrganisationUnitService = (postData) => {
  const url = '/api/organisation/unit';
  return axiosRequestPOST(url, postData);
};

export const updateOrganisationUnitNameService = (unitId, updateData) => {
  const url = `/api/organisation/${unitId}/update`;
  return axiosRequestPUT(url, updateData);
};

export const deleteOrganisationUnitService = (unitId) => {
  const url = `/api/organisation/${unitId}/delete`;
  return axiosRequestDELETE(url);
};

export const togglePersonRoleKnowledgeLinkingService = (
  personId,
  roleId,
  knowledgeAreaId
) => {
  const url = `/api/organisation/persons/${personId}/roles/${roleId}/knowledge/${knowledgeAreaId}`;
  return axiosRequestPUT(url);
};

export const updateRoleGoalsService = (unitId, roleId, updateData) => {
  const url = `/api/organisation/${unitId}/roles/${roleId}/goals/update`;
  return axiosRequestPUT(url, updateData);
};

export const updateRoleKnowledgeAreaGoalsService = (
  unitId,
  roleId,
  knowledgeAreaId,
  updateData
) => {
  const url = `/api/organisation/${unitId}/roles/${roleId}/knowledgeAreas/${knowledgeAreaId}/goals/update`;
  return axiosRequestPUT(url, updateData);
};

export const updateOrganisationUnitPersonsService = (unitId, updateData) => {
  const url = `/api/organisation/unit/${unitId}/set/persons`;
  return axiosRequestPOST(url, updateData);
};
export const updateOrganisationUnitPersonAdministratorsService = ({
  unitIds,
  personIds,
  context = 'organisationUnit',
}) => {
  const url = `/api/organisation/units/set/person-administrators`;
  return axiosRequestPOST(url, {
    personAdministrators: personIds,
    units: unitIds,
    context,
  });
};
export const updateOrganisationUnitRolesService = (unitId, updateData) => {
  const url = `/api/organisation/${unitId}/add/roles`;
  return axiosRequestPUT(url, updateData);
};

export const updateCompletionStateService = (completionState) =>
  axiosRequestPUT('/api/organisation/show-completion', {
    showCompletion: completionState,
  });

export const updateOrganisationUnitRoleSortOrderService = (
  unitId,
  updateData
) => axiosRequestPUT(`/api/organisation/${unitId}/sort/roles`, updateData);

export const getRoleDisconnectScopeInfoService = (unitId, roleId) =>
  axiosRequestGET(`/api/organisation/${unitId}/scope/${roleId}`);

export const removeRoleFromOrganisationUnitService = (unitId, roleId) =>
  axiosRequestDELETE(`/api/organisation/${unitId}/remove/${roleId}`);

export const expandOrganisationUnitService = (unitId) =>
  axiosRequestPUT(`/api/organisation/${unitId}/expand`);

export const collapseOrganisationUnitService = (unitId) =>
  axiosRequestPUT(`/api/organisation/${unitId}/collapse`);

export const updateEmployeeViewService = (unitId, view) =>
  axiosRequestPUT(`/api/organisation/${unitId}/employee-view`, { view });

export const updateOrganisationUnitLocationService = (
  unitId,
  originUnitId,
  destinationUnitId
) =>
  axiosRequestPUT(`/api/organisation/${unitId}/update/location`, {
    originOrganisationUnitId: originUnitId,
    destinationOrganisationUnitId: destinationUnitId,
  });

export const updateTimelineGroupingService = (grouping) =>
  axiosRequestPUT(`/api/organisation/timeline/grouping`, { grouping });

export const updateTimelineProgressService = (progress) =>
  axiosRequestPUT(`/api/organisation/timeline/progress`, { progress });

export const updateTimelineSortingService = (sorting) =>
  axiosRequestPUT(`/api/organisation/timeline/sorting`, { sorting });

export const duplicateOrganisationUnitService = (unitId, name, include) =>
  axiosRequestPOST(`/api/organisation/unit/${unitId}/duplicate`, {
    include,
    name,
  });

export const updateOrganisationUnitStateService = (unitId, state) =>
  axiosRequestPUT(`/api/organisation/units/${unitId}/state`, { state });
