import React from 'react';
import cx from 'classnames';

const Text = ({
  as: TextElement = 'p',
  children,
  color,
  upperCase,
  italic,
  size,
  className,
  bold,
  truncate,
  style,
  title,
}) => {
  const classes = cx({
    [`text--color-${color}`]: color,
    [`text--size-${size}`]: size,
    'text--upper-case': upperCase,
    'text--italic': italic,
    'text--bold': bold,
    'text--truncate': truncate,
  });
  return (
    <TextElement
      title={title}
      style={style}
      className={cx('text', classes, className)}
    >
      {children}
    </TextElement>
  );
};

export default Text;
