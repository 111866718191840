import {
  eachDayOfInterval,
  format,
  getDate,
  parse,
  startOfDay,
} from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccessLevelWrapper from '../../components/AccessLevelWrapper';
import Button from '../../components/Button';
import WithTrainingActions from '../../components/HOC/withTrainingActions';
import { Header } from '../../components/Header';
import HorizontalSection from '../../components/HorizontalSection';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import Tabs from '../../components/Tabs';
import Text from '../../components/Text';
import TrainingSessionCard from '../../components/TrainingSessionCard';
import Input from '../../components/formElements/Input';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS } from '../../constants';
import {
  getTrainingSessionsSettingsService,
  updateTrainingSessionsStatusSettingService,
  updateTrainingSessionsViewSettingService,
} from '../../services/trainingSessionService';
import { getAllTrainingSessions } from '../../slices/trainingSessionsSlice';
import {
  compareDates,
  compareLocal,
  getCurrentDateV2,
  getQueryStringParams,
  sortBy,
} from '../../utils/helpers';
import PersonWrapper from '../../components/PersonWrapper';
import withAccessControl from '../../components/HOC/withAccessControl';

const mapStateToProps = (state) => ({
  persons: state.persons,
  trainingSessions: state.trainingSessions,
  currentUserId: state.user.employeeId,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllTrainingSessions,
    },
    dispatch
  );

class TrainingSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: {},
      sessionsGroupedByDate: {},
      isLoading: true,
      activeView: 'all',
      showCompleted: true,
      filterString: '',
    };
  }

  async componentDidMount() {
    const { getAllTrainingSessions } = this.props;
    await Promise.all([
      getAllTrainingSessions({ reset: true }),
      this.getSettings(),
    ]);

    this.setSessions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeView } = this.state;
    const showArchived = this.getShowArchived();
    if (activeView === 'archived' && !showArchived) {
      this.onChangeView({ id: 'all' });
    }
  }

  getNumberOfArchivedSessions = () => {
    const { trainingSessions } = this.props;
    return Object.values(trainingSessions).reduce((acc, session) => {
      if (session.state === 'Archived') {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  getShowArchived = () => {
    const { trainingSessions, hasAccess, currentUserId } = this.props;
    const numberOfArchivedSessions = this.getNumberOfArchivedSessions();
    const isAnySessionsArchived = numberOfArchivedSessions > 0;
    const isAdmin = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.trainingSessionAdministrator,
    ]);

    const isCurrentUserOrganiserOnAnyArchivedSessions = Object.values(
      trainingSessions
    ).some((session) => session.organisers.includes(currentUserId));

    const showArchived =
      isAnySessionsArchived &&
      (isAdmin || isCurrentUserOrganiserOnAnyArchivedSessions);

    return showArchived;
  };

  setSessions = () => {
    const { trainingSessions: sessions } = this.props;
    const sessionsState = Object.values(sessions).reduce((acc, session) => {
      const getStartDate = () => {
        if (!session.startDate) return null;
        if (session.startTime) {
          return parse(
            `${session.startDate} ${session.startTime}`,
            'yyyy-MM-dd HH:mm',
            new Date()
          );
        }
        return parse(session.startDate, 'yyyy-MM-dd', new Date());
      };

      const getEndDate = () => {
        if (!session.endDate) return null;
        if (session.endTime) {
          return parse(
            `${session.endDate} ${session.endTime}`,
            'yyyy-MM-dd HH:mm',
            new Date()
          );
        }
        return parse(session.endDate, 'yyyy-MM-dd', new Date());
      };

      const startDate = getStartDate();
      const endDate = getEndDate();

      if (!startDate && !endDate) {
        if (acc[null]) {
          acc[null].push(session);
        } else {
          acc[null] = [session];
        }
      }

      if (startDate && !endDate) {
        const dateKey = format(startDate, 'yyyy-MM-dd');
        if (acc[dateKey]) {
          acc[dateKey].push(session);
        } else {
          acc[dateKey] = [session];
        }
      }
      if (!startDate && endDate) {
        const dateKey = format(endDate, 'yyyy-MM-dd');
        if (acc[dateKey]) {
          acc[dateKey].push(session);
        } else {
          acc[dateKey] = [session];
        }
      }

      if (startDate && endDate && endDate >= startDate) {
        const datesBetweenStartAndEndDate = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });

        datesBetweenStartAndEndDate.forEach((date, i) => {
          const dateKey = format(date, 'yyyy-MM-dd');
          if (acc[dateKey]) {
            acc[dateKey].push({
              ...session,
              originalName: session.name,
              name: `${session.name} (${i + 1}/${
                datesBetweenStartAndEndDate.length
              })`,
            });
          } else {
            acc[dateKey] = [
              {
                ...session,
                originalName: session.name,
                name: `${session.name} (${i + 1}/${
                  datesBetweenStartAndEndDate.length
                })`,
              },
            ];
          }
        });
      }
      return acc;
    }, {});

    this.setState({
      sessions: sessions,
      sessionsGroupedByDate: sessionsState,
      isLoading: false,
    });
  };

  getSettings = () => {
    return getTrainingSessionsSettingsService().then(({ data }) => {
      this.setState({
        showCompleted: data.showCompleted,
        activeView: data.view.toLowerCase(),
      });
    });
  };

  onChangeFilterString = (e) => {
    this.setState({ filterString: e.target.value });
  };

  onChangeView = (view) => {
    this.setState(
      {
        activeView: view.id,
      },
      () => updateTrainingSessionsViewSettingService(view.id)
    );
  };

  onClickCreateSession = () => {
    const { trainingActions, getAllTrainingSessions } = this.props;
    trainingActions.showCreateTrainingSessionModal({
      onCreated: async () => {
        await getAllTrainingSessions();
        this.setSessions();
      },
    });
  };

  onClickToggleShowCompleted = () => {
    const { showCompleted } = this.state;
    this.setState(
      (prevState) => ({
        showCompleted: !prevState.showCompleted,
      }),
      () => updateTrainingSessionsStatusSettingService(!showCompleted)
    );
  };

  onClickArchiveSession = (id) => {
    const { trainingActions } = this.props;
    trainingActions.showArchiveTrainingSessionModal({
      id,
      onArchived: this.setSessions,
    });
  };

  onClickCancelSession = (id) => {
    const { trainingActions } = this.props;
    trainingActions.showCancelTrainingSessionModal({
      id,
      onCancelled: this.setSessions,
    });
  };

  onClickResumeSession = (id) => {
    const { trainingActions } = this.props;
    trainingActions.onClickResumeTrainingSession({
      id,
      onResumed: this.setSessions,
    });
  };
  onClickRestoreSession = (id) => {
    const { trainingActions } = this.props;
    trainingActions.onClickRestoreTrainingSession({
      id,
      onRestored: this.setSessions,
    });
  };
  onClickDuplicateSession = (id) => {
    const { trainingActions, trainingSessions } = this.props;
    trainingActions.showDuplicateTrainingSessionModal({
      sessionId: id,
      sessionName: trainingSessions[id].name,
      onDuplicated: this.setSessions,
    });
  };

  renderHeader = () => {
    const {
      isLoading,
      activeView,
      timelineOptions,
      showCompleted,
      filterString,
    } = this.state;
    const { trainingSessions, hasAccess, currentUserId } = this.props;
    const numberOfArchivedSessions = this.getNumberOfArchivedSessions();
    const showArchived = this.getShowArchived();
    return (
      <Header
        className="training-sessions__header"
        leftComponentContainerClass="training-sessions__header-left"
        rightComponent={
          <AccessLevelWrapper
            acceptedLevels={[
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.trainingSessionAdministrator,
              ACCESS_LEVELS.createTrainingSessions,
            ]}
          >
            <Button
              kind="darkui"
              size="small"
              onClick={this.onClickCreateSession}
            >
              {localeLookup('translations.Create session')}
            </Button>
          </AccessLevelWrapper>
        }
        leftComponent={
          <>
            <Tabs
              activeTab={activeView}
              fixedWidth={false}
              activeTabColor="green"
              tabColor="grey"
              rounded
              onChangeTab={this.onChangeView}
              tabs={[
                { name: localeLookup('translations.All'), id: 'all' },
                { name: localeLookup('translations.Upcoming'), id: 'upcoming' },
                {
                  name: localeLookup('translations.Previous'),
                  id: 'previous',
                },
                ...(showArchived
                  ? [
                      {
                        name: `${localeLookup(
                          'translations.Archived'
                        )} (${numberOfArchivedSessions})`,
                        id: 'archived',
                      },
                    ]
                  : []),
              ]}
            />
            <Input
              placeholder={localeLookup('translations.Search for session')}
              size="small"
              className="dashboard-filter__left-input"
              onChange={this.onChangeFilterString}
              value={filterString}
            />
            <SwitchCheckbox
              onChange={this.onClickToggleShowCompleted}
              isChecked={showCompleted}
              wrapperClassName="training-sessions__left-switch"
              labelText={localeLookup('translations.Show completed')}
            />
          </>
        }
      />
    );
  };

  renderSections = () => {
    const { activeView, sessionsGroupedByDate, filterString, showCompleted } =
      this.state;
    const today = startOfDay(getCurrentDateV2());
    const sortedDateKeys = sortBy(
      Object.keys(sessionsGroupedByDate),
      [
        (a, b) => {
          if (a === 'null') return 1;
          if (b === 'null') return -1;
          return compareDates(new Date(a), new Date(b));
        },
      ],
      [activeView === 'upcoming' ? 'asc' : 'desc']
    );
    const filteredDateKeys = sortedDateKeys.filter((sessionGroupDate) => {
      if (activeView === 'all' || activeView === 'archived') {
        return true;
      }
      if (activeView === 'upcoming') {
        if (sessionGroupDate === 'null') return false;
        const dateKey = parse(sessionGroupDate, 'yyyy-MM-dd', new Date());
        return dateKey >= today;
      }
      if (activeView === 'previous') {
        if (sessionGroupDate === 'null') return false;
        const dateKey = parse(sessionGroupDate, 'yyyy-MM-dd', new Date());
        return dateKey < today;
      }
      return false;
    });

    return filteredDateKeys.map((sessionGroupDate) => {
      const dateSessions = sessionsGroupedByDate?.[sessionGroupDate] || [];
      const filteredSessions = dateSessions.filter((session) => {
        const searchString = `${session.name} ${session.location}`;
        const isArchived = session.state === 'Archived';
        if (activeView === 'archived' && !isArchived) return false;
        if (activeView !== 'archived' && isArchived) return false;
        const isCompleted =
          session.numberOfCompletedRegistrations ===
          session.totalNumberOfRegistrations;
        if (!showCompleted && isCompleted) return false;
        const doesMatchSearchString = searchString
          .toLowerCase()
          .includes(this.state.filterString.toLowerCase());
        return doesMatchSearchString;
      });
      const sortedSessions = sortBy(
        filteredSessions,
        [
          (a, b) => {
            return compareLocal(
              a.originalName || a.name,
              b.originalName || b.name
            );
          },
        ],
        ['asc']
      );
      if (filteredSessions.length === 0) return null;
      if (
        sessionGroupDate === 'null' &&
        (activeView === 'all' || activeView === 'archived')
      ) {
        return (
          <HorizontalSection
            showSeparator
            leftWidth="sm"
            key={sessionGroupDate}
            renderRight={() => (
              <ul className="training-sessions__list">
                {sortedSessions.map(this.renderTrainingSessionCard)}
              </ul>
            )}
          />
        );
      }
      if (activeView !== 'all' && sessionGroupDate === 'null') {
        return null;
      }
      const date = new Date(sessionGroupDate);
      const today = new Date();
      const dayOfMonth = getDate(date);
      const todayYear = format(today, 'yyyy');
      const sectionYear = format(date, 'yyyy');
      const shortMonth = format(date, 'MMM').replace('.', '');

      return (
        <HorizontalSection
          leftWidth="sm"
          key={sessionGroupDate}
          showSeparator
          renderLeft={() => (
            <div className="training-sessions__date">
              <Text size="lg" color="dark-grey">
                {dayOfMonth}
              </Text>
              <Text upperCase color="dark-grey">
                {shortMonth}
              </Text>
              {sectionYear !== todayYear && (
                <Text upperCase size="sm" color="dark-grey">
                  {sectionYear}
                </Text>
              )}
            </div>
          )}
          renderRight={() => (
            <ul className="training-sessions__list">
              {sortedSessions.map(this.renderTrainingSessionCard)}
            </ul>
          )}
        />
      );
    });
  };

  renderTrainingSessionCard = (session) => {
    const numberOfParticipants = session.participants.length;
    const participantsTooltip =
      session.participants.length === 0 ? null : (
        <>
          <Text>{localeLookup('translations.Participants')}:</Text>
          {session.participants.slice(0, 10).map((id) => {
            return (
              <PersonWrapper
                key={id}
                id={id}
                render={({ name, color, tooltip, suffix }) => (
                  <Text color={color}>
                    <span>
                      {name} {suffix}
                    </span>
                  </Text>
                )}
              />
            );
          })}
          {numberOfParticipants > 10 ? (
            <Text>
              {localeLookup('translations.and')} {numberOfParticipants - 10}{' '}
              {localeLookup('translations.more')}...
            </Text>
          ) : null}
        </>
      );
    const numberOfTrainers = session.trainers.length;
    const trainersTooltip =
      session.trainers.length === 0 ? null : (
        <>
          <Text>{localeLookup('translations.Trainers')}:</Text>
          {session.trainers.map((id) => {
            return (
              <PersonWrapper
                key={id}
                id={id}
                render={({ name, color, tooltip, suffix }) => (
                  <Text color={color}>
                    <span>
                      {name} {suffix}
                    </span>
                  </Text>
                )}
              />
            );
          })}
          {numberOfTrainers > 10 ? (
            <Text>
              {localeLookup('translations.and')} {numberOfTrainers - 10}{' '}
              {localeLookup('translations.more')}...
            </Text>
          ) : null}
        </>
      );
    return (
      <TrainingSessionCard
        id={session.id}
        key={session.id}
        name={session.name}
        location={session.location}
        originalName={session.originalName}
        startDate={session.startDate}
        endDate={session.endDate}
        startTime={session.startTime}
        endTime={session.endTime}
        organisers={session.organisers}
        participantsTooltip={participantsTooltip}
        trainersTooltip={trainersTooltip}
        numberOfParticipants={session.participants.length}
        numberOfTrainers={session.trainers.length}
        totalNumberOfRegistrations={session.totalNumberOfRegistrations}
        numberOfCompletedRegistrations={session.numberOfCompletedRegistrations}
        state={session.state}
        onClickArchive={this.onClickArchiveSession}
        onClickCancel={this.onClickCancelSession}
        onClickResume={this.onClickResumeSession}
        onClickRestore={this.onClickRestoreSession}
        onClickDuplicate={this.onClickDuplicateSession}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderHeader()}
        <Page>
          <MainArea defaultPadding backgroundColor="grey">
            {this.renderSections()}
          </MainArea>
        </Page>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAccessControl(WithTrainingActions(TrainingSessions)));
