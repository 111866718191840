import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const fetchTrainingAndOwnerships = () => {
  const url = `/api/trainingandownerships`;
  return axiosRequestGET(url);
};

export const fetchTrainingAndOwnershipsRole = (roleId, data) => {
  const url = `/api/trainingandownerships/role/${roleId}`;
  return axiosRequestGET(url, data);
};

export const fetchTrainingAndOwnershipsArea = (areaId, data) => {
  const url = `/api/trainingandownerships/area/${areaId}`;
  return axiosRequestGET(url, data);
};

/**
 * @typedef {Object} UpdateAreaObject
 * @property {string} employeeId Id of person to update
 * @property {string} knowledgeAreaId Id of area to update
 * @property {number} status Status to change to 1 for ongoing or 2 for completed
 */

/**
 * Service to update area status from role dashboard
 * @param {string} roleId Id of role to update
 * @param {UpdateAreaObject} updateData
 * @returns {Promise} Ajax promise
 */
export const updateRoleAreaStatus = (roleId, updateData) => {
  const url = `/api/trainingandownerships/role/${roleId}/update/status`;
  return axiosRequestPUT(url, updateData);
};

export const updateRoleAreaLinkState = (roleId, updateData) => {
  const url = `/api/trainingandownerships/role/${roleId}/update/link`;
  return axiosRequestPUT(url, updateData);
};

/**
 * @typedef {Object} UpdateRoleAreaElementObject
 * @property {string} EmployeeId Id of person to update
 * @property {string} KnowledgeElementId Id of area to update
 * @property {boolean} IsComplete
 */

/**
 * Service to update role area element status from role dashboard
 * @param {string} roleId Id of role to update
 * @param {string} areaId Id of area to update
 * @param {UpdateRoleAreaElementObject} updateData
 * @returns {Promise} Ajax promise
 */
export const updateRoleAreaElementStatus = (roleId, areaId, updateData) => {
  const url = `/api/trainingandownerships/role/${roleId}/${areaId}/update/status`;
  return axiosRequestPUT(url, updateData);
};

export const updateRoleAreaElementSignees = (roleId, areaId, updateData) => {
  const url = `/api/trainingandownerships/role/${roleId}/${areaId}/update/signee`;
  return axiosRequestPUT(url, updateData);
};

export const updateAreaElementStatus = (areaId, updateData) => {
  const url = `/api/trainingandownerships/area/${areaId}/update/status`;
  return axiosRequestPUT(url, updateData);
};

export const updateAreaElementSignees = (areaId, updateData) => {
  const url = `/api/trainingandownerships/area/${areaId}/update/signee`;
  return axiosRequestPUT(url, updateData);
};
