import React from 'react';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';
import localeLookup from '../../config/locale';
import CreateTrainingSessionForm from '../CreateTrainingSessionForm';

const CreateTrainingSessionModal = ({
  onClose,
  onCreated,
  initialValues,
}) => (
  <FormModal
    onClose={onClose}
    title={localeLookup('translations.Create training session')}
  >
    <CreateTrainingSessionForm
      onCreated={onCreated}
      initialValues={initialValues}
      renderFooter={({ handleSubmit, canSubmit }) => (
        <ModalFooter
          confirmButtonText={localeLookup('translations.Create')}
          confirmDisabled={!canSubmit}
          onConfirmClick={handleSubmit}
          onCancelClick={onClose}
        />
      )}
    />
  </FormModal>
);

export default CreateTrainingSessionModal;
