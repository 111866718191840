/* eslint-disable nonblock-statement-body-position */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import BoxMessage from '../BoxMessage';
import Icon from '../Icon';
import MultiStateButton from '../MultiStateButton';
import SimpleTable from '../SimpleTable';
import GenericModal from './GenericModal';
import MultiActionModal from './MultiActionModal';
import Text from '../Text';
import Tooltip from '../Tooltip';
import {
  connectSpacesToModulesService,
  disconnectSpacesFromModulesService,
  updateRoleSpaceService,
} from '../../services/contentService';
import { queryAreas } from '../../slices/areasSlice';
import { queryRoles } from '../../slices/rolesSlice';
import { getAllSpaces } from '../../slices/spaceSlice';
import { Legend } from '../Legend';
import { getAreasAccess } from '../../slices/editorSlice';
const mapStateToProps = (state) => ({
  roles: state.roles,
  areas: state.areas,
  spaces: state.spaces.spaces,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAreasAccess,
      queryRoles,
      queryAreas,
      getAllSpaces,
    },
    dispatch
  );

class ChangeRoleSpaceModal extends PureComponent {
  constructor(props) {
    super(props);
    const { roles, roleId, spaceId } = props;
    const role = roles[roleId];
    const currentSpaceId = role.space;

    const areaIds = [...role.areas, ...role.additionalAreas];

    this.state = {
      isLoading: false,
      areaConnections: areaIds.reduce((acc, id) => {
        return {
          ...acc,
          [id]: {
            [currentSpaceId]: this.getAreaStateForCurrentSpace(id),
            [spaceId]: this.getAreaStateForDestinationSpace(id),
          },
        };
      }, {}),
    };
    this.steps = [
      localeLookup('translations.Modules'),
      localeLookup('translations.Confirm'),
    ];
  }

  getAreaStateForCurrentSpace = (id) => {
    const { areas, roles, roleId } = this.props;
    const role = roles[roleId];
    const currentSpaceId = role.space;
    const area = areas[id];
    const currentState = Object.keys(area.editableInSpaces).includes(
      currentSpaceId
    )
      ? 'editable'
      : 'readonly';
    return currentState;
  };
  getAreaStateForDestinationSpace = (id) => {
    const { areas, spaceId: destinationSpaceId } = this.props;
    const area = areas[id];
    const isAreaEditableInDestinationSpace =
      !!area.editableInSpaces[destinationSpaceId];
    if (isAreaEditableInDestinationSpace) return 'editable';
    return 'readonly';
  };

  getConfirmationRows = () => {
    const { areaConnections } = this.state;
    const { areas, spaceId, roles, roleId, spaces } = this.props;
    const currentSpaceId = roles[roleId].space;
    const areaIds = this.getRoleAreaIds();
    const role = roles[roleId];
    const newSpace = spaces[spaceId];
    const areaIdsRequiredInRoleContentSpace =
      this.getRequiredAreaIdsForRoleContentSpace();
    return areaIds.map((id) => {
      const area = areas[id];
      const isRequired = areaIdsRequiredInRoleContentSpace.includes(id);
      return [
        area.name,
        <Tooltip
          overlayStyle={{ maxWidth: '300px', whiteSpace: 'normal' }}
          align={{ offset: [20, 0] }}
          key="1"
          tooltip={isRequired && this.getRequiredAreaTooltip(id)}
        >
          <span>
            <MultiStateButton
              isRequired={isRequired}
              //disabled={isRequired}
              onClick={(newStateId) => {
                this.onClickChangeAreaStateInSpace({
                  areaId: id,
                  spaceId: currentSpaceId,
                  newStateId,
                });
              }}
              rounded
              activeStateId={areaConnections[id][currentSpaceId]}
              states={
                isRequired
                  ? [
                      {
                        icon: 'eye',
                        stateId: 'readonly',
                        color: 'light-green',
                      },
                      { icon: 'check', stateId: 'editable', color: 'green' },
                    ]
                  : [
                      { stateId: 'remove', color: 'stroke' },
                      {
                        icon: 'eye',
                        stateId: 'readonly',
                        color: 'light-green',
                      },
                      { icon: 'check', stateId: 'editable', color: 'green' },
                    ]
              }
            />
          </span>
        </Tooltip>,
        <Tooltip
          overlayStyle={{ maxWidth: '300px', whiteSpace: 'normal' }}
          align={{ offset: [20, 0] }}
          key="2"
          tooltip={localeLookup(
            'translations.Must be either “Editable” or "Read only" to move {0} to {1}',
            [role.name, newSpace.name]
          )}
        >
          <span>
            <MultiStateButton
              isRequired
              onClick={(newStateId) => {
                this.onClickChangeAreaStateInSpace({
                  areaId: id,
                  spaceId,
                  newStateId,
                });
              }}
              rounded
              activeStateId={areaConnections[id][spaceId]}
              states={[
                { icon: 'eye', stateId: 'readonly', color: 'light-green' },
                { icon: 'check', stateId: 'editable', color: 'green' },
              ]}
            />
          </span>
        </Tooltip>,
      ];
    });
  };

  getRequiredAreaIdsForRoleContentSpace = () => {
    const { areas, roles, roleId } = this.props;
    const currentSpaceId = roles[roleId].space;
    const roleAreaIds = this.getRoleAreaIds();
    const nonMoveableAreaIds = roleAreaIds.filter((id) => {
      const area = areas[id];
      // Check if the area is editable in the roles' current content space
      if (area.editableInSpaces[currentSpaceId]) {
        // If the  area is editable in the roles' content space, it can only be removed if the area only is connected to that role
        const connectedRoleIdsInContentSpaceForArea =
          area.editableInSpaces[currentSpaceId].connectedToRoles;
        return connectedRoleIdsInContentSpaceForArea.length > 1;
      }
      if (area.readOnlyInSpaces[currentSpaceId]) {
        // If the  area is readonly in the roles' content space, it can only be removed if the area only is connected to that role
        const connectedRoleIdsInContentSpaceForArea =
          area.readOnlyInSpaces[currentSpaceId].connectedToRoles;
        return connectedRoleIdsInContentSpaceForArea.length > 1;
      }
      return false;
    });
    return nonMoveableAreaIds;
  };

  getRequiredAreaTooltip = (id) => {
    const { areas, roles, roleId } = this.props;
    const currentSpaceId = roles[roleId].space;
    const area = areas[id];
    const currentState = Object.keys(area.editableInSpaces).includes(
      currentSpaceId
    )
      ? 'editable'
      : 'readonly';
    const roleIdsInSpace =
      currentState === 'editable'
        ? area.editableInSpaces[currentSpaceId].connectedToRoles
        : area.readOnlyInSpaces[currentSpaceId].connectedToRoles;
    return (
      <p>
        {localeLookup(
          'translations.The module is required in the content space, because it is connected to the following roles'
        )}
        :
        <ul>
          {roleIdsInSpace.map((id) => {
            if (id === roleId) return null;
            return (
              <li key={id}>
                {roles[id].name}
                <br></br>
              </li>
            );
          })}
        </ul>
      </p>
    );
  };

  getRoleAreaIds = () => {
    const { roles, roleId } = this.props;
    const role = roles[roleId];
    return [...role.areas, ...role.additionalAreas];
  };

  onClickChangeAreaStateInSpace = ({ areaId, spaceId, newStateId }) => {
    const { areaConnections } = this.state;
    this.setState({
      areaConnections: {
        ...areaConnections,
        [areaId]: {
          ...areaConnections[areaId],
          [spaceId]: newStateId,
        },
      },
    });
  };

  onSubmit = async () => {
    const {
      roleId,
      roles,
      spaceId,
      queryRoles,
      queryAreas,
      getAllSpaces,
      onClose,
      getAreasAccess,
    } = this.props;
    const { areaConnections } = this.state;
    const role = roles[roleId];
    const currentSpaceId = role.space;
    const areaIds = Object.keys(areaConnections);
    await updateRoleSpaceService({
      destinationSpaceId: spaceId,
      originSpaceId: currentSpaceId,
      roleId,
      moduleConnections: areaConnections,
    });
    await Promise.all([
      getAllSpaces(),
      queryRoles([roleId]),
      queryAreas(areaIds),
      getAreasAccess(),
    ]);
    onClose();
  };

  renderAreaConnectionsModal = () => {
    const { isLoading } = this.state;
    const {
      onClose,
      title,
      onCancel,
      subtitle,
      spaceId,
      roles,
      roleId,
      spaces,
    } = this.props;
    const role = roles[roleId];
    const currentSpace = spaces[role.space];
    const newSpace = spaces[spaceId];

    return (
      <GenericModal
        subtitle={subtitle}
        message={{
          type: 'info',
          text: localeLookup(
            'translations.Modules must be in the same content space as roles they are connected to'
          ),
        }}
        //steps={this.steps}
        onClose={onClose}
        confirmDisabled={isLoading}
        title={title}
        onCancelClick={onClose}
        onConfirmClick={this.onSubmit}
        confirmButtonText={localeLookup('translations.Confirm')}
      >
        <Text>
          {localeLookup(
            'translations.The following modules are connected to the role you are moving. You can choose how the administration right for the modules should be in their current and future content space'
          )}
        </Text>
        <br></br>
        <SimpleTable
          spacingBottom={false}
          spacing="sm"
          columnAligns={['left', 'center', 'center']}
          headerRow={[
            localeLookup('translations.Knowledge area'),
            currentSpace.name,
            newSpace.name,
          ]}
          columnWidths={['40%', '30%', '30%']}
          rows={this.getConfirmationRows()}
        />
        <Legend
          items={[
            { icon: 'check', text: localeLookup('translations.Editable') },
            { icon: 'eye', text: localeLookup('translations.Read only') },
          ]}
        ></Legend>
        <Text size="sm">
          <Text as="span" color="red">
            *
          </Text>{' '}
          {localeLookup(
            'translations.The module is used in a role in the content space and can not be removed'
          )}
        </Text>
      </GenericModal>
    );
  };

  render() {
    return this.renderAreaConnectionsModal();
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeRoleSpaceModal);
