import React from 'react';
import InputLabel from './InputLabel';
import cx from 'classnames';
const Field = ({
  label,
  labelFor,
  required,
  children,
  className,
  labelSize,
  margin = 'md',
}) => {
  const modifierClasses = {
    [`field--margin-${margin}`]: margin,
  };
  return (
    <div className={cx('field', modifierClasses, className)}>
      {label && (
        <InputLabel size={labelSize} htmlFor={labelFor} required={required}>
          {label}
        </InputLabel>
      )}
      {children}
    </div>
  );
};

export default Field;
