import React from 'react';
import Checkbox from '../../components/Checkbox';
import RenderRecursive from '../../components/RenderRecursive';
import SideNavFilter from '../../components/sideNav/SideNavFilter';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import localeLookup from '../../config/locale';
import BoxMessage from '../../components/BoxMessage';

const ElementStatusOrganisationUnitFilter = ({
  filterState,
  onToggleFilter,
  organisationUnits,
  filterSearchQuery,
  onFilterSearchChange,
  organisationUnitRootNodes,
  onToggleMultipleFilters,
  isLoading,
}) => {
  const getUnitChildrenRecursively = (unitId) => {
    const unit = organisationUnits[unitId];
    const allChildren = [...unit.children];
    unit.children.forEach((unitId) => {
      if (organisationUnits[unitId].children.length > 0) {
        allChildren.push(...getUnitChildrenRecursively(unitId));
      }
    });
    return allChildren;
  };
  const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
  const renderItem = (item) => {
    return (
      <SideNavItem
        small
        element="div"
        key={`setting-${item.name}`}
        showHoverTitle
        title={item.name}
        disabled={isLoading}
        onClick={
          isLoading
            ? undefined
            : (e) => {
                if (e.nativeEvent.altKey) {
                  const children = getUnitChildrenRecursively(item.id);
                  onToggleMultipleFilters([item.id, ...children]);
                } else {
                  onToggleFilter(item.id);
                }
              }
        }
        stopRightComponentClickPropagation
        rightComponent={
          <Checkbox
            onClick={
              // For some reason, Firefox does not trigger the onChange event when clicking on the checkbox with alt key pressed
              isFirefox && !isLoading
                ? (e) => {
                    if (e.nativeEvent.altKey) {
                      const children = getUnitChildrenRecursively(item.id);
                      onToggleMultipleFilters([item.id, ...children]);
                    }
                  }
                : undefined
            }
            disabled={isLoading}
            onChange={
              isLoading
                ? undefined
                : (e) => {
                    if (e.nativeEvent.altKey) {
                      const children = getUnitChildrenRecursively(item.id);
                      onToggleMultipleFilters([item.id, ...children]);
                    } else {
                      onToggleFilter(item.id);
                    }
                  }
            }
            isChecked={!filterState.deselected.includes(item.id)}
            id={item.id}
          />
        }
      />
    );
  };

  const renderFlat = () => {
    return Object.values(organisationUnits)
      .filter((item) => {
        return item.name
          .toLowerCase()
          .includes(filterSearchQuery.toLowerCase());
      })
      .map((item) => {
        return renderItem(item);
      });
  };

  const renderTree = () => {
    return Object.values(organisationUnitRootNodes).map((unit) => {
      return (
        <RenderRecursive
          key={unit.id}
          childrenProp="children"
          childLeftMargin="0.5rem"
          nodes={organisationUnits}
          rootId={unit.id}
          render={({ isChild, node }) => {
            return renderItem(node);
          }}
        />
      );
    });
  };

  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const html = localeLookup(
    'translations.Tip: Hold {0} key on the keyboard while selecting to include underlying organisation units',
    [isMac ? '<kbd>option</kbd>' : '<kbd>Alt</kbd>']
  );

  return (
    <>
      <SideNavTitle title={localeLookup('translations.Organisation units')} />
      <BoxMessage paddingHorizontal="l">
        <p
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></p>
      </BoxMessage>
      <SideNavFilter
        autoFocus
        value={filterSearchQuery}
        onChange={onFilterSearchChange}
        placeholder={`${localeLookup('translations.Search')}...`}
      />
      {filterSearchQuery === '' ? renderTree() : renderFlat()}
    </>
  );
};

export default ElementStatusOrganisationUnitFilter;
