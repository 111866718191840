import { getAllGroupsService, getGroupService } from '../services/groupService';

// Actions
const LOAD = 'groups/LOAD';
export const REMOVE_GROUP = 'groups/REMOVE_GROUP';

export default (state = {}, action) => {
  switch (action.type) {
    case REMOVE_GROUP:
      const { [action.id]: value, ...withoutGroup } = state;
      return withoutGroup;
    case LOAD:
      return {
        ...state,
        ...action.groups,
      };

    default:
      return state;
  }
};

export const getGroups = () => (dispatch) =>
  getAllGroupsService().then(({ data }) => {
    dispatch({ type: LOAD, groups: data });
  });
export const getGroup = (id) => (dispatch) =>
  getAllGroupsService().then(({ data }) => {
    dispatch({ type: LOAD, groups: data });
    return data;
  });

export const removeGroup = (id) => (dispatch) => {
  return dispatch({ type: REMOVE_GROUP, id });
};
