import React from 'react';

const Row = ({ children, justify = 'flex-start', align = 'center' }) => {
  const getAlign = () => {
    if (align === 'start') {
      return 'flex-start';
    }
    if (align === 'end') {
      return 'flex-end';
    }
    return align;
  };
  const style = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justify,
    alignItems: getAlign(),
  };
  return (
    <div style={style} className="row">
      {children}
    </div>
  );
};

export default Row;
