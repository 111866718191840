import React from 'react';

import { linkifyString } from '../utils/helpers';
import localeLookup from '../config/locale';

const TraineeRoleDescription = ({ description }) => {

  const descriptionWithClickableLinks = linkifyString(description);

  return (
    <div className="trainee-role-description">
      <div className="trainee-role-description__header">
        <h1 className="trainee-role-description__header-title">
          {localeLookup('translations.Role description')}
        </h1>
      </div>
      <div className="trainee-role-description__description">
        <div
          className="trainee-role-description__description-text"
          dangerouslySetInnerHTML={{ __html: descriptionWithClickableLinks }}
        />
      </div>
    </div>
  );
};

export default TraineeRoleDescription;
