import React from 'react';
import localeLookup from '../../../config/locale';
import CreateRoleForm from '../../CreateRoleForm';
import FormModal from '../FormModal';
import ModalFooter from '../ModalFooter';

const CreateRoleModal = ({ onClose, persons, allowSpaceSelection, organisationUnits, onCreated, initialSelectedUnitIds, initialName, hideOrganisationUnitSelection }) => (
  <FormModal onClose={onClose} title={localeLookup('translations.Create role')}>
    <CreateRoleForm
      hideOrganisationUnitSelection={hideOrganisationUnitSelection}
      onCreated={onCreated}
      persons={persons}
      allowSpaceSelection={allowSpaceSelection}
      initialName={initialName}
      initialSelectedUnitIds={initialSelectedUnitIds}
      organisationUnits={organisationUnits}
      renderFooter={({ handleSubmit, canSubmit }) => <ModalFooter confirmButtonText={localeLookup('translations.Create')} confirmDisabled={!canSubmit} onConfirmClick={handleSubmit} onCancelClick={onClose} />}
    />
  </FormModal>
);

export default CreateRoleModal;
