import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import localeLookup from '../../config/locale';

const mapStateToProps = (state) => ({
  hasExternalUserDirectoryIntegration: state.rootmenu.hasExternalUserDirectoryIntegration,
});

const UserAdministrationOtherFiltersFilter = ({ filterState, isDefault, onClickReset, onToggleFilter, hasExternalUserDirectoryIntegration }) => {
  const { activePersons, archivedPersons, userTypeNoLogin, userTypeChamp, userTypeExternal } = filterState;
  const sections = [
    {
      title: localeLookup('translations.Status'),
      items: [
        {
          name: localeLookup('translations.Active persons'),
          selected: activePersons,
          stateKey: 'activePersons',
        },
        {
          name: localeLookup('translations.Archived persons'),
          selected: archivedPersons,
          stateKey: 'archivedPersons',
        },
      ],
    },
    {
      title: localeLookup('translations.Login'),
      items: [
        {
          name: localeLookup('translations.Without login'),
          selected: userTypeNoLogin,
          stateKey: 'userTypeNoLogin',
        },
        {
          name: localeLookup('translations.With login'),
          selected: userTypeChamp,
          stateKey: 'userTypeChamp',
        },
        {
          name: 'Active directory',
          selected: userTypeExternal,
          stateKey: 'userTypeExternal',
          visible: hasExternalUserDirectoryIntegration,
        },
      ],
    },
  ];
  const renderItem = (item) => {
    if (item.visible === false) return null;
    return (
      <SideNavItem
        small
        element="div"
        key={`setting-${item.name}`}
        disabled={item.disabled}
        showHoverTitle
        title={item.name}
        tooltip={item.tooltip}
        onClick={() => onToggleFilter(item.stateKey)}
        stopRightComponentClickPropagation
        rightComponent={<SwitchCheckbox disabled={item.disabled} onChange={() => onToggleFilter(item.stateKey)} isChecked={item.selected} id={`setting-${item.stateKey}`} />}
      />
    );
  };
  const renderSection = (section, index) => {
    if (section.groups) {
      return (
        <React.Fragment key={index}>
          <SideNavTitle size="small" title={section.title} />
          {section.groups.map((group, index) => (
            <React.Fragment key={index}>
              <SideNavTitle subtitle title={group.groupTitle} />
              {group.items.map(renderItem)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <SideNavTitle size="small" title={section.title} />
        {section.items.map(renderItem)}
      </React.Fragment>
    );
  };
  return (
    <>
      {sections.map(renderSection)}
      <Button kind="alert" disabled={isDefault} onClick={onClickReset} className="status-table__sidebar-reset-button">
        {localeLookup('translations.Reset')}
      </Button>
    </>
  );
};

export default connect(mapStateToProps)(UserAdministrationOtherFiltersFilter);
