import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const fetchUserSettings = () => {
  const url = `/api/profile/settings`;
  return axiosRequestGET(url);
};

export const updatePassword = (updateData) => {
  const url = `/api/profile/settings/password`;
  return axiosRequestPUT(url, updateData);
};

export const updateLocale = (updateData) => {
  const url = `/api/profile/settings/language`;
  return axiosRequestPUT(url, updateData);
};

export const changeNameService = (data) => {
  return axiosRequestPUT('/api/profile/settings/name', data);
};

export const updateUserSettingService = ({ name, enabled }) => axiosRequestPUT('/api/profile/settings', { name, enabled });

export const updatePreferredTenantService = (tenantId) => axiosRequestPUT('/api/Profile/settings/preferred-tenant', { preferredTenant: tenantId });
