import { AUTH_ENDPOINT } from '../constants';
import { axiosRequestPOST, axiosRequestGET } from '../services/serviceConfig';

export const loginService = (data) => axiosRequestPOST('/auth/credentials', data);

export const getAuthToken = () => axiosRequestGET('/session-to-token');

export const getLoginProviderService = (email) => axiosRequestPOST('/auth/provider', { email });

export const getUserInfo = () => axiosRequestGET(`${AUTH_ENDPOINT()}/connect/userinfo`);

export const getCSRFTokenService = () => axiosRequestGET('/api/champ/csrf-token');