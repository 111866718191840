import React from 'react';
import ButtonIcon from '../ButtonIcon';
import ScrollableTabs from '../ScrollableTabs';
import Tabs from '../Tabs';
import cx from 'classnames';
import { compareLocal, sortBy } from '../../utils/helpers';

export const EditorHeader = ({
  spaces,
  isFullscreen,
  onToggleFullscreen,
  onChangeSpace,
  activeSpaceId,
  spacesEnabled,
}) => {
  const modifierClasses = { 'designboard-header--fixed': isFullscreen };
  const tabs = sortBy(
    Object.keys(spaces).reduce((acc, id) => {
      const space = spaces[id];
      if (space.accessible) {
        return [...acc, { id, name: space.name }];
      }
      return acc;
    }, []),
    [(a, b) => compareLocal(a.name, b.name)]
  );
  return (
    <header className={cx('designboard-header', modifierClasses)}>
      <div className="designboard-header__left">
        {spacesEnabled ? (
          <ScrollableTabs
            onChangeTab={onChangeSpace}
            activeTab={activeSpaceId}
            tabs={tabs}
          />
        ) : null}
      </div>
      <div className="designboard-header__right">
        {onToggleFullscreen && (
          <ButtonIcon
            color="green"
            onClick={onToggleFullscreen}
            rounded
            size="md"
            icon={isFullscreen ? 'contract' : 'expand'}
          ></ButtonIcon>
        )}
      </div>
    </header>
  );
};
