import React from 'react';

import TraineeRole from '../containers/Profile/TraineeRole';
import TraineeRoles from '../containers/Profile/TraineeRoles';
import TrainingAndOwnerships from '../containers/Profile/TrainingAndOwnerships';
import UserProfile from '../containers/Profile/UserProfile';

import ElementStatus from '../containers/Organisation/ElementStatus';
import RoleMatrix from '../containers/Organisation/RoleMatrix';

import Designboard from '../containers/Editor/Designboard';
import KnowledgeEditor from '../containers/Editor/KnowledgeEditor';
import PageEditorKnowledge from '../containers/Editor/PageEditorKnowledge';

import PersonsPage from '../containers/Persons/PersonsPage';

import ChampLink from '../containers/ChampLink';
import ContentAdministration from '../containers/Editor/ContentAdministration';
import PermissionMatrix from '../containers/Persons/PermissionMatrix';
import Role from '../containers/Role';
import SystemSettings from '../containers/SystemSettings';
import OtherPrograms from '../containers/Training/OtherPrograms';
import Programs from '../containers/Training/Programs';
import localeLookup from './locale';
import TrainingSessions from '../containers/Training/TrainingSessions';
import TrainingSession from '../containers/Training/TrainingSession';

export default {
  profile: {
    title: localeLookup('translations.Your roles'),
    getTranslatedTitle: () => localeLookup('translations.Your roles'),
    path: '/roles',
    visibilityKey: 'yourRoles',
    icon: 'user',
    component: TraineeRoles,
    routes: [
      {
        title: null,
        visibilityKey: 'yourRoles',
        path: '/roles/:id',
        dynamic: true,
        component: TraineeRole,
      },
    ],
  },
  settings: {
    title: localeLookup('translations.Settings'),
    getTranslatedTitle: () => localeLookup('translations.Settings'),
    path: '/settings',
    component: UserProfile,
    showInMenu: false,
  },
  training: {
    title: localeLookup('translations.Training'),
    getTranslatedTitle: () => localeLookup('translations.Training'),
    icon: 'users2',
    path: '/training',
    visibilityKey: ['yourPrograms', 'otherMentorsPrograms', 'dashboards', 'trainingsessions'],
    routes: [
      {
        title: localeLookup('translations.Your programs'),
        getTranslatedTitle: () => localeLookup('translations.Your programs'),
        path: '/training/your programs',
        visibilityKey: 'yourPrograms',
        component: Programs,
        routes: [
          {
            title: null,
            path: '/training/your programs/:roleId',
            dynamic: true,
            component: (props) => <Role {...props} isMenteeContext />,
          },
        ],
      },
      {
        title: localeLookup("translations.Other mentors' programs"),
        getTranslatedTitle: () =>
          localeLookup("translations.Other mentors' programs"),
        path: "/training/other mentors' programs",
        visibilityKey: 'otherMentorsPrograms',
        component: OtherPrograms,
        routes: [
          {
            title: null,
            path: "/training/other mentors' programs/:roleId",
            dynamic: true,
            component: (props) => <Role {...props} isMenteeContext />,
          },
        ],
      },
      {
        title: localeLookup('translations.Dashboards'),
        getTranslatedTitle: () => localeLookup('translations.Dashboards'),
        path: '/training/dashboards',
        visibilityKey: 'dashboards',
        component: TrainingAndOwnerships,
      },
      {
        title: localeLookup('translations.Training sessions'),
        getTranslatedTitle: () =>
          localeLookup('translations.Training sessions'),
        path: '/training/sessions',
        visibilityKey: 'trainingsessions',
        component: TrainingSessions,
        routes: [
          {
            title: null,
            path: '/training/sessions/:sessionId',
            dynamic: true,
            component: TrainingSession,
          },
        ],
      },
    ],
  },
  champLink: {
    title: localeLookup('Champ Link'),
    getTranslatedTitle: () => localeLookup('Champ Link'),
    icon: 'champ-link-outline-2',
    path: '/champ link',
    component: ChampLink,
  },
  editor: {
    title: localeLookup('translations.Content'),
    getTranslatedTitle: () => localeLookup('translations.Content'),
    icon: 'pencil',
    path: '/editor',
    visibilityKey: ['designboard', 'moduleEditor', 'contentAdministration'],
    routes: [
      {
        title: localeLookup('translations.Design board'),
        getTranslatedTitle: () => localeLookup('translations.Design board'),
        path: '/editor/designboard',
        component: Designboard,
        visibilityKey: 'designboard',
      },
      {
        title: localeLookup('translations.Knowledge areas'),
        getTranslatedTitle: () => localeLookup('translations.Knowledge areas'),
        path: '/editor/knowledge',
        visibilityKey: 'moduleEditor',
        component: PageEditorKnowledge,
        routes: [
          {
            title: null,
            path: '/editor/knowledge/:id',
            dynamic: true,
            component: KnowledgeEditor,
          },
        ],
      },
      {
        title: localeLookup('translations.Administration'),
        getTranslatedTitle: () => localeLookup('translations.Administration'),
        path: '/editor/administration',
        component: ContentAdministration,
        visibilityKey: 'contentAdministration',
      },
    ],
  },
  organisation: {
    title: localeLookup('translations.Organisation'),
    getTranslatedTitle: () => localeLookup('translations.Organisation'),
    icon: 'site-map',
    path: '/organisation',
    visibilityKey: ['roleMatrix', 'elementStatus'],
    routes: [
      {
        title: localeLookup('translations.Role matrix'),
        getTranslatedTitle: () => localeLookup('translations.Role matrix'),
        visibilityKey: 'roleMatrix',
        path: '/organisation/rolematrix',
        component: RoleMatrix,
      },
      {
        title: localeLookup('translations.Element status'),
        getTranslatedTitle: () => localeLookup('translations.Element status'),
        path: '/organisation/elementstatus',
        visibilityKey: 'elementStatus',
        component: ElementStatus,
      },
    ],
  },

  persons: {
    title: localeLookup('translations.Persons'),
    getTranslatedTitle: () => localeLookup('translations.Persons'),
    visibilityKey: 'persons',
    icon: 'users',
    path: '/persons',
    routes: [
      {
        title: localeLookup('translations.Profiles'),
        getTranslatedTitle: () => localeLookup('translations.Profiles'),
        exact: false,
        visibilityKey: 'profiles',
        path: '/persons/all',
        component: PersonsPage,
      },
      {
        title: localeLookup('translations.Administration'),
        getTranslatedTitle: () =>
          localeLookup('translations.Administration'),
        path: '/persons/permissions',
        visibilityKey: 'userAdministration',
        component: PermissionMatrix,
      },
    ],
  },
  systemsettings: {
    visibilityKey: 'systemSettings',
    title: localeLookup('translations.System settings'),
    getTranslatedTitle: () => localeLookup('translations.System settings'),
    icon: 'cog',
    path: '/systemsettings',
    component: SystemSettings,
  },
};
