import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import { compareFalsy, compareLocal, sortBy } from '../../utils/helpers';
import BoxMessage from '../BoxMessage';
import ConditionalWrap from '../ConditionalWrapper';
import ListCard from '../ListCard';
import RadioButton from '../RadioButton';
import Text from '../Text';
import Tooltip from '../Tooltip';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import { EMPTY_ID } from '../../constants';

class RadioButtonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.selectedValue,
      filterString: '',
    };
  }

  getSortedOptions = () => {
    const { options, selectedValue, grouped } = this.props;
    if (grouped) return options;
    return sortBy(options, [
      (a, b) => {
        compareLocal(a.label, b.label);
      },
      (a, b) => {
        return compareFalsy(
          a.value === selectedValue,
          b.value === selectedValue
        );
      },
    ]);
  };

  getVisibleOptions = () => {
    const { options, grouped } = this.props;
    const { filterString } = this.state;
    const sortedOptions = this.getSortedOptions();
    const optionFilterFunction = (option) => {
      if (option.searchString) {
        return option.searchString
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return option.label.toLowerCase().includes(filterString.toLowerCase());
    };
    if (filterString.length === 0) return sortedOptions;
    if (grouped) {
      return options.reduce((acc, group) => {
        const visibleGroupOptions = group.options.filter(optionFilterFunction);
        if (visibleGroupOptions.length !== 0) {
          return [...acc, { ...group, options: visibleGroupOptions }];
        }
        return acc;
      }, []);
    }
    return sortedOptions.filter(optionFilterFunction);
  };

  onSelectedChange = (value) => {
    this.setState({
      selectedValue: value,
    });
  };

  onConfirm = () => {
    const { onConfirm, onClose } = this.props;
    const { selectedValue } = this.state;
    onClose();
    onConfirm(selectedValue);
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  renderGroup = (group, i) => {
    if (group.options.length > 0) {
      const sortedGroupOptions = sortBy(group.options, [
        (a, b) => compareLocal(a.label, b.label),
        (a, b) => compareFalsy(a.value === EMPTY_ID, b.value === EMPTY_ID),
      ]);
      return (
        <div key={i}>
          <h2 className="e-label-upper modal-content__body-title">
            {group.title}
          </h2>
          <ul className="box-list">
            {sortedGroupOptions.map(this.renderOption)}
          </ul>
        </div>
      );
    }
    return null;
  };

  renderOption = (option) => {
    const { selectedValue } = this.state;
    const isSelected = selectedValue === option.value;
    return (
      <ListCard
        clickable
        el="label"
        htmlFor={option.value}
        key={option.value}
        disabled={option.disabled}
        tooltip={option.tooltip}
        rightComponent={
          <RadioButton
            id={option.value}
            disabled={option.disabled && !isSelected}
            isChecked={isSelected}
            onChange={() => {
              this.onSelectedChange(option.value);
            }}
          />
        }
      >
        <>
          <Text color={option.labelColor}>{option.label}</Text>
          {option.subtitle && (
            <Text size="sm" color="dark-grey">
              {option.subtitle}
            </Text>
          )}
        </>
      </ListCard>
    );
  };

  render() {
    const {
      buttonText,
      filterPlaceholder = localeLookup('translations.Search'),
      confirmBtnText,
      onClose,
      onFilterButtonClick,
      titleText,
      subtitle,
      options,
      grouped,
      showFilterButton,
      infoText,
      disableConfirmIfValueIsNull,
    } = this.props;
    const { filterString, selectedValue } = this.state;
    const visibleOptions = options && this.getVisibleOptions();
    return (
      <ModalWrapper>
        <ModalHeader title={titleText} subtitle={subtitle} onClose={onClose} />
        {infoText && (
          <BoxMessage spacing="md" icon="info-circle">
            {infoText}
          </BoxMessage>
        )}
        <ModalFilter
          placeholder={`${filterPlaceholder}...`}
          onChange={this.onFilterChange}
          value={filterString}
          onButtonClick={() => onFilterButtonClick(filterString)}
          buttonIcon="plus-circle"
          buttonText={buttonText}
          showButton={showFilterButton}
        />
        <ModalBody deep>
          {grouped ? (
            this.getVisibleOptions().map(this.renderGroup)
          ) : (
            <ul className="box-list">
              {this.getVisibleOptions().map(this.renderOption)}
            </ul>
          )}
          {visibleOptions.length === 0 && (
            <div className="modal-content__body-info-message">
              <p>{localeLookup('translations.No matches for')}</p>
              <p>'{filterString}'</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmDisabled={
            disableConfirmIfValueIsNull && selectedValue === null
          }
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}

export default RadioButtonModal;
