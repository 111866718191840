import { merge } from 'lodash';
import {
  STATUS_TABLE_CATEGORIES_RECEIVED,
  STATUS_TABLE_KNOWLEDGE_AREAS_RECEIVED,
  STATUS_TABLE_KNOWLEDGE_ELEMENTS_RECEIVED,
  STATUS_TABLE_ORGANISATION_UNITS_RECEIVED,
  STATUS_TABLE_ORGANISATION_UNIT_ROOT_NOTES_RECEIVED,
  STATUS_TABLE_PERSONS_ELEMENT_STATUS_RECEIVED,
  STATUS_TABLE_PERSONS_RECEIVED,
  STATUS_TABLE_PRESET_FILTERS_RECEIVED,
  STATUS_TABLE_ROLES_RECEIVED,
  STATUS_TABLE_WILDCARD_PERSONS_RECEIVED,
} from '../actions/statusTableActions';

const initialState = {
  categories: {},
  knowledgeAreas: {},
  knowledgeElements: {},
  organisationUnits: {},
  persons: {},
  roles: {},
  wildcardPersons: {},
  presetFilters: {},
  hasLoadedStatus: false,
};

const statusTable = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_TABLE_CATEGORIES_RECEIVED:
      return {
        ...state,
        categories: action.categories,
      };
    case STATUS_TABLE_KNOWLEDGE_AREAS_RECEIVED:
      return {
        ...state,
        knowledgeAreas: action.knowledgeAreas,
      };
    case STATUS_TABLE_KNOWLEDGE_ELEMENTS_RECEIVED:
      return {
        ...state,
        knowledgeElements: action.knowledgeElements,
      };
    case STATUS_TABLE_ORGANISATION_UNITS_RECEIVED:
      return {
        ...state,
        organisationUnits: action.organisationUnits,
      };
    case STATUS_TABLE_ORGANISATION_UNIT_ROOT_NOTES_RECEIVED:
      return {
        ...state,
        organisationUnitRootNodes: action.organisationUnitRootNodes,
      };
    case STATUS_TABLE_PERSONS_ELEMENT_STATUS_RECEIVED:
      return {
        ...state,
        persons: addElementStatusToPersons(state.persons, action.persons),
        hasLoadedStatus: true,
      };
    case STATUS_TABLE_PRESET_FILTERS_RECEIVED:
      return {
        ...state,
        presetFilters: action.presetFilters,
      };
    case STATUS_TABLE_PERSONS_RECEIVED:
      return {
        ...state,
        persons: action.persons,
      };
    case STATUS_TABLE_ROLES_RECEIVED:
      return {
        ...state,
        roles: action.roles,
      };
    case STATUS_TABLE_WILDCARD_PERSONS_RECEIVED:
      return {
        ...state,
        wildcardPersons: action.wildcardPersons,
      };
    default:
      return state;
  }
};

const addElementStatusToPersons = (statePersons, personsWithElementStatus) =>
  Object.keys(statePersons).reduce((acc, id) => {
    acc[id] = merge(statePersons[id], personsWithElementStatus[id]);
    return acc;
  }, {});

export default statusTable;
