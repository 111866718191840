import cx from 'classnames';
import dayjs from 'dayjs';
import { debounce, isEqual, throttle } from 'lodash';
import React, { PureComponent } from 'react';
import {
  AutoSizer,
  MultiGrid,
  defaultCellRangeRenderer,
} from 'react-virtualized';
import localeLookup from '../config/locale';
import { editPersonRoleTrainingService } from '../services/personsService';
import {
  compareDates,
  compareFalsy,
  compareLocal,
  sortBy,
} from '../utils/helpers';
import Button from './Button';
import GanttTask from './GanttTask';
import WithModals from './HOC/withModals';
import RangeSlider from './formElements/RangeSlider';
import GanttChartTaskIndicator from './GanttChartTaskIndicator';

class GanttChart extends PureComponent {
  constructor() {
    super();
    // When selecting "Open in element status" we preserve zoom and scroll in sessionStorage
    const columnWidth = sessionStorage.getItem('ganttChartColumnWidth');

    this.state = {
      columnWidth: columnWidth ? parseInt(columnWidth) : 24,
      numberOfDays: 0,
      sortedTasks: [],
      sortedGroups: [],
      scrollBarInfo: { horizontal: false, size: 12, vertical: false },
      contextMenuPosition: { x: 0, y: 0 },
      contextMenuTrigger: null,
      contextMenuTaskInfo: {},
    };
    sessionStorage.removeItem('ganttChartColumnWidth');
    this.updateTasksOnPropChange = true;
  }

  componentDidMount() {
    this.setDateArray(this.setSectionedTasks);

    setTimeout(() => {
      const todayColumnIndex = this.getDateIndex(dayjs());
      const gridScrollingContainer =
        this.gridRef?._bottomRightGrid?._scrollingContainer;
      const offset = this.gridRef?._bottomRightGrid?.getOffsetForCell({
        alignment: 'center',
        columnIndex: todayColumnIndex,
      });
      // When selecting "Open in element status" we preserve zoom and scroll in sessionStorage
      const scrollLeft = sessionStorage.getItem('ganttChartScrollLeft');
      const scrollTop = sessionStorage.getItem('ganttChartScrollTop');

      gridScrollingContainer?.scrollTo({
        left: scrollLeft || offset.scrollLeft,
        top: scrollTop || 0,
        behavior: 'instant',
      });

      sessionStorage.removeItem('ganttChartScrollLeft');
      sessionStorage.removeItem('ganttChartScrollTop');
      this.gridRef?.recomputeGridSize();
    }, 0);
    window.addEventListener('resize', this.onResizeWindow);
  }

  componentDidUpdate(prevProps) {
    const { sorting, grouping, tasks, extraData, progressView, hideCursorBox } =
      this.props;
    if (
      prevProps.sorting !== sorting ||
      prevProps.grouping !== grouping ||
      prevProps.progressView !== progressView
    ) {
      this.gridRef?.forceUpdate();
      this.setSectionedTasks();
      this.gridRef?.recomputeGridSize();
    }

    if (prevProps.hideCursorBox !== hideCursorBox) {
      this.gridRef?.recomputeGridSize();
    }

    if (!isEqual(prevProps.tasks, tasks)) {
      if (this.updateTasksOnPropChange) {
        this.gridRef?.recomputeGridSize();
        this.gridRef?.forceUpdate();
        this.setSectionedTasks();
      } else {
        this.updateTasksOnPropChange = true;
      }
    }
    if (prevProps.extraData !== extraData) {
      this.gridRef?.recomputeGridSize();
      this.gridRef?.forceUpdate();
      this.setSectionedTasks();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow);
  }

  getHeaderHeight = () => {
    const headerUnderlayHeight = this.getHeaderRows().reduce((acc, row) => {
      return acc + row.height;
    }, 0);
    return headerUnderlayHeight;
  };

  getTaskLeftPosition = (startDate) => {
    const { columnWidth } = this.state;
    const columnStartIndex = this.getDateIndex(startDate);
    const leftPosition = columnStartIndex * columnWidth;
    return leftPosition;
  };

  getTaskRightPosition = (startDate, endDate, relativeTo = 'end') => {
    const { columnWidth, numberOfDays } = this.state;
    if (relativeTo === 'end') {
      const gridWidth = numberOfDays * columnWidth;
      const columnEndIndex = () => {
        if (endDate) {
          return this.getDateIndex(endDate || dayjs());
        } else if (dayjs(startDate).isAfter(dayjs())) {
          return this.getDateIndex(dayjs(startDate));
        } else {
          return this.getDateIndex(dayjs());
        }
      };
      const rightPosition = gridWidth - (columnEndIndex() + 1) * columnWidth;
      return rightPosition;
    } else {
      const columnEndIndex = () => {
        if (endDate) {
          return this.getDateIndex(endDate || dayjs());
        } else if (dayjs(startDate).isAfter(dayjs())) {
          return this.getDateIndex(dayjs(startDate));
        } else {
          return this.getDateIndex(dayjs());
        }
      };
      const rightPosition = columnEndIndex() * columnWidth;
      return rightPosition;
    }
  };

  getScrollBarSize = () => {
    return 12;
  };

  setDateArray = (cb) => {
    const today = dayjs().startOf('day');
    const firstDate = today
      .subtract(1, 'year')
      .set('date', 1)
      .set('month', 1)
      .startOf('day');
    const lastDate = today
      .add(2, 'year')
      .set('date', 1)
      .set('month', 1)
      .startOf('day');
    const diffInDays = Math.ceil(lastDate.diff(firstDate, 'day', true));
    const todayIndex = Math.ceil(today.diff(firstDate, 'day', true));
    this.setState(
      {
        todayIndex,
        startDate: firstDate,
        endDate: lastDate,
        numberOfDays: diffInDays,
      },
      () => cb?.()
    );
  };

  getDateIndex = (date) => {
    const { startDate } = this.state;
    if (!date || date === '') return 0;
    const dateObject = dayjs(date).startOf('day');
    const diffInDays = Math.ceil(dateObject.diff(startDate, 'day', true));
    return diffInDays;
  };

  getHeaderRows = () => {
    return [
      { name: 'years', height: 16 },
      { name: 'months', height: 16 },
      { name: 'days', height: 32 },
    ];
  };

  getRowCount = () => {
    const { grouping } = this.props;
    const headerRows = this.getHeaderRows();
    const numberOfHeaderRows = headerRows.length;
    if (grouping === 'none') return numberOfHeaderRows + 1;
    return numberOfHeaderRows + 1;
  };

  getRowHeight = ({ index }) => {
    const { sortedGroups } = this.state;
    const { tasks, grouping } = this.props;
    const headerHeight = 80;
    const TASK_HEIGHT = 48;
    const gridHeight = this.gridRef?.props?.height;
    if (index === 0 || index === 1) return 16;
    if (index === 2) return 32;

    if (grouping === 'none') {
      const heightOfRows = tasks.length * TASK_HEIGHT;
      if (heightOfRows < gridHeight - headerHeight) {
        return gridHeight - headerHeight;
      }
      return heightOfRows;
    } else {
      const numberOfGroups = sortedGroups.length;
      const numberOfTasks = sortedGroups.reduce((acc, group) => {
        return acc + group.tasks.length;
      }, 0);
      const heightOfRowsAndGroups =
        numberOfTasks * TASK_HEIGHT + numberOfGroups * TASK_HEIGHT;
      if (heightOfRowsAndGroups < gridHeight - headerHeight) {
        return gridHeight - headerHeight;
      }
      return heightOfRowsAndGroups;
    }
  };

  getRows = () => {
    return [...this.getHeaderRows(), ...this.getTaskRows()];
  };

  getScrollBarSize = () => 12;

  getTasksGroupedByMentor = (sortedTasks) => {
    const groupedTasks = sortedTasks.reduce((acc, task) => {
      if (acc[task.mentorId]) {
        return {
          ...acc,
          [task.mentorId]: {
            ...acc[task.mentorId],
            tasks: [...acc[task.mentorId].tasks, task],
          },
        };
      }
      return {
        ...acc,
        [task.mentorId]: {
          title: task.mentor,
          id: task.mentorId,
          tasks: [task],
        },
      };
    }, {});
    return groupedTasks;
  };

  getTasksGroupedByOrganisationUnit = (sortedTasks) => {
    let groupedTasks = {};
    sortedTasks.forEach((task) => {
      task.organisationUnits.forEach((organisationUnit) => {
        if (groupedTasks[organisationUnit.id]) {
          groupedTasks[organisationUnit.id].tasks.push(task);
        } else {
          groupedTasks[organisationUnit.id] = {
            title: `${organisationUnit.name} ${
              organisationUnit.state && organisationUnit.state === 'Passive'
                ? `(${localeLookup('translations.Limited visibility')})`
                : ''
            }`,
            id: organisationUnit.id,
            tasks: [task],
          };
        }
      });
    });
    return groupedTasks;
  };

  getTasksGroupedByPerson = (sortedTasks) => {
    const groupedTasks = sortedTasks.reduce((acc, task) => {
      if (acc[task.personId]) {
        return {
          ...acc,
          [task.personId]: {
            ...acc[task.personId],
            tasks: [...acc[task.personId].tasks, task],
          },
        };
      }
      return {
        ...acc,
        [task.personId]: {
          title: task.person,
          id: task.personId,
          tasks: [task],
        },
      };
    }, {});
    return groupedTasks;
  };

  getTasksGroupedByRole = (sortedTasks) => {
    const groupedTasks = sortedTasks.reduce((acc, task) => {
      if (acc[task.roleId]) {
        return {
          ...acc,
          [task.roleId]: {
            ...acc[task.roleId],
            tasks: [...acc[task.roleId].tasks, task],
          },
        };
      }
      return {
        ...acc,
        [task.roleId]: {
          title: task.role,
          id: task.roleId,
          tasks: [task],
        },
      };
    }, {});
    return groupedTasks;
  };

  getTaskVisibilityInfo = (task) => {
    const { leftPosition, rightPositionRelativeToLeft } = task;
    const { scrollLeft } = this.state;
    const hasEndDate = task.endDate && task.endDate !== '';
    const scrollContainerWidth =
      this.gridScrollContainerElement?.getBoundingClientRect()?.width;
    if (!this.gridScrollContainerElement) {
      return { isVisible: true };
    }
    const isOutOfViewRight = leftPosition > scrollLeft + scrollContainerWidth;
    // If task does not have end date we compensate for icon to ensure no overlap
    const isOutOfViewLeft = hasEndDate
      ? rightPositionRelativeToLeft < scrollLeft
      : rightPositionRelativeToLeft + 64 < scrollLeft;
    const isTaskVisible = !isOutOfViewLeft && !isOutOfViewRight;
    if (isTaskVisible) {
      return { isVisible: true, direction: null };
    } else {
      const direction = isOutOfViewLeft ? 'left' : 'right';
      return { isVisible: false, direction };
    }
  };

  setSectionedTasks = () => {
    const { tasks, grouping, sorting } = this.props;

    const tasksWithPositions = tasks.map((task) => {
      return {
        ...task,
        leftPosition: this.getTaskLeftPosition(task.startDate),
        rightPosition: this.getTaskRightPosition(task.startDate, task.endDate),
        rightPositionRelativeToLeft: this.getTaskRightPosition(
          task.startDate,
          task.endDate,
          'start'
        ),
      };
    });

    const sortedTasks =
      sorting === 'startDate'
        ? sortBy(
            tasksWithPositions,
            [
              (a, b) => {
                return compareDates(a.startDate, b.startDate);
              },
            ],
            ['asc']
          )
        : sortBy(
            tasksWithPositions,
            [
              (a, b) => {
                return compareFalsy(a.endDate === '', b.endDate === '');
              },
              (a, b) => {
                return compareDates(a.endDate, b.endDate);
              },
            ],
            ['asc', 'asc']
          );

    if (grouping === 'none') {
      this.setState({ sortedTasks });
    } else {
      let groupedTasks;

      switch (grouping) {
        case 'mentor':
          groupedTasks = this.getTasksGroupedByMentor(sortedTasks);
          break;
        case 'organisationUnit':
          groupedTasks = this.getTasksGroupedByOrganisationUnit(sortedTasks);
          break;
        case 'person':
          groupedTasks = this.getTasksGroupedByPerson(sortedTasks);
          break;
        case 'role':
          groupedTasks = this.getTasksGroupedByRole(sortedTasks);
          break;
        default:
          break;
      }
      const sortedGroups = sortBy(
        Object.values(groupedTasks),
        [
          (a, b) => {
            return compareLocal(a.title, b.title);
          },
        ],
        ['asc']
      );
      this.setState({ sortedGroups: sortedGroups });
    }
  };

  setTaskPositionsThrottled = throttle(() => {
    const { sortedGroups, sortedTasks } = this.state;
    const { grouping } = this.props;
    if (grouping !== 'none') {
      this.setState({
        sortedGroups: sortedGroups.map((group) => {
          return {
            ...group,
            tasks: group.tasks.map((task) => {
              return {
                ...task,
                leftPosition: this.getTaskLeftPosition(task.startDate),
                rightPosition: this.getTaskRightPosition(
                  task.startDate,
                  task.endDate
                ),
                rightPositionRelativeToLeft: this.getTaskRightPosition(
                  task.startDate,
                  task.endDate,
                  'start'
                ),
              };
            }),
          };
        }),
      });
    } else {
      const tasksWithPositions = sortedTasks.map((task) => {
        return {
          ...task,
          leftPosition: this.getTaskLeftPosition(task.startDate),
          rightPosition: this.getTaskRightPosition(
            task.startDate,
            task.endDate
          ),
          rightPositionRelativeToLeft: this.getTaskRightPosition(
            task.startDate,
            task.endDate,
            'start'
          ),
        };
      });
      this.setState({ sortedTasks: tasksWithPositions });
    }
  }, 100);

  getTaskRows = () => {
    const { tasks } = this.props;
    return tasks;
  };

  onClickShowToday = () => {
    const todayColumnIndex = this.getDateIndex(dayjs());
    const offset = this.gridRef?._bottomRightGrid?.getOffsetForCell({
      alignment: 'center',
      columnIndex: todayColumnIndex,
    });
    this.gridRef?._bottomRightGrid?._scrollingContainer?.scrollTo({
      left: offset.scrollLeft,
      behavior: 'smooth',
    });
  };

  onChangeEndDate = ({
    newDate,
    roleId,
    mentorId,
    personId,
    startDate,
    roleState,
  }) => {
    const { onChangeTrainingProgram } = this.props;
    this.updateTasksOnPropChange = false;
    return editPersonRoleTrainingService(personId, roleId, {
      mentorId,
      startDate,
      endDate: newDate,
      state: roleState
    }).then(() => {
      return onChangeTrainingProgram({ personId }).then(() => {
        this.updateTask({
          personId,
          roleId,
          startDate,
          endDate: newDate,
          mentorId,
        });
      });
    });
  };

  onChangeStartDate = ({
    newDate,
    roleId,
    mentorId,
    personId,
    endDate,
    roleState,
  }) => {
    const { onChangeTrainingProgram } = this.props;
    this.updateTasksOnPropChange = false;
    return editPersonRoleTrainingService(personId, roleId, {
      mentorId,
      startDate: newDate,
      endDate,
      state: roleState
    }).then(() => {
      return onChangeTrainingProgram({ personId }).then(() => {
        this.updateTask({
          personId,
          roleId,
          startDate: newDate,
          endDate,
          mentorId,
        });
      });
    });
  };

  onChangeZoomLevel = (value) => {
    const { columnWidth, numberOfDays } = this.state;
    if (parseInt(value) === columnWidth) return;
    const newColumnWidth = parseInt(value);

    const scrollEl = this.gridRef?._bottomRightGrid?._scrollingContainer;
    const previousScrollWidth = numberOfDays * columnWidth;
    const previousScrollLeft = scrollEl.scrollLeft + scrollEl.clientWidth / 2;

    this.setState({ columnWidth: newColumnWidth }, () => {
      this.gridRef?.recomputeGridSize();
      this.setTaskPositionsThrottled();
      const newScrollWidth = newColumnWidth * numberOfDays;
      const contentSizeChangePercentage = newScrollWidth / previousScrollWidth;
      scrollEl.scrollLeft =
        previousScrollLeft * contentSizeChangePercentage -
        scrollEl.clientWidth / 2;
    });
  };

  onClickTask = (e, { personId, roleId }) => {
    const { onClickTask } = this.props;
    onClickTask({ e, personId, roleId });
  };

  onClickTaskIndicator = (task) => {
    const taskColumnIndex = this.getDateIndex(task.startDate);
    const offset = this.gridRef?._bottomRightGrid?.getOffsetForCell({
      alignment: 'start',
      columnIndex: taskColumnIndex,
    });
    this.gridRef?._bottomRightGrid?._scrollingContainer?.scrollTo({
      left: offset.scrollLeft,
      behavior: 'smooth',
    });
  };

  setScrollDebounced = throttle((scrollLeft, scrollTop) => {
    this.setState({ scrollLeft, scrollTop });
  }, 100);

  onGridScroll = (e) => {
    const { onScroll } = this.props;
    onScroll?.(e);
    this.setScrollDebounced(e.scrollLeft, e.scrollTop);
    const taskIndicators = this.taskIndicatorsRef;
    if (taskIndicators) {
      taskIndicators.scrollTop = e.scrollTop;
    }
  };

  onResizeWindow = debounce(() => {
    this.gridRef?.recomputeGridSize();
  }, 100);

  onScrollbarPresenceChange = ({ horizontal, size, vertical }) => {
    this.setState({ scrollBarInfo: { horizontal, size, vertical } });
  };

  renderCell = ({ columnIndex, key, rowIndex, style }) => {
    const { todayIndex, columnWidth, startDate } = this.state;
    const date = startDate.add(columnIndex, 'day');
    // Render years
    if (rowIndex === 0) {
      if (
        date.get('date') === 1 &&
        date.get('month') === 0 &&
        columnWidth >= 24
      ) {
        return (
          <div className="gantt-chart__header-cell" style={style} key={key}>
            {date.format('YYYY')}
          </div>
        );
      }
      return null;
    }
    // Render months
    if (rowIndex === 1) {
      if (date.get('date') === 1 && columnWidth >= 24) {
        return (
          <div className="gantt-chart__header-cell" style={style} key={key}>
            {date.format('MMMM')} {date.format('YYYY')}
          </div>
        );
      } else if (date.get('date') === 1 && date.get('month') === 0) {
        return (
          <div className="gantt-chart__header-cell" style={style} key={key}>
            {date.format('YYYY')}
          </div>
        );
      }
      return null;
    }
    // Render days
    if (rowIndex === 2) {
      if (columnWidth >= 24) {
        return (
          <div
            className={cx(
              'gantt-chart__header-cell',
              'gantt-chart__header-cell--day',
              {
                'gantt-chart__header-cell--day-hidden': columnWidth < 24,
                'gantt-chart__header-cell--day-first': date.get('date') === 1,
                'gantt-chart__header-cell--day-today':
                  columnIndex === todayIndex,
              }
            )}
            style={style}
            key={key}
          >
            <span>{date.format('D')}</span>
          </div>
        );
      } else if (date.get('date') === 1) {
        return (
          <div
            className="gantt-chart__header-cell gantt-chart__header-cell--left-border"
            style={style}
            key={key}
          >
            {columnWidth >= 5 ? date.format('MMMM') : date.format('MMM')}{' '}
            {columnWidth >= 5 && date.format('YYYY')}
          </div>
        );
      }
      return null;
    }
    // Render grid
    const dateWeekDay = date.get('day');
    const dateDate = date.get('date');
    return (
      <div
        className={cx('gantt-chart__cell', {
          'gantt-chart__cell--no-border': columnWidth < 5,
          'gantt-chart__cell--left-border': columnWidth < 5 && dateDate === 1,
          'gantt-chart__cell--grey':
            columnWidth >= 5 ? dateWeekDay === 0 || dateWeekDay === 6 : false,
        })}
        style={style}
        key={key}
      ></div>
    );
  };

  cellRangeRenderer = (props) => {
    const children = defaultCellRangeRenderer(props);
    if (
      props.parent.props.className === 'gantt-chart__grid__bottom-right-grid'
    ) {
      // Operating on bottom right grid
      children.push(
        <div key="date-indicator">{this.renderDateIndicator()}</div>
      );
      children.push(<div key="tasks">{this.renderTasks()}</div>);
    }
    return children;
  };

  updateTask = ({ personId, roleId, startDate, endDate, mentorId }) => {
    const { sortedGroups } = this.state;
    const { grouping } = this.props;
    if (grouping !== 'none') {
      this.setState(
        {
          sortedGroups: sortedGroups.map((group) => {
            return {
              ...group,
              tasks: group.tasks.map((task) => {
                if (task.personId === personId && task.roleId === roleId) {
                  return {
                    ...task,
                    startDate: dayjs(startDate),
                    endDate: endDate ? dayjs(endDate) : '',
                    mentorId,
                    leftPosition: this.getTaskLeftPosition(dayjs(startDate)),
                    rightPosition: this.getTaskRightPosition(
                      dayjs(startDate),
                      endDate ? dayjs(endDate) : null
                    ),
                    rightPositionRelativeToLeft: this.getTaskRightPosition(
                      dayjs(startDate),
                      endDate ? dayjs(endDate) : null,
                      'start'
                    ),
                  };
                }
                return task;
              }),
            };
          }),
        },
        () => {
          this.gridRef?.recomputeGridSize();
          this.gridRef?.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          sortedTasks: this.state.sortedTasks.map((task) => {
            if (task.personId === personId && task.roleId === roleId) {
              return {
                ...task,
                startDate: dayjs(startDate),
                endDate: endDate ? dayjs(endDate) : '',
                mentorId,
                leftPosition: this.getTaskLeftPosition(dayjs(startDate)),
                rightPosition: this.getTaskRightPosition(
                  dayjs(startDate),
                  endDate ? dayjs(endDate) : null
                ),
                rightPositionRelativeToLeft: this.getTaskRightPosition(
                  dayjs(startDate),
                  endDate ? dayjs(endDate) : null,
                  'start'
                ),
              };
            }
            return task;
          }),
        },
        () => {
          this.gridRef?.recomputeGridSize();
          this.gridRef?.forceUpdate();
        }
      );
    }
  };

  renderDateIndicator = () => {
    const { columnWidth, todayIndex } = this.state;
    return (
      <div
        style={{ left: columnWidth * todayIndex + columnWidth / 2 }}
        className="gantt-chart__date-indicator"
      ></div>
    );
  };

  renderTasks = () => {
    const { sortedGroups, sortedTasks } = this.state;
    const { grouping } = this.props;
    if (grouping === 'none') {
      return (
        <div className="gantt-chart__tasks">
          {sortedTasks.map((task, index) => {
            return this.renderTask(task, index);
          })}
        </div>
      );
    } else {
      return (
        <div className="gantt-chart__tasks">
          {sortedGroups.map((group, index) => {
            return (
              <React.Fragment key={`section-${grouping}-${index}`}>
                <div className="gantt-chart__title-placeholder"></div>
                {group.tasks.map((task, index) =>
                  this.renderTask(task, index, group, group.id)
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  renderTask = (
    {
      endDate,
      startDate,
      personInitials,
      mentorInitials,
      showMentorError,
      mentorSuffix,
      role,
      progress,
      progressOverTime,
      roleId,
      personId,
      mentorId,
      mentor,
      person,
      personEmployeeNumber,
      mentorEmployeeNumber,
      canEdit,
      canClick,
      mentorIsWildcardPerson,
      roleState,
      isAllRoleOrganisationUnitsPassive
    },
    index,
    group,
    groupId
  ) => {
    const { progressView, hideCursorBox } = this.props;
    const { columnWidth, numberOfDays } = this.state;
    const gridWidth = numberOfDays * columnWidth;
    const leftPosition = this.getTaskLeftPosition(startDate);
    const rightPosition = this.getTaskRightPosition(startDate, endDate);

    const shownEndDate = () => {
      if (endDate) {
        return endDate;
      } else if (dayjs(startDate).isAfter(dayjs())) {
        return dayjs(startDate);
      } else {
        return dayjs();
      }
    };
    return (
      <GanttTask
        hideCursorBox={hideCursorBox}
        dragSnapInterval={columnWidth}
        endDate={endDate}
        groupId={groupId}
        key={`${group ? group.title : ''}${index}`}
        leftPosition={leftPosition}
        mentor={mentor}
        mentorEmployeeNumber={mentorEmployeeNumber}
        mentorIsWildcardPerson={mentorIsWildcardPerson}
        mentorInitials={mentorInitials}
        mentorSuffix={mentorSuffix}
        showMentorError={showMentorError}
        mentorId={mentorId}
        onChangeEndDate={this.onChangeEndDate}
        onChangeStartDate={this.onChangeStartDate}
        canClick={canClick}
        canEdit={canEdit}
        onClick={this.onClickTask}
        person={person}
        personEmployeeNumber={personEmployeeNumber}
        personInitials={personInitials}
        personId={personId}
        progress={progress}
        progressOverTime={progressOverTime}
        rightPosition={rightPosition}
        role={role}
        roleId={roleId}
        shownEndDate={shownEndDate()}
        startDate={startDate}
        taskIndex={index}
        width={gridWidth - leftPosition - rightPosition}
        progressView={progressView}
        roleState={roleState}
        isAllRoleOrganisationUnitsPassive={isAllRoleOrganisationUnitsPassive}
      ></GanttTask>
    );
  };

  renderTaskIndicators = () => {
    const { sortedGroups, sortedTasks, scrollBarInfo } = this.state;
    const { grouping } = this.props;

    if (grouping === 'none') {
      return (
        <div
          ref={(el) => (this.taskIndicatorsRef = el)}
          className="gantt-chart__task-indicators"
          style={{
            top: this.getHeaderHeight(),
            right: scrollBarInfo.vertical ? scrollBarInfo.size : 0,
          }}
        >
          {sortedTasks.map((task, i) => {
            const visibilityInfo = this.getTaskVisibilityInfo(task);
            return (
              <GanttChartTaskIndicator
                key={i}
                onClick={this.onClickTaskIndicator}
                isVisible={visibilityInfo.isVisible}
                task={task}
                direction={visibilityInfo.direction}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div
          ref={(el) => (this.taskIndicatorsRef = el)}
          className="gantt-chart__task-indicators"
          style={{
            top: this.getHeaderHeight(),
            right: scrollBarInfo.vertical ? scrollBarInfo.size : 0,
          }}
        >
          {sortedGroups.map((group, i) => {
            const groupId = group.id;
            return (
              <React.Fragment key={i}>
                <div className="gantt-chart__task-indicator-section">
                  <button
                    className="gantt-chart__task-indicator-button"
                    onClick={() => this.onClickTaskIndicator(group.tasks[0])}
                  >
                    <h2 className="gantt-chart__task-indicator-section-title">
                      {group.title}
                    </h2>
                  </button>
                </div>
                {group.tasks.map((task, i) => {
                  if (!task) return null;
                  const visibilityInfo = this.getTaskVisibilityInfo(task);
                  return (
                    <GanttChartTaskIndicator
                      key={i}
                      onClick={this.onClickTaskIndicator}
                      isVisible={visibilityInfo.isVisible}
                      task={task}
                      direction={visibilityInfo.direction}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { columnWidth, numberOfDays } = this.state;
    return (
      <div className="gantt-chart" id="gantt-chart">
        <div
          style={{ height: this.getHeaderHeight() }}
          className="gantt-chart__header-underlay"
        ></div>
        <div className="gantt-chart__grid">
          <AutoSizer nonce={cspNonce}>
            {({ width, height }) => {
              return (
                <MultiGrid
                  ref={(el) => {
                    this.gridRef = el;
                    this.gridScrollContainerElement =
                      el?._bottomRightGrid?._scrollingContainer;
                  }}
                  cellRangeRenderer={this.cellRangeRenderer}
                  cellRenderer={this.renderCell}
                  classNameBottomRightGrid="gantt-chart__grid__bottom-right-grid"
                  columnCount={numberOfDays}
                  columnWidth={columnWidth}
                  estimatedColumnSize={columnWidth}
                  fixedRowCount={3}
                  getScrollbarSize={this.getScrollBarSize}
                  height={height}
                  onScroll={this.onGridScroll}
                  onScrollbarPresenceChange={this.onScrollbarPresenceChange}
                  rowCount={this.getRowCount()}
                  rowHeight={this.getRowHeight}
                  width={width}
                />
              );
            }}
          </AutoSizer>
          {this.renderTaskIndicators()}
        </div>
        <footer className="gantt-chart__footer">
          <div className="gantt-chart__footer-left">
            <RangeSlider
              showControls
              value={columnWidth}
              min={2}
              max={43}
              onChange={this.onChangeZoomLevel}
            ></RangeSlider>
          </div>
          <div className="gantt-chart__footer-center">
            <Button
              onClick={this.onClickShowToday}
              className="element-history-event-approval__signature-button"
              kind="link-style"
            >
              {localeLookup('translations.Go to today')}
            </Button>
          </div>
          <div className="gantt-chart__footer-right"></div>
        </footer>
      </div>
    );
  }
}

export default WithModals(GanttChart);
