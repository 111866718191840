import cx from 'classnames';
import React from 'react';
import Icon from './Icon';

const BoxMessage = ({ icon, children, type, spacing, paddingHorizontal = 'md' }) => {
  const modifierClasses = {
    [`box-message--type-${type}`]: type,
    [`box-message--spacing-${spacing}`]: spacing,
    [`box-message--padding-horizontal-${paddingHorizontal}`]: paddingHorizontal,
  };
  return (
    <div className={cx('box-message', modifierClasses)}>
      {icon && (
        <Icon
          size="xlarge"
          className="box-message__icon"
          kind={icon || 'warning'}
        />
      )}
      <p className="box-message__text">{children}</p>
    </div>
  );
};

export default BoxMessage;
