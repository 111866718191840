import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../config/locale';
import { EMPTY_ID, LOCK_STATES, PERSON_STATES } from '../constants';
import { compareLocal, sortBy } from '../utils/helpers';
import WithModals from './HOC/withModals';
import withPersonLookup from './HOC/withPersonLookup';
import KnowledgeArea from './KnowledgeArea';
import MainArea from './MainArea';
import Modal from './Modal';
import RadioButtonModal from './modal/RadioButtonModal';
import PersonWrapper from './PersonWrapper';
import Portal from './Portal';
import RoleNav from './RoleNav';
import Text from './Text';
import TraineeLinkOverlay from './TraineeLinkOverlay';
import TraineeRoleDescription from './TraineeRoleDescription';

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    currentUserId: user.employeeId,
  };
};

class Role extends Component {
  constructor() {
    super();
    this.state = {
      activeView: null,
      selectedAreaId: null,
      selectedAreaIsAdditional: false,
      showNotConnectedElements: false,
      activeModal: '',
      modalProps: {},
    };
  }

  componentDidMount() {
    const {
      description,
      knowledgeAreasSortOrder,
      onViewArea,
      additionalKnowledgeAreasSortOrder,
    } = this.props;
    const descriptionLength =
      description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    if (descriptionLength) {
      this.changeView('description');
    } else if (knowledgeAreasSortOrder.length > 0) {
      this.changeView('area', knowledgeAreasSortOrder[0]);
    } else if (additionalKnowledgeAreasSortOrder.length > 0) {
      this.changeView('area', additionalKnowledgeAreasSortOrder[0], true);
    }
  }

  changeView = (view, areaId = null, isAdditional = false) => {
    const { onViewArea, id, knowledgeAreas, updateKnowledgeArea } = this.props;
    const { selectedAreaId } = this.state;
    if (view === 'area' && onViewArea && updateKnowledgeArea) {
      onViewArea(id, areaId);
      if (
        selectedAreaId &&
        knowledgeAreas[selectedAreaId] &&
        knowledgeAreas[selectedAreaId].notificationCount
      ) {
        updateKnowledgeArea(id, this.state.selectedAreaId);
      }
    }
    this.setState({
      activeView: view,
      selectedAreaId: areaId,
      selectedAreaIsAdditional: isAdditional,
    });
  };

  getDefaultMediatorText = (defaultMediatorId, lockState) => {
    const { persons, wildcardPersons } = this.props;
    if (defaultMediatorId === EMPTY_ID) {
      if (lockState === LOCK_STATES.LOCKED) {
        return localeLookup('translations.Relevant person');
      }
      return localeLookup('translations.Any person');
    }
    return (
      <PersonWrapper
        id={defaultMediatorId}
        render={({ name, color }) => (
          <Text as="span" color={color}>
            {name}
          </Text>
        )}
      />
    );
  };

  getMediatorOptions = ({
    defaultMediatorId,
    mediatorId,
    isMediatorDefault,
    lockState,
    alternativeMediatorId,
    isInTraining,
  }) => {
    const { persons, lookupPerson } = this.props;
    const sortedPersonIds = sortBy(Object.keys(persons), [
      (a, b) => compareLocal(persons[a]?.name, persons[b]?.name),
    ]);
    const options = sortedPersonIds.reduce(
      (acc, id) => {
        const person = persons[id];
        if (person.state === PERSON_STATES.ACTIVE) {
          acc.push({
            value: id,
            label: person.name,
            tooltip: `${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          });
        }
        return acc;
      },
      [
        {
          label: localeLookup('translations.Standard setting'),
          value: EMPTY_ID,
          subtitle: this.getDefaultMediatorText(
            !isInTraining && alternativeMediatorId !== null
              ? alternativeMediatorId
              : defaultMediatorId,
            lockState
          ),
        },
      ]
    );
    const isMediatorInOptions =
      options.find((option) => option.value === mediatorId) !== undefined;
    if (!isMediatorInOptions && !isMediatorDefault) {
      const mediator = lookupPerson(mediatorId);
      const isMediatorArchived = mediator?.state === PERSON_STATES.ARCHIVED;
      return [
        {
          label: `${mediator.name} ${
            isMediatorArchived
              ? `(${localeLookup('translations.Archived')})`
              : ''
          }`,
          labelColor: 'red',
          value: mediatorId,
          searchString: mediator.isWildcardPerson
            ? mediator.name
            : `${mediator.name}${mediator.initials}${mediator.email}`,
          disabled: true,
        },
        ...options,
      ];
    }
    return options;
  };

  onChangeMediatorClick = (areaId, elementId) => {
    const { knowledgeElements, updateKnowledgeElementMediator, mentorId } =
      this.props;
    const element = knowledgeElements[elementId];
    const isMediatorDefault = element.mediatorType === 'Default';
    const options = this.getMediatorOptions({
      alternativeMediatorId: element.alternativeMediator,
      isInTraining: mentorId !== EMPTY_ID,
      defaultMediatorId: element.defaultMediator,
      mediatorId: element.mediator,
      isMediatorDefault,
      lockState: element.lockState,
    });
    if (!element) return;
    this.showModal('radioButton', {
      title: localeLookup('translations.Responsible'),
      subtitle: element.name,
      fullWidth: true,
      options,
      selectedValue: isMediatorDefault ? EMPTY_ID : element.mediator,
      confirmBtnText: localeLookup('translations.Change'),
      onConfirm: (mediatorId) => {
        updateKnowledgeElementMediator(areaId, elementId, mediatorId);
      },
    });
  };

  onShowNotConnectedElementsClick = () => {
    this.setState({
      showNotConnectedElements: !this.state.showNotConnectedElements,
    });
  };

  hideModal = () => {
    this.setState({
      activeModal: '',
      modalProps: {},
    });
  };

  showModal = (type, props = {}) => {
    const { modalProps } = this.state;
    this.setState({
      activeModal: type,
      modalProps: { ...modalProps, ...props },
    });
  };

  renderModals = () => {
    const { activeModal, modalProps } = this.state;

    return (
      <Modal
        maxWidth={modalProps.maxWidth || '700px'}
        isOpen={activeModal}
        fullWidth={modalProps.fullWidth}
        onCloseClick={this.hideModal}
        closeOnEsc={modalProps.closeOnEsc}
        closeOnOverlayClick={modalProps.closeOnOverlayClick}
        render={({ onCloseClick }) => {
          if (activeModal === 'radioButton') {
            return (
              <RadioButtonModal
                onClose={onCloseClick}
                grouped={modalProps.grouped}
                onCreated={modalProps.onCreated}
                titleText={modalProps.title}
                options={modalProps.options}
                confirmBtnText={modalProps.confirmBtnText}
                filterPlaceholder={modalProps.filterPlaceholder}
                selectedValue={modalProps.selectedValue}
                onConfirm={modalProps.onConfirm}
                buttonText={modalProps.filterButtonText}
                onFilterButtonClick={modalProps.onFilterButtonClick}
                subtitle={modalProps.subtitle}
              />
            );
          }
          return null;
        }}
      />
    );
  };

  render() {
    const {
      additionalKnowledgeAreasSortOrder,
      categories,
      completionPercentage,
      currentUserId,
      description,
      id,
      isMenteeContext,
      knowledgeAreas,
      knowledgeAreasSortOrder,
      knowledgeElements,
      loggedInUserId,
      menteeId,
      mentor,
      mentorId,
      name,
      onChangeAreaLinkState,
      persons,
      wildcardPersons,
      updateKnowledgeElement,
      onCloseHistoryModal,
      showModal,
      startDate,
      endDate,
    } = this.props;
    const { activeView, selectedAreaId, selectedAreaIsAdditional } = this.state;
    const selectedKnowledgeArea =
      knowledgeAreas && selectedAreaId && knowledgeAreas[selectedAreaId];
    const descriptionLength =
      description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    return (
      <div className="role">
        <RoleNav
          activeView={activeView}
          additionalKnowledgeAreasSortOrder={additionalKnowledgeAreasSortOrder}
          areas={knowledgeAreas}
          areasSortOrder={knowledgeAreasSortOrder}
          categories={categories}
          completionPercentage={completionPercentage}
          mentorId={mentorId}
          showTrainingTasks={mentorId !== currentUserId}
          onSelect={this.changeView}
          persons={persons}
          roleId={id}
          startDate={startDate}
          endDate={endDate}
          selectedId={selectedAreaId}
          showDescriptionOption={descriptionLength > 0}
          knowledgeElements={knowledgeElements}
        />
        {activeView === 'area' &&
          selectedKnowledgeArea &&
          selectedAreaIsAdditional &&
          !selectedKnowledgeArea.isLinkedToPerson && (
            <TraineeLinkOverlay
              isMenteeContext={isMenteeContext}
              onChangeAreaLinkState={() => {
                onChangeAreaLinkState(id, selectedKnowledgeArea.id, true);
              }}
            />
          )}
        {activeView === 'area' && selectedKnowledgeArea && (
          <MainArea>
            <KnowledgeArea
              showModal={showModal}
              area={selectedKnowledgeArea}
              categories={categories}
              category={categories[selectedKnowledgeArea.category]}
              currentUserId={currentUserId}
              experts={selectedKnowledgeArea.experts}
              id={selectedKnowledgeArea.id}
              isAdditionalArea={selectedAreaIsAdditional}
              isMenteeContext={isMenteeContext}
              knowledgeElements={knowledgeElements}
              knowledgeElementsSortOrder={
                selectedKnowledgeArea.knowledgeElements
              }
              knowledgeOwner={selectedKnowledgeArea.knowledgeOwner}
              loggedInUserId={loggedInUserId}
              menteeId={menteeId}
              mentorId={mentorId}
              name={selectedKnowledgeArea.name}
              notConnectedElementsSortOrder={
                selectedKnowledgeArea.notConnectedElements
              }
              onChangeAreaLinkState={onChangeAreaLinkState}
              onChangeMediatorClick={this.onChangeMediatorClick}
              onCloseHistoryModal={onCloseHistoryModal}
              onShowNotConnectedElementsClick={
                this.onShowNotConnectedElementsClick
              }
              persons={persons}
              roleId={id}
              showNotConnectedElements={this.state.showNotConnectedElements}
              status={selectedKnowledgeArea.status}
              updateKnowledgeElement={updateKnowledgeElement}
              wildcardPersons={wildcardPersons}
            />
          </MainArea>
        )}
        {activeView === 'description' && description && (
          <MainArea>
            <TraineeRoleDescription description={description} />
          </MainArea>
        )}
        <Portal>{this.renderModals()}</Portal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withPersonLookup(WithModals(Role)));
