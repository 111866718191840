/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import {
  updatePersonPassword,
  validatePasswordService,
} from '../services/personsService';
import { updatePassword } from '../services/userProfileService';
import { getPersons } from '../slices/personsSlice';
import BoxMessage from './BoxMessage';
import { PasswordRequirementsChecker } from './PasswordRequirementsChecker';
import { PasswordScoreBar } from './PasswordScoreBar';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import ModalBody from './modal/ModalBody';
import ModalError from './modal/ModalError';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getPersons }, dispatch);

class ChangePasswordForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      score: 0,
      errors: [],
      serverError: '',
    };
  }
  getValidationSchema = () => {
    const { isOwnPassword } = this.props;
    const that = this;
    if (isOwnPassword) {
      return Yup.object().shape({
        currentPassword: Yup.string().required(
          localeLookup('translations.Current password is required')
        ),
        password: Yup.string()
          .required(localeLookup('translations.Password is required'))
          .test('is-valid', '', function (value) {
            return validatePasswordService(value)
              .then((response) => {
                that.setState(() => ({
                  score: response.data.score,
                  errors: response.data.errors,
                }));
                return true;
              })
              .catch((err) => {
                return this.createError({ message: err.response.data.message });
              });
          }),
        password2: Yup.string()
          .required(localeLookup('translations.Repeated password is required'))
          .oneOf(
            [Yup.ref('password'), null],
            localeLookup('translations.Passwords do not match')
          ),
      });
    }
    return Yup.object().shape({
      password: Yup.string()
        .required(localeLookup('translations.Password is required'))
        .test('is-valid', '', function (value) {
          return validatePasswordService(value)
            .then((response) => {
              that.setState(() => ({
                score: response.data.score,
                errors: response.data.errors,
              }));
              return true;
            })
            .catch((err) => {
              return this.createError({ message: err.response.data.message });
            });
        }),
      password2: Yup.string()
        .required(localeLookup('translations.Repeated password is required'))
        .oneOf(
          [Yup.ref('password'), null],
          localeLookup('translations.Passwords do not match')
        ),
    });
  };

  onSubmit = ({ currentPassword, password, password2 }, { setSubmitting }) => {
    const { onClose, isOwnPassword, personId } = this.props;
    if (isOwnPassword) {
      updatePassword({
        currentPassword,
        password,
        repeatedPassword: password2,
      })
        .then(() => {
          setSubmitting(false);
          onClose();
        })
        .catch((err) => {
          setSubmitting(false);
          this.setState({
            serverError: err.response.data.message,
            isSubmitting: false,
          });
        });
    } else {
      updatePersonPassword(personId, {
        password: password,
        repeatedPassword: password2,
      })
        .then(() => {
          setSubmitting(false);
          onClose();
        })
        .catch((err) => {
          setSubmitting(false);
          this.setState({
            serverError: err.response.data.message,
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    const { score, errors: passwordErrors, serverError } = this.state;
    const { initialValues, renderFooter, isOwnPassword } = this.props;
    const defaultInitialValues = {
      currentPassword: '',
      password: '',
      password2: '',
    };
    return (
      <ModalBody noSpacing>
        <FormWrapper
          className="create-person-form"
          onSubmit={this.onSubmit}
          initialValues={{ ...defaultInitialValues, ...initialValues }}
          validationSchema={this.getValidationSchema()}
          validateOnMount
        >
          {({
            handleSubmit,
            values,
            handleChange,
            touched,
            errors,
            handleBlur,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <>
                <form
                  className="change-password-form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  {/* <BoxMessage icon="info-circle">
                    {
                      'Password must be \n- At least 12 charracters long \n- At least "Medium" in strength \n- Not contain character sequences such as "123" or "abc" or repeated characters \n- Not found in online breaches of passwords'
                    }
                  </BoxMessage> */}

                  <div className="change-password-form__inner">
                    {isOwnPassword && (
                      <TextField
                        autoComplete="nope"
                        name="currentPassword"
                        id="currentPassword"
                        required
                        onBlur={handleBlur}
                        error={
                          touched.currentPassword && errors.currentPassword
                        }
                        value={values.currentPassword}
                        onChange={handleChange}
                        label={localeLookup('translations.Current password')}
                        type="password"
                      />
                    )}
                    <TextField
                      autoComplete="nope"
                      name="password"
                      id="password"
                      required
                      onBlur={handleBlur}
                      /* errors={
                        touched.password && passwordErrors.length > 0
                          ? passwordErrors
                          : null
                      } */
                      value={values.password}
                      onChange={handleChange}
                      label={localeLookup('translations.New password')}
                      type="password"
                    />
                    <PasswordScoreBar
                      passwordFilled={values.password.length > 0}
                      score={score}
                    ></PasswordScoreBar>
                    <PasswordRequirementsChecker errors={passwordErrors} />
                    <TextField
                      autoComplete="new-password"
                      name="password2"
                      id="password2"
                      required
                      onBlur={handleBlur}
                      error={touched.password2 && errors.password2}
                      value={values.password2}
                      onChange={handleChange}
                      label={localeLookup('translations.Repeat new password')}
                      type="password"
                    />
                  </div>
                  {serverError !== '' && (
                    <ModalError horizontalSpacing="md" error={serverError} />
                  )}
                  <input type="submit" style={{ visibility: 'hidden' }} />
                </form>
                {renderFooter &&
                  renderFooter({
                    handleSubmit,
                    disableSubmit: !isValid || isSubmitting,
                  })}
              </>
            );
          }}
        </FormWrapper>
      </ModalBody>
    );
  }
}

export default connect(null, mapDispatchToProps)(ChangePasswordForm);
