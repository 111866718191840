import React from 'react';
import { connect } from 'react-redux';
import { formatDate, formatTime } from '../utils/helpers';
import { parse, set } from 'date-fns';
import { TIME_FORMAT_STRINGS } from '../constants';
const mapStateToProps = (state) => ({
  timeZone: state.rootmenu.timeZone,
  dateFormat: state.rootmenu.dateFormat,
  timeFormat: state.rootmenu.timeFormat,
});

function DateTimeRange({
  startDate,
  endDate,
  startTime, // 'HH:mm'
  endTime, // 'HH:mm'
  timeZone,
  includeTimeFromDates,
  timeFormat,
  dateFormat,
  useTimeZone = false,
}) {
  const timeZoneToUse = useTimeZone ? timeZone : null;

  if (startDate && !endDate) {
    if (startTime) {
      const startDateParsed = new Date(startDate);
      const startTimeParsed = parse(startTime, 'HH:mm', new Date());
      const startDateWithStartTime = set(startDateParsed, {
        hours: startTimeParsed.getHours(),
        minutes: startTimeParsed.getMinutes(),
      });
      return <>{formatDate(startDateWithStartTime, timeZoneToUse, true)}</>;
    }
    return <>{formatDate(startDate, timeZoneToUse, false)}</>;
  }
  if (!startDate && endDate) {
    if (endTime) {
      const endDateParsed = new Date(endDate);
      const endTimeParsed = parse(endTime, 'HH:mm', new Date());
      const endDateWithEndTime = set(endDateParsed, {
        hours: endTimeParsed.getHours(),
        minutes: endTimeParsed.getMinutes(),
      });
      return <>{formatDate(endDateWithEndTime, timeZoneToUse, true)}</>;
    }
    return <>{formatDate(endDate, timeZoneToUse, false)} </>;
  }
  if (startDate && endDate) {
    if (startTime && endTime) {
      const startDateParsed = new Date(startDate);
      const startTimeParsed = parse(startTime, 'HH:mm', new Date());
      const startDateWithStartTime = set(startDateParsed, {
        hours: startTimeParsed.getHours(),
        minutes: startTimeParsed.getMinutes(),
      });
      const endDateParsed = new Date(endDate);
      const endTimeParsed = parse(endTime, 'HH:mm', new Date());
      const endDateWithEndTime = set(endDateParsed, {
        hours: endTimeParsed.getHours(),
        minutes: endTimeParsed.getMinutes(),
      });
      return (
        <>
          {formatDate(startDateWithStartTime, timeZoneToUse, true)} -{' '}
          {formatDate(endDateWithEndTime, timeZoneToUse, true)}
        </>
      );
    }
    return (
      <>
        {formatDate(startDate, timeZoneToUse, false)} -{' '}
        {formatDate(endDate, timeZoneToUse, false)}
      </>
    );
  }
  return null;
}

export default connect(mapStateToProps)(DateTimeRange);
