import React, { Component } from 'react';
import Select from 'react-select';
import cx from 'classnames';
import Icon from '../Icon';
import localeLookup from '../../config/locale';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
  key: 'jss-class-prefix-key',
  nonce: cspNonce || '',
});

export default function AutocompleteSelect({
  options,
  name,
  onChange,
  className,
  defaultValue,
  disabled,
  placeholder,
  filterOption,
  value,
  loadOptions,
  onBlur,
}) {
  return (
    <CacheProvider value={cache}>
      <Select
        placeholder={placeholder || localeLookup('translations.Select')}
        menuPosition="fixed"
        className={cx('multi-select', className)}
        isDisabled={disabled}
        classNamePrefix="multi-select"
        onChange={onChange}
        filterOption={filterOption}
        onBlur={onBlur}
        name={name}
        value={value}
        loadOptions={loadOptions}
        defaultValue={options.find((option) => option.value === defaultValue)}
        options={options}
        components={{
          DropdownIndicator: disabled
            ? null
            : () => (
                <Icon
                  className="multi-select__arrow"
                  kind="chevron-down"
                  color="grey"
                  size="small"
                ></Icon>
              ),
          NoOptionsMessage: () => (
            <p className="multi-select__no-options-message">
              {localeLookup('translations.No options found')}
            </p>
          ),
        }}
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if (data.showError) {
              return {
                ...styles,
                color: 'red',
              };
            }
            return styles;
          },
        }}
      />
    </CacheProvider>
  );
}
