import React, { PureComponent } from 'react';
import localeLookup from '../config/locale';
import { EVENT_TYPES } from '../constants';
import Button from './Button';
import ElementHistoryEvent from './ElementHistoryEvent';
import Loader from './Loader';

class ElementHistoryList extends PureComponent {
  renderEvent = ({ type, approvals, id, note, createdAt, sessionId }) => {
    const {
      onClickDeleteApproval,
      onClickRestoreApproval,
      onClickDeleteEvent,
      onClickRestoreEvent,
      currentUserId,
      onClickShowSignature,
      onClickShowElementVersion,
    } = this.props;
    return (
      <ElementHistoryEvent
        key={id}
        onClickShowElementVersion={onClickShowElementVersion}
        onClickShowSignature={onClickShowSignature}
        onClickDeleteApproval={onClickDeleteApproval}
        onClickRestoreApproval={onClickRestoreApproval}
        onClickDeleteEvent={onClickDeleteEvent}
        onClickRestoreEvent={onClickRestoreEvent}
        type={type}
        createdAt={createdAt}
        approvals={approvals}
        id={id}
        note={note}
        currentUserId={currentUserId}
        sessionId={sessionId}
      />
    );
  };

  render() {
    const {
      items,
      isLoading,
      totalEvents,
      onClickAddReset,
      onClickLoadAll,
      lockState,
    } = this.props;
    return (
      <div className="element-history-list">
        <div className="element-history-list__list">
          {items.map(this.renderEvent)}
          {isLoading && <Loader />}
          {!isLoading && totalEvents > items.length && (
            <Button
              className="element-history-list__button"
              onClick={onClickLoadAll}
              kind="invisible"
            >
              {localeLookup('translations.Load all')}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ElementHistoryList;
