/* eslint-disable no-case-declarations */
import {
  getTrainingSessionService,
  getTrainingSessionStatusService,
  getTrainingSessionsService,
} from '../services/trainingSessionService';

// Actions
const LOAD_ALL = 'elements/LOAD_ALL';
const LOAD_TRAINING_SESSION = 'elements/LOAD_TRAINING_SESSION';
const LOAD_TRAINING_SESSION_STATUS = 'elements/LOAD_TRAINING_SESSION_STATUS';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_ALL:
      if (action.reset) {
        return {
          ...action.trainingSessions,
        };
      }
      return {
        ...state,
        ...action.trainingSessions,
      };
    case LOAD_TRAINING_SESSION:
      return {
        ...state,
        [action.id]: {
          ...action.session[action.id],
          status: state[action.id]?.status ? state[action.id].status : {},
        },
      };
    case LOAD_TRAINING_SESSION_STATUS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: action.status,
        },
      };
    default:
      return state;
  }
};

export const getAllTrainingSessions =
  ({ reset } = {}) =>
  (dispatch) =>
    getTrainingSessionsService().then(({ data }) => {
      dispatch({ type: LOAD_ALL, trainingSessions: data, reset });
      return data;
    });

export const queryTrainingSession = (sessionId) => (dispatch) =>
  getTrainingSessionService(sessionId).then(({ data }) => {
    dispatch({ type: LOAD_TRAINING_SESSION, session: data, id: sessionId });
    return data;
  });

export const getTrainingSessionStatus = (sessionId) => (dispatch) =>
  getTrainingSessionStatusService(sessionId).then(({ data }) => {
    dispatch({
      type: LOAD_TRAINING_SESSION_STATUS,
      status: data,
      id: sessionId,
    });
    return data;
  });

// Selectors
export const selectTrainingSession = (state, id) => {
  return state.trainingSessions[id];
};
