import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import {
  DELETED_PERSON_ID,
  EMPTY_ID,
  NONE_ID,
  PERSON_STATES,
  ROLE_LEVELS,
} from '../constants';
import {
  getPersonsWithRoleService,
  setPersonRoleAsTrainingService,
} from '../services/personsService';
import { getGroups } from '../slices/groupsSlice';
import {
  getAllOrganisationUnits,
  selectParentMap,
} from '../slices/organisationUnitsSlice';
import { getPersons } from '../slices/personsSlice';
import { getAllRoles } from '../slices/rolesSlice';
import { compareLocal, sortBy } from '../utils/helpers';
import Column from './Column';
import DateFieldV2 from './DateFieldV2';
import withAccessControl from './HOC/withAccessControl';
import Icon from './Icon';
import { RadioButtonGroup } from './RadioButtonGroup';
import Row from './Row';
import Tooltip from './Tooltip';
import AutocompleteSelect from './formElements/AutocompleteSelect';
import Field from './formElements/Field';
import FormWrapper from './formElements/FormWrapper';
import ModalBody from './modal/ModalBody';
import withPersonLookup from './HOC/withPersonLookup';
import { compose } from 'redux';

const mapStateToProps = (state) => {
  return {
    persons: state.persons,
    roles: state.roles,
    groups: state.groups,
    organisationUnits: state.organisationUnits,
    organisationUnitParents: selectParentMap(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getPersons, getAllRoles, getAllOrganisationUnits, getGroups },
    dispatch
  );

class TrainingForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isLoading: true,
      roleLevels: null,
      showSettings: false,
    };
    this.formRef = React.createRef(null);
  }

  componentDidMount() {
    const {
      getPersons,
      getAllOrganisationUnits,
      getAllRoles,
      getGroups,
      initialValues,
    } = this.props;
    Promise.all([
      getPersons(),
      getGroups(),
      getAllOrganisationUnits(),
      getAllRoles(),
    ]).then(() => {
      if (initialValues?.roleId) {
        this.getRolePersonLevels(initialValues.roleId).then(() => {
          this.setState({ isLoading: false });
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  getGroupedMentorOptions = () => {
    const { groups } = this.props;
    const { roleLevels } = this.state;
    if (!roleLevels) return [];
    const options = this.getMentorOptions();
    const groupedOptions = options.reduce(
      (acc, option) => {
        const level = option.level;
        return {
          ...acc,
          [level]: {
            ...acc[level],
            options: [...acc[level].options, option],
          },
        };
      },
      {
        NoGroup: {
          options: [],
        },
        Groups: {
          label: localeLookup('translations.Groups'),
          options: [],
        },
        Experienced: {
          label: localeLookup('translations.Experienced'),
          options: [],
        },
        Qualified: {
          label: localeLookup('translations.Qualified'),
          options: [],
        },
        Training: {
          label: localeLookup('translations.In training'),
          options: [],
        },
        doesNotHaveRole: {
          label: localeLookup('translations.Other persons'),
          options: [],
        },
      }
    );
    return Object.values(groupedOptions);
  };

  getGroupedPersonOptions = () => {
    const { roleLevels } = this.state;
    if (!roleLevels) return [];
    const options = this.getPersonOptions();
    const groupedOptions = options.reduce(
      (acc, option) => {
        const level = option.level;
        return {
          ...acc,
          [level]: {
            ...acc[level],
            options: [...acc[level].options, option],
          },
        };
      },
      {
        doesNotHaveRole: {
          label: localeLookup('translations.Does not have role'),
          options: [],
        },
        Qualified: {
          label: localeLookup('translations.Qualified'),
          options: [],
        },
        Experienced: {
          label: localeLookup('translations.Experienced'),
          options: [],
        },
      }
    );
    return Object.values(groupedOptions);
  };

  getMentorOptions = () => {
    const { roleLevels } = this.state;
    const { persons, groups, initialValues, lookupPerson } = this.props;
    if (!roleLevels) return [];
    const isCurrentMentorDeleted =
      initialValues?.mentorId === DELETED_PERSON_ID;
    const selectedPersonId = this.formRef?.current?.values?.personId;
    const options = sortBy(
      Object.keys(persons)
        .filter((id) => {
          if (initialValues?.mentorId === id) {
            return true;
          }
          const isActive = persons[id].state === PERSON_STATES.ACTIVE;
          const isNotPerson = id !== selectedPersonId;
          return isActive && isNotPerson;
        })
        .map((id) => {
          const person = lookupPerson(id);
          const personRoleLevel = roleLevels[id]?.level;
          return {
            level: personRoleLevel || ROLE_LEVELS.NONE,
            label: `${person.name} ${person.suffix}`,
            value: id,
            isDisabled: person.showError,
            showError: person.showError,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
    return [
      ...options,
      ...sortBy(
        Object.values(groups)
          .filter((group) => group.assignableAs.includes('Mentor'))
          .map((group) => ({
            label: group.name,
            value: group.id,
            searchString: group.name,
            level: 'Groups',
          })),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
      {
        label: localeLookup('translations.No mentor'),
        value: NONE_ID,
        level: 'NoGroup',
        searchString: localeLookup('translations.No mentor'),
      },
      ...(isCurrentMentorDeleted
        ? [
            {
              label: localeLookup('translations.Deleted person'),
              value: DELETED_PERSON_ID,
              level: 'NoGroup',
              isDisabled: true,
              showError: true,
              searchString: localeLookup('translations.Deleted person'),
            },
          ]
        : []),
    ];
  };

  getMentorValue = () => {
    const selectedMentorId = this.formRef?.current?.values?.mentorId;
    const options = this.getMentorOptions();
    const selectedOption = options.find(
      (option) => option.value === selectedMentorId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  getOrganisationUnitOptions = () => {
    const { organisationUnits, organisationUnitParents } = this.props;
    return Object.keys(organisationUnits).reduce((acc, id) => {
      const organisationUnit = organisationUnits[id];
      const unitParents = organisationUnitParents[id];
      if (organisationUnit.hasAccess) {
        return [
          ...acc,
          {
            label: `${Array(unitParents.length).fill('·').join('')} ${
              organisationUnit.name
            }`,
            value: id,
          },
        ];
      }
      return acc;
    }, []);
  };

  getPersonOptions = () => {
    const { roleLevels } = this.state;
    const { persons, organisationUnits } = this.props;
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    if (!roleLevels) return [];
    const options = sortBy(
      Object.keys(persons)
        .filter((id) => {
          const personRoleLevel = roleLevels[id]?.level;
          const organisationUnit =
            organisationUnits[selectedOrganisationUnitId];
          return (
            organisationUnit?.employees?.includes(id) &&
            persons[id].state === PERSON_STATES.ACTIVE &&
            personRoleLevel !== 'Training'
          );
        })
        .map((id) => {
          const person = persons[id];
          const personRoleLevel = roleLevels[id]?.level;
          return {
            level: personRoleLevel || ROLE_LEVELS.NONE,
            label: persons[id].name,
            value: id,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
    return options;
  };

  getRolePersonLevels = (roleId) => {
    return getPersonsWithRoleService(roleId).then((response) => {
      this.setState({ roleLevels: response.data });
    });
  };

  getPersonValue = () => {
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    const selectedPersonId = this.formRef?.current?.values?.personId;
    const options = this.getPersonOptions(selectedOrganisationUnitId);
    const selectedOption = options.find(
      (option) => option.value === selectedPersonId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  getRoleOptions = () => {
    const { roles, organisationUnits } = this.props;
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    return sortBy(
      Object.keys(roles)
        .filter((id) => {
          const organisationUnit =
            organisationUnits[selectedOrganisationUnitId];
          return organisationUnit?.roles?.includes(id);
        })
        .map((id) => {
          const role = roles[id];
          return {
            label: role.name,
            value: id,
          };
        }),
      [(a, b) => compareLocal(a.label, b.label)]
    );
  };

  getRoleValue = () => {
    const selectedOrganisationUnitId =
      this.formRef?.current?.values?.organisationUnitId;
    const selectedRoleId = this.formRef?.current?.values?.roleId;
    const options = this.getRoleOptions(selectedOrganisationUnitId);
    const selectedOption = options.find(
      (option) => option.value === selectedRoleId
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  onChangeOrganisationUnit = ({
    selectedOption,
    setFieldValue,
    validateForm,
  }) => {
    Promise.all([
      setFieldValue('roleId', ''),
      setFieldValue('personId', ''),
      setFieldValue('mentorId', ''),
      setFieldValue('organisationUnitId', selectedOption.value),
    ]).then(() => {
      validateForm();
    });
  };

  onChangeRole = ({ selectedOption, setFieldValue, validateForm }) => {
    Promise.all([
      this.getRolePersonLevels(selectedOption.value),
      setFieldValue('roleId', selectedOption.value),
      setFieldValue('personId', ''),
      setFieldValue('mentorId', ''),
    ]).then(() => {
      validateForm();
    });
  };

  onClickToggleSettings = () => {
    this.setState((prevState) => ({ showSettings: !prevState.showSettings }));
  };

  onSubmit = (values) => {
    const { onConfirm, onClose } = this.props;
    const {
      roleId,
      personId,
      mentorId,
      organisationUnitId,
      startDate,
      endDate,
      state,
      daysActiveBeforeStartDate,
    } = values;
    this.setState({ isSubmitting: true });
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate =
      endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : null;
    setPersonRoleAsTrainingService({
      personId,
      roleId,
      data: {
        mentorId,
        startDate: formattedStartDate,
        endDate: endDate === '' ? null : formattedEndDate,
        state,
      },
    }).then(() => {
      onConfirm({
        personId,
        organisationUnitId,
        roleId,
        mentorId,
        startDate,
        endDate,
        state,
      });
      onClose();
    });
  };

  render() {
    const { isSubmitting, isLoading, showSettings } = this.state;
    const { initialValues, renderFooter, context } = this.props;

    const validationSchema = Yup.object().shape({
      /* daysActiveBeforeStartDate: Yup.number().when('state', {
        is: (value) => value && value === 'DaysBeforeStartDate',
        then: Yup.number()
          .min(0)
          .required(localeLookup('translations.Number of days is required')),
        otherwise: Yup.number(),
      }), */
      organisationUnitId:
        context === 'edit'
          ? Yup.string()
          : Yup.string().required(
              localeLookup('translations.Organisation unit is required')
            ),
      roleId: Yup.string().required(
        localeLookup('translations.Role is required')
      ),
      mentorId: Yup.string()
        .test(
          'is-not-empty-id',
          localeLookup('translations.Mentor is required'),
          function (value) {
            if (value === EMPTY_ID) {
              return false;
            }
            return true;
          }
        )
        .required(localeLookup('translations.Mentor is required')),
      personId: Yup.string().required(
        localeLookup('translations.Person is required')
      ),
      startDate: Yup.string()
        .test(
          'is-before-end-date',
          localeLookup('translations.Start date must be before end date'),
          function (value) {
            if (this?.parent?.endDate) {
              const valueObj = new Date(value);
              const endDateObj = new Date(this.parent.endDate);
              return (
                isBefore(valueObj, endDateObj) ||
                isSameDay(valueObj, endDateObj)
              );
            } else {
              return true;
            }
          }
        )
        .required(localeLookup('translations.Start date is required')),
      endDate: Yup.string()
        .nullable()
        .test(
          'is-after-start-date',
          localeLookup('translations.End date must be after start date'),
          function (value) {
            if (this?.parent?.startDate && value && value !== '') {
              const valueObj = new Date(value);
              const startDateObj = new Date(this.parent.startDate);
              return (
                isAfter(valueObj, startDateObj) ||
                isSameDay(valueObj, startDateObj)
              );
            } else {
              return true;
            }
          }
        ),
    });

    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }
      return true;
    };
    if (isLoading) return null;
    return (
      <FormWrapper
        ref={this.formRef}
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          daysActiveBeforeStartDate: '1',
          endDate: '',
          mentorId: '',
          organisationUnitId: '',
          personId: '',
          roleId: '',
          startDate: new Date(),
          state: 'Active',
          ...initialValues,
        }}
      >
        {({
          handleSubmit,
          values,
          isValid,
          setFieldValue,
          validateField,
          validateForm,
          touched,
          handleBlur,
          errors,
          handleChange,
        }) => (
          <>
            <ModalBody>
              <form
                className="training-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                {context === 'add' && (
                  <Row>
                    <Column size="6">
                      <Field
                        required
                        label={localeLookup('translations.Organisation unit')}
                      >
                        <AutocompleteSelect
                          defaultValue={values.organisationUnitId}
                          name="organisationUnitId"
                          onChange={(selectedOption) => {
                            this.onChangeOrganisationUnit({
                              selectedOption,
                              setFieldValue,
                              validateForm,
                            });
                          }}
                          filterOption={filterOption}
                          options={this.getOrganisationUnitOptions()}
                        ></AutocompleteSelect>
                      </Field>
                    </Column>
                    <Column size="6">
                      <Field required label={localeLookup('translations.Role')}>
                        <AutocompleteSelect
                          value={this.getRoleValue()}
                          disabled={!values.organisationUnitId}
                          name="roleId"
                          onChange={(selectedOption) => {
                            this.onChangeRole({
                              selectedOption,
                              setFieldValue,
                              validateForm,
                            });
                          }}
                          filterOption={filterOption}
                          options={this.getRoleOptions()}
                          placeholder={
                            values.organisationUnitId
                              ? undefined
                              : localeLookup(
                                  'translations.Select organisation unit first'
                                )
                          }
                        ></AutocompleteSelect>
                      </Field>
                    </Column>
                  </Row>
                )}
                <Row>
                  {context === 'add' && (
                    <Column size="6">
                      <Field
                        required
                        label={localeLookup('translations.Person')}
                      >
                        <AutocompleteSelect
                          value={this.getPersonValue()}
                          disabled={
                            !values.organisationUnitId || !values.roleId
                          }
                          name="personId"
                          onChange={(selectedOption) => {
                            const mentorValue = this.getMentorValue()?.value;
                            if (selectedOption.value === mentorValue) {
                              setFieldValue('mentorId', '').then(() => {
                                validateField('mentorId');
                              });
                            }
                            setFieldValue('personId', selectedOption.value);
                          }}
                          filterOption={filterOption}
                          options={this.getGroupedPersonOptions()}
                          placeholder={
                            values.organisationUnitId && values.roleId
                              ? undefined
                              : localeLookup(
                                  'translations.Select organisation unit and role first'
                                )
                          }
                        ></AutocompleteSelect>
                      </Field>
                    </Column>
                  )}
                  <Column size={context === 'add' ? '6' : '12'}>
                    <Field required label={localeLookup('translations.Mentor')}>
                      <AutocompleteSelect
                        disabled={
                          context === 'edit'
                            ? false
                            : !values.organisationUnitId || !values.roleId
                        }
                        value={this.getMentorValue()}
                        name="mentorId"
                        onChange={(selectedOption) => {
                          setFieldValue('mentorId', selectedOption.value);
                        }}
                        filterOption={filterOption}
                        options={this.getGroupedMentorOptions()}
                        placeholder={
                          (values.organisationUnitId && values.roleId) ||
                          context === 'edit'
                            ? undefined
                            : localeLookup(
                                'translations.Select organisation unit and role first'
                              )
                        }
                      ></AutocompleteSelect>
                    </Field>
                  </Column>
                </Row>
                <Row align="start">
                  <Column size="6">
                    <Field
                      required
                      label={localeLookup('translations.Start date')}
                    >
                      <DateFieldV2
                        error={touched.startDate && errors.startDate}
                        maxDate={values.endDate}
                        value={new Date(values.startDate)}
                        onBlur={handleBlur}
                        name="startDate"
                        id="startDate"
                        required
                        onChange={(value) => {
                          setFieldValue('startDate', value);
                        }}
                      />
                    </Field>
                  </Column>
                  <Column size="6">
                    <Field label={localeLookup('translations.End date')}>
                      <DateFieldV2
                        error={touched.endDate && errors.endDate}
                        onBlur={handleBlur}
                        name="endDate"
                        id="endDate"
                        minDate={values.startDate}
                        value={values.endDate}
                        clearable
                        onClear={() => setFieldValue('endDate', '')}
                        onChange={(value) => {
                          setFieldValue('endDate', value);
                        }}
                      />
                    </Field>
                  </Column>
                </Row>
                {/*  <button
                  className={cx('training-form__settings-button', {
                    'training-form__settings-button--state-open': showSettings,
                  })}
                  type="button"
                  onClick={this.onClickToggleSettings}
                >
                  <span className="training-form__settings-button-text">
                    {localeLookup('translations.More settings')}
                  </span>
                  <div className="training-form__settings-button-line"></div>
                  <div className="training-form__settings-button-icon-container">
                    <Icon
                      className="training-form__settings-button-icon"
                      color="light-grey"
                      kind="plus"
                    ></Icon>
                  </div>
                </button> */}
                {/* <Accordion isOpen={showSettings}> */}
                <div>
                  <RadioButtonGroup
                    groupName="state"
                    options={[
                      {
                        value: 'Active',
                        label: localeLookup('translations.Visible'),
                        isChecked: values.state === 'Active',
                      },
                      {
                        value: 'Passive',
                        label: (
                          <Tooltip
                            overlayStyle={{
                              maxWidth: '500px',
                              whiteSpace: 'normal',
                            }}
                            tooltip={localeLookup(
                              'translations.Limited visibility means this training program will only be visible to administrators and organisation administrators. The person themself, mentors and responsibles will not be able to see the training program and tasks'
                            )}
                          >
                            <span>
                              {localeLookup('translations.Limited visibility')}
                              <Icon
                                className="checkbox-button__tooltip-icon"
                                kind="info-circle"
                              ></Icon>
                            </span>
                          </Tooltip>
                        ),
                        isChecked: values.state === 'Passive',
                      },
                    ]}
                    label={`${localeLookup('translations.Visibility')}:`}
                    onChange={(e) => {
                      setFieldValue('state', e.target.id);
                    }}
                  ></RadioButtonGroup>
                </div>
                {/* </Accordion> */}
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAccessControl,
  withPersonLookup
)(TrainingForm);
