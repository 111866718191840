import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getMentorRoles } from '../../actions/trainingActions';
import EmptyState from '../../components/EmptyState';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import HorizontalSection from '../../components/HorizontalSection';
import Icon from '../../components/Icon';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import PersonWrapper from '../../components/PersonWrapper';
import RoleCard from '../../components/RoleCard';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import localeLookup from '../../config/locale';
import { compareLocal, formatDate, sortBy } from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';
import { EMPTY_ID } from '../../constants';

const mapStateToProps = (state) => {
  const { training } = state;
  return {
    trainingTasks: training.trainingTasks,
    roles: training.roles,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMentorRoles,
    },
    dispatch
  );

class OtherPrograms extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { getMentorRoles } = this.props;
    trackEvent('$pageview');
    getMentorRoles().then(() => this.setState({ isLoading: false }));
  }

  getDateSubtitle = (menteeId, roleId) => {
    const { trainingTasks } = this.props;
    const mentee = trainingTasks[menteeId];
    const menteeRole = mentee.roles[roleId];
    const { mentorId, endDate, startDate } = menteeRole;
    if (mentorId === EMPTY_ID || !startDate) return null;
    return `${formatDate(startDate)} - ${
      endDate ? `${formatDate(endDate)}` : ''
    }`;
  };

  getSubtitle = ({ menteeId, roleId }) => {
    const { trainingTasks } = this.props;

    const mentee = trainingTasks[menteeId];
    if (mentee.roles[roleId].trainingTasks > 0) {
      return (
        <Text as="span" color="blue">
          {localeLookup('translations.You have')}{' '}
          {mentee.roles[roleId].trainingTasks}{' '}
          {mentee.roles[roleId].trainingTasks === 1
            ? localeLookup('translations.training task')
            : localeLookup('translations.training tasks')}
        </Text>
      );
    }
    return null;
  };

  renderRole = ({ role, mentee }) => {
    const hasMentor = role.mentor !== '';
    const route = `/training/other mentors' programs/${role.id}?menteeId=${mentee.id}`;
    return (
      <Link
        to={{
          pathname: route,
          state: {
            title: `${mentee.name} - ${role.name}`,
            backTitle: localeLookup("translations.Other mentors' programs"),
          },
        }}
        key={role.id}
      >
        <RoleCard
          competenceLevel={role.competenceLevel}
          title={role.name}
          clickable
          notifications={role.notifications}
          subtitle={this.getSubtitle({ menteeId: mentee.id, roleId: role.id })}
          subtitle2={
            hasMentor && (
              <PersonWrapper
                id={role.mentorId}
                render={({ name, color, tooltip }) => (
                  <Text as="span" color={color}>
                    <Tooltip tooltip={tooltip}>
                      <span>
                        {localeLookup('translations.Mentor')}: {name}
                      </span>
                    </Tooltip>
                  </Text>
                )}
              />
            )
          }
          subtitle3={this.getDateSubtitle(mentee.id, role.id)}
          completionPercentage={role.completionPercentage}
          donutColor={hasMentor ? 'green' : 'grey'}
          renderRight={
            <Icon
              className="role-card__arrow"
              color="grey"
              kind="chevron-right"
            />
          }
        />
      </Link>
    );
  };

  renderMenteeSection = (menteeId) => {
    const { trainingTasks, lookupPerson } = this.props;
    const mentee = trainingTasks[menteeId];
    const person = lookupPerson(menteeId);
    const titleTooltip = `${person.initials} ${
      person.employeeNumber ? `· ${person.employeeNumber}` : ''
    }`;
    return (
      <HorizontalSection
        key={menteeId}
        titleTooltip={titleTooltip}
        title={mentee.name}
        items={Object.values(mentee.roles)}
        renderItem={(role) => this.renderRole({ role, mentee })}
      />
    );
  };

  render() {
    const { trainingTasks } = this.props;
    const { isLoading } = this.state;
    const sortedMenteeIds = sortBy(Object.keys(trainingTasks), [
      (a, b) => compareLocal(trainingTasks[a]?.name, trainingTasks[b]?.name),
    ]);
    if (Object.keys(trainingTasks).length === 0 && isLoading)
      return <LoadScreen />;
    return (
      <Page>
        <MainArea defaultPadding backgroundColor="grey">
          {sortedMenteeIds.map(this.renderMenteeSection)}
          {Object.keys(trainingTasks).length === 0 && (
            <EmptyState
              fullHeight
              title={localeLookup('translations.No tasks here!')}
              body={localeLookup(
                "translations.You have no tasks in other mentors' programs"
              )}
            />
          )}
        </MainArea>
      </Page>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonLookup(OtherPrograms));
