/* eslint-disable arrow-body-style */
import React from 'react';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, EMPTY_ID, EVENT_TYPES } from '../constants';
import Badge from './Badge';
import ElementHistoryEventApproval from './ElementHistoryEventApproval';
import Text from './Text';
import ButtonIcon from './ButtonIcon';
import withPersonLookup from './HOC/withPersonLookup';
import withAccessControl from './HOC/withAccessControl';
import cx from 'classnames';

const ElementHistoryEvent = ({
  id,
  approvals,
  type,
  onClickDeleteApproval,
  onClickRestoreApproval,
  note,
  currentUserId,
  onClickShowSignature,
  createdAt,
  onClickShowElementVersion,
  sessionId,
  onClickDeleteEvent,
  onClickRestoreEvent,
  hasAccess,
}) => {
  const getApprovalTitle = ({ pending, approverType }) => {
    switch (type) {
      case EVENT_TYPES.RESET_STATUS:
        return localeLookup('translations.ResetEvent');
      case EVENT_TYPES.ADMIN_APPROVAL:
        return localeLookup('translations.Admin completion');
      case EVENT_TYPES.ADMIN_RESET_STATUS:
        return localeLookup('translations.Admin reset');
      default:
        if (sessionId !== EMPTY_ID) {
          const prefix = `${localeLookup('translations.Training session')},`;
          if (approverType === 'SessionParticipant') {
            return `${
              type === EVENT_TYPES.MULTIPART ? '' : `${prefix} `
            }${localeLookup('translations.Participant completion')}`;
          } else if (approverType === 'SessionTrainer') {
            return `${
              type === EVENT_TYPES.MULTIPART ? '' : `${prefix} `
            }${localeLookup('translations.Trainer completion')}`;
          }
        }
        return localeLookup('translations.Completion');
    }
  };
  const getBadgeColor = () => {
    if (approvals.some((approval) => approval.deleted)) {
      return 'grey';
    }
    if (
      type === EVENT_TYPES.RESET_STATUS ||
      type === EVENT_TYPES.ADMIN_RESET_STATUS
    ) {
      return 'red';
    }
    if (type === EVENT_TYPES.MULTIPART && approvals.length < 2) {
      return 'light-green';
    }
    return 'green';
  };
  const getBadgeIcon = () => {
    if (sessionId !== EMPTY_ID) return 'school-teacher';
    switch (type) {
      case EVENT_TYPES.STANDARD:
        return 'check';
      case EVENT_TYPES.RESET_STATUS:
      case EVENT_TYPES.ADMIN_RESET_STATUS:
        return 'minus';
      case EVENT_TYPES.MULTIPART:
        return 'users2';
      case EVENT_TYPES.ADMIN_APPROVAL:
        return 'stamp';
      default:
        return '';
    }
  };

  // Only relevant for training session events
  const getIsEventDeleted = () => {
    if (sessionId === EMPTY_ID) return false;
    return approvals.some((approval) => approval.deleted);
  };
  const isAdmin = hasAccess([
    ACCESS_LEVELS.champadministrator,
    ACCESS_LEVELS.administrator,
    ACCESS_LEVELS.trainingSessionAdministrator,
  ]);
  const isEventDeleted = getIsEventDeleted();

  return (
    <div className="element-history-event">
      <div className="element-history-event__left">
        <Badge size="small" color={getBadgeColor()} icon={getBadgeIcon()} />
        <div className="element-history-event__line" />
      </div>
      <div className="element-history-event__right">
        {type === EVENT_TYPES.MULTIPART && (
          <>
            <div
              className={cx('element-history-event__title-box', {
                'element-history-event__title-box--vertical-spacing-sm':
                  sessionId !== EMPTY_ID,
              })}
            >
              <div className="element-history-event__title-box-left">
                <Text size="md">
                  {sessionId !== EMPTY_ID
                    ? `${localeLookup(
                        'translations.Training session'
                      )}, ${localeLookup('translations.Multipart completion')}`
                    : localeLookup('translations.Multipart completion')}
                </Text>
              </div>
              {sessionId !== EMPTY_ID && isAdmin ? (
                <div className="element-history-event__title-box-right">
                  <ButtonIcon
                    onClick={
                      isEventDeleted
                        ? () => onClickRestoreEvent({ eventId: id })
                        : () => onClickDeleteEvent({ eventId: id })
                    }
                    icon={isEventDeleted ? 'undelete' : 'trash2'}
                    iconColor={isEventDeleted ? 'green' : 'red'}
                  />
                </div>
              ) : null}
            </div>
            {approvals.length === 1 && (
              <ElementHistoryEventApproval
                nested
                title={localeLookup('translations.Pending other part')}
              />
            )}
          </>
        )}
        {approvals.map((approval, i) => {
          const {
            deleted,
            deletedAt,
            deletedBy,
            deletedById,
            hasSignature,
            pending,
            restored,
            restoredAt,
            restoredBy,
            restoredById,
            signature,
            signatureGatheredBy,
            signatureGatheredById,
            signatureMetadata,
            signedAt,
            signedBy,
            signeeId,
            version,
            approverType,
          } = approval;
          return (
            <ElementHistoryEventApproval
              key={i}
              currentUserId={currentUserId}
              date={signedAt}
              createdAt={createdAt}
              deleted={deleted}
              deletedAt={deletedAt}
              deletedBy={deletedBy}
              deletedById={deletedById}
              hasSignature={hasSignature}
              nested={type === EVENT_TYPES.MULTIPART}
              note={note}
              onClickDelete={() => {
                if (sessionId === EMPTY_ID) {
                  onClickDeleteApproval({
                    eventId: id,
                    approvalId: approval.id,
                  });
                } else {
                  onClickDeleteEvent({
                    eventId: id,
                  });
                }
              }}
              onClickRestore={() => {
                if (sessionId === EMPTY_ID) {
                  onClickRestoreApproval({
                    eventId: id,
                    approvalId: approval.id,
                  });
                } else {
                  onClickRestoreEvent({
                    eventId: id,
                  });
                }
              }}
              onClickShowElementVersion={onClickShowElementVersion}
              onClickShowSignature={onClickShowSignature}
              restored={restored}
              restoredAt={restoredAt}
              restoredBy={restoredBy}
              restoredById={restoredById}
              sessionId={sessionId}
              showCreatedAt={type === EVENT_TYPES.ADMIN_APPROVAL}
              signature={signature}
              signatureGatheredBy={signatureGatheredBy}
              signatureGatheredById={signatureGatheredById}
              signatureMetadata={signatureMetadata}
              signedBy={signedBy}
              signeeId={signeeId}
              title={getApprovalTitle({ pending, approverType })}
              version={version}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withAccessControl(ElementHistoryEvent);
