import React from 'react';
import localeLookup from '../../config/locale';
import Loader from '../Loader';

const ModalFooter = ({
  cancelButtonText,
  cancelDisabled,
  confirmDisabled,
  confirmButtonText,
  onCancelClick,
  onConfirmClick,
  confirmButtonKind = 'darkui',
  cancelButtonKind = 'cancel',
  showConfirmButtonLoader = false,
}) => {
  return (
    <div className="modal-content__footer">
      <button
        className={`ui-btn ui-btn-${cancelButtonKind} modal-content__footer-btn`}
        onClick={onCancelClick}
        disabled={cancelDisabled}
      >
        {(cancelButtonText && cancelButtonText) ||
          localeLookup('translations.Cancel')}
      </button>
      {onConfirmClick && (
        <button
          disabled={confirmDisabled}
          className={`ui-btn ui-btn-${confirmButtonKind} modal-content__footer-btn`}
          onClick={onConfirmClick}
        >
          {showConfirmButtonLoader ? (
            <Loader size="small"></Loader>
          ) : confirmButtonText ? (
            confirmButtonText
          ) : (
            localeLookup('translations.Save')
          )}
        </button>
      )}
    </div>
  );
};

export default ModalFooter;
