import React, { PureComponent } from 'react';
import Tooltip from './Tooltip';
import { motion } from 'framer-motion';
import cx from 'classnames';
import CursorContentWrapper from './CursorContentWrapper';
import dayjs from 'dayjs';
import localeLookup from '../config/locale';
import { formatDate } from '../utils/helpers';
import Text from './Text';
import Icon from './Icon';
import { ROLE_MATRIX_PROGRESS_VIEWS } from '../constants';
import { format, add } from 'date-fns';

export default class GanttTask extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      barWidth: 0,
      contentWidth: 0,
      initialLeftPosition: props.leftPosition,
      initialRightPosition: props.rightPosition,
      isDragging: false,
      dragHandle: '',
      distanceDraggedLeft: 0,
      distanceDraggedRight: 0,
      showCursorInfo: false,
      disableCursorInfo: false,
    };
    this.deltaX = 0;
    this.previousX = 0;
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    const contentWidth = this.contentRef?.clientWidth;
    const barWidth = this.barRef?.clientWidth;
    this.setState({
      contentWidth,
      barWidth,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { distanceDraggedLeft, distanceDraggedRight, barWidth } = this.state;
    const { leftPosition, rightPosition } = this.props;
    if (
      prevState.distanceDraggedLeft !== distanceDraggedLeft ||
      prevState.distanceDraggedRight !== distanceDraggedRight
    ) {
      const contentWidth = this.contentRef?.clientWidth;
      const barWidth = this.barRef?.clientWidth;
      this.setState({
        barWidth,
        contentWidth,
      });
    }

    if (prevProps.leftPosition !== leftPosition) {
      this.setState({ initialLeftPosition: leftPosition }, () => {
        const contentWidth = this.contentRef?.clientWidth;
        const newBarWidth = this.barRef?.clientWidth;
        this.setState({
          barWidth: newBarWidth === 0 ? barWidth : newBarWidth,
          contentWidth,
        });
      });
    }
    if (prevProps.rightPosition !== rightPosition) {
      this.setState({ initialRightPosition: rightPosition }, () => {
        const contentWidth = this.contentRef?.clientWidth;
        const newBarWidth = this.barRef?.clientWidth;
        this.setState({
          barWidth: newBarWidth === 0 ? barWidth : newBarWidth,
          contentWidth,
        });
      });
    }
  }

  onClick = (e) => {
    const { isDragging } = this.state;
    const {
      onClick,
      personId,
      roleId,
      mentorId,
      startDate,
      endDate,
      canClick,
    } = this.props;
    if (isDragging || !canClick) return;
    onClick?.(e, { personId, roleId, mentorId, startDate, endDate });
  };

  onDragLeftHandle = (event, info) => {
    const { dragSnapInterval, leftPosition, width } = this.props;
    const { isDragging, distanceDraggedLeft } = this.state;
    const distance = info.delta.x;
    if (!isDragging) return;
    const direction = distance < 0 ? 'left' : 'right';
    const newLeftPosition = leftPosition + distanceDraggedLeft + distance;
    const limit = leftPosition + width - dragSnapInterval;
    if (direction === 'right' && newLeftPosition > limit) {
      return;
    }
    this.setState({
      distanceDraggedLeft: distanceDraggedLeft + distance,
    });
  };

  onDragRightHandle = (event, info) => {
    const { rightPosition, width, dragSnapInterval } = this.props;
    const { isDragging, distanceDraggedRight } = this.state;
    const distance = info.delta.x;
    if (!isDragging) return;
    const direction = distance < 0 ? 'left' : 'right';
    const newRightPosition =
      rightPosition + parseInt(distanceDraggedRight) + parseInt(distance);
    const limit = rightPosition - width + dragSnapInterval;
    if (direction === 'left' && newRightPosition < limit) {
      return;
    }
    this.setState({
      distanceDraggedRight: distanceDraggedRight + distance,
    });
  };

  onDragStart = (handle) => {
    this.setState({ isDragging: true, dragHandle: handle });
  };
  onDragEnd = async () => {
    const { distanceDraggedRight, dragHandle, distanceDraggedLeft } =
      this.state;
    const {
      dragSnapInterval,
      startDate,
      shownStartDate,
      endDate,
      shownEndDate,
      onChangeStartDate,
      onChangeEndDate,
      roleId,
      personId,
      mentorId,
      roleState,
    } = this.props;
    if (dragHandle === 'left') {
      const daysDragged = Math.ceil(distanceDraggedLeft / dragSnapInterval);
      const newStartDate = dayjs(startDate || shownStartDate).add(
        daysDragged,
        'day'
      );
      await onChangeStartDate?.({
        newDate: newStartDate.format('YYYY-MM-DD'),
        roleId,
        mentorId,
        personId,
        endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        roleState,
      });
    } else if (dragHandle === 'right') {
      const daysDragged = Math.ceil(distanceDraggedRight / dragSnapInterval);
      const newEndDate = dayjs(endDate || shownEndDate).add(daysDragged, 'day');
      await onChangeEndDate?.({
        newDate: newEndDate.format('YYYY-MM-DD'),
        roleId,
        mentorId,
        personId,
        startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
        roleState,
      });
    }
    this.setState({
      isDragging: false,
      dragHandle: '',
      distanceDraggedLeft: 0,
      distanceDraggedRight: 0,
    });
  };
  onMouseEnter = () => {
    this.setState({ showCursorInfo: true });
  };
  onMouseLeave = () => {
    this.setState({ showCursorInfo: false });
  };

  renderCursorContent = () => {
    const { startDate, endDate, dragSnapInterval, shownEndDate } = this.props;
    const {
      dragHandle,
      distanceDraggedRight,
      isDragging,
      showCursorInfo,
      distanceDraggedLeft,
    } = this.state;
    if (isDragging) {
      let content = '';
      if (dragHandle === 'left') {
        const daysDragged = Math.ceil(distanceDraggedLeft / dragSnapInterval);
        const date = new Date(startDate);
        const dateWithDaysAdded = add(date, { days: daysDragged });
        content = formatDate(dateWithDaysAdded);
      } else if (dragHandle === 'right') {
        const daysDragged = Math.ceil(distanceDraggedRight / dragSnapInterval);
        const date = new Date(endDate || shownEndDate);
        const dateWithDaysAdded = add(date, { days: daysDragged });
        content = formatDate(dateWithDaysAdded);
      }
      return (
        <div className="gantt-task__cursor-box">
          <Text>
            {dragHandle === 'left'
              ? localeLookup('translations.New start date')
              : localeLookup('translations.New end date')}
            : {content}
          </Text>
        </div>
      );
    }
    if (showCursorInfo) {
      return this.renderCursorTaskInfo();
    }
  };

  renderCursorTaskInfo = () => {
    const {
      role,
      person,
      mentor,
      startDate,
      endDate,
      progress,
      personInitials,
      personEmployeeNumber,
      mentorEmployeeNumber,
      mentorInitials,
      mentorSuffix,
      showMentorError,
      canEdit,
      progressOverTime,
      progressView,
      hideCursorBox,
      mentorIsWildcardPerson,
      roleState,
    } = this.props;

    if (hideCursorBox) return null;

    const currentProgressView = Object.values(ROLE_MATRIX_PROGRESS_VIEWS).find(
      (obj) => obj.value === progressView
    );
    const progressViewLabel = `${currentProgressView.amount} ${localeLookup(
      currentProgressView.title
    )}`;
    return (
      <div className="gantt-task__cursor-box">
        <Text bold>
          {role}
          {canEdit ? '' : ` (${localeLookup('translations.Read only')})`}
        </Text>
        <Text size="sm">
          {person} ({personInitials}
          {personEmployeeNumber ? ` · ${personEmployeeNumber}` : ''}) <br />
          {localeLookup('translations.Mentor')}: {mentor}{' '}
          {mentorIsWildcardPerson ? '' : '('}
          {mentorInitials}
          {mentorEmployeeNumber ? ` · ${mentorEmployeeNumber}` : ''}
          {mentorIsWildcardPerson ? '' : ')'} {mentorSuffix}{' '}
          {showMentorError ? (
            <Text as="span" color="red">
              *
            </Text>
          ) : null}
          <br />
          {localeLookup('translations.Completion')}: {progress}%
          <br />
          {localeLookup('translations.Start date')}:{' '}
          {startDate
            ? formatDate(startDate)
            : localeLookup('translations.No start date')}
          <br />
          {localeLookup('translations.End date')}:{' '}
          {endDate
            ? formatDate(endDate)
            : localeLookup('translations.No end date')}
          <br />
          {roleState && roleState === 'Passive'
            ? localeLookup('translations.Limited visibility')
            : null}
        </Text>
        {progressView !== 'none' && (
          <Text bold size="sm">
            <br />
            {localeLookup('translations.Progress')} ({progressViewLabel}):{' '}
            {progressOverTime[progressView]}%
          </Text>
        )}
      </div>
    );
  };

  render() {
    const {
      initialLeftPosition,
      initialRightPosition,
      isDragging,
      contentWidth,
      barWidth,
      distanceDraggedLeft,
      distanceDraggedRight,
      showCursorInfo,
      disableCursorInfo,
    } = this.state;
    const {
      role,
      progress,
      dragSnapInterval,
      personInitials,
      mentorInitials,
      endDate,
      canEdit,
      showMentorError,
      canClick,
      progressOverTime,
      progressView,
      roleState,
      isAllRoleOrganisationUnitsPassive,
    } = this.props;
    const style = {
      right: `${
        initialRightPosition -
        Math.ceil(distanceDraggedRight / dragSnapInterval) * dragSnapInterval
      }px`,
      left: `${
        initialLeftPosition +
        Math.ceil(distanceDraggedLeft / dragSnapInterval) * dragSnapInterval
      }px`,
    };
    const progressBarStyle = { width: `${progress}%` };
    const timeProgressWidth = progressOverTime
      ? (progressOverTime[progressView] / progress) * 100
      : 0;
    return (
      <CursorContentWrapper
        offsetX={10}
        offsetY={10}
        enabled={(isDragging || showCursorInfo) && !disableCursorInfo}
        cursorContent={this.renderCursorContent()}
      >
        <div
          className={cx('gantt-task', {
            'gantt-task--content-outside': barWidth <= contentWidth + 10,
            'gantt-task--no-end-date': !endDate || endDate === '',
            'gantt-task--not-clickable': !canClick,
            'gantt-task--theme-grey':
              (roleState && roleState === 'Passive') ||
              isAllRoleOrganisationUnitsPassive === true,
          })}
        >
          <motion.div
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            ref={(el) => (this.barRef = el)}
            className="gantt-task__bar"
            style={style}
            onClick={this.onClick}
          >
            <div
              className="gantt-task__content"
              data-group-id={this.props.groupId}
              data-person-id={this.props.personId}
              data-role-id={this.props.roleId}
            >
              {progress !== 0 && (
                <div
                  className="gantt-task__bar-foreground"
                  style={progressBarStyle}
                >
                  {progressView !== 'none' && (
                    <div
                      className="gantt-task__time-progress"
                      style={{ width: `${timeProgressWidth}%` }}
                    />
                  )}
                  <div className="gantt-task__secondary-content">
                    <p className="gantt-task__title">{role}</p>
                    <p className="gantt-task__subtitle">
                      {personInitials} · {localeLookup('translations.Mentor')}:{' '}
                      {mentorInitials}{' '}
                      {showMentorError ? (
                        <Text
                          style={{
                            fontSize: '20px',
                            lineHeight: 0,
                            verticalAlign: 'middle',
                          }}
                          as="span"
                          color="light-red"
                        >
                          *
                        </Text>
                      ) : null}
                    </p>
                  </div>
                </div>
              )}
              <div className="gantt-task__bar-background">
                <div
                  ref={(el) => (this.contentRef = el)}
                  className="gantt-task__main-content"
                >
                  <p className="gantt-task__title">{role}</p>
                  <p className="gantt-task__subtitle">
                    {personInitials} · {localeLookup('translations.Mentor')}:{' '}
                    {mentorInitials}{' '}
                    {showMentorError ? (
                      <Text
                        style={{
                          fontSize: '20px',
                          lineHeight: 0,
                          verticalAlign: 'middle',
                        }}
                        as="span"
                        color="red"
                      >
                        *
                      </Text>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
            {canEdit && (
              <>
                <motion.div
                  onDragStart={() => this.onDragStart('left')}
                  onDragEnd={this.onDragEnd}
                  onDrag={this.onDragLeftHandle}
                  className="gantt-task__drag-handle gantt-task__drag-handle--left"
                  drag="y"
                  dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  dragElastic={0}
                  dragMomentum={false}
                ></motion.div>
                <motion.div
                  onDragStart={() => this.onDragStart('right')}
                  onDragEnd={this.onDragEnd}
                  onDrag={this.onDragRightHandle}
                  className="gantt-task__drag-handle gantt-task__drag-handle--right"
                  drag="y"
                  dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  dragElastic={0}
                  dragMomentum={false}
                ></motion.div>
              </>
            )}
            {endDate === '' && (
              <Tooltip
                onVisibleChange={(visible) =>
                  this.setState({ disableCursorInfo: visible })
                }
                tooltip={localeLookup('translations.No end date')}
              >
                <div className="gantt-task__end-date-alert">
                  <Icon color="red" kind="warning"></Icon>
                </div>
              </Tooltip>
            )}
          </motion.div>
        </div>
      </CursorContentWrapper>
    );
  }
}
