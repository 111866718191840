import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import CheckList from '../CheckList';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import EmptyState from '../EmptyState';
import Text from '../Text';
import SwitchCheckbox from '../SwitchCheckbox';
import { compareFalsy, compareLocal, sortBy } from '../../utils/helpers';
import ModalSection from './ModalSection';
import { ACCESS_LEVELS } from '../../constants';

export default class ContentAdministratorModal extends PureComponent {
  constructor(props) {
    super(props);
    const { personAccessLevels } = props;
    this.state = {
      filterString: '',
      selectedOptionIds: props.selectedOptionIds || [],
      overAllTeamAdministrator: [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) => personAccessLevels.includes(accessLevel)),
    };
  }

  isAllOptionsSelected = () => {
    const { selectedOptionIds } = this.state;
    const { renderSectioned, options } = this.props;
    if (renderSectioned) {
      return options.every((group) =>
        group.options.every((option) => selectedOptionIds.includes(option.id))
      );
    } else {
      return options.every((option) => selectedOptionIds.includes(option.id));
    }
  };

  getVisibleOptions = (options) => {
    const { selectedOptionIds } = this.props;
    const { filterString } = this.state;
    const filteredOptions = options.filter((option) => {
      if (option.searchString) {
        return option.searchString
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return (
        option.title.toLowerCase().includes(filterString.toLowerCase()) ||
        (option.subtitle &&
          option.subtitle.toLowerCase().includes(filterString.toLowerCase()))
      );
    });

    return sortBy(filteredOptions, [
      (a, b) => compareLocal(a.title, b.title),
      (a, b) =>
        compareFalsy(
          selectedOptionIds.includes(a.id),
          selectedOptionIds.includes(b.id)
        ),
    ]);
  };

  onConfirm = () => {
    const { options, selectedOptionIds, overAllTeamAdministrator } = this.state;
    const { onConfirm } = this.props;
    onConfirm({ selectedOptionIds, options, overAllTeamAdministrator });
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onToggleOption = (id) => {
    const { selectedOptionIds } = this.state;
    this.setState({
      selectedOptionIds: selectedOptionIds.includes(id)
        ? selectedOptionIds.filter((selectedId) => selectedId !== id)
        : [...selectedOptionIds, id],
    });
  };

  onToggleOverallContentAdministrator = () => {
    const { overAllTeamAdministrator } = this.state;
    this.setState({ overAllTeamAdministrator: !overAllTeamAdministrator });
  };

  render() {
    const {
      title,
      onClose,
      confirmBtnText,
      onFilterButtonClick,
      filterPlaceholder,
      filterButtonText,
      showFilterButton,
      options,
      emptyStateText,
      subtitle,
      showToggleAll,
      personAccessLevels,
    } = this.props;
    const { filterString, selectedOptionIds, overAllTeamAdministrator } =
      this.state;
    const showEmptyState = options.length === 0;
    const overAllTeamAdministratorDisabled = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
    ].some((accessLevel) => personAccessLevels.includes(accessLevel));
    return (
      <ModalWrapper>
        <ModalHeader
          title={localeLookup('translations.Content administrator')}
          onClose={onClose}
          subtitle={subtitle}
        />
        <ModalSection>
          <SwitchCheckbox
            onChange={this.onToggleOverallContentAdministrator}
            isChecked={overAllTeamAdministrator}
            disabled={overAllTeamAdministratorDisabled}
            tooltip={
              overAllTeamAdministratorDisabled
                ? localeLookup(
                    'translations.Can not change because person is administrator'
                  )
                : null
            }
            name="overallTeamAdministrator"
            wrapperClassName="mediator-approval-options__option"
            labelText={localeLookup(
              'translations.Content administrator in entire organisation'
            )}
          />
        </ModalSection>
        <ModalFilter
          placeholder={
            filterPlaceholder || `${localeLookup('translations.Search')}...`
          }
          onChange={this.onFilterChange}
          value={filterString}
          buttonIcon="plus-circle"
          buttonText={filterButtonText}
          onButtonClick={() => onFilterButtonClick({ filterString })}
          showButton={showFilterButton}
        />
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : (
            <CheckList
              disableAll={overAllTeamAdministrator}
              selectedOptionIds={
                overAllTeamAdministrator
                  ? options.map((option) => option.id)
                  : selectedOptionIds
              }
              options={this.getVisibleOptions(options)}
              onChange={this.onToggleOption}
            />
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}
