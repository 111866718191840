/* eslint-disable consistent-return */
import anchorme from 'anchorme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { setDefaultOptions } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import { store } from '../..';
import { DATE_FORMAT_STRINGS, TIME_FORMAT_STRINGS } from '../constants';
dayjs.extend(utc);
dayjs.extend(timezone);

const collators = {
  ta: new Intl.Collator('ta', { numeric: true }),
  da: new Intl.Collator('da', { numeric: true }),
  zh: new Intl.Collator('zh', { numeric: true }),
  en: new Intl.Collator('en', { numeric: true }),
  es: new Intl.Collator('es', { numeric: true }),
  hu: new Intl.Collator('hu', { numeric: true }),
  pl: new Intl.Collator('pl', { numeric: true }),
  de: new Intl.Collator('de', { numeric: true }),
  cs: new Intl.Collator('cs', { numeric: true }),
  ke: new Intl.Collator('en', { numeric: true }),
};
/**
 * Convert string to url slug
 * @param {*} string to convert
 * @returns {string}
 */
export const stringToSlug = (val) => {
  if (!val) return;
  return val
    .toLowerCase()
    .replace(/[^\w æøå]+/g, '')
    .replace(/ +/g, '-');
};

/**
 * Replace identifiable links with anchor tags in string for wysiwyg output
 * @param {*} string to convert
 * @returns {string}
 */
export const linkifyString = (string) => {
  if (!string) return;
  const replaced = string.replace(
    /href="(?!(|http|https):\/\/)/gi,
    'href="https://'
  );
  return anchorme({
    input: replaced,
    options: {
      protocol: 'https://',
      attributes: {
        target: '_blank',
        rel: 'noopener',
      },
    },
  });
};

/**
 * Truncate file name
 * @param {str} string to truncate
 * @param {length} number max length of string
 * @param {chracterPadding} number Number of chars to preserve in beginning and end of string
 * @returns {string}
 */
export const truncateFilename = (
  str = '',
  maxLength = 16,
  characterPadding = 4
) => {
  if (str.length <= maxLength) return str;
  const [fileName, ...extensions] = str.split('.');
  const truncatedFileName = `${fileName.slice(
    0,
    characterPadding
  )}...${fileName.slice(fileName.length - characterPadding, fileName.length)}.${
    extensions[extensions.length - 1]
  }`;
  return truncatedFileName;
};

export const createFormData = (properties) => {
  const data = new FormData();
  Object.keys(properties).map((key) => {
    data.append(key, properties[key]);
  });
  return data;
};

export const insertStringInString = (str, strToInsert, position) => {
  if (!str) return;
  return str.substring(0, position) + strToInsert + str.substring(position);
};

export const formatDate = (date, timeZone, includeTime) => {
  const state = store.getState();
  const organisationDateFormat = state.rootmenu?.dateFormat;
  const organisationTimeFormat = state.rootmenu?.timeFormat;

  const dateFormatString =
    DATE_FORMAT_STRINGS[organisationDateFormat] || 'DD.MM.YYYY';

  const timeFormatString =
    TIME_FORMAT_STRINGS[organisationTimeFormat] || 'HH:mm';

  if (includeTime) {
    if (timeZone === null) {
      return dayjs(date).format(`${dateFormatString} ${timeFormatString}`);
    }
    return dayjs(date)
      .tz(timeZone)
      .format(`${dateFormatString} ${timeFormatString}`);
  }
  if (timeZone === null) {
    return dayjs(date).format(dateFormatString);
  }

  return dayjs(date).tz(timeZone).format(dateFormatString);
};

export const formatTime = (dateWithTime, timeZone) => {
  const state = store.getState();
  const organisationTimeFormat = state.rootmenu?.timeFormat;

  const timeFormatString =
    TIME_FORMAT_STRINGS[organisationTimeFormat] || 'HH:mm';
  if (timeZone === null) {
    return dayjs(dateWithTime).format(`${timeFormatString}`);
  }
  return dayjs(dateWithTime).tz(timeZone).format(`${timeFormatString}`);
};

export const getCurrentDate = () => {
  const organisationTimeZone = store.getState().rootmenu?.timeZone;
  return dayjs().tz(organisationTimeZone);
};

export const getCurrentDateV2 = () => {
  const organisationTimeZone = store.getState().rootmenu?.timeZone;
  return utcToZonedTime(new Date(), organisationTimeZone);
};

export const getDateInTimezone = (date) => {
  const organisationTimeZone = store.getState().rootmenu?.timeZone;
  return utcToZonedTime(date, organisationTimeZone);
};

export const switchArrayItems = ({ arr, index1, index2 }) => {
  const list = Array.from(arr);
  const b = list[index1];
  list[index1] = list[index2];
  list[index2] = b;
  return list;
};

export const moveArrayItemToIndex = ({ arr, currentIndex, newIndex }) => {
  const list = [...arr];
  let numberOfDeletedElm = 1;
  const elm = list.splice(currentIndex, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  list.splice(newIndex, numberOfDeletedElm, elm);
  return list;
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

export const removeKeyFromObject = (obj, propToDelete) => {
  const { [propToDelete]: deleted, ...objectWithoutDeletedProp } = obj;
  return objectWithoutDeletedProp;
};

export const sortBy = (collection, compareFuncs, directions) => {
  const collectionCopy = [...collection];
  compareFuncs.forEach((compareFunc, i) => {
    if (directions?.[i] === 'desc') {
      collectionCopy.sort((a, b) => compareFunc(b, a));
    } else {
      collectionCopy.sort((a, b) => compareFunc(a, b));
    }
  });
  return collectionCopy;
};

export const compareLocal = (a, b) => {
  const locale = window.CHAMP?.locale?.data?.locale;
  const collator = collators[locale] || collators.en;
  return collator.compare(a, b);
};

export const compareEmpty = (a, b) => {
  if ((!a || a === '') && (!b || b === '')) return 0;
  if (!a || a === '') return 1;
  if (!b || b === '') return -1;
};

export const compareFalsy = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
};

export const compareDates = (a, b) => {
  var dateA = new Date(a).getTime();
  var dateB = new Date(b).getTime();
  return dateA > dateB ? 1 : -1;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const lerp = (x, y, a) => x * (1 - a) + y * a;
export const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x, y, a) => clamp((a - x) / (y - x));
export const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));

export const lowerCaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const setDefaultDateFnsOptions = async ({ locale }) => {
  if (locale === 'en') {
    setDefaultOptions({ locale: undefined });
  } else if (locale === 'zh') {
    const module = await import(`date-fns/locale/${locale}-CN`);
    setDefaultOptions({ locale: module.default });
    return;
  } else {
    const module = await import(`date-fns/locale/${locale}`);
    setDefaultOptions({ locale: module.default });
  }
};
