import React from 'react';
import { connect } from 'react-redux';
import { selectParentMap } from '../slices/organisationUnitsSlice';
import { ORGANISATION_UNIT_STATES } from '../constants';
import localeLookup from '../config/locale';

const mapStateToProps = (state) => ({
  organisationUnits: state.organisationUnits,
  organisationUnitParents: selectParentMap(state),
});

const OrganisationUnitPathTooltip = ({
  id,
  organisationUnitParents,
  organisationUnits,
}) => {
  if (organisationUnitParents[id].length === 0) return null;
  const unit = organisationUnits[id];
  const isVisibilityLimited =
    unit?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
    unit?.state === ORGANISATION_UNIT_STATES.PASSIVE;
  return (
    <>
      {organisationUnitParents[id].map((unit, i) => {
        const isVisibilityLimited =
          unit?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
          unit?.state === ORGANISATION_UNIT_STATES.PASSIVE;
        return (
          <span key={unit.id}>
            {Array(i)
              .fill('')
              .map((i) => '·')}{' '}
            {unit.name}{' '}
            {isVisibilityLimited
              ? `(${localeLookup('translations.Limited visibility')})`
              : null}
            <br />
          </span>
        );
      })}
      <span style={{ fontWeight: 'bold' }}>
        {Array(organisationUnitParents[id].length)
          .fill('')
          .map((i) => '·')}{' '}
        {unit.name}{' '}
        {isVisibilityLimited
          ? `(${localeLookup('translations.Limited visibility')})`
          : null}
      </span>
    </>
  );
};

export default connect(mapStateToProps)(OrganisationUnitPathTooltip);
