import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Role from '../components/Role';

import {
  updateMenteeElementMediator,
  updateMenteeAreaStatus,
  updateMenteeAreaLinkState,
  updateKnowledgeElement,
  getMenteeRole,
  updateArea,
} from '../actions/roleActions';
import LoadScreen from '../components/LoadScreen';
import { getQueryStringParams } from '../utils/helpers';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorState from '../components/ErrorState';
import { trackEvent } from '../utils/tracking';

const mapStateToProps = (state) => {
  const { role, persons } = state;
  const {
    id,
    name,
    completionPercentage,
    mentor,
    mentorId,
    menteeId,
    description,
    knowledgeAreas,
    knowledgeElements,
    categories,
    knowledgeAreasSortOrder,
    additionalKnowledgeAreasSortOrder,
    wildcardPersons,
    isLoading,
    startDate,
    endDate,
  } = role;
  return {
    id,
    name,
    completionPercentage,
    mentor,
    mentorId,
    menteeId,
    description,
    knowledgeAreas,
    knowledgeElements,
    persons,
    categories,
    knowledgeAreasSortOrder,
    additionalKnowledgeAreasSortOrder,
    wildcardPersons,
    isLoading,
    startDate,
    endDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getMenteeRole,
      updateMenteeElementMediator,
      updateMenteeAreaStatus,
      updateMenteeAreaLinkState,
      updateKnowledgeElement,
      updateArea,
    },
    dispatch
  ),
});

class RoleContainer extends Component {
  state = {
    isLoading: true,
    hasError: false,
  };

  componentDidMount() {
    const { location, getMenteeRole, match, overlayMenteeId, overlayRoleId } =
      this.props;
    trackEvent('$pageview');
    this.getRole();
  }

  getRole = () => {
    const { location, getMenteeRole, match, overlayMenteeId, overlayRoleId } =
      this.props;
    if (overlayMenteeId && overlayRoleId) {
      getMenteeRole(overlayMenteeId, overlayRoleId)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            hasError: true,
          });
        });
    } else {
      const queryParams = getQueryStringParams(window.location.search);
      getMenteeRole(queryParams.menteeId, match.params.roleId)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            hasError: true,
          });
        });
    }
  };

  onUpdateElement = ({ elementId, areaId }) => {
    const { menteeId, updateKnowledgeElement, updateArea, id } = this.props;
    return updateKnowledgeElement(menteeId, elementId).then(() => {
      updateArea({ personId: menteeId, areaId, roleId: id });
    });
  };

  updateAreaStatus = (roleId, areaId, status) => {
    const { isMenteeContext, menteeId, updateMenteeAreaStatus } = this.props;
    if (isMenteeContext) {
      updateMenteeAreaStatus({ menteeId, roleId, areaId, status });
    }
  };

  updateElementMediator = (areaId, elementId, mediatorId) => {
    const { id, menteeId, updateMenteeElementMediator } = this.props;
    const { isMenteeContext } = this.props;
    if (isMenteeContext) {
      updateMenteeElementMediator({
        menteeId,
        roleId: id,
        areaId,
        elementId,
        newMediatorId: mediatorId,
      });
    }
  };

  updateAreaLinkState = (roleId, areaId, link) => {
    const { isMenteeContext, menteeId, updateMenteeAreaLinkState } = this.props;
    if (isMenteeContext) {
      updateMenteeAreaLinkState({ menteeId, roleId, areaId, link });
    }
  };

  onCloseHistoryModal = () => {
    const { getMenteeRole, id, menteeId } = this.props;
    getMenteeRole(menteeId, id, null, false);
  };

  render() {
    const {
      id,
      isMenteeContext,
      name,
      completionPercentage,
      mentor,
      mentorId,
      menteeId,
      description,
      knowledgeAreas,
      knowledgeElements,
      persons,
      categories,
      knowledgeAreasSortOrder,
      additionalKnowledgeAreasSortOrder,
      wildcardPersons,
      updateKnowledgeElement,
      startDate,
      endDate,
    } = this.props;
    const { isLoading, hasError } = this.state;
    if (isLoading) return <LoadScreen />;
    return (
      <ErrorBoundary hasError={hasError}>
        <Role
          id={id}
          isMenteeContext={isMenteeContext}
          name={name}
          completionPercentage={completionPercentage}
          mentor={mentor}
          mentorId={mentorId}
          menteeId={menteeId}
          description={description}
          knowledgeAreas={knowledgeAreas}
          knowledgeElements={knowledgeElements}
          persons={persons}
          categories={categories}
          startDate={startDate}
          endDate={endDate}
          knowledgeAreasSortOrder={knowledgeAreasSortOrder}
          additionalKnowledgeAreasSortOrder={additionalKnowledgeAreasSortOrder}
          wildcardPersons={wildcardPersons}
          updateKnowledgeElementMediator={this.updateElementMediator}
          updateKnowledgeAreaStatus={this.updateAreaStatus}
          updateKnowledgeElement={this.onUpdateElement}
          onChangeAreaLinkState={this.updateAreaLinkState}
          onCloseHistoryModal={this.onCloseHistoryModal}
        />
      </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer);
