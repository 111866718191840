import { pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { PERSON_STATES } from '../constants';
import {
  getAllPersonsService,
  getPersonsWithAccessLevelsService,
} from '../services/personsService';
import { compareLocal, sortBy } from '../utils/helpers';

// Actions
const LOAD = 'persons/LOAD';
const REMOVE_PERSON = 'persons/REMOVE_PERSON';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        ...action.persons,
      };
    case REMOVE_PERSON:
      const { [action.id]: value, ...withoutPerson } = state;
      return withoutPerson;
    default:
      return state;
  }
};

export const getPersons = () => (dispatch) =>
  getAllPersonsService().then(({ data }) => {
    dispatch({ type: LOAD, persons: data });
    return data;
  });

export const removePerson = (id) => (dispatch) => {
  dispatch({ type: REMOVE_PERSON, id });
};

// Selectors

export const selectPersons = (state) => state.persons;

export const selectActivePersons = (state) => {
  return pickBy(state.persons, (person) => {
    return person.state === PERSON_STATES.ACTIVE;
  });
};

export const selectActivePersonsArray = createSelector(
  selectPersons,
  (persons) => {
    return Object.values(persons).filter((person) => {
      return person.state === PERSON_STATES.ACTIVE;
    });
  }
);
export const selectAllPersonsArray = createSelector(selectPersons, (persons) =>
  Object.values(persons)
);

export const selectActivePersonsSortOrder = createSelector(
  selectActivePersonsArray,
  (activePersons) => {
    return sortBy(activePersons, [(a, b) => compareLocal(a.name, b.name)]).map(
      (person) => person.id
    );
  }
);

export const selectAllPersonsSortOrder = createSelector(
  selectAllPersonsArray,
  (activePersons) => {
    return sortBy(activePersons, [(a, b) => compareLocal(a.name, b.name)]).map(
      (person) => person.id
    );
  }
);

export const selectArchivedPersonsSortOrder = (state) =>
  sortBy(
    Object.keys(state.persons).filter(
      (id) => state.persons[id].state === PERSON_STATES.ARCHIVED
    ),
    [(a, b) => compareLocal(state.persons[a].name, state.persons[b].name)]
  );
