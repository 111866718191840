import React from 'react';
import localeLookup from '../../config/locale';
import Button from '../Button';
import Icon from '../Icon';
import PersonCard from '../PersonCard';
import Tooltip from '../Tooltip';

const Experts = ({ experts, onRemoveClick, onAddClick }) => {
  return (
    <section className="editor__section">
      <div className="editor__section-header">
        <Tooltip tooltip={localeLookup('translations.ExpertsInfo')}>
          <h2 className="editor__section-title">
            {localeLookup('translations.Experts')}
            <Icon className="editor__section-title-icon" kind="info-circle" size="x-small" color="dark-grey"></Icon>
          </h2>
        </Tooltip>
      </div>

      <div className="editor__section-body">
        {experts.map((expert) => {
          return <PersonCard key={expert.id} name={expert.name} subtitle={localeLookup('translations.Expert')} onRemove={() => onRemoveClick(expert.id)} />;
        })}
        <Button className="editor__section-button" onClick={onAddClick} kind="link-style">
          {experts.length > 0 ? localeLookup('translations.Edit') : localeLookup('translations.Add')}...
        </Button>
      </div>
    </section>
  );
};

export default Experts;
