import localeLookup from './locale';

export default {
  platformAdministration: {
    title: localeLookup('translations.Platform administration'),
    getTranslatedTitle: () => localeLookup('translations.Platform administration'),
    icon: 'hammer-wrench',
    path: '', // adminSiteBaseURL
    visibilityKey: 'platformAdministration',
  },
  revert: {
    title: localeLookup('translations.Back'),
    getTranslatedTitle: () => localeLookup('translations.Back'),
    icon: 'exit-left',
    path: '/api/organisations/revert-inspect',
    visibilityKey: 'revert',
  },
};
