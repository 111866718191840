import { MENTOR_ROLES_RECEIVED } from '../actions/trainingActions';

const initialState = {
  mentees: {},
  trainingTasks: {},
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case MENTOR_ROLES_RECEIVED:
      return { ...state, ...data };
    default:
      return state;
  }
};
