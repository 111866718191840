import React, { Component } from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';
import cx from 'classnames';

export default class MultiStateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStateId: props.activeStateId,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { activeStateId } = this.props;
    const { activeStateId: componentState } = this.state;
    if (activeStateId !== componentState) {
      this.setState({ activeStateId });
    }
  }
  onClick = () => {
    const { activeStateId } = this.state;
    const { states, onClick } = this.props;
    const nextStateId = states[(states.findIndex((state) => state.stateId === activeStateId) + 1) % states.length].stateId;
    this.setState({
      activeStateId: nextStateId,
    });
    onClick(nextStateId);
  };
  renderCurrentState = () => {
    const { states } = this.props;
    const { activeStateId } = this.state;
    const state = states.find((state) => state.stateId === activeStateId);
    return (
      <Tooltip>
        <Icon color={state.iconColor || null} className="multi-state-button__icon" kind={state.icon}></Icon>
      </Tooltip>
    );
  };
  render() {
    const { className, color, disabled, states, rounded, isRequired, tooltip } = this.props;
    const { activeStateId } = this.state;
    const currentState = states.find((state) => state.stateId === activeStateId);
    if (!currentState) return null;
    const modifierClasses = {
      [`multi-state-button--color-${currentState.color || color}`]: currentState.color || color,
      'multi-state-button--rounded': rounded,
      'multi-state-button--required': isRequired,
    };
    return (
      <Tooltip
        align={{
          offset: [20, 0],
        }}
        tooltip={tooltip}
      >
        <button disabled={disabled} className={cx('multi-state-button', modifierClasses, className)} onClick={this.onClick}>
          <div className="multi-state-button__inner">{this.renderCurrentState()}</div>
        </button>
      </Tooltip>
    );
  }
}
