import React from 'react';
import Icon from '../Icon';
import ConfirmButton from '../ConfirmButton';
import localeLookup from '../../config/locale';

const Document = ({ fileName, url, onDelete, canDelete }) => {
  return (
    <div className="document">
      <div className="document__icon-wrapper">
        <Icon className="document__icon" kind="paperclip" />
      </div>
      <div className="document__info">
        <p className="document__info-name">
          <span>{fileName}</span>
        </p>
      </div>
      {canDelete && (
        <ConfirmButton
          onConfirm={onDelete}
          render={(onClick, isActive) => {
            return (
              <button onClick={onClick} className="ui-btn ui-btn-alert ui-small">
                {localeLookup('translations.Delete')}
              </button>
            );
          }}
        />
      )}
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className="ui-btn ui-btn-darkui ui-small document__delete"
      >
        {localeLookup('translations.Open')}
      </a>
    </div>
  );
};

export default Document;
