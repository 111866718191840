import { format, isAfter, isBefore, isSameDay, parse, set } from 'date-fns';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { TIME_FORMAT_STRINGS } from '../constants';
import { updateTrainingSessionDateAndTimeService } from '../services/trainingSessionService';
import DateFieldV2 from './DateFieldV2';
import { Grid } from './Grid';
import TimeField from './TimeField';
import Field from './formElements/Field';
import FormWrapper from './formElements/FormWrapper';
import ModalBody from './modal/ModalBody';
const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
  persons: state.persons,
  timeFormat: state.rootmenu?.timeFormat,
});

const mapDispatchToProps = {};

class ChangeTrainingSessionDatesForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  onSubmit = (values) => {
    const { onChanged, id } = this.props;
    const { startDate, endDate, startTime, endTime } = values;
    this.setState({ isSubmitting: true });
    const startTimePart = startTime ? format(startTime, 'HH:mm') : null;
    const endTimePart = endTime ? format(endTime, 'HH:mm') : null;
    updateTrainingSessionDateAndTimeService(id, {
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      startTime: startTimePart,
      endTime: endTimePart,
    }).then(({ data }) => {
      onChanged?.({ data });
    });
  };

  render() {
    const { isSubmitting } = this.state;
    const { renderFooter, initialValues, timeFormat } = this.props;
    const validationSchema = Yup.object().shape({
      startDate: Yup.date()
        .nullable()
        .test(
          'is-before-end-date',
          localeLookup('translations.Start date must be before end date'),
          function (value) {
            if (value && value !== '' && this?.parent?.endDate) {
              const valueObj = new Date(value);
              const endDateObj = new Date(this.parent.endDate);
              return (
                isBefore(valueObj, endDateObj) ||
                isSameDay(valueObj, endDateObj)
              );
            } else {
              return true;
            }
          }
        ),
      startTime: Yup.date()
        .nullable()
        .test(
          'is-filled-if-required',
          localeLookup(
            'translations.Start time is required when end time and start date is set'
          ),
          function (value) {
            if (this?.parent?.endTime && this?.parent?.startDate) {
              return value && value !== '';
            }
            return true;
          }
        ),
      endDate: Yup.date()
        .nullable()
        .when('endTime', {
          is: (value) => value && value !== '',
          then: () =>
            Yup.date().required(
              'translations.End date is required when end time is set'
            ),
        })
        .test(
          'is-after-start-date',
          localeLookup('translations.End date must be after start date'),
          function (value) {
            if (this?.parent?.startDate && value && value !== '') {
              const valueObj = new Date(value);
              const startDateObj = new Date(this.parent.startDate);
              return (
                isAfter(valueObj, startDateObj) ||
                isSameDay(valueObj, startDateObj)
              );
            } else {
              return true;
            }
          }
        ),
      endTime: Yup.date()
        .nullable()
        .test(
          'is-filled-if-required',
          localeLookup(
            'translations.End time is required when start time and end date is set'
          ),
          function (value) {
            if (this?.parent?.startTime && this?.parent?.endDate) {
              return value && value !== '';
            }
            return true;
          }
        )
        .test(
          'is-after-start-date',
          localeLookup(
            'translations.End time must be after start date and time'
          ),
          function (value) {
            if (
              this?.parent?.startDate &&
              this?.parent?.startTime &&
              this?.parent?.endDate &&
              value &&
              value !== ''
            ) {
              const startDateObj = new Date(this.parent.startDate);
              const startTimeObj = new Date(this.parent.startTime);
              const startDateWithStartTime = set(startDateObj, {
                hours: startTimeObj.getHours(),
                minutes: startTimeObj.getMinutes(),
              });
              const endDateObj = new Date(this.parent.endDate);
              const endTimeObj = new Date(this.parent.endTime);
              const endDateWithEndTime = set(endDateObj, {
                hours: endTimeObj.getHours(),
                minutes: endTimeObj.getMinutes(),
              });
              return isAfter(endDateWithEndTime, startDateWithStartTime);
            } else {
              return true;
            }
          }
        ),
    });
    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        validateOnMount
        validateOnChange
        validationSchema={validationSchema}
        initialValues={{
          startDate: initialValues.startDate
            ? parse(initialValues.startDate, 'yyyy-MM-dd', new Date())
            : null,
          endDate: initialValues.endDate
            ? parse(initialValues.endDate, 'yyyy-MM-dd', new Date())
            : null,
          startTime: initialValues.startTime
            ? parse(
                initialValues.startTime,
                timeFormat === TIME_FORMAT_STRINGS.TwelveHour
                  ? 'hh:mm'
                  : 'HH:mm',
                new Date()
              )
            : null,
          endTime: initialValues.endTime
            ? parse(
                initialValues.endTime,
                timeFormat === TIME_FORMAT_STRINGS.TwelveHour
                  ? 'hh:mm'
                  : 'HH:mm',
                new Date()
              )
            : null,
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          setFieldValue,
          validateForm,
        }) => (
          <>
            <ModalBody>
              <form
                className="create-training-session-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Grid gridTemplateColumns="1fr 1fr" columnGap="1rem">
                  <DateFieldV2
                    label={localeLookup('translations.Start date')}
                    error={errors.startDate}
                    onBlur={handleBlur}
                    name="startDate"
                    id="startDate"
                    minDate={new Date()}
                    maxDate={values.endDate}
                    value={values.startDate}
                    showFormatAsPlaceholder
                    clearable
                    onClear={async () => {
                      await setFieldValue('startDate', '');
                      setFieldValue('startTime', '');
                    }}
                    onChange={(value) => {
                      setFieldValue('startDate', value);
                    }}
                  />
                  <Field
                    label={localeLookup('translations.Start time')}
                    margin="none"
                    required={
                      values.startDate && values.endDate && values.endTime
                    }
                  >
                    <TimeField
                      error={errors.startTime}
                      onBlur={handleBlur}
                      name="startTime"
                      id="startTime"
                      disabled={!values.startDate}
                      value={values.startTime}
                      clearable
                      onClear={() => {
                        setFieldValue('startTime', '');
                      }}
                      onChange={(value) => {
                        setFieldValue('startTime', value);
                      }}
                    />
                  </Field>
                  <DateFieldV2
                    label={localeLookup('translations.End date')}
                    error={errors.endDate}
                    onBlur={handleBlur}
                    name="endDate"
                    id="endDate"
                    minDate={values.startDate || new Date()}
                    value={values.endDate}
                    showFormatAsPlaceholder
                    clearable
                    onClear={async () => {
                      await setFieldValue('endDate', '');
                      setFieldValue('endTime', '');
                    }}
                    onChange={(value) => {
                      setFieldValue('endDate', value);
                    }}
                  />

                  <Field
                    label={localeLookup('translations.End time')}
                    margin="none"
                    required={
                      values.startDate && values.startTime && values.endDate
                    }
                  >
                    <TimeField
                      disabled={!values.endDate}
                      error={errors.endTime}
                      onBlur={handleBlur}
                      name="endTime"
                      id="endTime"
                      minDate={new Date()}
                      value={values.endTime}
                      clearable
                      onClear={() => {
                        setFieldValue('endTime', '');
                      }}
                      onChange={(value) => {
                        setFieldValue('endTime', value);
                      }}
                    />
                  </Field>
                </Grid>
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(ChangeTrainingSessionDatesForm);
