const initialState = {
    title: null,
    breadcrumbs: []
}

const header = function(state = initialState, action){
    switch(action.type){
        case 'SET_HEADER_TITLE':
            return {
                ...state,
                title: action.titleText
            }
        case 'SET_BREADCRUMBS':
            return {
                breadcrumbs: action.breadcrumbs
            }
        default:
            return state;
    }
}

export default header;