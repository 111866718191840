import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import Input from '../formElements/Input';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
// Components
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import ButtonIcon from '../ButtonIcon';
import Button from '../Button';
import cx from 'classnames';
import RadioButton from '../RadioButton';
import Text from '../Text';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import { compareLocal, sortBy } from '../../utils/helpers';
import { updateOrganisationUnitLocationService } from '../../services/organisationService';
import { connect } from 'react-redux';
import { ORGANISATION_UNIT_STATES } from '../../constants';

const mapStateToProps = (state) => ({
  organisationUnitRootNodes: state.organisation.organisationUnitRootNodes,
  organisationUnits: state.organisation.organisationUnits,
});

const mapDispatchToProps = {};

const motion = {
  div: createDomMotionComponent('div'),
};

class EditOrganisationUnitsModal extends Component {
  constructor() {
    super();
    this.state = {
      addUnitToId: null,
      addUnitName: '',
      editingNameId: null,
      movingUnitId: '',
      movingUnitDestinationId: '',
    };
  }

  onAddUnitInputChange = (e) => {
    this.setState({
      addUnitName: e.target.value,
    });
  };

  onConfirm = () => {
    this.props.onClose();
  };

  onAddUnitBlur = () => {
    setTimeout(() => {
      this.setState({
        addUnitToId: null,
        addUnitName: '',
      });
    }, 0);
  };

  onAddUnitClick = (e, id) => {
    e.preventDefault();
    this.setState({
      addUnitToId: id,
    });
  };

  onAddUnitSubmit = (e, parentId) => {
    const { addUnitName } = this.state;
    const { onAddOrganisationUnit } = this.props;
    e.preventDefault();
    addUnitName.length > 0 && onAddOrganisationUnit(parentId, addUnitName);
    this.setState({
      addUnitToId: null,
      addUnitName: '',
    });
  };
  onChangeMoveDestination = (id) => {
    this.setState({ movingUnitDestinationId: id });
  };
  onClickCancelMove = () => {
    this.setState({
      movingUnitDestinationId: '',
      movingUnitId: '',
      movingUnitOriginId: '',
    });
  };
  onClickMoveUnit = (id, parentId) => {
    this.setState({
      movingUnitId: id,
      movingUnitOriginId: parentId,
    });
  };
  onEditUnitNameClick = (id) => {
    this.setState({
      editingNameId: id,
    });
  };

  onEditUnitNameInputBlur = () => {
    this.setState({
      editingNameId: null,
    });
  };

  onEditUnitNameSubmit = (id, newName) => {
    const { onChangeOrganisationUnitName } = this.props;
    onChangeOrganisationUnitName(id, newName);
  };

  onSubmitMoveUnit = () => {
    const { onChangeOrganisationUnitLocation } = this.props;
    const { movingUnitDestinationId, movingUnitId, movingUnitOriginId } =
      this.state;

    updateOrganisationUnitLocationService(
      movingUnitId,
      movingUnitOriginId,
      movingUnitDestinationId
    ).then(() => {
      this.setState({
        movingUnitDestinationId: '',
        movingUnitId: '',
        movingUnitOriginId: '',
      });
      onChangeOrganisationUnitLocation();
    });
  };

  renderOrganisationTree = (
    organisationUnit,
    organisationUnits,
    isChild = false,
    isMovingParent,
    parentId
  ) => {
    const {
      editingNameId,
      addUnitToId,
      addUnitName,
      movingUnitId,
      movingUnitDestinationId,
    } = this.state;
    const { onDeleteOrganisationUnit } = this.props;
    if (!organisationUnit) {
      return null;
    }
    const { organisationName, descendants, id, isReadOnly, canEdit } =
      organisationUnit;
    const canEditNameOrDelete = !isReadOnly && canEdit;
    const isMoving = movingUnitId !== '';
    const isMovingThisUnit = movingUnitId === id;
    const isMovingDescendant = descendants.includes(movingUnitId);
    const isVisibilityLimited =
      organisationUnit?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
      organisationUnit?.state === ORGANISATION_UNIT_STATES.PASSIVE;
    const getDescendants = () => {
      if (movingUnitDestinationId === id) {
        return sortBy(
          [...descendants, movingUnitId],
          [
            (a, b) => {
              return compareLocal(
                organisationUnits[a]?.organisationName,
                organisationUnits[b]?.organisationName
              );
            },
          ]
        );
      }
      return sortBy(descendants, [
        (a, b) =>
          compareLocal(
            organisationUnits[a]?.organisationName,
            organisationUnits[b]?.organisationName
          ),
      ]);
    };
    return (
      <li
        className={cx('organisation-unit-modal__organisation-unit', {
          'organisation-unit-modal__organisation-unit--child': isChild,
          'organisation-unit-modal__organisation-unit--moving':
            isMovingThisUnit,
          'organisation-unit-modal__organisation-unit--hide':
            movingUnitDestinationId !== '' &&
            isMovingThisUnit &&
            parentId !== movingUnitDestinationId,
          'organisation-unit-modal__organisation-unit--moving-new-destination':
            isMovingThisUnit && parentId === movingUnitDestinationId,
        })}
      >
        <div className="organisation-unit-modal__organisation-unit-wrapper">
          <div className="organisation-unit-modal__organisation-unit-info">
            {editingNameId === id && !isMoving ? (
              <InlineFieldEditor
                autoFocus
                classNameInput="ui-input-invisible organisation-unit-modal__organisation-unit-name-input"
                defaultValue={organisationName}
                onBlur={this.onEditUnitNameInputBlur}
                onSubmit={(newName) => {
                  this.onEditUnitNameSubmit(id, newName);
                }}
                fullWidth
                placeholder={localeLookup(
                  'translations.Name of organisation unit'
                )}
              />
            ) : (
              <p
                className="organisation-unit-modal__organisation-unit-title"
                onClick={
                  !canEditNameOrDelete
                    ? null
                    : () => {
                        this.onEditUnitNameClick(id);
                      }
                }
              >
                {organisationName}
                {canEditNameOrDelete && !isMoving && (
                  <Icon
                    kind="pencil"
                    className="organisation-unit-modal__organisation-unit-title-icon"
                  />
                )}
              </p>
            )}
            {'roleCount' in organisationUnit && (
              <p className="organisation-unit-modal__organisation-unit-subtitle">
                {organisationUnit.roleCount}{' '}
                {organisationUnit.roleCount === 1
                  ? localeLookup('translations.role')
                  : localeLookup('translations.roles')}
              </p>
            )}
            {'areaCount' in organisationUnit && (
              <p className="organisation-unit-modal__organisation-unit-subtitle">
                {organisationUnit.areaCount}{' '}
                {organisationUnit.areaCount === 1
                  ? localeLookup('translations.knowledge area')
                  : localeLookup('translations.knowledge areas')}
              </p>
            )}
            {isVisibilityLimited && (
              <p className="organisation-unit-modal__organisation-unit-subtitle">
                {localeLookup('translations.Limited visibility')}
              </p>
            )}
          </div>
          {!organisationUnit.isReadOnly && (
            <>
              {isChild && canEditNameOrDelete && !isMoving && (
                <>
                  <ButtonIcon
                    inline
                    icon="trash2"
                    onClick={() => {
                      onDeleteOrganisationUnit(organisationUnit);
                    }}
                  />

                  <ButtonIcon
                    inline
                    icon="move"
                    onClick={() => {
                      this.onClickMoveUnit(id, parentId);
                    }}
                  />
                </>
              )}
              {!isMoving && (
                <Button
                  kind="darkui"
                  size="small"
                  className="organisation-unit-modal__organisation-unit-button"
                  onClick={(e) => {
                    this.onAddUnitClick(e, id);
                  }}
                >
                  {localeLookup('translations.Add')}
                </Button>
              )}
              {isMoving && !isMovingThisUnit && !isMovingParent && (
                <RadioButton
                  id={id}
                  disabled={isMovingDescendant}
                  isChecked={
                    movingUnitDestinationId === id ||
                    (isMovingDescendant && movingUnitDestinationId === '')
                  }
                  onChange={() => {
                    this.onChangeMoveDestination(id);
                  }}
                />
              )}
            </>
          )}
        </div>
        {addUnitToId === id && (
          <div className="organisation-unit-modal__organisation-unit-addbox">
            <div className="organisation-unit-modal__organisation-unit-form">
              <Input
                autoFocus
                kind="invisible"
                wrapperClassName="organisation-unit-modal__organisation-unit-input"
                type="text"
                placeholder={localeLookup(
                  'translations.Name of organisation unit'
                )}
                onChange={this.onAddUnitInputChange}
                onBlur={this.onAddUnitBlur}
                onEnterPress={(e) => {
                  this.onAddUnitSubmit(e, id);
                }}
              />
              <Button
                kind="darkui"
                disabled={addUnitName.length === 0}
                size="small"
                onMouseDown={(e) => {
                  this.onAddUnitSubmit(e, id);
                }}
              >
                {localeLookup('translations.Save')}
              </Button>
            </div>
          </div>
        )}
        {getDescendants() &&
          getDescendants().map((key) => {
            return (
              <ul
                className="organisation-unit-modal__organisation-unit-list"
                key={key}
              >
                {this.renderOrganisationTree(
                  organisationUnits[key],
                  organisationUnits,
                  true,
                  isMovingParent || isMovingThisUnit,
                  id
                )}
              </ul>
            );
          })}
      </li>
    );
  };

  renderMovingActions = () => {
    const { movingUnitId } = this.state;
    const { organisationUnits } = this.props;
    return (
      <motion.div
        className="organisation-unit-modal__moving-actions-wrapper"
        animate={{ height: 'auto', opacity: 1 }}
        initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
        exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
      >
        <div className="organisation-unit-modal__moving-actions">
          <div className="organisation-unit-modal__moving-actions-left">
            <Text color="blue">
              {localeLookup(
                'translations.Select where you want to move {0} to',
                [organisationUnits[movingUnitId].organisationName]
              )}
            </Text>
          </div>
          <div className="organisation-unit-modal__moving-actions-right">
            <Button kind="cancel" onClick={this.onClickCancelMove} size="small">
              {localeLookup('translations.Cancel')}
            </Button>
            <Button kind="darkui" size="small" onClick={this.onSubmitMoveUnit}>
              {localeLookup('translations.Confirm')}
            </Button>
          </div>
        </div>
      </motion.div>
    );
  };

  render() {
    const { movingUnitId } = this.state;
    const {
      onClose,
      organisationUnits,
      title,
      subtitle,
      organisationUnitRootNodes,
    } = this.props;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <AnimatePresence>
          {movingUnitId && this.renderMovingActions()}
        </AnimatePresence>
        <ModalBody deep>
          <div className="organisation-unit-modal__organisation">
            <ul className="organisation-unit-modal__organisation-unit-list organisation-unit-modal__organisation-unit-list--editor">
              {organisationUnitRootNodes
                ? organisationUnitRootNodes.map((id) =>
                    this.renderOrganisationTree(
                      organisationUnits[id],
                      organisationUnits
                    )
                  )
                : this.renderOrganisationTree(
                    organisationUnits[Object.keys(organisationUnits)[0]],
                    organisationUnits
                  )}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter
          cancelButtonText={localeLookup('translations.Close')}
          onCancelClick={onClose}
          cancelButtonKind="darkui"
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(EditOrganisationUnitsModal);
