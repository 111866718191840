import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import { getRoleUsageService } from '../../services/contentService';
import Loader from '../Loader';
import SimpleTable from '../SimpleTable';

class RoleUsageInfo extends PureComponent {
  state = {
    usedInUnits: [],
    isLoading: true,
  };

  componentDidMount() {
    const { roleId } = this.props;
    getRoleUsageService(roleId)
      .then(({ data }) => {
        this.setState({
          usedInUnits: data.organisationUnits,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          hasError: true,
          isLoading: false,
        });
      });
  }

  getRows = () => {
    const { usedInUnits } = this.state;
    if (usedInUnits.length === 0) return [[localeLookup('translations.None'), localeLookup('translations.None')]];
    return usedInUnits.map((item) => [item.name, item.personsWithRole]);
  };

  render() {
    const { isLoading, usedInUnits } = this.state;
    if (isLoading) return <Loader />;
    return (
      <div className="role-usage-info">
        <p>{localeLookup('translations.The role is used as follows')}</p>
        <br />
        <SimpleTable
          headerRow={[localeLookup('translations.Organisation unit'), localeLookup('translations.Persons with the role')]}
          columnWidths={['50%', '50%']}
          rows={this.getRows()}
        />
        <p>{localeLookup('translations.The role setup, goal setting in role matrix and the persons skill level will be deleted')}</p>
        <br />
        <p>{localeLookup('translations.Completion of elements will be preserved')}</p>
      </div>
    );
  }
}

RoleUsageInfo.propTypes = {};

export default RoleUsageInfo;
