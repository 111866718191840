import React from 'react';
import Button from '../../components/Button';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import localeLookup from '../../config/locale';

const ElementStatusOtherFiltersFilter = ({ filterState, isDefault, onClickReset, onToggleFilter }) => {
  const { activePersons, archivedPersons, currentlyConnected, notConnected } = filterState;
  const sections = [
    {
      title: localeLookup('translations.Persons'),
      items: [
        {
          name: localeLookup('translations.Active persons'),
          selected: activePersons,
          stateKey: 'activePersons',
        },
        {
          name: localeLookup('translations.Archived persons'),
          selected: archivedPersons,
          stateKey: 'archivedPersons',
        },
      ],
    },
    {
      title: localeLookup('translations.Connections'),
      items: [
        {
          name: localeLookup('translations.Currently connected'),
          selected: currentlyConnected,
          stateKey: 'currentlyConnected',
          tooltip: localeLookup('translations.ElementStatusCurrentlyConnectedTooltip'),
        },
        {
          name: localeLookup('translations.Additional with history'),
          selected: notConnected,
          stateKey: 'notConnected',
          tooltip: localeLookup('translations.ElementStatusAdditionalCompletedTooltip'),
        },
      ],
    },
  ];
  const renderItem = (item) => (
    <SideNavItem
      small
      element="div"
      key={`setting-${item.name}`}
      disabled={item.disabled}
      showHoverTitle
      title={item.name}
      tooltip={item.tooltip}
      onClick={() => onToggleFilter(item.stateKey)}
      stopRightComponentClickPropagation
      rightComponent={<SwitchCheckbox disabled={item.disabled} onChange={() => onToggleFilter(item.stateKey)} isChecked={item.selected} id={`setting-${item.stateKey}`} />}
    />
  );
  const renderSection = (section, index) => {
    if (section.groups) {
      return (
        <React.Fragment key={index}>
          <SideNavTitle size="small" title={section.title} />
          {section.groups.map((group, index) => (
            <React.Fragment key={index}>
              <SideNavTitle subtitle title={group.groupTitle} />
              {group.items.map(renderItem)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <SideNavTitle size="small" title={section.title} />
        {section.items.map(renderItem)}
      </React.Fragment>
    );
  };
  return (
    <>
      {sections.map(renderSection)}
      <Button kind="alert" disabled={isDefault} onClick={onClickReset} className="status-table__sidebar-reset-button">
        {localeLookup('translations.Reset')}
      </Button>
    </>
  );
};

export default ElementStatusOtherFiltersFilter;
