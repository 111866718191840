import React, { PureComponent } from 'react';
import Icon from './Icon';
import Loader from './Loader';
import cx from 'classnames';

export default class DropdownButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedItem: props.selectedItem || '',
      position: {
        top: 0,
        left: 0,
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
    document.addEventListener('scroll', this.closeDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
    document.removeEventListener('scroll', this.closeDropdown, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      this.setState({
        selectedItem: this.props.selectedItem,
      });
    }
  }

  closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  onClickItem = (itemId) => {
    const { onChange } = this.props;
    this.setState(
      {
        selectedItem: itemId,
        isOpen: false,
      },
      () => {
        onChange(itemId);
      }
    );
  };

  onClickOutside = (event) => {
    if (this.componentRef && !this.componentRef.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  };

  onClickToggleOpen = () => {
    const buttonPosition = this.buttonRef.getBoundingClientRect();
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      position: {
        top: buttonPosition.top + buttonPosition.height,
        left: buttonPosition.left,
      },
    }));
  };

  renderIcon = () => {
    const { isLoading, color, icon } = this.props;

    if (isLoading) {
      return (
        <Loader color={color ? 'white' : ''} size="small" numberOfDots={1} />
      );
    }
    if (icon) {
      return <Icon kind={icon} color={color ? 'white' : 'grey'} size="small" />;
    }
    return (
      <Icon color={color ? 'white' : 'grey'} kind="chevron-down" size="small" />
    );
  };

  render() {
    const { isOpen, selectedItem, position } = this.state;
    const { placeholder, items, isLoading, color, disabled } = this.props;
    const modifierClasses = {
      [`dropdown-button--color-${color}`]: color,
    };

    return (
      <div
        ref={(el) => (this.componentRef = el)}
        className={cx('dropdown-button', modifierClasses)}
      >
        <button
          ref={(el) => (this.buttonRef = el)}
          onClick={disabled ? null : this.onClickToggleOpen}
          className="dropdown-button__button"
        >
          {isLoading ? null : (
            <span className="dropdown-button__text">
              {selectedItem
                ? items.find((item) => item.id === selectedItem).label
                : placeholder}
            </span>
          )}
          <span className="dropdown-button__icon">{this.renderIcon()}</span>
        </button>
        {isOpen ? (
          <ul
            className="dropdown-button__list"
            style={{ top: position.top, left: position.left }}
          >
            <li className="dropdown-button__item">
              <button
                onClick={() => this.onClickItem('')}
                className="dropdown-button__item-button"
              >
                {placeholder}
              </button>
            </li>
            {items.map((item) => (
              <li key={item.id} className="dropdown-button__item">
                <button
                  onClick={() => this.onClickItem(item.id)}
                  className="dropdown-button__item-button"
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
}
