import React from 'react';
import PropTypes from 'prop-types';
import { MAIN_STATUS_CATEGORIES } from '../../constants';
import DashboardAreaBox from './DashboardAreaBox';
import DashboardKnowledgeElement from './DashboardKnowledgeElementV2';

const DashboardKnowledgeArea = ({
  allPersons,
  area,
  expandedAreaId,
  id,
  isAdditionalArea,
  knowledgeElements,
  mentorId,
  personArea,
  personId,
  personKnowledgeElements,
  roleId,
  wildcardPersons,
  onChangeElementStatus,
  onClickAdditionalArea,
  canSelectElements,
  onClickSelectElement,
  selectedElements,
  showResponsibles,
  onClickSubmitChangeSingleMediator,
}) => {
  const areaTotalElementCount = area.knowledgeElements.length;
  const isUnlinkedAdditionalAraa = isAdditionalArea && !personArea?.roles?.[roleId]?.isLinkedToPerson;
  const personAreaCompletedElementsCount = area.knowledgeElements.reduce((acc, id) => {
    const personElement = personKnowledgeElements[id];
    if (personElement && personElement.status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION)) {
      return acc + 1;
    }
    return acc;
  }, 0);
  const onClick = () => {
    onClickAdditionalArea({ roleId, personId, areaId: id, isLinked: personArea?.roles?.[roleId]?.isLinkedToPerson });
  };
  const getColor = () => {
    if (isUnlinkedAdditionalAraa) {
      return 'grey';
    }
    if (areaTotalElementCount === personAreaCompletedElementsCount) {
      return 'green';
    }
    return 'light-green';
  };
  return (
    <>
      <DashboardAreaBox
        transparent={expandedAreaId !== '' && expandedAreaId !== id}
        onClick={isAdditionalArea && onClick}
        color={getColor()}
        icon={isUnlinkedAdditionalAraa && 'unlink2'}
        text={`${personAreaCompletedElementsCount}/${areaTotalElementCount}`}
      />
      {expandedAreaId === id &&
        area.knowledgeElements.map((id, index, arr) => (
          <DashboardKnowledgeElement
            canSelect={canSelectElements}
            onClick={onClickSelectElement}
            allPersons={allPersons}
            areaExpertsIds={area.experts}
            areaKnowledgeOwnerId={area.knowledgeOwner}
            areaId={id}
            element={knowledgeElements[id]}
            key={id}
            mentorId={mentorId}
            personElement={personKnowledgeElements[id]}
            personId={personId}
            wildcardPersons={wildcardPersons}
            onChangeElementStatus={onChangeElementStatus}
            selectedElements={selectedElements}
            showResponsibles={showResponsibles}
            onClickSubmitChangeSingleMediator={onClickSubmitChangeSingleMediator}
            isLast={arr.length === index + 1}
          />
        ))}
    </>
  );
};

DashboardKnowledgeArea.propTypes = {
  area: PropTypes.shape({
    knowledgeElements: PropTypes.arrayOf(PropTypes.string).isRequired,
    experts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    knowledgeOwner: PropTypes.string.isRequired,
  }).isRequired,
  expandedAreaId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isAdditionalArea: PropTypes.string.isRequired,
  mentorId: PropTypes.string.isRequired,
  personArea: PropTypes.shape({
    roles: PropTypes.objectOf(PropTypes.shape({ isLinkedToPerson: PropTypes.bool.isRequired })).isRequired,
  }).isRequired,
  personKnowledgeElements: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  roleId: PropTypes.string.isRequired,
  onChangeElementStatus: PropTypes.func.isRequired,
  onClickSelectElement: PropTypes.func.isRequired,
  canSelectElements: PropTypes.bool.isRequired,
};

export default DashboardKnowledgeArea;
