import React from 'react';
import ButtonIcon from '../ButtonIcon';
import cx from 'classnames';

const SideNavTitle = ({ title, icon, iconColor, onIconClick, size, subtitle, className }) => {
  const modifierClasses = {
    [`side-nav-title--size-${size}`]: size,
    'side-nav-title--subtitle': subtitle,
  };
  return (
    <div className={cx('side-nav-title', modifierClasses, className)}>
      <h2 className="side-nav-title__text">{title}</h2>
      {onIconClick && <ButtonIcon noMargin inline iconColor={iconColor} onClick={onIconClick} icon={icon} />}
    </div>
  );
};

export default SideNavTitle;
