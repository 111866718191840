import React from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS } from '../../constants';
import Button from '../Button';
import withAccessControl from '../HOC/withAccessControl';
import Icon from '../Icon';
import PersonCard from '../PersonCard';
import Tooltip from '../Tooltip';

const mapStateToProps = (state) => ({ currentUserId: state.user.employeeId });

const KnowledgeOwner = ({ knowledgeOwner, onRemoveClick, onEditClick, hasAccess, currentUserId }) => {
  const hasAccessRoles = hasAccess([ACCESS_LEVELS.champadministrator, ACCESS_LEVELS.administrator, ACCESS_LEVELS.contentAdministrator]);
  const isOwner = knowledgeOwner.id === currentUserId;
  const canEditOwner = hasAccessRoles || isOwner;
  return (
    <section className="editor__section">
      <div className="editor__section-header">
        <Tooltip tooltip={localeLookup('translations.KnowledgeOwnerInfo')}>
          <h2 className="editor__section-title">
            {localeLookup('translations.Owner')}
            <Icon className="editor__section-title-icon" kind="info-circle" size="x-small" color="dark-grey"></Icon>
          </h2>
        </Tooltip>
      </div>
      <div className="editor__section-body">
        {knowledgeOwner && <PersonCard name={knowledgeOwner.name} subtitle={localeLookup('translations.Owner')} onRemove={canEditOwner ? onRemoveClick : undefined} />}
        {canEditOwner && (
          <Button className="editor__section-button" onClick={onEditClick} kind="link-style">
            {knowledgeOwner ? localeLookup('translations.Edit') : localeLookup('translations.Add')}
            ...
          </Button>
        )}
      </div>
    </section>
  );
};

export default connect(mapStateToProps)(withAccessControl(KnowledgeOwner));
