import React from 'react';
import localeLookup from '../config/locale';
import Tooltip from './Tooltip';

const TruncatedArrayText = ({
  items,
  limit = 2,
  countSuffixText,
  tooltip,
  showTooltip = true,
  render,
}) => {
  if (items.length >= limit) {
    return (
      <Tooltip
        tooltip={
          showTooltip
            ? tooltip ||
              items.map((name, i) => (
                <span key={i}>
                  {name} <br></br>
                </span>
              ))
            : null
        }
      >
        <span>
          {items.length} {countSuffixText}
        </span>
      </Tooltip>
    );
  }
  if (render) {
    return items.map((item, i) => (
      <span key={i}>
        {item}
        {i !== items.length - 1 ? ', ' : ''}
      </span>
    ));
  }
  return <span>{items.join(', ')}</span>;
};

export default TruncatedArrayText;
