/* eslint-disable nonblock-statement-body-position */
import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import CreateUserInformationModal from './CreateUserInformationModal';
import UserLoginInformationModal from './UserLoginInformationModal';
import UserTypeModal from './UserTypeModal';

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  hasExternalUserDirectoryIntegration: state.rootmenu.hasExternalUserDirectoryIntegration,
  organisationAllowsChampLogin: state.rootmenu.organisationAllowsChampLogin,
});

const defaultState = {
  activeStep: 'type',
  userType: '',
  email: '',
  name: '',
  conflicts: [],
  newUserType: '',
};

class ManageLoginModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...defaultState, email: this.props.email };
  }

  getSelectedUserType = () => {
    const {
      currentUserType,
      hasExternalUserDirectoryIntegration,
      organisationAllowsChampLogin,
    } = this.props;
    if (currentUserType === 'NoAccess') return 'userWithoutLogin';
    if (currentUserType === 'External') {
      if (!hasExternalUserDirectoryIntegration) {
        return '';
      }
      return 'userWithExternalLogin';
    }
    if (currentUserType === 'Champ') {
      if (!organisationAllowsChampLogin) {
        return '';
      }
      return 'userWithLogin';
    }
    return '';
  };

  setStep = (step) => {
    const { email, userType } = this.state;
    if (step === 'type') {
      this.setState({
        ...defaultState,
        activeStep: step,
        email,
        userType,
      });
    } else {
      this.setState({
        activeStep: step,
      });
    }
  };

  onSubmitUserInformation = ({ email, password, password2, newUserType }) => {
    const { onSubmit } = this.props;
    onSubmit({ email, password, password2, newUserType });
  };

  onSubmitUserType = ({ email, userType }) => {
    const { onSubmit } = this.props;
    const newUserType = () => {
      if (userType === 'userWithoutLogin') return 'NoAccess';
      if (userType === 'userWithExternalLogin') return 'External';
      return 'Champ';
    };
    if (userType === 'userWithoutLogin' || userType === 'userWithExternalLogin') {
      onSubmit({ newUserType: newUserType(), email });
    } else {
      this.setState({
        activeStep: 'informations',
        userType,
        email,
        newUserType: newUserType(),
      });
    }
  };

  render() {
    const { onClose, title, currentUserType } = this.props;
    const { activeStep, email, userType, name, newUserType } = this.state;

    const steps = [localeLookup('translations.User type'), localeLookup('translations.Information')];

    if (activeStep === 'type') {
      return (
        <UserTypeModal
          activeStepIndex={0}
          confirmButtonText={localeLookup('translations.Next')}
          initialValues={{
            email,
            userType: this.getSelectedUserType(),
          }}
          isEditingLogin
          onClose={onClose}
          onCancelClick={onClose}
          onSubmit={this.onSubmitUserType}
          steps={steps}
          title={title}
        />
      );
    }
    if (activeStep === 'informations') {
      return (
        <UserLoginInformationModal
          activeStepIndex={1}
          cancelButtonText={localeLookup('translations.Back')}
          confirmButtonText={localeLookup('translations.Save')}
          currentUserType={currentUserType}
          initialValues={{
            email: email || '',
            name: name || '',
          }}
          newUserType={newUserType}
          onSubmit={this.onSubmitUserInformation}
          onCancelClick={() => {
            this.setStep('type');
          }}
          onClose={onClose}
          steps={steps}
          title={title}
        />
      );
    }
    return null;
  }
}

export default connect(mapStateToProps)(ManageLoginModal);
