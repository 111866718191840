import cx from 'classnames';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import React, { PureComponent } from 'react';

const motion = {
  div: createDomMotionComponent('div'),
};

class Modal extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
  }

  onKeyPress = (e) => {
    const { onCloseClick, closeOnEsc } = this.props;
    if (e.key === 'Escape' && closeOnEsc) {
      onCloseClick();
    }
  };

  render() {
    const {
      render,
      isOpen,
      onCloseClick,
      minWidth,
      maxWidth,
      fullWidth,
      closeOnOverlayClick,
      stopPropagation = true,
    } = this.props;
    const modifierClasses = {
      'modal--full-width': fullWidth,
    };
    return (
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              // Needed when opening modal from statusbutton - Else it will open element drawer
              onClick={stopPropagation ? (e) => e.stopPropagation() : null}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 50, opacity: 0 },
              }}
              transition={{ duration: 0.15 }}
              key="modal"
              className={cx('modal', modifierClasses)}
              onMouseDown={closeOnOverlayClick ? onCloseClick : null}
            >
              <div
                onMouseDown={
                  stopPropagation ? (e) => e.stopPropagation() : null
                }
                className="modal__box"
                style={{ minWidth: minWidth, maxWidth: maxWidth }}
              >
                {render({ onCloseClick })}
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              transition={{ duration: 0.15 }}
              key="shade"
              className="modal-shade"
            />
          </>
        )}
      </AnimatePresence>
    );
  }
}

Modal.defaultProps = {
  closeOnEsc: true,
  closeOnOverlayClick: true,
};

export default Modal;
