import { motion } from 'framer-motion';
import React from 'react';

const variants = {
  hidden: {
    height: 0,
  },
  visible: {
    height: 'auto',
  },
};

const ErrorMessage = ({ show = false, children }) => (
  <motion.div variants={variants} animate={show ? 'visible' : 'hidden'} className="error-message">
    <p className="error-message__text">{children}</p>
  </motion.div>
);

export default ErrorMessage;
