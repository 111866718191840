import React from 'react';
import cx from 'classnames';
const DragAndDropHandle = ({ className, dragHandleProps = {} }) => (
  <div className={cx('drag-and-drop-handle', className)} {...dragHandleProps}>
    <span className="drag-and-drop-handle__line" />
    <span className="drag-and-drop-handle__line" />
    <span className="drag-and-drop-handle__line" />
  </div>
);

export default DragAndDropHandle;
