import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import localeLookup from '../config/locale';
import { connect } from 'react-redux';
import { ACCESS_LEVELS, PERSON_STATES } from '../constants';
import {
  createAreaService,
  createRoleService,
} from '../services/contentService';
import BoxMessage from './BoxMessage';
import AutocompleteSelect from './formElements/AutocompleteSelect';
import Field from './formElements/Field';
import FormWrapper from './formElements/FormWrapper';
import TextField from './formElements/TextField';
import withAccessControl from './HOC/withAccessControl';
import ModalBody from './modal/ModalBody';
import MultiSelect from './MultiSelect';
import SwitchCheckbox from './SwitchCheckbox';
import { compareLocal, sortBy } from '../utils/helpers';

const mapStateToProps = (state) => ({
  currentUserId: state.user.employeeId,
  persons: state.persons,
  spaces: state.spaces.spaces,
  spacesEnabled: state.spaces.enabled,
  activeSpaceId: state.spaces.activeSpaceId,
});

const mapDispatchToProps = {};

class CreateAreaForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  canEditAfterCreation = (values) => {
    const { currentUserId, hasAccess } = this.props;
    const hasAccessRoles = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ]);

    if (hasAccessRoles) return true;
    if (values.editors.includes(currentUserId)) return true;
    if (values.owner === currentUserId) return true;
    return false;
  };

  onSubmit = (values) => {
    const { onCreated } = this.props;
    const { name, editors, owner, spaceId } = values;
    this.setState({ isSubmitting: true });
    createAreaService(name, owner, editors, [spaceId]).then(({ data }) => {
      onCreated(data);
    });
  };

  render() {
    const { isSubmitting } = this.state;
    const {
      persons,
      activeSpaceId,
      renderFooter,
      initialName,
      hasAccess,
      currentUserId,
      spaces,
      allowSpaceSelection,
      spacesEnabled,
      hasCreateAreaPermission,
    } = this.props;

    const spaceOptions = sortBy(
      Object.keys(spaces).reduce((acc, id) => {
        const space = spaces[id];
        const canCreateAreasInSpace = hasCreateAreaPermission({
          spaceId: space.id,
        });
        if (canCreateAreasInSpace) {
          return [...acc, { label: spaces[id].name, value: id }];
        }

        return acc;
      }, []),
      [(a, b) => compareLocal(a.label, b.label)]
    );

    const ownerOptions = [
      {
        value: '',
        label: localeLookup('translations.No owner'),
        default: true,
        searchString: localeLookup('translations.No owner'),
      },
      ...sortBy(
        Object.keys(persons)
          .filter((id) => persons[id].state === PERSON_STATES.ACTIVE)
          .map((id) => {
            const person = persons[id];
            return {
              label: persons[id].name,
              value: id,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            };
          }),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
    ];
    const editorOptions = [
      ...sortBy(
        Object.keys(persons)
          .filter((id) => persons[id].state === PERSON_STATES.ACTIVE)
          .map((id) => {
            const person = persons[id];
            return {
              label: persons[id].name,
              value: id,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            };
          }),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
    ];
    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
      spaceId: Yup.string().required(
        localeLookup('translations.Content space is required')
      ),
    });
    const defaultOwnerId = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ])
      ? ''
      : currentUserId;

    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }
      return true;
    };

    return (
      <FormWrapper
        onSubmit={this.onSubmit}
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          name: initialName,
          owner: defaultOwnerId,
          editors: [],
          spaceId: activeSpaceId,
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <>
            {!this.canEditAfterCreation(values) && (
              <BoxMessage type="warning" spacing="md" icon="warning">
                {localeLookup(
                  'translations.You have to be owner or editor to access this module after creation'
                )}
              </BoxMessage>
            )}
            <ModalBody>
              <form
                className="create-area-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  required
                  autoFocus
                  placeholder={localeLookup('translations.Name')}
                  name="name"
                  id="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.name && errors.name}
                  value={values.name}
                  label={localeLookup('translations.Name')}
                />
                <Field label={localeLookup('translations.Owner')}>
                  <AutocompleteSelect
                    defaultValue={values.owner}
                    name="owner"
                    filterOption={filterOption}
                    onChange={(selectedOption) => {
                      setFieldValue('owner', selectedOption.value);
                    }}
                    options={ownerOptions}
                  ></AutocompleteSelect>
                </Field>
                <Field label={localeLookup('translations.Editors')}>
                  <MultiSelect
                    onChange={(values) => {
                      setFieldValue(
                        'editors',
                        values.map((value) => value.value)
                      );
                    }}
                    name="editors"
                    filterOption={filterOption}
                    options={editorOptions}
                    placeholder={localeLookup('translations.Select editors')}
                  />
                </Field>
                {spacesEnabled && (
                  <Field
                    required
                    label={`${localeLookup(
                      'translations.Content space'
                    )} (${localeLookup('translations.Editable')})`}
                  >
                    <AutocompleteSelect
                      defaultValue={values.spaceId}
                      name="spaceId"
                      placeholder={localeLookup(
                        'translations.Select content space'
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('spaceId', selectedOption.value);
                      }}
                      options={spaceOptions}
                      disabled={!allowSpaceSelection}
                    ></AutocompleteSelect>
                  </Field>
                )}
              </form>
            </ModalBody>
            {renderFooter &&
              renderFooter({
                handleSubmit,
                canSubmit: isValid && !isSubmitting,
              })}
          </>
        )}
      </FormWrapper>
    );
  }
}

export default connect(mapStateToProps)(withAccessControl(CreateAreaForm));
