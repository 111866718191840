import React, { Component } from 'react';
import Icon from './Icon';
import Text from './Text';
import cx from 'classnames';

export default class SessionStatusButton extends Component {
  render() {
    const { context, totalNumber, completedNumber, icon, onClick } = this.props;
    const getColors = () => {
      const isAllCompleted = completedNumber === totalNumber;
      const isEmpty = totalNumber === 0;
      const isSomeCompleted =
        completedNumber > 0 && completedNumber < totalNumber;
      if (isEmpty)
        return {
          textColor: 'dark-grey',
          backgroundColor: 'white',
          iconColor: 'dark-grey',
        };
      if (isAllCompleted)
        return {
          textColor: 'white',
          backgroundColor: 'green',
          iconColor: 'white',
        };
      if (isSomeCompleted)
        return {
          textColor: 'green',
          backgroundColor: 'light-green',
          iconColor: 'green',
        };
      return {
        textColor: 'dark-grey',
        backgroundColor: 'white',
        iconColor: 'dark-grey',
      };
    };
    const colors = getColors();
    return (
      <button
        onClick={onClick}
        className={cx(
          'session-status-button',
          `session-status-button--color-${colors.backgroundColor}`
        )}
      >
        <Text color={colors.textColor} className="session-status-button__text">
          {completedNumber}/{totalNumber}
        </Text>
        <Icon
          color={colors.iconColor}
          className="session-status-button__icon"
          kind={icon}
        ></Icon>
      </button>
    );
  }
}
