import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import localeLookup from '../../config/locale';
import {
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  PERSON_STATES,
} from '../../constants';
import withPersonLookup from '../HOC/withPersonLookup';
import Loader from '../Loader';
import Text from '../Text';
import Tooltip from '../Tooltip';
import RoleDashboardPerson from './RoleDashboardPerson';
import { formatDate } from '../../utils/helpers';

const RoleDashboardContent = ({
  additionalKnowledgeAreasSortOrder,
  allPersons,
  expandedAreaId,
  knowledgeAreas,
  knowledgeAreasSortOrder,
  knowledgeElements,
  onClickAdditionalArea,
  persons,
  roleId,
  visiblePersonIds,
  wildcardPersons,
  onChangeElementStatus,
  canSelectElements,
  onClickSelectElement,
  selectedElements,
  showResponsibles,
  onClickSubmitChangeSingleMediator,
  lookupPerson,
  personView,
}) => {
  const personsRowRef = useRef(null);

  const renderPerson = ({ columnIndex, key, style, isScrolling }) => {
    const id = visiblePersonIds[columnIndex];
    const person = persons[id];
    const content = isScrolling ? (
      <Loader padding="md" />
    ) : (
      <RoleDashboardPerson
        allPersons={allPersons}
        additionalKnowledgeAreasSortOrder={additionalKnowledgeAreasSortOrder}
        expandedAreaId={expandedAreaId}
        id={id}
        initials={person.initials}
        knowledgeAreas={knowledgeAreas}
        knowledgeAreasSortOrder={knowledgeAreasSortOrder}
        knowledgeElements={knowledgeElements}
        mentorId={person.mentor}
        name={person.name}
        onClickAdditionalArea={onClickAdditionalArea}
        personKnowledgeAreas={person.knowledgeAreas}
        personKnowledgeElements={person.knowledgeElements}
        roleId={roleId}
        wildcardPersons={wildcardPersons}
        onChangeElementStatus={onChangeElementStatus}
        canSelectElements={canSelectElements}
        onClickSelectElement={onClickSelectElement}
        selectedElements={selectedElements}
        showResponsibles={showResponsibles}
        onClickSubmitChangeSingleMediator={onClickSubmitChangeSingleMediator}
      />
    );
    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };
  const renderMentorName = (id) => {
    const mentor = lookupPerson(id);
    if (showResponsibles) {
      return mentor.name;
    }
    if (personView === 'employeeNumber' && mentor.employeeNumber) {
      return mentor.employeeNumber;
    }
    return mentor.initials;
  };
  const renderPersonName = (id) => {
    const person = persons[id];
    if (showResponsibles) {
      return person.name;
    }
    if (personView === 'employeeNumber' && person.employeeNumber) {
      return person.employeeNumber;
    }
    return person.initials;
  };
  const renderPersonsRow = () => {
    return (
      <div ref={personsRowRef} className="dashboard-content__persons-row">
        {visiblePersonIds.map((id) => {
          const person = persons[id];
          const mentor = lookupPerson(person.mentor);
          const isMentorArchived = mentor?.state === PERSON_STATES.ARCHIVED;
          const isMentorFormerEmployee =
            person.mentor === DELETED_PERSON_ID ||
            person.mentor === DELETED_GROUP_ID;
          return (
            <Tooltip
              key={id}
              tooltip={
                <span>
                  {person.name} ({person.initials}
                  {person.employeeNumber ? ` · ${person.employeeNumber}` : ''})
                  {mentor ? (
                    <span>
                      <br />
                      {localeLookup('translations.Mentor')}: {mentor.name} (
                      {mentor.initials}
                      {mentor.employeeNumber
                        ? ` · ${mentor.employeeNumber}`
                        : ''}
                      )
                      {isMentorArchived
                        ? ` (${localeLookup('translations.Archived')})`
                        : ''}
                      <br />
                      {localeLookup('translations.Start date')}:{' '}
                      {formatDate(person.startDate)}
                      {person.endDate ? (
                        <>
                          <br />
                          {localeLookup('translations.End date')}:{' '}
                          {formatDate(person.endDate)}
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  ) : null}
                </span>
              }
            >
              <div key={id} className="dashboard-content__name-box">
                <Text truncate color="blue">
                  {renderPersonName(id)}
                </Text>
                {mentor && (
                  <Text
                    truncate
                    color={
                      isMentorArchived || isMentorFormerEmployee
                        ? 'red'
                        : 'dark-grey'
                    }
                    size="xs"
                  >
                    {renderMentorName(person.mentor)}
                  </Text>
                )}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };
  return (
    <div className="dashboard-content">
      {renderPersonsRow()}
      <AutoSizer nonce={cspNonce}>
        {({ width, height }) => (
          <Grid
            cellRenderer={renderPerson}
            columnWidth={showResponsibles ? 200 : 80}
            rowHeight={height + 50}
            columnCount={visiblePersonIds.length}
            height={height + 50}
            width={width}
            rowCount={1}
            autoHeight
            onScroll={({ scrollLeft }) => {
              personsRowRef.current.scrollTo(scrollLeft, 0);
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

RoleDashboardContent.propTypes = {
  canSelectElements: PropTypes.bool.isRequired,
  additionalKnowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  allPersons: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
    })
  ).isRequired,
  expandedAreaId: PropTypes.string.isRequired,
  knowledgeAreas: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  knowledgeElements: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClickAdditionalArea: PropTypes.func.isRequired,
  persons: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      mentor: PropTypes.string.isRequired,
      knowledgeAreas: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.string,
          roles: PropTypes.objectOf(
            PropTypes.shape({
              id: PropTypes.string,
              isLinkedToPerson: PropTypes.bool,
              status: PropTypes.string,
            })
          ),
        })
      ).isRequired,
      knowledgeElements: PropTypes.objectOf(
        PropTypes.shape({
          status: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  knowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  roleId: PropTypes.string.isRequired,
  visiblePersonIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  wildcardPersons: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeElementStatus: PropTypes.func.isRequired,
  onClickSelectElement: PropTypes.func.isRequired,
};

export default withPersonLookup(RoleDashboardContent);
