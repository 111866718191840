/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import localeLookup from '../../config/locale';
import {
  ORGANISATION_UNIT_STATES,
  ROLE_MATRIX_COMPLETION_STATES,
} from '../../constants';
import { updateEmployeeViewService } from '../../services/organisationService';
import { compareLocal, sortBy } from '../../utils/helpers';
import Accordion from '../Accordion';
import ButtonIcon from '../ButtonIcon';
import GanttChart from '../GanttChart';
import withPersonLookup from '../HOC/withPersonLookup';
import Icon from '../Icon';
import RadioButton from '../RadioButton';
import ContextMenu from '../contextMenu/ContextMenu';
import ContextMenuItem from '../contextMenu/ContextMenuItem';
import OrganisationBoardPerson from './OrganisationBoardPerson';
import OrganisationBoardRole from './OrganisationBoardRole';
import StatusBoxKnowledgeOrganisation from './StatusBoxKnowledgeOrganisation';
import StatusBoxRoleOrganisation from './StatusBoxRoleOrganisation';
import WithOrganisationUnitActions from '../HOC/withOrganisationUnitActions';
import { compose } from 'redux';

class OrganisationBoardOrganisationUnit extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      expandedRoles: [],
      personWidths: {},
      view:
        props.organisationUnit?.view === 'Initials'
          ? 'initials'
          : 'employeeNumber',
    };
  }

  componentDidMount() {
    this.bindResizeObservers();
    this.gridRef?.addEventListener('wheel', this.onGridScroll, {
      passive: false,
    });
  }

  bindResizeObservers = () => {
    if (this.ref.current) {
      new ResizeObserver(this.rebindResizeObservers).observe(document.body);
      this.columnSizeObserver = new ResizeObserver((entries) => {
        const personWidths = entries.reduce((acc, entry) => {
          const personId = entry.target.getAttribute('data-person-id');
          return {
            ...acc,
            [personId]: entry.contentRect.width,
          };
        }, {});
        this.setState(() => ({ personWidths }));
      });

      const columnEls = this.ref.current.querySelectorAll(
        '.organisation-board-unit__grid-column'
      );
      columnEls.forEach((columnEl) => {
        this.columnSizeObserver.observe(columnEl);
      });
    }
  };

  rebindResizeObservers = () => {
    this.columnSizeObserver.disconnect();
    if (this.ref.current) {
      const columnEls = this.ref.current.querySelectorAll(
        '.organisation-board-unit__grid-column'
      );
      columnEls.forEach((columnEl) => {
        this.columnSizeObserver.observe(columnEl);
      });
    }
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.columnSizeObserver.disconnect();
  }

  componentDidUpdate(prevProps, prevState) {
    const { organisationUnit, filteredPersons, completionState } = this.props;
    if (
      organisationUnit.persons.length !==
        prevProps.organisationUnit.persons.length ||
      filteredPersons.length !== prevProps.filteredPersons.length ||
      completionState !== prevProps.completionState
    ) {
      this.rebindResizeObservers();
    }
  }

  getEmptyText = () => {
    const { organisationUnit } = this.props;
    const hasNoRoles = organisationUnit.roles.length === 0;
    const hasNoPersons = organisationUnit.persons.length === 0;
    if (hasNoPersons && hasNoRoles) {
      return localeLookup('translations.Contains no roles or persons');
    }
    return localeLookup('translations.Contains no persons');
  };

  getSortedPersons = () => {
    const { view } = this.state;
    const { organisationUnit, persons } = this.props;

    const getIdentifier = (person) => {
      const identifier =
        view === 'initials'
          ? person.initials
          : person.employeeNumber
          ? person.employeeNumber
          : person.initials;
      return identifier;
    };

    return sortBy(organisationUnit.persons, [
      (a, b) => {
        const personA = persons[a];
        const personB = persons[b];
        const personAIdentifier = personA ? getIdentifier(personA) : '';
        const personBIdentifier = personB ? getIdentifier(personB) : '';
        return compareLocal(personAIdentifier, personBIdentifier);
      },
    ]);
  };

  getSortedAndFilteredPersons = () => {
    const { view } = this.state;
    const { organisationUnit, persons, filteredPersons } = this.props;

    const getIdentifier = (person) => {
      const identifier =
        view === 'initials'
          ? person.initials
          : person.employeeNumber
          ? person.employeeNumber
          : person.initials;
      return identifier;
    };

    return sortBy(organisationUnit.persons, [
      (a, b) => {
        const personA = persons[a];
        const personB = persons[b];
        const personAIdentifier = personA ? getIdentifier(personA) : '';
        const personBIdentifier = personB ? getIdentifier(personB) : '';
        return compareLocal(personAIdentifier, personBIdentifier);
      },
    ]).filter((personId) => filteredPersons.includes(personId));
  };

  onChangeView = (e) => {
    const { organisationUnit } = this.props;
    updateEmployeeViewService(organisationUnit.id, e.target.value);
    this.setState({ view: e.target.value }, () => {
      this.rebindResizeObservers();
    });
  };

  onClickAddRole = () => {
    const { onClickAddRole, organisationUnit } = this.props;
    onClickAddRole(organisationUnit.id);
  };

  onClickAssignRoles = () => {
    const { organisationUnitActions, organisationUnit, getOpenUnitsData } =
      this.props;
    organisationUnitActions.showAssignRolesModal({
      unitId: organisationUnit.id,
      unitName: organisationUnit.organisationName,
      onConfirm: getOpenUnitsData,
    });
  };

  onClickChangeName = () => {
    const { organisationUnitActions, organisationUnit } = this.props;
    organisationUnitActions.showChangeOrganisationUnitNameModal({
      unitId: organisationUnit.id,
      name: organisationUnit.organisationName,
    });
  };

  onClickChangeOrganisationUnitPersonAdministrators = () => {
    const {
      organisationUnit,
      onChangeOrganisationUnitPersonAdministratorsClick,
    } = this.props;
    onChangeOrganisationUnitPersonAdministratorsClick({
      unitId: organisationUnit.id,
    });
  };

  onClickChangeOrganisationUnitPersons = () => {
    const { onChangeOrganisationUnitPersonsClick, organisationUnit } =
      this.props;
    onChangeOrganisationUnitPersonsClick({
      unitId: organisationUnit.id,
    });
  };

  onClickChangeState = () => {
    const { organisationUnitActions, organisationUnit } = this.props;
    organisationUnitActions.showChangeOrganisationUnitStateModal({
      unitId: organisationUnit.id,
      unitName: organisationUnit.organisationName,
      currentState: organisationUnit.state,
    });
  };

  onClickCloseOrganisationUnit = () => {
    const { onCloseOrganisationUnit, organisationUnit } = this.props;
    onCloseOrganisationUnit(organisationUnit.id);
  };

  onClickDelete = () => {
    const {
      organisationUnitActions,
      organisationUnit,
      roles,
      onCloseOrganisationUnit,
      organisationUnits,
      hideOrganisationUnit,
    } = this.props;
    organisationUnitActions.showDeleteOrganisationUnitModal({
      unit: organisationUnit,
      unitName: organisationUnit.organisationName,
      organisationUnits,
      roles,
      onDeleted: () => {
        hideOrganisationUnit(organisationUnit.id);
      },
    });
  };

  onClickDuplicate = () => {
    const { organisationUnitActions, organisationUnit } = this.props;
    organisationUnitActions.showDuplicateOrganisationUnitModal({
      unitId: organisationUnit.id,
      unitName: organisationUnit.organisationName,
    });
  };

  onClickOpenInElementStatus = () => {
    const { history, organisationUnit } = this.props;
    history.push({
      pathname: `/organisation/elementstatus`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
        filterSettings: {
          organisationUnits: [organisationUnit.id],
        },
      },
    });
  };

  onClickRemoveRoleConnection = ({ roleId }) => {
    const { onClickRemoveRoleConnection, organisationUnit } = this.props;
    onClickRemoveRoleConnection({ unitId: organisationUnit.id, roleId });
  };

  onClickSortRoles = () => {
    const { organisationUnit, onClickSortRoles } = this.props;
    onClickSortRoles(organisationUnit.id);
  };

  onExpandRoleClick = ({ id }) => {
    const { expandedRoles } = this.state;
    if (expandedRoles.includes(id)) {
      this.setState({
        expandedRoles: expandedRoles.filter((expandedId) => expandedId !== id),
      });
    } else {
      this.setState({
        expandedRoles: [...expandedRoles, id],
      });
    }
  };

  onGridScroll = (e) => {
    if (this.gridRef.scrollWidth > this.gridRef.offsetWidth) {
      e.preventDefault();
      this.gridRef.scrollLeft += e.deltaY;
    }
  };

  onToggleContextMenu = (data) => {
    const { organisationUnit, toggleContextMenu } = this.props;
    toggleContextMenu({ ...data, unitId: organisationUnit.id });
  };

  onUpdateGoals = () => {
    const { organisationUnit, onUpdateGoals } = this.props;
    onUpdateGoals(organisationUnit.id);
  };

  renderColumn = (personId) => {
    const { view } = this.state;
    const {
      organisationUnit,
      persons,
      roles,
      onMouseEnterStatusBox,
      onMouseLeaveStatusBox,
    } = this.props;

    const person = persons[personId];
    return (
      <div
        className="organisation-board-unit__grid-column"
        data-person-id={personId}
        key={personId}
      >
        <OrganisationBoardPerson
          key={personId}
          name={person?.name}
          personId={personId}
          initials={person?.initials}
          employeeNumber={person?.employeeNumber}
          view={view}
          onMouseEnter={onMouseEnterStatusBox}
          onMouseLeave={onMouseLeaveStatusBox}
        />
        {organisationUnit.roles.map((roleId) => {
          if (roles[roleId]) {
            return (
              <React.Fragment key={roleId}>
                {this.renderRolePersonStatus({ roleId, personId })}
                {this.renderPersonRoleAreas({ roleId, personId })}
              </React.Fragment>
            );
          }
          return null;
        })}
      </div>
    );
  };

  renderPerson = (personId) => {
    const { view, personWidths } = this.state;
    const {
      persons,
      onMouseEnterStatusBox,
      onMouseLeaveStatusBox,
      isReadOnly,
    } = this.props;

    const person = persons[personId];
    const width = personWidths[personId];
    return (
      <OrganisationBoardPerson
        key={personId}
        name={person?.name}
        personId={personId}
        initials={person?.initials}
        employeeNumber={person?.employeeNumber}
        view={view}
        onMouseEnter={onMouseEnterStatusBox}
        onMouseLeave={onMouseLeaveStatusBox}
        width={width}
        toggleContextMenu={(props) => {
          if (isReadOnly) return;
          this.onToggleContextMenu({ ...props, context: 'person' });
        }}
      />
    );
  };

  renderPersonAreaStatus = ({ roleId, areaId, personId }) => {
    const { personWidths } = this.state;
    const {
      isReadOnly,
      persons,
      organisationUnit,
      onMouseEnterStatusBox,
      onMouseLeaveStatusBox,
      completionState,
      lookupPerson,
    } = this.props;

    const role = persons[personId]?.roles?.[roleId];
    const personAdditionalKnowledgeAreaObject =
      role?.additionalKnowledgeAreas[areaId];
    const isExperienced = personAdditionalKnowledgeAreaObject?.isExperienced;
    const isIrrelevant =
      personAdditionalKnowledgeAreaObject?.notRelevantInOrganisationUnits.includes(
        organisationUnit.id
      );
    const hasIncompleteCriticalKnowledge =
      personAdditionalKnowledgeAreaObject?.hasIncompleteCriticalKnowledge;
    const completionPercentage =
      personAdditionalKnowledgeAreaObject?.completionPercentage;
    const isConnected = !!personAdditionalKnowledgeAreaObject;
    const mentor = lookupPerson(role?.mentor);
    const showProgress = () => {
      if (completionState === ROLE_MATRIX_COMPLETION_STATES.ALL) {
        return true;
      }
      if (
        completionState === ROLE_MATRIX_COMPLETION_STATES.TRAINING &&
        !!mentor
      ) {
        return true;
      }
      return false;
    };

    return (
      <StatusBoxKnowledgeOrganisation
        areaId={areaId}
        hasIncompleteCriticalKnowledge={hasIncompleteCriticalKnowledge}
        isExperienced={isExperienced}
        isIrrelevant={isIrrelevant}
        isReadOnly={isReadOnly}
        onMouseEnter={onMouseEnterStatusBox}
        onMouseLeave={onMouseLeaveStatusBox}
        isConnected={isConnected}
        key={personId}
        completionPercentage={completionPercentage}
        knowledgeAreaId={areaId}
        personId={personId}
        roleId={roleId}
        toggleContextMenu={(props) =>
          this.onToggleContextMenu({ ...props, context: 'area' })
        }
        showProgress={showProgress()}
        completionState={completionState}
      />
    );
  };

  renderGrid = () => {
    const { view, personWidths } = this.state;
    const { organisationUnit } = this.props;
    const sortedFilteredPersons = this.getSortedAndFilteredPersons();
    return (
      <ScrollSync>
        <div className="organisation-board-unit__grid-wrapper">
          <ScrollSyncPane>
            <div className="organisation-board-unit__grid-persons">
              {sortedFilteredPersons.map((personId) =>
                this.renderPerson(personId)
              )}
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div
              className="organisation-board-unit__grid"
              ref={(el) => (this.gridRef = el)}
            >
              {sortedFilteredPersons.map((personId) =>
                this.renderColumn(personId)
              )}
            </div>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    );
  };

  renderPersonRoleAreas = ({ roleId, personId }) => {
    const { roles } = this.props;
    const { expandedRoles } = this.state;
    const isRoleExpanded = expandedRoles.includes(roleId);
    if (
      !roles[roleId] ||
      !roles[roleId].additionalKnowledgeAreas ||
      Object.keys(roles[roleId].additionalKnowledgeAreas).length === 0
    ) {
      return null;
    }
    return (
      <Accordion isOpen={isRoleExpanded}>
        <div>
          {roles[roleId].additionalKnowledgeAreas.map((areaId) => {
            return this.renderPersonAreaStatus({ roleId, areaId, personId });
          })}
        </div>
      </Accordion>
    );
  };

  renderRolePersonStatus = ({ personId, roleId }) => {
    const { view } = this.state;
    const {
      isReadOnly,
      organisationUnit,
      persons,
      roleLevelFilters,
      onMouseEnterStatusBox,
      onMouseLeaveStatusBox,
      completionState,
    } = this.props;
    const roleLevelFilter = roleLevelFilters[roleId];
    const activeFilterCount =
      roleLevelFilter &&
      Object.keys(roleLevelFilter).filter((level) => !roleLevelFilter[level])
        .length;
    const personRole = persons[personId]?.roles?.[roleId];
    return (
      <StatusBoxRoleOrganisation
        isReadOnly={isReadOnly}
        isIrrelevant={personRole?.notRelevantInOrganisationUnits.includes(
          organisationUnit.id
        )}
        unitId={organisationUnit.id}
        highlight={activeFilterCount > 0}
        key={personId}
        onMouseEnter={onMouseEnterStatusBox}
        onMouseLeave={onMouseLeaveStatusBox}
        personId={personId}
        role={personRole}
        roleId={roleId}
        toggleContextMenu={(props) =>
          this.onToggleContextMenu({ ...props, context: 'role' })
        }
        completionState={completionState}
        view={view}
      />
    );
  };

  renderSidebar = () => {
    const {
      filteredPersons,
      isReadOnly,
      knowledgeAreas,
      onDeleteRoleClick,
      onUpdateKnowledgeAreaName,
      onUpdateRoleName,
      organisationUnit,
      persons,
      roles,
      translatePixels,
      roleLevelFilters,
      onClickToggleRoleLevelFilter,
      onClickClearRoleLevelFilter,
      onClickShowRoleDashboardOverlay,
    } = this.props;
    const { expandedRoles } = this.state;
    const sortedPersons = this.getSortedPersons();
    const showEmptyText =
      organisationUnit.roles.length === 0 && sortedPersons.length !== 0;
    return (
      <div className="organisation-board-unit__sidebar">
        {(sortedPersons.length !== 0 ||
          (sortedPersons.length === 0 &&
            organisationUnit.roles.length !== 0)) && (
          <div className="organisation-board-header">
            <div className="organisation-board-header__placeholder">
              <span>{localeLookup('translations.Role')}</span>
            </div>
            <div className="organisation-board-header__goal">
              <span>{localeLookup('translations.GoalShort')}</span>
            </div>
          </div>
        )}
        {organisationUnit.roles.map((roleId) => {
          if (roles[roleId]) {
            return (
              <OrganisationBoardRole
                filteredPersons={filteredPersons}
                goals={organisationUnit.goals[roleId]}
                isExpanded={expandedRoles.includes(roleId)}
                isReadOnly={isReadOnly}
                key={roleId}
                organisationUnitState={organisationUnit.state}
                knowledgeAreas={knowledgeAreas}
                roleLevelFilters={roleLevelFilters}
                onClickClearRoleLevelFilter={onClickClearRoleLevelFilter}
                onClickRemoveRoleConnection={this.onClickRemoveRoleConnection}
                onClickToggleRoleLevelFilter={onClickToggleRoleLevelFilter}
                onDeleteRoleClick={onDeleteRoleClick}
                onExpand={this.onExpandRoleClick}
                onUpdateGoals={this.onUpdateGoals}
                onUpdateKnowledgeAreaName={onUpdateKnowledgeAreaName}
                onUpdateRoleName={onUpdateRoleName}
                organisationUnitPersons={organisationUnit.persons}
                persons={persons}
                role={roles[roleId]}
                translatePixels={translatePixels}
                unitId={organisationUnit.id}
                onClickShowRoleDashboardOverlay={
                  onClickShowRoleDashboardOverlay
                }
              />
            );
          }
          return null;
        })}
        {showEmptyText && (
          <p className="organisation-board-unit__sidebar-empty-text">
            {localeLookup('translations.Contains no roles')}
          </p>
        )}
        <div className="organisation-board-unit__add-role">
          <div
            className="organisation-board-unit__add-role-text-wrapper"
            onClick={this.onClickAddRole}
          >
            <Icon
              kind="plus-circle"
              className="organisation-board-unit__add-role-icon"
            />
            <p className="organisation-board-unit__add-role-text">
              {localeLookup('translations.Add role')}...
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderTitleHeader = () => {
    const { view } = this.state;
    const { isReadOnly, organisationUnit } = this.props;
    const {
      rolesHasNotCompletedGoals,
      additionalKnowledgeHasNotCompletedGoals,
    } = organisationUnit;

    const subtitleRolePart =
      rolesHasNotCompletedGoals > 0
        ? `${rolesHasNotCompletedGoals} ${
            rolesHasNotCompletedGoals > 1
              ? localeLookup('translations.roles')
              : localeLookup('translations.role')
          }`
        : '';

    const subtitleAreaPart =
      additionalKnowledgeHasNotCompletedGoals > 0
        ? `${additionalKnowledgeHasNotCompletedGoals} ${
            additionalKnowledgeHasNotCompletedGoals > 1
              ? localeLookup('translations.knowledge areas')
              : localeLookup('translations.knowledge area')
          }`
        : '';

    const subtitle = `${subtitleRolePart} ${
      subtitleRolePart.length > 0 && subtitleAreaPart.length > 0
        ? localeLookup('translations.and')
        : ''
    } ${subtitleAreaPart} ${localeLookup(
      'translations.with unachieved goals'
    )}`;

    const numberOfPersonAdmins = organisationUnit.personAdministrators.length;

    return (
      <div className="organisation-board-unit__header">
        <div>
          <div className="organisation-board-unit__header-title">
            <p className="organisation-board-unit__header-title-text">
              {organisationUnit.organisationName}{' '}
              {isReadOnly && (
                <span className="organisation-board-unit__header-read-only-text">
                  ({localeLookup('translations.Read only')})
                </span>
              )}
            </p>
            <ContextMenu triggerClass="organisation-board-unit__header-context-menu-trigger">
              {!isReadOnly && (
                <>
                  <ContextMenuItem
                    onClick={this.onClickChangeName}
                    leftIconKind="pencil"
                    titleText={localeLookup('translations.Name')}
                  />
                  <ContextMenuItem
                    onClick={this.onClickSortRoles}
                    leftIconKind="menu"
                    titleText={localeLookup('translations.Sort roles')}
                  />
                  <ContextMenuItem
                    onClick={
                      this.onClickChangeOrganisationUnitPersonAdministrators
                    }
                    leftIconKind="group-work"
                    subtitleText={`${numberOfPersonAdmins} ${
                      numberOfPersonAdmins === 1
                        ? localeLookup('translations.person')
                        : localeLookup('translations.persons')
                    }`}
                    titleText={localeLookup(
                      'translations.Organisation administrators'
                    )}
                  />
                </>
              )}
              <li className="context-menu-item context-menu-item--form">
                <Icon kind="profile" className="context-menu-item__icon-left" />
                <div className="context-menu-item__text">
                  <p className="context-menu-item__text-title">
                    {localeLookup('translations.Employee view')}
                  </p>
                  <form>
                    <div className="context-menu-item__form-field">
                      <RadioButton
                        size="small"
                        name={`view${organisationUnit.id}`}
                        isChecked={view === 'initials'}
                        value="initials"
                        id={`${organisationUnit.id}initials`}
                        onChange={this.onChangeView}
                      />
                      <label
                        htmlFor={`${organisationUnit.id}initials`}
                        className="context-menu-item__form-label"
                      >
                        {localeLookup('translations.Initials')}
                      </label>
                    </div>
                    <div className="context-menu-item__form-field">
                      <RadioButton
                        size="small"
                        name={`view${organisationUnit.id}`}
                        isChecked={view === 'employeeNumber'}
                        value="employeeNumber"
                        id={`${organisationUnit.id}employeeNumber`}
                        onChange={this.onChangeView}
                      />
                      <label
                        htmlFor={`${organisationUnit.id}employeeNumber`}
                        className="context-menu-item__form-label"
                      >
                        {localeLookup('translations.Employee number')}
                      </label>
                    </div>
                  </form>
                </div>
              </li>
              {!isReadOnly && (
                <>
                  <ContextMenuItem
                    onClick={this.onClickAssignRoles}
                    leftIconKind="select"
                    titleText={localeLookup('translations.Assign roles')}
                  />
                  <ContextMenuItem
                    onClick={this.onClickDuplicate}
                    leftIconKind="copy"
                    titleText={localeLookup('translations.Duplicate')}
                  />
                  <ContextMenuItem
                    onClick={this.onClickChangeState}
                    leftIconKind="eye"
                    titleText={localeLookup('translations.Visibility')}
                    subtitleText={
                      organisationUnit.state ===
                        ORGANISATION_UNIT_STATES.PASSIVE ||
                      organisationUnit.state ===
                        ORGANISATION_UNIT_STATES.INHERITED_PASSIVE
                        ? localeLookup('translations.Limited visibility')
                        : localeLookup('translations.Visible')
                    }
                  />
                  {organisationUnit.state === 'Active' && (
                    <ContextMenuItem
                      onClick={this.onClickOpenInElementStatus}
                      leftIconKind="enter-right"
                      titleText={localeLookup(
                        'translations.Open in element status'
                      )}
                    />
                  )}
                  <ContextMenuItem
                    onClick={this.onClickDelete}
                    leftIconKind="trash2"
                    leftIconColor="red"
                    titleText={localeLookup('translations.Delete')}
                  />
                </>
              )}
            </ContextMenu>
          </div>
          {(organisationUnit.rolesHasNotCompletedGoals > 0 ||
            organisationUnit.additionalKnowledgeHasNotCompletedGoals > 0) && (
            <p className="organisation-board-unit__header-subtitle">
              {subtitle}
            </p>
          )}
        </div>
        <div className="organisation-board-unit__header-close">
          <button
            type="button"
            className="organisation-board-unit__header-close-button"
            onClick={this.onClickCloseOrganisationUnit}
          >
            <Icon kind="cross-circle" />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { organisationUnit, isReadOnly, activeView, timelineOptions } =
      this.props;
    if (!organisationUnit) return null;
    const isVisibilityLimited =
      organisationUnit?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
      organisationUnit?.state === ORGANISATION_UNIT_STATES.PASSIVE;
    return (
      <div
        ref={this.ref}
        className={cx('organisation-board-unit', {
          'organisation-board-unit--read-only': isReadOnly,
          'organisation-board-unit--state-limited-visibility':
            isVisibilityLimited,
        })}
      >
        {this.renderTitleHeader()}
        <div className="organisation-board-unit__main">
          {activeView === 'timeline' ? (
            <GanttChart
              sorting={timelineOptions.sorting}
              grouping={timelineOptions.grouping}
            ></GanttChart>
          ) : (
            <>
              {this.renderSidebar()}
              {this.renderGrid()}
              <div className="organisation-board-unit__right">
                <ButtonIcon
                  className="organisation-board-unit__right-add-persons"
                  icon="user-plus"
                  inline
                  iconColor="green"
                  onClick={this.onClickChangeOrganisationUnitPersons}
                  tooltip={localeLookup('translations.Add or remove persons')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  WithOrganisationUnitActions,
  withRouter,
  withPersonLookup
)(OrganisationBoardOrganisationUnit);
