import { connect } from 'react-redux';
import localeLookup from '../config/locale';
import {
  EMPTY_ID,
  DELETED_PERSON_ID,
  LOCK_STATES,
  PERSON_STATES,
  DELETED_GROUP_ID,
} from '../constants';

const PersonWrapper = ({
  id,
  persons,
  wildcardPersons,
  as = 'span',
  isTrainingTask,
  lockState,
  render,
  isLocked,
  groups,
}) => {
  const getColor = () => {
    if (
      id === DELETED_PERSON_ID ||
      id === DELETED_GROUP_ID ||
      persons[id]?.state === PERSON_STATES.ARCHIVED
    ) {
      return 'red';
    }
    if (isTrainingTask) return 'blue';
    return 'black';
  };

  const getText = () => {
    if (id === EMPTY_ID && (lockState === LOCK_STATES.LOCKED || isLocked))
      return localeLookup('translations.Relevant person');
    if (groups[id]) return groups[id].name;
    if (wildcardPersons[id]) return wildcardPersons[id].name;
    if (persons[id]) {
      const person = persons[id];
      if (person.state === PERSON_STATES.ARCHIVED) {
        return `${person.name} (${localeLookup('translations.Archived')})`;
      }
      return person.name;
    }
    return null;
  };

  const getSuffix = () => {
    if (persons[id]) {
      const person = persons[id];
      return `(${person.initials}${
        person.employeeNumber ? ` · ${person.employeeNumber}` : ''
      })`;
    }
  };

  const getTooltip = () => {
    if (groups[id]) {
      const group = groups[id];
      if (group.members.length === 0)
        return localeLookup('translations.No persons in group');

      const personNames = group.members.map((id) => {
        const person = persons[id];
        if (person.state === PERSON_STATES.ARCHIVED) {
          return `${person.name} (${localeLookup('translations.Archived')}) (${
            person.initials
          }${person.employeeNumber ? ` · ${person.employeeNumber}` : ''})`;
        }
        return `${person.name} (${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      });
      return personNames.join('\n');
    }
    if (persons[id]) {
      const person = persons[id];
      return `${person.initials} ${
        person.employeeNumber ? `· ${person.employeeNumber}` : ''
      }`;
    }
  };

  return render({ name: getText(), color: getColor(), tooltip: getTooltip(), suffix: getSuffix()});
};

const mapStateToProps = (state) => ({
  persons: state.persons,
  wildcardPersons: state.wildcardPersons,
  groups: state.groups,
});

export default connect(mapStateToProps)(PersonWrapper);
