import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeOrganisationLanguage,
  changeOrganisationName,
  changePermission,
  rotateSecret,
  requestPermissions,
  requestSystemSettings,
} from '../actions/systemSettingsActions';
import LoadScreen from '../components/LoadScreen';
import MainArea from '../components/MainArea';
import Modal from '../components/Modal';
import InfoModal from '../components/modal/InfoModal';
import OrganisationSettings from '../components/OrganisationSettings';
import Page from '../components/Page';
import localeLookup from '../config/locale';
import {
  updateAzureADAdminConsentService,
  updateOrganisationTimeZoneService,
  updateSupportAccessLevelsService,
} from '../services/systemSettingsService';
import { getQueryStringParams } from '../utils/helpers';
import { trackEvent } from '../utils/tracking';

const mapStateToProps = (state) => {
  const { systemSettings, user } = state;
  return {
    organisationSettings: systemSettings.organisationSettings,
    isFetchingSettings: systemSettings.isFetchingSettings,
    permissions: systemSettings.permissions,
    permissionGroups: systemSettings.permissionGroups,
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      changeOrganisationLanguage,
      changeOrganisationName,
      requestPermissions,
      requestSystemSettings,
      changePermission,
      rotateSecret,
    },
    dispatch
  ),
});

class SystemSettings extends Component {
  constructor() {
    super();
    this.state = {
      activeModal: '',
      modalProps: {},
      isLoading: true,
      timeZone: 'Europe/Dublin',
    };
  }

  componentDidMount() {
    trackEvent('$pageview');
    this.checkForAzureADConsent();
    this.getInitialData().then(() => {
      this.setState({ isLoading: false });
    });
  }

  checkForAzureADConsent = () => {
    const { history, location } = this.props;
    const queryParams = getQueryStringParams(window.location.search);
    if (queryParams.admin_consent) {
      updateAzureADAdminConsentService({
        adminConsent: queryParams.admin_consent,
        tenantId: queryParams.tenant,
        state: queryParams.state,
      }).then(() => {
        history.push(location.pathname);
        this.showModal('info', {
          title: localeLookup('translations.Active Directory connected'),
          body: localeLookup('translations.adConsentMessage'),
        });
        this.getInitialData();
      });
    }
  };

  getInitialData = () => {
    const { requestSystemSettings, requestPermissions } = this.props;
    return Promise.all([requestSystemSettings(), requestPermissions()]);
  };

  hideModal = () => {
    this.setState({
      activeModal: '',
      modalProps: {},
    });
  };

  onChangeSupportAccessLevels = (accessLevels) => {
    const { requestPermissions } = this.props;
    updateSupportAccessLevelsService(accessLevels).then(() => {
      requestPermissions();
    });
  };

  onChangeTimeZone = (timeZone) => {
    this.setState({ timeZone });
    updateOrganisationTimeZoneService(timeZone).then(() => {
      this.getInitialData();
    });
  };

  showModal = (modalType, modalProps) => {
    this.setState({
      activeModal: modalType,
      modalProps,
    });
  };

  renderModals = () => {
    const { activeModal, modalProps } = this.state;

    return (
      <Modal
        isOpen={activeModal}
        onCloseClick={this.hideModal}
        maxWidth="500px"
        render={({ onCloseClick }) => (
          <>
            {activeModal === 'info' && (
              <InfoModal
                title={modalProps.title}
                body={modalProps.body}
                onCancel={onCloseClick}
                onClose={onCloseClick}
              />
            )}
          </>
        )}
      />
    );
  };

  render() {
    const {
      isFetchingSettings,
      organisationSettings,
      changeOrganisationName,
      changeOrganisationLanguage,
      changePermission,
      rotateSecret,
      permissions,
      permissionGroups,
      user,
    } = this.props;
    const { isLoading, timeZone } = this.state;
    if (isLoading) return <LoadScreen />;
    return (
      <Page>
        <MainArea>
          <OrganisationSettings
            settings={organisationSettings}
            permissions={permissions}
            permissionGroups={permissionGroups}
            onChangePermission={changePermission}
            onChangeSupportAccessLevels={this.onChangeSupportAccessLevels}
            onRotateSecret={rotateSecret}
            onChangeOrganisationName={changeOrganisationName}
            onChangeOrganisationLanguage={changeOrganisationLanguage}
            user={user}
            timeZone={organisationSettings.timeZone}
            onChangeTimeZone={this.onChangeTimeZone}
          />
        </MainArea>
        {this.renderModals()}
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettings);
