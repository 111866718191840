// Services
import { fetchTraineeRoleData, fetchTraineeRoles } from '../services/traineeService';

export const TRAINEEROLES_RECIEVED = 'TRAINEEROLES_RECIEVED';
export const traineeRolesRecieved = (data) => ({
  type: TRAINEEROLES_RECIEVED,
  data,
});

export const requestTraineeRoles = () => (dispatch) =>
  fetchTraineeRoles()
    .then((data) => {
      dispatch(traineeRolesRecieved(data.data));
    })
    .catch(() => {
      console.log('failed fetching roles');
    });

export const TRAINEE_ROLE_DATA_RECIEVED = 'TRAINEE_ROLE_DATA_RECIEVED';
export const traineeRoleDataRecieved = (data) => ({
  type: TRAINEE_ROLE_DATA_RECIEVED,
  data,
});

export const TRAINEE_ROLE_DATA_PENDING = 'TRAINEE_ROLE_DATA_PENDING';
export const traineeRoleDataPending = () => ({
  type: TRAINEE_ROLE_DATA_PENDING,
});

export const TRAINEE_ROLE_DATA_ERROR = 'TRAINEE_ROLE_DATA_ERROR';
export const traineeRoleDataError = () => ({
  type: TRAINEE_ROLE_DATA_ERROR,
});

export const requestTraineeRoleData = (roleId, setLoadState = true) => (dispatch) => {
  setLoadState && dispatch(traineeRoleDataPending());

  return fetchTraineeRoleData(roleId)
    .then((data) => {
      dispatch(traineeRoleDataRecieved(data.data));
    })
    .catch((ex) => {
      throw new Error(ex);
    });
};
