import React from 'react';
import { connect } from 'react-redux';
import { formatDate } from '../utils/helpers';

const mapStateToProps = (state) => ({
  timeZone: state.rootmenu.timeZone,
  dateFormat: state.rootmenu.dateFormat,
  timeFormat: state.rootmenu.timeFormat,
});

function DateTime({
  date = '',
  format = false,
  showTooltip,
  timeZone,
  includeTime,
  timeFormat,
  dateFormat,
}) {
  return <>{formatDate(date, timeZone, includeTime, dateFormat, timeFormat)}</>;
}

export default connect(mapStateToProps)(DateTime);
