import posthog from 'posthog-js';

export const initiateTracking = (apiKey, host) => {
  posthog.init(apiKey, { api_host: host, autocapture: false });
};

export const identifyUser = ({ id, email, name, tenantId }) => {
  posthog.identify(id, {
    tenant_id: tenantId,
    email,
    name,
  });
};

export const resetTrackingData = () => {
  posthog.reset();
};

export const trackEvent = (eventName, props = {}) => {
  posthog.capture(eventName, props);
};
