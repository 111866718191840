import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Component } from 'react';
import { withAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import localeLookup from '../../config/locale';
import routes from '../../config/routes';
import { resetTrackingData } from '../../utils/tracking';
import withAccessControl from '../HOC/withAccessControl';
import Icon from '../Icon';
import Portal from '../Portal';
import Text from '../Text';
import { compareLocal, sortBy } from '../../utils/helpers';
import { PERSON_STATES } from '../../constants';
import Badge from '../Badge';

const variants = {
  open: { height: 'auto' },
  closed: {
    height: 0,
  },
};

const mapStateToProps = (state) => {
  return {
    userLocale: state.userSettings.locale,
    userFullName: state.user.name,
    organisation: state.user.organisation,
    tenantId: state.user.tenantId,
    userId: state.user.id,
    tenants: state.user.tenants,
    telemetryEnabled: state.permissions.telemetryEnabled,
  };
};
class MainHeader extends Component {
  constructor() {
    super();
    this.componentRef = React.createRef();
    this.menuRef = React.createRef();
    this.state = {
      showMenu: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  getCurrentTenantId = () => {
    const { userId } = this.props;
    return localStorage.getItem(`tenantId_${userId}`);
  };

  getNavLinks = () => {
    return routes[this.getRootPath()]?.routes || [];
  };

  getRootPath = () => {
    return window.location.pathname.split('/')[1];
  };

  getRouteByPath = (routes) => {
    const { location } = this.props;
    for (let index = 0; index < routes.length; index++) {
      const route = routes[index];
      if (route.path === location.pathname) {
        return route;
      }
      if (route.routes) {
        const result = this.getRouteByPath(route.routes);
        if (result) return result;
      }
    }
  };

  getTitle = () => {
    const { location, title } = this.props;
    if (title) return title;
    if (location?.state?.title) return location.state.title;
    // TODO: Find better way to handle this
    if (location.pathname.startsWith('/persons/all'))
      return localeLookup('translations.Profiles');
    const currentRoute = this.getRouteByPath(Object.values(routes));
    if (currentRoute && currentRoute.getTranslatedTitle) {
      return currentRoute.getTranslatedTitle();
    }
    return '';
  };

  onClickLogout = () => {
    const { auth, telemetryEnabled, removeUser } = this.props;
    const { signoutRedirect } = auth;
    if (telemetryEnabled) resetTrackingData();
    signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
      extraQueryParams: { explicit_logout: true },
    });
  };
  onClickOrganisation = (id) => {
    const { userId } = this.props;
    localStorage.setItem(`tenantId_${userId}`, id);
    window.location.replace('/');
  };

  onClickOutside = (event) => {
    if (
      this.componentRef?.current &&
      !this.componentRef.current.contains(event.target) &&
      this.menuRef?.current &&
      !this.menuRef.current.contains(event.target)
    ) {
      this.setState({ showMenu: false });
    }
  };

  onClickToggleMenu = () => {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
  };

  render() {
    const { showMenu } = this.state;
    const { userFullName, tenants, userId, history, location } = this.props;
    const currentTenantId = this.getCurrentTenantId();
    return (
      <header className="main-header" ref={this.componentRef}>
        <div className="main-header__left">
          {location?.state?.backTitle && (
            <button
              onClick={history.goBack}
              type="button"
              className="main-header__back"
            >
              <Icon className="main-header__back-icon" kind="arrow-left"></Icon>
              <span>
                {localeLookup('translations.Back to')}{' '}
                {location.state.backTitle}
              </span>
            </button>
          )}
          <h1 className="main-header__left-title">{this.getTitle()}</h1>
        </div>
        <button
          onClick={this.onClickToggleMenu}
          type="button"
          className="main-header__right"
        >
          <div className="main-header__user-info">
            <p className="main-header__user-info-name">{userFullName}</p>
            <p className="main-header__user-info-organisation">
              {tenants?.[currentTenantId]?.name}
            </p>
          </div>

          {tenants?.[currentTenantId]?.state !== PERSON_STATES.ACTIVE ? (
            <div className="main-header__user-badge">
              <Badge icon="eye-crossed" size={'xsmall'}></Badge>
            </div>
          ) : (
            <div className="main-header__user-badge">
              <span>{userFullName[0]}</span>
            </div>
          )}
        </button>
        <Portal>
          <AnimatePresence>
            {showMenu && (
              <motion.div
                ref={this.menuRef}
                initial={variants.closed}
                animate={variants.open}
                exit={variants.closed}
                transition={{ ease: 'easeOut', duration: 0.15 }}
                className="main-header__navigation-wrapper"
              >
                {Object.values(tenants).length > 1 && (
                  <Text
                    className="main-header__navigation-title"
                    size="xs"
                    color="dark-grey"
                    upperCase
                  >
                    {localeLookup('translations.Organisations')}
                  </Text>
                )}
                <ul className="main-header__navigation">
                  {Object.values(tenants).length > 1 && (
                    <li className="main-header__navigation-item">
                      <ul className="main-header__organisations">
                        {sortBy(
                          Object.values(tenants),
                          [(a, b) => compareLocal(a.name, b.name)],
                          ['asc']
                        ).map((organisation) => (
                          <li
                            key={organisation.id}
                            className={cx('main-header__organisations-item', {
                              'main-header__organisations-item--selected':
                                organisation.id === currentTenantId,
                            })}
                          >
                            <button
                              disabled={organisation.id === currentTenantId}
                              className="main-header__organisations-button"
                              onClick={() =>
                                this.onClickOrganisation(organisation.id)
                              }
                            >
                              <div className="main-header__organisations-name-container">
                                <p className="main-header__organisations-name">
                                  {organisation.name}
                                </p>
                                {tenants?.[organisation.id]?.state ===
                                  PERSON_STATES.HIDDEN && (
                                  <Icon
                                    size="small"
                                    color="grey"
                                    kind="eye-crossed"
                                  />
                                )}
                              </div>
                              {organisation.id === currentTenantId && (
                                <p className="main-header__organisations-selection-text">
                                  {localeLookup('translations.Current')}
                                </p>
                              )}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                  <li className="main-header__navigation-item">
                    <NavLink
                      onClick={this.onClickToggleMenu}
                      activeClassName="main-header__navigation--selected"
                      to="/settings"
                      className="main-header__navigation-link"
                    >
                      <Icon
                        size="small"
                        color="grey"
                        className="main-header__navigation-icon"
                        kind="cog"
                      ></Icon>
                      {localeLookup('translations.Settings')}
                    </NavLink>
                  </li>
                  <li className="main-header__navigation-item">
                    <button
                      className="main-header__navigation-button"
                      onClick={this.onClickLogout}
                    >
                      <Icon
                        size="small"
                        color="grey"
                        className="main-header__navigation-icon"
                        kind="exit-right"
                      ></Icon>
                      {localeLookup('translations.Log out')}
                    </button>
                  </li>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </Portal>
      </header>
    );
  }
}
export default withRouter(
  connect(mapStateToProps)(withAccessControl(withAuth(MainHeader)))
);
