import React, { Component } from 'react';
import ErrorMessage from '../formElements/ErrorMessage';
import FormWrapper from '../formElements/FormWrapper';
import Input from '../formElements/Input';
import GenericModal from './GenericModal';
import * as Yup from 'yup';
import localeLookup from '../../config/locale';

export default class NameModal extends Component {
  render() {
    const {
      onConfirm,
      confirmButtonText,
      inputPlaceholder,
      onCloseClick,
      title,
      defaultValue,
    } = this.props;
    return (
      <FormWrapper
        onSubmit={onConfirm}
        initialValues={{ name: defaultValue }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            localeLookup('translations.Name is required')
          ),
        })}
      >
        {(props) => {
          const { errors, handleSubmit, values, dirty, handleChange, isValid } =
            props;
          return (
            <GenericModal
              confirmButtonText={confirmButtonText}
              onCancelClick={onCloseClick}
              onClose={onCloseClick}
              onConfirmClick={handleSubmit}
              title={title}
              confirmDisabled={!isValid || !dirty}
            >
              <form onSubmit={handleSubmit}>
                <Input
                  autoFocus
                  fullWidth
                  placeholder={inputPlaceholder}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <ErrorMessage show={errors.name}>{errors.name}</ErrorMessage>
              </form>
            </GenericModal>
          );
        }}
      </FormWrapper>
    );
  }
}
