import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../Icon';
const DashboardAreaBox = ({ color, text, icon, onClick, transparent }) => {
  const modifierClasses = {
    [`dashboard-area-box--color-${color}`]: color,
    'dashboard-area-box--transparent': transparent,
  };
  if (onClick) {
    return (
      <div className={cx('dashboard-area-box', modifierClasses)}>
        <button type="button" className="dashboard-area-box__button" onClick={onClick}>
          {icon && <Icon className="dashboard-area-box__icon" size="small" kind={icon} />}
          {!icon && <p className="dashboard-area-box__text">{text}</p>}
        </button>
      </div>
    );
  }
  return (
    <div className={cx('dashboard-area-box', modifierClasses)}>
      {icon && <Icon className="dashboard-area-box__icon" size="small" kind={icon} />}
      {!icon && <p className="dashboard-area-box__text">{text}</p>}
    </div>
  );
};

DashboardAreaBox.defaultProps = {
  text: '',
  icon: '',
  transparent: false,
};

DashboardAreaBox.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired,
  transparent: PropTypes.bool,
};

export default DashboardAreaBox;
