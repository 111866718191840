import React from 'react';
import cx from 'classnames';
import Icon from './Icon';

const NotificationCounter = ({ count, color, size, icon, onClick, className }) => {
  const modifierClasses = {
    [`notification-counter--color-${color}`]: color,
    [`notification-counter--size-${size}`]: size,
    ['notification-counter--button']: onClick,
  };
  return (
    <div onClick={onClick} className={cx('notification-counter', className, modifierClasses)}>
      {icon && <Icon className="notification-counter__icon" kind={icon} />}
      <span className="notification-counter__count">{count}</span>
    </div>
  );
};

export default NotificationCounter;
