import React from 'react';
import localeLookup from '../../config/locale';
import ButtonIcon from '../ButtonIcon';
import Input from '../formElements/Input';
import Label from '../Label';
import Select from '../Select';
import SwitchCheckbox from '../SwitchCheckbox';
import Tabs from '../Tabs';
import OrganisationBoardLegend from './OrganisationBoardLegend';
import Button from '../Button';
import RadioButton from '../RadioButton';
import InputLabel from '../formElements/InputLabel';
import { RadioButtonGroup } from '../RadioButtonGroup';
import {
  ROLE_MATRIX_COMPLETION_STATES,
  ROLE_MATRIX_PROGRESS_VIEWS,
} from '../../constants';

const OrganisationHeader = ({
  onChangePersonFilterString,
  personFilterString,
  onChangeTimelineGrouping,
  onChangeTimelineSorting,
  timelineOptions,
  activeView,
  onChangeView,
  completionState,
  onClickAddProgram,
  onChangeCompletionState,
  onChangeTimelineProgressView,
}) => {
  return (
    <div className="organisation-header">
      <div className="organisation-header__top">
        <div className="organisation-header__top-left">
          {activeView === 'matrix' ? (
            <>
              <Input
                value={personFilterString}
                onChange={onChangePersonFilterString}
                className="organisation-header-filter-input"
                placeholder={localeLookup('translations.Filter persons')}
              />
              <div className="organisation-header__setting">
                <RadioButtonGroup
                  groupName="progressView"
                  options={[
                    {
                      value: ROLE_MATRIX_COMPLETION_STATES.ALL,
                      label: localeLookup('translations.All'),
                      isChecked:
                        completionState === ROLE_MATRIX_COMPLETION_STATES.ALL,
                    },
                    {
                      value: ROLE_MATRIX_COMPLETION_STATES.TRAINING,
                      label: localeLookup('translations.Training'),
                      isChecked:
                        completionState ===
                        ROLE_MATRIX_COMPLETION_STATES.TRAINING,
                    },
                    {
                      value: ROLE_MATRIX_COMPLETION_STATES.NONE,
                      label: localeLookup('translations.None'),
                      isChecked:
                        completionState === ROLE_MATRIX_COMPLETION_STATES.NONE,
                    },
                  ]}
                  optionDirection="horizontal"
                  direction="horizontal"
                  label={`${localeLookup('translations.Show status')}:`}
                  onChange={onChangeCompletionState}
                ></RadioButtonGroup>
              </div>
            </>
          ) : (
            <>
              <div className="organisation-header__field">
                <Label className="organisation-header__field-label">
                  {localeLookup('translations.Grouping')}
                </Label>
                <div className="organisation-header__field-group">
                  <Select
                    value={timelineOptions.grouping}
                    onChange={onChangeTimelineGrouping}
                    size="small"
                    options={[
                      {
                        title: localeLookup('translations.None'),
                        value: 'none',
                      },
                      {
                        title: localeLookup('translations.Organisation unit'),
                        value: 'organisationUnit',
                      },
                      {
                        title: localeLookup('translations.Person'),
                        value: 'person',
                      },
                      {
                        title: localeLookup('translations.Mentor'),
                        value: 'mentor',
                      },
                      {
                        title: localeLookup('translations.Role'),
                        value: 'role',
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="organisation-header__field">
                <Label className="organisation-header__field-label">
                  {localeLookup('translations.Sorting')}
                </Label>
                <div className="organisation-header__field-group">
                  <Select
                    value={timelineOptions.sorting}
                    onChange={onChangeTimelineSorting}
                    size="small"
                    options={[
                      {
                        value: 'startDate',
                        title: localeLookup('translations.Start date'),
                      },
                      {
                        value: 'endDate',
                        title: localeLookup('translations.End date'),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="organisation-header__field">
                <Label className="organisation-header__field-label">
                  {localeLookup('translations.Show progress')}
                </Label>
                <div className="organisation-header__field-group">
                  <Select
                    value={timelineOptions.progressView}
                    onChange={onChangeTimelineProgressView}
                    size="small"
                    options={Object.values(ROLE_MATRIX_PROGRESS_VIEWS).map(
                      (view) => {
                        return {
                          value: view.value,
                          title: `${view.amount} ${localeLookup(view.title)}`,
                        };
                      }
                    )}
                  />
                </div>
              </div>
              <Button onClick={onClickAddProgram} kind="darkui" size="small">
                {localeLookup('translations.Start training')}
              </Button>
            </>
          )}
        </div>
        <div className="organisation-header__top-right">
          <Tabs
            activeTab={activeView}
            tabWidth="100px"
            activeTabColor="green"
            tabColor="grey"
            rounded
            onChangeTab={onChangeView}
            tabs={[
              { name: localeLookup('translations.Matrix'), id: 'matrix' },
              { name: localeLookup('translations.Timeline'), id: 'timeline' },
            ]}
          ></Tabs>
        </div>
      </div>
      {activeView === 'matrix' && (
        <OrganisationBoardLegend></OrganisationBoardLegend>
      )}
    </div>
  );
};

export default OrganisationHeader;
