/* eslint-disable nonblock-statement-body-position */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { getPersons } from '../../slices/personsSlice';
import CreateUserInformationModal from './CreateUserInformationModal';
import UserTypeModal from './UserTypeModal';

const defaultState = {
  activeStep: 'type',
  userType: '',
  email: '',
  name: '',
  isExternalUser: false,
  isUserWithoutLogin: false,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPersons,
    },
    dispatch
  );

class CreateUserModal extends PureComponent {
  constructor() {
    super();
    this.state = defaultState;
  }

  setStep = (step) => {
    const { email, userType } = this.state;
    if (step === 'type') {
      this.setState({
        ...defaultState,
        activeStep: step,
        email,
        userType,
      });
    } else {
      this.setState({
        activeStep: step,
      });
    }
  };

  onCreatedUser = ({ id, name, initials, autoGeneratedInitials }) => {
    const { onCreated, getPersons } = this.props;
    getPersons().then(() => {
      onCreated({ id, name, initials, autoGeneratedInitials });
    });
  };

  onSubmitUserType = ({ email, userType }) => {
    if (userType === 'userWithoutLogin') {
      this.setState({
        activeStep: 'informations',
        userType,
        email: '',
        isUserWithoutLogin: true,
        isExternalUser: false,
      });
    } else {
      this.setState({
        activeStep: 'informations',
        userType,
        email,
        isUserWithoutLogin: false,
        isExternalUser: userType === 'userWithExternalLogin',
      });
    }
  };

  render() {
    const { onClose, title, onCancel, initialName } = this.props;
    const { activeStep, email, isExternalUser, isUserWithoutLogin, name } =
      this.state;

    const steps = [
      localeLookup('translations.User type'),
      localeLookup('translations.Information'),
    ];

    if (activeStep === 'type') {
      return (
        <UserTypeModal
          activeStepIndex={0}
          confirmButtonText={localeLookup('translations.Next')}
          onClose={onClose}
          onCancelClick={onCancel || onClose}
          onSubmit={this.onSubmitUserType}
          steps={steps}
          title={title}
          initialValues={{ email: email }}
        />
      );
    }
    if (activeStep === 'informations') {
      return (
        <CreateUserInformationModal
          onClose={onClose}
          title={title}
          steps={steps}
          activeStepIndex={1}
          isExternalUser={isExternalUser}
          isUserWithoutLogin={isUserWithoutLogin}
          onCreated={this.onCreatedUser}
          initialValues={{
            email: email || '',
            name: initialName || '',
          }}
          confirmButtonText={localeLookup('translations.Create')}
          cancelButtonText={localeLookup('translations.Back')}
          onCancelClick={() => {
            this.setStep('type');
          }}
        />
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
