import {
  axiosRequestDELETE,
  axiosRequestGET,
  axiosRequestPOST,
  axiosRequestPUT,
} from './serviceConfig';

// Get all
export const getAllElementsService = () => axiosRequestGET('/api/content/elements');

export const getAllAreasService = () => axiosRequestGET('/api/content/areas');

export const getAllRolesService = () => axiosRequestGET('/api/content/roles');

export const getAllCategoriesService = () =>
  axiosRequestGET('/api/content/categories');

export const getAllSpacesService = (spaceIds) =>
  axiosRequestPOST('/api/content/query/spaces', { spaces: spaceIds });

// Queries

export const queryElementsService = (elementIds = []) =>
  axiosRequestPOST('/api/content/query/elements', { elements: elementIds });

export const queryAreasService = (areaIds = []) =>
  axiosRequestPOST('/api/content/query/areas', { areas: areaIds });

export const queryRolesService = (roleIds = []) =>
  axiosRequestPOST('/api/content/query/roles', { roles: roleIds });

// Spaces

export const updateSpaceNameService = (id, name = '') =>
  axiosRequestPUT(`/api/content/spaces/${id}`, { name });

export const connectSpacesToModulesService = (
  spaceIds = [],
  moduleIds = [],
  editable
) =>
  axiosRequestPUT(`/api/content/spaces/modules/connect`, {
    editable,
    modules: moduleIds,
    spaces: spaceIds,
  });

export const disconnectSpacesFromModulesService = (
  spaceIds = [],
  moduleIds = []
) =>
  axiosRequestPUT(`/api/content/spaces/modules/disconnect`, {
    modules: moduleIds,
    spaces: spaceIds,
  });

export const updateRoleSpaceService = ({
  originSpaceId = '',
  destinationSpaceId = '',
  moduleConnections = {},
  roleId = '',
}) =>
  axiosRequestPUT(`/api/content/spaces/roles/move`, {
    role: roleId,
    originSpace: originSpaceId,
    destinationSpace: destinationSpaceId,
    moduleConnections,
  });

export const updateSpacesSortOrderService = (spaceIds) =>
  axiosRequestPUT(`/api/content/spaces/roles/move`, { spaces: spaceIds });

export const getActiveSpaceService = () =>
  axiosRequestGET('/api/content/spaces/active-space');

export const getSpaceStatusService = () =>
  axiosRequestGET('/api/content/spaces/status');

export const setSpaceStatusService = (enabled) =>
  axiosRequestPUT('/api/content/spaces/status', {
    status: enabled ? 'Activated' : ' Deactivated',
  });

export const setActiveSpaceService = (id) =>
  axiosRequestPUT('/api/content/spaces/active-space', { spaceId: id });

export const updateSpaceAdministratorsService = (
  spaceIds = [],
  administratorIds = [],
  context = 'space' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/spaces/administrators`, {
    administrators: administratorIds,
    spaces: spaceIds,
    context,
  });

export const updateSpaceCreateRolePermissionsService = (
  spaceIds = [],
  canCreateRoleIds = [],
  context = 'space' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/spaces/create-roles`, {
    createRoles: canCreateRoleIds,
    spaces: spaceIds,
    context,
  });

export const updateSpaceCreateModulePermissionsService = (
  spaceIds = [],
  canCreateModuleIds = [],
  context = 'space' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/spaces/create-modules`, {
    createModules: canCreateModuleIds,
    spaces: spaceIds,
    context,
  });

export const createSpaceService = (name = '') =>
  axiosRequestPOST(`/api/content/spaces/`, { name });

export const deleteSpaceService = (id = '') =>
  axiosRequestDELETE(`/api/content/spaces/${id}`);

// Roles

export const updateRoleNameService = (id, name = '') =>
  axiosRequestPUT(`/api/content/roles/${id}/name`, { name });

export const updateRoleOwnerService = (
  roleIds = [],
  ownerId = '',
  context = 'role' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/roles/owner`, {
    owner: ownerId,
    roles: roleIds,
    context,
  });

export const updateRoleEditorsService = (
  roleIds = [],
  editorIds = [],
  context = 'role' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/roles/editors`, {
    editors: editorIds,
    roles: roleIds,
    context,
  });

export const updateRoleDescriptionService = (id, description = '') =>
  axiosRequestPUT(`/api/content/roles/${id}/description`, { description });

export const getRoleUsageService = (id) =>
  axiosRequestGET(`/api/content/roles/${id}/usage`);

export const deleteRoleService = (id) =>
  axiosRequestDELETE(`/api/content/roles/${id}`);

export const createRoleService = (
  name = '',
  owner = '',
  editors = [],
  spaceId = ''
) =>
  axiosRequestPOST(`/api/content/roles`, {
    name,
    owner,
    editors,
    space: spaceId,
  });

export const connectRolesToOrganisationUnitsService = (
  roleIds = [],
  unitIds = []
) =>
  axiosRequestPUT(`/api/content/roles/connect`, {
    roles: roleIds,
    units: unitIds,
  });

export const disconnectRolesFromOrganisationUnitsService = (
  roleIds = [],
  unitIds = []
) =>
  axiosRequestPUT(`/api/content/roles/disconnect`, {
    roles: roleIds,
    units: unitIds,
  });

export const updateRoleAreasOrderService = (
  id,
  areas = [],
  additionalAreas = []
) =>
  axiosRequestPUT(`/api/content/roles/${id}/sort`, { areas, additionalAreas });

// Areas

export const updateAreaNameService = (id, name = '') =>
  axiosRequestPUT(`/api/content/areas/${id}/name`, { name });

export const updateAreaOwnerService = (
  areaIds = [],
  ownerId = '',
  context = 'module' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/areas/owner`, {
    owner: ownerId,
    modules: areaIds,
    context,
  });

export const updateAreaExpertsService = (id, experts = []) =>
  axiosRequestPUT(`/api/content/areas/${id}/experts`, { experts });

export const updateAreaEditorsService = (
  areaIds = [],
  editorIds = [],
  context = 'module' /* or 'person' */
) =>
  axiosRequestPUT(`/api/content/areas/editors`, {
    modules: areaIds,
    editors: editorIds,
    context,
  });

export const updateAreaCategoryService = (id, category = '') =>
  axiosRequestPUT(`/api/content/areas/${id}/category`, { category });

export const updateAreaChampLinkVisiblityService = (id, visible = false) =>
  axiosRequestPUT(`/api/content/areas/${id}/champ-link`, { public: visible });

export const deleteAreaService = (id) =>
  axiosRequestDELETE(`/api/content/areas/${id}`);

export const createAreaService = (
  name = '',
  owner = '',
  editors = [],
  editableInSpaces = []
) =>
  axiosRequestPOST(`/api/content/areas`, {
    name,
    owner,
    editors,
    editableInSpaces,
  });

export const connectAreasToRolesService = (
  roles = [],
  areas = [],
  additional = false
) =>
  axiosRequestPUT(`/api/content/areas/connect`, { roles, areas, additional });

export const disconnectAreasFromRolesService = (roles = [], areas = []) =>
  axiosRequestPUT(`/api/content/areas/disconnect`, { roles, areas });

export const updateAreaElementsOrderService = (id, elements = []) =>
  axiosRequestPUT(`/api/content/areas/${id}/sort`, { elements });

// Categories

export const createCategoryService = (name = '') =>
  axiosRequestPOST('/api/content/categories', { name });

export const deleteCategoryService = (id = '') =>
  axiosRequestDELETE(`/api/content/categories/${id}`);

export const updateCategoryNameService = (id, name = '') =>
  axiosRequestPUT(`/api/content/categories/${id}`, { name });

// Elements

export const updateElementNameService = (id, name = '') =>
  axiosRequestPUT(`/api/content/elements/${id}/name`, { name });

export const updateElementResponsibilityService = (
  id,
  {
    lockedApprover,
    completionRequirement,
    completionType,
    responsible,
    alternativeResponsible,
  }
) =>
  axiosRequestPUT(`/api/content/elements/${id}/responsible-and-completion`, {
    lockedApprover,
    completionRequirement,
    completionType,
    responsible,
    alternativeResponsible,
  });

export const updateElementDescriptionService = (id, description = '') =>
  axiosRequestPUT(`/api/content/elements/${id}/description`, { description });

export const updateElementTypeService = (id, type = '') =>
  axiosRequestPUT(`/api/content/elements/${id}/type`, { type });

export const updateElementExpirationService = (
  id,
  enabled = false,
  validPeriod = 0
) =>
  axiosRequestPUT(`/api/content/elements/${id}/expiration`, {
    enabled,
    validPeriod,
  });

export const updateElementLinkVisibilityService = (id, visible = false) =>
  axiosRequestPUT(`/api/content/elements/${id}/champ-link`, {
    public: visible,
  });

export const connectElementsToRolesService = (roles = [], elements = []) =>
  axiosRequestPUT(`/api/content/elements/connect`, { roles, elements });

export const disconnectElementsFromRolesService = (roles = [], elements = []) =>
  axiosRequestPUT(`/api/content/elements/disconnect`, { roles, elements });

export const updateElementAreaLocationService = (
  id,
  originAreaId = '',
  destinationAreaId
) =>
  axiosRequestPUT(`/api/content/elements/${id}/move`, {
    origin: originAreaId,
    destination: destinationAreaId,
  });

export const getElementRelocationRelationsService = (
  id,
  originAreaId = '',
  destinationAreaId
) =>
  axiosRequestPUT(`/api/content/elements/${id}/move-relations`, {
    origin: originAreaId,
    destination: destinationAreaId,
  });

export const deleteElementService = (id) =>
  axiosRequestDELETE(`/api/content/elements/${id}`);

export const createElementService = (names = [], areaId = '') =>
  axiosRequestPOST(`/api/content/elements`, { names, area: areaId });

// Element file specific

export const getAllowedMimeTypesService = () =>
  axiosRequestGET('/api/content/mimetypes');

export const getElementFileConversionProgress = (id) =>
  axiosRequestGET(`/api/content/elements/${id}/conversion-progress`);

export const finalizeElementFileUpload = (id, fileName) =>
  axiosRequestPUT(`/api/content/elements/${id}/finalize-upload`, { fileName });

export const deleteElementFileService = (id, fileName) =>
  axiosRequestDELETE(`/api/content/elements/${id}/file`, { fileName });

export const getElementUploadAccess = (id, contentType) =>
  axiosRequestPOST(`/api/content/elements/${id}/upload-access`, {
    contentType,
  });

export const uploadFileToElementService = (host, data, requestOptions) =>
  axiosRequestPOST(
    `${host}${host.endsWith('/') ? '' : '/'}api/upload`,
    data,
    requestOptions
  );
