import React, { PureComponent } from 'react';
import cx from 'classnames';
import ButtonIcon from './ButtonIcon';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';
import Icon from './Icon';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';

class TableCellEditable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      controlValue: props.controlValue,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { controlValue } = this.props;
    if (prevProps.controlValue !== controlValue) {
      this.setState({ controlValue });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onBlurInput = (e) => {
    const { onSubmit } = this.props;
    onSubmit(e.target.value).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  onChangeInput = (e) => {
    this.setState({
      controlValue: e.target.value,
    });
  };

  onChangeSelect = (e) => {
    const { onSubmit } = this.props;
    onSubmit(e.target.value).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  onClickOutside = (event) => {
    const { controlValue, onSubmit } = this.props;
    const { controlValue: newValue, isEditing } = this.state;
    if (
      this.componentRef &&
      !this.componentRef.contains(event.target) &&
      isEditing
    ) {
      if (controlValue !== newValue) {
        onSubmit(newValue).then(() => {
          this.setState({
            isEditing: false,
          });
        });
      } else {
        this.setState({
          isEditing: false,
        });
      }
    }
  };

  onClickToggleEdit = () => {
    const { controlValue } = this.props;
    const { isEditing } = this.state;
    this.setState({
      isEditing: !isEditing,
      controlValue: controlValue,
    });
  };

  onKeyDownInput = (e) => {
    const { controlValue, onSubmit } = this.props;
    const { controlValue: newValue, isEditing } = this.state;
    if (e.key === 'Enter') {
      if (controlValue !== newValue) {
        onSubmit(newValue).then(() => {
          this.setState({
            isEditing: false,
          });
        });
      }
    }
  };

  renderContent = () => {
    const { tooltip, title, subtitle, controlDisabled, contextMenuItems, disabled } = this.props;
    return (
      <button
        disabled={controlDisabled}
        type="button"
        className="table-cell-editable__button"
        onClick={this.onClickToggleEdit}
      >
        <div className="table-cell-editable__left">
          <p className="table-cell-editable__title" title={title}>
            <ConditionalWrap
              condition={tooltip}
              wrap={(children) => (
                <Tooltip tooltip={tooltip}>{children}</Tooltip>
              )}
            >
              <span>{title}</span>
            </ConditionalWrap>
          </p>
          <p className="table-cell-editable__subtitle">{subtitle}</p>
        </div>
        <div className="table-cell-editable__right">
          <Icon
            size="small"
            className="table-cell-editable__icon"
            color="grey"
            kind="pencil"
          ></Icon>
        </div>
        {contextMenuItems && !disabled && (
          <ContextMenu
            fixed
            closeOnSelectItem
            containerElementClass="scrollContainer .ReactVirtualized__Grid__innerScrollContainer"
            triggerClass="table-cell__context-menu-trigger"
          >
            {contextMenuItems.map((item, i) => (
              <ContextMenuItem
                key={i}
                titleText={item.title || item.titleText}
                leftIconKind={item.leftIconKind}
                leftIconColor={item.leftIconColor}
                onClick={item.onClick}
              ></ContextMenuItem>
            ))}
          </ContextMenu>
        )}
      </button>
    );
  };

  renderControl = () => {
    const { controlValue } = this.state;
    const { controlType, selectOptions, onChange, controlProps } = this.props;
    if (controlType === 'input') {
      return (
        <input
          onKeyDown={this.onKeyDownInput}
          onChange={this.onChangeInput}
          value={controlValue}
          autoFocus
          className="table-cell-editable__input"
          type="text"
          {...controlProps}
        />
      );
    }
    if (controlType === 'select') {
      return (
        <select
          value={controlValue}
          onChange={this.onChangeSelect}
          className="table-cell-editable__select"
          name="select"
          id="select"
        >
          {selectOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }
    return null;
  };

  render() {
    const { isEditing } = this.state;
    const {
      tooltip,
      style,
      title,
      subtitle,
      highlightColor,
      controlType,
      controlDisabled,
      onIconClick,
      centerContent,
      titleColor,
      className,
    } = this.props;
    const modifierClasses = {
      'table-cell-editable--center-content': centerContent,
      [`table-cell-editable--highlight-${highlightColor}`]: highlightColor,
      [`table-cell-editable--title-color-${titleColor}`]: titleColor,
      [`table-cell-editable--disabled`]: controlDisabled,
    };
    return (
      <div
        ref={(el) => (this.componentRef = el)}
        className={cx('table-cell-editable', className, modifierClasses)}
        style={style}
      >
        {isEditing || (controlType === 'select' && !controlDisabled)
          ? this.renderControl()
          : this.renderContent()}
      </div>
    );
  }
}

export default TableCellEditable;
