import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { portalRoot: null, el: null };
  }

  componentDidMount() {
    const id = this.props.id || 'portal-root';
    const portalRoot = document.getElementById(id);
    const el = document.createElement('div');
    // Append the element into the DOM on mount. We'll render
    // into the Portal container element (see the HTML tab).
    portalRoot.appendChild(el);
    this.setState({ portalRoot, el });
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    this.state.portalRoot?.removeChild(this.state.el);
  }

  render() {
    const { el } = this.state;
    if (!el) {
      return null;
    }
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      <React.Fragment>{this.props.children}</React.Fragment>,
      // A DOM element
      el
    );
  }
}

export default Portal;
