// Actions
import { MENU_DATA_RECEIVED } from '../actions/menuActions';

const initialState = {
  items: [],
  allowChangeOfChampLinkVisibility: false,
  disableUpload: true,
  adminSiteBaseUrl: '',
};

const rootmenu = (state = initialState, action) => {
  switch (action.type) {
    case MENU_DATA_RECEIVED:
      return {
        ...state,
        items: action.data.menuGuide.items,
        allowChangeOfChampLinkVisibility:
          action.data.menuGuide.allowChangeOfChampLinkVisibility,
        hasExternalUserDirectoryIntegration:
          action.data.menuGuide.hasExternalUserDirectoryIntegration,
        organisationAllowsChampLogin:
          action.data.menuGuide.organisationAllowsChampLogin,
        disableUpload: action.data.menuGuide.disableUpload,
        demoMode: action.data.menuGuide.demoMode,
        adminSiteBaseUrl: action.data.menuGuide.adminSiteBaseUrl,
        timeZone: action.data.menuGuide.timeZone,
        dateFormat: action.data.formatOptions.dateFormat,
        timeFormat: action.data.formatOptions.timeFormat,
      };
    default:
      return state;
  }
};

export default rootmenu;
