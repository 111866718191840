import React from 'react';
import cx from 'classnames';

const Aside = React.forwardRef(
  ({ noScroll, children, wide, isSublevel, footerComponent, hidden, className }, ref) => {
    const modifierClasses = {
      'aside--wide': wide,
      'aside--sublevel': isSublevel,
      'aside--footer': !!footerComponent,
      'aside--no-scroll': noScroll,
      'aside--hidden': hidden,
    };
    if (footerComponent) {
      return (
        <aside ref={ref} className={cx('aside', modifierClasses, className)}>
          <div className="aside__content">{children}</div>
          <div className="aside__footer">{footerComponent}</div>
        </aside>
      );
    }
    return (
      <aside ref={ref} className={cx('aside', modifierClasses, className)}>
        {children}
      </aside>
    );
  }
);

export default Aside;
