import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import React from 'react';

const motion = {
  section: createDomMotionComponent('section'),
};

const Accordion = ({ isOpen, children, className }) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.section
          className={className}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
              transitionEnd: { overflow: 'visible' },
            },
            collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
          }}
          transition={{ duration: .15 }}
        >
          {children}
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export default Accordion;
