import localeLookup from '../config/locale';
import ButtonIcon from './ButtonIcon';
import IconWithCount from './IconWithCount';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectElement } from '../slices/elementsSlice';
import WithEditorActions from './HOC/withEditorActions';
import WithModals from './HOC/withModals';
import cx from 'classnames';
import { compareLocal, sortBy } from '../utils/helpers';

const mapStateToProps = (state, ownProps) => ({
  roles: state.roles,
  element: selectElement(state, ownProps.elementId),
});

class EditorElementIndicators extends Component {
  getConnectedRoleIds = () => {
    const { element } = this.props;
    return Object.keys(element.roleConnections).filter((id) => element.roleConnections[id]);
  };
  getContentButtonIcon = () => {
    const { element } = this.props;
    if (element.files.length > 0) {
      const file = element.files[0];
      if (file.type === 'Image') {
        return 'picture';
      }
      if (file.type === 'Video') {
        return 'camera';
      }
      return 'paperclip';
    }
    return 'document';
  };

  onClickEditConnections = () => {
    const { editorActions, element } = this.props;
    editorActions.showChangeElementConnectionsModal(element.id);
  };

  onClickEditDescription = () => {
    const { element, hasAccessToArea, showModal, editorActions } = this.props;
    if (!hasAccessToArea) {
      showModal('richText', {
        title: element.name,
        description: element.description,
        files: element.files,
        maxWidth: '700px',
        fullWidth: true,
      });
    } else {
      editorActions.showChangeElementDescriptionModal(element.id);
    }
  };

  onClickEditResponsible = () => {
    const { element, areaId, editorActions } = this.props;
    editorActions.showChangeElementResponsibleModal(element.id, areaId);
  };

  render() {
    const { element, roles, showConnectionCounter, hasAccessToArea, size } = this.props;
    const modifierClasses = {
      [`editor-element-indicators--size-${size}`]: size,
    };
    if (!element) return null;
    const { validity, lockedApprover, completionRequirement, description, validPeriod, files } = element;
    const descriptionLength = description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    const connectedRoleIds = this.getConnectedRoleIds();
    const connectionsTooltip = (
      <span>
        {localeLookup('translations.Connected to {0} roles', [connectedRoleIds.length])} <br />
        {sortBy(connectedRoleIds.map((id) => roles[id]?.name), [compareLocal]).map((name) => (
          <>
            {name}
            <br />
          </>
        ))}
      </span>
    );
    return (
      <div className={cx('editor-element-indicators', modifierClasses)}>
        {validity.enabled && <ButtonIcon inline color="grey" size={size} icon="sync" disabled={true} tooltip={`${validity.validPeriod} ${localeLookup('translations.Days until expiration')}`} className="editor-element-indicators__indicator" />}
        {lockedApprover && <ButtonIcon inline icon="lock" color="grey" size={size} disabled={!hasAccessToArea} onClick={this.onClickEditResponsible} tooltip={localeLookup('translations.Only responsible can complete the element')} className="editor-element-indicators__indicator" />}
        {completionRequirement === 'Signature' && <ButtonIcon inline color="grey" size={size} disabled={!hasAccessToArea} onClick={this.onClickEditResponsible} tooltip={localeLookup('translations.Requires signature')} icon="sign" className="editor-element-indicators__indicator" />}
        {(descriptionLength > 0 || files.length > 0) && <ButtonIcon inline onClick={this.onClickEditDescription} size={size} icon={this.getContentButtonIcon()} color="grey" className="editor-element-indicators__indicator" />}
        {showConnectionCounter && (
          <IconWithCount className="editor-element-indicators__indicator" size={size} tooltip={connectionsTooltip} count={connectedRoleIds.length} onClick={hasAccessToArea ? this.onClickEditConnections : undefined} kind="share2" color={connectedRoleIds.length === 0 ? 'red' : 'grey'} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(WithEditorActions(WithModals(EditorElementIndicators)));
