/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import localeLookup from '../../config/locale';
import {
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  NONE_ID,
  PERSON_STATES,
} from '../../constants';

const mapStateToProps = (state, ownProps) => ({
  statePersons: state.persons,
  stateWildcardPersons: state.wildcardPersons,
  stateGroups: state.groups,
  wrappedComponentProps: ownProps,
});

const WithPersonLookup = (WrappedComponent) => {
  function WithPersonLookupComponent(props) {
    const getTooltip = (id) => {
      const { stateWildcardPersons, statePersons, stateGroups } = props;

      if (stateGroups[id]) {
        const group = stateGroups[id];
        if (group.members.length === 0)
          return localeLookup('translations.No persons in group');

        const personNames = group.members.map((id) => {
          const person = statePersons[id];
          if (person.state === PERSON_STATES.ARCHIVED) {
            return `${person.name} (${localeLookup(
              'translations.Archived'
            )}) (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber}` : ''
            })`;
          }
          return `${person.name} (${person.initials}${
            person.employeeNumber ? ` · ${person.employeeNumber}` : ''
          })`;
        });
        return personNames.join('\n');
      }
      if (statePersons[id]) {
        const person = statePersons[id];
        return `${person.initials} ${
          person.employeeNumber ? `· ${person.employeeNumber}` : ''
        }`;
      }
    };

    const getWildcardInitials = (id) => {
      if (id === DELETED_PERSON_ID) return localeLookup('translations.[DP]');
      if (id === DELETED_GROUP_ID) return localeLookup('translations.[DG]');
      if (id === NONE_ID) return localeLookup('translations.[N]');
      return '';
    };

    const isWildcardPerson = (id) => {
      return id.startsWith('00000000');
    };

    const lookupPerson = (id, options = {}) => {
      const { stateWildcardPersons, statePersons, stateGroups } = props;
      const { handleEmptyId, isLocked } = options;
      if (handleEmptyId && id === EMPTY_ID) {
        if (isLocked)
          return {
            id,
            name: localeLookup('translations.Relevant person'),
            email: '',
            initials: '',
            isWildcardPerson: true,
            showError: false,
            suffix: '',
            isGroup: false,
          };
        return {
          id,
          name: localeLookup('translations.Any person'),
          email: '',
          initials: '',
          isWildcardPerson: true,
          showError: false,
          suffix: '',
          isGroup: false, // TODO: is this correct?
        };
      }
      if (stateWildcardPersons[id]) {
        const wildcardPerson = stateWildcardPersons[id];
        return {
          id,
          name: wildcardPerson.name,
          email: '',
          initials: getWildcardInitials(id),
          isWildcardPerson: true,
          showError: id === DELETED_PERSON_ID || id === DELETED_GROUP_ID,
          suffix: '',
          isGroup: false,
        };
      }
      if (stateGroups[id]) {
        const group = stateGroups[id];
        return {
          id,
          name: group.name,
          email: '',
          initials: group.shortName,
          isWildcardPerson: false,
          showError: false,
          tooltip: getTooltip(id),
          suffix: '',
          isGroup: true,
        };
      }
      if (statePersons[id]) {
        const person = statePersons[id];
        return {
          id,
          name: person.name,
          email: person.email,
          initials: person.initials,
          employeeNumber: person.employeeNumber,
          state: person.state,
          isWildcardPerson: false,
          showError: person.state === PERSON_STATES.ARCHIVED,
          isArchived: person.state === PERSON_STATES.ARCHIVED,
          suffix:
            person.state === PERSON_STATES.ARCHIVED
              ? `(${localeLookup('translations.Archived')})`
              : '',
          isGroup: false,
        };
      }
      return null;
    };
    return (
      <WrappedComponent
        lookupPerson={lookupPerson}
        isWildcardPerson={isWildcardPerson}
        {...props.wrappedComponentProps}
      />
    );
  }
  return WithPersonLookupComponent;
};

const withPersonLookup = compose(
  connect(mapStateToProps, null),
  WithPersonLookup
);

export default withPersonLookup;
