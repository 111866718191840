import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, EMPTY_ID } from '../constants';
import { queryAreas } from '../slices/areasSlice';
import { selectActivePersonsSortOrder } from '../slices/personsSlice';
import { insertStringInString } from '../utils/helpers';
import Button from './Button';
import Elements from './editor/Elements';
import Experts from './editor/Experts';
import KnowledgeOwner from './editor/KnowledgeOwner';
import withAccessControl from './HOC/withAccessControl';
import WithEditorActions from './HOC/withEditorActions';
import WithModals from './HOC/withModals';

const mapStateToProps = (state) => {
  const { persons, user } = state;
  return {
    persons,
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    currentUserId: user.employeeId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryAreas }, dispatch);

class KnowledgeEditorContent extends Component {
  getArea = () => {
    const { area, queryAreas } = this.props;
    return queryAreas([area.id]);
  };

  onClickEditExperts = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaExpertsModal({ areaId: area.id });
  };

  onClickEditOwner = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaOwnerModal({
      areaId: area.id,
      selectedValue: area.owner,
    });
  };

  onClickRemoveExpert = (expertId) => {
    const { area, editorActions } = this.props;
    editorActions.updateAreaExperts({
      areaId: area.id,
      expertIds: area.experts.filter((id) => id !== expertId),
    });
  };

  onClickRemoveOwner = () => {
    const { area, editorActions } = this.props;
    editorActions.updateAreaOwner({
      areaId: area.id,
      ownerId: EMPTY_ID,
      showModalOnScopeCancel: false,
    });
  };

  onClickToggleAreaLinkVisiblity = () => {
    const { area, editorActions } = this.props;
    editorActions.showToggleAreaLinkVisiblityModal(area);
  };

  renderHeader = () => {
    const { allowChangeOfChampLinkVisibility, area, isAreaReadOnly } =
      this.props;
    return (
      <div className="editor__header">
        <h1 className="editor__header-title">
          {localeLookup('translations.Content')}
        </h1>
        {allowChangeOfChampLinkVisibility && !isAreaReadOnly ? (
          <Button
            onClick={this.onClickToggleAreaLinkVisiblity}
            kind="shy"
            className="editor__header-button"
            icon={area.champLink.isPublic ? 'unlock' : 'lock'}
          >
            {insertStringInString(area.champLink.link, '-', 3)}
          </Button>
        ) : (
          <p className="editor__header-text">
            {insertStringInString(area.champLink.link, '-', 3)}
          </p>
        )}
      </div>
    );
  };

  renderExpertsSection = () => {
    const { area, persons } = this.props;
    const experts = area.experts.reduce((acc, id) => {
      const person = persons[id];
      if (persons[id]) {
        return [
          ...acc,
          {
            id,
            name: person.name,
          },
        ];
      }
      return acc;
    }, []);
    return (
      <Experts
        experts={experts}
        onAddClick={this.onClickEditExperts}
        onRemoveClick={this.onClickRemoveExpert}
      />
    );
  };

  renderElementsSection = () => {
    const { allowChangeOfChampLinkVisibility, area, isAreaReadOnly } =
      this.props;
    const { knowledgeElements } = area;
    return (
      <Elements
        allowChangeOfChampLinkVisibility={allowChangeOfChampLinkVisibility}
        areaId={area.id}
        readOnly={isAreaReadOnly}
        canDrag={!isAreaReadOnly}
        canCreate={!isAreaReadOnly}
        hideConnectionSwitch
        sortOrder={knowledgeElements}
      />
    );
  };

  render() {
    return (
      <div className="editor">
        {this.renderHeader()}
        {this.renderElementsSection()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(WithEditorActions(withAccessControl(KnowledgeEditorContent))));
