import React from 'react';
import Button from '../../components/Button';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SwitchCheckbox from '../../components/SwitchCheckbox';
import localeLookup from '../../config/locale';

const ElementStatusElementConfigurationFilter = ({ filterState, isDefault, onClickReset, onToggleFilter }) => {
  const { critical, notCritical, requiresSignature, doesNotRequireSignature, multipart, notMultipart, withExpiration, withoutExpiration } = filterState;
  const sections = [
    {
      title: localeLookup('translations.Element type'),
      items: [
        {
          name: localeLookup('translations.Critical'),
          selected: critical,
          stateKey: 'critical',
        },
        {
          name: localeLookup('translations.Not critical'),
          selected: notCritical,
          stateKey: 'notCritical',
        },
      ],
    },
    {
      title: localeLookup('translations.Expiration'),
      items: [
        {
          name: localeLookup('translations.With expiration'),
          selected: withExpiration,
          stateKey: 'withExpiration',
        },
        {
          name: localeLookup('translations.Without expiration'),
          selected: withoutExpiration,
          stateKey: 'withoutExpiration',
        },
      ],
    },
    {
      title: localeLookup('translations.Signature'),
      items: [
        {
          name: localeLookup('translations.Requires signature'),
          selected: requiresSignature,
          stateKey: 'requiresSignature',
        },
        {
          name: localeLookup('translations.Does not require signature'),
          selected: doesNotRequireSignature,
          stateKey: 'doesNotRequireSignature',
        },
      ],
    },
    {
      title: localeLookup('translations.Completion'),
      items: [
        {
          name: localeLookup('translations.Multipart completion'),
          selected: multipart,
          stateKey: 'multipart',
        },
        {
          name: localeLookup('translations.Standard completion'),
          selected: notMultipart,
          stateKey: 'notMultipart',
        },
      ],
    },
  ];
  const renderItem = (item) => (
    <SideNavItem
      small
      element="div"
      key={`setting-${item.name}`}
      disabled={item.disabled}
      showHoverTitle
      title={item.name}
      tooltip={item.tooltip}
      onClick={() => onToggleFilter(item.stateKey)}
      stopRightComponentClickPropagation
      rightComponent={<SwitchCheckbox disabled={item.disabled} onChange={() => onToggleFilter(item.stateKey)} isChecked={item.selected} id={`setting-${item.stateKey}`} />}
    />
  );
  const renderSection = (section, index) => {
    if (section.groups) {
      return (
        <React.Fragment key={index}>
          <SideNavTitle size="small" title={section.title} />
          {section.groups.map((group, index) => (
            <React.Fragment key={index}>
              <SideNavTitle subtitle title={group.groupTitle} />
              {group.items.map(renderItem)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        <SideNavTitle size="small" title={section.title} />
        {section.items.map(renderItem)}
      </React.Fragment>
    );
  };
  return (
    <>
      {sections.map(renderSection)}
      <Button kind="alert" disabled={isDefault} onClick={onClickReset} className="status-table__sidebar-reset-button">
        {localeLookup('translations.Reset')}
      </Button>
    </>
  );
};

export default ElementStatusElementConfigurationFilter;
