import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const getContentAdministrationColumnSettingsService = () =>
  axiosRequestGET('/api/editor/administration');

export const updateContentAdministrationColumnSettingsService = (
  settings = {
    roles: {
      hiddenColumns: [],
      focusedColumn: '',
      showEmptyColumns: true,
    },
    modules: {
      hiddenColumns: [],
      focusedColumn: '',
      showEmptyColumns: true,
    },
    persons: {
      hiddenColumns: [],
      focusedColumn: '',
      showEmptyColumns: true,
    },
    spaces: {
      hiddenColumns: [],
      focusedColumn: '',
      showEmptyColumns: true,
    },
  }
) => axiosRequestPUT('/api/editor/administration', { settings });
