import React from 'react';
import cx from 'classnames';
const InputLabel = ({
  children,
  htmlFor,
  required,
  size = 'md',
  noMargin,
  theme = 'caps',
}) => {
  const modifierClasses = {
    'input-label--required': required,
    'input-label--no-margin': noMargin,
    [`input-label--size-${size}`]: size,
    [`input-label--theme-${theme}`]: theme,
  };
  return (
    <label htmlFor={htmlFor} className={cx('input-label', modifierClasses)}>
      {children}
    </label>
  );
};

export default InputLabel;
