import React from 'react';
import Input from '../formElements/Input';
import cx from 'classnames';
const SideNavFilter = ({ autoFocus, inputRef, onBlur, onChange, placeholder, value, filterActions, className }) => (
  <div className={cx('side-nav-filter', className)}>
    <div className="side-nav-filter__inner">
      <Input autoFocus={autoFocus} ref={inputRef} onBlur={onBlur} onChange={onChange} placeholder={placeholder} value={value} size="small" fullWidth />
      {filterActions && (
        <div className="side-nav-filter__buttons">
          {filterActions.map((action) => (
            <button key={action.title} className="side-nav-filter__button" onClick={action.onClick}>
              {action.title}
            </button>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default SideNavFilter;
