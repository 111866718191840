/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import Badge from '../Badge';
import BoxMessage from '../BoxMessage';
import withPersonLookup from '../HOC/withPersonLookup';
import SignaturePad from '../SignaturePad';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => {
  const { user } = state;
  return { currentUserName: user.name };
};

class SignatureModal extends Component {
  constructor() {
    super();
    this.state = {
      base64signature: '',
      isLoading: false,
    };
  }

  onClickConfirm = () => {
    const { onConfirm } = this.props;
    const { base64signature } = this.state;
    this.setState({ isLoading: true });
    onConfirm(base64signature);
  };

  onEndSignature = (base64signature) => {
    this.setState({
      base64signature,
    });
  };

  render() {
    const {
      title,
      subtitle,
      subject,
      onClose,
      onCancel,
      cancelButtonText,
      confirmButtonText,
      confirmButtonKind,
      onConfirmClick,
      confirmDisabled,
      signee,
      signeeId,
      currentUserName,
      infoText,
      lookupPerson
    } = this.props;
    const { base64signature, isLoading } = this.state;
    const getSigneeName = () => {
      const person = lookupPerson(signeeId);
      if (person) {
        return `${signee} (${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }
      return '';
    };
    return (
      <ModalWrapper className="signature-modal">
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalBody>
          {infoText && (
            <BoxMessage spacing="md" icon="info-circle">
              {infoText}
            </BoxMessage>
          )}
          <SignaturePad
            signee={signee ? getSigneeName() : currentUserName}
            onEndSignature={this.onEndSignature}
          />
        </ModalBody>
        <ModalFooter
          onCancelClick={onCancel || onClose}
          cancelButtonText={cancelButtonText}
          confirmButtonText={
            confirmButtonText || localeLookup('translations.Sign')
          }
          confirmButtonKind={confirmButtonKind}
          onConfirmClick={this.onClickConfirm}
          confirmDisabled={base64signature === '' || isLoading}
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(withPersonLookup(SignatureModal));
