/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { ELEMENT_TYPES } from '../../constants';
import { queryAreas } from '../../slices/areasSlice';
import { queryElements } from '../../slices/elementsSlice';
import { queryRoles } from '../../slices/rolesSlice';
import Button from '../Button';
import ElementAddBox from '../ElementAddBox';
import WithEditorActions from '../HOC/withEditorActions';
import EditorElement from '../EditorElement';
import SwitchCheckbox from '../SwitchCheckbox';
import withAccessControl from '../HOC/withAccessControl';
import CreateElementBox from '../CreateElementBox';

const mapStateToProps = (state) => {
  const { elements } = state;
  return { elements };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryElements, queryRoles, queryAreas }, dispatch);

class Elements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultSortOrder: props.sortOrder || [],
      isAddingElement: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sortOrder } = this.props;
    if (sortOrder !== prevProps.sortOrder) {
      this.setState({
        defaultSortOrder: sortOrder,
      });
    }
  }

  getElementConnectionsCount = () => {
    const { roleId, elements, hideConnectionSwitch, sortOrder } = this.props;
    if (hideConnectionSwitch) return {};
    const elementsConnectedToRole = sortOrder.reduce((acc, id) => {
      const element = elements[id];
      if (element?.roleConnections[roleId]) return acc + 1;
      return acc;
    }, 0);
    if (!elements) return { connected: 0, total: 0 };
    return {
      connected: elementsConnectedToRole,
      total: sortOrder.length,
    };
  };

  onAddElementClick = () => {
    this.setState({ isAddingElement: true });
  };

  onClickToggleConnectAllElements = () => {
    const { roleId, sortOrder, editorActions } = this.props;
    const connectionCount = this.getElementConnectionsCount();
    const areaElementIds = sortOrder;
    if (connectionCount.total === 0) return;
    if (connectionCount.total === connectionCount.connected) {
      editorActions.disconnectElementsFromRoles({
        roleIds: [roleId],
        elementIds: areaElementIds,
      });
    } else {
      editorActions.connectElementsToRoles({
        roleIds: [roleId],
        elementIds: areaElementIds,
      });
    }
  };

  onBlurAddElement = () => {
    this.setState({ isAddingElement: false });
  };

  onSubmitAddElements = (names) => {
    const {
      areaId,
      roleId,
      queryElements,
      queryRoles,
      queryAreas,
      editorActions,
    } = this.props;
    editorActions.createElements({
      names,
      areaId,
      roleId,
      connectToRole: !!roleId,
    });
  };

  onDragEnd = (result) => {
    const { editorActions, areaId } = this.props;
    const { destination, source } = result;
    const isDroppedAtSamePlace =
      destination.droppableId === source.droppableId &&
      destination.index === source.index;
    if (!destination || isDroppedAtSamePlace) return;
    editorActions.changeAreaElementsOrder({
      areaId,
      index: source.index,
      moveToIndex: destination.index,
    });
  };

  render() {
    const {
      areaId,
      allowChangeOfChampLinkVisibility,
      canDrag,
      elements,
      hideConnectionSwitch,
      roleId,
      hasAccessToRole,
      isInRoleContext,
      canCreate,
      readOnly,
    } = this.props;
    const { defaultSortOrder, isAddingElement } = this.state;

    const connectionCount = this.getElementConnectionsCount();
    const hasAccess = hasAccessToRole(roleId);
    return (
      <section className="editor__section">
        <div className="editor__section-header editor__section-header--no-mg">
          <h2 className="editor__section-title">
            {localeLookup('translations.Elements')}
          </h2>
          {!hideConnectionSwitch && defaultSortOrder.length > 0 && (
            <SwitchCheckbox
              disabled={!hasAccess}
              className="editor__section-connect-all"
              labelText={localeLookup('translations.Connect all')}
              htmlFor="connectAll"
              onChange={this.onClickToggleConnectAllElements}
              isChecked={connectionCount.total === connectionCount.connected}
            />
          )}
        </div>
        <DragDropContext nonce={cspNonce} onDragEnd={this.onDragEnd}>
          <Droppable droppableId="elements">
            {(provided) => (
              <div
                className="editor__elements"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {defaultSortOrder.map((id, i) => {
                  if (elements[id]) {
                    const element = elements[id];
                    if (!element) return null;
                    return (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={i}
                        isDragDisabled={!canDrag}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <EditorElement
                              allowChangeOfChampLinkVisibility={
                                allowChangeOfChampLinkVisibility
                              }
                              alternativeResponsible={
                                element.alternativeResponsible
                              }
                              areaId={areaId}
                              canDrag={canDrag}
                              champLink={element.champLink}
                              completionRequirement={
                                element.completionRequirement
                              }
                              completionType={element.completionType}
                              daysValid={element.validity.validPeriod}
                              description={element.description}
                              files={element.files}
                              hideConnectionSwitch={hideConnectionSwitch}
                              id={id}
                              index={i}
                              isConnected={!!element.roleConnections[roleId]}
                              isMediatorLocked={element.isMediatorLocked}
                              isRequired={
                                element.type === ELEMENT_TYPES.CRITICAL
                              }
                              key={id}
                              lockedApprover={element.lockedApprover}
                              responsible={element.responsible}
                              roleId={roleId}
                              title={element.name}
                              readOnly={readOnly}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isAddingElement && (
          <CreateElementBox
            placeholder={localeLookup('translations.Name of knowledge element')}
            onBlur={this.onBlurAddElement}
            onSubmit={this.onSubmitAddElements}
          />
        )}

        {canCreate && (
          <Button
            ref={(text) => {
              this.elementAddTextRef = text;
            }}
            onClick={this.onAddElementClick}
            kind="link-style"
            className="editor__section-button"
          >
            {localeLookup('translations.Add')}...
          </Button>
        )}
      </section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithEditorActions(withAccessControl(Elements)));
