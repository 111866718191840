import { omit as lodashOmit } from 'lodash';
// Services
import {
  fetchPermissions,
  fetchSystemSettings,
  updateOrganisationLanguage,
  updateOrganisationName,
  updatePermission,
  updateSecret,
} from '../services/systemSettingsService';

export const SYSTEM_SETTINGS_DATA_PENDING = 'SYSTEM_SETTINGS_DATA_PENDING';
export const systemSettingsDataPending = () => ({
  type: SYSTEM_SETTINGS_DATA_PENDING,
});

export const SYSTEM_SETTINGS_RECIEVED = 'SYSTEM_SETTINGS_RECIEVED';
export const systemSettingsRecieved = (data) => ({
  type: SYSTEM_SETTINGS_RECIEVED,
  data,
});

export const requestSystemSettings = () => (dispatch) =>
  fetchSystemSettings()
    .then((data) => {
      dispatch(systemSettingsRecieved(data.data));
    })
    .catch(() => {
      console.log('failed fetching persons overview');
    });

export const changeOrganisationName = (name, errCallback) => (dispatch) => {
  return updateOrganisationName({ organisationName: name })
    .then((data) => {
      dispatch(systemSettingsRecieved(data.data));
    })
    .catch(() => {
      errCallback();
    });
};

export const changeOrganisationLanguage =
  (languageCode, errCallback) => (dispatch) => {
    return updateOrganisationLanguage({ language: languageCode })
      .then((data) => {
        window.location.reload();
        window.CHAMP.locale.data.map = data.data.localeData.translations;
        window.CHAMP.locale.data.locale = data.data.localeData.code;
        dispatch(systemSettingsRecieved(lodashOmit(data.data, 'localeData')));
      })
      .catch(() => {
        errCallback();
      });
  };

export const PERMISSIONS_RECEIVED = 'PERMISSIONS_RECEIVED';
export const permissionsReceived = (data) => ({
  type: PERMISSIONS_RECEIVED,
  data,
});

export const requestPermissions = () => (dispatch) =>
  fetchPermissions()
    .then((response) => {
      dispatch(permissionsReceived(response.data));
    })
    .catch(() => {
      console.log('failed fetching permissions');
    });

export const changePermission =
  ({ name, enabled, value = '' }) =>
  (dispatch) => {
    return updatePermission({ name, enabled, value })
      .then((response) => {
        dispatch(permissionsReceived(response.data));
      })
      .catch(() => {});
  };

export const rotateSecret = () => (dispatch) => {
  return updateSecret()
    .then((response) => {
      dispatch(permissionsReceived(response.data));
    })
    .catch(() => {});
};
