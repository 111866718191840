import {
  TASK_LIST_CATEGORIES_RECEIVED,
  TASK_LIST_KNOWLEDGE_AREAS_RECEIVED,
  TASK_LIST_KNOWLEDGE_ELEMENTS_RECEIVED,
  TASK_LIST_ORGANISATION_UNITS_RECEIVED,
  TASK_LIST_PERSON_ELEMENT_STATUS_RECEIVED,
  TASK_LIST_PERSONS_ELEMENT_STATUS_RECEIVED,
  TASK_LIST_PERSONS_RECEIVED,
  TASK_LIST_ROLES_RECEIVED,
  TASK_LIST_WILDCARD_PERSONS_RECEIVED,
} from '../actions/taskListActions';

const initialState = {
  categories: {},
  knowledgeAreas: {},
  knowledgeElements: {},
  organisationUnits: {},
  persons: {},
  roles: {},
  wildcardPersons: {},
};

const taskList = (state = initialState, action) => {
  switch (action.type) {
    case TASK_LIST_CATEGORIES_RECEIVED:
      return {
        ...state,
        categories: action.categories,
      };
    case TASK_LIST_KNOWLEDGE_AREAS_RECEIVED:
      return {
        ...state,
        knowledgeAreas: action.knowledgeAreas,
      };
    case TASK_LIST_KNOWLEDGE_ELEMENTS_RECEIVED:
      return {
        ...state,
        knowledgeElements: action.knowledgeElements,
      };
    case TASK_LIST_ORGANISATION_UNITS_RECEIVED:
      return {
        ...state,
        organisationUnits: action.organisationUnits,
      };
    case TASK_LIST_PERSON_ELEMENT_STATUS_RECEIVED:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.menteeId]: {
            ...state.persons[action.menteeId],
            knowledgeElements: {
              ...state.persons[action.menteeId].knowledgeElements,
              [action.elementId]: {
                ...state.persons[action.menteeId].knowledgeElements[action.elementId],
                isCompleted: action.isCompleted,
                mediator: action.mediator,
                hasExpiration: action.hasExpiration,
                daysUntilExpiration: action.daysUntilExpiration,
                completedAt: action.completedAt,
                showNotification: action.showNotification,
              },
            },
          },
        },
      };
    case TASK_LIST_PERSONS_ELEMENT_STATUS_RECEIVED:
      return {
        ...state,
        persons: addElementStatusToPersons(state.persons, action.persons),
      };
    case TASK_LIST_PERSONS_RECEIVED:
      return {
        ...state,
        persons: addPersonsToState(state.persons, action.persons),
      };
    case TASK_LIST_ROLES_RECEIVED:
      return {
        ...state,
        roles: action.roles,
      };
    case TASK_LIST_WILDCARD_PERSONS_RECEIVED:
      return {
        ...state,
        wildcardPersons: action.wildcardPersons,
      };
    default:
      return state;
  }
};

const addPersonsToState = (statePersons, personsToAdd) =>
  Object.keys(personsToAdd).reduce((acc, id) => {
    if (statePersons[id]) {
      return {
        ...acc,
        [id]: {
          ...statePersons[id],
          ...personsToAdd[id],
        },
      };
    }
    return {
      ...acc,
      [id]: personsToAdd[id],
    };
  }, {});

const addElementStatusToPersons = (statePersons, personsWithElementStatus) =>
  Object.keys(statePersons).reduce((acc, id) => {
    acc[id] = {
      ...statePersons[id],
      ...personsWithElementStatus[id],
    };
    return acc;
  }, {});

export default taskList;
