import React, { PureComponent } from 'react';
import cx from 'classnames';
import ButtonIcon from './ButtonIcon';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';

class TableCell extends PureComponent {
  render() {
    const {
      tooltip,
      style,
      title,
      subtitle,
      highlightColor,
      hoverToShowIcon,
      contextMenuItems,
      children,
      hidden,
      empty,
      icon,
      onIconClick,
      centerContent,
      titleColor,
      className,
      backgroundColor,
      disabled,
      iconSize,
      subtitleTooltip,
    } = this.props;
    const modifierClasses = {
      'table-cell--center-content': centerContent,
      'table-cell--hidden': hidden,
      'table-cell--disabled': disabled,
      'table-cell--hover-icon': hoverToShowIcon,
      [`table-cell--highlight-${highlightColor}`]: highlightColor,
      [`table-cell--title-color-${titleColor}`]: titleColor,
      [`table-cell--background-color-${backgroundColor}`]: backgroundColor,
    };
    if (children || empty) {
      return (
        <ConditionalWrap
          condition={tooltip}
          wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
        >
          <div
            className={cx('table-cell', className, modifierClasses)}
            style={style}
          >
            {children}
          </div>
        </ConditionalWrap>
      );
    }
    return (
      <div
        className={cx('table-cell', className, modifierClasses)}
        style={style}
      >
        <div className="table-cell__content">
          <p
            className="table-cell__title"
            title={typeof title === 'string' ? title : null}
          >
            <ConditionalWrap
              condition={tooltip}
              wrap={(children) => (
                <Tooltip tooltip={tooltip}>{children}</Tooltip>
              )}
            >
              <span>{title}</span>
            </ConditionalWrap>
          </p>
          <p className="table-cell__subtitle">
            <ConditionalWrap
              condition={subtitleTooltip}
              wrap={(children) => (
                <Tooltip tooltip={subtitleTooltip}>{children}</Tooltip>
              )}
            >
              <span>{subtitle}</span>
            </ConditionalWrap>
          </p>
        </div>
        {icon && !disabled && (
          <ButtonIcon
            className="table-cell__icon"
            onClick={onIconClick}
            inline
            icon={icon}
            size={iconSize}
          />
        )}
        {contextMenuItems && !disabled && (
          <ContextMenu
            fixed
            closeOnSelectItem
            containerElementClass="scrollContainer .ReactVirtualized__Grid__innerScrollContainer"
            triggerClass="table-cell__context-menu-trigger"
          >
            {contextMenuItems.map((item, i) => (
              <ContextMenuItem
                key={i}
                titleText={item.title || item.titleText}
                leftIconKind={item.leftIconKind}
                leftIconColor={item.leftIconColor}
                onClick={item.onClick}
              ></ContextMenuItem>
            ))}
          </ContextMenu>
        )}
      </div>
    );
  }
}

export default TableCell;
