import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS } from '../../constants';
import AccessLevelWrapper from '../AccessLevelWrapper';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import OrganisationBoardGoal from './OrganisationBoardGoal';
import ContextMenu from '../contextMenu/ContextMenu';
import ContextMenuItem from '../contextMenu/ContextMenuItem';
import { withRouter } from 'react-router';

class OrganisationBoardKnowledgeArea extends PureComponent {
  constructor() {
    super();
    this.state = {
      isEditingName: false,
    };
  }

  onClickOpenAreaInElementStatus = () => {
    const { history, unitId, knowledgeAreaId, role } = this.props;
    history.push({
      pathname: `/organisation/elementstatus`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
        filterSettings: {
          knowledgeAreas: [knowledgeAreaId],
          organisationUnits: [unitId],
          roles: [role.id],
        },
      },
    });
  };

  onClickOpenAreaInModules = () => {
    const { knowledgeAreaId, history, knowledgeAreas } = this.props;
    history.push({
      pathname: `/editor/knowledge/${knowledgeAreaId}`,
      state: {
        title: knowledgeAreas[knowledgeAreaId]?.name,
        backTitle: localeLookup('translations.Role matrix'),
      },
    });
  };

  onEditNameBlur = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onEditNameClick = () => {
    this.setState({
      isEditingName: true,
    });
  };

  onUpdateGoals = (goals) => {
    const { onChangeGoals, knowledgeAreaId } = this.props;
    return onChangeGoals(knowledgeAreaId, goals);
  };

  onUpdateName = (newName) => {
    const { role, knowledgeAreaId, onUpdateName } = this.props;
    onUpdateName({ roleId: role.id, areaId: knowledgeAreaId, name: newName });
  };

  renderContextMenu = () => {
    const { organisationUnitState } = this.props;
    return (
      <ContextMenu triggerClass="organisation-board-role__name-context-menu-icon">
        <AccessLevelWrapper
          acceptedLevels={[
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
          ]}
        >
          <ContextMenuItem
            onClick={this.onClickOpenAreaInModules}
            leftIconKind="enter-right"
            titleText={localeLookup('translations.Open in knowledge editor')}
          />
        </AccessLevelWrapper>
        {organisationUnitState !== 'Passive' && (
          <ContextMenuItem
            onClick={this.onClickOpenAreaInElementStatus}
            leftIconKind="enter-right"
            titleText={localeLookup('translations.Open in element status')}
          />
        )}
      </ContextMenu>
    );
  };

  render() {
    const { goalCompletionPercentage, goals, knowledgeAreaId, knowledgeAreas } =
      this.props;
    const { isEditingName } = this.state;

    return (
      <div className="organisation-board-knowledge" key={knowledgeAreaId}>
        <div className="organisation-board-knowledge__name-wrapper">
          <div className="organisation-board-knowledge__name">
            {isEditingName ? (
              <InlineFieldEditor
                autoFocus
                classNameInput="organisation-board-knowledge__name-input"
                defaultValue={knowledgeAreas[knowledgeAreaId].name}
                onBlur={this.onEditNameBlur}
                onSubmit={this.onUpdateName}
                placeholder={localeLookup('translations.Name')}
              />
            ) : (
              <p
                className="organisation-board-knowledge__name-text"
                title={knowledgeAreas[knowledgeAreaId]?.name}
              >
                {knowledgeAreas[knowledgeAreaId]?.name}
              </p>
            )}
            {!isEditingName && (
              <AccessLevelWrapper
                acceptedLevels={[
                  ACCESS_LEVELS.champadministrator,
                  ACCESS_LEVELS.administrator,
                ]}
              >
                <Icon
                  onClick={this.onEditNameClick}
                  isButton
                  kind="pencil"
                  className="organisation-board-knowledge__name-edit-icon"
                />
              </AccessLevelWrapper>
            )}
          </div>
          {this.renderContextMenu()}
        </div>
        <OrganisationBoardGoal
          completionPercentage={goalCompletionPercentage}
          goals={goals}
          onUpdateGoals={this.onUpdateGoals}
        />
      </div>
    );
  }
}

export default withRouter(OrganisationBoardKnowledgeArea);
