import React from 'react';

const Textarea = ({ maxLength, placeholder, onChange, value }) => {
  return (
    <div className="textarea">
      <textarea className="textarea__input" maxLength={maxLength} rows={5} value={value} onChange={onChange} placeholder={placeholder}></textarea>
      <span className="textarea__character-count">
        {value.length} / {maxLength}
      </span>
    </div>
  );
};

export default Textarea;
