import React from 'react';
import localeLookup from '../../../config/locale';
import CreateAreaForm from '../../CreateAreaForm';
import FormModal from '../FormModal';
import ModalFooter from '../ModalFooter';

const CreateAreaModal = ({ onClose, persons, allowSpaceSelection, onCreated, initialName }) => (
  <FormModal onClose={onClose} title={localeLookup('translations.Create knowledge area')}>
    <CreateAreaForm
      onCreated={onCreated}
      persons={persons}
      allowSpaceSelection={allowSpaceSelection}
      initialName={initialName}
      renderFooter={({ handleSubmit, canSubmit }) => <ModalFooter confirmButtonText={localeLookup('translations.Create')} confirmDisabled={!canSubmit} onConfirmClick={handleSubmit} onCancelClick={onClose} />}
    />
  </FormModal>
);

export default CreateAreaModal;
