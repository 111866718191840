/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS, ROLE_LEVELS } from '../../constants';
import {
  updateRoleGoalsService,
  updateRoleKnowledgeAreaGoalsService,
} from '../../services/organisationService';
import AccessLevelWrapper from '../AccessLevelWrapper';
import Accordion from '../Accordion';
import ButtonIcon from '../ButtonIcon';
import ContextMenu from '../contextMenu/ContextMenu';
import ContextMenuItem from '../contextMenu/ContextMenuItem';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import NotificationCounter from '../NotificationCounter';
import SwitchCheckbox from '../SwitchCheckbox';
import OrganisationBoardGoal from './OrganisationBoardGoal';
// Components
import OrganisationBoardKnowledgeArea from './OrganisationBoardKnowledgeArea';

class OrganisationBoardRole extends Component {
  constructor() {
    super();
    this.state = {
      isEditingName: false,
    };
  }

  onChangeRoleGoals = (goalsArray) => {
    const { onUpdateGoals, unitId, role } = this.props;
    return updateRoleGoalsService(unitId, role.id, { goals: goalsArray }).then(
      () => {
        onUpdateGoals();
      }
    );
  };

  onChangeKnowledgeAreaGoals = (knowledgeAreaId, goalsArray) => {
    const { role, unitId, onUpdateGoals } = this.props;
    return updateRoleKnowledgeAreaGoalsService(
      unitId,
      role.id,
      knowledgeAreaId,
      {
        goals: goalsArray,
      }
    ).then(() => {
      onUpdateGoals();
    });
  };

  onClickExpand = () => {
    const { onExpand, role } = this.props;
    onExpand({ id: role.id });
  };

  onClickOpenRoleInElementStatus = () => {
    const { history, role, unitId } = this.props;
    history.push({
      pathname: `/organisation/elementstatus`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
        filterSettings: {
          roles: [role.id],
          organisationUnits: [unitId],
        },
      },
    });
  };

  onClickRemoveRoleConnection = () => {
    const { onClickRemoveRoleConnection, role } = this.props;
    onClickRemoveRoleConnection({ roleId: role.id });
  };

  onClickResetFilter = () => {
    const { onClickClearRoleLevelFilter, role } = this.props;
    onClickClearRoleLevelFilter(role.id);
  };

  onDeleteRoleClick = () => {
    const { role, onDeleteRoleClick } = this.props;
    onDeleteRoleClick(role.id);
  };

  onEditNameBlur = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onEditNameClick = (e) => {
    e.stopPropagation();
    this.setState({
      isEditingName: true,
    });
  };

  onOpenRoleInDesignboardClick = () => {
    const { role, history } = this.props;
    history.push({
      pathname: '/editor/designboard',
      state: { rolesToOpen: [role.id] },
    });
  };

  onUpdateRoleName = (newName) => {
    const { onUpdateRoleName, role } = this.props;
    onUpdateRoleName({ roleId: role.id, name: newName });
  };

  renderContextMenu = () => {
    const {
      roleLevelFilters,
      onClickToggleRoleLevelFilter,
      role,
      unitId,
      onClickShowRoleDashboardOverlay,
      isReadOnly,
      organisationUnitState,
    } = this.props;
    if (isReadOnly && !role.canEdit) return null;
    return (
      <ContextMenu triggerClass="organisation-board-role__name-context-menu-icon">
        <ContextMenuItem
          leftIconKind="funnel"
          titleText={localeLookup('translations.Level filter')}
        >
          <SwitchCheckbox
            isChecked={
              roleLevelFilters[role.id]
                ? roleLevelFilters[role.id][ROLE_LEVELS.NONE]
                : true
            }
            onChange={() =>
              onClickToggleRoleLevelFilter({
                roleId: role.id,
                level: ROLE_LEVELS.NONE,
              })
            }
            wrapperClassName="organisation-board-role__filter-switch"
            size="small"
            labelText={localeLookup('translations.Does not have role')}
          />
          <SwitchCheckbox
            isChecked={
              roleLevelFilters[role.id]
                ? roleLevelFilters[role.id][ROLE_LEVELS.TRAINING]
                : true
            }
            onChange={() =>
              onClickToggleRoleLevelFilter({
                roleId: role.id,
                level: ROLE_LEVELS.TRAINING,
              })
            }
            wrapperClassName="organisation-board-role__filter-switch"
            size="small"
            labelText={localeLookup('translations.In training')}
          />
          <SwitchCheckbox
            isChecked={
              roleLevelFilters[role.id]
                ? roleLevelFilters[role.id][ROLE_LEVELS.QUALIFIED]
                : true
            }
            onChange={() =>
              onClickToggleRoleLevelFilter({
                roleId: role.id,
                level: ROLE_LEVELS.QUALIFIED,
              })
            }
            wrapperClassName="organisation-board-role__filter-switch"
            size="small"
            labelText={localeLookup('translations.Qualified')}
          />
          <SwitchCheckbox
            isChecked={
              roleLevelFilters[role.id]
                ? roleLevelFilters[role.id][ROLE_LEVELS.EXPERIENCED]
                : true
            }
            onChange={() =>
              onClickToggleRoleLevelFilter({
                roleId: role.id,
                level: ROLE_LEVELS.EXPERIENCED,
              })
            }
            wrapperClassName="organisation-board-role__filter-switch"
            size="small"
            labelText={localeLookup('translations.Experienced')}
          />
        </ContextMenuItem>
        <AccessLevelWrapper
          acceptedLevels={[
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.teamadministrator,
          ]}
        >
          <ContextMenuItem
            onClick={() =>
              onClickShowRoleDashboardOverlay({ roleId: role.id, unitId })
            }
            leftIconKind="users"
            titleText={localeLookup('translations.Show status')}
          />
        </AccessLevelWrapper>
        <AccessLevelWrapper
          acceptedLevels={[
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
          ]}
        >
          <ContextMenuItem
            onClick={this.onOpenRoleInDesignboardClick}
            leftIconKind="enter-right"
            titleText={localeLookup('translations.Open in designboard')}
          />
        </AccessLevelWrapper>
        {organisationUnitState === 'Active' && (
          <ContextMenuItem
            onClick={this.onClickOpenRoleInElementStatus}
            leftIconKind="enter-right"
            titleText={localeLookup('translations.Open in element status')}
          />
        )}
        <ContextMenuItem
          leftIconColor="red"
          leftIconKind="circle-minus"
          titleText={localeLookup(
            'translations.Remove connection to organisation unit'
          )}
          onClick={this.onClickRemoveRoleConnection}
        />
        {role.canEdit && (
          <ContextMenuItem
            onClick={this.onDeleteRoleClick}
            leftIconClassName="organisation-board-role__name-context-menu-delete-role-icon"
            leftIconKind="trash2"
            titleText={localeLookup('translations.Delete')}
          />
        )}
      </ContextMenu>
    );
  };

  render() {
    const {
      filteredPersons,
      isExpanded,
      knowledgeAreas,
      onUpdateKnowledgeAreaName,
      organisationUnitPersons,
      persons,
      role,
      translatePixels,
      goals,
      roleLevelFilters,
      unitId,
      organisationUnitState
    } = this.props;

    const { isEditingName } = this.state;
    const roleLevelFilter = roleLevelFilters[role.id];
    const activeFilterCount =
      roleLevelFilter &&
      Object.keys(roleLevelFilter).filter((level) => !roleLevelFilter[level])
        .length;
    const totalFilterCount = 4;
    return (
      <>
        <div
          className={`organisation-board-role ${cx({
            'organisation-board-role--has-filter': activeFilterCount > 0,
            'organisation-board-role__name--expanded': isExpanded,
            'organisation-board-role--area-goals-with-gaps':
              goals?.knowledgeAreaGoalsHasGap,
            'organisation-board-role--areas-goals-with-ongoing-gaps':
              !goals?.knowledgeAreaGoalsHasGap &&
              goals?.knowledgeAreaGoalsHasOngoingGap,
          })}`}
        >
          <div className="organisation-board-role__name">
            <div
              className={`organisation-board-role__name-expansion-wrapper ${cx({
                'organisation-board-role__name-expansion-wrapper--expandable':
                  Object.keys(role.additionalKnowledgeAreas).length > 0,
              })}`}
              onClick={this.onClickExpand}
            >
              {isEditingName ? (
                <InlineFieldEditor
                  autoFocus
                  classNameInput="organisation-board-role__name-input"
                  defaultValue={role.name}
                  onBlur={this.onEditNameBlur}
                  onSubmit={this.onUpdateRoleName}
                  placeholder={localeLookup('translations.Name')}
                />
              ) : (
                <p
                  className="organisation-board-role__name-text"
                  title={role.name}
                >
                  {role.name}
                </p>
              )}
              {!isEditingName && role.canEdit && (
                <Icon
                  onClick={this.onEditNameClick}
                  isButton
                  kind="pencil"
                  className="organisation-board-role__name-edit-icon"
                />
              )}
            </div>
            {activeFilterCount > 0 && (
              <NotificationCounter
                className="organisation-board-role__filter-count"
                onClick={this.onClickResetFilter}
                color="grey"
                icon="cross2"
                size="small"
                count={totalFilterCount - activeFilterCount}
              />
            )}
            {Object.keys(role.additionalKnowledgeAreas).length > 0 && (
              <ButtonIcon
                size="xs"
                onClick={this.onClickExpand}
                icon="chevron-down"
                className="organisation-board-role__expand"
              />
            )}
            {this.renderContextMenu()}
          </div>
          <OrganisationBoardGoal
            completionPercentage={goals.competencyGoalsCompletionPercentage}
            goals={goals.goals}
            onUpdateGoals={this.onChangeRoleGoals}
          />
        </div>
        <Accordion isOpen={isExpanded}>
          <div>
            {role.additionalKnowledgeAreas.map((knowledgeAreaId) => {
              return (
                <OrganisationBoardKnowledgeArea
                  onChangeGoals={this.onChangeKnowledgeAreaGoals}
                  filteredPersons={filteredPersons}
                  goals={goals.knowledge[knowledgeAreaId]?.goals}
                  goalCompletionPercentage={
                    goals.knowledge[knowledgeAreaId]
                      ?.competencyGoalsCompletionPercentage
                  }
                  organisationUnitState={organisationUnitState}
                  key={knowledgeAreaId}
                  knowledgeAreaId={knowledgeAreaId}
                  knowledgeAreas={knowledgeAreas}
                  onUpdateName={onUpdateKnowledgeAreaName}
                  organisationUnitPersons={organisationUnitPersons}
                  persons={persons}
                  role={role}
                  unitId={unitId}
                  translatePixels={translatePixels}
                />
              );
            })}
          </div>
        </Accordion>
      </>
    );
  }
}

export default withRouter(OrganisationBoardRole);
