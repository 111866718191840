import React from 'react';
import ReactSelect, { components } from 'react-select';
import cx from 'classnames';
import Icon from './Icon';
import ButtonIcon from './ButtonIcon';
import localeLookup from '../config/locale';
import Checkbox from './Checkbox';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
  key: 'jss-class-prefix-key',
  nonce: cspNonce || '',
});

const OptionWithCheckbox = ({ isSelected, ...props }) => {
  return (
    <components.Option {...props}>
      <Checkbox readOnly isChecked={isSelected}></Checkbox>{' '}
      <label style={{ marginLeft: '.5rem' }}>{props.label}</label>
    </components.Option>
  );
};

export default function MultiSelect({
  overrideComponents = {},
  hideDropdownIndicator = false,
  value,
  closeMenuOnSelect,
  showCheckboxes,
  defaultValue,
  isMulti = true,
  isSearchable = true,
  hideSelectedOptions = true,
  isClearable = true,
  options,
  name,
  className,
  placeholder,
  onChange,
  controlShouldRenderValue = true,
  controlStyles = {},
  filterOption,
  onBlur,
}) {
  const { MultiValue, Placeholder } = overrideComponents;
  return (
    <CacheProvider value={cache}>
      <ReactSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...controlStyles,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
          }),
        }}
        filterOption={filterOption}
        defaultValue={defaultValue}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={onChange}
        value={value}
        name={name}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onBlur={onBlur}
        options={options}
        placeholder={placeholder}
        className={cx('multi-select', className)}
        classNamePrefix="multi-select"
        controlShouldRenderValue={controlShouldRenderValue}
        menuPosition="fixed"
        hideSelectedOptions={hideSelectedOptions}
        components={{
          Option: showCheckboxes ? OptionWithCheckbox : components.Option,
          MultiValue: MultiValue ? MultiValue : components.MultiValue,
          DropdownIndicator: hideDropdownIndicator
            ? null
            : () => (
                <Icon
                  className="multi-select__arrow"
                  kind="chevron-down"
                  color="grey"
                  size="small"
                ></Icon>
              ),
          ClearIndicator: (props) => {
            const {
              getStyles,
              innerProps: { ref, ...restInnerProps },
            } = props;
            return (
              <div
                className="multi-select__cross"
                {...restInnerProps}
                ref={ref}
              >
                <Icon
                  {...props}
                  className="multi-select__cross-icon"
                  kind="cross"
                  color="grey"
                  size="small"
                ></Icon>
              </div>
            );
          },
          Placeholder: Placeholder ? Placeholder : components.Placeholder,
          NoOptionsMessage: () => (
            <p className="multi-select__no-options-message">
              {localeLookup('translations.No options found')}
            </p>
          ),
        }}
      />
    </CacheProvider>
  );
}
