import React from 'react';
import Loader from './Loader';
import cx from 'classnames';

const LoadOverlay = ({ fixed, light }) => {
  const modifierClasses = {
    ['load-overlay--fixed']: fixed,
    ['load-overlay--color-light']: light,
  };
  return (
    <div className={cx('load-overlay', modifierClasses)}>
      <Loader />
    </div>
  );
};

export default LoadOverlay;
