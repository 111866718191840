import React from 'react';
import cx from 'classnames';

const ContextMenuTrigger = ({ className, size, onClick, padding = 'sm' }) => {
  const modifierClasses = {
    [`context-menu__trigger--${size}`]: size,
    [`context-menu__trigger--padding-${padding}`]: padding,
  };
  return (
    <div
      className={cx('context-menu__trigger', modifierClasses, className)}
      onClick={onClick}
    >
      <svg
        className="icon ui-medium context-menu__trigger-icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <circle cx="9.5" cy="3.5" r="2.5" />
        <circle cx="9.5" cy="10.5" r="2.5" />
        <circle cx="9.5" cy="17.5" r="2.5" />
      </svg>
    </div>
  );
};

export default ContextMenuTrigger;
