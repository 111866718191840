import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { PureComponent } from 'react';
import localeLookup from '../config/locale';
import { insertStringInString } from '../utils/helpers';
import Accordion from './Accordion';
import DrawerBox from './DrawerBox';
import EmptyState from './EmptyState';
import Icon from './Icon';
import KnowledgeElement from './KnowledgeElement';
import ListHeader from './ListHeader';
import PersonCard from './PersonCard';
import { TrainingElementLegend } from './TrainingElementLegend';

class KnowledgeArea extends PureComponent {
  onClickShowHistory = (elementId) => {
    const {
      showModal,
      id,
      updateKnowledgeElement,
      knowledgeElements,
      isMenteeContext,
      menteeId,
      loggedInUserId,
      persons,
    } = this.props;
    const element = knowledgeElements[elementId];
    showModal('elementHistory', {
      fullWidth: true,
      maxWidth: '500px',
      cancelButtonText: localeLookup('translations.Close'),
      title: localeLookup('translations.History'),
      subtitle: `${persons[menteeId]?.name} · ${element.name}`,
      elementId,
      areaId: id,
      personId: isMenteeContext ? menteeId : loggedInUserId,
      elementName: element.name,
      onChangeHistory: () => updateKnowledgeElement({ elementId, areaId: id }),
    });
  };

  onUpdateElement = (elementId) => {
    const { updateKnowledgeElement, id } = this.props;
    return updateKnowledgeElement({ elementId, areaId: id });
  };

  renderElement = ({ element, isAdditionalKnowledge, index }) => {
    const {
      currentUserId,
      isMenteeContext,
      showModal,
      loggedInUserId,
      menteeId,
      mentorId,
      experts,
      id,
      knowledgeOwner,
      persons,
      roleId,
      wildcardPersons,
      isReadOnly,
    } = this.props;
    return (
      <KnowledgeElement
        areaId={id}
        currentUserId={currentUserId}
        daysUntilExpiration={element.daysUntilExpiration}
        hasValidity={element.hasValidity}
        description={element.description}
        experts={experts}
        files={element.files}
        hasHistory={element.hasHistory}
        id={element.id}
        isAdditionalKnowledge={isAdditionalKnowledge}
        isNewKnowledge={element.isNewKnowledge}
        isReadOnly={isReadOnly}
        isRequired={element.isRequired}
        isTrainingTask={element.isTrainingTask}
        key={element.id}
        knowledgeOwnerId={knowledgeOwner}
        lockState={element.lockState}
        mediatorId={element.mediator}
        mediatorType={element.mediatorType}
        mentorId={mentorId}
        name={element.name}
        nextAction={element.nextAction}
        onUpdateElement={this.onUpdateElement}
        pending={element.pending}
        personId={isMenteeContext ? menteeId : loggedInUserId}
        persons={persons}
        roleId={roleId}
        showModal={showModal}
        showNotification={!isAdditionalKnowledge && element.showNotification}
        showTopBorder={isAdditionalKnowledge && index === 0}
        status={element.status}
        wildcardPersons={wildcardPersons}
      />
    );
  };

  render() {
    const {
      category,
      experts,
      id,
      isAdditionalArea,
      isMenteeContext,
      knowledgeElements,
      knowledgeElementsSortOrder,
      knowledgeOwner,
      loggedInUserId,
      name,
      notConnectedElementsSortOrder,
      onChangeAreaLinkState,
      onChangeMediatorClick,
      onShowNotConnectedElementsClick,
      persons,
      roleId,
      showNotConnectedElements,
      champLink,
    } = this.props;

    const showEmptyState =
      knowledgeElementsSortOrder?.length === 0 &&
      (!notConnectedElementsSortOrder ||
        notConnectedElementsSortOrder?.length === 0) &&
      experts?.length === 0 &&
      !persons[knowledgeOwner];

    return (
      <div className="knowledge-area">
        <div className="knowledge-area__header">
          <div className="knowledge-area__header-left">
            <h1 className="knowledge-area__header-title">{name}</h1>
            <p className="knowledge-area__header-category">{category}</p>
          </div>
          {champLink && <p>{insertStringInString(champLink, '-', 3)}</p>}
          {isAdditionalArea && (
            <button
              type="button"
              onClick={() => {
                onChangeAreaLinkState(roleId, id, false);
              }}
              className="ui-btn ui-btn-shy"
            >
              <Icon kind="unlink2" />
              <p>
                {isMenteeContext
                  ? localeLookup(
                      'translations.Disconnect from the persons role'
                    )
                  : localeLookup('translations.Disconnect from my role')}
              </p>
            </button>
          )}
        </div>

        {showEmptyState && (
          <EmptyState title={localeLookup('translations.No content')} />
        )}

        {(knowledgeElementsSortOrder?.length > 0 ||
          notConnectedElementsSortOrder?.length > 0) && (
          <section className="knowledge-area__section knowledge-area__section--no-padding">
            <ListHeader title={localeLookup('translations.Elements')} />
            {knowledgeElementsSortOrder?.length === 0 && (
              <p className="knowledge-area__section-more-knowledge-help">
                {localeLookup(
                  'translations.No knowledge elements are connected to this area'
                )}
              </p>
            )}
            {knowledgeElementsSortOrder.map((elementId, index) => {
              const element = knowledgeElements[elementId];
              if (element) {
                return (
                  <DrawerBox
                    disabled={!isMenteeContext}
                    key={elementId}
                    options={[
                      {
                        icon: 'users2',
                        text: localeLookup('translations.Responsible'),
                        onClick: () => {
                          onChangeMediatorClick(id, elementId);
                        },
                      },
                      {
                        icon: 'calendar-check',
                        text: localeLookup('translations.History'),
                        onClick: () => {
                          this.onClickShowHistory(elementId);
                        },
                      },
                    ]}
                  >
                    {this.renderElement({
                      element,
                      isAdditionalKnowledge: false,
                      index,
                    })}
                  </DrawerBox>
                );
              }
            })}
            <TrainingElementLegend />
            {notConnectedElementsSortOrder?.length > 0 && (
              <div
                className={`knowledge-area__section-more-knowledge ${cx({
                  'knowledge-area__section-more-knowledge--expanded':
                    showNotConnectedElements,
                })}`}
              >
                <div className="knowledge-area__section-show-more">
                  <p
                    className="knowledge-area__section-show-more-text"
                    onClick={onShowNotConnectedElementsClick}
                  >
                    {!showNotConnectedElements &&
                      localeLookup('translations.Show more knowledge')}
                    {showNotConnectedElements &&
                      localeLookup('translations.Hide more knowledge')}
                    <Icon
                      className="knowledge-area__section-show-more-icon"
                      kind="chevron-down"
                    />
                  </p>
                </div>
                <Accordion isOpen={showNotConnectedElements}>
                  <div>
                    <p className="knowledge-area__section-more-knowledge-help">
                      {localeLookup(
                        'translations.These knowledge elements are not connected to this role and therefore do not affect the status of the area'
                      )}
                    </p>
                    {notConnectedElementsSortOrder.map((elementId, index) => {
                      const element = knowledgeElements[elementId];
                      if (element) {
                        return this.renderElement({
                          element,
                          isAdditionalKnowledge: true,
                          index,
                        });
                      }
                    })}
                  </div>
                </Accordion>
              </div>
            )}
          </section>
        )}

        {(persons[knowledgeOwner] || experts.length > 0) && (
          <section className="knowledge-area__section knowledge-area__section--no-header-border knowledge-area__section--no-padding">
            <ListHeader
              title={localeLookup('translations.Persons who can help me')}
            />
            {knowledgeOwner && persons[knowledgeOwner] && (
              <PersonCard
                person={persons[knowledgeOwner]}
                subtitle={localeLookup('translations.Owner')}
              />
            )}
            {experts &&
              experts.map((id) => {
                if (persons[id]) {
                  return (
                    <PersonCard
                      key={id}
                      person={persons[id]}
                      subtitle={localeLookup('translations.Expert')}
                    />
                  );
                }
              })}
          </section>
        )}
      </div>
    );
  }
}

export default KnowledgeArea;
