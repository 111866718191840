// Services
import { changeNameService, fetchUserSettings, updateLocale, updatePassword } from '../services/userProfileService';
import { isNothing } from '../utils/fn';

export const USER_SETTINGS_RECIEVED = 'PUSER_SETTINGS_RECIEVED';
export const userSettingsRecieved = (data) => ({
  type: USER_SETTINGS_RECIEVED,
  data,
});

export const requestUserSettings = () => (dispatch) => {
  return fetchUserSettings()
    .then((data) => {
      dispatch(userSettingsRecieved(data.data));
    })
    .catch(() => {
      console.log('failed fetching user settings');
    });
};

export const changeName = (name, errCallback) => (dispatch) => {
  changeNameService({
    name,
  })
    .then(({ data }) => {
      dispatch({ type: 'USER_NAME_CHANGED', name });
      dispatch(userSettingsRecieved(data));
    })
    .catch(() => {
      errCallback();
    });
};

export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
export const setChangePasswordError = (errMessage) => ({
  type: SET_CHANGE_PASSWORD_ERROR,
  errMessage,
});

export const changeLocale = (locale) => (dispatch) => {
  return updateLocale({
    locale,
  })
    .then((data) => {
      window.CHAMP.locale.data.map = data.data.localeData.translations;
      window.CHAMP.locale.data.locale = data.data.localeData.code;
      dispatch(userSettingsRecieved(data.data));
    })
    .catch(() => {
      console.log('failed updating language');
    });
};

export const changePassword = (currentPassword, newPassword, newRepeatedPassword) => (dispatch) =>
  updatePassword({
    currentPassword,
    password: newPassword,
    repeatedPassword: newRepeatedPassword,
  })
    .then(() => {
      dispatch(setChangePasswordError(null));
    })
    .catch((ex) => {
      dispatch(setChangePasswordError(ex.response.data.message));
      throw new Error('failed updating lastname');
    });
