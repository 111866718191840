import React from 'react';
import cx from 'classnames';

const OrganisationStatusboxProgressBar = ({ completionPercentage, color = 'green' }) => {
  const classes = cx('organisation-statusbox-progress', {
    [`organisation-statusbox-progress--color-${color}`]: color,
  });
  return (
    <div className={classes}>
      <div
        className="organisation-statusbox-progress__bar"
        style={{ width: `${completionPercentage}%` }}
      />
    </div>
  );
};

export default OrganisationStatusboxProgressBar;
