import React from 'react';
import Icon from './Icon';
import cx from 'classnames';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';

export default function IconWithCount({ className, kind, color, size, count, tooltip, onClick }) {
  const modifierClasses = { [`icon-with-count--color-${color}`]: color, [`icon-with-count--size-${size}`]: size, 'icon-with-count--type-button': onClick };
  const sizeInterpolator = () => {
    if (size === 'sm') return 'small';
    if (size === 'xs') return 'x-small';
  };
  if (onClick) {
    return (
      <ConditionalWrap condition={tooltip} wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}>
        <button onClick={onClick} className={cx('icon-with-count', modifierClasses, className)}>
          <span className="icon-with-count__count">{count}</span>
          <Icon className="icon-with-count__icon" kind={kind} color={color} size={sizeInterpolator()}></Icon>
        </button>
      </ConditionalWrap>
    );
  }
  return (
    <ConditionalWrap condition={tooltip} wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}>
      <div className={cx('icon-with-count', modifierClasses, className)}>
        <span className="icon-with-count__count">{count}</span>
        <Icon className="icon-with-count__icon" kind={kind} color={color} size={sizeInterpolator()}></Icon>
      </div>
    </ConditionalWrap>
  );
}
