import React from 'react';
import Image from './mediaElements/Image';
import Video from './mediaElements/Video';
import Document from './mediaElements/Document';
import Icon from './Icon';
import ConfirmButton from './ConfirmButton';
import { AUTH_ENDPOINT } from '../constants';

const FileHandler = ({ file, onDeleteFile, onGetFilesProgress, canDelete = true }) => {
  const renderFile = () => {
    const jsonToken = sessionStorage.getItem(`oidc.user:${AUTH_ENDPOINT()}:champ`);
    const token = JSON.parse(jsonToken)?.access_token;
    const urlWithAccessToken = `${file.url}${file.url.includes('?') ? '&' : '?'}access_token=${token}`;
    if (file.type === 'Image') {
      return <Image url={urlWithAccessToken} />;
    } else if (file.type === 'Video') {
      return <Video fileName={file.fileName} processPercentage={file.progress} processMinutesRemaining={file.minutesRemaining} onCancelProcess={onDeleteFile} url={urlWithAccessToken} isProcessing={file.state === 'Preparing'} onGetProgress={onGetFilesProgress} />;
    }
    return (
      <Document
        canDelete={canDelete}
        fileName={file.fileName}
        url={urlWithAccessToken}
        onDelete={() => {
          onDeleteFile({ fileName: file.fileName });
        }}
      />
    );
  };

  return (
    <div className="file-handler">
      {(file.type === 'Image' || file.type === 'Video') && onDeleteFile && canDelete && file.state !== 'Preparing' && (
        <ConfirmButton
          className="file-handler__delete-confirmation"
          onConfirm={() => {
            onDeleteFile({ fileName: file.fileName });
          }}
          render={(onClick, isActive) => (
            <button className="file-handler__delete" onClick={onClick}>
              <Icon kind="trash2" />
            </button>
          )}
        />
      )}
      {renderFile()}
    </div>
  );
};

export default FileHandler;
