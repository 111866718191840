import { axiosRequestGET, axiosRequestPUT } from '../services/serviceConfig';

export const getMentorRolesService = () => axiosRequestGET('/api/mentor/roles');

export const getMenteeRoleService = (menteeId, roleId) => axiosRequestGET(`/api/mentor/${menteeId}/roles/${roleId}`);

export const getAreaService = (menteeId, roleId, knowledgeAreaId) => axiosRequestGET(`/api/mentor/${menteeId}/roles/${roleId}/${knowledgeAreaId}`);

export const updateMenteeAreaLinkStateService = (menteeId, roleId, knowledgeAreaId, updateData) => {
  const url = `/api/mentor/${menteeId}/roles/${roleId}/${knowledgeAreaId}/update/link`;
  return axiosRequestPUT(url, updateData);
};

export const updateMenteeAreaStatusService = (menteeId, roleId, knowledgeAreaId, updateData) => {
  const url = `/api/mentor/${menteeId}/roles/${roleId}/${knowledgeAreaId}/update`;
  return axiosRequestPUT(url, updateData);
};

export const updateMenteeElementMediatorService = (menteeId, roleId, knowledgeAreaId, knowledgeElementId, updateData) =>
  axiosRequestPUT(`/api/mentor/${menteeId}/roles/${roleId}/${knowledgeAreaId}/${knowledgeElementId}/changemediator`, updateData);

export const updateMenteesElementMediatorsService = (updateData) => axiosRequestPUT('/api/mentor/changemediators', updateData);

export const updateMenteesElementStatusesService = (updateData) => axiosRequestPUT('/api/mentor/changeStatuses', updateData);
