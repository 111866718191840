// Services
import {
  duplicateOrganisationUnitService,
  fetchOrganisationOverviewService,
  getOrganisationUnitPersonService,
  getOrganisationUnitPersonsService,
  getOrganisationUnitService,
  getOrganisationUnitsService,
  toggleOrganisationUnitSelectionService,
  updateCompletionStateService,
  updateOrganisationUnitStateService,
} from '../services/organisationService';

export const INITIAL_ORGANISATION_OVERVIEW_RECEIVED =
  'INITIAL_ORGANISATION_OVERVIEW_RECEIVED';
export const initialOrganisationOverviewReceived = (data) => ({
  type: INITIAL_ORGANISATION_OVERVIEW_RECEIVED,
  data,
});

export const ORGANISATION_OVERVIEW_RECEIVED = 'ORGANISATION_OVERVIEW_RECEIVED';
export const organisationOverviewReceived = (data) => ({
  type: ORGANISATION_OVERVIEW_RECEIVED,
  data,
});

export const ORGANISATION_UNITS_DATA_RECEIVED =
  'ORGANISATION_UNITS_DATA_RECEIVED';
export const organisationUnitsDataReceived = (data) => ({
  type: ORGANISATION_UNITS_DATA_RECEIVED,
  data,
});

export const UPDATED_ORGANISATION_UNIT_DATA_RECEIVED =
  'UPDATED_ORGANISATION_UNIT_DATA_RECEIVED';
export const updatedOrganisationUnitDataReceived = (data) => ({
  type: UPDATED_ORGANISATION_UNIT_DATA_RECEIVED,
  data,
});

export const CREATED_ROLE_DATA_RECEIVED = 'CREATED_ROLE_DATA_RECEIVED';
export const createdRoleDataReceived = (data) => ({
  type: CREATED_ROLE_DATA_RECEIVED,
  data,
});

export const UPDATED_ROLE_PERSON_DATA_RECEIVED =
  'UPDATED_ROLE_PERSON_DATA_RECEIVED';
export const updatedRolePersonDataReceived = (data) => ({
  type: UPDATED_ROLE_PERSON_DATA_RECEIVED,
  data,
});

export const UPDATED_ROLE_PERSONS_DATA_RECEIVED =
  'UPDATED_ROLE_PERSONS_DATA_RECEIVED';
export const updatedRolePersonsDataReceived = (data) => ({
  type: UPDATED_ROLE_PERSONS_DATA_RECEIVED,
  data,
});

export const CREATED_ORGANISATION_PERSON_DATA_RECEIVED =
  'CREATED_ORGANISATION_PERSON_DATA_RECEIVED';
export const createdOrganisationPersonDataReceived = (data) => ({
  type: CREATED_ORGANISATION_PERSON_DATA_RECEIVED,
  data,
});

export const COMPLETION_STATE_UPDATED = 'COMPLETION_STATE_UPDATED';
export const comletionStateUpdated = (completionState) => ({
  type: COMPLETION_STATE_UPDATED,
  completionState,
});

export const requestOrganisationOverview = (resetState) => (dispatch) =>
  fetchOrganisationOverviewService()
    .then((data) => {
      if (resetState) {
        dispatch(initialOrganisationOverviewReceived(data.data));
      } else {
        dispatch(organisationOverviewReceived(data.data));
      }
      return data.data;
    })
    .catch(() => {
      console.log('Failed getting organisation overview');
    });

export const getOrganisationUnitPerson =
  ({ personId }) =>
  (dispatch) =>
    getOrganisationUnitPersonService(personId)
      .then(({ data }) => {
        dispatch(updatedRolePersonDataReceived(data));
      })
      .catch((ex) => {
        console.log(ex);
      });

export const getOrganisationUnitMultiplePersons = (personIds) => (dispatch) =>
  getOrganisationUnitPersonsService(personIds)
    .then(({ data }) => {
      dispatch(updatedRolePersonsDataReceived(data));
    })
    .catch((ex) => {
      console.log(ex);
    });

export const getOrganisationUnit = (id) => (dispatch) =>
  getOrganisationUnitService(id)
    .then(({ data }) => {
      dispatch(updatedOrganisationUnitDataReceived(data));
    })
    .catch((ex) => console.log(ex));

export const getOrganisationsUnits =
  ({ unitIds }) =>
  (dispatch) =>
    getOrganisationUnitsService({ organisationUnits: unitIds })
      .then(({ data }) => {
        dispatch(organisationUnitsDataReceived(data));
      })
      .catch((ex) => console.log(ex));

export const updateCompletionState = (completionState) => (dispatch) => {
  return updateCompletionStateService(completionState).then(() => {
    dispatch(comletionStateUpdated(completionState));
  });
};

export const duplicateOrganisationUnit =
  ({ unitId, name, include }) =>
  (dispatch) => {
    return duplicateOrganisationUnitService(unitId, name, include).then(
      async ({ data }) => {
        await toggleOrganisationUnitSelectionService({
          organisationUnitId: data.newOrganisationUnitId,
        });
        await dispatch(requestOrganisationOverview());
        return dispatch(getOrganisationUnit(data.newOrganisationUnitId));
      }
    );
  };

export const updateOrgnisationUnitState =
  ({ unitId, state }) =>
  (dispatch) => {
    return updateOrganisationUnitStateService(unitId, state).then(
      ({ data }) => {
        return dispatch(requestOrganisationOverview(false));
        //return dispatch(getOrganisationUnit(unitId));
      }
    );
  };
