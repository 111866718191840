/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import {
  createCategoryService,
  deleteCategoryService,
  updateCategoryNameService,
} from '../../services/contentService';
import {
  getAllCategories,
  removeCategory,
  selectCategoriesSortOrder,
} from '../../slices/categoriesSlice';
import ButtonIcon from '../ButtonIcon';
import Input from '../formElements/Input';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => {
  const { categories, areas } = state;
  return {
    categories,
    categoriesSortOrder: selectCategoriesSortOrder(state),
    areas,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllCategories,
      removeCategory,
    },
    dispatch
  ),
});

class EditCategoriesModal extends Component {
  constructor() {
    super();
    this.state = {
      isAddingCategory: false,
      addCategoryName: '',
      editingNameId: null,
      filterString: '',
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    const { getAllCategories } = this.props;
    getAllCategories();
  };

  getAreaCount = (categoryId) => {
    const { areas } = this.props;
    return Object.values(areas).reduce((acc, area) => {
      if (area.category === categoryId) return acc + 1;
      return acc;
    }, 0);
  };

  onChangeAddCategoryInput = (e) => {
    this.setState({
      addCategoryName: e.target.value,
    });
  };

  onSubmitAddCategory = (e) => {
    const { addCategoryName } = this.state;
    e.preventDefault();
    this.setState({ isAddingCategory: false, addCategoryName: '' });
    createCategoryService(addCategoryName).then(this.getCategories);
  };

  onBlurAddCategory = () => {
    this.setState({
      isAddingCategory: false,
      addCategoryName: '',
    });
  };

  onClickAddCategory = () => {
    this.setState({
      isAddingCategory: true,
    });
  };

  onClickDeleteCategory = (id) => {
    const { showModal, categories, removeCategory } = this.props;
    showModal('confirmation', {
      title: `${localeLookup('translations.Are you sure you want to delete')} ${
        categories[id]
      }?`,
      maxWidth: '500px',
      body: (
        <>
          <p className="confirmation-modal__body-text">
            {localeLookup(
              'translations.Deletion of this category is permanent'
            )}
          </p>
          <p className="confirmation-modal__body-text">
            {localeLookup(
              'translations.The underlying knowledge areas will not be deleted, but will no longer have a category'
            )}
          </p>
        </>
      ),
      confirmButtonText: localeLookup('translations.Delete'),
      confirmButtonType: 'alert',
      onCancel: () => {
        showModal('editCategories');
      },
      onConfirm: () => {
        deleteCategoryService(id).then(() => {
          removeCategory(id);
          showModal('editCategories');
        });
      },
    });
  };

  onSubmitRenameCategory = (id, newName) => {
    this.setState({ editingNameId: null });
    updateCategoryNameService(id, newName).then(this.getCategories);
  };

  onBlurRenameCategory = () => {
    this.setState({
      editingNameId: null,
    });
  };

  onClickRenameCategory = (id) => {
    this.setState({
      editingNameId: id,
    });
  };

  onChangeFilter = (e) => {
    this.setState({
      filterString: e.target.value,
      addCategoryName: e.target.value,
    });
  };

  render() {
    const { filterString, isAddingCategory, addCategoryName, editingNameId } =
      this.state;
    const { categories, categoriesSortOrder, onDeleteCategory, onClose } =
      this.props;
    const visibleCategoriesKeys = categoriesSortOrder.filter((key) => {
      const category = categories[key];
      return category.toLowerCase().startsWith(filterString.toLowerCase());
    });

    return (
      <ModalWrapper className="modal-content">
        <ModalHeader
          title={localeLookup('translations.Edit categories')}
          onClose={onClose}
        />
        <ModalFilter
          placeholder={`${localeLookup('translations.Search for category')}...`}
          onChange={this.onChangeFilter}
          value={filterString}
          onButtonClick={this.onClickAddCategory}
          buttonIcon="plus-circle"
          buttonText={localeLookup('translations.Create category')}
          showButton
        />
        <ModalBody deep>
          <ul className="box-list">
            {isAddingCategory && (
              <li className="box-list__item">
                <div className="box-list__item-info">
                  <Input
                    autoFocus
                    kind="invisible"
                    fullWidth
                    placeholder={localeLookup('translations.Name of category')}
                    defaultValue={filterString}
                    onChange={this.onChangeAddCategoryInput}
                    onBlur={this.onBlurAddCategory}
                    onEnterPress={(e) => {
                      this.onSubmitAddCategory(e);
                    }}
                  />
                </div>
                <button
                  type="button"
                  onMouseDown={this.onSubmitAddCategory}
                  className={`ui-btn ui-btn-${
                    addCategoryName.length ? 'darkui' : 'inactive'
                  } ui-small`}
                >
                  {localeLookup('translations.Save')}
                </button>
              </li>
            )}
            {categoriesSortOrder &&
              visibleCategoriesKeys.map((id) => (
                <li key={id} className="box-list__item">
                  <div className="box-list__item-info">
                    {editingNameId === id ? (
                      <InlineFieldEditor
                        autoFocus
                        classNameInput="ui-input-invisible"
                        defaultValue={categories[id]}
                        onBlur={this.onBlurRenameCategory}
                        onSubmit={(newName) => {
                          this.onSubmitRenameCategory(id, newName);
                        }}
                        placeholder={localeLookup(
                          'translations.Name of category'
                        )}
                      />
                    ) : (
                      <p
                        className="box-list__item-title"
                        onClick={() => {
                          this.onClickRenameCategory(id);
                        }}
                      >
                        {categories[id]}
                        <Icon
                          kind="pencil"
                          className="box-list__item-title-icon"
                        />
                      </p>
                    )}
                    <p className="box-list__item-subtitle">
                      {this.getAreaCount(id)}{' '}
                      {this.getAreaCount(id) === 1
                        ? localeLookup('translations.knowledge area')
                        : localeLookup('translations.knowledge areas')}
                    </p>
                  </div>
                  <ButtonIcon
                    inline
                    icon="trash2"
                    onClick={() => {
                      this.onClickDeleteCategory(id);
                    }}
                  />
                </li>
              ))}
          </ul>
          {visibleCategoriesKeys.length === 0 && (
            <div className="modal-content__body-info-message">
              <p>{localeLookup('translations.No categories matches')}</p>
              <p>'{filterString}'</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter
          cancelButtonText={localeLookup('translations.Close')}
          onCancelClick={onClose}
          cancelButtonKind="darkui"
        />
      </ModalWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategoriesModal);
