import React, { PureComponent } from 'react';
import cx from 'classnames';
import Icon from './Icon';
import ButtonIcon from './ButtonIcon';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';
import Tooltip from './Tooltip';

class TableHeaderCell extends PureComponent {
  render() {
    const {
      style,
      title,
      subtitle,
      children,
      sortDirection,
      sortActive,
      centerContent,
      onClick,
      sortable,
      className,
      rotated,
      alignEnd,
      hidden,
      contextMenuItems,
      tooltip,
    } = this.props;
    const modifierClasses = {
      [`table-header-cell--state-sort-${sortDirection}`]: sortDirection,
      'table-header-cell--center-content': centerContent,
      'table-header-cell--state-sort-active': sortActive,
      'table-header-cell--sortable': sortable,
      'table-header-cell--rotated': rotated,
      'table-header-cell--align-end': alignEnd,
      'table-header-cell--hidden': hidden,
    };
    if (children) {
      return (
        <div
          onClick={onClick}
          className={cx('table-header-cell', modifierClasses, className)}
          style={style}
        >
          {children}
        </div>
      );
    }
    return (
      <div
        onClick={onClick}
        className={cx('table-header-cell', modifierClasses, className)}
        style={style}
      >
        <div className="table-header-cell__content">
          <div className="table-header-cell__content-left">
            {title && (
              <p className="table-header-cell__title">
                <span>{title}</span>
                {tooltip && (
                  <Tooltip tooltip={tooltip}>
                    <span className="table-header-cell__title-tooltip">
                      <Icon
                        color="grey"
                        size="x-small"
                        kind="info-circle"
                      ></Icon>
                    </span>
                  </Tooltip>
                )}
              </p>
            )}
            {subtitle && (
              <p className="table-header-cell__subtitle">{subtitle}</p>
            )}
          </div>
          <div className="table-header-cell__content-right"></div>
        </div>
        {sortable && (
          <Icon
            className="table-header-cell__icon"
            kind="chevron-down"
            size="small"
          />
        )}{' '}
        {contextMenuItems?.length > 0 && (
          <ContextMenu
            closeOnSelectItem
            fixed
            className="table-header-cell__context-menu"
            triggerClass="table-header-cell__context-menu-trigger"
          >
            {contextMenuItems.map((item, i) => {
              return (
                <ContextMenuItem
                  key={i}
                  leftIconKind={item.icon}
                  titleText={item.title}
                  onClick={item.onClick}
                ></ContextMenuItem>
              );
            })}
          </ContextMenu>
        )}
      </div>
    );
  }
}

export default TableHeaderCell;
