import {
  CREATED_ORGANISATION_PERSON_DATA_RECEIVED,
  CREATED_ROLE_DATA_RECEIVED,
  UPDATED_ORGANISATION_UNIT_DATA_RECEIVED,
  ORGANISATION_OVERVIEW_RECEIVED,
  COMPLETION_STATE_UPDATED,
  UPDATED_ROLE_PERSON_DATA_RECEIVED,
  ORGANISATION_UNITS_DATA_RECEIVED,
  INITIAL_ORGANISATION_OVERVIEW_RECEIVED,
  UPDATED_ROLE_PERSONS_DATA_RECEIVED,
} from '../actions/organisationActions';
import { REMOVE_ROLE } from '../slices/rolesSlice';
import { compareLocal, sortBy } from '../utils/helpers';

const organisationInitialState = {
  categories: {},
  categoriesSortOrder: [],
  knowledgeAreas: {},
  organisationUnitRootNodes: [],
  organisationUnits: {},
  organisationUnitsExpanded: [],
  organisationUnitSortOrder: [],
  persons: {},
  personsSortOrder: [],
  roles: {},
  selectedOrganisationUnits: [],
};

const addPersonsToState = (stateElements, newElements) => {
  if (!newElements) return stateElements;

  const elementObj = Object.keys(newElements).reduce((obj, key) => {
    if (stateElements[key]) {
      obj[key] = {
        ...stateElements[key],
        ...newElements[key],
        roles: {
          ...stateElements[key].roles,
          ...newElements[key].roles,
        },
      };
    } else {
      obj[key] = newElements[key];
    }
    return obj;
  }, {});
  return {
    ...stateElements,
    ...elementObj,
  };
};

const addElementsToState = (stateElements, newElements) => {
  if (!newElements) return stateElements;

  const elementObj = Object.keys(newElements).reduce((obj, key) => {
    if (stateElements[key]) {
      obj[key] = {
        ...stateElements[key],
        ...newElements[key],
      };
    } else {
      obj[key] = newElements[key];
    }
    return obj;
  }, {});
  return {
    ...stateElements,
    ...elementObj,
  };
};

const organisation = (state = organisationInitialState, action) => {
  switch (action.type) {
    case INITIAL_ORGANISATION_OVERVIEW_RECEIVED:
      return {
        ...state,
        organisationUnitRootNodes: action.data.organisationUnitRootNodes,
        organisationUnitSortOrder: action.data.organisationUnitSortOrder,
        organisationUnits: action.data.organisationUnits,
        organisationUnitsExpanded: action.data.organisationUnitsExpanded,
        roles: action.data.roles,
        persons: action.data.persons,
        selectedOrganisationUnits: action.data.selectedOrganisationUnits,
        showCompletion: action.data.showCompletion,
        completionState: action.data.completionState,
        isReadOnly: action.data.isReadOnly,
        hasOverviewLoaded: true,
      };
    case ORGANISATION_OVERVIEW_RECEIVED:
      return {
        ...state,
        organisationUnitRootNodes: action.data.organisationUnitRootNodes,
        organisationUnitSortOrder: action.data.organisationUnitSortOrder,
        organisationUnits: addElementsToState(
          state.organisationUnits,
          action.data.organisationUnits
        ),
        organisationUnitsExpanded: action.data.organisationUnitsExpanded,
        roles: addElementsToState(state.roles, action.data.roles),
        persons: addElementsToState(state.persons, action.data.persons),
        selectedOrganisationUnits: action.data.selectedOrganisationUnits,
        showCompletion: action.data.showCompletion,
        completionState: action.data.completionState,
        isReadOnly: action.data.isReadOnly,
        hasOverviewLoaded: true,
      };

    case ORGANISATION_UNITS_DATA_RECEIVED:
      return {
        ...state,

        knowledgeAreas: addElementsToState(
          state.knowledgeAreas,
          action.data.knowledgeAreas
        ),
        organisationUnits: addElementsToState(
          state.organisationUnits,
          action.data.organisationUnits
        ),
        persons: addElementsToState(state.persons, action.data.persons),
        roles: addElementsToState(state.roles, action.data.roles),
      };
    case REMOVE_ROLE:
      const { [action.id]: value, ...withoutRole } = state.roles;
      return {
        ...state,
        roles: withoutRole,
      };

    case COMPLETION_STATE_UPDATED:
      return {
        ...state,
        completionState: action.completionState,
      };

    case CREATED_ROLE_DATA_RECEIVED:
      return {
        ...state,
        organisationUnits: addElementsToState(
          state.organisationUnits,
          action.data.organisationUnits
        ),
        roles: addElementsToState(state.roles, action.data.roles),
      };

    case UPDATED_ORGANISATION_UNIT_DATA_RECEIVED:
      return {
        ...state,
        knowledgeAreas: addElementsToState(
          state.knowledgeAreas,
          action.data.knowledgeAreas
        ),
        organisationUnits: {
          ...state.organisationUnits,
          ...action.data.organisationUnits,
        },
        roles: {
          ...state.roles,
          ...action.data.roles,
        },
        persons: addPersonsToState(state.persons, action.data.persons),
      };
    case UPDATED_ROLE_PERSON_DATA_RECEIVED:
      return {
        ...state,
        organisationUnits: {
          ...state.organisationUnits,
          ...action.data.organisationUnits,
        },
        persons: {
          ...state.persons,
          [action.data.person.id]: {
            ...state.persons[action.data.person.id],
            ...action.data.person,
          },
        },
      };
    case UPDATED_ROLE_PERSONS_DATA_RECEIVED:
      return {
        ...state,
        persons: addElementsToState(state.persons, action.data.persons),
      };

    case CREATED_ORGANISATION_PERSON_DATA_RECEIVED:
      return {
        ...state,
        persons: addElementsToState(state.persons, action.data.persons),
      };
    default:
      return state;
  }
};

export const personSortOrderSelector = (state) => {
  return sortBy(Object.keys(state.organisation.persons), [
    (a, b) => {
      return compareLocal(
        state.organisation.persons[a].initials,
        state.organisation.persons[b].initials
      );
    },
  ]);
};

export default organisation;
