import React from 'react';
import localeLookup from '../../config/locale';
import ChangeTrainingSessionDatesForm from '../ChangeTrainingSessionDatesForm';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';

const ChangeTrainingSessionDatesModal = ({
  onClose,
  onChanged,
  initialValues,
  subtitle,
  id,
}) => (
  <FormModal
    onClose={onClose}
    title={localeLookup('translations.Change date and time')}
    subtitle={subtitle}
  >
    <ChangeTrainingSessionDatesForm
      onChanged={onChanged}
      id={id}
      initialValues={initialValues}
      renderFooter={({ handleSubmit, canSubmit }) => (
        <ModalFooter
          confirmButtonText={localeLookup('translations.Confirm')}
          confirmDisabled={!canSubmit}
          onConfirmClick={handleSubmit}
          onCancelClick={onClose}
        />
      )}
    />
  </FormModal>
);

export default ChangeTrainingSessionDatesModal;
