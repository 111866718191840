import { withAuth, hasAuthParams } from 'react-oidc-context';
import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createBrowserHistory } from 'history';
import { getAuthUserData, requestMenuData } from '../actions/menuActions';
import {
  systemHideContextMenu,
  toggleMenu,
  updateAllowedMimeTypes,
} from '../actions/systemActions';
import ErrorBoundary from '../components/ErrorBoundary';
import WithModals from '../components/HOC/withModals';
import MainHeader from '../components/mainheaders/MainHeader';
import Text from '../components/Text';
import localeLookup from '../config/locale';
import routes from '../config/routes';
import { getLogoutThresholds } from '../services/champService';
import { getTranslationsService } from '../services/menuService';
import { getPersons } from '../slices/personsSlice';
import { getWildcardPersons } from '../slices/wildcardPersonsSlice';
import { getQueryStringParams } from '../utils/helpers';
import {
  initiateSupport,
  resetSupportSession,
  setSupportUserProperties,
} from '../utils/support';
import {
  identifyUser,
  initiateTracking,
  resetTrackingData,
} from '../utils/tracking';
import { systemSelector } from './../reducers/systemReducer';
import Notifications from './Notifications';
import RootNavigator from './RootNavigator';
import LoadOverlay from '../components/LoadOverlay';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getCSRFTokenService } from '../services/authService';
import { getGroups } from '../slices/groupsSlice';

var reactPlugin = new ReactPlugin();

const mapStateToProps = function (state) {
  return {
    blockUI: systemSelector.blockUI(state),
    menuOpen: systemSelector.menuOpen(state),
    showLoadOverlay: state.system.showLoadOverlay,
    notifications: state.notifications,
    locale: state.user.locale,
    userFullName: state.user.name,
    user: state.user,
    telemetryEnabled: state.permissions.telemetryEnabled,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestMenuData,
      getPersons,
      getWildcardPersons,
      updateAllowedMimeTypes,
      getAuthUserData,
      toggleMenu,
      systemHideContextMenu,
      getGroups,
    },
    dispatch
  );

class App extends Component {
  constructor(props) {
    super(props);
    this.props.history.listen(() => {
      props.systemHideContextMenu();
      props.requestMenuData();
      props.getPersons();
      props.getGroups();
    });
    this.state = {
      hasTranslations: false,
      isGettingInitialState: true,
      hasFetchedInitialData: false,
      timeouts: 0,
      idleTimeout: 0,
    };
    this.idleTimer = React.createRef();
  }

  async componentDidMount() {
    await getCSRFTokenService();
    const queryParams = getQueryStringParams(window.location.search);
    const lsRememberMe = localStorage.getItem('rememberMe');
    if (queryParams.sign_in) {
      this.signInWithCredentials = true;
    }
    if (queryParams.remember_me) {
      this.setState({ logoutOnIdle: queryParams.remember_me === 'False' });
      localStorage.setItem('rememberMe', queryParams.remember_me);
    } else if (lsRememberMe) {
      this.setState({ logoutOnIdle: lsRememberMe === 'False' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      locale,
      userData,
      requestMenuData,
      getAuthUserData,
      signinRedirect,
      activeNavigator,
      auth,
    } = this.props;
    // Automatic login
    const { isAuthenticated, isLoading } = auth;
    if (!isAuthenticated && !isLoading) {
      auth.signinRedirect();
    }

    if (!prevProps.auth.isAuthenticated && isAuthenticated) {
      getAuthUserData().then(({ tenantId, tenants, userId }) => {
        const currentTenantId = this.setTenant(tenantId, tenants, userId);
        const currentTenantName = tenants[currentTenantId]?.name;
        requestMenuData().then(
          ({
            name,
            email,
            telemetryApiKey,
            telemetryUrl,
            showSupport,
            aiConnectionString,
            telemetryEnabled,
          }) => {
            if (aiConnectionString) {
              this.initApplicationInsights(aiConnectionString);
            }
            if (showSupport) {
              this.initSupport({
                email,
                name,
                organisation: currentTenantName,
              });
            }
            if (telemetryEnabled) {
              this.initTracking({
                telemetryApiKey,
                telemetryUrl,
                id: userId,
                name,
                email,
                tenantId: currentTenantId,
              });
            }
            this.getInitialState().then(() => {
              this.setState({ hasFetchedInitialData: true });
            });
          }
        );
      });
    }
    if (
      (prevProps.locale === '' && locale !== '') ||
      prevProps.locale !== locale
    ) {
      this.getTranslations();
    }
  }

  setTenant = (preferredTenantId, tenants, userId) => {
    if (this.signInWithCredentials) {
      localStorage.setItem(`tenantId_${userId}`, preferredTenantId);
      return preferredTenantId;
    } else {
      const lsTenantId = localStorage.getItem(`tenantId_${userId}`);
      if (lsTenantId === null || !tenants[lsTenantId]) {
        localStorage.setItem(`tenantId_${userId}`, preferredTenantId);
        return preferredTenantId;
      }
      return lsTenantId;
    }
  };

  getInitialState = () => {
    const { logoutOnIdle } = this.state;
    const {
      getPersons,
      getWildcardPersons,
      updateAllowedMimeTypes,
      getGroups,
    } = this.props;
    return Promise.all([
      getLogoutThresholds(),
      getPersons(),
      getWildcardPersons(),
      getGroups(),
      this.getTranslations(),
      updateAllowedMimeTypes(),
    ]).then(([thresholds, ...rest]) => {
      this.setState(
        {
          idleTimeout: thresholds.data.modalThreshold,
          logoutTimeout:
            thresholds.data.logoutThreshold - thresholds.data.modalThreshold,
          modalThreshold: thresholds.data.modalThreshold,
          isGettingInitialState: false,
          hasFetchedInitialData: true,
        },
        () => {
          if (logoutOnIdle) {
            this.idleTimer.current?.start();
          }
        }
      );
    });
  };

  getTranslations = () => {
    const { locale } = this.props;
    return getTranslationsService().then(({ data }) => {
      const globalTranslationObject = window.CHAMP.locale.data;
      if (locale === '') return;
      globalTranslationObject.locale = locale;
      globalTranslationObject.map = Object.keys(
        data[locale.toLowerCase()].translations
      ).reduce(
        (acc, key) => ({
          ...acc,
          [key.toLowerCase()]: data[locale.toLowerCase()].translations[key],
        }),
        {}
      );
      this.setState({
        hasTranslations: true,
      });
    });
  };

  getRoutesRecursively(routes) {
    const allRoutes = [];
    routes.forEach((route) => {
      allRoutes.push(route);
      if (route.routes) {
        allRoutes.push(...this.getRoutesRecursively(route.routes));
      }
    });
    return allRoutes;
  }

  initApplicationInsights = (connectionString) => {
    const browserHistory = createBrowserHistory({ basename: '' });
    var appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();
  };

  initSupport = ({ email, name, organisation }) => {
    initiateSupport();
    resetSupportSession();
    setSupportUserProperties({ email, nickname: name, company: organisation });
  };

  initTracking = ({
    telemetryApiKey,
    telemetryUrl,
    id,
    name,
    email,
    tenantId,
  }) => {
    initiateTracking(telemetryApiKey, telemetryUrl);
    identifyUser({
      id,
      name,
      email,
      tenantId,
    });
  };

  onIdle = () => {
    const { showModal, auth, userFullName, hideModal, telemetryEnabled } =
      this.props;
    const { signoutRedirect } = auth;
    const { timeouts, modalThreshold, logoutTimeout } = this.state;
    if (timeouts === 0) {
      showModal('confirmation', {
        title: localeLookup('translations.Welcome back'),
        closeOnEsc: false,
        closeOnOverlayClick: false,
        dismissable: false,
        infoText: `${localeLookup(
          'translations.You logged in without selecting "Keep me logged in" and you have been inactive for'
        )} ${modalThreshold / 1000 / 60} ${localeLookup(
          'translations.minutes'
        )}`,
        maxWidth: '500px',
        fullWidth: true,
        body: (
          <Text>
            {localeLookup('translations.You are logged in as')}{' '}
            <Text as="span" bold>
              {userFullName}
            </Text>
            {'. '}
            {localeLookup('translations.Do you want to stay logged in?')}
          </Text>
        ),
        confirmButtonText: localeLookup('translations.Stay logged in'),
        btnRejectTitle: localeLookup('translations.Log out'),
        onCancel: () => {
          if (telemetryEnabled) resetTrackingData();
          signoutRedirect({ post_logout_redirect_uri: window.location.origin });
        },
        onConfirm: () => {
          this.setState({ idleTimeout: modalThreshold, timeouts: 0 });
          hideModal();
        },
      });
      this.setState({ idleTimeout: logoutTimeout, timeouts: 1 }, () => {
        this.idleTimer.current.start();
      });
    } else {
      if (telemetryEnabled) resetTrackingData();
      signoutRedirect({ post_logout_redirect_uri: window.location.origin });
    }
  };

  renderRoutes() {
    const routesToRender = this.getRoutesRecursively(Object.values(routes));
    return routesToRender.map((route) => {
      if (!route.component && route.routes) {
        return (
          <Redirect
            exact
            key={route.path}
            from={route.path}
            to={route.routes[0].path}
          />
        );
      }
      return (
        <Route
          exact={route.exact !== undefined ? route.exact : true} // Exact route by default
          key={route.path}
          path={route.path}
          render={(props) => (
            <ErrorBoundary>
              <route.component {...props}></route.component>
            </ErrorBoundary>
          )}
        />
      );
    });
  }

  render() {
    const { menuOpen, toggleMenu, showLoadOverlay, auth } = this.props;
    const { isAuthenticated } = auth;
    const {
      hasTranslations,
      isGettingInitialState,
      hasFetchedInitialData,
      idleTimeout,
      logoutOnIdle,
    } = this.state;
    if (!isAuthenticated || !hasTranslations || !hasFetchedInitialData)
      return null;
    return (
      <div className="app-container">
        {logoutOnIdle && (
          <IdleTimer
            ref={this.idleTimer}
            timeout={idleTimeout}
            onIdle={this.onIdle}
            startManually={true}
            debounce={250}
          />
        )}
        <RootNavigator />
        <main className="main">
          <MainHeader />
          <Switch>
            {this.renderRoutes()}
            <Redirect from="/login" exact to="/roles" />
            {this.props.user.isManagingCustomer ? (
              <Redirect from="/" exact to="/editor" />
            ) : (
              <Redirect from="/" exact to="/roles" />
            )}
            {/* <Redirect from="*" exact to="/" /> */}
          </Switch>
          {menuOpen && (
            <div className="navigation__overlay" onClick={toggleMenu} />
          )}
          {showLoadOverlay && <LoadOverlay fixed />}
          <div id="overlay-root"></div>
          <div id="cursor-content-root"></div>
        </main>
        {this.props.blockUI && <div className="system-blockui-overlay" />}
        <Notifications notifications={this.props.notifications} />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    WithModals(withAuth(withAITracking(reactPlugin, App, 'AItracking', 'root')))
  )
);
