import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const getRoleDashbordService = (roleId) => axiosRequestGET(`/api/dashboard/role/${roleId}`);

export const getAreaDashbordService = (areaId) => axiosRequestGET(`/api/dashboard/knowledge/${areaId}`);

export const updateElementDashboardSignees = (areaId, updateData) => {
  const url = `/api/dashboard/knowledge/${areaId}/signee`;
  return axiosRequestPUT(url, updateData);
};

export const updateRoleAreaElementDashboardSignees = (roleId, areaId, updateData) => {
  const url = `/api/dashboard/roles/${roleId}/knowledge/${areaId}/signee`;
  return axiosRequestPUT(url, updateData);
};

export const updateRoleAreaDashboardLinkState = (roleId, updateData) => {
  const url = `/api/dashboard/roles/${roleId}/link`;
  return axiosRequestPUT(url, updateData);
};
