/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import {
  ACTION_STATES,
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  EXPERT_ID,
  KNOWLEDGE_OWNER_ID,
  MENTEE_ID,
  MENTOR_ID,
  PENDING_STATES,
} from '../../constants';
import { getApproversService } from '../../services/trainingService';
import { compareFalsy, sortBy } from '../../utils/helpers';
import BoxMessage from '../BoxMessage';
import EmptyState from '../EmptyState';
import withPersonLookup from '../HOC/withPersonLookup';
import ListCard from '../ListCard';
import Loader from '../Loader';
import RadioButton from '../RadioButton';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => ({ wildcardPersons: state.wildcardPersons });

class ApproverModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApproverId: '',
      possibleApprovers: props.possibleApprovers || [],
      isLoading: props.context === 'role',
      filterString: '',
    };
  }
  static defaultProps = {
    context: 'role',
  };

  componentDidMount() {
    const { context } = this.props;
    if (context === 'role') {
      this.getApprovers();
    }
  }

  getApprovers = () => {
    const { elementId, menteeId } = this.props;
    getApproversService({ elementId, menteeId }).then(({ data }) => {
      this.setState({
        possibleApprovers: sortBy(data, [
          (a, b) => {
            return compareFalsy(a.as === MENTEE_ID, b.as === MENTEE_ID);
          },
        ]),
        isLoading: false,
      });
    });
  };

  getFilteredApprovers = () => {
    const { lookupPerson } = this.props;
    const { filterString, possibleApprovers } = this.state;
    if (filterString === '') return possibleApprovers;
    return possibleApprovers.filter((approver) => {
      const person = lookupPerson(approver.id);
      if (person) {
        return `${person.name}${person.employeeNumber}${person.initials}`
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return false;
    });
  };

  getMessage = () => {
    const { possibleApprovers } = this.state;
    const {
      action,
      mediatorId,
      pending,
      isMediatorArchived,
      wildcardPersons,
      context,
    } = this.props;
    if (
      (possibleApprovers.length === 0 && wildcardPersons[mediatorId]) ||
      (possibleApprovers.length === 1 &&
        possibleApprovers[0].as === MENTEE_ID &&
        pending === PENDING_STATES.ALL) ||
      isMediatorArchived
    ) {
      const getWarningPrefix = () => {
        switch (action) {
          case ACTION_STATES.REQUEST_SIGNATURE:
            return localeLookup(
              'translations.You can receive a signature to add a completion'
            );
          case ACTION_STATES.LOCKED:
          case ACTION_STATES.PENDING_COUNTERPART:
            return `${localeLookup(
              'translations.You can not add a completion'
            )}. ${
              mediatorId === EXPERT_ID
                ? localeLookup('translations.An')
                : localeLookup('translations.A')
            } ${wildcardPersons[mediatorId].name.toLowerCase()} ${localeLookup(
              'translations.can add a completion'
            )}`;
        }
      };
      if (isMediatorArchived) {
        switch (action) {
          case ACTION_STATES.REQUEST_SIGNATURE:
            return {
              type: 'warning',
              text: `${localeLookup(
                'translations.You can receive a signature to add a completion'
              )}, ${localeLookup('translations.but')} ${localeLookup(
                'translations.the responsible person is archived'
              )}`,
            };
          case ACTION_STATES.LOCKED:
          case ACTION_STATES.PENDING_COUNTERPART:
            return {
              type: 'warning',
              text: `${localeLookup(
                'translations.You can not add a completion'
              )}. ${localeLookup(
                'translations.The responsible person is archived'
              )}`,
            };
        }
      }
      if (mediatorId === DELETED_PERSON_ID || mediatorId === DELETED_GROUP_ID) {
        switch (action) {
          case ACTION_STATES.REQUEST_SIGNATURE:
            return {
              type: 'warning',
              text: `${localeLookup(
                'translations.You can receive a signature to add a completion'
              )}, ${localeLookup('translations.but')} ${localeLookup(
                'translations.the responsible person is deleted'
              )}`,
            };
          case ACTION_STATES.LOCKED:
          case ACTION_STATES.PENDING_COUNTERPART:
            return {
              type: 'warning',
              text: `${localeLookup(
                'translations.You can not add a completion'
              )}. ${localeLookup(
                'translations.The responsible person is deleted'
              )}`,
            };
        }
      }
      if (mediatorId === MENTOR_ID) {
        return {
          type: 'warning',
          text: `${getWarningPrefix()}, ${localeLookup(
            'translations.but there is no mentor assigned to this person in this role'
          )}`,
        };
      }
      if (mediatorId === KNOWLEDGE_OWNER_ID) {
        return {
          type: 'warning',
          text: `${getWarningPrefix()}, ${localeLookup(
            'translations.but there is no knowledge owner assigned to this knowledge area'
          )}`,
        };
      }
      if (mediatorId === EXPERT_ID) {
        return {
          type: 'warning',
          text: `${getWarningPrefix()}, ${localeLookup(
            'translations.but there is no experts assigned to this knowledge area'
          )}`,
        };
      }
      return { type: '', text: '' };
    }
    switch (action) {
      case ACTION_STATES.LOCKED:
      case ACTION_STATES.PENDING_COUNTERPART:
        return {
          type: 'info',
          text: localeLookup(
            'translations.You can not add a completion. The following persons can add a completion'
          ),
        };
      case ACTION_STATES.REQUEST_SIGNATURE:
        if (context === 'session')
          return {
            type: 'info',
            text: localeLookup(
              'translations.There are several trainers in this training session. Choose which trainer must sign for the completion of elements'
            ),
          };
        return {
          type: 'info',
          text: localeLookup(
            'translations.You can receive a signature from one of the following persons to add a completion'
          ),
        };
      default:
        return { type: '', text: '' };
    }
  };

  onChangeFilter = (e) => {
    this.setState({ filterString: e.target.value });
  };

  onChangeSelectedApprover = (id) => {
    this.setState({
      selectedApproverId: id,
    });
  };

  onClickConfirm = () => {
    const { onConfirm } = this.props;
    const { selectedApproverId, possibleApprovers } = this.state;
    onConfirm({
      selectedApproverId,
      selectedApproverName: possibleApprovers.find(
        ({ id }) => id === selectedApproverId
      ).name,
    });
  };

  renderApprover = ({ name, id, as }) => {
    const { selectedApproverId } = this.state;
    const { action, wildcardPersons, lookupPerson } = this.props;
    const readOnly = action !== ACTION_STATES.REQUEST_SIGNATURE;
    const getTooltip = () => {
      const person = lookupPerson(id);
      if (person) {
        return `${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        }`;
      }
      return '';
    };
    return (
      <ListCard
        clickable={!readOnly}
        el={!readOnly ? 'label' : 'div'}
        key={id}
        tooltip={getTooltip()}
        rightComponent={
          !readOnly ? (
            <RadioButton
              id={id}
              isChecked={selectedApproverId === id}
              onChange={() => {
                this.onChangeSelectedApprover(id);
              }}
            />
          ) : null
        }
      >
        <Text>{name}</Text>
        <Text size="sm" color="dark-grey">
          {wildcardPersons[as]?.name}
        </Text>
      </ListCard>
    );
  };

  renderApprovers = () => {
    const { isLoading } = this.state;
    const filteredApprovers = this.getFilteredApprovers();
    if (isLoading) {
      return <Loader></Loader>;
    }
    if (filteredApprovers.length > 0) {
      return filteredApprovers.map(this.renderApprover);
    }
    return (
      <EmptyState
        icon="warning"
        title={localeLookup('translations.No approvers found')}
      />
    );
  };

  renderMessage = () => {
    const { isLoading } = this.state;
    if (!isLoading) {
      const message = this.getMessage();
      return (
        <BoxMessage
          type={message.type}
          icon={message.type === 'warning' ? 'warning' : 'info-circle'}
        >
          {message.text}
        </BoxMessage>
      );
    }
    return null;
  };

  render() {
    const { possibleApprovers, selectedApproverId, filterString } = this.state;
    const { title, subtitle, onClose, onCancel, action } = this.props;
    const readOnly = action !== ACTION_STATES.REQUEST_SIGNATURE;

    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        {this.renderMessage()}
        {possibleApprovers.length >= 5 && !readOnly && (
          <ModalFilter
            placeholder={`${localeLookup(
              'translations.Search for approver'
            )}...`}
            onChange={this.onChangeFilter}
            value={filterString}
          />
        )}
        <ModalBody deep>{this.renderApprovers()}</ModalBody>
        <ModalFooter
          onConfirmClick={readOnly ? null : this.onClickConfirm}
          onCancelClick={onCancel || onClose}
          confirmDisabled={selectedApproverId === ''}
          confirmButtonText={localeLookup('translations.Next')}
          cancelButtonText={
            readOnly
              ? localeLookup('translations.Close')
              : localeLookup('translations.Cancel')
          }
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(withPersonLookup(ApproverModal));
