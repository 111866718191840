import React from 'react';
import FormModal from './FormModal';
import LoginInformationForm from '../LoginInformationForm';
import ModalFooter from './ModalFooter';

const UserLoginInformationModal = ({
  activeStepIndex,
  cancelButtonText,
  confirmButtonText,
  currentUserType,
  initialValues,
  newUserType,
  onCancelClick,
  onClose,
  onSubmit,
  steps,
  title,
}) => {
  return (
    <FormModal onClose={onClose} title={title} steps={steps} activeStepIndex={activeStepIndex}>
      <LoginInformationForm
        currentUserType={currentUserType}
        initialValues={initialValues}
        newUserType={newUserType}
        onSubmit={onSubmit}
        renderFooter={({ handleSubmit, canSubmit }) => (
          <ModalFooter
            confirmButtonText={confirmButtonText}
            confirmDisabled={!canSubmit}
            onConfirmClick={handleSubmit}
            cancelButtonText={cancelButtonText}
            onCancelClick={onCancelClick}
          />
        )}
      />
    </FormModal>
  );
};

export default UserLoginInformationModal;
