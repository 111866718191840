import cx from 'classnames';
import { motion } from 'framer-motion';
import { withAuth } from 'react-oidc-context';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import champIcon from '../../assets/icons/logo.svg';
import { toggleMenu } from '../actions/systemActions';
import AccessLevelWrapper from '../components/AccessLevelWrapper';
import adminRoutes from '../config/adminRoutes';
import routes from '../config/routes';
import { systemSelector } from '../reducers/systemReducer';
import { userSelector } from '../reducers/userReducer';
import ButtonIcon from './../components/ButtonIcon';
import Icon from './../components/Icon';

const subMenuVariants = {
  open: {
    height: 'auto',
    overflow: 'hidden',
    opacity: 1,
  },
  closed: { height: 0, opacity: 0, overflow: 'hidden' },
};

const mapStateToProps = (state) => ({
  menuOpen: systemSelector.menuOpen(state),
  items: state.rootmenu.items,
  user: userSelector.user(state),
  adminSiteBaseUrl: state.rootmenu.adminSiteBaseUrl,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(toggleMenu());
  },
});

class RootNavigator extends PureComponent {
  constructor() {
    super();
    this.componentRef = React.createRef();
    this.onLinkClick = this.onLinkClick.bind(this);
    this.state = {
      expandedMenuItemIndex: null,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { expandedMenuItemIndex } = this.state;
    const { menuOpen, location } = this.props;
    const body = document.querySelector('body');
    if (prevProps.menuOpen !== menuOpen) {
      if (body) {
        if (menuOpen) {
          if (expandedMenuItemIndex === null) {
            const index = Object.values(routes).findIndex((route) => location.pathname.startsWith(route.path));
            if (index !== -1) this.setState({ expandedMenuItemIndex: index });
          }
          body.classList.add('menu-open');
        } else {
          body.classList.remove('menu-open');
          if (expandedMenuItemIndex !== null) this.setState({ expandedMenuItemIndex: null });
        }
      }
    }
  }

  isRouteVisible = (route) => {
    const { items } = this.props;
    if (route.showInMenu === false) return false;
    if (route.visibilityKey && typeof route.visibilityKey === 'string') {
      return items.includes(route.visibilityKey);
    }
    if (route.visibilityKey && typeof route.visibilityKey === 'object') {
      return route.visibilityKey.some((key) => items.includes(key));
    }

    return true;
  };

  onClickOutside = (event) => {
    const { toggleMenu } = this.props;
    if (this.componentRef?.current && !this.componentRef.current.contains(event.target)) {
      this.setState({ expandedMenuItemIndex: null });
    }
  };

  onClickParentRoute = (index) => {
    const { menuOpen, toggleMenu } = this.props;
    const { expandedMenuItemIndex } = this.state;
    if (index === expandedMenuItemIndex) {
      this.setState({ expandedMenuItemIndex: null });
    } else {
      if (!menuOpen) toggleMenu();
      this.setState({ expandedMenuItemIndex: index });
    }
  };

  onLinkClick() {
    const { menuOpen, toggleMenu } = this.props;
    this.setState({ expandedMenuItemIndex: null });
    if (menuOpen) {
      toggleMenu();
    }
  }

  renderAdminItems = () => {
    const { adminSiteBaseUrl } = this.props;
    return Object.values(adminRoutes).map((item, i) => {
      const isVisible = this.isRouteVisible(item);
      if (!isVisible) return null;
      const path = item.visibilityKey === 'platformAdministration' ? adminSiteBaseUrl : item.path;
      return (
        <li className={cx('navigation__item', { 'navigation__item--margin': i === 0 })} key={i}>
          <a className="navigation__item-link" href={path}>
            <Icon className="navigation__item-icon" kind={item.icon} size="large" />
            <p className="navigation__item-title">
              <span className="navigation__item-title-text">{item.title}</span>
            </p>
          </a>
        </li>
      );
    });
  };

  renderItems = () => {
    const { expandedMenuItemIndex } = this.state;
    const { menuOpen, location } = this.props;
    return Object.values(routes).map((item, i) => {
      const isVisible = this.isRouteVisible(item);
      if (!isVisible) return null;
      return (
        <AccessLevelWrapper key={i} acceptedLevels={item.requiredRoles}>
          <motion.li className={cx('navigation__item', { 'navigation__item--expanded': expandedMenuItemIndex === i })}>
            {item.routes && !item.routes.every((subroute) => subroute.dynamic) ? (
              <button
                type="button"
                onClick={() => this.onClickParentRoute(i)}
                className={cx('navigation__item-link', {
                  'navigation__item-link--selected': location?.pathname?.startsWith(item.path) && !menuOpen,
                })}
              >
                <Icon className="navigation__item-icon" kind={item.icon} size="large" />
                <p className="navigation__item-title">
                  <span className="navigation__item-title-text">{item.getTranslatedTitle() || item.title}</span>
                </p>
                <Icon className="navigation__item-expand-icon" kind="chevron-down" size="small" />
              </button>
            ) : (
              <NavLink activeClassName="navigation__item-link--selected" to={item.path} onClick={this.onLinkClick} className="navigation__item-link">
                <Icon className="navigation__item-icon" kind={item.icon} size="large" />
                <p className="navigation__item-title">
                  <span className="navigation__item-title-text">{item.getTranslatedTitle()}</span>
                </p>
              </NavLink>
            )}
            <motion.ul animate={expandedMenuItemIndex === i ? 'open' : 'closed'} variants={subMenuVariants} className="navigation__submenu">
              {item.routes &&
                !item.routes.every((subroute) => subroute.dynamic) &&
                item.routes.map((subroute, i) => {
                  const isVisible = this.isRouteVisible(subroute);
                  if (!isVisible) return null;
                  return (
                    <motion.li key={i} layout="position" className="navigation__item navigation__item--nested">
                      <NavLink activeClassName="navigation__item-link--selected" onClick={this.onLinkClick} to={subroute.path} className="navigation__item-link">
                        <p className="navigation__item-title">
                          <span className="navigation__item-title-text">{subroute.getTranslatedTitle()}</span>
                        </p>
                      </NavLink>
                    </motion.li>
                  );
                })}
            </motion.ul>
          </motion.li>
        </AccessLevelWrapper>
      );
    });
  };

  render() {
    const { toggleMenu, location } = this.props;
    const modifierClasses = {
      'navigation--color-blue': location.pathname.startsWith('/training'),
      'navigation--color-red': location.pathname.startsWith('/editor'),
    };

    return (
      <>
        <nav className={cx('navigation', modifierClasses)} ref={this.componentRef}>
          <div className="navigation__toggle" onClick={toggleMenu}>
            <ButtonIcon className="navigation__toggle-button" icon="chevron-right" round color="green" />
          </div>
          <ul className="navigation__list">
            {this.renderItems()}
            {this.renderAdminItems()}
          </ul>
          <div className="navigation__logo">
            <svg className="navigation__logo-img">
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`${champIcon}#champicon`} />
            </svg>
          </div>
          <div className="navigation__gradient"></div>
        </nav>
        {/* {menuOpen && <div className="navigation__overlay" onClick={toggleMenu} />} */}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth(RootNavigator)));
