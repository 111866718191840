import { getAllowedMimeTypesService } from '../services/contentService';
import { getAllowedFileTypes } from '../services/knowledgeService';

export const SYSTEM_TOGGLE_MENU = 'SYSTEM_TOGGLE_MENU';
export const toggleMenu = () => ({
  type: SYSTEM_TOGGLE_MENU,
});

export const SYSTEM_BLOCK_UI_ON = 'SYSTEM_BLOCK_UI_ON';
export const systemBlockUiOn = () => ({
  type: SYSTEM_BLOCK_UI_ON,
});

export const SYSTEM_BLOCK_UI_OFF = 'SYSTEM_BLOCK_UI_OFF';
export const systemBlockUiOff = () => ({
  type: SYSTEM_BLOCK_UI_OFF,
});

export const SYSTEM_SHOW_LOAD_OVERLAY = 'SYSTEM_SHOW_LOAD_OVERLAY';
export const systemShowLoadOverlay = () => ({
  type: SYSTEM_SHOW_LOAD_OVERLAY,
});

export const SYSTEM_HIDE_LOAD_OVERLAY = 'SYSTEM_HIDE_LOAD_OVERLAY';
export const systemHideLoadOverlay = () => ({
  type: SYSTEM_HIDE_LOAD_OVERLAY,
});

export const SYSTEM_ALLOWED_MIME_TYPES_RECEIVED =
  'SYSTEM_ALLOWED_MIME_TYPES_RECEIVED';
export const systemAllowedMimeTypesReceived = (mimeTypes) => ({
  type: SYSTEM_ALLOWED_MIME_TYPES_RECEIVED,
  mimeTypes,
});

export const updateAllowedMimeTypes = () => (dispatch) => {
  return getAllowedMimeTypesService()
    .then((response) => {
      dispatch(systemAllowedMimeTypesReceived(response.data));
    })
    .catch(() => {
      console.log('Failed getting allowed mime types');
    });
};

export const SYSTEM_SHOW_CONTEXT_MENU = 'SYSTEM_SHOW_CONTEXT_MENU';
export const systemShowContextMenu = () => ({
  type: SYSTEM_SHOW_CONTEXT_MENU,
});

export const SYSTEM_HIDE_CONTEXT_MENU = 'SYSTEM_HIDE_CONTEXT_MENU';
export const systemHideContextMenu = () => ({
  type: SYSTEM_HIDE_CONTEXT_MENU,
});
