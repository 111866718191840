import React from 'react';
import cx from 'classnames';
import Icon from './Icon';
import Loader from './Loader';

const Button = ({
  children,
  className,
  disabled,
  Element = 'button',
  fullWidth,
  ghost,
  href,
  icon,
  innerRef,
  isLoading,
  kind,
  onClick,
  onMouseDown,
  size,
  square,
  type = 'button',
  iconColor,
}) => {
  const modifierClasses = {
    [`button--color-${kind}`]: kind,
    [`button--size-${size}`]: size,
    'button--style-ghost': ghost,
    'button--full-width': fullWidth,
    'button--square': square,
  };
  return (
    <Element
      type={type}
      href={href}
      ref={innerRef}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={cx('button', modifierClasses, className)}
    >
      {isLoading ? (
        <Loader color="white" size="small" />
      ) : (
        <>
          {icon && <Icon color={iconColor} className="button__icon" kind={icon} />}
          <span>{children}</span>
        </>
      )}
    </Element>
  );
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <Button innerRef={ref} {...props} />);
