import React from 'react';

// Components
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalWrapper from './ModalWrapper';
import ModalFooter from './ModalFooter';

import ButtonIcon from '../ButtonIcon';
import BoxMessage from '../BoxMessage';
import localeLookup from '../../config/locale';

const InfoModal = ({ onCancel, onClose, title, body, subtitle, infoText }) => (
  <ModalWrapper>
    <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
    {infoText && (
      <BoxMessage spacing="md" icon="info-circle">
        {infoText}
      </BoxMessage>
    )}
    <ModalBody>{body}</ModalBody>
    <ModalFooter onCancelClick={onClose} cancelButtonKind="darkui" cancelButtonText={localeLookup('translations.Close')} />
  </ModalWrapper>
);

export default InfoModal;
