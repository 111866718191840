/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cx from 'classnames';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';
import Icon from './Icon';

const CheckboxButton = ({
  id,
  label,
  className,
  labelPosition = 'left',
  disabled,
  tooltip,
  style,
  color,
  onClick,
  icon,
  text,
  buttonTooltip,
}) => {
  const modifierClasses = {
    [`checkbox-button--style-${style}`]: style,
  };
  return (
    <div className={cx('checkbox-button', modifierClasses, className)}>
      {label && labelPosition === 'left' && (
        <label
          onClick={disabled ? null : onClick}
          htmlFor={id}
          className="e-label-upper checkbox-button__text checkbox-button__text--mg-right"
        >
          {label}
          {tooltip && (
            <Tooltip tooltip={tooltip}>
              <span>
                <Icon
                  className="checkbox-button__tooltip-icon"
                  kind="info-circle"
                ></Icon>
              </span>
            </Tooltip>
          )}
        </label>
      )}
      <Tooltip tooltip={buttonTooltip}>
        <button
          type="button"
          disabled={disabled}
          onClick={onClick}
          className={cx('checkbox-button__button', {
            [`checkbox-button__button--${color}`]: color,
          })}
        >
          {icon && (
            <Icon className="checkbox-button__button-icon" kind={icon}></Icon>
          )}
          {text && <span className="checkbox-button__button-text">{text}</span>}
        </button>
      </Tooltip>
      {label && labelPosition === 'right' && (
        <label
          onClick={disabled ? null : onClick}
          htmlFor={id}
          className="e-label-upper checkbox-button__text checkbox-button__text--mg-left"
        >
          {label}
          {tooltip && (
            <Tooltip tooltip={tooltip}>
              <span>
                <Icon
                  className="checkbox-button__tooltip-icon"
                  kind="info-circle"
                ></Icon>
              </span>
            </Tooltip>
          )}
        </label>
      )}
    </div>
  );
};

export default CheckboxButton;
