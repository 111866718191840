import React from 'react';
import RadioButton from '../RadioButton';
import InputLabel from './InputLabel';

const RadioButtonField = ({ label, id, name, onChange, isChecked, value }) => {
  return (
    <div className="radio-button-field">
      <RadioButton
        isChecked={isChecked}
        onChange={onChange}
        name={name}
        id={id}
        value={value}
      ></RadioButton>
      <InputLabel htmlFor={id}>{label}</InputLabel>
    </div>
  );
};

export default RadioButtonField;
