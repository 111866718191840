import React from 'react';

// Components
import Icon from './Icon';
import localeLookup from '../config/locale';

const TraineeLinkOverlay = ({ onChangeAreaLinkState, isMenteeContext }) => (
  <div className="trainee-link-overlay">
    <div className="trainee-link-overlay__overlay" />
    <div className="trainee-link-overlay__content">
      <div className="trainee-link-overlay__content-icon-wrapper">
        <Icon kind="link2" className="trainee-link-overlay__content-icon" />
      </div>
      <h1 className="trainee-link-overlay__content-title">
        {isMenteeContext
          ? localeLookup('translations.This knowledge area is not connected to the persons role')
          : localeLookup('translations.This knowledge area is not connected to your role')}
      </h1>
      <button onClick={onChangeAreaLinkState} className="ui-btn ui-btn-darkui trainee-link-overlay__content-button">
        {localeLookup('translations.Connect')}
      </button>
    </div>
  </div>
);

export default TraineeLinkOverlay;
