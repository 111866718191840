// Constants
import { SET_CHANGE_PASSWORD_ERROR, USER_SETTINGS_RECIEVED } from '../actions/userSettingsActions';

const userSettingsInitialState = {};

const userSettings = (state = userSettingsInitialState, action) => {
  switch (action.type) {
    case SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.errMessage,
      };
    case USER_SETTINGS_RECIEVED: {
      return {
        ...state,
        isFetchingUserSettings: false,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default userSettings;
