import cx from 'classnames';
import React from 'react';
import Donut from './Donut';
import NotificationCounter from './NotificationCounter';
import Badge from './Badge';
import localeLookup from '../config/locale';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';
import { ROLE_LEVELS } from '../constants';

const RoleCard = ({
  subtitle,
  subtitle2,
  subtitle3,
  notifications,
  completionPercentage,
  title,
  renderRight,
  onClick,
  clickable,
  donutColor = 'green',
  showStatus = true,
  competenceLevel,
  tooltip,
  disabled
}) => {
  const getDonutProps = () => {
    switch (competenceLevel?.toLowerCase()) {
      case ROLE_LEVELS.TRAINING:
        return { icon: 'users2', text: localeLookup('translations.Training') };

      case ROLE_LEVELS.EXPERIENCED:
        return { icon: 'star', text: localeLookup('translations.Experienced') };

      case ROLE_LEVELS.QUALIFIED:
        return {
          icon: 'graduation-hat',
          text: localeLookup('translations.Qualified'),
        };
    }
  };
  return (
    <ConditionalWrap
      condition={tooltip}
      wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
    >
      <div
        className={cx('role-card', {
          'role-card--clickable': clickable,
          'role-card--state-disabled': disabled,
        })}
        onClick={onClick}
      >
        {showStatus ? (
          <Donut
            color={donutColor}
            value={completionPercentage}
            icon={getDonutProps().icon}
            text={getDonutProps().text}
          />
        ) : (
          <div className="role-card__badge">
            <Badge icon="eye-crossed"></Badge>
          </div>
        )}
        <div className="role-card__info">
          <p className="role-card__info-title">{title}</p>
          {subtitle && <p className="role-card__info-subtitle">{subtitle}</p>}
          {subtitle2 && <p className="role-card__info-subtitle">{subtitle2}</p>}
          {subtitle3 && <p className="role-card__info-subtitle">{subtitle3}</p>}
        </div>
        {!!notifications && notifications !== 0 && (
          <NotificationCounter count={notifications} />
        )}
        {renderRight}
      </div>
    </ConditionalWrap>
  );
};

export default RoleCard;
