import React from 'react';
import Badge from './Badge';
import Text from './Text';
import localeLookup from '../config/locale';
import cx from 'classnames';

const EmptyState = ({ title = localeLookup('translations.No content'), body, fullHeight, icon }) => {
  const modifierClasses = {
    'empty-state--full-height': fullHeight,
  };
  return (
    <div className={cx('empty-state', modifierClasses)}>
      <Badge iconColor="grey" size="large" icon={icon} />
      <Text size="lg" className="empty-state__title" size="lg" color="dark-grey">
        {title}
      </Text>
      {body && (
        <Text className="empty-state__body" color="dark-grey">
          {body}
        </Text>
      )}
    </div>
  );
};

EmptyState.defaultProps = {
  icon: 'wind',
};

export default EmptyState;
