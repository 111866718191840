// Actions
const LOAD_ROLES_ACCESS = 'editor/LOAD_ROLES_ACCESS';
const LOAD_AREAS_ACCESS = 'editor/LOAD_AREAS_ACCESS';

import { getAreasAccessService } from '../services/knowledgeEditorService';
import { getRolesAccessService } from '../services/roleEditorService';

const defaultState = {
  accessibleRoles: [],
  accessibleAreas: [],
  readOnlyAreas: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_ROLES_ACCESS:
      return { ...state, accessibleRoles: action.accessibleRoles };
    case LOAD_AREAS_ACCESS:
      return {
        ...state,
        accessibleAreas: action.accessibleAreas,
        readOnlyAreas: action.readOnlyAreas,
      };
    default:
      return state;
  }
};

export const getRolesAccess = () => (dispatch) =>
  getRolesAccessService().then(({ data }) => {
    dispatch({
      type: LOAD_ROLES_ACCESS,
      accessibleRoles: data.accessibleRoles,
    });
    return data;
  });

export const getAreasAccess = () => (dispatch) =>
  getAreasAccessService().then(({ data }) => {
    dispatch({
      type: LOAD_AREAS_ACCESS,
      accessibleAreas: data.accessibleModules,
      readOnlyAreas: data.readOnlyModules,
    });
    return data;
  });
