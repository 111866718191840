import React, { PureComponent } from 'react';
import cx from 'classnames';

import Icon from './Icon';
import localeLookup from '../config/locale';

class SlideButton extends PureComponent {
  constructor() {
    super();

    this.state = {
      open: false,
    };
    this.onToggle = this.onToggle.bind(this);
  }
  onToggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  render() {
    const { renderButton, actions, showTrigger } = this.props;
    const modifierClasses = {
      'slide-button--state-open': this.state.open,
    };
    const actionModifierClasses = color => ({
      [`slide-button__action--color-${color}`]: color,
    });
    const actionButtonWidth = (100 / (actions.length + 1)).toFixed(2);
    return (
      <div className={cx('slide-button', modifierClasses)}>
        <div className="slide-button__button">
          <div style={{ width: 'calc(100% - 3rem)' }}>{renderButton(this.onToggle)}</div>
          {showTrigger && (
            <button className="slide-button__trigger" onClick={this.onToggle}>
              <svg className="slide-button__trigger-icon" viewBox="0 0 20 20">
                <circle cx="9.5" cy="3.5" r="2.5" />
                <circle cx="9.5" cy="10.5" r="2.5" />
                <circle cx="9.5" cy="17.5" r="2.5" />
              </svg>
            </button>
          )}
        </div>
        <div className="slide-button__actions">
          {actions.map(action => (
            <button
              onClick={() => {
                action.onClick();
                this.onToggle();
              }}
              key={action.title}
              style={{ width: `${actionButtonWidth}%` }}
              className={cx('slide-button__action', actionModifierClasses(action.color))}
            >
              <Icon className="slide-button__action-icon" kind={action.icon} />
              <p className="slide-button__action-title">{action.title}</p>
            </button>
          ))}
          <button
            style={{ width: `${actionButtonWidth}%` }}
            className="slide-button__action"
            onClick={this.onToggle}
          >
            <Icon className="slide-button__action-icon" kind="cross" />
            <p className="slide-button__action-title">{localeLookup('translations.Close')}</p>
          </button>
        </div>
      </div>
    );
  }
}

SlideButton.defaultProps = {
  actions: [],
};

export default SlideButton;
