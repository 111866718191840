import cx from 'classnames';
import { motion, AnimateSharedLayout } from 'framer-motion';

import React, { PureComponent } from 'react';

export default class ScrollableTabs extends PureComponent {
  renderTab = (tab) => {
    const { activeTab, onChangeTab } = this.props;
    return (
      <button type="button" className={cx('scrollable-tabs__tab', { 'scrollable-tabs__tab--state-active': activeTab === tab.id })} key={tab.name} onClick={() => onChangeTab(tab)}>
        <span className="scrollable-tabs__tab-text">{tab.name}</span>
        {activeTab === tab.id && (
          <motion.div
            transition={{
              type: 'linear',
            }}
            className="scrollable-tabs__active-background"
            layoutId="background"
          />
        )}
      </button>
    );
  };

  render() {
    const { tabs, onChangeTab, activeTab, rounded = true } = this.props;
    console.log('render');
    const modifierClasses = {
      'scrollable-tabs--rounded': rounded,
    };
    return (
      <AnimateSharedLayout>
        <div className={cx('scrollable-tabs', modifierClasses)}>{tabs.map(this.renderTab)}</div>
      </AnimateSharedLayout>
    );
  }
}
