import { axiosRequestGET, axiosRequestPUT } from './serviceConfig';

export const fetchTraineeRoles = () => {
  const url = '/api/trainee/roles';
  return axiosRequestGET(url);
};

export const fetchTraineeRoleData = (roleId) => {
  const url = `/api/trainee/roles/${roleId}`;
  return axiosRequestGET(url);
};

export const getTraineeAreaService = (roleId, knowledgeAreaId) => axiosRequestGET(`/api/trainee/roles/${roleId}/${knowledgeAreaId}`);

export const fetchTraineeRoleAreaData = (roleId, knowledgeAreaId) => {
  const url = `/api/trainee/roles/${roleId}/${knowledgeAreaId}`;
  return axiosRequestGET(url);
};

export const getKnowledgeElementService = (personId, elementId) => axiosRequestGET(`/api/trainee/${personId}/knowledge/${elementId}`);

// This is for setting "last viewed" in database for handling notifications
export const updateTraineeAreaViewState = (roleId, knowledgeAreaId) => {
  const url = `/api/trainee/roles/${roleId}/${knowledgeAreaId}/update/viewstate`;
  return axiosRequestPUT(url);
};

export const updateTraineeAreaStatus = (roleId, knowledgeAreaId, updateData) => {
  const url = `/api/trainee/roles/${roleId}/${knowledgeAreaId}/update`;
  return axiosRequestPUT(url, updateData);
};

export const updateAreaExpertStatus = (roleId, knowledgeAreaId, updateData) => {
  const url = `/api/trainee/roles/${roleId}/${knowledgeAreaId}/update/expert`;
  return axiosRequestPUT(url, updateData);
};
export const updateAreaLinkState = (roleId, knowledgeAreaId, updateData) => {
  const url = `/api/trainee/roles/${roleId}/${knowledgeAreaId}/update/link`;
  return axiosRequestPUT(url, updateData);
};
