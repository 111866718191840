import React from 'react';
import localeLookup from '../config/locale';
import cx from 'classnames';

export const PasswordScoreBar = ({ score, passwordFilled }) => {
  const getScoreTerm = () => {
    switch (score) {
      case 0:
      case 1:
        return localeLookup('translations.Weak');
      case 2:
      case 3:
        return localeLookup('translations.Medium');
      case 4:
        return localeLookup('translations.Strong');
      default:
        return '';
    }
  };
  const modifierClasses = {
    'password-score-bar--weak': (score === 0 || score === 1) && passwordFilled,
    'password-score-bar--medium': score === 2 || score === 3,
    'password-score-bar--strong': score === 4,
  };
  return (
    <div className={cx('password-score-bar', modifierClasses)}>
      <div className="password-score-bar__lines">
        <div className="password-score-bar__line"></div>
        <div className="password-score-bar__line"></div>
        <div className="password-score-bar__line"></div>
      </div>
      <p className="password-score-bar__score">
        {localeLookup('translations.Strength')}: {getScoreTerm()}
      </p>
    </div>
  );
};
