import React, { Component } from 'react';
import Checkbox from './Checkbox';
import Label from './Label';

export default class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: props.initialCheckedValues || [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkedValues } = this.state;
    const { checkedValues: prevCheckedValues } = prevState;
    const { onChange } = this.props;
    if (checkedValues.length !== prevCheckedValues.length) {
      onChange(checkedValues);
    }
  }

  onChange = (e) => {
    const { onChange, options } = this.props;
    const { checkedValues } = this.state;
    const { id: name } = e.target;
    const optionNamesThatDependOnOption = options.reduce((acc, option) => {
      if (option.dependsOn === name) {
        return [...acc, option.value];
      }
      return acc;
    }, []);
    if (checkedValues.includes(name)) {
      console.log(optionNamesThatDependOnOption);
      this.setState({
        checkedValues: checkedValues.filter(
          (val) => val !== name && !optionNamesThatDependOnOption.includes(val)
        ),
      });
    } else {
      this.setState({
        checkedValues: [...checkedValues, name],
      });
    }
  };

  render() {
    const { checkedValues } = this.state;
    const { options, groupName, title } = this.props;
    return (
      <fieldset className="checkbox-group">
        <legend className="checkbox-group__title">{title}</legend>
        {options.map((option) => {
          return (
            <div className="checkbox-group__option" key={option.value}>
              <Label
                style="none"
                className="checkbox-group__option-label"
                htmlFor={option.value}
              >
                {option.label}
              </Label>
              <Checkbox
                id={option.id || option.value}
                value={option.value}
                name={groupName}
                disabled={
                  option.dependsOn
                    ? !checkedValues.includes(option.dependsOn)
                    : option.disabledFunc
                    ? option.disabledFunc(checkedValues)
                    : option.disabled
                }
                onChange={this.onChange}
                isChecked={
                  option.dependsOn
                    ? checkedValues.includes(option.dependsOn) &&
                      checkedValues.includes(option.value)
                    : checkedValues.includes(option.value)
                }
              />
            </div>
          );
        })}
      </fieldset>
    );
  }
}
