import React from 'react';
import cx from 'classnames';
const InputError = ({ children }) => {
  const modifierClasses = {
    'input-error--visible': !!children,
  };
  return (
    <p className={cx('input-error', modifierClasses)}>
      <span className="input-error__text">{children}</span>
    </p>
  );
};

export default InputError;
