/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { ELEMENT_TYPES, TRAINING_REGISTRATION_TYPES } from '../../constants';
import { queryElements, removeElement } from '../../slices/elementsSlice';
import EditorElementContextMenu from '../EditorElementContextMenu';
import EditorElementIndicators from '../EditorElementIndicators';
import EditorElementResponsibilitySubtitle from '../EditorElementResponsibilitySubtitle';
import WithEditorActions from '../HOC/withEditorActions';
import WithModals from '../HOC/withModals';
import withPersonLookup from '../HOC/withPersonLookup';
import Icon from '../Icon';
import InlineFieldEditor from '../InlineFieldEditor';
import SwitchCheckbox from '../SwitchCheckbox';

const mapStateToProps = (state) => ({
  roles: state.roles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ queryElements, removeElement }, dispatch);

class DesignboardKnowledgeElement extends PureComponent {
  constructor() {
    super();
    this.state = {
      isEditingName: false,
    };
  }

  onBlurEditName = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onClickEditConnections = () => {
    const { editorActions, knowledgeElement } = this.props;
    editorActions.showChangeElementConnectionsModal(knowledgeElement.id);
  };

  onClickEditDescription = () => {
    const { onClickEditElementDescription, knowledgeElement, areaId, hasAccess, showModal } = this.props;
    if (!hasAccess) {
      showModal('richText', {
        title: knowledgeElement.name,
        description: knowledgeElement.description,
        files: knowledgeElement.files,
        maxWidth: '700px',
        fullWidth: true,
      });
    } else {
      onClickEditElementDescription(knowledgeElement.id, areaId);
    }
  };

  onClickEditName = (e) => {
    e.stopPropagation();
    this.setState({
      isEditingName: true,
    });
  };

  onClickEditResponsible = () => {
    const { onClickEditElementResponsible, knowledgeElement, areaId } = this.props;
    onClickEditElementResponsible(knowledgeElement.id, areaId);
  };

  onClickDelete = () => {
    const { knowledgeElement, onClickDeleteElement, areaId, roleId } = this.props;
    onClickDeleteElement({ elementId: knowledgeElement.id, areaId, roleId });
  };

  onClickMove = () => {
    const { knowledgeElement, onClickMoveElement, areaId } = this.props;
    onClickMoveElement({ id: knowledgeElement.id, areaId });
  };

  onSubmitEditName = (value) => {
    const { knowledgeElement, editorActions } = this.props;
    editorActions.changeElementName(knowledgeElement.id, value);
  };

  onToggleConnectToRole = () => {
    const { knowledgeElement, roleId, onChangeElementConnections } = this.props;
    const isConnected = knowledgeElement.roleConnections[roleId];
    onChangeElementConnections({ elementIds: [knowledgeElement.id], connect: !isConnected });
  };

  onToggleElementLinkVisiblity = () => {
    const { allowChangeOfChampLinkVisibility, onClickToggleElementLinkVisiblity, knowledgeElement } = this.props;
    if (allowChangeOfChampLinkVisibility) onClickToggleElementLinkVisiblity({ elementId: knowledgeElement.id, elementName: knowledgeElement.name, isPublic: knowledgeElement.champLink.isPublic });
  };

  renderContextMenu = () => {
    const { knowledgeElement, hasAccess, areaId, roleId } = this.props;
    return <EditorElementContextMenu containerClass="designboard" hasAccessToArea={hasAccess} elementId={knowledgeElement.id} areaId={areaId} roleId={roleId}></EditorElementContextMenu>;
  };

  renderOptionIndicators = () => {
    const { knowledgeElement, hasAccess, showConnectionCounter, areaId } = this.props;
    return <EditorElementIndicators areaId={areaId} size="xs" hasAccessToArea={hasAccess} showConnectionCounter={showConnectionCounter} elementId={knowledgeElement.id} />;
  };

  renderSubtitle = () => {
    const { knowledgeElement, hasAccess } = this.props;
    const hasCounterSign = knowledgeElement.completionType === TRAINING_REGISTRATION_TYPES.MULTIPART;
    return (
      <EditorElementResponsibilitySubtitle
        className="designboard-knowledge-element__subtitle"
        onClick={hasAccess ? this.onClickEditResponsible : undefined}
        isLocked={knowledgeElement.lockedApprover}
        alternativeMediatorId={knowledgeElement.alternativeResponsible}
        mediatorId={knowledgeElement.responsible}
        hasCounterSign={hasCounterSign}
      />
    );
  };

  renderTitle = () => {
    const { isEditingName } = this.state;
    const { knowledgeElement, hasAccess } = this.props;
    if (isEditingName) {
      return <InlineFieldEditor autoFocus classNameInput="designboard-knowledge-element__title-input" defaultValue={knowledgeElement.name} onBlur={this.onBlurEditName} onSubmit={this.onSubmitEditName} placeholder={localeLookup('translations.Name of knowledge element')} />;
    }
    return (
      <div className="designboard-knowledge-element__title-name" onClick={hasAccess ? this.onClickEditName : undefined}>
        <p title={knowledgeElement.name} className="designboard-knowledge-element__title-name-text">{knowledgeElement.name}</p>
        {hasAccess && <Icon kind="pencil" className="designboard-knowledge-element__title-name-icon" size="small" />}
      </div>
    );
  };

  render() {
    const { isDragging, knowledgeElement, roleId, transformStyle, hasAccess } = this.props;
    const dragModifier = isDragging ? 'designboard-knowledge-element--hidden' : '';
    const modifierClasses = {
      'designboard-knowledge-element--critical': knowledgeElement.type === ELEMENT_TYPES.CRITICAL,
      'designboard-knowledge-element--no-access': !hasAccess,
    };
    return (
      <div className={cx('designboard-knowledge-element', dragModifier, modifierClasses)} style={transformStyle}>
        <div className="designboard-knowledge-element__title-wrapper">
          {this.renderTitle()}
          {this.renderSubtitle()}
        </div>
        <div className="designboard-knowledge-element__options">
          {this.renderOptionIndicators()}
          <SwitchCheckbox className="designboard-knowledge-element__options-checkbox" isChecked={!!knowledgeElement.roleConnections[roleId]} onChange={this.onToggleConnectToRole} size="small" />
          {this.renderContextMenu()}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withPersonLookup(WithModals(WithEditorActions(DesignboardKnowledgeElement))));
