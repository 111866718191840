import React, { Component } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
// import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import localeLookup from '../config/locale';

class RTEditor extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    if (this.props.defaultValue) {
      const blocksFromHTML = stateFromHTML(this.props.defaultValue);
      this.state = {
        editorState: EditorState.createWithContent(blocksFromHTML),
      };
    } else {
      this.state = { editorState: EditorState.createEmpty() };
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
    window.setTimeout(() => {
      const editorArea = document.querySelector('.public-DraftEditor-content');
      if (editorArea && this.props.autoFocus) {
        editorArea.focus();
        if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
          const range = document.createRange();
          range.selectNodeContents(editorArea);
          range.collapse(false);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange !== 'undefined') {
          const textRange = document.body.createTextRange();
          textRange.moveToElementText(editorArea);
          textRange.collapse(false);
          textRange.select();
        }
      }
    }, 1);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onClickOutside = (event) => {
    const { onBlur } = this.props;
    if (this.componentRef.current && !this.componentRef.current.contains(event.target)) {
      onBlur();
    }
  };

  handleRTEChange = (editorState) => {
    const { onChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const markup = stateToHTML(contentState);
    onChange(markup);
  };

  render() {
    const toolbarConfig = {
      options: ['inline', 'list', 'history', 'link', 'remove'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
      link: {
        defaultTargetOption: '_blank',
        linkCallback: (linkObj) => {
          if (this.editor) this.editor.focus();
          return linkObj;
        },
      },
    };
    return (
      <div ref={this.componentRef}>
        <Editor
          handlePastedText={() => false}
          wrapperClassName={`rt-editor ${this.props.kind ? 'rt-editor--' + this.props.kind : ''}`}
          toolbarClassName="rt-editor__toolbar"
          editorClassName="rt-editor__editor"
          defaultEditorState={this.state.editorState}
          editorRef={(el) => (this.editor = el)}
          onEditorStateChange={this.handleRTEChange}
          //onBlur={this.props.onBlur}
          toolbar={this.props.config || toolbarConfig}
          placeholder={this.props.placeholder}
          localization={{
            translations: {
              'generic.add': localeLookup('translations.Add'),
              'generic.cancel': localeLookup('translations.Cancel'),
              'components.controls.link.linkTitle': localeLookup('translations.Title'),
              'components.controls.link.linkTarget': 'URL',
            },
          }}
        />
      </div>
    );
  }
}

export default RTEditor;
