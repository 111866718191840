import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../config/locale';
import {
  ACTION_STATES,
  EMPTY_ID,
  MAIN_STATUS_CATEGORIES,
  PENDING_STATES,
  PERSON_STATES,
  RELEVANCE_STATES,
  STATUS_CATEGORIES,
  TRAINING_REGISTRATION_TYPES,
} from '../constants';
import {
  getApproversService,
  registerTrainingOnBehalfService,
  registerTrainingService,
} from '../services/trainingService';
import Button from './Button';
import WithModals from './HOC/withModals';
import withPersonLookup from './HOC/withPersonLookup';
import Icon from './Icon';
import Loader from './Loader';
import Text from './Text';
import Tooltip from './Tooltip';

const mapStateToProps = (state) => ({
  isDemoOrganisation: state.rootmenu.demoMode,
});

const accordionVariants = {
  closed: (slideMenuDirection) => ({
    width:
      slideMenuDirection === 'down' || slideMenuDirection === 'up'
        ? 'auto'
        : '0',
    height:
      slideMenuDirection === 'down' || slideMenuDirection === 'up' ? 0 : 'auto',
    overflow: 'hidden',
  }),
  open: {
    width: 'auto',
    height: 'auto',
  },
};

const animatedOverlayContainerVariants = {
  enter: {
    opacity: 1,
    delayChildren: 200,
  },
  exit: {
    opacity: 0,
  },
};

const animatedOverlayTextVariants = {
  enter: {
    scale: 1,
  },
  exit: {
    scale: 0,
  },
};

class StatusButton extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showActions: false,
      showOverlay: false,
      promptUserForCounterSign: true,
    };
    this.getIcon = this.getIcon.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  getActionText = () => {
    const { nextAction } = this.props;
    switch (nextAction) {
      case ACTION_STATES.COMPLETE:
        return localeLookup('translations.Complete');
      case ACTION_STATES.COMPLETE_WITH_SIGNATURE:
        return localeLookup('translations.Sign');
      case ACTION_STATES.LOCKED:
        return localeLookup('translations.Locked');
      case ACTION_STATES.PENDING_COUNTERPART:
        return localeLookup('translations.Pending');
      case ACTION_STATES.REQUEST_SIGNATURE:
        return localeLookup('translations.Receive signature');

      default:
        return '';
    }
  };

  getColor = () => {
    const { showActions } = this.state;
    const { status, relevance } = this.props;
    if (showActions) return 'white';
    if (status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION)) {
      if (relevance === RELEVANCE_STATES.NOT_RELEVANT) {
        return 'grey';
      }
      return 'white';
    }
    if (status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION)) {
      if (status === 'ValidCompletionExpiring') {
        return 'yellow';
      }
      return 'green';
    }
    if (status.startsWith(MAIN_STATUS_CATEGORIES.INVALID_COMPLETION)) {
      if (status === STATUS_CATEGORIES.INVALID_COMPLETION_ONGOING) {
        return 'light-green';
      }
      if (relevance === RELEVANCE_STATES.NOT_RELEVANT) {
        return 'grey';
      }
      return 'red';
    }
    return '';
  };

  getIcon() {
    const { nextAction } = this.props;
    switch (nextAction) {
      case ACTION_STATES.COMPLETE:
        return 'check';
      case ACTION_STATES.COMPLETE_WITH_SIGNATURE:
        return 'sign';
      case ACTION_STATES.LOCKED:
        return 'lock';
      case ACTION_STATES.PENDING_COUNTERPART:
        return 'user-clock';
      case ACTION_STATES.REQUEST_SIGNATURE:
        return 'user-sign';

      default:
        return '';
    }
  }

  renderText = () => {
    const { showActions } = this.state;
    const { daysUntilExpiration, hasValidity } = this.props;
    if (showActions) {
      return (
        <Text className="status-button__text">
          {localeLookup('translations.Close')}
        </Text>
      );
    }
    if (hasValidity) {
      return (
        <Text className="status-button__text">
          <Icon
            className="status-button__text-icon"
            size="x-small"
            kind="sync"
          />
          {`${daysUntilExpiration}d`}
        </Text>
      );
    }
    return null;
  };

  onClick(e) {
    const { showActions, isLoading } = this.state;
    const { status, isDemoOrganisation, hasHistory } = this.props;
    if (isLoading) return;
    e && e.stopPropagation();
    if (
      (!status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION) &&
        !showActions) ||
      (hasHistory && !isDemoOrganisation)
    ) {
      this.onToggle(e);
    } else {
      this.onClickAction(e);
    }
  }

  onClickAction = (e) => {
    const {
      showModal,
      elementName,
      nextAction,
      hideModal,
      personId,
      elementId,
      mediatorId,
      personName,
      lookupPerson,
      pending,
    } = this.props;
    const isMediatorArchived =
      lookupPerson(mediatorId)?.state === PERSON_STATES.ARCHIVED;
    e && e.stopPropagation();
    switch (nextAction) {
      case ACTION_STATES.COMPLETE:
        this.onClickRegisterTraining();
        break;
      case ACTION_STATES.COMPLETE_WITH_SIGNATURE:
        showModal('signature', {
          title: localeLookup('translations.Sign'),
          subtitle: `${personName} · ${elementName}`,
          infoText: localeLookup(
            'translations.Completion of the element requires signature'
          ),
          fullWidth: true,
          subject: elementName,
          onConfirm: (signature) => {
            this.onClickRegisterTraining({ signature, metadata: {} }).then(
              () => {
                hideModal();
              }
            );
          },
        });
        break;
      case ACTION_STATES.LOCKED:
        // If mediator is "Relevant" person we dont want to show all other persons in approver modal
        if (
          mediatorId === EMPTY_ID &&
          pending !== PENDING_STATES.OTHER_TRAINEE &&
          pending !== PENDING_STATES.OTHER_TRAINER
        ) {
          showModal('infoModal', {
            title: localeLookup('translations.Locked'),
            subtitle: `${personName} · ${elementName}`,
            infoText: localeLookup('translations.You can not add a completion'),
            body: localeLookup(
              'translations.Anyone other than you can add a completion'
            ),
            fullWidth: true,
            maxWidth: '500px',
          });
        } else {
          showModal('approvers', {
            title: localeLookup('translations.Locked'),
            subtitle: `${personName} · ${elementName}`,
            menteeId: personId,
            mediatorId,
            isMediatorArchived,
            elementId,
            fullWidth: true,
            maxWidth: '500px',
            action: nextAction,
            pending,
          });
        }
        break;
      case ACTION_STATES.PENDING_COUNTERPART:
        showModal('approvers', {
          title: localeLookup('translations.Pending'),
          subtitle: `${personName} · ${elementName}`,
          menteeId: personId,
          mediatorId,
          isMediatorArchived,
          elementId,
          fullWidth: true,
          maxWidth: '500px',
          action: nextAction,
          pending,
        });
        break;
      case ACTION_STATES.REQUEST_SIGNATURE:
        this.onClickRequestSignature();
        break;
      default:
        break;
    }
  };

  onClickOutside(event) {
    if (this.componentRef && !this.componentRef.contains(event.target)) {
      this.setState({
        showActions: false,
      });
    }
  }

  onClickRegisterTraining = (signature = null) => {
    const {
      elementId,
      personId,
      onChangeStatus,
      pending,
      status,
      showModal,
      hideModal,
      elementName,
    } = this.props;

    this.setState({ showActions: false });
    const registerTraining = () => {
      this.setState({ isLoading: true, showActions: false });
      return registerTrainingService({
        personId,
        elementId,
        pending,
        type: TRAINING_REGISTRATION_TYPES.STANDARD,
        signature,
      }).then(() => {
        //this.setState({ isLoading: false });
        onChangeStatus(elementId).then(() => {
          this.setState({ isLoading: false });
          if (
            status === STATUS_CATEGORIES.VALID_COMPLETION &&
            pending === PENDING_STATES.NONE
          )
            this.showCompletionOverlay();
        });
      });
    };

    if (
      status === STATUS_CATEGORIES.VALID_COMPLETION &&
      pending === PENDING_STATES.ALL
    ) {
      showModal('confirmation', {
        title: localeLookup('translations.Start new multipart completion'),
        subtitle: elementName,
        maxWidth: '500px',
        body: localeLookup(
          'translations.This element is already completed. This action will start a new multipart completion and the element will not be completed until the other part has completed'
        ),
        confirmButtonText: localeLookup('translations.Continue'),
        //confirmButtonType: 'alert',
        onConfirm: () => {
          registerTraining();
          hideModal();
        },
      });
    } else {
      return registerTraining();
    }
  };

  onClickRequestSignature = () => {
    const {
      showModal,
      hideModal,
      elementName,
      personId,
      elementId,
      mediatorId,
      personName,
      pending,
      lookupPerson,
    } = this.props;
    const isMediatorArchived =
      lookupPerson(mediatorId)?.state === PERSON_STATES.ARCHIVED;
    getApproversService({ elementId, menteeId: personId }).then(
      ({ data: approvers }) => {
        // The only scenario where we can not show signature modal (If only 1 approver)
        // Is if "pending" is "ALL"
        if (approvers.length === 1 && pending !== PENDING_STATES.ALL) {
          this.onClickShowRequestSignatureModal({
            approverName: approvers[0].name,
            approverId: approvers[0].id,
            onCancel: hideModal,
          });
        } else {
          showModal('approvers', {
            title: localeLookup('translations.Receive signature'),
            subtitle: `${personName} · ${elementName}`,
            menteeId: personId,
            mediatorId,
            isMediatorArchived,
            elementId,
            fullWidth: true,
            cancelButtonText: localeLookup('translations.Back'),
            onCancel: hideModal,
            maxWidth: '500px',
            action: ACTION_STATES.REQUEST_SIGNATURE,
            pending,
            onConfirm: ({ selectedApproverName, selectedApproverId }) => {
              this.onClickShowRequestSignatureModal({
                approverName: selectedApproverName,
                approverId: selectedApproverId,
              });
            },
          });
        }
      }
    );
  };

  onClickShowHistory = () => {
    const {
      showModal,
      elementId,
      areaId,
      personId,
      onChangeStatus,
      elementName,
      personName,
      hideModal,
    } = this.props;
    this.setState({ promptUserForCounterSign: false });
    showModal('elementHistory', {
      title: localeLookup('translations.History'),
      subtitle: `${personName} · ${elementName}`,
      fullWidth: true,
      maxWidth: '500px',
      cancelButtonText: localeLookup('translations.Close'),
      elementId,
      areaId,
      personId,
      elementName,
      onClose: () => {
        hideModal();
        this.setState({ promptUserForCounterSign: true });
      },
      onChangeHistory: () => {
        onChangeStatus(elementId);
      },
    });
  };

  onClickShowRequestSignatureModal = ({
    approverName,
    approverId,
    onCancel,
  }) => {
    const {
      showModal,
      hideModal,
      elementName,
      personId,
      elementId,
      onChangeStatus,
      pending,
      personName,
      status,
    } = this.props;

    showModal('signature', {
      title: localeLookup('translations.Receive signature'),
      subtitle: `${personName} · ${elementName}`,
      maxWidth: '700px',
      infoText: `${localeLookup(
        'translations.You can receive a signature from'
      )} ${approverName} ${localeLookup('translations.to add a completion')}`,
      confirmButtonText: localeLookup('translations.Receive'),
      signee: approverName,
      signeeId: approverId,
      fullWidth: true,
      onCancel: onCancel || this.onClickRequestSignature,
      onConfirm: (signature) => {
        this.setState({ isLoading: true });
        return registerTrainingOnBehalfService({
          menteeId: personId,
          signeeId: approverId,
          elementId,
          signature: { signature, metadata: {} },
        }).then(() => {
          onChangeStatus(elementId).then(() => {
            hideModal();
            this.setState({ isLoading: false });
            if (
              status === STATUS_CATEGORIES.VALID_COMPLETION &&
              pending === PENDING_STATES.NONE
            )
              this.showCompletionOverlay();
          });
        });
      },
    });
  };

  onToggle(e) {
    const { showActions, isLoading } = this.state;
    if (isLoading) return;
    e.stopPropagation();
    this.setState({
      showActions: !showActions,
    });
  }

  showCompletionOverlay = () => {
    this.setState({ showOverlay: true }, () =>
      setTimeout(() => {
        this.setState({ showOverlay: false });
      }, 2000)
    );
  };

  renderMainButton = () => {
    const { status, tooltip } = this.props;
    const { isLoading, showActions } = this.state;
    if (tooltip) {
      return (
        <Tooltip placement="top" tooltip={tooltip}>
          <button
            type="button"
            className="status-button__button"
            onClick={showActions ? this.onToggle : this.onClick}
          >
            {isLoading ? (
              <Loader
                size="small"
                color={
                  status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION)
                    ? 'green'
                    : 'white'
                }
                numberOfDots={3}
              />
            ) : (
              <>
                <Icon
                  className="status-button__icon"
                  kind={showActions ? 'cross' : this.getIcon()}
                />
                {this.renderText()}
              </>
            )}
          </button>
        </Tooltip>
      );
    }
    return (
      <button
        type="button"
        className="status-button__button"
        disabled={isLoading}
        onClick={showActions ? this.onToggle : this.onClick}
      >
        {isLoading ? (
          <Loader
            size="small"
            color={
              status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION)
                ? 'green'
                : 'white'
            }
            numberOfDots={3}
          />
        ) : (
          <>
            <Icon
              className="status-button__icon"
              kind={showActions ? 'cross' : this.getIcon()}
            />
            {this.renderText()}
          </>
        )}
      </button>
    );
  };

  render() {
    const { size, slideMenuDirection, fullWidth } = this.props;
    const { isLoading, showActions, showOverlay } = this.state;
    const actions = [
      {
        title: this.getActionText(),
        icon: this.getIcon(),
        onClick: this.onClickAction,
        buttonKind: 'darkui',
      },
      {
        title: localeLookup('translations.History'),
        icon: 'calendar-check',
        onClick: this.onClickShowHistory,
        buttonKind: 'light-grey',
      },
    ];
    const modifierClasses = {
      'status-button--state-loading': isLoading,
      [`status-button--size-${size}`]: size,
      'status-button--full-width': fullWidth,
      [`status-button--menu-direction-${slideMenuDirection}`]:
        slideMenuDirection,
    };
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        ref={(el) => (this.componentRef = el)}
        className={cx(
          'status-button',
          modifierClasses,
          `status-button--color-${this.getColor()}`
        )}
      >
        {this.renderMainButton()}
        <AnimatePresence>
          {showOverlay && (
            <motion.div
              variants={animatedOverlayContainerVariants}
              key="overlay"
              className="status-button__overlay"
            >
              <motion.p
                variants={animatedOverlayTextVariants}
                className="status-button__overlay-text"
              >
                +1
              </motion.p>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          initial={closed}
          custom={slideMenuDirection}
          variants={accordionVariants}
          animate={showActions ? 'open' : 'closed'}
          className="status-button__actions"
        >
          {actions.map((action) => (
            <Button
              key={action.title}
              square
              icon={action.icon}
              kind={action.buttonKind}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}
              className="status-button__action"
            >
              {action.title}
            </Button>
          ))}
        </motion.div>
      </div>
    );
  }
}

StatusButton.defaultProps = {
  slideMenuDirection: 'left',
  fullWidth: false,
  relevance: RELEVANCE_STATES.RELEVANT,
};

StatusButton.propTypes = {
  areaId: PropTypes.string.isRequired,
  daysUntilExpiration: PropTypes.number.isRequired,
  elementId: PropTypes.string.isRequired,
  elementName: PropTypes.string.isRequired,
  hasHistory: PropTypes.bool.isRequired,
  hasValidity: PropTypes.bool.isRequired,
  mediatorId: PropTypes.string.isRequired,
  nextAction: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  pending: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  relevance: PropTypes.string,
  size: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  slideMenuDirection: PropTypes.string,
  personName: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isDemoOrganisation: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
};

export default connect(mapStateToProps)(
  withPersonLookup(WithModals(StatusButton))
);
