import React from 'react';
import Input from './Input';
import InputError from './InputError';
import InputLabel from './InputLabel';

const TextField = ({
  autoComplete,
  autoFocus,
  accept,
  className,
  id,
  innerRef,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  kind,
  size,
  label,
  error,
  name,
  required,
  disabled,
  maxLength,
  errors,
}) => {
  const getError = () => {
    if (error) return error;
    if (errors)
      return errors.map((error, i) => (
        <React.Fragment key={i}>
          <span>- {error}</span>
          <br />
        </React.Fragment>
      ));
  };
  return (
    <div className="text-field">
      <InputLabel required={required} htmlFor={id}>
        {label}
      </InputLabel>
      <Input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        accept={accept}
        className={className}
        id={id}
        disabled={disabled}
        name={name}
        innerRef={innerRef}
        onBlur={onBlur}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        kind={kind}
        size={size}
        fullWidth
      />
      <InputError>{getError()}</InputError>
    </div>
  );
};

export default TextField;
