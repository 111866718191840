/* eslint-disable arrow-body-style */
import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import localeLookup from '../../config/locale';
import {
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  EXPERT_ID,
  KNOWLEDGE_OWNER_ID,
  MAIN_STATUS_CATEGORIES,
  MENTOR_ID,
  PENDING_STATES,
  PERSON_STATES,
  RELEVANCE_STATES,
  SELF_STUDY_ID,
} from '../../constants';
import { compareLocal, sortBy } from '../../utils/helpers';
import Button from '../Button';
import WithModals from '../HOC/withModals';
import withPersonLookup from '../HOC/withPersonLookup';
import PersonWrapper from '../PersonWrapper';
import StatusButton from '../StatusButton';
import Text from '../Text';
import Tooltip from '../Tooltip';

const accordionVariants = {
  closed: (props) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    height: 0,
    top: props.position === 'top' ? 0 : '100%',
    y: props.position === 'top' ? '-100%' : 0,
  }),
  open: {
    height: 'auto',
  },
};

const DashboardKnowledgeElement = ({
  allPersons,
  areaExpertsIds,
  areaId,
  areaKnowledgeOwnerId,
  canSelect,
  element,
  hideModal,
  lookupPerson,
  mentorId,
  onChangeElementStatus,
  onClick,
  onClickSubmitChangeSingleMediator,
  personElement,
  personId,
  selectedElements,
  showModal,
  showResponsibles,
  wildcardPersons,
}) => {
  const [showDrawer, toggleShowDrawer] = useState(false);
  const componentRef = useRef(null);

  const getMediatorOptions = ({
    defaultMediatorId,
    mediatorId,
    isMediatorDefault,
  }) => {
    const sortedPersonIds = sortBy(Object.keys(allPersons), [
      (a, b) => compareLocal(allPersons[a]?.name, allPersons[b]?.name),
    ]);
    const options = sortedPersonIds.reduce(
      (acc, id) => {
        const person = allPersons[id];
        if (person.state !== PERSON_STATES.ARCHIVED) {
          acc.push({
            value: id,
            label: person.name,
            tooltip: `${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`,
            searchString: `${person.name}${person.employeeNumber}${person.initials}`,
          });
        }
        return acc;
      },
      [
        {
          label: localeLookup('translations.Standard setting'),
          value: 0,
          subtitle: (
            <PersonWrapper
              id={defaultMediatorId}
              isLocked={element.lockedApprover}
              render={({ name, color }) => (
                <Text as="span" color={color}>
                  {name}
                </Text>
              )}
            />
          ),
        },
      ]
    );

    const isMediatorInOptions =
      options.find((option) => option.value === mediatorId) !== undefined;
    if (!isMediatorInOptions && !isMediatorDefault) {
      const mediator = lookupPerson(mediatorId);
      return [
        {
          label: mediator.name,
          value: mediatorId,
          searchString: mediator.isWildcardPerson
            ? mediator.name
            : `${mediator.name}${mediator.initials}${mediator.email}`,
          disabled: true,
        },
        ...options,
      ];
    }
    return options;
  };

  const onChangeMediatorClick = () => {
    const isMediatorDefault = personElement.mediatorType === 'Default';
    const options = getMediatorOptions({
      defaultMediatorId: personElement.defaultMediator,
      isMediatorDefault,
      mediatorId: personElement.mediator,
    });
    showModal('radioButton', {
      title: localeLookup('translations.Change responsible'),
      fullWidth: true,
      options,
      selectedValue: isMediatorDefault ? 0 : personElement.mediator,
      confirmBtnText: localeLookup('translations.Change'),
      onConfirm: (mediatorId) => {
        onClickSubmitChangeSingleMediator({
          elementId: element.id,
          personId,
          mediatorId,
        });
        /* updateKnowledgeElementMediator(areaId, elementId, mediatorId); */
      },
    });
  };

  const onClickDocument = (e) => {
    if (!componentRef?.current?.contains(e.target)) {
      toggleShowDrawer(false);
    }
  };

  const onClickShowHistory = () => {
    showModal('elementHistory', {
      title: localeLookup('translations.History'),
      subtitle: `${allPersons[personId].name} · ${element.name}`,
      fullWidth: true,
      maxWidth: '500px',
      cancelButtonText: localeLookup('translations.Close'),
      elementId: element.id,
      areaId,
      personId,
      elementName: element.name,
      onClose: () => {
        hideModal();
      },
      onChangeHistory: () => {
        onChangeElementStatus({ elementId: element.id, personId });
      },
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickDocument);
    return () => {
      document.removeEventListener('mousedown', onClickDocument);
    };
  });
  const onClickApprover = () => {
    if (canSelect) {
      onClick({ personId, elementId: element.id });
    } else {
      toggleShowDrawer(!showDrawer);
    }
  };
  const onChangeStatus = () => {
    return onChangeElementStatus({ personId, elementId: element.id });
  };

  const getApprover = () => {
    const mediatorId = personElement.mediator;
    if (allPersons[mediatorId]) {
      const person = allPersons[mediatorId];
      return {
        name: `${allPersons[mediatorId].name}${
          person.state === PERSON_STATES.ARCHIVED
            ? ` (${localeLookup('translations.Archived')})`
            : ''
        } (${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`,
        initials: allPersons[mediatorId].initials,
      };
    }
    if (mediatorId === SELF_STUDY_ID) {
      // Return string "self study"
      return {
        name: wildcardPersons[mediatorId].name,
        initials: '(S)',
      };
    }
    if (mediatorId === MENTOR_ID) {
      const mentor = allPersons[mentorId];
      if (mentorId && mentor) {
        return {
          name: `${allPersons[mentorId].name} (${mentor.initials}${
            mentor.employeeNumber ? ` · ${mentor.employeeNumber}` : ''
          })`,
          initials: allPersons[mentorId].initials,
        };
      }
      return {
        name: wildcardPersons[mediatorId].name,
        initials: '(MEN)',
      };
    }
    if (mediatorId === KNOWLEDGE_OWNER_ID) {
      // If there is a knowledge owner return person's name else return string "knowledge owner"
      if (areaKnowledgeOwnerId && allPersons[areaKnowledgeOwnerId]) {
        return allPersons[areaKnowledgeOwnerId].name;
      }
      return {
        name: wildcardPersons[mediatorId].name,
        initials: '(K)',
      };
    }
    if (mediatorId === EXPERT_ID) {
      // If there only 1 expert return person's name else return string "expert"
      if (areaExpertsIds && areaExpertsIds.length === 1) {
        return { name: allPersons[areaExpertsIds[0]].name };
      }
      if (areaExpertsIds && areaExpertsIds.length > 1) {
        return areaExpertsIds.map((expertId) => {
          const person = allPersons[expertId];
          if (!person) return;
          return {
            name: `${person.name} (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber}` : ''
            })`,
          };
        });
      }
      return {
        name: wildcardPersons[mediatorId].name,
        initials: '(E)',
      };
    }
    if (mediatorId === DELETED_PERSON_ID) {
      // Return string "Deleted person"
      return { name: wildcardPersons[mediatorId].name, initials: '(N/A)' };
    }
    if (mediatorId === DELETED_GROUP_ID) {
      // Return string "Deleted group"
      return { name: wildcardPersons[mediatorId].name, initials: '(N/A)' };
    }
    if (mediatorId === EMPTY_ID && element.lockedApprover) {
      return {
        name: localeLookup('translations.Relevant person'),
        initials: '(RP)',
      };
    }
    return null;
  };

  const getTooltipText = () => {
    const approver = getApprover();
    const person = allPersons[personId];
    const isElementNotRelevantAndNotCompleted =
      personElement.relevance === RELEVANCE_STATES.NOT_RELEVANT &&
      (personElement.status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION) ||
        personElement.status.startsWith(
          MAIN_STATUS_CATEGORIES.INVALID_COMPLETION
        ));
    if (!isElementNotRelevantAndNotCompleted && !approver) return '';
    return (
      <span>
        {isElementNotRelevantAndNotCompleted && (
          <>
            {localeLookup('translations.Not relevant')}
            <br />
          </>
        )}
        {isElementNotRelevantAndNotCompleted && approver && <br />}
        {approver && (
          <>
            {Array.isArray(approver) ? (
              <span>
                {localeLookup('translations.Responsible')}: {approver.name}
                {approver.map((approver) => (
                  <>
                    <br />
                    {approver.name}
                  </>
                ))}
              </span>
            ) : (
              <span>
                {localeLookup('translations.Responsible')}: {approver.name}
                {personElement.pending !== PENDING_STATES.NONE
                  ? `\n & ${person.name} (${person.initials}${
                      person.employeeNumber ? ` · ${person.employeeNumber}` : ''
                    })`
                  : ''}
              </span>
            )}
          </>
        )}
      </span>
    );
  };

  const isSelected = selectedElements[element.id]?.persons?.includes(personId);
  const hideApprover =
    personElement.mediator === EMPTY_ID && !element.lockedApprover;
  const modifierClasses = {
    'dashboard-knowledge-element--state-selectable': canSelect,
    'dashboard-knowledge-element--state-selected': isSelected,
    'dashboard-knowledge-element--training-task': personElement.isTrainingTask,
  };
  return (
    <div className={cx('dashboard-knowledge-element', modifierClasses)}>
      {showResponsibles && (
        <Tooltip placement="top" tooltip={getTooltipText()}>
          <div className="dashboard-knowledge-element__left" ref={componentRef}>
            <button
              type="button"
              onClick={onClickApprover}
              className="dashboard-knowledge-element__left-button"
            >
              {!hideApprover && (
                <>
                  <PersonWrapper
                    id={personElement.mediator}
                    isTrainingTask={personElement.isTrainingTask}
                    isLocked={element.lockedApprover}
                    render={({ name, color }) => (
                      <Text truncate size="sm" color={color}>
                        {name}
                      </Text>
                    )}
                  />
                  {personElement.pending !== PENDING_STATES.NONE && (
                    <PersonWrapper
                      id={personId}
                      render={({ name }) => (
                        <Text truncate color="dark-grey" size="sm">
                          & {name}
                        </Text>
                      )}
                    />
                  )}
                </>
              )}
            </button>
            <motion.div
              initial="closed"
              custom={{ position: 'bottom' }}
              variants={accordionVariants}
              animate={showDrawer ? 'open' : 'closed'}
              className="dashboard-knowledge-element__drawer"
            >
              <Button
                fullWidth
                square
                icon="users2"
                kind="light-grey"
                onClick={onChangeMediatorClick}
                className="dashboard-knowledge-element__drawer-action"
              >
                {localeLookup('translations.Responsible')}
              </Button>
              <Button
                fullWidth
                square
                icon="calendar-check"
                kind="light-grey"
                onClick={onClickShowHistory}
                className="dashboard-knowledge-element__drawer-action"
              >
                {localeLookup('translations.History')}
              </Button>
            </motion.div>
          </div>
        </Tooltip>
      )}
      <StatusButton
        fullWidth={!showResponsibles}
        areaId={areaId}
        daysUntilExpiration={personElement.daysUntilExpiration}
        elementId={element.id}
        elementName={element.name}
        hasHistory={personElement.hasHistory}
        hasValidity={personElement.hasValidity}
        mediatorId={personElement.mediator}
        nextAction={personElement.nextAction}
        onChangeStatus={onChangeStatus}
        pending={personElement.pending}
        personId={personId}
        personName={allPersons[personId].name}
        relevance={personElement.relevance}
        size="small"
        slideMenuDirection={'down'}
        status={personElement.status}
        tooltip={!showResponsibles ? getTooltipText() : undefined}
      />
    </div>
  );
};

DashboardKnowledgeElement.propTypes = {
  areaExpertsIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  areaKnowledgeOwnerId: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  mentorId: PropTypes.string.isRequired,
  personElement: PropTypes.shape({
    daysUntilExpiration: PropTypes.number.isRequired,
    hasHistory: PropTypes.bool.isRequired,
    nextAction: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  personId: PropTypes.string.isRequired,
  wildcardPersons: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeElementStatus: PropTypes.func.isRequired,
};

export default withPersonLookup(WithModals(DashboardKnowledgeElement));
