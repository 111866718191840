import React from 'react';
import localeLookup from '../../config/locale';
import CreateGroupForm from '../CreateGroupForm';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';

const CreateGroupModal = ({ onClose, onCreated, initialName }) => (
  <FormModal
    onClose={onClose}
    title={localeLookup('translations.Create group')}
  >
    <CreateGroupForm
      onCreated={onCreated}
      initialName={initialName}
      renderFooter={({ handleSubmit, canSubmit }) => (
        <ModalFooter
          confirmButtonText={localeLookup('translations.Create')}
          confirmDisabled={!canSubmit}
          onConfirmClick={handleSubmit}
          onCancelClick={onClose}
        />
      )}
    />
  </FormModal>
);

export default CreateGroupModal;
