import cx from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Aside from '../components/Aside';
import ChampLinkForm from '../components/ChampLinkForm';
import DateTime from '../components/DateTime';
import KnowledgeArea from '../components/KnowledgeArea';
import MainArea from '../components/MainArea';
import Modal from '../components/Modal';
import InfoModal from '../components/modal/InfoModal';
import RichTextModal from '../components/modal/RichTextModal';
import Page from '../components/Page';
import Portal from '../components/Portal';
import localeLookup from '../config/locale';
import { getLinkService } from '../services/knowledgeService';
import { insertStringInString } from '../utils/helpers';
import { trackEvent } from '../utils/tracking';

const mapStateToProps = (state) => ({
  champLinkPrefix: state.user.champLinkPrefix,
});

const mapDispatchToProps = {};

class ChampLink extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      data: {},
      activeModal: '',
      modalProps: {},
    };
  }

  componentDidMount() {
    trackEvent('$pageview');
  }

  getLink = (link) => {
    this.setState({ isLoading: true });
    return getLinkService(link.toLowerCase())
      .then(({ data }) => {
        if (!data || data.linkType === null) {
          this.setState({ isLoading: false });
          this.showModal('information', {
            title: localeLookup('translations.Content deleted'),
            body: (
              <>
                {data.info.knowledgeElementName.length > 0 && (
                  <p>
                    {localeLookup('translations.Element name')}: {data.info.knowledgeElementName}
                  </p>
                )}
                <p>
                  {localeLookup('translations.Area name')}: {data.info.knowledgeAreaName}
                </p>
                <p>
                  {localeLookup('translations.Owner')}: {data.info.knowledgeAreaOwner}
                </p>
                <p>
                  {localeLookup('translations.Deleted by')}: {data.info.deletedBy}
                </p>
                <p>
                  {localeLookup('translations.Deleted')}: <DateTime date={data.info.knowledgeDeletionDate}></DateTime>
                </p>
              </>
            ),
          });
        } else if (data.linkType === 'KnowledgeArea') {
          this.addLinkToStorage(link, data.knowledgeArea.name);
          this.setState({ data, isLoading: false });
        } else {
          const { knowledgeElements } = data;
          const element = knowledgeElements[Object.keys(data.knowledgeElements)[0]];
          this.setState({ data: {} });
          this.onShowDescriptionPress({
            name: element.name,
            champLink: element.champLink,
            files: element.files,
            description: element.description,
          });
          this.addLinkToStorage(link, element.name);
        }
      })
      .catch((err) => this.handleLinkError({ response: err }));
  };

  getPreviousVisitedLinks = () => {
    const { champLinkPrefix } = this.props;
    const previousLinks = JSON.parse(window.localStorage.getItem('champLinks'));
    if (previousLinks) {
      return previousLinks.filter((linkObject) => linkObject.link.startsWith(champLinkPrefix));
    }
    return [];
  };

  handleLinkError = ({ response }) => {
    const { data, status } = response;
    switch (status) {
      case 403:
        this.showModal('information', {
          title: localeLookup('translations.Login required'),
          body: localeLookup('translations.You need to log in to access the requested link'),
        });
        break;
      default:
        this.showModal('information', {
          title: localeLookup('translations.Not found'),
          body: localeLookup('translations.The requested link could not be found'),
        });
        break;
    }
    this.setState({ isLoading: false });
  };

  onShowDescriptionPress = ({ description, files, name, champLink }) => {
    this.showModal('richText', {
      title: name,
      subtitle: insertStringInString(champLink, '-', 3),
      files,
      description,
      fullWidth: true,
    });
  };

  onSubmit = (champLinkValue) => {
    return this.getLink(champLinkValue);
  };

  hideModal = () => {
    this.setState({
      activeModal: '',
      modalProps: {},
    });
  };

  addLinkToStorage = (link, title) => {
    const ls = window.localStorage;
    const previousLinks = ls.getItem('champLinks');
    if (previousLinks) {
      const previousLinksParsed = JSON.parse(previousLinks);
      if (previousLinksParsed.find((linkObject) => linkObject.link === link)) return;
      ls.setItem('champLinks', JSON.stringify([...previousLinksParsed, { link, title }]));
    } else {
      ls.setItem('champLinks', JSON.stringify([{ link, title }]));
    }
  };

  showModal = (type, props = {}) => {
    const { modalProps } = this.state;
    this.setState({
      activeModal: type,
      modalProps: { ...modalProps, ...props },
    });
  };

  renderArea = () => {
    const { data } = this.state;
    const { knowledgeArea, knowledgeElements, persons } = data;
    return (
      <KnowledgeArea
        knowledgeElementsSortOrder={knowledgeArea.knowledgeElements}
        champLink={knowledgeArea.champLink}
        knowledgeElements={knowledgeElements}
        persons={persons}
        name={knowledgeArea.name}
        knowledgeOwner={knowledgeArea.knowledgeOwner}
        experts={knowledgeArea.experts}
        isReadOnly
        onShowDescriptionClick={this.onShowDescriptionPress}
        showModal={this.showModal}
      />
    );
  };

  renderContent = () => {
    const { data } = this.state;
    if (data.linkType === 'KnowledgeArea') return this.renderArea();
  };

  renderModals = () => {
    const { activeModal, modalProps } = this.state;

    return (
      <Modal
        maxWidth="700px"
        fullWidth={modalProps.fullWidth}
        isOpen={activeModal}
        onCloseClick={this.hideModal}
        render={({ onCloseClick }) => {
          if (activeModal === 'richText') {
            return <RichTextModal title={modalProps.title} subtitle={modalProps.subtitle} body={modalProps.description} files={modalProps.files} onClose={onCloseClick} />;
          }
          if (activeModal === 'information') {
            return <InfoModal onClose={modalProps.onClose || onCloseClick} body={modalProps.body} title={modalProps.title} />;
          }
        }}
      />
    );
  };

  render() {
    const { data } = this.state;
    const { champLinkPrefix } = this.props;
    const hasLink = data.linkType === 'KnowledgeArea';

    return (
      <Page className={cx('champ-link', { 'champ-link--active': hasLink })}>
        <Aside wide className="champ-link__sidebar">
          <ChampLinkForm previousLinks={this.getPreviousVisitedLinks()} defaultValue={champLinkPrefix} onSubmit={this.onSubmit} />
        </Aside>
        <MainArea className="champ-link__main" backgroundColor={hasLink ? 'white' : 'grey'}>
          {this.renderContent()}
        </MainArea>
        <Portal>{this.renderModals()}</Portal>
      </Page>
    );
  }
}

ChampLink.propTypes = {};

export default connect(mapStateToProps)(ChampLink);
