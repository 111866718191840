import React, { Component } from 'react';
import cx from 'classnames';

class ContextMenuFixed extends Component {
  constructor() {
    super();
    this.onWindowClick = this.onWindowClick.bind(this);
  }
  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
    window.addEventListener('resize', this.props.hideContextMenu);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
    window.removeEventListener('resize', this.props.hideContextMenu);
  }

  onWindowClick(event) {
    const { contextMenuTrigger, hideContextMenu, open } = this.props;
    if (contextMenuTrigger) {
      if (
        contextMenuTrigger !== event.target &&
        !contextMenuTrigger.contains(event.target)
      ) {
        hideContextMenu();
      }
    } else if (open) {
    }
  }

  getPosition() {
    const { mousePosition } = this.props;

    if (this.contextMenu) {
      const contextMenuRightPosition =
        mousePosition.x + this.contextMenu.scrollWidth;
      const contextMenuBottomPosition =
        mousePosition.y + this.contextMenu.scrollHeight;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (contextMenuRightPosition > windowWidth) {
        return contextMenuBottomPosition > windowHeight
          ? 'top-left'
          : 'bottom-left';
      }
      return contextMenuBottomPosition > windowHeight
        ? 'top-right'
        : 'bottom-right';
    }
    return '';
  }

  render() {
    const { mousePosition, open } = this.props;
    const position = this.getPosition();

    const menuModifierClass = cx({
      'context-menu-fixed--bottom-left': position === 'bottom-left',
      'context-menu-fixed--bottom-right':
        position === 'bottom-right' || position === undefined,
      'context-menu-fixed--top-left': position === 'top-left',
      'context-menu-fixed--top-right': position === 'top-right',
    });

    const containerClasses = cx({
      'context-menu-fixed--open': open,
    });

    return (
      <ul
        className={`context-menu-fixed ${containerClasses} ${menuModifierClass}`}
        ref={(menu) => {
          this.contextMenu = menu;
        }}
        style={{ left: mousePosition.x, top: mousePosition.y }}
      >
        {this.props.children}
      </ul>
    );
  }
}

export default ContextMenuFixed;
