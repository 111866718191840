import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import KnowledgeDashboard from '../../components/dashboard/KnowledgeDashboard';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorState from '../../components/ErrorState';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithEditorActions from '../../components/HOC/withEditorActions';
import WithModals from '../../components/HOC/withModals';
import KnowledgeEditorContent from '../../components/KnowledgeEditorContent';
import KnowledgeEditorNav from '../../components/KnowledgeEditorNav';
import KnowledgeEditorRole from '../../components/KnowledgeEditorRole';
import KnowledgeEditorSettings from '../../components/KnowledgeEditorSettings';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import Page from '../../components/Page';
import { getAreaDashbordService } from '../../services/dashboardService';
import { getDashboardAccessService } from '../../services/knowledgeEditorService';
import { getAllAreas, queryAreas } from '../../slices/areasSlice';
import {
  getAllCategories,
  selectCategoriesSortOrder,
} from '../../slices/categoriesSlice';
import { getAreasAccess, getRolesAccess } from '../../slices/editorSlice';
import { queryElements } from '../../slices/elementsSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { getAllRoles, queryRoles } from '../../slices/rolesSlice';
import {
  getActiveSpace,
  getAllSpaces,
  getSpaceStatus,
} from '../../slices/spaceSlice';
import { trackEvent } from '../../utils/tracking';

const mapStateToProps = (state) => {
  const {
    categories,
    organisationUnits,
    roles,
    wildcardPersons,
    persons,
    areas,
  } = state;
  return {
    allowChangeOfChampLinkVisibility:
      state.rootmenu.allowChangeOfChampLinkVisibility,
    roles,
    categories,
    categoriesSortOrder: selectCategoriesSortOrder(state),
    organisationUnits,
    wildcardPersons,
    persons,
    areas,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAreasAccess,
      getRolesAccess,
      queryAreas,
      getAllAreas,
      getAllCategories,
      getAllOrganisationUnits,
      queryRoles,
      getAllRoles,
      queryElements,
      getAllSpaces,
      getSpaceStatus,
      getActiveSpace,
    },
    dispatch
  ),
});

class KnowledgeEditor extends Component {
  state = {
    isLoading: true,
    hasError: false,
    activeView: 'content',
    selectedRoleId: '',
    areaId: '',
    showDashboard: false,
  };

  componentDidMount() {
    trackEvent('$pageview');
    this.getData();
  }

  getArea = () => {
    const { areaId } = this.state;
    const { queryAreas } = this.props;
    return queryAreas([areaId]);
  };

  getData = () => {
    const {
      match,
      queryRoles,
      queryAreas,
      getAllRoles,
      getAllCategories,
      getAllOrganisationUnits,
      queryElements,
      getRolesAccess,
      getAreasAccess,
      getSpaceStatus,
      getAllSpaces,
      getActiveSpace,
    } = this.props;
    const areaId = match.params.id;
    this.setState({ areaId });

    getDashboardAccessService(areaId).then(() => {
      this.setState({ showDashboard: true });
    });

    Promise.all([
      queryAreas([areaId]),
      getAllRoles(),
      getRolesAccess(),
      getAreasAccess(),
      getAllCategories(),
      getAllOrganisationUnits(),
      getSpaceStatus(),
      getAllSpaces({}),
      getActiveSpace(),
    ]).then((responses) => {
      const [areaResponse, ...rest] = responses;
      queryElements(areaResponse[areaId].knowledgeElements).then(() => {
        const areaRoleIds = [
          ...areaResponse[areaId].attachedAsMandatoryToRoles,
          ...areaResponse[areaId].attachedAsAdditionalToRoles,
        ];

        if (areaRoleIds.length > 0) {
          queryRoles(areaRoleIds).then(() => {
            this.setState({ isLoading: false });
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
  };

  onClickRemoveAreaConnection = () => {
    this.setState({
      activeView: '',
      selectedRoleId: '',
    });
  };

  onSelectMenuItem = (view, roleId = '') => {
    this.setState({
      activeView: view,
      selectedRoleId: roleId,
    });
  };

  showAddRoleModal = ({ connectAsAdditional = false }) => {
    const { areaId } = this.state;
    const { editorActions } = this.props;
    editorActions.showChangeAreaRolesModal({ areaId, connectAsAdditional });
  };

  render() {
    const {
      allowChangeOfChampLinkVisibility,
      categories,
      categoriesSortOrder,
      persons,
      wildcardPersons,
      roles,
      organisationUnits,
      areas,
      hasAccessToArea,
      hasReadOnlyAccessToArea,
    } = this.props;
    const {
      isLoading,
      hasError,
      activeView,
      selectedRoleId,
      showDashboard,
      areaId,
    } = this.state;
    if (isLoading) return <LoadScreen />;
    if (hasError) return <ErrorState fullHeight />;
    const area = areas[areaId];
    const hasAreaAccess = hasAccessToArea(area.id);
    const hasReadOnlyAccess = hasReadOnlyAccessToArea(area.id);
    const isAreaReadOnly = hasAreaAccess ? hasReadOnlyAccess : true;
    return (
      <ErrorBoundary hasError={hasError}>
        <Page>
          <KnowledgeEditorNav
            activeView={activeView}
            allRoles={roles}
            organisationUnits={organisationUnits}
            onAddRoleClick={this.showAddRoleModal}
            onAddAsAddtionalToRoleClick={() =>
              this.showAddRoleModal({ connectAsAdditional: true })
            }
            onSelect={this.onSelectMenuItem}
            roles={area.attachedAsMandatoryToRoles}
            knowledgeElements={area.knowledgeElements}
            attachedAsAdditionalToRoles={area.attachedAsAdditionalToRoles}
            selectedId={selectedRoleId}
            showDashboard={showDashboard}
          />

          {activeView === 'settings' && (
            <MainArea>
              <KnowledgeEditorSettings
                area={area}
                isAreaReadOnly={isAreaReadOnly}
                persons={persons}
                categories={categories}
                categoriesSortOrder={categoriesSortOrder}
              />
            </MainArea>
          )}

          {activeView === 'status' && (
            <KnowledgeDashboard
              getData={() => getAreaDashbordService(area.id)}
            />
          )}

          {activeView === 'content' && (
            <MainArea>
              <KnowledgeEditorContent
                allowChangeOfChampLinkVisibility={
                  allowChangeOfChampLinkVisibility
                }
                isAreaReadOnly={isAreaReadOnly}
                area={area}
                persons={persons}
                wildcardPersons={wildcardPersons}
              />
            </MainArea>
          )}

          {activeView === 'role' &&
            (area.attachedAsMandatoryToRoles.includes(selectedRoleId) ||
              area.attachedAsAdditionalToRoles.includes(selectedRoleId)) && (
              <MainArea>
                <KnowledgeEditorRole
                  allowChangeOfChampLinkVisibility={
                    allowChangeOfChampLinkVisibility
                  }
                  isAreaReadOnly={isAreaReadOnly}
                  area={area}
                  persons={persons}
                  role={roles[selectedRoleId]}
                  onClickRemoveAreaConnection={this.onClickRemoveAreaConnection}
                  wildcardPersons={wildcardPersons}
                />
              </MainArea>
            )}
        </Page>
      </ErrorBoundary>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(withAccessControl(WithEditorActions(KnowledgeEditor))));
