import cx from 'classnames';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import InputError from './formElements/InputError';
import InputLabel from './formElements/InputLabel';
import Icon from './Icon';

const mapStateToProps = (state) => {
  const { rootmenu } = state;
  return {
    dateFormat: rootmenu?.dateFormat,
    timeFormat: rootmenu?.timeFormat,
    userLocale: state.user.locale,
  };
};

const DateField = ({
  onChange,
  minDate,
  value,
  maxDate,
  label,
  clearable,
  onClear,
  required,
  error,
  errors,
  onBlur,
  id,
  name,
  userLocale,
  dateFormat,
  showFormatAsPlaceholder,
  placeholderText,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const datepickerRef = React.useRef(null);

  const setLocale = async () => {
    if (userLocale === 'en') {
      setIsLoading(false);
      return;
    }

    if (userLocale === 'zh') {
      const module = await import(`date-fns/locale/${userLocale}-CN`);
      registerLocale(userLocale, module.default);
      setIsLoading(false);
      return;
    }

    try {
      const module = await import(`date-fns/locale/${userLocale}`);
      registerLocale(userLocale, module.default);
    } catch {}

    setIsLoading(false);
  };

  useEffect(() => {
    setLocale();
  }, []);

  const modifierClasses = {
    'date-field--no-value': !value,
  };

  const getDateFormat = () => {
    const DATE_FORMAT_STRINGS = {
      DayMonthYear: 'dd.MM.yyyy',
      MonthDayYear: 'MM.dd.yyyy',
      YearMonthDay: 'yyyy.MM.dd',
    };
    const dateFormatString = DATE_FORMAT_STRINGS[dateFormat] || 'dd.MM.yyyy';
    return dateFormatString;
  };

  const getError = () => {
    if (error) return error;
    if (errors)
      return errors.map((error, i) => (
        <React.Fragment key={i}>
          <span>- {error}</span>
          <br />
        </React.Fragment>
      ));
  };

  if (isLoading) return null;
  return (
    <div className={cx('date-field', modifierClasses)}>
      {label && (
        <InputLabel className="date-field__label" htmlFor="date" required={required}> 
          {label}
        </InputLabel>
      )}
      <div className="date-field__input-wrapper">
        <DatePicker
          ref={datepickerRef}
          calendarStartDay={1}
          className="date-field__input"
          dateFormat={getDateFormat()}
          focusSelectedMonth
          maxDate={maxDate}
          minDate={minDate}
          onBlur={onBlur}
          onChange={(date) => {
            onChange(date);
          }}
          id={id || 'date'}
          name={name}
          selected={value}
          portalId="portal-root"
          locale={userLocale}
          todayButton="Today"
          placeholderText={
            showFormatAsPlaceholder
              ? getDateFormat()?.toUpperCase?.()
              : placeholderText
          }
          handleCalendarClickOutside={() => {
            console.log('click outside');
          }}
        />
        {clearable && !!value && (
          <button onClick={onClear} className="date-field__clear-button">
            <Icon size="small" color="grey" kind="cross"></Icon>
          </button>
        )}
      </div>
      <InputError>{getError()}</InputError>
    </div>
  );
};

export default connect(mapStateToProps)(DateField);
