import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalFooter from './ModalFooter';

import ChangePasswordForm from '../ChangePasswordForm';
import FormModal from './FormModal';

const mapStateToProps = (state) => ({
  serverErrorMessage: state.userSettings.changePasswordError,
});

class ChangePasswordModal extends Component {
  render() {
    const {
      onClose,
      title,
      confirmButtonText,
      cancelButtonText,
      onCancelClick,
      isOwnPassword,
      subtitle,
      personId,
    } = this.props;
    return (
      <FormModal onClose={onClose} title={title} subtitle={subtitle}>
        <ChangePasswordForm
          personId={personId}
          isOwnPassword={isOwnPassword}
          onClose={onClose}
          renderFooter={({ handleSubmit, disableSubmit }) => (
            <ModalFooter
              confirmButtonText={confirmButtonText}
              confirmDisabled={disableSubmit}
              onConfirmClick={handleSubmit}
              cancelButtonText={cancelButtonText}
              onCancelClick={onCancelClick}
            />
          )}
        />
      </FormModal>
    );
  }
}

export default connect(mapStateToProps)(ChangePasswordModal);
