import cx from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import { EMPTY_ID } from '../../constants';
import { updateElementDashboardSignees } from '../../services/dashboardService';
import { getWildcardPersonsService } from '../../services/personsService';
import { getKnowledgeElementService } from '../../services/traineeService';
import { updateUserSettingService } from '../../services/userProfileService';
import { compareLocal, sortBy } from '../../utils/helpers';
import Loader from '../Loader';
import MainArea from '../MainArea';
import DashboardFilter from './DashboardFilter';
import KnowledgeDashboardContent from './KnowledgeDashboardContent';
import KnowledgeDashboardSidebar from './KnowledgeDashboardSidebar';

const mapStateToProps = (state) => ({
  allPersons: state.persons,
});

class KnowledgeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSelectElements: false,
      filterString: '',
      fullScreen: false,
      isLoading: true,
      knowledgeElements: {},
      personFilterOption: props.defaultFilterOption || 'persons',
      persons: {},
      personView: 'initials',
      selectedElements: {},
      showResponsibles: false,
      wildcardPersons: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { getData } = this.props;
    Promise.all([getData(), getWildcardPersonsService()]).then(
      ([{ data }, { data: wildcardPersons }]) => {
        this.setState({
          area: data.area,
          knowledgeElements: data.knowledgeElements,
          persons: data.persons,
          isLoading: false,
          showResponsibles: data.showResponsible,
          personView: data.showEmployeeNumber ? 'employeeNumber' : 'initials',
          wildcardPersons,
        });
      }
    );
  };

  getFilterOptions = () => {
    const { hideAllPersonsFilterOption } = this.props;
    const { area } = this.state;
    let options = [];
    if (area.yourMentees) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.Your training tasks')} (${
            area.yourMentees.length
          })`,
          value: 'yourMentees',
        },
      ];
    }

    if (area.mentees) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.All training tasks')} (${
            area.mentees.length
          })`,
          value: 'mentees',
        },
      ];
    }

    if (area.persons && !hideAllPersonsFilterOption) {
      options = [
        ...options,
        {
          title: `${localeLookup('translations.All with this knowledge')} (${
            area.persons.length
          })`,
          value: 'persons',
        },
      ];
    }
    return options;
  };

  getVisiblePersonIds = () => {
    const {
      filterString,
      personFilterOption,
      persons,
      area,
      personView,
      showResponsibles,
    } = this.state;
    const checkPersonMatch = (id) => {
      const person = persons[id];
      return `${person?.name}${person?.initials}${person?.employeeNumber}`
        .toLowerCase()
        .includes(filterString.toLowerCase());
    };
    const getIdentifier = (person) => {
      if (showResponsibles) return person.name;
      const identifier =
        personView === 'initials'
          ? person.initials
          : person.employeeNumber
          ? person.employeeNumber
          : person.initials;
      return identifier;
    };
    const sortFunction = (a, b) => {
      const personA = persons[a];
      const personB = persons[b];
      const personAIdentifier = getIdentifier(personA);
      const personBIdentifier = getIdentifier(personB);
      return compareLocal(personAIdentifier, personBIdentifier);
    };

    if (personFilterOption === 'yourMentees') {
      return sortBy(area.yourMentees.filter(checkPersonMatch), [sortFunction]);
    }
    if (personFilterOption === 'mentees') {
      return sortBy(area.mentees.filter(checkPersonMatch), [sortFunction]);
    }
    if (personFilterOption === 'persons') {
      return sortBy(area.persons.filter(checkPersonMatch), [sortFunction]);
    }
  };

  onChangeElementStatus = ({ elementId, personId }) => {
    const { persons } = this.state;
    return getKnowledgeElementService(personId, elementId).then(({ data }) => {
      this.setState({
        persons: {
          ...persons,
          [personId]: {
            ...persons[personId],
            knowledgeElements: {
              ...persons[personId].knowledgeElements,
              [elementId]: data.knowledgeElements[elementId],
            },
          },
        },
      });
    });
  };

  onChangeFilterOption = (e) => {
    this.setState({ personFilterOption: e.target.value });
  };

  onChangeFilterString = (e) => {
    this.setState({ filterString: e.target.value });
  };

  onChangePersonViewTab = (tab) => {
    updateUserSettingService({
      name: 'DashboardEmployeeNumberView',
      enabled: tab.id === 'employeeNumber',
    });
    this.setState({ personView: tab.id });
  };

  onClickSelectElement = ({ elementId, personId }) => {
    const { selectedElements } = this.state;
    const selectedElementObject = selectedElements[elementId];
    // If element is not selected, add the element with the person
    if (!selectedElementObject) {
      this.setState({
        selectedElements: {
          ...selectedElements,
          [elementId]: { persons: [personId] },
        },
      });
    } else {
      const isPersonSelected =
        selectedElementObject.persons?.includes(personId);
      // If person is not selected, but element is, add the person
      if (!isPersonSelected) {
        this.setState({
          selectedElements: {
            ...selectedElements,
            [elementId]: {
              persons: [...selectedElementObject.persons, personId],
            },
          },
        });
      }
      // If person and element and the element is already selected, remove the person
      else {
        const newElementPersonSelections = selectedElementObject.persons.filter(
          (id) => id !== personId
        );
        // If element selections is empty after removal, remove element selection
        if (newElementPersonSelections.length === 0) {
          const { [elementId]: value, ...withoutElementId } = selectedElements;
          this.setState({
            selectedElements: withoutElementId,
          });
        }
        // If element selections is not empty after removal, remove person selection
        else {
          this.setState({
            selectedElements: {
              ...selectedElements,
              [elementId]: {
                ...selectedElementObject,
                persons: newElementPersonSelections,
              },
            },
          });
        }
      }
    }
  };

  onClickShowElementDescription = (element) => {
    const { showModal } = this.props;
    showModal('richText', {
      title: element.name,
      description: element.description,
      files: element.files,
      maxWidth: '700px',
      fullWidth: true,
    });
  };

  onClickSubmitChangeSingleMediator = ({ elementId, personId, mediatorId }) => {
    const { area, persons } = this.state;
    updateElementDashboardSignees(area.id, {
      signeeId: mediatorId === 0 ? EMPTY_ID : mediatorId,
      knowledgeElements: { [elementId]: { persons: [personId] } },
    }).then(({ data }) => {
      this.setState({
        persons: Object.keys(persons).reduce((acc, id) => {
          if (data.persons[id]) {
            return { ...acc, [id]: { ...persons[id], ...data.persons[id] } };
          }
          return {
            ...acc,
            [id]: persons[id],
          };
        }, {}),
      });
    });
  };

  onClickToggleFullScreen = () => {
    const { fullScreen } = this.state;
    this.setState({
      fullScreen: !fullScreen,
    });
  };

  onClickToggleShowResponsibles = () => {
    const { showResponsibles } = this.state;
    updateUserSettingService({
      name: 'DashboardShowResponsible',
      enabled: !showResponsibles,
    });
    this.setState({
      showResponsibles: !showResponsibles,
      canSelectElements: false,
      selectedElements: {},
    });
  };

  render() {
    const { allPersons, dashboardType } = this.props;
    const {
      isLoading,
      area,
      knowledgeElements,
      persons,
      filterString,
      personFilterOption,
      canSelectElements,
      selectedElements,
      wildcardPersons,
      showResponsibles,
      fullScreen,
      personView,
    } = this.state;
    const visiblePersonIds = !isLoading && this.getVisiblePersonIds();

    const modifierClasses = {
      'dashboard--state-compact': !showResponsibles,
    };

    return (
      <MainArea backgroundColor="grey" fullScreen={fullScreen}>
        <div className={cx('dashboard', modifierClasses)}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DashboardFilter
                filterOptions={this.getFilterOptions()}
                filterString={filterString}
                fullScreen={fullScreen}
                onChangeFilterOption={this.onChangeFilterOption}
                onChangeFilterString={this.onChangeFilterString}
                onChangePersonViewTab={this.onChangePersonViewTab}
                onClickToggleFullScreen={this.onClickToggleFullScreen}
                onClickToggleShowResponsibles={
                  this.onClickToggleShowResponsibles
                }
                hideFilterSelect={dashboardType === 'trainingKnowledgeArea'}
                personFilterOption={personFilterOption}
                personView={personView}
                showResponsibles={showResponsibles}
              />
              <div className="dashboard__main">
                <KnowledgeDashboardSidebar
                  knowledgeElementsSortOrder={area.knowledgeElements}
                  knowledgeElements={knowledgeElements}
                  areaName={area.name}
                />
                <KnowledgeDashboardContent
                  allPersons={allPersons}
                  areaId={area.id}
                  canSelectElements={canSelectElements}
                  experts={area.experts}
                  knowledgeElements={knowledgeElements}
                  knowledgeElementsSortOrder={area.knowledgeElements}
                  knowledgeOwner={area.knowledgeOwner}
                  onChangeElementStatus={this.onChangeElementStatus}
                  onClickSelectElement={this.onClickSelectElement}
                  persons={persons}
                  selectedElements={selectedElements}
                  showResponsibles={showResponsibles}
                  visiblePersonIds={visiblePersonIds}
                  wildcardPersons={wildcardPersons}
                  onClickSubmitChangeSingleMediator={
                    this.onClickSubmitChangeSingleMediator
                  }
                  personView={personView}
                />
              </div>
            </>
          )}
        </div>
      </MainArea>
    );
  }
}

export default connect(mapStateToProps)(KnowledgeDashboard);
