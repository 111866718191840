/**
 * Check if value type String
 * @param {*} val to test
 * @returns {boolean}
 */
export const isString = val => typeof val === 'string';

/**
 * Check if value type is Array
 * @param {*} val to test
 * @returns {boolean}
 */
export const isArray = val => Array.isArray(val);

/**
 * Check if value type is Number
 * @param {*} val to test
 * @returns {boolean}
 */
export const isNumber = val => typeof val === 'number';

/**
 * Check if value type is Boolean
 * @param {*} val to test
 * @returns {boolean}
 */
export const isBoolean = val => typeof val === 'boolean';

/**
 * Check if value type is Object
 * @param {*} val to test
 * @returns {boolean}
 */
export const isObject = val => typeof val === 'object';

/**
 * Check if value type is Function
 * @param {*} val to test
 * @returns {boolean}
 */
export const isFunction = val => typeof val === 'function';

/**
 * Check if value type is Null
 * @param {*} val to test
 * @returns {boolean}
 */
export const isNull = val => val === null;

/**
 * Check if value type is Undefined
 * @param {*} val to test
 * @returns {boolean}
 */
export const isUndefined = val => val === undefined;

/**
 * Check if value type is Undefined
 * @param {*} val to test
 * @returns {boolean}
 */
export const isNothing = val => isNull(val) || isUndefined(val);

export default {
  isString,
  isArray,
  isNumber,
  isBoolean,
  isObject,
  isFunction,
  isNull,
  isUndefined,
  isNothing,
};
