import React from 'react';
import DashboardKnowledgeElement from './DashboardKnowledgeElementV2';

const KnowledgeDashboardPerson = ({
  allPersons,
  canSelectElements,
  experts,
  id,
  knowledgeElements,
  knowledgeElementsSortOrder,
  knowledgeOwner,
  mentorId,
  onChangeElementStatus,
  onClickSelectElement,
  personKnowledgeElements,
  selectedElements,
  showResponsibles,
  wildcardPersons,
  areaId,
  onClickSubmitChangeSingleMediator,
}) => {
  const renderKnowledgeElement = (elementId, index, arr) => {
    const personElement = personKnowledgeElements[elementId];
    return (
      <DashboardKnowledgeElement
        allPersons={allPersons}
        areaExpertsIds={experts}
        areaId={areaId}
        areaKnowledgeOwnerId={knowledgeOwner}
        canSelect={canSelectElements}
        element={knowledgeElements[elementId]}
        isLast={arr.length === index + 1}
        key={elementId}
        mentorId={mentorId}
        onChangeElementStatus={onChangeElementStatus}
        onClick={onClickSelectElement}
        onClickSubmitChangeSingleMediator={onClickSubmitChangeSingleMediator}
        personElement={personElement}
        personId={id}
        selectedElements={selectedElements}
        showResponsibles={showResponsibles}
        wildcardPersons={wildcardPersons}
      />
    );
  };

  return <div className="dashboard-person">{knowledgeElementsSortOrder.map(renderKnowledgeElement)}</div>;
};

export default KnowledgeDashboardPerson;
