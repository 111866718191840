import React from 'react';

// Components
import Icon from './Icon';
import Tooltip from './Tooltip';

const PersonCard = ({
  onEditClick,
  onRemove,
  person,
  subtitle,
  showEdit,
  name,
}) => {
  const titleTooltip = `${person.initials} ${
    person.employeeNumber ? `· ${person.employeeNumber}` : ''
  }`;
  return (
    <div className="person-card">
      <div className="person-card__img">
        <Icon kind="user" />
      </div>
      <div className="person-card__info">
        <Tooltip tooltip={titleTooltip}>
          <p className="person-card__info-name">{name || person?.name}</p>
        </Tooltip>
        <p className="person-card__info-subtitle">{subtitle}</p>
      </div>
      <div className="person-card__icon">
        {showEdit && (
          <div className="person-card__icon-wrapper" onClick={onEditClick}>
            <Icon className="person-card__icon" kind="pencil" />
          </div>
        )}
        {onRemove && (
          <div className="person-card__icon-wrapper" onClick={onRemove}>
            <Icon className="person-card__icon-minus" kind="circle-minus" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonCard;
