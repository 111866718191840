import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeAreaLinkState,
  setTraineeAreaViewState,
  updateKnowledgeAreaStatus,
  updateKnowledgeElement,
  updateTraineeRoleArea,
  updateArea,
} from '../../actions/traineeRoleActions';
import { requestTraineeRoleData } from '../../actions/traineeRolesActions';
import LoadScreen from '../../components/LoadScreen';
import ErrorState from '../../components/ErrorState';
import Role from '../../components/Role';
import { rootmenuSelector } from '../../reducers/rootmenuReducer';
import ErrorBoundary from '../../components/ErrorBoundary';
import { trackEvent } from '../../utils/tracking';
import { getWildcardPersonsService } from '../../services/personsService';

const mapStateToProps = (state) => ({
  role: state.trainee.selectedRole,
  persons: state.persons,
  wildcardPersons: state.trainee.wildcardPersons,
  loggedInUserId: state.user.employeeId,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateKnowledgeAreaStatus,
      changeAreaLinkState,
      setTraineeAreaViewState,
      updateTraineeRoleArea,
      updateKnowledgeElement,
      requestTraineeRoleData,
      updateArea,
    },
    dispatch
  ),
});

class TraineeRole extends Component {
  state = {
    isLoading: true,
    hasError: false,
    wildcardPersons: {},
  };

  componentDidMount() {
    trackEvent('$pageview');
    getWildcardPersonsService()
      .then(({ data }) => {
        this.setState({ wildcardPersons: data });
      })
      .then(this.getRole)
      .catch(() => {
        this.setState({
          isLoading: false,
          hasError: true,
        });
      });
  }

  getRole = () => {
    const { match, requestTraineeRoleData } = this.props;

    return requestTraineeRoleData(match.params.id).then(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  onCloseHistoryModal = () => {
    const { role, requestTraineeRoleData } = this.props;
    requestTraineeRoleData(role.id, false);
  };

  onUpdateElement = ({ elementId, areaId }) => {
    const { loggedInUserId, updateKnowledgeElement, updateArea, role } =
      this.props;
    return updateKnowledgeElement(loggedInUserId, elementId).then(() => {
      updateArea({ areaId, roleId: role.id });
    });
  };

  render() {
    const { isLoading, hasError, wildcardPersons } = this.state;
    const {
      changeAreaLinkState,
      loggedInUserId,
      role,
      setTraineeAreaViewState,
      updateTraineeRoleArea,
      updateKnowledgeAreaStatus,
      persons,
    } = this.props;
    if (isLoading) return <LoadScreen />;
    if (hasError) return <ErrorState fullHeight />;

    return (
      <ErrorBoundary>
        <Role
          additionalKnowledgeAreasSortOrder={
            role.additionalKnowledgeAreasSortOrder
          }
          categories={role.categories}
          completionPercentage={role.completionPercentage}
          description={role.description}
          id={role.id}
          knowledgeAreas={role.knowledgeAreas}
          knowledgeAreasSortOrder={role.knowledgeAreasSortOrder}
          knowledgeElements={role.knowledgeElements}
          loggedInUserId={loggedInUserId}
          menteeId={loggedInUserId}
          mentor={role.mentor}
          startDate={role.startDate}
          endDate={role.endDate}
          mentorId={role.mentorId}
          name={role.name}
          onChangeAreaLinkState={changeAreaLinkState}
          onViewArea={setTraineeAreaViewState}
          persons={persons}
          updateKnowledgeAreaStatus={updateKnowledgeAreaStatus}
          updateKnowledgeArea={updateTraineeRoleArea}
          updateKnowledgeElement={this.onUpdateElement}
          wildcardPersons={wildcardPersons}
          onCloseHistoryModal={this.onCloseHistoryModal}
        />
      </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeRole);
