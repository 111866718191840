import {
  activatePersonService,
  changeEmployeeNumberService,
  changeInitialsService,
  changeLanguageService,
  changeNameService,
  deactivatePersonService,
  deletePersonService,
  fetchPersonsDetails,
  fetchPersonsOverview,
  updatePersonAdminStatus,
  updatePersonPassword,
} from '../services/personsService';
import { getPersons } from '../slices/personsSlice';
import { isNothing } from '../utils/fn';

export const PERSONS_OVERVIEW_DATA_PENDING = 'PERSONS_OVERVIEW_DATA_PENDING';
export const personsOverviewDataPending = () => ({
  type: PERSONS_OVERVIEW_DATA_PENDING,
});

export const PERSONS_OVERVIEW_RECIEVED = 'PERSONS_OVERVIEW_RECIEVED';
export const personsOverviewRecieved = (data) => ({
  type: PERSONS_OVERVIEW_RECIEVED,
  data,
});

export const PERSON_INFO_RECEIVED = 'PERSON_INFO_RECEIVED';
export const personInfoReceived = (data) => ({
  type: PERSON_INFO_RECEIVED,
  data,
});

export const requestPersonsOverview = () => (dispatch) =>
  fetchPersonsOverview()
    .then((data) => {
      dispatch(personsOverviewRecieved(data.data));
    })
    .catch(() => {
      console.log('failed fetching persons overview');
    });

export const PERSON_DETAILS_RECEIVED = 'PERSON_DETAILS_RECEIVED';
export const personDetailsReceived = (data) => ({
  type: PERSON_DETAILS_RECEIVED,
  data,
});

export const requestPersonsDetails = (personId) => (dispatch) =>
  fetchPersonsDetails(personId)
    .then((data) => {
      dispatch(personDetailsReceived(data.data));
    })
    .catch((ex) => {
      throw new Error(ex);
    });

export const UPDATED_PERSON_DATA_RECEIVED = 'UPDATED_PERSON_DATA_RECEIVED';
export const updatedPersonDataReceived = (data) => ({
  type: UPDATED_PERSON_DATA_RECEIVED,
  data,
});

export const CREATED_PERSON_DATA_RECEIVED = 'CREATED_PERSON_DATA_RECEIVED';
export const createdPersonDataReceived = (data) => ({
  type: CREATED_PERSON_DATA_RECEIVED,
  data,
});

export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
export const setChangePasswordError = (errMessage) => ({
  type: SET_CHANGE_PASSWORD_ERROR,
  errMessage,
});

export const changePersonPassword =
  (personId, newPassword, newRepeatedPassword, successCallback) =>
  (dispatch) => {
    const put = updatePersonPassword(personId, {
      password: newPassword,
      repeatedPassword: newRepeatedPassword,
    });
    put
      .then((data) => {
        if (isNothing(data.data)) {
          console.log('Kontakt til serveren fejlede');
          return;
        }
        successCallback();
      })
      .catch((ex) => {
        dispatch(
          setChangePasswordError(ex.response.data.responseStatus.message)
        );
      });
  };

export const changePersonAdminState =
  (personId, newAdminState) => (dispatch) => {
    const put = updatePersonAdminStatus(personId, {
      isAdministrator: newAdminState,
    });
    put
      .then((data) => {
        if (isNothing(data.data)) {
          console.log('Kontakt til serveren fejlede');
          return;
        }
        dispatch(updatedPersonDataReceived(data.data));
      })
      .catch(() => {});
  };

export const PERSONS_LISTS_RECEIVED = 'PERSONS_LISTS_RECEIVED';
export const personListsReceived = (data) => ({
  type: PERSONS_LISTS_RECEIVED,
  data,
});

export const deactivatePerson = (personId) => (dispatch) => {
  return deactivatePersonService(personId, {
    personId,
  })
    .then((data) => {
      dispatch(personListsReceived(data.data));
    })
    .catch(() => {
      console.log('failed deactivating person');
    });
};

export const activatePerson =
  (personId, newPassword, newRepeatedPassword, successCallback) =>
  (dispatch) => {
    return activatePersonService(personId)
      .then((data) => {
        dispatch(personListsReceived(data.data));
        successCallback && successCallback();
      })
      .catch(() => {
        console.log('failed activating person');
      });
  };

export const PERSONS_SORT_ORDERS_RECEIVED = 'PERSONS_SORT_ORDERS_RECEIVED';
export const personsSortOrdersReceived = (data) => ({
  type: PERSONS_SORT_ORDERS_RECEIVED,
  data,
});

export const deletePerson = (personId, successCallback) => (dispatch) => {
  return deletePersonService(personId)
    .then((data) => {
      successCallback();
      dispatch(personsSortOrdersReceived(data.data));
    })
    .catch(() => {
      console.log('failed deleting person');
    });
};

export const changeName =
  ({ personId, name }) =>
  (dispatch) => {
    changeNameService(personId, {
      name,
    })
      .then(({ data }) => {
        dispatch(personInfoReceived(data));
        dispatch(getPersons());
      })
      .catch(() => {});
  };

export const changeInitials =
  ({ personId, initials }) =>
  (dispatch) => {
    changeInitialsService(personId, {
      initials,
    })
      .then(({ data }) => {
        dispatch(personInfoReceived(data));
      })
      .catch(() => {});
  };
export const changeEmployeeNumber =
  ({ personId, employeeNumber }) =>
  (dispatch) => {
    changeEmployeeNumberService(personId, {
      employeeNumber,
    })
      .then(({ data }) => {
        dispatch(personInfoReceived(data));
      })
      .catch(() => {});
  };

export const changeLanguage =
  ({ personId, language }) =>
  (dispatch) => {
    changeLanguageService(personId, {
      locale: language,
    })
      .then(({ data }) => {
        dispatch(personInfoReceived(data));
      })
      .catch(() => {});
  };
