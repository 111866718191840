import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, PERSON_STATES } from '../constants';
import {
  selectActivePersonsSortOrder,
  selectArchivedPersonsSortOrder,
} from '../slices/personsSlice';
import Aside from './Aside';
import DateTime from './DateTime';
import withAccessControl from './HOC/withAccessControl';
import Icon from './Icon';
import SideNavFilter from './sideNav/SideNavFilter';
import SideNavItem from './sideNav/SideNavItem';
import SideNavList from './sideNav/SideNavList';
import SideNavTitle from './sideNav/SideNavTitle';

const mapStateToProps = (state) => {
  const { persons } = state;
  return {
    persons: persons,
    archivedPersonsSortOrder: selectArchivedPersonsSortOrder(state),
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
  };
};

const mapDispatchToProps = {};

const PersonsNav = ({
  persons,
  onCreatePersonClick,
  onFilterChange,
  visiblePersons,
  hasAccess, // From withAccessControlHOC
  match,
  archivedPersonsSortOrder,
  activePersonsSortOrder,
}) => {
  const showCreatePerson = hasAccess([
    ACCESS_LEVELS.champadministrator,
    ACCESS_LEVELS.administrator,
    ACCESS_LEVELS.createPersons,
    ACCESS_LEVELS.userAdministrator,
    ACCESS_LEVELS.personAdministrator,
  ]);
  return (
    <Aside className="persons-nav">
      <SideNavFilter
        className="persons-nav__filter"
        autoFocus
        onChange={onFilterChange}
        placeholder={localeLookup('translations.Search for person')}
      />
      <SideNavTitle
        className="persons-nav__title"
        icon={showCreatePerson ? 'plus-circle' : null}
        iconColor="green"
        onIconClick={showCreatePerson ? onCreatePersonClick : null}
        title={localeLookup('translations.Persons')}
      />
      <SideNavList>
        {activePersonsSortOrder.map((id) => {
          if (
            persons[id] &&
            persons[id].state === PERSON_STATES.ACTIVE &&
            visiblePersons.indexOf(id) !== -1
          ) {
            const person = persons[id];
            return (
              <SideNavItem
                key={id}
                innerElement={NavLink}
                to={{
                  pathname: `${match.url}/${id}`,
                  state: { title: localeLookup('translations.Profiles') },
                }}
                title={person.name}
                rightComponent={
                  <>
                    {!person.hasAccess && (
                      <Icon color="grey" kind="prohibited" />
                    )}
                    {person.hasAccess && person.hasSyncErrors && (
                      <Icon color="red" kind="warning" />
                    )}
                  </>
                }
              />
            );
          }
        })}
      </SideNavList>
      {archivedPersonsSortOrder && archivedPersonsSortOrder.length > 0 && (
        <>
          <SideNavTitle
            className="persons-nav__title"
            title={localeLookup('translations.Archived persons')}
          />
          <SideNavList>
            {archivedPersonsSortOrder.map((id) => {
              if (persons[id] && visiblePersons.indexOf(id) !== -1) {
                const person = persons[id];
                return (
                  <SideNavItem
                    key={id}
                    innerElement={NavLink}
                    to={{
                      pathname: `${match.url}/${id}`,
                      state: { title: localeLookup('translations.Profiles') },
                    }}
                    title={person.name}
                    subtitle={<DateTime date={person.archivedDate}></DateTime>}
                  />
                );
              }
            })}
          </SideNavList>
        </>
      )}
    </Aside>
  );
};

export default connect(mapStateToProps)(
  withRouter(withAccessControl(PersonsNav))
);
