/* eslint-disable react/display-name */
import React from 'react';
import cx from 'classnames';
const ModalError = React.forwardRef(
  ({ error, margin, horizontalSpacing }, ref) => {
    if (!error) return null;
    const modifierClasses = {
      ['modal-content__error--sm']: margin === 'sm',
      [`modal-content__error--h-spacing-${horizontalSpacing}`]:
        horizontalSpacing,
    };
    return (
      <div className={cx('modal-content__error', modifierClasses)} ref={ref}>
        <p>{error}</p>
      </div>
    );
  }
);

export default ModalError;
