import { format } from 'date-fns';
import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import { ROLE_LEVELS } from '../../constants';
import {
  assignRolesService,
  getBulkRoleAssignConflictsService,
} from '../../services/personsService';
import AssignRolesForm from '../AssignRolesForm';
import FormModal from './FormModal';
import ModalFooter from './ModalFooter';
import ConfirmationModal from './ConfirmationModal';
import SimpleTable from '../SimpleTable';
import { getPersons } from '../../slices/personsSlice';
import { getAllRoles } from '../../slices/rolesSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { getGroups } from '../../slices/groupsSlice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withPersonLookup from '../HOC/withPersonLookup';
import Text from '../Text';
import PersonWrapper from '../PersonWrapper';
import { compareLocal, formatDate, sortBy } from '../../utils/helpers';

const mapStateToProps = (state) => {
  return {
    persons: state.persons,
    roles: state.roles,
    groups: state.groups,
    organisationUnits: state.organisationUnits,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getPersons, getAllRoles, getAllOrganisationUnits, getGroups },
    dispatch
  );

class AssignRolesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 'form',
      showConfirmation: false,
      formData: {},
      createOnly: true,
      isSubmitting: false,
    };
  }

  assignRoles = () => {
    const { createOnly, formData } = this.state;
    const { onConfirm, onClose } = this.props;
    const {
      mentorId,
      startDate,
      endDate,
      state,
      roleLevel,
      personIds,
      roleIds,
    } = formData;
    this.setState({ isSubmitting: true });
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate =
      endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : null;

    assignRolesService({
      roleLevel,
      personIds,
      roleIds,
      createOnly,
      data:
        roleLevel === ROLE_LEVELS.TRAINING
          ? {
              mentorId,
              startDate: formattedStartDate,
              endDate: endDate === '' ? null : formattedEndDate,
              state,
            }
          : null,
    })
      .then(() => {
        onConfirm?.();
        onClose();
        this.setState({ isSubmitting: false });
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  onChangeCreateOnly = (createOnly) => {
    this.setState({ createOnly });
  };

  onSubmit = (values) => {
    const { createOnly, formData } = this.state;
    const { onConfirm, onClose } = this.props;
    const {
      mentorId,
      startDate,
      endDate,
      state,
      roleLevel,
      personIds,
      roleIds,
    } = values;
    // Set values from form in state to keep them when showing conflicts
    this.setState(
      { isSubmitting: true, formData: values ? values : {} },
      () => {
        if (!createOnly) {
          const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
          const formattedEndDate =
            endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : null;

          getBulkRoleAssignConflictsService({
            roleLevel,
            personIds,
            roleIds,
            data:
              roleLevel === ROLE_LEVELS.TRAINING
                ? {
                    mentorId,
                    startDate: formattedStartDate,
                    endDate: endDate === '' ? null : formattedEndDate,
                    state,
                  }
                : null,
          })
            .then(() => {
              // No conflicts, just submit
              this.assignRoles();
            })
            .catch((e) => {
              if (e.response.status === 409) {
                // Conflicts, show confirmation
                this.setState({
                  activeStep: 'confirmation',
                  conflicts: e.response.data,
                });
              }
            });
        } else {
          // No confirmation, just submit
          this.assignRoles();
        }
      }
    );
  };

  renderConflicts = () => {
    const { persons, roles, lookupPerson } = this.props;
    const { conflicts } = this.state;
    const getRoleLevelText = (competenceLevel) => {
      switch (competenceLevel?.toLowerCase()) {
        case ROLE_LEVELS.TRAINING:
          return localeLookup('translations.Training');

        case ROLE_LEVELS.EXPERIENCED:
          return localeLookup('translations.Experienced');

        case ROLE_LEVELS.QUALIFIED:
          return localeLookup('translations.Qualified');
      }
    };
    const rows = sortBy(
      conflicts.map((conflict) => {
        const role = roles[conflict.roleId];
        const person = lookupPerson(conflict.personId);
        return [
          `${person.name} (${person.initials}${
            person.employeeNumber ? ` · ${person.employeeNumber})` : ')'
          }`,
          role.name,
          <>
            <Text>{getRoleLevelText(conflict.competenceLevel)}</Text>
            {conflict.competenceLevel.toLowerCase() === ROLE_LEVELS.TRAINING ? (
              <>
                <PersonWrapper
                  id={conflict.mentor}
                  render={({ name, color, suffix }) => (
                    <Text
                      color={color === 'red' ? 'red' : 'dark-grey'}
                      size="sm"
                    >
                      {localeLookup('translations.Mentor')}: {name} {suffix}
                    </Text>
                  )}
                />
                <Text size="sm" color="dark-grey">
                  {formatDate(conflict.startDate)} -
                  {conflict.endDate ? `${formatDate(conflict.endDate)}` : ''}
                </Text>
                <Text size="sm" color="dark-grey">
                  {conflict.state === 'Passive'
                    ? localeLookup('translations.Limited visibility')
                    : null}
                </Text>
              </>
            ) : null}
          </>,
        ];
      }),
      [(a, b) => compareLocal(a[0], b[0])]
    );
    return (
      <SimpleTable
        rows={rows}
        cellPadding="md"
        verticalAlign="top"
        headerRow={[
          localeLookup('translations.Person'),
          localeLookup('translations.Role'),
          localeLookup('translations.Role level'),
        ]}
        columnWidths={['35%', '30%', '35%']}
      />
    );
  };

  render() {
    const { activeStep, formData, createOnly, isSubmitting } = this.state;
    const {
      onClose,
      title,
      //initialValues,
      onConfirm,
      subtitle,
      confirmButtonText,
      context,
      organisationUnitId,
    } = this.props;

    const initialValues = {
      mentorId: formData.mentorId || '',
      personIds: formData.personIds || [],
      roleIds: formData.roleIds || [],
      endDate: formData.endDate || null,
      startDate: formData.startDate || new Date(),
      state: formData.state || 'Active',
      roleLevel: formData.roleLevel || '',
    };

    if (activeStep === 'confirmation') {
      return (
        <ConfirmationModal
          title={title}
          subtitle={subtitle}
          btnConfirmTitle={localeLookup('translations.Assign')}
          btnRejectTitle={localeLookup('translations.Back')}
          //dismissable={modalProps.dismissable}
          body={this.renderConflicts()}
          onCancel={() => this.setState({ activeStep: 'form' })}
          onClose={onClose}
          btnConfirmKind="alert"
          onConfirm={this.assignRoles}
          //safeWord={modalProps.safeWord}
          infoText={localeLookup(
            'translations.The following persons already have the selected role(s) assigned as shown. This action will overwrite these assignments and associated data. Do you want to continue?'
          )}
          infoTextType="error"
          infoTextIcon="warning"
          //skipCloseOnConfirm={modalProps.skipCloseOnConfirm}
        />
      );
    }
    if (activeStep === 'form') {
      return (
        <FormModal onClose={onClose} title={title} subtitle={subtitle}>
          <AssignRolesForm
            createOnly={createOnly}
            onClose={onClose}
            onChangeCreateOnly={this.onChangeCreateOnly}
            onConfirm={this.onSubmit}
            initialValues={initialValues}
            organisationUnitId={organisationUnitId}
            renderFooter={({ handleSubmit, canSubmit }) => (
              <ModalFooter
                confirmButtonText={localeLookup('translations.Assign')}
                confirmDisabled={!canSubmit || isSubmitting}
                onConfirmClick={handleSubmit}
                onCancelClick={onClose}
                showConfirmButtonLoader={isSubmitting}
              />
            )}
          />
        </FormModal>
      );
    }
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonLookup(AssignRolesModal));
