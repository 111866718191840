import React, { PureComponent } from 'react';
import cx from 'classnames';
import Icon from './Icon';
import Loader from './Loader';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';

class ButtonIcon extends PureComponent {
  state = {
    isLoading: false,
  };

  onClick = (e) => {
    const { onClick } = this.props;
    if (!onClick) return;
    const promise = onClick(e);
    this.setState({ isLoading: true });
    if (promise instanceof Promise) {
      promise.then(() => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    const { icon, className, inline, round, color, iconColor, size, tooltip, disabled, noMargin, isLoading: isLoadingProp, numberOfLoaderDots = 3, rounded, tabIndex } = this.props;
    const modifierClasses = {
      'button-icon--inline': inline,
      'button-icon--round': round,
      'button-icon--rounded': rounded,
      'button-icon--no-margin': noMargin,
      'button-icon--cursor-help': disabled && tooltip,
      [`button-icon--color-${color}`]: color,
      [`button-icon--size-${size}`]: size,
      [`button-icon--icon-color-${iconColor}`]: iconColor,
    };
    return (
      <ConditionalWrap
        condition={tooltip}
        wrap={(children) => (
          <Tooltip tooltip={tooltip}>
            <span className="button-icon-wrapper">{children}</span>
          </Tooltip>
        )}
      >
        <button type="button" onClick={this.onClick} className={cx('button-icon', modifierClasses, className)} disabled={disabled} tabIndex={tabIndex}>
          <span>{isLoading || isLoadingProp ? <Loader numberOfDots={numberOfLoaderDots} size="small" /> : <Icon kind={icon} className="button-icon__icon" />}</span>
        </button>
      </ConditionalWrap>
    );
  }
}

ButtonIcon.propTypes = {};

export default ButtonIcon;
