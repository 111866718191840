/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import Badge from '../Badge';
import BoxMessage from '../BoxMessage';
import withPersonLookup from '../HOC/withPersonLookup';
import SignaturePad from '../SignaturePad';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import CheckList from '../CheckList';
import { registerBulkTrainingService } from '../../services/trainingService';
import { TRAINING_REGISTRATION_TYPES } from '../../constants';

const mapStateToProps = (state) => {
  const { user, elements } = state;
  return {
    currentUserName: user.name,
    currentUserId: user.employeeId,
    elements,
  };
};

class MultiElementSignatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64signature: '',
      isLoading: false,
      selectedOptionIds: props.selectedOptionIds || [],
    };
  }

  onClickConfirm = () => {
    const { onConfirm } = this.props;
    const { base64signature, selectedOptionIds } = this.state;
    this.setState({ isLoading: true });

    onConfirm({
      signature: base64signature,
      selectedOptionIds: selectedOptionIds,
    });
  };

  onEndSignature = (base64signature) => {
    this.setState({
      base64signature,
    });
  };

  onToggleElement = (id, nestedOptionId) => {
    const { nestedOptions, options } = this.props;
    const { selectedOptionIds } = this.state;
    if (nestedOptions) {
      if (nestedOptionId) {
        this.setState({
          selectedOptionIds: {
            ...selectedOptionIds,
            [id]: selectedOptionIds[id].includes(nestedOptionId)
              ? selectedOptionIds[id].filter(
                  (selectedId) => selectedId !== nestedOptionId
                )
              : [...selectedOptionIds[id], nestedOptionId],
          },
        });
      } else {
        const allOptionIds = options
          .find((option) => option.id === id)
          .options.filter((option) => !option.disabled)
          .map((option) => option.value);

        const isAllNestedOptionsSelected = allOptionIds.every((optionId) =>
          selectedOptionIds[id].includes(optionId)
        );
        this.setState({
          selectedOptionIds: {
            ...selectedOptionIds,
            [id]: isAllNestedOptionsSelected ? [] : allOptionIds,
          },
        });
      }
    } else {
      this.setState({
        selectedOptionIds: selectedOptionIds.includes(id)
          ? selectedOptionIds.filter((selectedId) => selectedId !== id)
          : [...selectedOptionIds, id],
      });
    }
  };

  renderOptions = () => {
    const { selectedOptionIds } = this.state;
    const { options, nestedOptions } = this.props;

    return (
      <CheckList
        backgroundColor="grey"
        //negativeHorizontalMargin
        selectedOptionIds={selectedOptionIds}
        options={options}
        onChange={this.onToggleElement}
        nestedOptions={nestedOptions}
      />
    );
  };

  render() {
    const { selectedOptionIds } = this.state;
    const {
      title,
      subtitle,
      onClose,
      onCancel,
      cancelButtonText,
      confirmButtonText,
      confirmButtonKind,
      onConfirmClick,
      confirmDisabled,
      signeeId,
      infoText,
      lookupPerson,
      currentUserId,
    } = this.props;
    const { base64signature, isLoading } = this.state;
    const getSigneeName = () => {
      const person = signeeId
        ? lookupPerson(signeeId)
        : lookupPerson(currentUserId);
      if (person) {
        return `${person.name} (${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }
      return '';
    };
    return (
      <ModalWrapper className="signature-modal">
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        {infoText && (
          <BoxMessage icon="info-circle">
            {infoText}
          </BoxMessage>
        )}
        <ModalBody deep>{this.renderOptions()}</ModalBody>
        <SignaturePad
          margin="md"
          signee={getSigneeName()}
          onEndSignature={this.onEndSignature}
        />
        <ModalFooter
          onCancelClick={onCancel || onClose}
          cancelButtonText={cancelButtonText}
          confirmButtonText={
            confirmButtonText || localeLookup('translations.Sign')
          }
          confirmButtonKind={confirmButtonKind}
          onConfirmClick={this.onClickConfirm}
          confirmDisabled={
            base64signature === '' ||
            selectedOptionIds.length === 0 ||
            isLoading
          }
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(
  withPersonLookup(MultiElementSignatureModal)
);
