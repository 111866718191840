import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EmptyState from '../EmptyState';
import ListCard from '../ListCard';
import RenderRecursive from '../RenderRecursive';
import SwitchCheckbox from '../SwitchCheckbox';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import { ORGANISATION_UNIT_STATES } from '../../constants';
import localeLookup from '../../config/locale';

const mapStateToProps = (state) => ({
  organisationUnits: state.organisationUnits,
});

const mapDispatchToProps = {};

class OrganisationAdministratorModal extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedUnits } = props;
    this.state = {
      filterString: '',
      selectedUnits,
    };
  }

  getImplicitSelectedUnitIds = () => {
    const { selectedUnits } = this.state;
    const { organisationUnits } = this.props;
    const getUnitChildrenRecursively = (unitId) => {
      const unit = organisationUnits[unitId];
      const allChildren = [...unit.descendants];
      unit.descendants.forEach((unitId) => {
        if (organisationUnits[unitId].descendants.length > 0) {
          allChildren.push(...getUnitChildrenRecursively(unitId));
        }
      });
      return allChildren;
    };
    return selectedUnits
      .map((unitId) => {
        return getUnitChildrenRecursively(unitId);
      })
      .flat();
  };

  getVisibleOptions = () => {
    const { options, filterString } = this.state;
    /* return options.filter(
      (option) =>
        option.title.toLowerCase().includes(filterString.toLowerCase()) ||
        (option.subtitle && option.subtitle.toLowerCase().includes(filterString.toLowerCase()))
    ); */
  };

  onConfirm = () => {
    const { onConfirm } = this.props;
    const { selectedUnits } = this.state;
    onConfirm(selectedUnits);
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onToggleOption = (id) => {
    const { selectedUnits } = this.state;
    if (selectedUnits.includes(id)) {
      this.setState({
        selectedUnits: selectedUnits.filter((selectedId) => selectedId !== id),
      });
    } else {
      this.setState({
        selectedUnits: [...selectedUnits, id],
      });
    }
  };

  renderTree = () => {
    const { selectedUnits } = this.state;
    const { rootNodes, organisationUnits } = this.props;
    const implicitSelectedUnitIds = this.getImplicitSelectedUnitIds();
    return rootNodes.map((unitId) => (
      <RenderRecursive
        key={unitId}
        childrenProp="descendants"
        nodes={organisationUnits}
        rootId={unitId}
        render={({ isChild, node }) => {
          const isVisibilityLimited =
            node?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
            node?.state === ORGANISATION_UNIT_STATES.PASSIVE;
          return (
            <ListCard
              clickable
              el="label"
              htmlFor={node.id}
              key={node.id}
              rightComponent={
                <SwitchCheckbox
                  onChange={() => this.onToggleOption(node.id)}
                  id={node.id}
                  disabled={implicitSelectedUnitIds.includes(node.id)}
                  isChecked={
                    selectedUnits.includes(node.id) ||
                    implicitSelectedUnitIds.includes(node.id)
                  }
                />
              }
            >
              <>
                <Text>{node.name}</Text>
                {isVisibilityLimited && (
                  <Text size="sm" color="dark-grey">{localeLookup('translations.Limited visibility')}</Text>
                )}
              </>
            </ListCard>
          );
        }}
      />
    ));
  };

  render() {
    const {
      title,
      onClose,
      confirmBtnText,
      rootNodes,
      emptyStateText,
      subtitle,
    } = this.props;
    const { filterString } = this.state;
    const showEmptyState = rootNodes.length === 0;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : (
            this.renderTree()
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationAdministratorModal);
