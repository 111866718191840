/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import Aside from '../Aside';
import withAccessControl from '../HOC/withAccessControl';
import Icon from '../Icon';
import Loader from '../Loader';
import OrganisationUnitsText from '../OrganisationUnitsText';
import SideNavFilter from '../sideNav/SideNavFilter';
import SideNavItem from '../sideNav/SideNavItem';
import SideNavList from '../sideNav/SideNavList';
import SideNavTitle from '../sideNav/SideNavTitle';

class DesignboardSidebar extends Component {
  constructor() {
    super();
    this.state = {
      loadingRoleIds: [],
    };
  }

  onClickRole = (roleId) => {
    const { loadingRoleIds } = this.state;
    const { onClickToggleRoleVisiblity } = this.props;
    this.setState({ loadingRoleIds: [...loadingRoleIds, roleId] });
    onClickToggleRoleVisiblity(roleId).then(() => {
      this.setState((prevState) => ({
        loadingRoleIds: prevState.loadingRoleIds.filter((id) => id !== roleId),
      }));
    });
  };

  render() {
    const { loadingRoleIds } = this.state;
    const {
      filteredRoles,
      onChangeFilterString,
      onClickHideAllRoles,
      onCreateRoleClick,
      organisationUnits,
      roles,
      selectedRoles,
      hidden,
      hasCreateRolePermission,
      activeSpaceId,
    } = this.props;
    const showCreateRoleButton = hasCreateRolePermission({
      spaceId: activeSpaceId,
    });
    return (
      <Aside className="designboard-sidebar" hidden={hidden}>
        <SideNavTitle
          icon="plus-circle"
          iconColor="green"
          onIconClick={showCreateRoleButton ? onCreateRoleClick : null}
          title={localeLookup('translations.Roles')}
        />
        <SideNavFilter
          filterActions={[
            {
              title: localeLookup('translations.Hide all'),
              onClick: onClickHideAllRoles,
            },
          ]}
          autoFocus
          onChange={onChangeFilterString}
          placeholder={`${localeLookup('translations.Search for role')}...`}
        />
        <SideNavList>
          {filteredRoles &&
            filteredRoles.map((id) => {
              const role = roles[id];
              const visible = selectedRoles.includes(id);
              return (
                <SideNavItem
                  key={id}
                  showHoverTitle
                  title={role.name}
                  subtitle={
                    <OrganisationUnitsText
                      organisationUnitIds={role.organisationUnits}
                      /* organisationUnits={role.organisationUnits.map(
                        (id) => organisationUnits[id].name
                      )} */
                    />
                  }
                  onClick={() => {
                    this.onClickRole(id);
                  }}
                  rightComponent={
                    loadingRoleIds.includes(id) ? (
                      <Loader numberOfDots={1} />
                    ) : (
                      <Icon
                        kind={visible ? 'eye' : 'eye-crossed'}
                        color={visible ? 'green' : 'light-grey'}
                      />
                    )
                  }
                />
              );
            })}
        </SideNavList>
      </Aside>
    );
  }
}

export default withAccessControl(DesignboardSidebar);
