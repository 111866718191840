import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import {
  requestOwnedAreaData, requestOwnedRoleData,
  requestTrainingAreaData, requestTrainingRoleData, setSelectedSidebarElement
} from '../../actions/trainingAndOwnershipsActions';

// Components
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavList from '../../components/sideNav/SideNavList';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import localeLookup from '../../config/locale';
import { compareLocal, sortBy } from '../../utils/helpers';

const mapStateToProps = (state) => {
  const { trainingAndOwnerships } = state;
  return {
    knowledgeAreas: trainingAndOwnerships.knowledgeAreas,
    ownedKnowledgeAreas: trainingAndOwnerships.ownedKnowledgeAreas,
    ownedRoles: trainingAndOwnerships.ownedRoles,
    roles: trainingAndOwnerships.roles,
    selectedSidebarElement: trainingAndOwnerships.selectedSidebarElement,
    trainingKnowledgeAreas: trainingAndOwnerships.trainingKnowledgeAreas,
    trainingRoles: trainingAndOwnerships.trainingRoles,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedSidebarElement,
      requestTrainingRoleData,
      requestOwnedRoleData,
      requestTrainingAreaData,
      requestOwnedAreaData,
    },
    dispatch
  );

class TrainingAndOwnershipsSidebar extends Component {
  constructor(props) {
    super(props);
    this.sidebarElementClickHandler =
      this.sidebarElementClickHandler.bind(this);
    this.renderSidebarCategory = this.renderSidebarCategory.bind(this);
  }

  sidebarElementClickHandler(e, id, elementType) {
    const {
      setSelectedSidebarElement,
      requestTrainingRoleData,
      requestOwnedRoleData,
      requestTrainingAreaData,
      requestOwnedAreaData,
    } = this.props;
    e.preventDefault();
    // if (selectedSidebarElement.id === id) return;
    setSelectedSidebarElement(id, elementType);
    if (elementType === 'trainingRole') {
      requestTrainingRoleData(id);
    } else if (elementType === 'ownedRole') {
      requestOwnedRoleData(id);
    } else if (elementType === 'trainingKnowledgeArea') {
      requestTrainingAreaData(id);
    } else if (elementType === 'ownedKnowledgeArea') {
      requestOwnedAreaData(id);
    }
  }

  renderSidebarCategory(categoryElements, elementType, title) {
    const {
      selectedSidebarElement,
      onClickSelectElement,
      selectedSidebarElementType,
      selectedSidebarElementId,
    } = this.props;

    return (
      <>
        <SideNavTitle title={title} />
        <SideNavList>
          {sortBy(Object.values(categoryElements), [
            (a, b) => compareLocal(a.name, b.name),
          ]).map((item) => {
            const numPersons =
              elementType === 'trainingRole' ||
              elementType === 'trainingKnowledgeArea'
                ? item.yourMentees.length
                : item.persons.length;
            const localePersons =
              numPersons === 1
                ? localeLookup('translations.person')
                : localeLookup('translations.persons');

            return (
              <SideNavItem
                title={item.name}
                subtitle={`${numPersons} ${localePersons}`}
                onClick={() =>
                  onClickSelectElement({ id: item.id, type: elementType })
                }
                active={
                  selectedSidebarElementId === item.id &&
                  selectedSidebarElementType === elementType
                }
                leftComponent={
                  <Icon
                    color="green"
                    kind={
                      elementType === 'trainingRole' ||
                      elementType === 'ownedRole'
                        ? 'profile'
                        : 'book2'
                    }
                  />
                }
              />
            );
          })}
        </SideNavList>
      </>
    );
  }

  render() {
    const {
      ownedKnowledgeAreas,
      ownedRoles,
      trainingKnowledgeAreas,
      trainingRoles,
    } = this.props;
    return (
      <Aside>
        {Object.keys(trainingRoles).length > 0 &&
          this.renderSidebarCategory(
            trainingRoles,
            'trainingRole',
            localeLookup('translations.Your mentorships')
          )}
        {Object.keys(trainingKnowledgeAreas).length > 0 &&
          this.renderSidebarCategory(
            trainingKnowledgeAreas,
            'trainingKnowledgeArea',
            localeLookup('translations.Your training tasks')
          )}
        {Object.keys(ownedRoles).length > 0 &&
          this.renderSidebarCategory(
            ownedRoles,
            'ownedRole',
            localeLookup('translations.Your role ownerships')
          )}
        {Object.keys(ownedKnowledgeAreas).length > 0 &&
          this.renderSidebarCategory(
            ownedKnowledgeAreas,
            'ownedKnowledgeArea',
            localeLookup('translations.Your knowledge ownerships')
          )}
      </Aside>
    );
  }
}

TrainingAndOwnershipsSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingAndOwnershipsSidebar);

export default TrainingAndOwnershipsSidebar;
