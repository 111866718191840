import React from 'react';
import cx from 'classnames';

const ProgressBar = ({ percentage, className, size, rounded }) => {
  const modifierClasses = {
    [`progress-bar--size-${size}`]: size,
    'progress-bar--rounded': rounded,
  };
  return (
    <div className={cx('progress-bar', modifierClasses, className)}>
      <span
        className="progress-bar__progress"
        style={{ transform: `scaleX(${percentage / 100})` }}
      />
    </div>
  );
};

export default ProgressBar;
