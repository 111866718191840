import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import CheckList from '../CheckList';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import EmptyState from '../EmptyState';
import Text from '../Text';
import SwitchCheckbox from '../SwitchCheckbox';
import { compareFalsy, compareLocal, sortBy } from '../../utils/helpers';
import BoxMessage from '../BoxMessage';

export default class CheckListModal extends PureComponent {
  constructor(props) {
    super(props);
    const { options } = props;
    this.state = {
      filterString: '',
      selectedOptionIds: props.selectedOptionIds || [],
    };
  }

  isAllOptionsSelected = () => {
    const { selectedOptionIds } = this.state;
    const { renderSectioned, options } = this.props;
    if (renderSectioned) {
      return options.every((group) =>
        group.options.every((option) => selectedOptionIds.includes(option.id))
      );
    } else {
      return options.every((option) => selectedOptionIds.includes(option.id));
    }
  };

  getVisibleOptions = (options) => {
    const { selectedOptionIds, nestedOptions } = this.props;
    const { filterString } = this.state;
    const filteredOptions = options.filter((option) => {
      if (option.searchString) {
        return option.searchString
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return (
        option.title.toLowerCase().includes(filterString.toLowerCase()) ||
        (option.subtitle &&
          option.subtitle.toLowerCase().includes(filterString.toLowerCase()))
      );
    });

    if (nestedOptions) return filteredOptions;

    return sortBy(filteredOptions, [
      (a, b) => compareLocal(a.title, b.title),
      (a, b) =>
        compareFalsy(
          selectedOptionIds.includes(a.id),
          selectedOptionIds.includes(b.id)
        ),
    ]);
  };

  onClickToggleAllOptions = (e) => {
    const { renderSectioned, options } = this.props;
    const { checked } = e.target;
    if (checked) {
      if (renderSectioned) {
        const optionIds = options.reduce((acc, group) => {
          return [...acc, ...group.options.map((option) => option.id)];
        }, []);
        this.setState({ selectedOptionIds: optionIds });
      } else {
        this.setState({
          selectedOptionIds: options.map((option) => option.id),
        });
      }
    } else {
      this.setState({ selectedOptionIds: [] });
    }
  };

  onConfirm = () => {
    const { options, selectedOptionIds } = this.state;
    const { onConfirm } = this.props;
    onConfirm(selectedOptionIds, options);
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onToggleOption = (id, nestedOptionId) => {
    const { nestedOptions, options } = this.props;

    const { selectedOptionIds } = this.state;
    if (nestedOptions) {
      if (nestedOptionId) {
        this.setState({
          selectedOptionIds: {
            ...selectedOptionIds,
            [id]: selectedOptionIds[id]?.includes(nestedOptionId)
              ? selectedOptionIds[id].filter(
                  (selectedId) => selectedId !== nestedOptionId
                )
              : [
                  ...(selectedOptionIds[id] ? selectedOptionIds[id] : []),
                  nestedOptionId,
                ],
          },
        });
      } else {
        const allOptionIds = options
          .find((option) => option.id === id)
          .options.filter((option) => !option.disabled)
          .map((option) => option.value);

        const isAllNestedOptionsSelected = allOptionIds.every((optionId) =>
          selectedOptionIds[id]?.includes(optionId)
        );
        this.setState({
          selectedOptionIds: {
            ...selectedOptionIds,
            [id]: isAllNestedOptionsSelected ? [] : allOptionIds,
          },
        });
      }
    } else {
      this.setState({
        selectedOptionIds: selectedOptionIds.includes(id)
          ? selectedOptionIds.filter((selectedId) => selectedId !== id)
          : [...selectedOptionIds, id],
      });
    }
  };

  renderSections = () => {
    const { selectedOptionIds } = this.state;
    const { options } = this.props;
    return options.map((section, i) => {
      const visibleOptions = this.getVisibleOptions(section.options);
      if (visibleOptions.length === 0) return null;
      return (
        <React.Fragment key={i}>
          <h2 className="e-label-upper modal-content__body-title">
            {section.title}
          </h2>
          <CheckList
            selectedOptionIds={selectedOptionIds}
            options={visibleOptions}
            onChange={this.onToggleOption}
          />
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      title,
      onClose,
      confirmButtonText,
      confirmButtonKind,
      onFilterButtonClick,
      filterPlaceholder,
      filterButtonText,
      showFilterButton,
      options,
      emptyStateText,
      subtitle,
      renderSectioned,
      showToggleAll,
      showFilter,
      infoText,
      nestedOptions,
    } = this.props;
    const { filterString, selectedOptionIds } = this.state;
    const showEmptyState = options.length === 0;
    const isAnyOptionsDisabled = this.getVisibleOptions(options).some(
      (option) => option.disabled
    );
    return (
      <ModalWrapper>
        <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
        {infoText && (
          <BoxMessage spacing="md" icon="info-circle">
            {infoText}
          </BoxMessage>
        )}
        {showFilter !== false && (
          <ModalFilter
            placeholder={
              filterPlaceholder || `${localeLookup('translations.Search')}...`
            }
            onChange={this.onFilterChange}
            value={filterString}
            buttonIcon="plus-circle"
            buttonText={filterButtonText}
            onButtonClick={() => onFilterButtonClick({ filterString })}
            showButton={showFilterButton}
          />
        )}
        {showToggleAll && !isAnyOptionsDisabled && (
          <div className="checklist-header">
            <Text>{localeLookup('translations.Select all')}</Text>
            <SwitchCheckbox
              onChange={this.onClickToggleAllOptions}
              isChecked={this.isAllOptionsSelected()}
            ></SwitchCheckbox>
          </div>
        )}
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : renderSectioned ? (
            this.renderSections()
          ) : (
            <CheckList
              nestedOptions={nestedOptions}
              selectedOptionIds={selectedOptionIds}
              options={this.getVisibleOptions(options)}
              onChange={this.onToggleOption}
            />
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmButtonText}
          confirmButtonKind={confirmButtonKind}
        />
      </ModalWrapper>
    );
  }
}
