/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import React, { Component } from 'react';
import withPersonLookup from '../HOC/withPersonLookup';
import OrganisationStatusboxProgressBar from './OrganisationStatusboxProgressBar';
import { ROLE_LEVELS, ROLE_MATRIX_COMPLETION_STATES } from '../../constants';

class StatusBoxRoleOrganisation extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { role, highlight, view, completionState } = this.props;

    if (role !== nextProps.role) return true;
    if (completionState !== nextProps.completionState) return true;
    if (highlight !== nextProps.highlight) return true;
    if (view !== nextProps.view) return true;

    if (role !== undefined && nextProps.role !== undefined) {
      if (
        role.mentor !== nextProps.role.mentor ||
        role.completionPercentage !== nextProps.role.completionPercentage ||
        role.isExperienced !== nextProps.role.isExperienced
      ) {
        return true;
      }
    }
    return false;
  }

  onClick = (e) => {
    const { toggleContextMenu, personId, roleId, isReadOnly, onMouseLeave } =
      this.props;
    if (isReadOnly) return;
    toggleContextMenu({ e, personId, roleId });
    onMouseLeave();
  };

  onMouseEnter = () => {
    const { onMouseEnter, roleId, personId, unitId } = this.props;
    onMouseEnter({ roleId, personId, unitId, type: 'role' });
  };
  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    onMouseLeave();
  };

  renderContent() {
    const { role, lookupPerson, view } = this.props;
    if (role && role.competenceLevel) {
      const mentor = lookupPerson(role.mentor);
      const flagMentor = mentor?.showError;
      if (role.competenceLevel.toLowerCase() === ROLE_LEVELS.TRAINING) {
        return (
          <div
            className={cx('organisation-statusbox-role__mentorship', {
              'organisation-statusbox-role__mentorship--flag': flagMentor,
            })}
          >
            <abbr className="organisation-statusbox-role__mentorship-initials">
              {view === 'initials'
                ? mentor?.initials
                : mentor?.employeeNumber || mentor?.initials}
            </abbr>
          </div>
        );
      }
      if (role.competenceLevel.toLowerCase() === ROLE_LEVELS.EXPERIENCED) {
        return (
          <div className="organisation-statusbox-role__star">
            <svg
              className="organisation-statusbox-role__star-icon"
              height="20"
              width="20"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" />
            </svg>
          </div>
        );
      }
      return <div className="organisation-statusbox-role__point" />;
    }
    return <span className="organisation-statusbox-role__dash">–</span>;
  }

  render() {
    const { role, isIrrelevant, highlight, completionState, lookupPerson } =
      this.props;

    const classes = cx('organisation-statusbox-role', {
      'organisation-statusbox-role--highlight': highlight,
      'organisation-statusbox-role--theme-red':
        role && role.hasIncompleteCriticalKnowledge,
      'organisation-statusbox-role--state-progress':
        completionState !== ROLE_MATRIX_COMPLETION_STATES.NONE,
      'organisation-statusbox-role--theme-grey':
        isIrrelevant || role?.state === 'Passive',
    });

    const showProgressBar = () => {
      if (!role || !role.competenceLevel) return false;
      if (role) {
        if (completionState === ROLE_MATRIX_COMPLETION_STATES.ALL) {
          return role?.completionPercentage !== 100;
        }
        if (completionState === ROLE_MATRIX_COMPLETION_STATES.TRAINING) {
          return (
            role.competenceLevel.toLowerCase() === ROLE_LEVELS.TRAINING &&
            role?.completionPercentage !== 100
          );
        }
      }
      return false;
    };

    const progessBarColor = () => {
      if (isIrrelevant || role?.state === 'Passive') return 'grey';
      if (role.hasIncompleteCriticalKnowledge) return 'red';
      return 'green';
    };

    return (
      <div
        className={classes}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={(element) => {
          this.statusBoxRef = element;
        }}
      >
        <div className="organisation-statusbox-role__content">
          {this.renderContent()}
        </div>
        {showProgressBar() && (
          <OrganisationStatusboxProgressBar
            color={progessBarColor()}
            completionPercentage={role.completionPercentage}
          />
        )}
      </div>
    );
  }
}

export default withPersonLookup(StatusBoxRoleOrganisation);
