import React from 'react';
import cx from 'classnames';

const MainArea = ({
  children,
  backgroundColor,
  horizontalScroll,
  forceVerticalScrollbar,
  defaultPadding,
  className,
  noBottomPadding,
  fullScreen,
  onScroll,
}) => {
  const modifierClasses = cx({
    [`main-area--background-${backgroundColor}`]: backgroundColor,
    'main-area--horizontal-scroll': horizontalScroll,
    'main-area--force-vertical-scroll-bar': forceVerticalScrollbar,
    'main-area--default-pd': defaultPadding,
    'main-area--no-bottom-pd': noBottomPadding,
    'main-area--full-screen': fullScreen,
  });
  return (
    <div
      onScroll={onScroll}
      id="scroll-container"
      className={cx('main-area', modifierClasses, className)}
    >
      {children}
    </div>
  );
};

export default MainArea;
