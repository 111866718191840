import React from 'react';
import cx from 'classnames';

const Loader = ({ numberOfDots = 3, size, color, className, padding }) => {
  const modifierClasses = {
    [`loader--color-${color}`]: color,
    [`loader--size-${size}`]: size,
    [`loader--padding-${padding}`]: padding,
  };
  return (
    <div className={cx('loader', modifierClasses, className)}>
      <div className="loader__dots">
        {Array(numberOfDots)
          .fill(numberOfDots)
          .map((number, index) => (
            <span key={index} className="loader__dot" />
          ))}
      </div>
    </div>
  );
};

export default Loader;
