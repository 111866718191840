import React, { PureComponent } from 'react';
import EmptyState from '../EmptyState';
import ListCard from '../ListCard';
import RenderRecursive from '../RenderRecursive';
import SwitchCheckbox from '../SwitchCheckbox';
import Text from '../Text';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import localeLookup from '../../config/locale';
import { ORGANISATION_UNIT_STATES } from '../../constants';

export default class OrganisationUnitCheckListModal extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedUnits } = props;
    this.state = {
      filterString: '',
      selectedUnits,
    };
  }

  getVisibleOptions = () => {
    const { options, filterString } = this.state;
    /* return options.filter(
      (option) =>
        option.title.toLowerCase().includes(filterString.toLowerCase()) ||
        (option.subtitle && option.subtitle.toLowerCase().includes(filterString.toLowerCase()))
    ); */
  };

  onConfirm = () => {
    const { onConfirm } = this.props;
    const { selectedUnits } = this.state;
    onConfirm(selectedUnits);
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  onToggleOption = (id) => {
    const { selectedUnits } = this.state;
    if (selectedUnits.includes(id)) {
      this.setState({
        selectedUnits: selectedUnits.filter((selectedId) => selectedId !== id),
      });
    } else {
      this.setState({
        selectedUnits: [...selectedUnits, id],
      });
    }
  };

  renderTree = () => {
    const { selectedUnits } = this.state;
    const { rootNodes, organisationUnits } = this.props;
    return rootNodes.map((unitId) => (
      <RenderRecursive
        key={unitId}
        childrenProp="descendants"
        nodes={organisationUnits}
        rootId={unitId}
        render={({ isChild, node }) => {
          return (
            <ListCard
              clickable
              el="label"
              htmlFor={node.id}
              key={node.id}
              rightComponent={
                <SwitchCheckbox
                  onChange={() => this.onToggleOption(node.id)}
                  id={node.id}
                  isChecked={selectedUnits.includes(node.id)}
                />
              }
            >
              <>
                <Text>{node.name}</Text>
                {(node.state === ORGANISATION_UNIT_STATES.PASSIVE ||
                  node.state ===
                    ORGANISATION_UNIT_STATES.INHERITED_PASSIVE) && (
                  <Text size="sm" color="dark-grey">
                    {localeLookup('translations.Limited visibility')}
                  </Text>
                )}
              </>
            </ListCard>
          );
        }}
      />
    ));
  };

  render() {
    const {
      title,
      onClose,
      confirmBtnText,
      onFilterButtonClick,
      filterPlaceholder,
      filterButtonText,
      showFilterButton,
      organisationUnits,
      rootNodes,
      emptyStateText,
    } = this.props;
    const { filterString } = this.state;
    const showEmptyState = rootNodes.length === 0;
    return (
      <ModalWrapper>
        <ModalHeader title={title} onClose={onClose} />
        {/* <ModalFilter
          placeholder={filterPlaceholder || `${localeLookup('translations.Search')}...`}
          onChange={this.onFilterChange}
          value={filterString}
          buttonIcon="plus-circle"
          buttonText={filterButtonText}
          onButtonClick={onFilterButtonClick}
          showButton={showFilterButton}
        /> */}
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : (
            this.renderTree()
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}
