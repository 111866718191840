import React from 'react';

const Image = ({ url }) => {
  return (
    <div className="image">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="image__img" src={url} alt="Description" />
      </a>
    </div>
  );
};

export default Image;
