import cx from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../config/locale';
import { TRAINING_REGISTRATION_TYPES } from '../constants';
import { queryAreas } from '../slices/areasSlice';
import { queryElements } from '../slices/elementsSlice';
import { queryRoles } from '../slices/rolesSlice';
import DragAndDropHandle from './DragAndDropHandle';
import EditorElementContextMenu from './EditorElementContextMenu';
import EditorElementIndicators from './EditorElementIndicators';
import EditorElementResponsibilitySubtitle from './EditorElementResponsibilitySubtitle';
import withAccessControl from './HOC/withAccessControl';
import WithEditorActions from './HOC/withEditorActions';
import WithModals from './HOC/withModals';
import Icon from './Icon';
import InlineFieldEditor from './InlineFieldEditor';
import SwitchCheckbox from './SwitchCheckbox';

const mapStateToProps = (state) => {
  const { areas } = state;
  return {
    areas,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryElements, queryRoles, queryAreas }, dispatch);

class EditorElement extends Component {
  constructor() {
    super();
    this.state = {
      isEditingName: false,
    };
  }

  getContentIndicatorIcon = () => {
    const { files } = this.props;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'Image') {
        return 'picture';
      }
      if (file.type === 'Video') {
        return 'camera';
      }
      return 'paperclip';
    }
    return 'document';
  };

  getElement = () => {
    const { queryElements, id } = this.props;
    return queryElements([id]);
  };

  onBlurEditName = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onChangeElementType = (e) => {
    const { id, editorActions } = this.props;
    editorActions.changeElementType(id, e.target.value);
  };

  onChangeExpiration = (value) => {
    const { daysValid, id, editorActions } = this.props;
    if (value === daysValid) return;
    const enabled = value > 0;
    editorActions.changeElementExpiration({ id, enabled, value });
  };

  onClickChangeDescription = () => {
    const { id, editorActions } = this.props;
    editorActions.showChangeElementDescriptionModal(id);
  };

  onClickChangeResponsible = () => {
    const { id, areaId, editorActions } = this.props;
    editorActions.showChangeElementResponsibleModal(id, areaId);
  };

  onClickEditName = (e) => {
    e.stopPropagation();
    this.setState({
      isEditingName: true,
    });
  };

  onClickToggleConnectToRole = () => {
    const { isConnected, id, roleId, editorActions } = this.props;
    if (!isConnected) {
      editorActions.connectElementsToRoles({
        roleIds: [roleId],
        elementIds: [id],
      });
    } else {
      editorActions.disconnectElementsFromRoles({
        roleIds: [roleId],
        elementIds: [id],
      });
    }
  };

  onSubmitRename = (value) => {
    const { id, editorActions } = this.props;
    editorActions.changeElementName(id, value);
  };

  renderContextMenu = () => {
    const { id, areaId, roleId, readOnly } = this.props;
    return (
      <EditorElementContextMenu
        elementId={id}
        areaId={areaId}
        containerClass="editor"
        roleId={roleId}
        triggerSize="large"
        hasAccessToArea={!readOnly}
      ></EditorElementContextMenu>
    );
  };

  renderHandle = () => {
    const { canDrag } = this.props;
    if (!canDrag) return null;
    return <DragAndDropHandle />;
  };

  renderIndicators = () => {
    const { areaId, id, readOnly } = this.props;
    return (
      <EditorElementIndicators
        areaId={areaId}
        showConnectionCounter
        elementId={id}
        hasAccessToArea={!readOnly}
      />
    );
  };

  renderOptions = () => {
    const { isConnected, hideConnectionSwitch, hasAccessToRole, roleId } =
      this.props;
    const hasAccess = hasAccessToRole(roleId);
    if (hideConnectionSwitch) return null;
    return (
      <div className="editor-element__options">
        <SwitchCheckbox
          className="editor-element__options-checkbox"
          disabled={!hasAccess}
          isChecked={isConnected}
          onChange={this.onClickToggleConnectToRole}
        />
      </div>
    );
  };

  renderSubtitle = () => {
    const {
      alternativeResponsible,
      completionType,
      responsible,
      lockedApprover,
      hasAccess,
      readOnly,
    } = this.props;
    const hasCounterSign =
      completionType === TRAINING_REGISTRATION_TYPES.MULTIPART;
    return (
      <EditorElementResponsibilitySubtitle
        onClick={hasAccess ? this.onClickChangeResponsible : undefined}
        isLocked={lockedApprover}
        alternativeMediatorId={alternativeResponsible}
        mediatorId={responsible}
        hasCounterSign={hasCounterSign}
        textSize="sm"
        disabled={readOnly}
      />
    );
  };

  renderTitle = () => {
    const { isEditingName } = this.state;
    const { title, readOnly } = this.props;
    if (isEditingName) {
      return (
        <InlineFieldEditor
          autoFocus
          classNameInput="editor-element__info-title-input"
          defaultValue={title}
          onBlur={this.onBlurEditName}
          onSubmit={this.onSubmitRename}
          placeholder={localeLookup('translations.Name of knowledge element')}
        />
      );
    }
    return (
      <button
        className="editor-element__info-title"
        onClick={this.onClickEditName}
        disabled={readOnly}
      >
        {title}
        {!readOnly && (
          <Icon kind="pencil" className="editor-element__info-title-icon" />
        )}
      </button>
    );
  };

  render() {
    const { isDragging, isRequired } = this.props;
    const dragModifier = isDragging ? 'editor-element--hidden' : '';
    const modifierClasses = { 'editor-element--critical': isRequired };
    return (
      <div className={cx('editor-element', dragModifier, modifierClasses)}>
        <div className="editor-element-wrapper">
          {this.renderHandle()}
          <div className="editor-element__info">
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          {this.renderIndicators()}
          {this.renderOptions()}
          {this.renderContextMenu()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithModals(withAccessControl(WithEditorActions(EditorElement))));
