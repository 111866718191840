import React from 'react';
import localeLookup from '../config/locale';
import { EMPTY_ID, MAIN_STATUS_CATEGORIES } from '../constants';
import Aside from './Aside';
import Badge from './Badge';
import Donut from './Donut';
import EmptyState from './EmptyState';
// Components
import Icon from './Icon';
import NotificationCounter from './NotificationCounter';
import PersonWrapper from './PersonWrapper';
import SideNavItem from './sideNav/SideNavItem';
import SideNavListSection from './sideNav/SideNavListSection';
import StatusLabel from './StatusLabel';
import Text from './Text';
import Tooltip from './Tooltip';
import { formatDate } from '../utils/helpers';

const RoleNav = ({
  activeView,
  additionalKnowledgeAreasSortOrder,
  areas,
  areasSortOrder,
  categories,
  completionPercentage,
  onSelect,
  selectedId,
  showDescriptionOption,
  knowledgeElements,
  showTrainingTasks,
  mentorId,
  startDate,
  endDate,
}) => {
  const getBadgeProps = (areaId, isAdditional) => {
    const area = areas[areaId];
    if (!area) return {};
    const shownNumberOfElements = area.knowledgeElements.length;
    const completedKnowledgeElements = () =>
      area.knowledgeElements.reduce((acc, id) => {
        const element = knowledgeElements[id];
        if (
          element.status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION)
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);

    const getColor = () => {
      const isAllElementsCompleted =
        completedKnowledgeElements() === shownNumberOfElements;
      if (mentorId !== EMPTY_ID) {
        return isAllElementsCompleted ? 'green' : 'light-green';
      }
      if (mentorId === EMPTY_ID && isAllElementsCompleted) {
        return 'dark-grey';
      }
      return null;
    };
    if (isAdditional && !area.isLinkedToPerson) {
      return {
        icon: 'unlink2',
      };
    }

    return {
      text: `${completedKnowledgeElements()}/${shownNumberOfElements}`,
      color: getColor(),
    };
  };

  const getSubtitle = ({ areaId, isAdditional = false }) => {
    const area = areas[areaId];
    if (isAdditional && !area.isLinkedToPerson) return null;
    if (area.trainingTasksCount > 0 && showTrainingTasks)
      return (
        <Text color="blue" size="sm">
          {localeLookup('translations.You have')} {area.trainingTasksCount}{' '}
          {area.trainingTasksCount === 1
            ? localeLookup('translations.training task')
            : localeLookup('translations.training tasks')}
        </Text>
      );
  };

  const renderMentorText = () => {
    if (mentorId === EMPTY_ID) return localeLookup('translations.No mentor');
    return (
      <PersonWrapper
        id={mentorId}
        render={({ name, color, tooltip }) => (
          <Tooltip tooltip={tooltip}>
            <span>{name}</span>
          </Tooltip>
        )}
      />
    );
  };

  const showEmptyState =
    areasSortOrder.length === 0 &&
    additionalKnowledgeAreasSortOrder.length === 0 &&
    !showDescriptionOption;
  return (
    <Aside wide>
      <div className="role-nav">
        <div className="role-nav__role-info">
          <Donut
            color={mentorId !== EMPTY_ID ? 'green' : 'grey'}
            size={60}
            value={completionPercentage}
            showLabel
            cutout="80%"
          />
          <div className="role-nav__role-info-mentor">
            <Text color="dark-grey">
              {localeLookup('translations.Mentor')}:{' '}
              <Text as="span" bold>
                {renderMentorText()}
              </Text>
            </Text>
            {mentorId !== EMPTY_ID && (
              <>
                <Text size="sm" color="dark-grey">
                  {localeLookup('translations.Start date')}:{' '}
                  <Text as="span" size="sm" bold>
                    {formatDate(startDate)}
                  </Text>
                </Text>
                {endDate ? (
                  <Text size="sm" color="dark-grey">
                    {localeLookup('translations.End date')}:{' '}
                    <Text as="span" size="sm" bold>
                      {formatDate(endDate)}
                    </Text>
                  </Text>
                ) : null}
              </>
            )}
          </div>
        </div>
        {showEmptyState && (
          <EmptyState title={localeLookup('translations.No content')} />
        )}

        {showDescriptionOption && (
          <SideNavListSection>
            <SideNavItem
              onClick={() => {
                onSelect('description');
              }}
              active={activeView === 'description'}
              leftComponent={
                <Icon
                  className="role-nav__list-role-desc-icon"
                  kind="profile"
                />
              }
              title={localeLookup('translations.Role description')}
            />
          </SideNavListSection>
        )}
        {areasSortOrder.length > 0 && (
          <SideNavListSection
            title={localeLookup('translations.Mandatory knowledge')}
          >
            {areasSortOrder.map((key) => {
              const area = areas[key];
              if (area) {
                return (
                  <SideNavItem
                    onClick={() => {
                      onSelect('area', area.id);
                    }}
                    key={key}
                    active={area.id === selectedId}
                    title={area.name}
                    subtitle={categories[area.category]}
                    subtitle2={getSubtitle({ areaId: area.id })}
                    leftComponent={
                      <Badge size="small" {...getBadgeProps(area.id)} />
                    }
                    rightComponent={
                      area.notificationCount > 0 && (
                        <NotificationCounter count={area.notificationCount} />
                      )
                    }
                  />
                );
              }
            })}
          </SideNavListSection>
        )}

        {areas &&
          additionalKnowledgeAreasSortOrder &&
          additionalKnowledgeAreasSortOrder.length > 0 && (
            <SideNavListSection
              title={localeLookup('translations.Additional knowledge')}
            >
              {additionalKnowledgeAreasSortOrder.map((key) => {
                const area = areas[key];
                if (area) {
                  return (
                    <SideNavItem
                      onClick={() => {
                        onSelect('area', area.id, true);
                      }}
                      key={key}
                      active={area.id === selectedId}
                      leftComponent={
                        <Badge size="small" {...getBadgeProps(area.id, true)} />
                      }
                      title={area.name}
                      subtitle={categories[area.category]}
                      subtitle2={getSubtitle({
                        areaId: area.id,
                        isAdditional: true,
                      })}
                      rightComponent={
                        area.notificationCount > 0 && (
                          <NotificationCounter count={area.notificationCount} />
                        )
                      }
                    />
                  );
                }
              })}
            </SideNavListSection>
          )}
      </div>
    </Aside>
  );
};

export default RoleNav;
