/* eslint-disable arrow-body-style */
import cx from 'classnames';
import React from 'react';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, EMPTY_ID } from '../constants';
import Button from './Button';
import ButtonIcon from './ButtonIcon';
import DateTime from './DateTime';
import withAccessControl from './HOC/withAccessControl';
import withPersonLookup from './HOC/withPersonLookup';
import Text from './Text';
import Tooltip from './Tooltip';

const ElementHistoryEventApproval = ({
  currentUserId,
  date,
  createdAt,
  deleted,
  deletedAt,
  deletedBy,
  deletedById,
  hasAccess,
  hasSignature,
  nested,
  note,
  onClickDelete,
  onClickRestore,
  onClickShowElementVersion,
  onClickShowSignature,
  restored,
  restoredAt,
  restoredBy,
  restoredById,
  sessionId,
  signature,
  signatureGatheredBy,
  signatureGatheredById,
  signatureMetadata,
  signedBy,
  signeeId,
  title,
  showCreatedAt,
  lookupPerson,
  version,
}) => {
  const canSeeVersion = hasAccess([
    ACCESS_LEVELS.champadministrator,
    ACCESS_LEVELS.administrator,
    ACCESS_LEVELS.teamadministrator,
  ]);

  const canDelete = () => {
    if (
      hasAccess([ACCESS_LEVELS.champadministrator, ACCESS_LEVELS.administrator])
    ) {
      return true;
    }
    if (restored) {
      return restoredById === currentUserId;
    }
    if (deleted) {
      return deletedById === currentUserId;
    }
    if (signeeId === currentUserId) {
      return true;
    }
    return false;
  };
  const getSignedByTooltip = () => {
    const person = lookupPerson(signeeId);
    if (person) {
      return `${person.initials}${
        person.employeeNumber ? ` · ${person.employeeNumber}` : ''
      }`;
    }
    return null;
  };
  const getDeletedByTooltip = () => {
    const person = lookupPerson(deletedById);
    if (person) {
      return `${person.initials}${
        person.employeeNumber ? ` · ${person.employeeNumber}` : ''
      }`;
    }
    return null;
  };
  const getRestoredByTooltip = () => {
    const person = lookupPerson(restoredById);
    if (person) {
      return `${person.initials}${
        person.employeeNumber ? ` · ${person.employeeNumber}` : ''
      }`;
    }
    return null;
  };
  return (
    <div
      className={cx('element-history-event-approval', {
        'element-history-event-approval--nested': nested,
      })}
    >
      <div className="element-history-event-approval__inner">
        <div className="element-history-event-approval__left">
          <Text size="md">
            {title}{' '}
            {signedBy && (
              <>
                ·{' '}
                {canSeeVersion ? (
                  <Button
                    kind="link-style"
                    onClick={() => onClickShowElementVersion(version)}
                  >
                    v{version}
                  </Button>
                ) : (
                  <Text as="span">v{version}</Text>
                )}
              </>
            )}
          </Text>
          {signedBy && (
            <Text size="sm">
              <Tooltip tooltip={getSignedByTooltip()}>
                <span>{signedBy}</span>
              </Tooltip>
              , <DateTime includeTime date={date}></DateTime>
            </Text>
          )}
          {signedBy && showCreatedAt && (
            <Text size="sm">
              {localeLookup('translations.Created')}{' '}
              <DateTime includeTime date={createdAt}></DateTime>
            </Text>
          )}
          {deleted && !restored && (
            <Text color="red" size="sm">
              {localeLookup('translations.Deleted by')}{' '}
              <Tooltip tooltip={getDeletedByTooltip()}>
                <span>{deletedBy}</span>
              </Tooltip>
              , <DateTime includeTime date={deletedAt}></DateTime>
            </Text>
          )}
          {restored && (
            <Text color="green" size="sm">
              {localeLookup('translations.Restored by')}{' '}
              <Tooltip tooltip={getRestoredByTooltip()}>
                <span>{restoredBy}</span>
              </Tooltip>
              , <DateTime includeTime date={restoredAt}></DateTime>
            </Text>
          )}
          {note && (
            <Text color="dark-grey" size="sm">
              {localeLookup('translations.Note')}: {note}
            </Text>
          )}
          {hasSignature && (
            <Button
              className="element-history-event-approval__signature-button"
              onClick={() =>
                onClickShowSignature({
                  signature,
                  signee: signedBy,
                  signatureMetadata,
                  signatureGatheredBy,
                  signatureGatheredById,
                  signedById: signeeId,
                })
              }
              kind="link-style"
            >
              {localeLookup('translations.Show signature')}
            </Button>
          )}
        </div>
        {(onClickDelete && canDelete() && sessionId == EMPTY_ID) ||
        (onClickDelete && canDelete() && sessionId && !nested) ? (
          <div className="element-history-event-approval__right">
            <ButtonIcon
              onClick={deleted ? onClickRestore : onClickDelete}
              icon={deleted ? 'undelete' : 'trash2'}
              iconColor={deleted ? 'green' : 'red'}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withAccessControl(withPersonLookup(ElementHistoryEventApproval));
