import React, { Component } from 'react';
import cx from 'classnames';
import Icon from './Icon';

class InlineSelectEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.resetSelect = this.resetSelect.bind(this);

    this.state = {
      editing: false,
      value: this.props.defaultValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editing: false,
    });

    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: nextProps.defaultValue });
    }
  }

  onChange(e) {
    e.preventDefault();
    const newValue = this.select.value;

    if (newValue != this.props.defaultValue) {
      this.setState({
        value: newValue,
      });
      this.props.onSubmit(newValue, this.resetSelect);
    } else {
      this.select.blur();
    }
  }

  onBlur() {
    this.setState({
      editing: false,
    });
  }

  resetSelect() {
    this.setState({
      value: this.props.defaultValue,
    });
  }

  render() {
    const {
      className,
      classNameSelect,
      defaultValue,
      options,
      label,
      selectId,
      inlineLabel,
      disabled,
    } = this.props;

    return (
      <div
        className={`inline-select-editor ${cx(className, {
          'inline-select-editor--inline-label': inlineLabel,
          'inline-select-editor--disabled': disabled,
        })}`}
      >
        <form className="inline-select-editor__form" autoComplete="off">
          {label && (
            <label
              className="e-label-upper inline-select-editor__form-label"
              htmlFor={selectId}
            >
              {label}
            </label>
          )}
          <select
            className={`ui-select inline-select-editor__form-select ${cx(
              classNameSelect
            )}`}
            onChange={this.onChange}
            disabled={disabled}
            value={this.state.value}
            id={selectId}
            ref={(input) => {
              this.select = input;
            }}
          >
            {options &&
              options.map((option, i) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                );
              })}
          </select>
        </form>
      </div>
    );
  }
}

export default InlineSelectEditor;
