/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import localeLookup from '../../config/locale';
import { getCurrentDate } from '../../utils/helpers';
import BoxMessage from '../BoxMessage';
import DateField from '../DateField';
import Textarea from '../Textarea';
import GenericModal from './GenericModal';
import DateFieldV2 from '../DateFieldV2';
import { format } from 'date-fns';
class AdminApprovalModal extends Component {
  constructor() {
    super();
    this.state = {
      note: '',
      date: new Date(),
      useDate: false,
    };
  }

  onChangeDate = (date) => {
    this.setState({
      date,
      useDate: true,
    });
  };

  onChangeNote = (e) => {
    this.setState({
      note: e.target.value,
    });
  };

  onClickConfirm = () => {
    const { note, date, useDate } = this.state;
    const { onConfirm } = this.props;
    const formattedDate = format(new Date(date), 'yyyy-MM-dd');
    onConfirm(note, useDate ? formattedDate : null);
  };

  render() {
    const { note, date } = this.state;
    const {
      cancelButtonText,
      onCancel,
      onClose,
      onCloseClick,
      subtitle,
      infoText,
    } = this.props;
    return (
      <GenericModal
        title={localeLookup('translations.Complete as admin')}
        subtitle={subtitle}
        confirmButtonText={localeLookup('translations.Add')}
        onConfirmClick={this.onClickConfirm}
        cancelButtonText={cancelButtonText}
        onCancelClick={onCancel || onCloseClick}
        onClose={onClose || onCloseClick}
      >
        {infoText && (
          <BoxMessage spacing="md" icon="info-circle">
            {infoText}
          </BoxMessage>
        )}
        <DateFieldV2
          label={localeLookup('translations.Valid from')}
          value={date}
          maxDate={new Date()}
          onChange={this.onChangeDate}
        />
        <Textarea
          maxLength={255}
          placeholder={localeLookup('translations.Add a note')}
          onChange={this.onChangeNote}
          value={note}
        />
      </GenericModal>
    );
  }
}

export default AdminApprovalModal;
