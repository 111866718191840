import React, { PureComponent } from 'react';
import localeLookup from '../../config/locale';
import CheckList from '../CheckList';
import ModalBody from './ModalBody';
import ModalFilter from './ModalFilter';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import EmptyState from '../EmptyState';
import Text from '../Text';
import SwitchCheckbox from '../SwitchCheckbox';
import ListCard from '../ListCard';
import MultiStateButton from '../MultiStateButton';
import { Legend } from '../Legend';
import { compareLocal, sortBy } from '../../utils/helpers';

export default class MultiStateButtonModal extends PureComponent {
  constructor(props) {
    super(props);
    const { options } = props;
    this.state = {
      filterString: '',
      options,
    };
  }

  getVisibleOptions = () => {
    const { filterString, options } = this.state;
    const filteredOptions = options.filter((option) => {
      if (option.searchString) {
        return option.searchString
          .toLowerCase()
          .includes(filterString.toLowerCase());
      }
      return (
        option.title.toLowerCase().includes(filterString.toLowerCase()) ||
        (option.subtitle &&
          option.subtitle.toLowerCase().includes(filterString.toLowerCase()))
      );
    });

    return sortBy(filteredOptions, [(a, b) => compareLocal(a.title, b.title)]);
  };

  onClickChangeOptionState = ({ optionId, newStateId }) => {
    const { options } = this.state;
    this.setState({
      options: options.map((option) => {
        if (option.id === optionId) {
          return { ...option, activeStateId: newStateId };
        }
        return option;
      }),
    });
  };

  onConfirm = () => {
    const { options } = this.state;
    const { onConfirm } = this.props;
    onConfirm(options);
  };

  onFilterChange = (e) => {
    this.setState({
      filterString: e.target.value,
    });
  };

  renderOption = (option) => {
    const { disabled, id, isRequired, activeStateId, states, title, subtitle } =
      option;
    return (
      <ListCard
        disabled={disabled}
        key={id}
        rightComponent={
          <MultiStateButton
            disabled={disabled}
            isRequired={isRequired}
            onClick={(newStateId) =>
              this.onClickChangeOptionState({
                optionId: option.id,
                newStateId,
              })
            }
            rounded
            activeStateId={activeStateId}
            states={states}
          />
        }
      >
        <>
          <Text>{title}</Text>
          {subtitle && (
            <Text size="sm" color="dark-grey">
              {subtitle}
            </Text>
          )}
        </>
      </ListCard>
    );
  };

  renderOptions = () => {
    return this.getVisibleOptions().map(this.renderOption);
  };

  render() {
    const {
      title,
      onClose,
      confirmBtnText,
      onFilterButtonClick,
      filterPlaceholder,
      filterButtonText,
      showFilterButton,
      options,
      emptyStateText,
      subtitle,
    } = this.props;
    const { filterString } = this.state;
    const showEmptyState = options.length === 0;
    return (
      <ModalWrapper>
        <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
        <ModalFilter
          placeholder={
            filterPlaceholder || `${localeLookup('translations.Search')}...`
          }
          onChange={this.onFilterChange}
          value={filterString}
          buttonIcon="plus-circle"
          buttonText={filterButtonText}
          onButtonClick={() => onFilterButtonClick({ filterString })}
          showButton={showFilterButton}
        />
        <ModalBody deep>
          {showEmptyState ? (
            <EmptyState body={emptyStateText} />
          ) : (
            this.renderOptions()
          )}
        </ModalBody>
        <Legend
          horizontalPadding
          items={[
            { icon: 'check', text: localeLookup('translations.Editable') },
            { icon: 'eye', text: localeLookup('translations.Read only') },
          ]}
        >
          <Text size="sm">
            <Text as="span" color="red">
              *
            </Text>{' '}
            {localeLookup(
              'translations.The module is used in a role in the content space and can not be removed'
            )}
          </Text>
        </Legend>
        <ModalFooter
          onCancelClick={onClose}
          onConfirmClick={this.onConfirm}
          confirmButtonText={confirmBtnText}
        />
      </ModalWrapper>
    );
  }
}
