import React, { Component } from 'react';
import localeLookup from '../config/locale';
import { insertStringInString } from '../utils/helpers';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';
import Counter from './Counter';
import Icon from './Icon';
import RadioButton from './RadioButton';
import cx from 'classnames';
import { connect } from 'react-redux';
import { ELEMENT_TYPES } from '../constants';
import WithEditorActions from './HOC/withEditorActions';
import { selectElement } from '../slices/elementsSlice';

const mapStateToProps = (state, ownProps) => ({
  element: selectElement(state, ownProps.elementId),
  allowChangeOfChampLinkVisibility:
    state.rootmenu.allowChangeOfChampLinkVisibility,
});

class EditorElementContextMenu extends Component {
  getConnectedRoleIds = () => {
    const { element } = this.props;
    return Object.keys(element.roleConnections).filter(
      (id) => element.roleConnections[id]
    );
  };

  onChangeExpiration = (value) => {
    const { element, editorActions } = this.props;
    if (value === element.validity.validPeriod) return;
    const enabled = value > 0;
    editorActions.changeElementExpiration({ id: element.id, enabled, value });
  };

  onClickDelete = () => {
    const { editorActions, elementId, areaId, roleId } = this.props;
    editorActions.showConfirmElementDeleteModal({ elementId, areaId, roleId });
  };

  onClickEditDescription = () => {
    const { element, hasAccessToArea, showModal, editorActions } = this.props;
    if (!hasAccessToArea) {
      showModal('richText', {
        title: element.name,
        description: element.description,
        files: element.files,
        maxWidth: '700px',
        fullWidth: true,
      });
    } else {
      editorActions.showChangeElementDescriptionModal(element.id);
    }
  };

  onClickEditResponsible = () => {
    const { element, areaId, editorActions } = this.props;
    editorActions.showChangeElementResponsibleModal(element.id, areaId);
  };

  onClickMove = () => {
    const { editorActions, elementId, areaId } = this.props;
    editorActions.showChangeElementLocationModal({ id: elementId, areaId });
  };

  onClickShowVersions = () => {
    const { editorActions, elementId } = this.props;
    editorActions.showElementVersionsModal({ id: elementId });
  };

  onToggleElementLinkVisiblity = () => {
    const {
      allowChangeOfChampLinkVisibility,
      elementId,
      editorActions,
      element,
    } = this.props;
    if (allowChangeOfChampLinkVisibility)
      editorActions.showToggleElementLinkVisiblityModal({
        elementId: elementId,
        elementName: element.name,
        isPublic: element.champLink.isPublic,
      });
  };

  onChangeElementType = (e) => {
    const { element, editorActions } = this.props;
    editorActions.changeElementType(element.id, e.target.value);
  };

  onClickEditConnections = () => {
    const { editorActions, element } = this.props;
    editorActions.showChangeElementConnectionsModal(element.id);
  };

  render() {
    const {
      hasAccessToArea,
      element,
      elementId,
      allowChangeOfChampLinkVisibility,
      triggerSize,
      containerClass
    } = this.props;
    const descriptionLength =
      element.description &&
      element.description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    const numConnectedRoles = this.getConnectedRoleIds().length;
    const champLinkIcon = () => {
      if (allowChangeOfChampLinkVisibility) {
        return element.champLink.isPublic ? 'unlock' : 'lock';
      }
      return null;
    };
    return (
      <ContextMenu
        containerElementClass={containerClass}
        triggerSize={triggerSize}
        triggerClass="designboard-knowledge-element__options-context-menu-icon"
      >
        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickEditResponsible}
          leftIconKind="check-square"
          titleText={localeLookup('translations.Responsibility & completion')}
        />

        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickEditDescription}
          leftIconKind="document2"
          titleText={localeLookup('translations.Content')}
          subtitleText={
            descriptionLength > 0
              ? element.description.replace(/(<([^>]+)>)/gi, '')
              : localeLookup('translations.No notes')
          }
        />
        <li
          className={cx('context-menu-item context-menu-item--form', {
            'context-menu-item--disabled': !hasAccessToArea,
          })}
        >
          <Icon kind="notification" className="context-menu-item__icon-left" />
          <div className="context-menu-item__text">
            <p className="context-menu-item__text-title">
              {localeLookup('translations.Type')}
            </p>
            <form>
              <div className="context-menu-item__form-field">
                <RadioButton
                  disabled={!hasAccessToArea}
                  size="small"
                  name={`elementType${elementId}`}
                  isChecked={element.type === ELEMENT_TYPES.STANDARD}
                  value={ELEMENT_TYPES.STANDARD}
                  id={`${elementId}notRequired`}
                  onChange={this.onChangeElementType}
                />
                <label
                  htmlFor={`${elementId}notRequired`}
                  className="context-menu-item__form-label"
                >
                  {localeLookup('translations.Standard')}
                </label>
              </div>
              <div className="context-menu-item__form-field">
                <RadioButton
                  disabled={!hasAccessToArea}
                  size="small"
                  name={`elementType${elementId}`}
                  isChecked={element.type === ELEMENT_TYPES.CRITICAL}
                  value={ELEMENT_TYPES.CRITICAL}
                  id={`${elementId}required`}
                  onChange={this.onChangeElementType}
                />
                <label
                  htmlFor={`${elementId}required`}
                  className="context-menu-item__form-label"
                >
                  {localeLookup('translations.Critical')}
                </label>
              </div>
            </form>
          </div>
        </li>
        <ContextMenuItem
          disabled={!hasAccessToArea}
          noClick
          leftIconKind="sync"
          titleText={localeLookup('translations.Expiration')}
          subtitleText={localeLookup('translations.Days until expiration')}
          rightComponent={
            <Counter
              disabled={!hasAccessToArea}
              value={element.validity.validPeriod}
              zeroAsDash
              handleChange={this.onChangeExpiration}
            />
          }
        />
        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickShowVersions}
          leftIconKind="compare"
          titleText={localeLookup('translations.Versions')}
        />
        <ContextMenuItem
          disabled={!hasAccessToArea}
          leftIconKind="champ-link-outline-2"
          rightIconKind={champLinkIcon()}
          onClick={this.onToggleElementLinkVisiblity}
          titleText={insertStringInString(element.champLink.link, '-', 3)}
        />
        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickEditConnections}
          leftIconKind="share2"
          titleText={localeLookup('translations.Connections')}
          subtitleText={`${numConnectedRoles} ${
            numConnectedRoles === 1
              ? localeLookup('translations.role')
              : localeLookup('translations.roles')
          }`}
        />
        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickMove}
          leftIconKind="book2"
          titleText={localeLookup('translations.Relocate')}
        />
        <ContextMenuItem
          disabled={!hasAccessToArea}
          onClick={this.onClickDelete}
          leftIconClassName="designboard-knowledge-element__options-delete-icon"
          leftIconKind="trash2"
          titleText={localeLookup('translations.Delete')}
        />
      </ContextMenu>
    );
  }
}

export default connect(mapStateToProps)(
  WithEditorActions(EditorElementContextMenu)
);
