import React from 'react';
import cx from 'classnames';
import Icon from './Icon';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';

const SwitchCheckbox = ({
  className,
  isChecked,
  onChange,
  size,
  id,
  name,
  stopPropagation,
  disabled,
  labelText,
  labelSize,
  defaultChecked,
  wrapperClassName,
  icon,
  tooltip,
  hitslop,
}) => {
  const modifierClasses = {
    [`switch--hitslop-${hitslop}`]: hitslop,
  };

  const sizeClass = size ? `switch--${size} ` : '';
  const onLabelClick = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
  };
  if (labelText) {
    return (
      <ConditionalWrap
        condition={tooltip}
        wrap={(children) => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
      >
        <label
          className={cx('switch-wrapper', modifierClasses, wrapperClassName)}
          htmlFor={id}
        >
          {icon && <Icon color="grey" className="switch__icon" kind={icon} />}
          <span
            className={cx('switch__label', {
              [`switch__label--size-${labelSize}`]: labelSize,
            })}
          >
            {labelText}
          </span>
          <div className={`switch switch--round ${sizeClass}${className}`}>
            <input
              className="switch__checkbox"
              defaultChecked={defaultChecked}
              id={id}
              type="checkbox"
              name={name}
              disabled={disabled}
              onChange={onChange}
              checked={isChecked}
            />
            <div className={`switch__slider ${sizeClass}`} />
          </div>
        </label>
      </ConditionalWrap>
    );
  }

  return (
    <label
      className={cx('switch switch--round', sizeClass, modifierClasses, className)}
      htmlFor={id}
      onClick={onLabelClick}
    >
      <input
        className="switch__checkbox"
        defaultChecked={defaultChecked}
        id={id}
        type="checkbox"
        name={name}
        disabled={disabled}
        onChange={onChange}
        checked={isChecked}
      />
      <div className={`switch__slider ${sizeClass}`} />
    </label>
  );
};

export default SwitchCheckbox;
