import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS } from '../constants';
import { queryAreas, removeArea } from '../slices/areasSlice';
import { selectActivePersonsSortOrder } from '../slices/personsSlice';
import { compareLocal, sortBy } from '../utils/helpers';
import Button from './Button';
import withAccessControl from './HOC/withAccessControl';
import WithEditorActions from './HOC/withEditorActions';
import WithModals from './HOC/withModals';
import withPersonLookup from './HOC/withPersonLookup';
import Icon from './Icon';
import InlineFieldEditor from './InlineFieldEditor';
import Tooltip from './Tooltip';
import TruncatedArrayText from './TruncatedArrayText';

const mapStateToProps = (state) => {
  const { persons, user } = state;
  return {
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    persons,
    currentUserId: user.employeeId,
    spaces: state.spaces.spaces,
    spacesEnabled: state.spaces.enabled,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryAreas, removeArea }, dispatch);

class KnowledgeEditorSettings extends Component {
  getArea = () => {
    const { area, queryAreas } = this.props;
    queryAreas([area.id]);
  };

  onClickDelete = () => {
    const { area, history, showModal, removeArea, editorActions } = this.props;
    editorActions.showConfirmAreaDeleteModal(area.id, () => {
      history.replace('/editor/knowledge');
    });
  };

  onClickChangeEditors = () => {
    const { area, editorActions, history } = this.props;
    editorActions.showChangeAreaEditorsModal({
      areaId: area.id,
      onChanged: (hasLostAccess) => {
        if (hasLostAccess) {
          history.replace('/editor/knowledge');
        }
      },
    });
  };

  onClickChangeExperts = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaExpertsModal({ areaId: area.id });
  };

  onSubmitChangeName = (value) => {
    const { area, history, location, editorActions } = this.props;
    editorActions.changeAreaName(area.id, value).then(() => {
      history.replace(location.pathname, { ...location.state, title: value });
    });
  };

  onClickChangeOwner = () => {
    const { area, editorActions, history } = this.props;
    editorActions.showChangeAreaOwnerModal({
      areaId: area.id,
      selectedValue: area.owner,
      onChanged: (hasLostAccess) => {
        if (hasLostAccess) {
          history.replace('/editor/knowledge');
        }
      },
    });
  };

  onClickChangeCategory = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaCategoryModal(area.id);
  };
  onClickChangeSpaces = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaSpaceModal({ areaId: area.id });
  };

  render() {
    const {
      area,
      categories,
      categoriesSortOrder,
      persons,
      lookupPerson,
      hasAccess,
      currentUserId,
      spaces,
      spacesEnabled,
      isAreaReadOnly,
      hasChangeAreaOwnerPermission,
    } = this.props;
    const hasAccessRoles = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ]);

    const isAdminInAnySpacesWhereAreaIsEditable = Object.keys(
      area.editableInSpaces
    ).some((spaceId) => spaces[spaceId].administrators.includes(currentUserId));

    const showContentSpaces = spacesEnabled
      ? hasAccessRoles || isAdminInAnySpacesWhereAreaIsEditable
      : false;

    const canEditOwner = hasChangeAreaOwnerPermission({
      currentOwnerId: area.owner,
      editableInSpaceIds: Object.keys(area.editableInSpaces),
    });

    const getSpacesLabel = () => {
      const editableInSpaceNames = sortBy(
        Object.keys(area.editableInSpaces).map((id) => spaces[id]?.name),
        [(a, b) => compareLocal(a, b)]
      );
      const readOnlyInSpaceNames = sortBy(
        Object.keys(area.readOnlyInSpaces).map((id) => spaces[id]?.name),
        [(a, b) => compareLocal(a, b)]
      );
      return (
        <TruncatedArrayText
          showTooltip={false}
          countSuffixText={localeLookup('translations.Content spaces')}
          items={[...editableInSpaceNames, ...readOnlyInSpaceNames]}
        />
      );
    };
    const getSpacesTooltip = () => {
      const editableInSpaceNames = sortBy(
        Object.keys(area.editableInSpaces).map((id) => spaces[id]?.name),
        [(a, b) => compareLocal(a, b)]
      );
      const readOnlyInSpaceNames = sortBy(
        Object.keys(area.readOnlyInSpaces).map((id) => spaces[id]?.name),
        [(a, b) => compareLocal(a, b)]
      );
      return (
        <>
          {editableInSpaceNames?.length > 0 && (
            <>
              <p>{localeLookup('translations.Editable')}:</p>
              <ul>
                {editableInSpaceNames.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </>
          )}
          {readOnlyInSpaceNames?.length > 0 && (
            <>
              <p>{localeLookup('translations.Read only')}:</p>
              <ul>
                {readOnlyInSpaceNames.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </>
          )}
        </>
      );
    };
    const getEditorsLabel = () => {
      if (area.editors.length === 0)
        return localeLookup('translations.No editors');
      if (area.editors.length === 1) {
        const person = lookupPerson(area.editors[0]);
        if (person) return person.name;
        return '';
      }
      return localeLookup('translations.{0} editors', [area.editors.length]);
    };
    const getExpertsLabel = () => {
      if (area.experts.length === 0)
        return localeLookup('translations.No experts');
      if (area.experts.length === 1) {
        const person = lookupPerson(area.experts[0]);
        return person.name;
      }
      return localeLookup('translations.{0} experts', [area.experts.length]);
    };

    return (
      <div className="editor-settings">
        <div className="editor-settings__header">
          <h1 className="editor-settings__header-title">
            {localeLookup('translations.Area settings')}
          </h1>
          <Button
            className="editor-settings__header-delete-button"
            icon="trash2"
            kind="alert"
            onClick={this.onClickDelete}
            disabled={isAreaReadOnly}
          >
            {localeLookup('translations.Delete')}
          </Button>
        </div>
        <div className="editor-settings__options">
          <InlineFieldEditor
            disabled={isAreaReadOnly}
            className="editor-settings__options-value"
            label={localeLookup('translations.Name')}
            inputId="name"
            defaultValue={area.name}
            onSubmit={this.onSubmitChangeName}
          />
          {showContentSpaces && (
            <div className="editor-settings__field">
              <h2 className="e-label-upper editor-settings__field-label">
                {localeLookup('translations.Content spaces')}
              </h2>
              <Tooltip tooltip={getSpacesTooltip()}>
                <button
                  onClick={this.onClickChangeSpaces}
                  className="editor-settings__field-button"
                  type="button"
                >
                  {getSpacesLabel()}
                </button>
              </Tooltip>
            </div>
          )}

          <div className="editor-settings__field">
            <h2 className="e-label-upper editor-settings__field-label">
              {localeLookup('translations.Owner')}
            </h2>
            <button
              disabled={!canEditOwner}
              onClick={this.onClickChangeOwner}
              className="editor-settings__field-button"
              type="button"
            >
              {persons[area.owner]?.name ||
                localeLookup('translations.No owner')}
            </button>
          </div>
          <div className="editor-settings__field">
            <h2 className="e-label-upper editor-settings__field-label">
              {localeLookup('translations.Editors')}
            </h2>
            <Tooltip
              tooltip={
                area.editors?.length > 0 &&
                area.editors.map((id) => (
                  <p key={id}>{lookupPerson(id)?.name}</p>
                ))
              }
            >
              <button
                disabled={isAreaReadOnly}
                onClick={this.onClickChangeEditors}
                className="editor-settings__field-button"
                type="button"
              >
                {getEditorsLabel()}
              </button>
            </Tooltip>
          </div>
          <div className="editor-settings__field">
            <h2 className="e-label-upper editor-settings__field-label">
              {localeLookup('translations.Experts')}
            </h2>
            <Tooltip
              tooltip={
                area.experts?.length > 0 &&
                area.experts.map((id) => (
                  <p key={id}>{lookupPerson(id)?.name}</p>
                ))
              }
            >
              <button
                onClick={this.onClickChangeExperts}
                className="editor-settings__field-button"
                type="button"
                disabled={isAreaReadOnly}
              >
                {getExpertsLabel()}
              </button>
            </Tooltip>
          </div>
          <div className="editor-settings__field">
            <h2 className="e-label-upper editor-settings__field-label">
              {localeLookup('translations.Category')}
            </h2>
            <button
              onClick={this.onClickChangeCategory}
              className="editor-settings__field-button"
              type="button"
              disabled={isAreaReadOnly}
            >
              {categories[area.category] ||
                localeLookup('translations.No category')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  WithModals(
    withRouter(
      withAccessControl(
        WithEditorActions(withPersonLookup(KnowledgeEditorSettings))
      )
    )
  )
);
