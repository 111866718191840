import cx from 'classnames';
import React from 'react';

const ModalStepIndicator = ({ steps, activeStepIndex }) => {
  if (!steps) return null;
  return (
    <p className="modal-step-indicator">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <span
            className={cx('modal-step-indicator__item', {
              'modal-step-indicator__item--active': activeStepIndex === index,
            })}
          >
            {step}
          </span>
          {index + 1 !== steps.length && <span className="modal-step-indicator__seperator">{'>'}</span>}
        </React.Fragment>
      ))}
    </p>
  );
};

export default ModalStepIndicator;
