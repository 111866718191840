import React, { Component } from 'react';
import localeLookup from '../config/locale';
import TextareaAutosize from 'react-textarea-autosize';

import Input from './formElements/Input';
import WithModals from './HOC/withModals';

class CreateElementBox extends Component {
  constructor() {
    super();
    this.state = {
      elementName: '',
      useBlurEvent: true,
    };
  }
  componentDidMount() {
    this.nameInput.focus();
  }

  onInputChange = (e) => {
    this.setState({
      elementName: e.target.value,
    });
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit(e);
    }
  };

  onSubmit = (e) => {
    const { onSubmit, showModal, hideModal, onBlur } = this.props;
    const { elementName } = this.state;
    this.setState({ useBlurEvent: false });
    e.preventDefault();
    if (elementName.trim().length === 0) return;
    const elementNames = elementName.split('\n').filter((name) => name.trim() !== '');
    if (elementNames.length > 100) {
      showModal('infoModal', {
        title: localeLookup('translations.Create element'),
        body: localeLookup('translations.The limit for creating elements is {0} elements simultaneously', ['100']),
        fullWidth: true,
        maxWidth: '500px',
        onClose: () => {
          this.setState({ useBlurEvent: true });
          hideModal();
        },
      });
    } else if (elementNames.length > 1) {
      showModal('confirmation', {
        title: localeLookup('translations.Create element'),
        maxWidth: '600px',
        body: <>{localeLookup('translations.Do you want to create {0} elements or just one?', [elementNames.length])}</>,
        confirmButtonText: localeLookup('translations.Create {0} elements', [elementNames.length]),
        btnRejectTitle: localeLookup('translations.Just one element'),
        onConfirm: () => {
          onSubmit(elementNames);
          this.setState({
            elementName: '',
          });
          onBlur();
          this.setState({ useBlurEvent: true });
        },
        onCancel: () => {
          hideModal();
          onSubmit([elementName]);
          this.setState({
            elementName: '',
          });
          onBlur();
          this.setState({ useBlurEvent: true });
        },
      });
    } else {
      onSubmit([elementName]);
      this.setState({
        elementName: '',
      });
      this.setState({ useBlurEvent: true });
    }
  };

  render() {
    const { useBlurEvent } = this.state;
    const { placeholder, onBlur } = this.props;

    return (
      <div className="add-element-box">
        <div className="add-element-box__left">
          <TextareaAutosize
            ref={(input) => {
              this.nameInput = input;
            }}
            placeholder={placeholder}
            onBlur={useBlurEvent ? onBlur : undefined}
            value={this.state.elementName}
            onChange={this.onInputChange}
            className="add-element-box__input"
            maxRows={10}
            onKeyDown={this.onKeyDown}
          ></TextareaAutosize>
        </div>
        <div className="add-element-box__right">
          <button className={`ui-btn ui-btn-${this.state.elementName.trim().length > 0 ? 'darkui' : 'inactive'} add-element-box__button`} onMouseDown={this.onSubmit}>
            {localeLookup('translations.Add')}
          </button>
        </div>
      </div>
    );
  }
}

export default WithModals(CreateElementBox);
