/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cx from 'classnames';
import ConditionalWrap from './ConditionalWrapper';
import Tooltip from './Tooltip';
import Icon from './Icon';

const Checkbox = ({
  id,
  onChange,
  label,
  className,
  isChecked,
  name,
  labelPosition = 'left',
  disabled,
  onClick,
  tooltip,
  style,
  readOnly,
}) => {
  const modifierClasses = {
    [`checkbox--style-${style}`]: style,
  };
  return (
    <div
      onClick={onClick}
      className={cx('checkbox', modifierClasses, className)}
    >
      {label && labelPosition === 'left' && (
        <label
          htmlFor={id}
          className="e-label-upper checkbox__text checkbox__text--mg-right"
        >
          {label}
          {tooltip && (
            <Tooltip tooltip={tooltip}>
              <span>
                <Icon
                  className="checkbox__tooltip-icon"
                  kind="info-circle"
                ></Icon>
              </span>
            </Tooltip>
          )}
        </label>
      )}
      <input
        readOnly={readOnly}
        className="checkbox__input"
        type="checkbox"
        onChange={onChange}
        id={id}
        name={name}
        checked={isChecked}
        disabled={disabled}
      />
      <label htmlFor={id} className="checkbox__label">
        <svg className="checkbox__icon" viewBox="0 0 20 20">
          <path d="M5.5 17.5c-0.128 0-0.256-0.049-0.354-0.146l-5-5c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l4.646 4.646 13.646-13.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-14 14c-0.098 0.098-0.226 0.146-0.354 0.146z" />
        </svg>
      </label>
      {label && labelPosition === 'right' && (
        <label
          htmlFor={id}
          className="e-label-upper checkbox__text checkbox__text--mg-left"
        >
          {label}
          {tooltip && (
            <Tooltip tooltip={tooltip}>
              <span>
                <Icon
                  className="checkbox__tooltip-icon"
                  kind="info-circle"
                ></Icon>
              </span>
            </Tooltip>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
