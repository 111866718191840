import React from 'react';
import InputLabel from './formElements/InputLabel';
import RadioButton from './RadioButton';
import cx from 'classnames';
export const RadioButtonGroup = ({
  options = [],
  groupName = '',
  onChange,
  label,
  optionDirection = 'vertical',
  direction = 'vertical',
  disabled,
}) => {
  const modifierClasses = cx({
    'radio-button-group--direction-horizontal': direction === 'horizontal',
    'radio-button-group--option-direction-horizontal':
      optionDirection === 'horizontal',
  });
  return (
    <div className={cx('radio-button-group', modifierClasses)}>
      {label && (
        <InputLabel noMargin={direction === 'horizontal'}>{label}</InputLabel>
      )}
      <div className="radio-button-group__options">
        {options
          ? options.map((option, i) => {
              return (
                <div key={i} className="radio-button-group__option">
                  <RadioButton
                    size={option.size}
                    name={groupName}
                    isChecked={option.isChecked}
                    value={option.value}
                    id={option.value}
                    onChange={onChange}
                    disabled={disabled || option.disabled}
                  />
                  <InputLabel htmlFor={option.value} theme="none" noMargin>
                    {option.label}
                  </InputLabel>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
