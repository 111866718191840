/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

// Components
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalWrapper from './ModalWrapper';
import ButtonIcon from '../ButtonIcon';
import localeLookup from '../../config/locale';
import BoxMessage from '../BoxMessage';

class MultiActionModal extends Component {
  render() {
    const { onClose, title, body, actions, closeOnActionClick = true, steps, message, subtitle } = this.props;
    return (
      <ModalWrapper>
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} steps={steps} />
        {message && (
          <BoxMessage type={message.type} icon={message.type === 'warning' ? 'warning' : 'info-circle'}>
            {message.text}
          </BoxMessage>
        )}
        <ModalBody>{body}</ModalBody>
        <div className="modal-content__footer">
          <button type="button" className="ui-btn ui-btn-cancel modal-content__footer-btn" onClick={onClose}>
            {localeLookup('translations.Cancel')}
          </button>
          {actions.map((action, i) => {
            return (
              <button
                type="button"
                key={i}
                onClick={() => {
                  action.action();
                  closeOnActionClick && onClose();
                }}
                className={`ui-btn ui-btn-${action.btnKind ? action.btnKind : 'darkui'} modal-content__footer-btn`}
              >
                {action.title}
              </button>
            );
          })}
        </div>
      </ModalWrapper>
    );
  }
}

export default MultiActionModal;
