import React, { useRef } from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import Loader from '../Loader';
import Text from '../Text';
import Tooltip from '../Tooltip';
import KnowledgeDashboardPerson from './KnowledgeDashboardPerson';

const KnowledgeDashboardContent = ({
  allPersons,
  areaId,
  canSelectElements,
  experts,
  knowledgeElements,
  knowledgeElementsSortOrder,
  knowledgeOwner,
  onChangeElementStatus,
  onClickSelectElement,
  persons,
  selectedElements,
  showResponsibles,
  visiblePersonIds,
  wildcardPersons,
  onClickSubmitChangeSingleMediator,
  personView,
}) => {
  const personsRowRef = useRef(null);
  const renderPerson = ({ columnIndex, key, style, isScrolling }) => {
    const id = visiblePersonIds[columnIndex];
    const person = persons[id];
    const content = isScrolling ? (
      <Loader padding="md" />
    ) : (
      <KnowledgeDashboardPerson
        areaId={areaId}
        allPersons={allPersons}
        canSelectElements={canSelectElements}
        experts={experts}
        id={id}
        knowledgeElements={knowledgeElements}
        knowledgeElementsSortOrder={knowledgeElementsSortOrder}
        knowledgeOwner={knowledgeOwner}
        mentorId={person.mentor}
        onChangeElementStatus={onChangeElementStatus}
        onClickSelectElement={onClickSelectElement}
        personKnowledgeElements={person.knowledgeElements}
        selectedElements={selectedElements}
        showResponsibles={showResponsibles}
        wildcardPersons={wildcardPersons}
        onClickSubmitChangeSingleMediator={onClickSubmitChangeSingleMediator}
      />
    );
    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };
  const renderPersonName = (id) => {
    const person = persons[id];
    if (showResponsibles) {
      return person.name;
    }
    if (personView === 'employeeNumber' && person.employeeNumber) {
      return person.employeeNumber;
    }
    return person.initials;
  };
  const renederPersonsRow = () => (
    <div ref={personsRowRef} className="dashboard-content__persons-row">
      {visiblePersonIds.map((id) => {
        const person = persons[id];
        return (
          <Tooltip
            placement="top"
            tooltip={
              <span>
                {person.name} ({person.initials}
                {person.employeeNumber ? ` · ${person.employeeNumber}` : ''})
              </span>
            }
            key={id}
          >
            <div key={id} className="dashboard-content__name-box dashboard-content__name-box--center">
              <Text truncate color="blue">{renderPersonName(id)}</Text>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
  return (
    <div className="dashboard-content">
      {renederPersonsRow()}
      <AutoSizer nonce={cspNonce}>
        {({ width, height }) => (
          <Grid
            cellRenderer={renderPerson}
            columnWidth={showResponsibles ? 200 : 80}
            rowHeight={height + 50}
            columnCount={visiblePersonIds.length}
            height={height + 50}
            width={width}
            rowCount={1}
            autoHeight
            onScroll={({ scrollLeft }) => {
              personsRowRef.current.scrollTo(scrollLeft, 0);
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default KnowledgeDashboardContent;
